import MenuItems from '../../components/MenuItems';

const isUrlMatchingPattern = (url, regexArray) => {
  return regexArray.reduce((result, regex) => result || regex.test(url), false);
};

export const getSelectedNavItems = (url, _drawerFixed) => {
  switch (true) {
    case isUrlMatchingPattern(url, [
      /\/assessor\/assessment-tasks\//,
      /\/competitions\/competition-tasks\//,
      /\/assessor\/fd-training\/tasks\//,
    ]):
      return {
        selected: 'Challenges',
        selectedParent: '',
        defaultOpen: _drawerFixed,
      };
    case isUrlMatchingPattern(url, [
      /\/assessor\/assessment-overview\//,
      /\/competitions\/competition-overview\//,
      /\/assessor\/fd-training\/overview\//,
    ]):
      return {
        selected: 'Overview',
        selectedParent: '',
        defaultOpen: _drawerFixed,
      };
    case isUrlMatchingPattern(url, [
      /\/competitions\/competition-scoreboard\//,
    ]):
      return {
        selected: 'Scoreboard',
        selectedParent: '',
        defaultOpen: _drawerFixed,
      };
    case isUrlMatchingPattern(url, [
      /\/competitions\/competition-announcements\//,
    ]):
      return {
        selected: 'Announcements',
        selectedParent: '',
        defaultOpen: _drawerFixed,
      };
    case isUrlMatchingPattern(url, [
      /\/competitions\/competition-chat-forum\//,
    ]):
      return {
        selected: 'Chat Forum',
        selectedParent: '',
        defaultOpen: _drawerFixed,
      };
    case isUrlMatchingPattern(url, [
      /\/assessor\/assessment-complete\/?$/,
      /\/assessor\/fd-training\/complete\/?$/,
      /\/assessor\/survey\/?$/,
      /\/assessor\/view\//,
      /\/assessor\/fd-training\/view\//,
    ]):
      return {
        selected: 'Home',
        selectedParent: '',
        defaultOpen: _drawerFixed,
      };
    case isUrlMatchingPattern(url, [/\/labs\/?$/]):
      return {
        selected: 'Labs',
        selectedParent: 'Manage Content',
        defaultOpen: _drawerFixed,
      };
    case isUrlMatchingPattern(url, [/\/labs\/vms/]):
      return {
        selected: 'VMs',
        selectedParent: 'Manage Content',
        defaultOpen: _drawerFixed,
      };
    case isUrlMatchingPattern(url, [/\/labs\/quizzes/]):
      return {
        selected: 'Quizzes',
        selectedParent: 'Manage Content',
        defaultOpen: _drawerFixed,
      };
    case isUrlMatchingPattern(url, [
      /\/tasks\/?$/,
      /\/tasks\/create\//,
      /\/tasks\/edit-draft\//,
    ]):
      return {
        selected: 'Challenges',
        selectedParent: 'Manage Content',
        defaultOpen: _drawerFixed,
      };
    case isUrlMatchingPattern(url, [
      /\/user-management\/affiliated-users/,
      /\/competitions\/affiliated-view-user/,
      /\/competitions\/training\/create/,
      /\/competitions\/training\/edit/,
      /^\/competitions\/trophy-room\/.+$/,
    ]):
      return {
        selected: MenuItems.affiliated.name,
        selectedParent: MenuItems.users.name,
        defaultOpen: _drawerFixed,
      };
    case isUrlMatchingPattern(url, [/\/user-management\/squads/]):
      return {
        selected: MenuItems.squads.name,
        selectedParent: MenuItems.users.name,
        defaultOpen: _drawerFixed,
      };
    case isUrlMatchingPattern(url, [
      /\/user-management\/non-affiliated-users/,
      /\/competitions\/non-affiliated-view-user/,
    ]):
      return {
        selected: MenuItems.nonAffiliated.name,
        selectedParent: MenuItems.users.name,
        defaultOpen: _drawerFixed,
      };
    case isUrlMatchingPattern(url, [/\/user-management\/tags/]):
      return {
        selected: MenuItems.tagDirectory.name,
        selectedParent: '',
        defaultOpen: _drawerFixed,
      };
    case isUrlMatchingPattern(url, [
      /\/landing\/?$/,
      /\/landing\/landing-homepage\/?$/,
      /\/landing\/org\/profile\/?$/,
      /\/landing\/user\/profile\/?$/,
    ]):
      return {
        selected: 'Home',
        selectedParent: '',
        defaultOpen: _drawerFixed,
      };
    case isUrlMatchingPattern(url, [/\/org\/template-library/]):
      return {
        selected: MenuItems.templateLibrary.name,
        selectedParent: '',
        defaultOpen: _drawerFixed,
      };
    case isUrlMatchingPattern(url, [/\/competitions\/skills-overview/]):
      return {
        selected: MenuItems.mySkills.name,
        selectedParent: '',
        defaultOpen: _drawerFixed,
      };
    case isUrlMatchingPattern(url, [/\/landing\/landing-homepage\/my-events/]):
      return {
        selected: MenuItems.myEvents.name,
        selectedParent: '',
        defaultOpen: _drawerFixed,
      };
    case isUrlMatchingPattern(url, [/\/landing\/teams/]):
      return {
        selected: MenuItems.myTeams.name,
        selectedParent: '',
        defaultOpen: _drawerFixed,
      };
    case isUrlMatchingPattern(url, [/\/landing\/hall-of-fame/]):
      return {
        selected: MenuItems.hallOfFame.name,
        selectedParent: '',
        defaultOpen: _drawerFixed,
      };
    case isUrlMatchingPattern(url, [/^\/competitions\/trophy-room\/?$/]):
      return {
        selected: MenuItems.trophyRoom.name,
        selectedParent: '',
        defaultOpen: _drawerFixed,
      };
    case isUrlMatchingPattern(url, [
      /\/user-management\/workforce\/functions\/?$/,
      /\/user-management\/workforce\/functions\/create\/?$/,
      /\/user-management\/workforce\/functions\/edit\//,
    ]):
      return {
        selected: MenuItems.functions.name,
        selectedParent: MenuItems.workforce.name,
        defaultOpen: _drawerFixed,
      };
    case isUrlMatchingPattern(url, [
      /\/org\/?$/,
      /\/org\/create\/?$/,
      /\/org\/view\//,
    ]):
      return {
        selected: MenuItems.organisations.name,
        selectedParent: '',
        defaultOpen: _drawerFixed,
      };
    case isUrlMatchingPattern(url, [/\/org\/content-library/]):
      return {
        selected: MenuItems.contentLibrary.name,
        selectedParent: '',
        defaultOpen: _drawerFixed,
      };
    case isUrlMatchingPattern(url, [/\/org\/content-lease-catalogue/]):
      return {
        selected: MenuItems.contentCatalogue.name,
        selectedParent: '',
        defaultOpen: _drawerFixed,
      };
    case isUrlMatchingPattern(url, [/\/labs\/courses/]):
      return {
        selected: 'Home',
        selectedParent: '',
        defaultOpen: _drawerFixed,
      };
    default:
      return { defaultOpen: false };
  }
};
