import { isBefore, isAfter } from 'date-fns';
import { TEMPLATE_STATUS, AVAILABILITY_STATUS } from '../../constants';

export const getTemplateAlertMsg = (templateStatus, availabilityStatus) => {
  let message = '';
  if (templateStatus === TEMPLATE_STATUS.DRAFT) {
    message =
      'This is a draft training template. Organisations will not receive their instances of this template until it has been released, regardless of availability status.';
  } else if (
    templateStatus === TEMPLATE_STATUS.RELEASED &&
    availabilityStatus === AVAILABILITY_STATUS.Active
  ) {
    message =
      'This released training template is currently active and cannot be edited. Affiliated participants and managers can access it until the Training End date. To remove access before the Training End date, click Roll Back.';
  } else if (
    templateStatus === TEMPLATE_STATUS.RELEASED &&
    availabilityStatus === AVAILABILITY_STATUS.Upcoming
  ) {
    message =
      'This is a released training template. You can only make edits until the scheduled Training Start date, after which organisations will receive their own instances of the training. Edits will no longer be possible once the training has started.';
  } else if (
    templateStatus === TEMPLATE_STATUS.RELEASED &&
    availabilityStatus === AVAILABILITY_STATUS.Ended
  ) {
    message =
      'This released training template is past its scheduled availability period. Organisations no longer have access to their instances of this template. However, any data generated by individuals during this training remains available in their profiles.';
  } else {
    message =
      'This is a rolled-back training template. Organisations no longer have access to their instances of the template. However, any data generated by individuals during the training remains available in their profiles.';
  }
  return message;
};

export const getTemplateAvailabilityStatus = (startDate, endDate) => {
  let status = AVAILABILITY_STATUS.Upcoming;
  if (isAfter(new Date(startDate), new Date())) {
    status = AVAILABILITY_STATUS.Upcoming;
  } else if (isBefore(new Date(endDate), new Date())) {
    status = AVAILABILITY_STATUS.Ended;
  } else {
    status = AVAILABILITY_STATUS.Active;
  }

  return status;
};
