import React from 'react';
import { useQuery, gql } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import {
  FdProgress,
  FdChip,
  FdAccordion,
  FdTypography as Typography,
  BasePage,
  FdMarkdownRender,
  FdFileAttachment,
} from '@fifthdomain/fe-shared';
import { FdBreadcrumbHeader } from '@fifthdomain/sidebar';
import { getTask } from '../graphql/queries';
import HintsTable from '../components/HintsTable';
import { getDifficultyLevel } from '../shared/utils/difficultyMapping';

const ViewChallenge = () => {
  const { taskId } = useParams();
  const { data: taskData, loading: taskDataLoading } = useQuery(gql(getTask), {
    variables: {
      id: taskId,
    },
    fetchPolicy: 'cache-and-network',
  });

  if (taskDataLoading) {
    return <FdProgress />;
  }
  const taskDetails = () => (
    <Box>
      <Typography variant="subtitle1" color="primary">
        Name
      </Typography>
      <Typography variant="body1" color="secondary">
        {taskData?.getTask?.name}
      </Typography>
      <Box m={2} />
      <Typography variant="subtitle1" color="primary">
        Description
      </Typography>
      <Typography variant="body1" color="secondary">
        {taskData?.getTask?.description}
      </Typography>
      <Box m={2} />
      <Typography variant="subtitle1" color="primary">
        Proficiency
      </Typography>
      <Typography variant="body1" color="secondary">
        {getDifficultyLevel(taskData?.getTask?.difficulty)}
      </Typography>
      <Box m={2} />
      <Typography variant="subtitle1" color="primary">
        Points
      </Typography>
      <Typography variant="body1" color="secondary">
        {taskData?.getTask?.recommendedPoints}
      </Typography>
      <Box m={2} />
      <Typography variant="subtitle1" color="primary">
        Estimated time to solve
      </Typography>
      <Typography variant="body1" color="secondary">
        {`${taskData?.getTask?.estimatedSolveTime} Minutes`}
      </Typography>
    </Box>
  );
  const flagValue = () => (
    <Box>
      <Typography variant="subtitle1" color="primary">
        Flag
      </Typography>
      <Typography
        variant="body1"
        color="secondary"
        style={{ whiteSpace: 'pre' }}
      >
        {taskData?.getTask?.answer}
      </Typography>
    </Box>
  );

  const labDetailsView = () => (
    <Box>
      <Typography variant="subtitle1" color="primary">
        Name
      </Typography>
      <Typography variant="body1" color="secondary">
        {taskData?.getTask?.lab?.name ||
          taskData?.getTask?.modulePart?.lab?.name}
      </Typography>
    </Box>
  );

  const attachUpload = () =>
    taskData?.getTask?.files?.items?.length > 0
      ? taskData?.getTask?.files?.items
          ?.filter((file) => file.type.startsWith('application'))
          ?.map((f) => <FdFileAttachment name={f.name} />)
      : '';

  const solutionUpload = () =>
    taskData?.getTask?.files?.items?.length > 0
      ? taskData?.getTask?.files?.items
          ?.filter((file) => file.type === 'text/markdown')
          ?.map((f) => <FdFileAttachment name={f.name} />)
      : '';
  const challengeName = taskData?.getTask?.name;

  return (
    <Box>
      <FdBreadcrumbHeader
        entries={[{ name: 'Challenges', type: 'CHALLENGE' }]}
        page={{ name: challengeName, type: 'CHALLENGE' }}
      />
      <BasePage heading={challengeName}>
        <Box my={2}>
          <Box m={2} />
          <FdAccordion
            summary={() => <Typography variant="h3">Details</Typography>}
            open
            content={taskDetails}
          />
          <Box m={2} />
          <FdAccordion
            summary={() => <Typography variant="h3">Competencies</Typography>}
            open
            content={() =>
              taskData?.getTask?.competencies?.items?.map((item) => (
                <Box ml={1}>
                  <FdChip
                    label={item.competency.competency}
                    style={{ marginBottom: '0.6rem' }}
                  />
                </Box>
              ))
            }
          />
          <Box m={2} />
          <FdAccordion
            summary={() => <Typography variant="h3">Flag</Typography>}
            open
            content={flagValue}
          />
          <Box m={2} />
          <FdAccordion
            summary={() => <Typography variant="h3">Hints</Typography>}
            open
            content={() => (
              <HintsTable hints={taskData?.getTask?.hints?.items || []} />
            )}
          />
          {(taskData?.getTask?.type === 'LAB' ||
            taskData?.getTask?.modulePart) && (
            <>
              <Box m={2} />
              <FdAccordion
                summary={() => (
                  <Typography variant="h3">Lab Details</Typography>
                )}
                open
                content={labDetailsView}
              />
            </>
          )}
          <Box m={2} />
          <FdAccordion
            summary={() => <Typography variant="h3">Attachments</Typography>}
            open
            content={attachUpload}
          />
          <Box m={2} />
          <FdAccordion
            summary={() => <Typography variant="h3">Solution</Typography>}
            open
            content={() => (
              <Box>
                <Box mb={2}>{solutionUpload}</Box>
                <FdMarkdownRender markdown={taskData?.getTask?.solution} />
              </Box>
            )}
          />
        </Box>
      </BasePage>
    </Box>
  );
};

export default ViewChallenge;
