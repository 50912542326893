import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { gql } from '@apollo/client';
import { Box } from '@mui/material';
import {
  FdCard,
  FdTypography,
  FdTable,
  useQueryRecursive,
} from '@fifthdomain/fe-shared';
import { listUsersByAssessmentId } from '../../../graphql/queries';

const statusMap = {
  STARTED: { label: 'Started', color: 'rgba(151, 180, 255, 1)' },
  FINISHED: { label: 'Completed', color: 'rgba(53, 195, 161, 1)' },
};

const FDTrainingParticipants = ({ assessmentId, setRouteIndex }) => {
  const { data: listUsersData, loading: listUsersLoading } = useQueryRecursive(
    gql(listUsersByAssessmentId),
    {
      variables: {
        userAssessmentAssessmentId: assessmentId,
        limit: 1000,
      },
    },
  );
  const userRows =
    listUsersData?.listUsersByAssessmentId?.items?.map((u) => ({
      id: u?.userId,
      name: u?.user?.name,
      email: u?.user?.email,
      status: u?.status,
      startedOn: u?.startedOn,
    })) || [];

  const actions = [
    {
      label: 'View',
      onClick: ({ id }) => {
        setRouteIndex(2, id);
      },
    },
  ];

  return (
    <Box>
      <FdCard className="my-1" variant="outlined">
        <FdTypography variant="h3">Participants</FdTypography>
        <Box my={1}>
          <FdTypography variant="body2" color="secondary">
            This table displays all participants in your organisation who have
            started this FD training.
          </FdTypography>
        </Box>
        <Box height="650px" data-cy="event-table">
          <FdTable
            elevation={0}
            id="fd-training-table"
            loading={listUsersLoading}
            defaultMuiToolbarSettings={{
              showMuiDefaultToolbar: true,
              filterButton: true,
              searchBox: true,
              columnsButton: false,
              densityButton: true,
            }}
            toolbarSettings={{
              filterButton: true,
              searchBox: true,
              columnsButton: false,
            }}
            selection={false}
            rows={userRows}
            actions={actions}
            columns={[
              { field: 'name', width: 400, headerName: 'Participant Name' },
              {
                field: 'email',
                width: 400,
                headerName: 'Email',
              },
              {
                field: 'status',
                width: 100,
                headerName: 'Status',
                renderCell: (params) => (
                  <Box
                    sx={{
                      bgcolor: statusMap[params.value]?.color,
                    }}
                    className="text-center rounded-xl px-2 py-0.5 font-bold"
                  >
                    <FdTypography variant="body2">
                      {statusMap[params.value]?.label || ''}
                    </FdTypography>
                  </Box>
                ),
              },
              {
                field: 'startedOn',
                width: 180,
                headerName: 'Started',
                valueFormatter: (params) =>
                  format(new Date(params.value), 'mm:hh dd/MM/yyyy'),
              },
            ]}
            tablePageSize={10}
            gridId="fd-trainings"
          />
        </Box>
      </FdCard>
    </Box>
  );
};

FDTrainingParticipants.propTypes = {
  assessmentId: PropTypes.string.isRequired,
  setRouteIndex: PropTypes.func.isRequired,
};

export default FDTrainingParticipants;
