import React from 'react';
import StylesProvider from '@mui/styles/StylesProvider';
import createGenerateClassName from '@mui/styles/createGenerateClassName';
import { BrowserRouter, Switch, Redirect } from 'react-router-dom';
import { AppBasePageProvider } from '@fifthdomain/fe-shared';
import UserManagementRoute from './routes/UserManagementRoute';
import UserManagementGroupsRoute from './routes/UserManagementGroupsRoute';
import UserManagementQuizRoute from './routes/UserManagementQuizRoute';
import TagDirectoryManagementRoute from './routes/TagDirectoryManagementRoute';
import CreateGroup from './pages/CreateGroup';
import EditGroup from './pages/EditGroup';
import ManageQuiz from './pages/ManageQuiz';
import ViewQuiz from './pages/ViewQuiz';
import CreateQuiz from './pages/CreateQuiz';
import TagDirectory from './pages/TagDirectory';
import AppThemeProvider from './providers/AppThemeProvider';
import UsersAffiliated from './pages/UsersAffiliated';
import UsersNonAffiliated from './pages/UsersNonAffiliated';
import ViewEditSquad from './pages/ViewEditSquad';
import UserManagementSquadRoute from './routes/UserManagementSquadRoute';
import ListFunctions from './pages/ListFunctions';
import CreateFunction from './pages/CreateFunction';
import EditFunction from './pages/EditFunction';
import FunctionDashboardRoute from './routes/FunctionDashboardRoute';
import ViewFunctionRoute from './routes/ViewFunctionRoute';
import CreateFunctionRoute from './routes/CreateFunctionRoute';
import GraphQlClient from './shared/GraphQlClient';
import './user-management.css';

const generateClassName = createGenerateClassName({
  productionPrefix: 'user-management',
  seed: 'user-management',
});

const App = () => {
  return (
    <GraphQlClient>
      <AppThemeProvider>
        <StylesProvider generateClassName={generateClassName}>
          <AppBasePageProvider>
            <BrowserRouter>
              <Switch>
                <TagDirectoryManagementRoute
                  path="/user-management/tags"
                  component={TagDirectory}
                  exact
                />
                <UserManagementRoute
                  path="/user-management/affiliated-users"
                  component={UsersAffiliated}
                />
                <UserManagementSquadRoute
                  path="/user-management/squads/view/:squadId"
                  component={ViewEditSquad}
                />
                <UserManagementRoute
                  path="/user-management/non-affiliated-users"
                  component={UsersNonAffiliated}
                />
                <UserManagementQuizRoute
                  path="/user-management/quizzes"
                  component={ManageQuiz}
                  exact
                />
                <UserManagementQuizRoute
                  path="/user-management/quizzes/view/:quizId"
                  component={ViewQuiz}
                />
                <UserManagementQuizRoute
                  path="/user-management/quizzes/create"
                  component={CreateQuiz}
                />
                <UserManagementGroupsRoute
                  path="/user-management/groups/create"
                  component={CreateGroup}
                />
                <UserManagementGroupsRoute
                  path="/user-management/groups/edit/:groupId"
                  component={EditGroup}
                />
                <CreateFunctionRoute
                  path="/user-management/workforce/functions/create"
                  component={CreateFunction}
                />
                <ViewFunctionRoute
                  path="/user-management/workforce/functions/edit/:functionId"
                  component={EditFunction}
                />
                <FunctionDashboardRoute
                  path="/user-management/workforce/functions"
                  component={ListFunctions}
                />
                <Redirect to="/" />
              </Switch>
            </BrowserRouter>
          </AppBasePageProvider>
        </StylesProvider>
      </AppThemeProvider>
    </GraphQlClient>
  );
};

export default App;
