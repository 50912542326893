import React from 'react';
import PropTypes from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import { useFormContext, Controller } from 'react-hook-form';
import { Box, IconButton } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SendIcon from '@mui/icons-material/Send';
import {
  FdMultiChipTextField,
  FdTypography,
  FdTooltip,
  FdButton,
  FdAlert,
  errorToastMessage,
  successToastMessage,
} from '@fifthdomain/fe-shared';
import { manageUserAndTeam } from '../../graphql/mutations';
import { TEAM_ROLES } from '../../constants';

const InviteTeamMembers = ({ loading, refetchTeam }) => {
  const { control, trigger, getValues, setValue } = useFormContext();
  const [manageUserAndTeamMutation, { loading: manageUserAndTeamLoading }] =
    useMutation(gql(manageUserAndTeam));

  const onSendInvites = async (e) => {
    e.preventDefault();
    const valid = await trigger(['emails']);

    if (valid) {
      const { id, emails } = getValues();
      // add users to the team
      manageUserAndTeamMutation({
        variables: {
          action: 'ADD',
          emails,
          participantEventType: 'COMPETITION',
          role: TEAM_ROLES.GENERAL_MEMBER,
          teamId: id,
        },
        onCompleted: (_data) => {
          refetchTeam();
          setValue('emails', []);
          successToastMessage(
            _data?.manageUserAndTeam?.message || 'Team invites sent!',
          );
        },
        onError: ({ graphQLErrors }) => {
          if (graphQLErrors) {
            errorToastMessage(graphQLErrors[0]?.message);
          }
        },
      });
    }
  };

  return (
    <Box height="452px">
      <Box className="flex items-center mt-5">
        <FdTypography variant="subtitle1">Invite Team Members</FdTypography>
        <FdTooltip title="Invited users will receive an email to log in/register to the FifthDomain platform. When they log in, they will be able to view your team on their My Teams page.">
          <IconButton size="small" style={{ marginLeft: '5px' }}>
            <InfoOutlinedIcon />
          </IconButton>
        </FdTooltip>
      </Box>
      <FdTypography variant="captiontext1">
        Invite new members to your team by typing or pasting email addresses
        into the box below and clicking the Invite button.
      </FdTypography>
      <Box mt={3}>
        <Box>
          <Box className="flex justify-between mb-2 items-center">
            <FdTypography variant="captiontext1" style={{ fontWeight: 'bold' }}>
              Invitee Email Addresses
            </FdTypography>
            <FdButton
              startIcon={<SendIcon />}
              onClick={onSendInvites}
              disabled={manageUserAndTeamLoading || loading}
            >
              Send invites
            </FdButton>
          </Box>
          <Controller
            control={control}
            name="emails"
            render={({
              field: { ref, value, onChange, ...rest },
              fieldState: { error },
            }) => {
              const allErrors =
                error?.length > 0
                  ? [...new Set(error?.map((e) => e.message))]
                  : [error?.message];

              return (
                <Box>
                  <FdMultiChipTextField
                    id="emails"
                    width="100%"
                    height="130px"
                    label=""
                    required
                    values={value}
                    setValues={onChange}
                    {...rest}
                    inputRef={ref}
                  />
                  {allErrors &&
                    allErrors?.map((message) => (
                      <FdTypography
                        variant="captiontext1"
                        style={{ color: 'red', marginTop: '5px' }}
                      >
                        {message}
                      </FdTypography>
                    ))}
                </Box>
              );
            }}
          />
          <Box className="mt-5">
            <FdAlert
              alertTitle="Invited team members will automatically have the General Member role type."
              message="As a Team Manager, you can change the role type of newly invited team members from General Member to Team Manager once they register to the platform. "
              variant="info"
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

InviteTeamMembers.defaultProps = {
  refetchTeam: () => null,
  loading: false,
};

InviteTeamMembers.propTypes = {
  loading: PropTypes.bool,
  refetchTeam: PropTypes.func,
};

export default InviteTeamMembers;
