import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import { gql, useQuery } from '@apollo/client';
import * as singleSpa from 'single-spa';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { FdTypography, FdProgress } from '@fifthdomain/fe-shared';
import { FdBreadcrumbHeader } from '@fifthdomain/sidebar';
import ModuleSidebar from '../components/Courses/ModuleSidebar';
import { getCourseUser } from '../queries/customQueries';
import PdfPage from '../components/Courses/PdfPage/PdfPage';
import VideoPage from '../components/Courses/VideoPage/VideoPage';
import LabStart from '../components/Labs/LabStart';
import scrollToTop from '../shared/utils/scroll';
import MarkdownPage from '../components/Courses/MarkdownPage';
import QuizAttempt from '../components/Courses/QuizAttempt';

const ViewModule = () => {
  const { courseUserId, moduleId, partId } = useParams();

  const { data: courseUserData, loading: courseUserLoading } = useQuery(
    gql(getCourseUser),
    {
      variables: {
        userCourseId: courseUserId,
      },
      onCompleted: (_cData) => {
        scrollToTop();
        if (!_cData?.getCourseUser) {
          singleSpa.navigateToUrl('/labs/courses');
        }
      },
    },
  );

  if (courseUserLoading) return <FdProgress />;

  const currentCourse = courseUserData?.getCourseUser;

  const sortByOrder = (a, b) => a.orderNumber - b.orderNumber;
  const courseModules =
    [...currentCourse?.course?.courseModules?.items]
      .map((m) => ({
        ...m,
        parts: {
          items: [...m?.parts?.items].sort(sortByOrder),
        },
      }))
      .sort(sortByOrder) || [];

  const allModuleParts = courseModules
    .map((cm) => cm.parts.items.map((p) => ({ partId: p.id, moduleId: cm.id })))
    .flat();

  const currentModule = courseModules.find((cm) => cm.id === moduleId);
  const currentPart =
    currentModule?.parts?.items.find((p) => p.id === partId) || {};

  const currentIndex = allModuleParts.findIndex(
    (mp) => mp.partId === currentPart.id,
  );
  const prevIndex = allModuleParts[currentIndex - 1] || '';
  const nextIndex = allModuleParts[currentIndex + 1] || '';

  const onNavigate = (action) => {
    const navItem = action === 'next' ? nextIndex : prevIndex;
    if (navItem) {
      singleSpa.navigateToUrl(
        `/labs/courses/view/${courseUserId}/module/${navItem.moduleId}/part/${navItem.partId}`,
      );
    }
  };

  const onSidebarSelection = (courseModuleId, id) =>
    singleSpa.navigateToUrl(
      `/labs/courses/view/${courseUserId}/module/${courseModuleId}/part/${id}`,
    );

  // Lab status not ready or expired redirect
  if (
    currentPart?.lab &&
    !['EXPIRED', 'READY'].includes(currentPart?.lab?.status)
  ) {
    singleSpa.navigateToUrl(`/labs/courses/view/${courseUserId}/`);
  }

  return (
    <Box width="calc(100%-200px)">
      <FdBreadcrumbHeader
        page={{ name: currentCourse?.course?.name, type: 'COURSE' }}
      />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Box display="flex" width="100%">
          <Box width="20%" pr={2}>
            <ModuleSidebar
              modules={courseModules}
              currentPartId={partId}
              onSidebarSelection={onSidebarSelection}
            />
          </Box>
          <Box width="80%" display="flex" flexDirection="column">
            <Box display="flex" justifyContent="space-between" mt={1} mb={2}>
              <FdTypography variant="h4">{currentModule?.name}</FdTypography>
              <Box display="flex">
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Button
                    variant="link"
                    startIcon={<KeyboardArrowLeftIcon />}
                    onClick={() => onNavigate('previous')}
                    style={{ textTransform: 'none' }}
                    disabled={!prevIndex}
                  >
                    Previous
                  </Button>
                  <Button
                    variant="link"
                    endIcon={<KeyboardArrowRightIcon />}
                    onClick={() => onNavigate('next')}
                    style={{ textTransform: 'none' }}
                    disabled={!nextIndex}
                  >
                    Next
                  </Button>
                </Box>
              </Box>
            </Box>
            <Box>
              {
                {
                  PDF: (
                    <PdfPage
                      file={{ key: currentPart?.file?.key }}
                      name={currentPart?.name}
                      description={currentPart?.description}
                      courseId={currentCourse?.course?.id}
                      userId={currentCourse?.user?.id}
                    />
                  ),
                  VIDEO: (
                    <VideoPage
                      url={currentPart?.url}
                      name={currentPart?.name}
                      description={currentPart?.description}
                      courseId={currentCourse?.course?.id}
                      userId={currentCourse?.user?.id}
                    />
                  ),
                  LAB: (
                    <LabStart
                      lab={{
                        name: currentPart?.name,
                        description: currentPart?.description,
                        labId: currentPart?.lab?.id,
                        modulePartId: currentPart.id,
                        expiry: currentPart?.expiry,
                        duration: currentPart?.duration,
                        status: currentPart?.lab?.status,
                        updatedAt: currentPart?.lab?.updatedAt,
                      }}
                      courseId={currentCourse?.course?.id}
                      userId={currentCourse?.user?.id}
                    />
                  ),
                  QUIZ: (
                    <QuizAttempt
                      part={currentPart}
                      quiz={currentPart?.quiz}
                      courseId={currentCourse?.course?.id}
                      userId={currentCourse?.user?.id}
                    />
                  ),
                  MARKDOWN: (
                    <MarkdownPage
                      file={{ key: currentPart?.file?.key }}
                      name={currentPart?.name}
                      description={currentPart?.description}
                      courseId={currentCourse?.course?.id}
                      userId={currentCourse?.user?.id}
                    />
                  ),
                }[currentPart?.type]
              }
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ViewModule;
