import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { FdTypography, FdCard } from '@fifthdomain/fe-shared';
import { BarNivo } from '../../Charts';

const FDTechGraphs = ({
  tasks,
  completedTasks,
  selectedUserIds,
  graphHeight,
  isModalView,
}) => {
  const getTechniqueTags = (_tasks, _tag) =>
    _tasks?.items.filter((item) =>
      item.task.skills.items.some((skillItem) =>
        skillItem.techniqueTags.items.some(
          (tagItem) => tagItem.techniqueTag.name === _tag,
        ),
      ),
    );
  const getTechnologyTags = (_tasks, _tag) =>
    _tasks?.items.filter((item) =>
      item.task.technologyTags.items.some(
        (techItem) => techItem.technologyTag.name === _tag,
      ),
    );
  const completedTasksBySelectedUserIds = completedTasks?.filter((ct) =>
    selectedUserIds.includes(ct.userId),
  );

  const techniquesInAssessment = [
    ...new Set(
      tasks?.items
        ?.map((item) => item.task.skills.items)
        .flat()
        .map((skillItem) => skillItem.techniqueTags.items)
        .flat()
        .map((tagItem) => tagItem.techniqueTag.name),
    ),
  ].sort();

  const technologiesInAssessment = [
    ...new Set(
      tasks?.items
        ?.map((item) => item.task.technologyTags.items)
        .flat()
        .map((tagItem) => tagItem.technologyTag.name),
    ),
  ].sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));

  const techniquesBarChartData = techniquesInAssessment?.map((tag) => {
    const tagItemsFound = getTechniqueTags(tasks, tag);
    const taskIds = tagItemsFound?.map((f) => f.task?.id);
    const taskPoints = completedTasksBySelectedUserIds
      .filter((ct) => taskIds.includes(ct.taskId))
      .map((tp) => ({
        [tp?.task?.name]: tp?.task?.recommendedPoints,
      }));
    const completedTaskIds = completedTasksBySelectedUserIds?.map(
      (ct) => ct.taskId,
    );
    const taskNotSolvedPoints = tagItemsFound
      .filter((ct) => !completedTaskIds.includes(ct.taskId))
      .map((tp) => ({
        [`~pointsAvailable${tp?.task?.name}`]: tp?.task?.recommendedPoints,
      }));
    const taskNotSolvedAssessment = [];
    return {
      tag,
      ...taskPoints.reduce((result, item) => {
        const [key, value] = Object.entries(item)[0];
        result[key] = value;
        return result;
      }, {}),
      ...taskNotSolvedPoints.reduce((result, item) => {
        const [key, value] = Object.entries(item)[0];
        result[key] = value;
        return result;
      }, {}),
      ...taskNotSolvedAssessment.reduce((result, item) => {
        const [key, value] = Object.entries(item)[0];
        result[key] = value;
        return result;
      }, {}),
      taskPoints,
      taskNotSolvedPoints,
      taskNotSolvedAssessment,
    };
  });

  const techniquesBarChartDataFinal = techniquesBarChartData;

  const techniqueKeys = [
    ...new Set(
      techniquesBarChartDataFinal.flatMap((item) => [
        ...(item.taskPoints?.flatMap(Object.keys) || []),
        ...(item.taskNotSolvedPoints?.flatMap(Object.keys) || []),
        ...(item.taskNotSolvedAssessment?.flatMap(Object.keys) || []),
      ]),
    ),
  ].sort();

  const technologiesBarChartData = technologiesInAssessment?.map((tag) => {
    const tagItemsFound = getTechnologyTags(tasks, tag);
    const taskIds = tagItemsFound?.map((f) => f.task?.id);
    const taskPoints = completedTasksBySelectedUserIds
      .filter((ct) => taskIds.includes(ct.taskId))
      .map((tp) => ({
        [tp?.task?.name]: tp?.task?.recommendedPoints,
      }));
    const completedTaskIds = completedTasksBySelectedUserIds?.map(
      (ct) => ct.taskId,
    );
    const taskNotSolvedPoints = tagItemsFound
      .filter((ct) => !completedTaskIds.includes(ct.taskId))
      .map((tp) => ({
        [`~pointsAvailable${tp?.task?.name}`]: tp?.task?.recommendedPoints,
      }));
    const taskNotSolvedAssessment = [];

    return {
      tag,
      ...taskPoints.reduce((result, item) => {
        const [key, value] = Object.entries(item)[0];
        result[key] = value;
        return result;
      }, {}),
      ...taskNotSolvedPoints.reduce((result, item) => {
        const [key, value] = Object.entries(item)[0];
        result[key] = value;
        return result;
      }, {}),
      ...taskNotSolvedAssessment.reduce((result, item) => {
        const [key, value] = Object.entries(item)[0];
        result[key] = value;
        return result;
      }, {}),
      taskPoints,
      taskNotSolvedPoints,
      taskNotSolvedAssessment,
    };
  });

  const technologiesBarChartDataFinal = technologiesBarChartData;

  const technologyKeys = [
    ...new Set(
      technologiesBarChartDataFinal.flatMap((item) => [
        ...(item.taskPoints?.flatMap(Object.keys) || []),
        ...(item.taskNotSolvedPoints?.flatMap(Object.keys) || []),
        ...(item.taskNotSolvedAssessment?.flatMap(Object.keys) || []),
      ]),
    ),
  ].sort();

  const getColor = (_data) => {
    if (_data.id.includes('~pointsAvailable')) {
      return '#BDBDBD';
    }
    return '#35C3A1';
  };

  const showTooltip = (d) => (
    <Box
      style={{
        backgroundColor: '#777',
        color: '#fff',
        padding: '4px 8px',
        fontSize: '12px',
      }}
    >
      {d.id
        .replace('~pointsAvailableInAssessment', '')
        .replace('~pointsAvailable', '')}
    </Box>
  );

  return (
    <Box
      className={`flex ${isModalView ? 'flex-col' : 'flex-row'} gap-x-4 my-4`}
    >
      {isModalView && (
        <FdTypography variant="h4">Techniques in Challenge</FdTypography>
      )}
      <FdCard variant="outlined">
        {!isModalView && (
          <>
            <FdTypography variant="h3">Performance in Techniques</FdTypography>
            <FdTypography variant="body2" color="secondary">
              The bar chart below shows the techniques covered in this training.
              Each segment represents a challenge, with its length indicating
              weightage. Green sections highlight successful demonstrations.
              Hover to view challenge details.
            </FdTypography>
          </>
        )}
        <Box height={graphHeight} width="100%">
          <BarNivo
            id="technique-chart-non-pdf"
            data={techniquesBarChartDataFinal}
            keys={techniqueKeys}
            indexBy="tag"
            layout="horizontal"
            colors={getColor}
            margin={{
              top: 10,
              right: 50,
              bottom: 10,
              left: 250,
            }}
            enableGridY={false}
            enableGridX={false}
            gridXValues={5}
            groupMode="stacked"
            enableLabel={false}
            animate={false}
            tooltip={showTooltip}
            innerPadding={1}
          />
        </Box>
      </FdCard>
      {isModalView && (
        <FdTypography variant="h4">Technologies in Challenge</FdTypography>
      )}
      <FdCard variant="outlined">
        {!isModalView && (
          <>
            <FdTypography variant="h3">
              Performance in Technologies
            </FdTypography>
            <FdTypography variant="body2" color="secondary">
              The bar chart below shows the technologies included in this
              training. Each segment represents a challenge, with its length
              indicating weightage. Green sections indicate successful
              applications. Hover to see challenge details.
            </FdTypography>
          </>
        )}
        <Box height={graphHeight} width="100%">
          <BarNivo
            id="technology-chart-non-pdf"
            data={technologiesBarChartDataFinal}
            keys={technologyKeys}
            indexBy="tag"
            layout="horizontal"
            colors={getColor}
            margin={{
              top: 10,
              right: 50,
              bottom: 10,
              left: 250,
            }}
            enableGridY={false}
            enableGridX={false}
            gridXValues={5}
            groupMode="stacked"
            enableLabel={false}
            animate={false}
            tooltip={showTooltip}
            innerPadding={1}
          />
        </Box>
      </FdCard>
    </Box>
  );
};

FDTechGraphs.propTypes = {
  tasks: PropTypes.shape({ items: PropTypes.arrayOf(PropTypes.shape({})) })
    .isRequired,
  completedTasks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedUserIds: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  graphHeight: PropTypes.string.isRequired,
  isModalView: PropTypes.bool,
};

FDTechGraphs.defaultProps = {
  isModalView: false,
};

export default FDTechGraphs;
