import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import * as singleSpa from 'single-spa';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { gql, useMutation, useSubscription, useQuery } from '@apollo/client';
import {
  BasePage,
  FdCard,
  FdTypography,
  FdButton,
  FdLoadingSpinner,
  useSnapshot,
  globalStore,
  FdCortex,
  cortexSort,
  getCortexLevels,
  useQueryRecursive,
} from '@fifthdomain/fe-shared';
import { FdBreadcrumbHeader } from '@fifthdomain/sidebar';
import {
  createUserAssessment,
  startAssessmentForUser,
  updateUserAssessment,
} from '../graphql/mutations';
import { onUpdateAssessment } from '../graphql/subscriptions';
import { getDateTimeZoneFormatted } from '../shared/utils/dateUtils';
import { invalidateAdminHomePageDataQuery } from '../queries/invalidateQueries';
import useGetSkills from '../hooks/useGetSkills';
import {
  getFDTrainingAssessment,
  getUserAssessment,
  listUserAssessmentsByUserId,
} from '../queries/customQueries';
import useStopAssessment from '../hooks/useStopAssessment';
import BreakDownGraphs from '../components/Participant/FDTraining/BreakDownGraphs';
import AssessmentSummary from '../components/Participant/AssessmentSummary';
import { listTaskAttemptsByUserAssessmentId } from '../graphql/queries';
import { PROFICIENCY_LEVELS } from '../constants';

const FDTrainingOverview = () => {
  const { userName, userId } = useSnapshot(globalStore);
  const { assessmentId, mode } = useParams();

  const {
    data: assessmentData,
    loading: assessmentLoading,
    refetch: refetchAssessmentTasksDataQuery,
  } = useQuery(gql(getUserAssessment), {
    variables: {
      id: assessmentId,
    },
    fetchPolicy: 'network-only',
    skip: mode === 'start', // overview mode
  });

  const { data: fdTrainingData, loading: fdTrainingLoading } = useQuery(
    gql(getFDTrainingAssessment),
    {
      variables: {
        id: assessmentId,
      },
      fetchPolicy: 'network-only',
      skip: mode === 'overview', // start mode
    },
  );

  const { loading: userAssessmentsLoading } = useQueryRecursive(
    gql(listUserAssessmentsByUserId),
    {
      variables: {
        userId,
        limit: 1000,
      },
      onCompleted: (data) => {
        const allUserAssessments =
          data?.listUserAssessmentsByUserId?.items || [];
        const userAssessment =
          allUserAssessments.find((ua) => ua.assessment?.id === assessmentId) ||
          {};
        // if already started then move to tasks page
        if (userAssessment?.assessment?.status === 'STARTED') {
          singleSpa.navigateToUrl(
            `/assessor/fd-training/tasks/${userAssessment?.id}`,
          );
        }
      },
      staleTime: { seconds: 0 },
    },
  );

  const [
    createUserAssessmentMutation,
    { loading: createUserAssessmentLoading },
  ] = useMutation(gql(createUserAssessment));

  // get the assessment selected
  const assessmentDataSelected = useMemo(
    () =>
      mode === 'start'
        ? {
            id: assessmentId,
            userAssessmentAssessmentId: assessmentId,
            assessment: { ...fdTrainingData?.getAssessment },
          } || {}
        : assessmentData?.getUserAssessment || {},
    [assessmentData, fdTrainingData, mode, assessmentId],
  );

  const { skills, skillsLoading } = useGetSkills();
  const cortexLoading = skillsLoading;
  const specialties = _(skills)
    .groupBy('specialtyId')
    .map((items, specialtyId) => ({
      specialtyId,
      specialtyName: items[0]?.specialty?.name,
      items: items?.filter((s) => s?.alias),
    }))
    .value();
  const [updateUserAssessmentMutation] = useMutation(gql(updateUserAssessment));
  const [startAssessment, { loading: startAssessmentInProgress }] = useMutation(
    gql(startAssessmentForUser),
    {
      refetchQueries: ['ListUserAssessments'],
      awaitRefetchQueries: true,
      onCompleted: (_data) => {
        const newUserAssessmentId = _data?.startAssessmentForUser;
        invalidateAdminHomePageDataQuery();
        updateUserAssessmentMutation({
          variables: {
            input: {
              id: newUserAssessmentId,
              lastActive: new Date().toISOString(),
            },
          },
        });
        singleSpa.navigateToUrl(
          `/assessor/fd-training/tasks/${newUserAssessmentId}`,
        );
      },
      onError: () => singleSpa.navigateToUrl('/assessor/error'),
    },
  );

  const { data: tasksAttemptsUserData, loading: tasksAttemptsUserDataLoading } =
    useQueryRecursive(gql(listTaskAttemptsByUserAssessmentId), {
      variables: {
        taskAttemptUserAssessmentId: assessmentId,
      },
      skip: !assessmentId,
    });

  useSubscription(gql(onUpdateAssessment), {
    variables: {
      id: assessmentDataSelected?.userAssessmentAssessmentId,
    },
    onData: () => {
      refetchAssessmentTasksDataQuery();
    },
  });

  const [stopAssessment, { loading: stopAssessmentInProgress }] =
    useStopAssessment(false, 'FDTRAINING');

  if (
    assessmentLoading ||
    startAssessmentInProgress ||
    !assessmentDataSelected ||
    stopAssessmentInProgress ||
    tasksAttemptsUserDataLoading ||
    fdTrainingLoading ||
    createUserAssessmentLoading ||
    userAssessmentsLoading
  ) {
    return <FdLoadingSpinner />;
  }

  const {
    assessment: {
      name,
      hours,
      minutes,
      endDateTime,
      description,
      tasks,
      participantEventType,
    } = {},
    id: userAssessmentId,
  } = assessmentDataSelected || {};

  const cortexData =
    tasks?.items
      ?.map(({ task }) =>
        task?.skills?.items?.map(({ skill }) => ({
          difficulty: task?.difficulty,
          skill: skill?.alias,
        })),
      )
      ?.flat() || [];

  const specialtiesFormatted =
    specialties?.sort(cortexSort)?.map((specialty, idx) => {
      const { levels = [], flip = false } = getCortexLevels(idx);

      const dataSorted = specialty?.items?.map((skill) => {
        return {
          id: skill?.alias,
          idWidth: '30px',
          data: [
            ...(levels?.map((l) => {
              const yValue =
                cortexData?.filter(
                  (d) => d?.difficulty === l && d?.skill === skill?.alias,
                )?.length || 0;

              return {
                x: l,
                yAlias: skill?.alias,
                yValue,
                yToolTip: (
                  <Box className="text-center">
                    <Box className="flex gap-x-1 justify-center">
                      <FdTypography variant="captiontext1" color="contrastText">
                        <strong>{`${skill?.name} (${skill?.alias})`}</strong>
                      </FdTypography>
                    </Box>
                    <Box className="flex gap-x-1 justify-center">
                      <FdTypography variant="captiontext1" color="contrastText">
                        {`Level ${l} - ${PROFICIENCY_LEVELS[l]}`}
                      </FdTypography>
                    </Box>
                    <Box>
                      <FdTypography
                        variant="captiontext1"
                        color="contrastText"
                        style={{ fontStyle: 'italic' }}
                      >
                        {`${yValue} x Challenge${yValue === 1 ? 's' : ''}`}
                      </FdTypography>
                    </Box>
                  </Box>
                ),
              };
            }) || []),
          ],
        };
      });
      return { ...specialty, flip, levels, dataSorted };
    }) || [];

  const endDate = getDateTimeZoneFormatted(endDateTime || new Date()); // TimeZone, like AEDT

  const enterCompetition = () => {
    // create a new user assessment and navigate with the new user assessmentId
    createUserAssessmentMutation({
      variables: {
        input: {
          userId,
          status: 'NOT_STARTED',
          userAssessmentAssessmentId: assessmentId,
        },
      },
      onCompleted: (_dataCreateUser) => {
        const newUserAssessmentId = _dataCreateUser?.createUserAssessment.id;
        // start user assessment after creation
        startAssessment({
          variables: {
            userAssessmentId: newUserAssessmentId,
          },
        });
      },
    });
  };
  const isStartMode = mode === 'start';
  const allTasks = tasks?.items?.map((t) => ({ ...t?.task })) || [];
  const tasksAttemptsData =
    tasksAttemptsUserData?.listTaskAttemptsByUserAssessmentId?.items || [];
  const tasksCompleted =
    tasksAttemptsData?.filter((t) => t.success).length || 0;

  return (
    <Box>
      <FdBreadcrumbHeader page={{ name, type: 'TRAINING' }} />
      <BasePage
        heading={name}
        headingAdornment={
          isStartMode && (
            <FdButton onClick={enterCompetition}>Enter Training</FdButton>
          )
        }
      >
        {!isStartMode && (
          <Box mb={2}>
            <AssessmentSummary
              assessment={{
                hours,
                minutes,
                endDateTime: new Date(endDateTime),
                tasksCompleted,
                participantEventType,
              }}
              onFinish={() =>
                stopAssessment({
                  variables: {
                    userAssessmentId,
                  },
                })
              }
            />
          </Box>
        )}
        <FdCard className="my-1">
          {isStartMode && (
            <FdTypography variant="h4">{`Welcome ${userName}!`}</FdTypography>
          )}
          <Box my={2}>
            <FdTypography variant="subtitle1">
              Training Description
            </FdTypography>
            <FdTypography variant="body2" color="secondary">
              {description}
            </FdTypography>
          </Box>
          <Box my={2}>
            <FdTypography variant="subtitle1">Available Until</FdTypography>
            <FdTypography variant="body2" color="secondary">
              {endDate}
            </FdTypography>
          </Box>
          <Box className="flex gap-x-14">
            <Box className="flex-grow">
              <FdCard variant="outlined" className="h-full">
                <FdTypography variant="subtitle1">
                  Training Breakdown
                </FdTypography>
                <Box className="my-1">
                  <FdTypography variant="captiontext1" color="secondary">
                    View this training&apos;s structure breakdown below.
                  </FdTypography>
                </Box>
                <Box
                  className="inline-block my-2 px-2 py-1 rounded-lg text-center"
                  sx={{ bgcolor: 'primary.main' }}
                >
                  <FdTypography
                    variant="captiontext2"
                    color="primary.contrastText"
                    className="font-medium"
                  >
                    {`${allTasks?.length} Challenges`}
                  </FdTypography>
                </Box>
                <BreakDownGraphs allTasks={allTasks} />
              </FdCard>
            </Box>
            <Box maxWidth={550}>
              <Box>
                <FdTypography variant="subtitle1" className="text-center">
                  Cyber Skills Cortex Overview
                </FdTypography>
                <FdTypography
                  variant="captiontext1"
                  color="secondary"
                  className="text-center p-1"
                >
                  The Cyber Skills Cortex below displays the Skill-Proficiency
                  areas addressed by challenges in this training. The numbers
                  within each skill-proficiency square indicate the number of
                  challenges included for that specific area.
                </FdTypography>
              </Box>
              <Box my={2}>
                <FdCortex
                  specialties={specialtiesFormatted}
                  loading={cortexLoading}
                />
              </Box>
            </Box>
          </Box>
        </FdCard>
      </BasePage>
    </Box>
  );
};

export default FDTrainingOverview;
