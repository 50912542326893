import React, { useState, useEffect } from 'react';
import JSZip from 'jszip';
import _ from 'lodash';
import { saveAs } from 'file-saver';
import useAsyncEffect from 'use-async-effect';
import { gql } from '@apollo/client';
import { Box, Divider, useTheme } from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  FdSelect,
  FdTypography,
  useQueryRecursive,
  FdCard,
  FdAlert,
  FdSkeleton,
  FdDelayed,
  FdButton,
  FdTooltip,
  useSnapshot,
  globalStore,
  successToastMessage,
} from '@fifthdomain/fe-shared';
import { isAfter, hoursToMinutes } from 'date-fns';
import { Summary } from '../components/Assessment';
import { listTaskNotes, listUsersByAssessmentId } from '../graphql/queries';
import {
  listGroupsUsersByAssessmenId,
  getTasksAttemptsByAssessmentId,
} from '../queries/customQueries';
import {
  getDateTimeZoneFormatted,
  formatMinutes,
  getDifferenceInMinutes,
} from '../shared/utils/dateUtils';
import scrollToTop from '../shared/utils/scroll';
import NoDataToPresent from '../components/Overview/NoDataToPresent';
import ProgressIndicator from '../components/Overview/ProgressIndicator';
import {
  filterBySkill,
  getRankingNormalizedRows,
  getRankingTableRows,
} from '../components/Overview/overviewUtils';
import { sortObjectArrayByField } from '../shared/utils/objectUtils';
import { LineNivo, RadarNivo } from '../components/Charts';
import TableHeaderColumnWithTooltip from '../components/Assessment/TableHeaderColumnWithTooltip';
import ProficiencyApproachTable from '../components/Assessment/Insights/ProficiencyApproachTable';
import TechGraphs from '../components/Assessment/Insights/TechGraphs';
import InsightsPdfReportDownload from '../components/Assessment/InsightsPdfReport/InsightsPdfReportDownload';
import HeatmapNivo from '../components/Charts/HeatmapNivo';
import { getSkillsProficienciesMappedData } from '../shared/utils/getAssessmentOverview';
import InsightsPdfReportGenerate from '../components/Assessment/InsightsPdfReport/InsightsPdfReportGenerate';
import ChartsForDownload from '../components/Assessment/InsightsPdfReport/ChartsForDownload';
import CircularProgressWithLabel from '../components/Assessment/Insights/CircularProgress';
import HeatMapCustomLegend from '../components/Assessment/HeatMapCustomLegend';
import useGetSvgWidthOnResize from '../hooks/useGetSvgWidthOnResize';
import { getSpecialtyLegends } from '../components/Assessment/getSpecialtyLegends';
import ProfileHyperlink from '../components/Assessment/Insights/ProfileHyperlink';

const Insights = ({
  defaultInsight,
  assessmentData,
  attemptsData,
  loading,
  allTasks,
  timeSpentDataForAssessment,
  specialtySkills,
  tasksOpened,
}) => {
  const [userName, setUserName] = useState('');
  const [downloadAllLoading, setDownloadAllLoading] = useState(false);
  const [pdfBlobs, setPdfBlobs] = useState([]);
  const { assessmentId } = useParams();
  const [zipPartCount, setZipPartCount] = useState(0);
  const theme = useTheme();
  const isDarkTheme = theme?.palette?.type === 'dark';
  const globalSnap = useSnapshot(globalStore);
  const starterPricingTier = globalSnap.orgPricingTier === 'STARTER' || false;
  const zipChunkSize = 30; // zip file will have a max of this file size
  const [divRef, width, labelWidth, updateHeatMapWidth] =
    useGetSvgWidthOnResize();

  const { data: usersData } = useQueryRecursive(gql(listUsersByAssessmentId), {
    variables: {
      userAssessmentAssessmentId: assessmentId,
      limit: 1000,
    },
  });

  // get all task notes for the assessment
  const { data: tasksNotesData } = useQueryRecursive(gql(listTaskNotes), {
    variables: {
      filter: {
        assessmentID: { eq: assessmentId },
      },
      limit: 1000,
    },
  });

  const { data: groupsAssessmentData } = useQueryRecursive(
    gql(listGroupsUsersByAssessmenId),
    {
      variables: {
        assessmentId,
        limit: 1000,
      },
    },
  );

  // get all task attempts for the assessment
  const {
    data: tasksAttemptsAssessmentData,
    loading: tasksAttemptsAssessmentDataLoading,
  } = useQueryRecursive(gql(getTasksAttemptsByAssessmentId), {
    variables: {
      assessmentId,
      limit: 1000,
    },
  });

  useEffect(() => {
    if (defaultInsight) {
      setUserName(defaultInsight);
      scrollToTop();
    }
  }, [defaultInsight]);

  useAsyncEffect(async () => {
    const zipChunkSizeReached = pdfBlobs.length === zipChunkSize;
    // download zip file if chunk size reached or if all reports are generated
    if (
      pdfBlobs.length > 0 &&
      // eslint-disable-next-line no-use-before-define
      (zipChunkSizeReached ||
        // eslint-disable-next-line no-use-before-define
        dropDownOptions.length ===
          pdfBlobs.length + zipPartCount * zipChunkSize)
    ) {
      const zip = new JSZip();
      // eslint-disable-next-line no-restricted-syntax
      for (const pdf of pdfBlobs) {
        // eslint-disable-next-line no-await-in-loop
        await zip.file(`${pdf.fileName}.pdf`, pdf.value);
      }
      await zip.generateAsync({ type: 'blob' }).then((content) =>
        saveAs(
          content,
          `${assessmentData.getAssessment?.name} - Reports (${
            zipPartCount + 1
            // eslint-disable-next-line no-use-before-define
          } of ${zipParts}).zip`,
        ),
      );
      document.body.style.overflow = 'auto';
      successToastMessage('Downloads complete.');
      setPdfBlobs([]);
      setDownloadAllLoading(false);
      setUserName('');
      setZipPartCount(0);
      const finishedAllUsers =
        pdfBlobs.length + (zipPartCount + 1) * zipChunkSize ===
        // eslint-disable-next-line no-use-before-define
        dropDownOptions.length;
      // all pdfs zipped then finish batch download
      if (finishedAllUsers) {
        setDownloadAllLoading(false);
        setUserName('');
      }
      setZipPartCount(zipPartCount + 1);
    }
    // eslint-disable-next-line no-use-before-define
  }, [pdfBlobs, dropDownOptions]);

  const { teamBased, hours, minutes, tasks } =
    assessmentData?.getAssessment ?? {};
  // team based
  const teamGroups = groupsAssessmentData?.listGroupsByAssessmenId?.items || [];
  const teamsCompleted = teamGroups.filter(
    (tg) => tg.status === 'FINISHED',
  ).length;

  const teamOrParticipant = teamBased ? 'Team' : 'Participant';
  const teamOrApplicant = teamBased ? 'team' : 'participant';
  const participantsCompleted =
    usersData?.listUsersByAssessmentId?.items.filter(
      (pc) => pc.status === 'FINISHED',
    ).length || 0;

  // if team based then populate with teams instead
  const participantsFinishedAssessment = teamBased
    ? teamGroups
        .filter((tg) => tg.status === 'FINISHED')
        ?.map((t) => ({ userId: t?.group?.id, userName: t?.group?.name }))
        .sort(sortObjectArrayByField('userName'))
    : _.flatMap(
        _.groupBy(
          usersData?.listUsersByAssessmentId?.items
            .filter((pc) => pc?.status === 'FINISHED')
            ?.map((u) => u?.user) || [],
          'name', // check for duplicates in name
        ),
        (users) =>
          users?.length === 1
            ? [{ userId: users[0]?.id, userName: users[0]?.name }]
            : users.map((user, index) => ({
                userId: user?.id,
                userName: `${user?.name} [${index + 1}]`,
              })),
      ).sort(sortObjectArrayByField('userName'));

  const dropDownOptions = participantsFinishedAssessment
    ?.map((p) => p?.userName)
    .sort((a, b) => a.localeCompare(b));

  const selectedUserIds =
    (teamBased
      ? teamGroups
          ?.find((tg) => tg.group?.name === userName)
          ?.group.users?.items?.map((u) => u.user?.id)
      : participantsFinishedAssessment
          ?.filter((u) => u.userName === userName)
          .map((p) => p?.userId)) || [];

  const taskNotes =
    tasksNotesData?.listTaskNotes?.items?.filter((tn) =>
      selectedUserIds?.includes(tn.userId),
    ) || [];

  // attempts detail based on selected user
  const taskAttemptsDetail =
    tasksAttemptsAssessmentData?.listTaskAttempts?.items?.filter((ta) =>
      selectedUserIds.includes(ta.userId),
    ) || [];

  // find zipParts by dividing with zipChunkSize
  let zipParts = (dropDownOptions?.length || 0) / zipChunkSize;
  // add another one if there is a fraction
  if (zipParts % 1 !== 0) {
    zipParts = Math.floor(zipParts) + 1;
  }

  const onDownloadAll = () => {
    setPdfBlobs([]);
    setZipPartCount(0);
    setDownloadAllLoading(true);
    setUserName(dropDownOptions[0]);
  };

  const onGenerateComplete = (blob) => {
    const index = dropDownOptions.findIndex((e) => e === userName) + 1;
    if (index <= dropDownOptions.length) {
      setPdfBlobs((urls) => [
        ...urls,
        {
          fileName: `${userName} - ${assessmentData.getAssessment?.name} - Report`,
          value: blob,
        },
      ]);
      setUserName(dropDownOptions[index]);
    }
  };

  const participantsFinished =
    usersData?.listUsersByAssessmentId?.items?.filter(
      (td) => td.status === 'FINISHED',
    );
  const allTaskAttempts =
    attemptsData?.listTaskAttemptAggregatesByAssessmentId?.items || [];
  const completedTasks = allTaskAttempts?.filter(
    (a) => a.status === 'COMPLETED',
  );
  // no participation then show no data present
  const noDataToPresent =
    !loading &&
    (teamBased ? teamsCompleted === 0 : participantsCompleted === 0);

  const totalAssessmentPoints = tasks?.items.reduce(
    (acc, i) => acc + i.task?.recommendedPoints,
    0,
  );

  const totalPointsScoredInAssessment = completedTasks?.reduce(
    (acc, i) => acc + i?.points,
    0,
  );

  const maxDurationInMins = hours * 60 + minutes;

  const rankingNormalizedRows = getRankingNormalizedRows({
    _participantsFinished: participantsFinished,
    _completedTasks: completedTasks,
    _allTaskAttempts: allTaskAttempts,
    _totalAssessmentPoints: totalAssessmentPoints,
    _maxDurationInMins: maxDurationInMins,
    _timeSpentData: timeSpentDataForAssessment,
    _totalPointsScoredInAssessment: totalPointsScoredInAssessment,
    _teamBased: teamBased,
    _teams: participantsFinishedAssessment,
    _teamGroups: teamGroups,
    _tasksOpened: tasksOpened,
  });

  const {
    rankingTableRows,
    avgOverallScore,
    successPoolAverage,
    efficiencyPoolAverage,
    speedPoolAverage,
  } = getRankingTableRows({
    _participantsFinished: participantsFinished,
    _rankingNormalizedRows: rankingNormalizedRows,
  });

  const participantNormalizedValues = rankingTableRows?.find(
    (p) =>
      participantsFinishedAssessment.find((dd) => dd.userName === userName)
        ?.userId === p.id,
  );

  const {
    rank,
    overallScore,
    normalizedSuccess,
    successRange,
    assessmentSuccessRange,
    normalizedEfficiency,
    efficiencyRange,
    assessmentEfficiencyRange,
    normalizedSpeed,
    speedRange,
    assessmentSpeedRange,
    overallScoreRange,
    assessmentOverallScoreRange,
  } = participantNormalizedValues ?? {};

  const ProgressIndicatorWithLabel = ({
    variant,
    label,
    values,
    range1,
    range2,
  }) => (
    <Box className="flex w-full">
      <Box className="w-1/2">
        <FdTypography variant="subtitle1">{`${label}: ${values.marker}`}</FdTypography>
        <FdTypography variant="captiontext1" color="secondary">
          {`Cohort Average: ${values.avg}`}
        </FdTypography>
      </Box>
      <Box className="w-1/2">
        <ProgressIndicator
          variant={variant}
          range1={{ start: range1?.min, end: range1?.max }}
          range2={{ start: range2?.min, end: range2?.max }}
          marker={values.marker}
        />
      </Box>
    </Box>
  );
  ProgressIndicatorWithLabel.propTypes = {
    variant: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    values: PropTypes.shape({ marker: PropTypes.number, avg: PropTypes.number })
      .isRequired,
    range1: PropTypes.shape({ min: PropTypes.number, max: PropTypes.number })
      .isRequired,
    range2: PropTypes.shape({ min: PropTypes.number, max: PropTypes.number })
      .isRequired,
  };
  const getCohortAvgValue = (_field) =>
    rankingNormalizedRows?.length > 0
      ? Math.round(
          rankingNormalizedRows?.reduce((acc, i) => acc + i[_field], 0) /
            rankingNormalizedRows?.length,
          2,
        )
      : 0;
  const radarChartData = [
    {
      label: 'Success',
      baseLine: 100,
      [`${teamOrParticipant} Score`]: normalizedSuccess,
      'Cohort Average': getCohortAvgValue('normalizedSuccess'),
    },
    {
      label: 'Speed',
      baseLine: 100,
      [`${teamOrParticipant} Score`]: normalizedSpeed,
      'Cohort Average': getCohortAvgValue('normalizedSpeed'),
    },
    {
      label: 'Efficiency',
      baseLine: 100,
      [`${teamOrParticipant} Score`]: normalizedEfficiency,
      'Cohort Average': getCohortAvgValue('normalizedEfficiency'),
    },
  ];
  // all skills in the assessment
  const allSkills = _.chain(
    tasks?.items
      ?.map(({ task }) =>
        task?.skills?.items?.map(({ skill }) => ({
          skillId: skill?.id,
          skillName: skill?.name,
          skillAlias: skill?.alias,
        })),
      )
      ?.flat()
      ?.filter((a) => a?.skillId),
  )
    .uniqBy('skillId')
    .sortBy('skillName')
    ?.map((nc) => {
      const tasksCount =
        tasks?.items?.filter((t) =>
          t.task?.skills?.items?.some(({ skill }) => skill?.id === nc?.skillId),
        )?.length || 0;
      return {
        ...nc,
        caption: `${tasksCount} tasks`,
        tasksCount,
      };
    })
    .value();
  const getSkillName = (alias) =>
    allSkills?.find((s) => s.skillAlias === alias)?.skillName || '';
  const chartCohortRow = {
    id: 'Cohort Average',
    userId: 0,
    data: allSkills?.map((s) => {
      const skillTasks = filterBySkill(tasks?.items, s.skillName);
      const skillTotalAssessmentPoints = skillTasks?.reduce(
        (acc, i) => acc + i.task?.recommendedPoints,
        0,
      );

      const normalizedRows = getRankingNormalizedRows({
        _participantsFinished: participantsFinished,
        _completedTasks: filterBySkill(completedTasks, s.skillName),
        _allTaskAttempts: filterBySkill(allTaskAttempts, s.skillName),
        _totalAssessmentPoints: skillTotalAssessmentPoints,
        _maxDurationInMins: maxDurationInMins,
        _timeSpentData: timeSpentDataForAssessment,
        _totalPointsScoredInAssessment: totalPointsScoredInAssessment,
        _teamBased: teamBased,
        _teams: participantsFinishedAssessment,
        _teamGroups: teamGroups,
        _tasksOpened: filterBySkill(tasksOpened, s.skillName),
      });

      return {
        x: s.skillAlias,
        y:
          rankingNormalizedRows?.length > 0
            ? normalizedRows?.reduce((acc, i) => acc + i?.overallScore, 0) /
              rankingNormalizedRows?.length
            : 0,
      };
    }),
  };
  const selectedUserIdOrTeamId = participantsFinishedAssessment?.find(
    (p) => p.userName === userName,
  )?.userId;
  const timeSpentData = timeSpentDataForAssessment?.find(
    (t) => t.userId === selectedUserIdOrTeamId,
  );

  const chartData = rankingNormalizedRows
    ?.filter((c) =>
      teamBased ? c.name === userName : selectedUserIds.includes(c.id),
    )
    .map((c) => {
      return {
        id: c.name,
        userId: c.id,
        data: allSkills?.map((s) => {
          const skillTasks = filterBySkill(tasks?.items, s.skillName);
          const skillTotalAssessmentPoints = skillTasks?.reduce(
            (acc, i) => acc + i.task?.recommendedPoints,
            0,
          );

          const normalizedRows = getRankingNormalizedRows({
            _participantsFinished: participantsFinished,
            _completedTasks: filterBySkill(completedTasks, s.skillName),
            _allTaskAttempts: filterBySkill(allTaskAttempts, s.skillName),
            _totalAssessmentPoints: skillTotalAssessmentPoints,
            _maxDurationInMins: maxDurationInMins,
            _timeSpentData: timeSpentDataForAssessment,
            _totalPointsScoredInAssessment: totalPointsScoredInAssessment,
            _teamBased: teamBased,
            _teams: participantsFinishedAssessment,
            _teamGroups: teamGroups,
            _tasksOpened: filterBySkill(tasksOpened, s.skillName),
          });

          return {
            x: s.skillAlias,
            y: normalizedRows?.find((nr) => nr?.id === c.id)?.overallScore || 0,
          };
        }),
        rank: c.rank,
      };
    });

  const chartDataRows = [chartCohortRow, ...chartData];

  const allTaskAttemptsUserWise = allTaskAttempts?.filter((at) =>
    selectedUserIds.includes(at.userId),
  );

  const challengesSolved =
    completedTasks?.filter((c) => selectedUserIds.includes(c.userId))?.length ||
    0;
  const submittedData = usersData?.listUsersByAssessmentId?.items || [];

  const userSubmittedData = submittedData?.filter(
    (sd) => selectedUserIds.includes(sd.userId) && sd.status === 'FINISHED',
  )[0];

  const assessmentDuration =
    hoursToMinutes(userSubmittedData?.assessment?.hours) +
    Number(userSubmittedData?.assessment?.minutes);

  const _participantDuration = getDifferenceInMinutes(
    new Date(userSubmittedData?.finishedOn),
    new Date(userSubmittedData?.startedOn),
  );

  const participantDuration = formatMinutes(
    isAfter(_participantDuration, assessmentDuration)
      ? assessmentDuration
      : _participantDuration,
    true,
    true,
  );

  const lineChartRowsPdf = chartCohortRow?.data?.map((r, idx) => ({
    id: r.x,
    skillName: `${getSkillName(r.x)} (${r.x})`,
    skillScore: Math.round(chartData[0]?.data?.[idx]?.y),
    cohortAverage: Math.round(r.y),
  }));

  const pdfDownloadData = {
    submittedDate:
      userSubmittedData?.finishedOn &&
      getDateTimeZoneFormatted(userSubmittedData?.finishedOn),
    skills: allSkills,
    rank,
    totalCandidates: dropDownOptions.length,
    lineChartRows: lineChartRowsPdf,
    assessmentScore: {
      marker: overallScore,
      avg: avgOverallScore,
      range: overallScoreRange,
      assessmentRange: assessmentOverallScoreRange,
    },
    success: {
      marker: Math.round(normalizedSuccess),
      avg: successPoolAverage,
      range: successRange,
      assessmentRange: assessmentSuccessRange,
    },
    efficiency: {
      marker: Math.round(normalizedEfficiency),
      avg: efficiencyPoolAverage,
      range: efficiencyRange,
      assessmentRange: assessmentEfficiencyRange,
    },
    speed: {
      marker: Math.round(normalizedSpeed),
      avg: speedPoolAverage,
      range: speedRange,
      assessmentRange: assessmentSpeedRange,
    },
    allTaskAttemptsUserWise,
    taskAttemptsDetail,
    startDateTime: userSubmittedData?.startedOn,
    taskNotes,
    tasks,
    completedTasks,
    selectedUserIds,
    teamBased,
    tasksOpened,
  };
  const taskIds = tasks?.items?.map((c) => c.taskId);
  const tasksSelected = allTasks?.filter((a) => taskIds?.includes(a.id));

  const skillsProficienciesByProficiency = _.groupBy(
    tasksSelected.map((task) => ({
      proficiency: task.difficulty,
      skills: task.skills.items.map(({ skill }) => ({
        name: skill?.name,
        alias: skill?.alias,
      })),
    })),
    'proficiency',
  );

  const { specialtyLegends, skillsSorted } = getSpecialtyLegends({
    specialtySkills,
  });

  const skillsProficienciesData = getSkillsProficienciesMappedData(
    skillsProficienciesByProficiency,
    skillsSorted,
  );

  const chartsForDownloadData = {
    loading,
    skillsProficienciesData,
    completedTasks,
    selectedUserIds,
    tasks,
    chartDataRows,
    radarChartData,
    teamOrParticipant,
    specialtyLegends,
  };

  useEffect(() => {
    // update heatmap label
    setTimeout(() => {
      updateHeatMapWidth();
    }, 3000);
  }, [loading, updateHeatMapWidth]);

  return (
    <Box width="calc(100%-200px)" data-cy="insights-page">
      <Box
        display="flex"
        justifyContent="flex-end"
        marginBottom={2}
        alignItems="center"
        marginTop="-75px"
      >
        {dropDownOptions.length > 0 && (
          <Box
            height="40px"
            display="flex"
            alignItems="center"
            data-cy="report-download"
          >
            {downloadAllLoading && userName && (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                mr={2}
              >
                <FdTooltip
                  title={`${Math.round(
                    pdfBlobs.length + zipChunkSize * zipPartCount,
                  )} of ${dropDownOptions.length}`}
                >
                  <span>
                    <CircularProgressWithLabel
                      value={Math.round(
                        ((pdfBlobs.length + zipChunkSize * zipPartCount) /
                          dropDownOptions.length) *
                          100,
                      )}
                    />
                    <FdSkeleton loading height="10px" width="40px">
                      <></>
                    </FdSkeleton>
                    <FdDelayed
                      delay={1000}
                      triggerField={userName}
                      hideProgress
                    >
                      <Box>
                        <InsightsPdfReportGenerate
                          userName={userName}
                          userEmail={userSubmittedData?.user?.email || ''}
                          assessmentName={assessmentData.getAssessment?.name}
                          insightsData={pdfDownloadData}
                          loading={
                            loading || tasksAttemptsAssessmentDataLoading
                          }
                          onCompleted={onGenerateComplete}
                        />
                      </Box>
                    </FdDelayed>
                  </span>
                </FdTooltip>
              </Box>
            )}
            <FdSkeleton
              loading={loading}
              height="40px"
              width="182px"
              className="mr-3 mb-3"
            >
              {!starterPricingTier &&
                !loading &&
                !userName &&
                !downloadAllLoading && (
                  <Box className="mr-3 mb-3">
                    <FdButton
                      size="large"
                      startIcon={<GetAppIcon />}
                      onClick={onDownloadAll}
                    >
                      Download All
                    </FdButton>
                  </Box>
                )}
            </FdSkeleton>
          </Box>
        )}
        <Box height="40px" mb={2} className="flex items-center">
          {!starterPricingTier &&
            !loading &&
            userName &&
            !downloadAllLoading && (
              <Box width="140px">
                <FdDelayed
                  triggerField={
                    loading || tasksAttemptsAssessmentDataLoading || userName
                  }
                  delay={1000}
                  showSkeleton
                >
                  <Box mr={1}>
                    <InsightsPdfReportDownload
                      userName={userName}
                      userEmail={userSubmittedData?.user?.email || ''}
                      assessmentName={assessmentData.getAssessment?.name}
                      insightsData={pdfDownloadData}
                      loading={loading || tasksAttemptsAssessmentDataLoading}
                    />
                  </Box>
                </FdDelayed>
              </Box>
            )}
          <FdSelect
            id="completedParticipants"
            placeholder={`Select ${teamOrParticipant}`}
            defaultSelected={userName || defaultInsight}
            options={dropDownOptions}
            disabled={noDataToPresent || downloadAllLoading}
            onChange={(v) => {
              setUserName(v);
            }}
            data-cy="completed-participants"
          />
        </Box>
      </Box>
      {userName && !downloadAllLoading ? (
        <>
          <Summary
            data={[
              {
                value: (
                  <ProfileHyperlink userId={selectedUserIdOrTeamId}>
                    <FdTypography variant="h3">{userName}</FdTypography>
                  </ProfileHyperlink>
                ),
                description: userSubmittedData?.user?.email || '',
              },
              {
                value: (
                  <Box mb={1}>
                    {userSubmittedData?.finishedOn &&
                      getDateTimeZoneFormatted(userSubmittedData?.finishedOn)}
                  </Box>
                ),
                description: 'Submitted',
              },
              {
                value: <Box mb={1}>{challengesSolved}</Box>,
                description: 'Challenges Solved',
              },
              {
                value: <Box mb={1}>{participantDuration}</Box>,
                description: 'Duration',
              },
            ]}
            titleVariant="subtitle1"
            subtitleVariant="subtitle2"
          />
          <Box maxHeight="2130px" style={{ overflow: 'hidden' }}>
            <Box mt={2}>
              <FdCard
                heading={`How did the ${teamOrApplicant} perform in the assessment?`}
                subHeading={`This section gives you an overall view of how successful, efficient and quick the ${teamOrApplicant} has been in this assessment`}
                elevation={0}
              >
                <FdCard variant="outlined">
                  <Box className="flex gap-x-4">
                    <Box width="40%">
                      <Box className="flex items-center pb-3">
                        <FdTypography variant="subtitle1">
                          Assessment Rank
                        </FdTypography>
                        <Box
                          className="flex items-center justify-center w-8 h-8 rounded-full ml-4"
                          style={{ backgroundColor: '#1565C0' }}
                        >
                          <span className="text-white">{`#${rank}`}</span>
                        </Box>
                      </Box>
                      <FdTypography variant="captiontext1" color="secondary">
                        The assessment rank is determined by the assessment
                        score, which is a function of Success (challenges
                        successfully solved), Efficiency (scoring points with
                        the least number of attempts), and Speed (quickness in
                        completing the assessment), where Success is the biggest
                        contributor to the score.
                      </FdTypography>
                      <Divider className="my-4" />
                      <TableHeaderColumnWithTooltip
                        title="Distribution bars"
                        tooltipText={
                          <Box>
                            In the distribution bars, the shaded part shows one
                            standard deviation of variation in the scores of the
                            cohort from the average.
                            <Box mt={1}>
                              Simply put, a wider shaded region indicates that
                              the scores of the cohort are more spread out from
                              the average. Conversely, a smaller shaded region
                              suggests that the majority of scores are clustered
                              closely around the average.
                            </Box>
                            <Box mt={1}>
                              {`The dark line on the bar shows where the ${teamOrApplicant}'s score is for that measure.`}
                            </Box>
                          </Box>
                        }
                        mb={2}
                      />
                      <Box className="flex">
                        <ProgressIndicatorWithLabel
                          variant="grey"
                          label="Assessment Score"
                          values={{
                            marker: overallScore,
                            avg: avgOverallScore,
                          }}
                          range1={assessmentOverallScoreRange}
                          range2={overallScoreRange}
                        />
                      </Box>
                      <Box className="flex">
                        <Divider
                          orientation="vertical"
                          flexItem
                          className="mr-5"
                        />
                        <Box className="flex flex-col w-full gap-y-2 mt-3">
                          <ProgressIndicatorWithLabel
                            variant="red"
                            label="Success"
                            values={{
                              marker: Math.round(normalizedSuccess),
                              avg: successPoolAverage,
                            }}
                            range1={assessmentSuccessRange}
                            range2={successRange}
                          />
                          <Divider />
                          <ProgressIndicatorWithLabel
                            variant="orange"
                            label="Efficiency"
                            values={{
                              marker: Math.round(normalizedEfficiency),
                              avg: efficiencyPoolAverage,
                            }}
                            range1={assessmentEfficiencyRange}
                            range2={efficiencyRange}
                          />
                          <Divider />
                          <ProgressIndicatorWithLabel
                            variant="green"
                            label="Speed"
                            values={{
                              marker: Math.round(normalizedSpeed),
                              avg: speedPoolAverage,
                            }}
                            range1={assessmentSpeedRange}
                            range2={speedRange}
                          />
                        </Box>
                      </Box>
                    </Box>
                    <Divider orientation="vertical" flexItem className="mx-5" />
                    <Box width="60%">
                      <FdTypography variant="subtitle1" className="pb-3">
                        Performance Triangle
                      </FdTypography>
                      <FdTypography variant="captiontext1" color="secondary">
                        {`The performance triangle displays overall ${teamOrApplicant} assessment performance incorporating Success, Efficiency and Speed metrics.`}
                        <Box>
                          {`The dark grey line shows the cohort average, and the blue line shows the ${teamOrApplicant}'s score. Click on the info icons for metrics' definitions`}
                        </Box>
                      </FdTypography>
                      <Box display="flex">
                        <TableHeaderColumnWithTooltip
                          title="Success"
                          tooltipText={
                            <Box>
                              Success is defined by the points earned by
                              successfully solving tasks where:
                              <FdTypography variant="captiontext2">
                                0= No points achieved
                              </FdTypography>
                              <FdTypography variant="captiontext2">
                                100= All Points achieved
                              </FdTypography>
                            </Box>
                          }
                          mr={3}
                        />
                        <TableHeaderColumnWithTooltip
                          title="Efficiency"
                          tooltipText={
                            <Box>
                              {`Efficiency is the ${teamOrApplicant}'s ability to score points in least number of
                              tries where:`}
                              <FdTypography variant="captiontext2">
                                0= All tasks entered but not solved
                              </FdTypography>
                              <FdTypography variant="captiontext2">
                                100= All tasks entered are solved
                              </FdTypography>
                            </Box>
                          }
                          mr={3}
                        />
                        <TableHeaderColumnWithTooltip
                          title="Speed"
                          tooltipText={
                            <Box>
                              {`Speed is determined by the ${teamOrApplicant}'s capability to complete the assessment in
                              the quickest possible time where:`}
                              <FdTypography variant="captiontext2">
                                0=0 solves in complete assessment duration
                              </FdTypography>
                              <FdTypography variant="captiontext2">
                                100= 100% solves in just 1% of assessment
                                duration
                              </FdTypography>
                            </Box>
                          }
                        />
                      </Box>
                      {noDataToPresent ? (
                        <NoDataToPresent />
                      ) : (
                        <FdSkeleton loading={loading} height="350px">
                          <Box height="350px">
                            <RadarNivo
                              data={radarChartData}
                              keys={[
                                'baseLine',
                                `${teamOrParticipant} Score`,
                                'Cohort Average',
                              ]}
                              indexBy="label"
                              colors={[
                                'transparent',
                                'transparent',
                                'transparent',
                              ]}
                              gridLabel={(props) => {
                                // eslint-disable-next-line react/prop-types
                                const { x, y, id } = props;
                                const anchor =
                                  x > 5 ? 'start' : x < -5 ? 'end' : 'middle';
                                const lineData = radarChartData?.find(
                                  (d) => d.label === id,
                                );
                                return (
                                  <g
                                    transform={`translate(${
                                      x + (id === 'Success' ? 30 : 0)
                                    }, ${y})`}
                                  >
                                    <text
                                      alignmentBaseline="middle"
                                      textAnchor={
                                        id === 'Success' ? 'start' : anchor
                                      }
                                      fontSize={14}
                                      fontFamily="Montserrat, sans-serif"
                                      fontWeight={700}
                                      opacity={0.7}
                                      fill={isDarkTheme ? '#fff' : '#000'}
                                    >
                                      {id}
                                      <tspan
                                        fontSize={14}
                                        fontWeight={400}
                                        x={0}
                                        dy={25}
                                      >
                                        {`${teamOrParticipant} Score ${Math.round(
                                          lineData[
                                            `${teamOrParticipant} Score`
                                          ],
                                        )}`}
                                      </tspan>
                                      <tspan
                                        fontSize={14}
                                        fontWeight={400}
                                        x={0}
                                        dy={20}
                                        opacity={0.7}
                                      >
                                        {`Cohort Average ${Math.round(
                                          lineData['Cohort Average'],
                                        )}`}
                                      </tspan>
                                    </text>
                                  </g>
                                );
                              }}
                              borderColor={(v) =>
                                v.key === 'Cohort Average'
                                  ? '#424242'
                                  : v.key === 'baseLine'
                                    ? 'rgba(0, 0, 0, 0.12)'
                                    : theme?.palette?.primary?.main
                              }
                            />
                          </Box>
                        </FdSkeleton>
                      )}
                    </Box>
                  </Box>
                </FdCard>
                <Box className="flex gap-x-2">
                  <FdCard
                    heading="Performance in skills"
                    variant="outlined"
                    className="w-3/5"
                  >
                    <FdTypography
                      variant="subtitle2"
                      style={{ marginTop: '-20px' }}
                    >
                      Skills performance compared to cohort
                    </FdTypography>
                    <FdTypography variant="captiontext1" color="secondary">
                      {`Below, you'll find the ${
                        teamBased ? 'team' : 'participant'
                      }'s performance in assessed skills. The performance score is a 
                      function of the success in that skill as well as the speed and efficiency.`}
                    </FdTypography>
                    <Box mt={2}>
                      <FdTypography variant="captiontext1" color="secondary">
                        {`The blue line represents the ${teamOrApplicant}'s performance, while the dashed line shows the cohort's average. Hover over nodes on the lines to see the assessment scores.`}
                      </FdTypography>
                    </Box>
                    <Box display="flex" height="520px" alignItems="center">
                      <Box height="271px" width="100%">
                        <LineNivo
                          data={chartDataRows}
                          colors={['#757575', theme?.palette?.primary?.main]}
                          showDashedForId="Cohort Average"
                          tooltip={(d) => {
                            const { serieId, data: _data } = d.point;
                            const skillName = getSkillName(_data?.x);
                            return (
                              <Box
                                p={1}
                                style={{
                                  backgroundColor: '#6E6E6E',
                                  borderRadius: '4px',
                                }}
                              >
                                <FdTypography
                                  variant="captiontext1"
                                  color="contrastText"
                                >
                                  {serieId}
                                </FdTypography>
                                <Box mt={0.5}>
                                  <FdTypography
                                    variant="captiontext2"
                                    color="contrastText"
                                  >
                                    {`${skillName}(${_data?.x}): ${Math.round(
                                      _data?.yFormatted,
                                    )}`}
                                  </FdTypography>
                                </Box>
                              </Box>
                            );
                          }}
                        />
                      </Box>
                    </Box>
                  </FdCard>
                  <FdCard variant="outlined" className="w-2/5">
                    <FdTypography variant="captiontext1" color="secondary">
                      The bar chart below shows all the techniques and
                      technologies that are a part of this assessment.
                    </FdTypography>
                    <Box mt={2}>
                      <FdTypography variant="captiontext1" color="secondary">
                        The length of the grey bar indicates the weightage of
                        that technique/technology covered in the assessment. The
                        green part represents the successful demonstration of
                        that technique/technology by the participant.
                      </FdTypography>
                    </Box>
                    {noDataToPresent ? (
                      <Box mt={6}>
                        <NoDataToPresent />
                      </Box>
                    ) : (
                      <TechGraphs
                        loading={loading}
                        tasks={tasks}
                        completedTasks={completedTasks}
                        selectedUserIds={selectedUserIds}
                        containerHeight="560px"
                        graphHeight="450px"
                      />
                    )}
                  </FdCard>
                </Box>

                <FdCard heading="Assessment Approach" variant="outlined">
                  <Box>
                    <FdTypography
                      variant="subtitle2"
                      style={{ marginTop: '-20px' }}
                    >
                      {`How did the ${
                        teamBased ? 'teams' : 'participants'
                      } plan their time?`}
                    </FdTypography>
                    <FdTypography variant="captiontext1" color="secondary">
                      {`This visualisation maps the ${teamOrApplicant}'s journey, showing when they viewed, started, attempted, and solved challenges , revealing their time allocation and assessment approach. These actions are depicted using unique icons, and you can find their explanations in the legend below. In instances where actions follow immediately after one another, you may see only one icon representing the most recent action.`}
                    </FdTypography>
                  </Box>
                  <FdAlert
                    customIcon={<InfoOutlinedIcon />}
                    variant="info"
                    message={
                      <FdTypography variant="body2">
                        {`Click 'View' for comprehensive task details, including valuable insights from the ${teamOrParticipant}'s Notes, providing a glimpse into their problem-solving approach and thought process.`}
                      </FdTypography>
                    }
                    className="my-4"
                  />
                  <ProficiencyApproachTable
                    taskAttempts={allTaskAttemptsUserWise}
                    taskAttemptsDetail={taskAttemptsDetail}
                    startDateTime={userSubmittedData?.startedOn}
                    loading={loading || tasksAttemptsAssessmentDataLoading}
                    taskNotes={taskNotes}
                    tasks={tasks}
                    completedTasks={completedTasks}
                    selectedUserIds={selectedUserIds}
                    teamBased={teamBased}
                    tasksOpened={tasksOpened}
                    timeSpentInMins={timeSpentData?.duration}
                  />
                </FdCard>
              </FdCard>
              <Box mt={3} height="300px">
                <TechGraphs
                  loading={loading}
                  tasks={tasks}
                  completedTasks={completedTasks}
                  selectedUserIds={selectedUserIds}
                  containerHeight="360px"
                  graphHeight="250px"
                  forPdfDownload
                />
              </Box>
              <Box height="380px" id="heatmap-chart" width="100%">
                <Box ref={divRef} height="100%" width="100%">
                  <HeatmapNivo
                    customBottomLegend={
                      <HeatMapCustomLegend
                        width={width}
                        labelWidth={labelWidth}
                        specialtyLegends={specialtyLegends}
                        forPdf
                      />
                    }
                    data={
                      skillsProficienciesData?.sort((a, b) => b.id - a.id) || []
                    }
                    yAxisLabel="Proficiency Levels"
                    color="#3E3EB7"
                    height="350px"
                    theme={{
                      fontSize: 20,
                      axis: {
                        legend: {
                          text: {
                            fontSize: 20,
                            fontFamily: 'Montserrat',
                          },
                        },
                      },
                    }}
                  />
                </Box>
              </Box>
              <Box
                height="350px"
                id="performance-triangle-chart"
                width="1140px"
              >
                <RadarNivo
                  data={radarChartData}
                  keys={[
                    'baseLine',
                    `${teamOrParticipant} Score`,
                    'Cohort Average',
                  ]}
                  indexBy="label"
                  colors={['transparent', 'transparent', 'transparent']}
                  gridLabel={(props) => {
                    // eslint-disable-next-line react/prop-types
                    const { x, y, id } = props;
                    const anchor = x > 5 ? 'start' : x < -5 ? 'end' : 'middle';
                    const lineData = radarChartData?.find(
                      (d) => d.label === id,
                    );
                    return (
                      <g
                        transform={`translate(${
                          x + (id === 'Success' ? 30 : 0)
                        }, ${y})`}
                      >
                        <text
                          alignmentBaseline="middle"
                          textAnchor={id === 'Success' ? 'start' : anchor}
                          fontSize={14}
                          fontFamily="Montserrat, sans-serif"
                          fontWeight={700}
                          opacity={0.7}
                          fill={isDarkTheme ? '#fff' : '#000'}
                        >
                          {id}
                          <tspan fontSize={14} fontWeight={400} x={0} dy={25}>
                            {`${teamOrParticipant} Score ${Math.round(
                              lineData[`${teamOrParticipant} Score`],
                            )}`}
                          </tspan>
                          <tspan
                            fontSize={14}
                            fontWeight={400}
                            x={0}
                            dy={20}
                            opacity={0.7}
                          >
                            {`Cohort Average ${Math.round(
                              lineData['Cohort Average'],
                            )}`}
                          </tspan>
                        </text>
                      </g>
                    );
                  }}
                  borderColor={(v) =>
                    v.key === 'Cohort Average'
                      ? '#424242'
                      : v.key === 'baseLine'
                        ? 'rgba(0, 0, 0, 0.12)'
                        : theme?.palette?.primary?.main
                  }
                  animate={false}
                />
              </Box>
              <Box height="271px" id="performance-skills-chart" width="992px">
                <LineNivo
                  data={chartDataRows}
                  colors={['#757575', theme?.palette?.primary?.main]}
                  showDashedForId="Cohort Average"
                  animate={false}
                />
              </Box>
            </Box>
          </Box>
        </>
      ) : (
        <Box style={{ maxHeight: '740px', overflow: 'hidden' }}>
          <Box
            className="flex flex-col items-center content-center justify-center"
            style={{ height: '80vh' }}
          >
            <FdTypography variant="h3" data-cy="insights-message">
              {' '}
              {noDataToPresent
                ? 'Nothing to show'
                : `No ${teamOrParticipant} Selected`}
            </FdTypography>
            <FdTypography
              variant="body1"
              color="secondary"
              data-cy="insights-no-attempts"
            >
              <i>
                {noDataToPresent
                  ? `You can view insights once a ${teamOrParticipant} who has attempted a task completes the Assessment `
                  : `Select a ${teamOrParticipant} at the top right of this page to view their Insights`}
              </i>
            </FdTypography>
          </Box>
          <ChartsForDownload chartsForDownloadData={chartsForDownloadData} />
        </Box>
      )}
    </Box>
  );
};

Insights.propTypes = {
  defaultInsight: PropTypes.string,
  assessmentData: PropTypes.shape({
    getAssessment: PropTypes.shape({ name: PropTypes.string }),
  }).isRequired,
  attemptsData: PropTypes.shape({
    listTaskAttemptAggregatesByAssessmentId: PropTypes.arrayOf(
      PropTypes.shape({}),
    ),
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  allTasks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  timeSpentDataForAssessment: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  specialtySkills: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
  tasksOpened: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
};

Insights.defaultProps = {
  defaultInsight: '',
};

export default Insights;
