import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import {
  FdTypography,
  FdLoadingSpinner,
  FdMarkdownRender,
} from '@fifthdomain/fe-shared';
import { FdBreadcrumbHeader } from '@fifthdomain/sidebar';
import { getUserAssessment } from '../graphql/queries';

const AssessmentComplete = () => {
  const { assessmentId } = useParams();
  const { data: assessmentData, loading: assessmentLoading } = useQuery(
    gql(getUserAssessment),
    {
      variables: {
        id: assessmentId,
      },
      skip: !assessmentId,
      fetchPolicy: 'cache-and-network',
    },
  );
  const assessmentDataFiltered = assessmentData?.getUserAssessment;

  if (assessmentLoading) {
    return <FdLoadingSpinner />;
  }
  return (
    <Box>
      <FdBreadcrumbHeader />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <Box id="complete-image" mt={10} data-cy="complete-image">
          <svg
            width="264"
            height="227"
            viewBox="0 0 264 227"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M104.324 138.9L170.001 72.75L194.5 97.425L104.324 188.25L55.5 139.075L79.9987 114.4L104.324 138.9ZM178.11 97.425L170.001 89.2583L104.324 155.408L79.9988 130.908L71.8904 139.075L104.324 171.742L178.11 97.425Z"
              fill="#1976D2"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M15.9873 171.203C15.2252 170.888 13.5044 172.435 11.7835 174.151L7.48145 170.018C8.02228 169.366 8.75978 168.641 9.64478 167.771C10.2594 167.167 13.0864 164.75 16.3314 164.75C18.4702 164.75 22.5019 166.417 22.5019 171.662C22.5019 174.948 21.2235 176.833 19.306 179.588C18.1998 181.183 15.6185 185.461 14.7581 188.095C13.8731 190.729 14.5369 192.735 15.6431 192.735C16.6565 192.735 17.6698 191.536 18.2933 190.798C18.3202 190.766 18.3464 190.735 18.3719 190.705C18.9373 190.125 22.5756 185.896 24.0014 184.132C25.8698 181.884 30.6144 177.268 36.1456 177.268C43.3731 177.268 45.6839 183.431 46.0527 187.418H52.1248V193.46H46.0773C45.0939 204.988 38.5548 208.202 34.5477 208.202C30.1964 208.202 26.6564 204.843 26.6564 200.734C26.6564 196.626 30.5898 189.303 39.8823 187.757C39.8648 187.635 39.8483 187.511 39.8316 187.386C39.5891 185.567 39.3042 183.431 35.5556 183.431C32.4827 183.431 28.5002 188.143 25.5256 191.744L25.4945 191.782C22.7804 195.075 20.6235 197.692 18.0031 198.438C15.7906 199.091 13.3569 198.68 11.5131 197.327C9.39895 195.78 8.21895 193.218 8.21895 190.125C8.21895 185.032 13.0847 178.015 14.7476 175.617C14.9942 175.261 15.1704 175.007 15.2498 174.876C15.9873 173.692 17.2164 171.686 15.9873 171.203ZM32.8023 200.589C32.8023 201.701 33.8594 202.329 34.6214 202.329C36.3423 202.329 39.1202 200.42 39.8577 193.919C34.5969 195.272 32.8023 199.139 32.8023 200.589Z"
              fill="#81D4FA"
            />
            <path
              opacity="0.5"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M216.894 65.6772C220.48 58.6137 229.093 55.8011 236.156 59.3871C243.22 62.9731 246.032 71.5862 242.446 78.6497C238.86 85.7132 230.247 88.5258 223.184 84.9398C216.12 81.3539 213.308 72.7408 216.894 65.6772ZM232.478 62.1109L226.918 73.0621L230.569 74.9153L236.128 63.9641L232.478 62.1109Z"
              fill="#F06292"
            />
            <path
              d="M235.314 47.4814L246.266 53.0411L244.412 56.6915L233.461 51.1318L235.314 47.4814Z"
              fill="#F06292"
            />
            <path
              d="M232.479 62.1111L236.129 63.9643L230.569 74.9155L226.919 73.0623L232.479 62.1111Z"
              fill="#F06292"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M251.616 67.144L247.709 68.42C248.527 72.3706 248.061 76.6336 246.106 80.4848C241.501 89.556 230.429 93.1866 221.34 88.5721C212.25 83.9576 208.647 72.8771 213.252 63.8059C217.858 54.7347 228.947 51.1134 238.019 55.7186C241.888 57.683 244.761 60.8405 246.441 64.54L250.349 63.264C250.891 64.5031 251.304 65.8147 251.616 67.144ZM216.893 65.6774C213.308 72.7409 216.12 81.354 223.184 84.94C230.247 88.5259 238.86 85.7133 242.446 78.6498C246.032 71.5863 243.22 62.9732 236.156 59.3872C229.093 55.8013 220.479 58.6139 216.893 65.6774Z"
              fill="#F06292"
            />
            <path
              opacity="0.5"
              d="M64.8489 32.3003C57.879 35.0389 55.8741 42.354 58.6017 49.2958L65.2008 66.0906L87.5939 57.2918L80.9948 40.497C78.2672 33.5551 71.8187 29.5616 64.8489 32.3003Z"
              fill="#00BCD4"
            />
            <path
              d="M81.8966 75.6869C84.9757 74.4771 86.505 70.968 85.2952 67.8889L74.0986 72.2883C75.3085 75.3674 78.8176 76.8967 81.8966 75.6869Z"
              fill="#00BCD4"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M67.0461 25.5561C75.8276 24.3029 83.2168 29.7039 86.5933 38.2973L92.0926 52.293L99.8906 55.6915L100.99 58.4907L56.2043 76.0884L55.1044 73.2892L58.503 65.4912L53.0037 51.4955C49.6161 42.8742 51.3911 33.9046 58.6487 28.8556L57.9008 26.9522C56.9879 24.6289 58.1265 22.0166 60.4497 21.1037C62.773 20.1909 65.3854 21.3294 66.2982 23.6527L67.0461 25.5561ZM65.2011 66.0907L87.5942 57.2918L80.9951 40.497C78.2674 33.5551 71.819 29.5616 64.8492 32.3003C57.8793 35.0389 55.8743 42.354 58.602 49.2958L65.2011 66.0907Z"
              fill="#00BCD4"
            />
            <path
              d="M49.815 30.3878L44.2395 27.9579C39.5343 35.7199 38.2132 45.3513 41.3409 54.4625L46.9392 52.2628C44.4444 44.6801 45.6996 36.6903 49.815 30.3878Z"
              fill="#00BCD4"
            />
            <path
              d="M91.5575 34.7311L97.1557 32.5314C93.2163 23.7391 85.6921 17.5833 76.9894 15.0896L74.5874 20.6541C81.8365 22.4912 88.223 27.4784 91.5575 34.7311Z"
              fill="#00BCD4"
            />
            <path
              opacity="0.5"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M171.075 198.252L183.02 166.14L215.132 178.084L203.187 210.196L171.075 198.252ZM177.369 195.371L193.425 201.343L195.131 196.755L179.075 190.783L177.369 195.371ZM203.719 194.728L180.782 186.196L182.488 181.608L205.425 190.14L203.719 194.728ZM184.194 177.021L207.131 185.553L208.838 180.965L185.901 172.433L184.194 177.021Z"
              fill="#AB47BC"
            />
            <path
              d="M179.075 190.783L195.131 196.755L193.425 201.343L177.369 195.371L179.075 190.783Z"
              fill="#AB47BC"
            />
            <path
              d="M182.487 181.608L205.424 190.14L203.718 194.728L180.781 186.196L182.487 181.608Z"
              fill="#AB47BC"
            />
            <path
              d="M185.9 172.434L208.838 180.965L207.131 185.553L184.194 177.021L185.9 172.434Z"
              fill="#AB47BC"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M216.838 173.497L207.25 169.931C207.276 166.911 205.47 164.046 202.488 162.937C199.506 161.828 196.267 162.816 194.314 165.119L184.726 161.552C184.405 161.433 184.098 161.345 183.774 161.303C182.811 161.154 181.838 161.314 180.988 161.703C180.422 161.962 179.89 162.339 179.456 162.804C179.022 163.269 178.671 163.791 178.432 164.433L166.488 196.545C166.257 197.165 166.164 197.835 166.189 198.471C166.214 199.107 166.379 199.717 166.629 200.306C167.018 201.156 167.65 201.913 168.477 202.429C168.758 202.586 169.048 202.72 169.369 202.839L201.481 214.784C204.004 215.722 206.836 214.426 207.774 211.903L219.719 179.791C220.657 177.267 219.361 174.435 216.838 173.497ZM200.995 166.951C201.936 167.301 202.425 168.371 202.076 169.311C201.726 170.252 200.656 170.742 199.715 170.392C198.775 170.042 198.285 168.972 198.635 168.032C198.985 167.091 200.055 166.601 200.995 166.951ZM171.075 198.252L203.187 210.196L215.132 178.084L183.019 166.14L171.075 198.252Z"
              fill="#AB47BC"
            />
          </svg>
        </Box>
        <Box m={2}>
          <FdTypography variant="h2" data-cy="complete-title">
            Assessment Complete
          </FdTypography>
        </Box>
        <FdMarkdownRender
          mt={2}
          display="flex"
          alignItems="center"
          flexDirection="column"
          width="684px"
          markdown={assessmentDataFiltered?.assessment?.postMessage}
        />
        <Box mt={3}>
          <FdTypography variant="body1" emphasis="medium" data-cy="help-text">
            If there were any issues, please raise a support ticket by clicking
            the support box icon on the bottom right of the screen.
          </FdTypography>
        </Box>
      </Box>
    </Box>
  );
};

export default AssessmentComplete;
