import React from 'react';
import { useQuery, gql } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { Grid, Box } from '@mui/material';
import {
  BasePage,
  FdProgress,
  useSnapshot,
  globalStore,
  Authorization,
} from '@fifthdomain/fe-shared';
import { FdBreadcrumbHeader } from '@fifthdomain/sidebar';
import { listDashboardsByOrgId } from '../graphql/queries';
import Dashboard from '../components/Dashboard/Dashboard';

const ListDashboards = () => {
  const { user, orgId, loading, orgPricingTier, permissions } =
    useSnapshot(globalStore);
  const history = useHistory();

  const { data: dashboardsData, loading: dashboardsLoading } = useQuery(
    gql(listDashboardsByOrgId),
    {
      variables: {
        orgId,
      },
      skip: !orgId,
    },
  );

  if (loading || dashboardsLoading) return <FdProgress />;

  const dashboards = dashboardsData?.listDashboardsByOrgId?.items;

  const canViewDashboards =
    orgPricingTier !== 'STARTER' &&
    user &&
    (Authorization.canManageEvents(permissions) ||
      Authorization.canViewInsights(permissions));

  if (dashboards?.length === 0 || !canViewDashboards) return history.goBack();

  return (
    <Box>
      <FdBreadcrumbHeader page={{ name: 'Dashboards', type: 'ASSESSMENT' }} />
      <BasePage heading="Dashboards">
        <Grid container spacing={3} style={{ paddingTop: '16px' }}>
          {dashboards?.map((dashboard) => (
            <Grid item xs={12} sm={12} md={6} lg={4} key={dashboard.id}>
              <Dashboard dashboard={dashboard} />
            </Grid>
          ))}
        </Grid>
      </BasePage>
    </Box>
  );
};

export default ListDashboards;
