import React, { useState } from 'react';
import {
  useParams,
  Link as RouterLink,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { Box } from '@mui/material';
import {
  BasePage,
  FdTab,
  useSnapshot,
  globalStore,
} from '@fifthdomain/fe-shared';
import { FdBreadcrumbHeader } from '@fifthdomain/sidebar';
import { getTeam } from '../queries/customQueries';
import TeamSummary from '../components/Team/TeamSummary';
import TeamManagement from '../components/Team/TeamManagement';
import { TEAM_ROLES } from '../constants';
import TeamDashboard from '../components/Team/TeamDashboard';

const ViewTeam = () => {
  const { teamId } = useParams();
  const history = useHistory();
  const { search } = useLocation();
  const tabindex = new URLSearchParams(search).get('tabindex') || 0; // default
  const [custIndex, setCustIndex] = useState(tabindex);
  const globalSnap = useSnapshot(globalStore);
  const {
    data: teamData,
    loading: teamDataLoading,
    refetch: refetchTeam,
  } = useQuery(gql(getTeam), {
    variables: {
      id: teamId,
    },
  });
  const team = teamData?.getTeam || {};
  // set tabindex in url along with tab switch
  const setRouteIndex = (indexValue) => {
    setCustIndex(indexValue);
    history.push({ search: `tabindex=${indexValue}` });
  };
  const role =
    team?.members?.items.find((m) => m?.userId === globalSnap.userId)?.role ||
    TEAM_ROLES.GENERAL_MEMBER;

  return (
    <Box>
      <FdBreadcrumbHeader
        entries={[{ name: 'My Teams', path: '/landing/teams', type: 'TEAM' }]}
        page={{ name: team?.name, type: 'TEAM' }}
      />
      <BasePage data-cy="scoreboard-page">
        <Box>
          <TeamSummary team={team} loading={teamDataLoading} />
          <FdTab
            label={[
              {
                label: 'Team Dashboard',
                tabRoute: `/competitions/view-team/${teamId}?tabindex=0`,
                index: 0,
                data: <TeamDashboard team={team} loading={teamDataLoading} />,
              },
              {
                label: 'Team Management',
                tabRoute: `/competitions/view-team/${teamId}?tabindex=1`,
                index: 1,
                data: (
                  <TeamManagement
                    team={team}
                    loading={teamDataLoading}
                    refetchTeam={refetchTeam}
                    role={role}
                  />
                ),
              },
            ]}
            index={parseInt(tabindex, 10)}
            custIndex={parseInt(custIndex, 10)}
            setCustIndex={setRouteIndex}
            tabLinkComponent={RouterLink}
            style={{ marginTop: '-5px' }}
          />
        </Box>
      </BasePage>
    </Box>
  );
};

export default ViewTeam;
