import React, { useState } from 'react';
import shortid from 'shortid';
import { Box, Stepper, Step, StepLabel } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useForm, FormProvider } from 'react-hook-form';
import WarningIcon from '@mui/icons-material/Warning';
import {
  FdButton,
  BasePage,
  useQueryRecursive,
  FdProgress,
  FdModal,
  FdTypography,
  errorToastMessage,
  successToastMessage,
  warningToastMessage,
} from '@fifthdomain/fe-shared';
import { FdBreadcrumbHeader } from '@fifthdomain/sidebar';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQuery, gql, useMutation } from '@apollo/client';
import * as singleSpa from 'single-spa';
import NavigationPrompt from 'react-router-navigation-prompt';
import { useHistory } from 'react-router-dom';
import {
  initialValues,
  validationSchema,
} from '../validation-schemas/Organisation';
import scrollToTop from '../shared/utils/scroll';
import {
  getOrg,
  listTasks,
  listProducts,
  listThemes,
} from '../graphql/queries';
import {
  createOrg,
  allocateTasksToOrg,
  createProductOrg,
  updateOrg,
  deleteProductOrg,
  createThemeOrg,
  deleteThemeOrg,
} from '../graphql/mutations';
import {
  OrgDetails,
  OrgThemes,
  OrgUsers,
  OrgProducts,
  OrgContent,
  OrgWorkRoles,
} from '../components/Organisation';
import {
  createUpdateOrg,
  createUpdateProducts,
  createUpdateThemes,
  getProductName,
} from '../components/Organisation/orgUtils';
import LogoUpload from '../components/Logo/LogoUpload';

const useStyles = makeStyles()((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  stepper: {
    background: 'none',
    paddingLeft: '5px',
  },
}));

const steps = [
  'Details',
  'Themes',
  'Logos',
  'Affiliated Users',
  'Work Roles',
  'Events',
  'Content',
];

const CreateOrg = () => {
  const [activeStep, setActiveStep] = useState(0);
  const { classes } = useStyles();
  const history = useHistory();

  const hookFormMethods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const {
    formState: { isDirty },
    reset,
    getValues,
    trigger,
    setValue,
    watch,
    handleSubmit,
  } = hookFormMethods;
  const orgId = watch('orgId');

  const [createOrgMutation, { loading: createOrgLoading }] = useMutation(
    gql(createOrg),
    {
      onCompleted: async (data) => {
        const { id, name } = data?.createOrg || {};
        setValue('orgId', id);

        successToastMessage(`Success! ${name} created.`);
      },
    },
  );

  const [allocateTasksToOrgMutation] = useMutation(gql(allocateTasksToOrg));
  const [createProductOrgMutation] = useMutation(gql(createProductOrg), {
    onCompleted: (_data) => {
      successToastMessage(
        `Success! ${getProductName(
          _data.createProductOrg?.product?.name,
        )} added to the org.`,
      );
    },
  });
  const [deleteProductOrgMutation] = useMutation(gql(deleteProductOrg), {
    onCompleted: (_data) =>
      successToastMessage(
        `${getProductName(
          _data.deleteProductOrg?.product?.name,
        )} removed from the org.`,
      ),
  });
  const [updateOrgMutation] = useMutation(gql(updateOrg), {
    refetchQueries: ['GetOrg'],
    awaitRefetchQueries: true,
  });

  const { data: orgData_, loading: getOrgLoading } = useQuery(gql(getOrg), {
    variables: {
      id: orgId,
    },
    fetchPolicy: 'network-only',
    skip: !orgId,
  });

  const [createThemeOrgMutation] = useMutation(gql(createThemeOrg), {
    refetchQueries: ['GetOrg'],
    awaitRefetchQueries: true,
  });
  const [deleteThemeOrgMutation] = useMutation(gql(deleteThemeOrg), {
    refetchQueries: ['GetOrg'],
    awaitRefetchQueries: true,
  });

  const { data: listTasksData, loading: listTasksLoading } = useQueryRecursive(
    gql(listTasks),
    {
      variables: {
        filter: {
          status: { ne: 'DRAFT' },
        },
      },
    },
  );

  const { data: listProductsData, loading: listProductsLoading } =
    useQueryRecursive(gql(listProducts));

  const { data: listThemesData, loading: listThemesLoading } = useQuery(
    gql(listThemes),
  );
  const allThemes = listThemesData?.listThemes?.items;

  if (
    listTasksLoading ||
    createOrgLoading ||
    listProductsLoading ||
    listThemesLoading ||
    getOrgLoading
  ) {
    return <FdProgress />;
  }

  const allTasks = listTasksData?.listTasks?.items?.map((task) => ({
    ...task,
    specialtyName: task?.specialty?.name,
    skills: task?.specialty?.skills?.items.map((s) => s.name),
    techniqueTags: task?.skills?.items
      .map((s) => s.techniqueTags?.items.map((st) => st.techniqueTag?.name))
      .flat(),
    technologyTags: task?.technologyTags?.items.map(
      (tt) => tt.technologyTag?.name,
    ),
    difficultyInteger: task?.difficulty,
    specialtyAreas: [
      ...new Set(
        task.competencies?.items
          ?.map((competencyData) => competencyData?.area?.areaName)
          .flat(),
      ),
    ],
    assigned: [
      ...new Set(task.orgs?.items?.map((orgData) => orgData?.org?.name).flat()),
    ],
    type: task?.modulePart !== null ? 'Labs' : 'Static',
  }));

  const allProducts = listProductsData?.listProducts?.items;

  const onSubmit = async () => {
    const values = getValues();
    const {
      orgName,
      products,
      assessorLimit,
      removedProducts,
      logos,
      pricingTier,
    } = values;
    if (activeStep === 0) {
      createUpdateOrg({
        createOrgMutation,
        updateOrgMutation,
        orgValues: { orgName, orgId, pricingTier },
      });
    }
    if (activeStep === 1) {
      const orgSelectedTheme = getValues('orgSelectedTheme');
      const removedThemes = getValues('removedThemes');
      const addedThemes = getValues('addedThemes');

      await createUpdateThemes({
        createThemeOrgMutation,
        deleteThemeOrgMutation,
        updateOrgMutation,
        orgId,
        orgSelectedTheme: allThemes.find((t) => t.name === orgSelectedTheme),
        removedThemes,
        addedThemes,
      });

      successToastMessage('Success! Theme updated');
    }
    if (activeStep === 2 && logos.length === 0) {
      errorToastMessage('No file was uploaded!');
    }
    if (activeStep === 5) {
      if (products?.length > 0) {
        const { productsAdded } = await createUpdateProducts({
          products,
          createProductOrgMutation,
          deleteProductOrgMutation,
          orgId,
          removedProducts,
          updateOrgMutation,
          assessorLimit,
          remainingInvitees: assessorLimit,
        });
        // flag records which are already submitted to db with pId
        const productsModified = products?.map((p) =>
          productsAdded.includes(p.id)
            ? { ...p, pId: shortid.generate() }
            : { ...p },
        );
        setValue('products', productsModified);
      } else {
        warningToastMessage('No Product added');
      }
    }
  };

  const validatePage = async () => {
    let result;
    switch (activeStep) {
      case 0:
        result = await trigger(['orgName', 'pricingTier']);
        break;
      case 1: {
        result = true;
        break;
      }
      case 6:
        result = await trigger(['taskIds']);
        break;
      default:
        result = true;
        break;
    }
    return result;
  };

  const handleNext = async () => {
    if (await validatePage()) {
      onSubmit();
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      scrollToTop();
    }
  };

  const handleBack = () => {
    // on click cancel of first page go back to list page
    if (activeStep === 0) {
      singleSpa.navigateToUrl('/org');
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      scrollToTop();
    }
  };

  return (
    <Box>
      <FdBreadcrumbHeader
        page={{ name: 'Create Organistation', type: 'ORGANISATION' }}
      />
      <BasePage heading="Create Organisation" data-cy="create-org-base-page">
        <Box my={2}>
          <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>

        <FormProvider {...hookFormMethods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            {
              {
                0: <OrgDetails />,
                1: (
                  <OrgThemes
                    allThemes={allThemes}
                    orgThemes={orgData_?.getOrg?.specialThemes?.items?.map(
                      (item) => item,
                    )}
                  />
                ),
                2: (
                  <LogoUpload
                    allThemes={allThemes}
                    orgThemes={orgData_?.getOrg?.specialThemes?.items?.map(
                      (item) => item,
                    )}
                    editMode={false}
                  />
                ),
                3: <OrgUsers orgId={orgId} />,
                4: <OrgWorkRoles orgId={orgId} />,
                5: <OrgProducts allProducts={allProducts} />,
                6: (
                  <OrgContent title="Assign Initial Content" data={allTasks} />
                ),
              }[activeStep]
            }
            <Box display="flex" justifyContent="space-between" pb={3}>
              <Box>
                {activeStep !== 0 && (
                  <FdButton
                    size="large"
                    variant="secondary"
                    onClick={handleBack}
                  >
                    Back
                  </FdButton>
                )}
              </Box>
              <Box>
                <FdButton
                  size="large"
                  variant="secondary"
                  onClick={() => {
                    if (!isDirty) {
                      warningToastMessage('Changes to org not saved');
                    }
                    singleSpa.navigateToUrl('/org');
                  }}
                >
                  Cancel
                </FdButton>

                {activeStep < 6 ? (
                  <FdButton
                    size="large"
                    onClick={handleNext}
                    style={{ marginLeft: '1rem' }}
                  >
                    Save and Next
                  </FdButton>
                ) : (
                  <FdButton
                    size="large"
                    onClick={async () => {
                      if (await validatePage()) {
                        allocateTasksToOrgMutation({
                          variables: {
                            orgId,
                            taskIds: getValues()?.taskIds,
                          },
                          onCompleted: () => {
                            reset();
                            singleSpa.navigateToUrl('/org');
                          },
                        });
                      }
                    }}
                    style={{ marginLeft: '1rem' }}
                  >
                    Assign and Save
                  </FdButton>
                )}
              </Box>
            </Box>
          </form>
        </FormProvider>
        <NavigationPrompt
          when={isDirty}
          afterCancel={() => {
            if (window.location.pathname !== '/org/create') {
              history.goBack();
            }
          }}
        >
          {({ onConfirm, onCancel }) => (
            <FdModal
              title={
                <Box display="flex" alignItems="center">
                  <WarningIcon
                    style={{
                      fontSize: 38,
                      color: '#C62828',
                      paddingRight: '0.5rem',
                    }}
                  />
                  <span>Cancel Org Creation?</span>
                </Box>
              }
              description={
                <Box>
                  <FdTypography variant="subtitle1">
                    Are you sure you want to cancel creating the Org?
                  </FdTypography>
                  <Box mt={2}>
                    <FdTypography variant="body1" color="secondary">
                      Any information will be lost.
                    </FdTypography>
                  </Box>
                </Box>
              }
              dismiss="CANCEL"
              confirm="OK"
              open
              onConfirm={onConfirm}
              onDismiss={onCancel}
            />
          )}
        </NavigationPrompt>
      </BasePage>
    </Box>
  );
};

export default CreateOrg;
