import React from 'react';
import { Route, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  useAuthSession,
  useSnapshot,
  globalStore,
  FdLoadingSpinner,
  Authorization,
} from '@fifthdomain/fe-shared';

const UserManagementSquadRoute = ({ component: Component, ...rest }) => {
  const { user } = useAuthSession();
  const { userType, loading, permissions } = useSnapshot(globalStore);
  const history = useHistory();

  // only user with permission manage-groups
  // view insight can also view the users
  // also manage squads
  const canManageUsersAndViewInsights =
    Authorization.canManageUsers(permissions) ||
    Authorization.canViewInsights(permissions) ||
    Authorization.canManageSquads(permissions);

  if (loading || !permissions) return <FdLoadingSpinner />;

  return (
    <Route
      {...rest}
      render={(props) => {
        if (
          user &&
          userType !== 'PARTICIPANT' &&
          canManageUsersAndViewInsights
        ) {
          return <Component {...rest} {...props} />;
        }
        return history.goBack();
      }}
    />
  );
};

UserManagementSquadRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
};

export default UserManagementSquadRoute;
