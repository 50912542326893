import React from 'react';
import PropTypes from 'prop-types';
import { ToastContainer, toast } from 'react-toastify';

const options = {
  position: 'bottom-left',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const styles = {
  color: '#000',
  boxShadow:
    '0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2)',
  borderRadius: '8px',
  fontFamily: 'Montserrat',
  width: 'max-content',
};

const Message = ({ message }) => <span className="mr-3">{message}</span>;

Message.propTypes = {
  message: PropTypes.node.isRequired,
};

const successToastMessage = (message, props) => {
  toast.success(<Message message={message} />, {
    ...options,
    style: {
      ...styles,
      backgroundColor: 'rgba(202, 247, 236, 1)',
      '--toastify-icon-color-success': '#28957B',
    },
    ...props,
  });
};

const errorToastMessage = (message, props) => {
  toast.error(<Message message={message} />, {
    ...options,
    style: {
      ...styles,
      backgroundColor: 'rgba(255, 219, 230, 1)',
      '--toastify-icon-color-error': '#C62828',
    },
    ...props,
  });
};

const warningToastMessage = (message, props) => {
  toast.warning(<Message message={message} />, {
    ...options,
    style: {
      ...styles,
      backgroundColor: 'rgba(255, 243, 224, 1)',
      '--toastify-icon-color-warning': '#EF6C00',
    },
    ...props,
  });
};

const infoToastMessage = (message, props) => {
  toast.warning(<Message message={message} />, {
    ...options,
    style: {
      ...styles,
      backgroundColor: 'rgba(235, 242, 255, 1)',
      '--toastify-icon-color-info': '#2420E2',
    },
    ...props,
  });
};

const stickyToastMessage = (
  icon,
  message,
  toastId,
  backgroundColor = '#FFE0B2',
) => {
  toast.warning(<Message message={message} />, {
    position: 'bottom-left',
    autoClose: false,
    hideProgressBar: false,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    style: {
      ...styles,
      backgroundColor,
      width: '800px',
      '--toastify-icon-color-warning': '#EF6C00',
    },
    toastId,
    icon,
  });
};

export {
  successToastMessage,
  errorToastMessage,
  warningToastMessage,
  infoToastMessage,
  stickyToastMessage,
  ToastContainer,
};
