import React, { useState } from 'react';
import {
  useParams,
  useLocation,
  useHistory,
  Link as RouterLink,
} from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { Box } from '@mui/material';
import {
  BasePage,
  FdTab,
  useQueryRecursive,
  useRecentLinks,
  useSnapshot,
  globalStore,
} from '@fifthdomain/fe-shared';
import { hoursToMinutes, isAfter, minutesToHours } from 'date-fns';
import { FdBreadcrumbHeader } from '@fifthdomain/sidebar';
import {
  getFDTrainingAssessment,
  getTasksAttemptsByAssessmentId,
} from '../queries/customQueries';
import FDTrainingStructure from '../components/Admin/FDTraining/FDTrainingStructure';
import FDTrainingParticipants from '../components/Admin/FDTraining/FDTrainingParticipants';
import FDTrainingInsights from '../components/Admin/FDTraining/FDTrainingInsights';
import {
  getSystemTime,
  listTaskAttemptAggregatesByAssessmentId,
  listTaskOpenedsByAssessmentId,
  listUsersByAssessmentId,
} from '../graphql/queries';
import {
  getDateTimeZoneFormatted,
  getDifferenceInMinutes,
} from '../shared/utils/dateUtils';
import { upperCaseFirstLetter } from '../shared/utils/stringUtils';
import { getAssessmentStatus } from '../shared/utils/taskUtils';

const ViewFDTraining = () => {
  const { assessmentId } = useParams();
  const { search, pathname } = useLocation();
  const history = useHistory();
  const tabindex = new URLSearchParams(search).get('tabindex') || 0; // defaults to structure tab
  const [custIndex, setCustIndex] = useState(tabindex);
  const [defaultInsight, setDefaultInsight] = useState('');
  const globalSnap = useSnapshot(globalStore);
  const { addRecentLink } = useRecentLinks({ userId: globalSnap.userId });

  const setRouteIndex = (indexValue, _defaultInsight) => {
    setCustIndex(indexValue);
    setDefaultInsight(_defaultInsight);
    history.push({ search: `tabindex=${indexValue}` });
  };
  const { data: assessmentData, loading: assessmentDataLoading } = useQuery(
    gql(getFDTrainingAssessment),
    {
      variables: {
        id: assessmentId,
      },
      onCompleted: (_data) => {
        const trainingName = _data?.getAssessment?.name || '';
        // add recent link
        addRecentLink({
          id: assessmentId,
          name: trainingName,
          type: 'TRAINING',
          url: pathname + search,
          role: 'MANAGE',
        });
      },
    },
  );
  const {
    data: listTaskAttemptsAggregateData,
    loading: listTaskAttemptsAggregateDataLoading,
  } = useQueryRecursive(gql(listTaskAttemptAggregatesByAssessmentId), {
    variables: {
      assessmentId,
      limit: 1000,
    },
  });
  const { data: serverTime } = useQuery(gql(getSystemTime));

  const { data: listUsersData, loading: listUsersLoading } = useQueryRecursive(
    gql(listUsersByAssessmentId),
    {
      variables: {
        userAssessmentAssessmentId: assessmentId,
        limit: 1000,
      },
    },
  );

  const { data: tasksAttemptsAssessmentData } = useQueryRecursive(
    gql(getTasksAttemptsByAssessmentId),
    {
      variables: {
        assessmentId,
        limit: 1000,
      },
    },
  );

  const { data: tasksOpenedData } = useQueryRecursive(
    gql(listTaskOpenedsByAssessmentId),
    {
      variables: {
        assessmentId,
        limit: 1000,
      },
    },
  );
  const tasksOpened =
    tasksOpenedData?.listTaskOpenedsByAssessmentId?.items || [];
  const { name, tasks, startDateTime, endDateTime } =
    assessmentData?.getAssessment ?? {};
  const status = getAssessmentStatus(
    startDateTime,
    endDateTime,
    serverTime?.getSystemTime,
  );
  const participantRows = listUsersData?.listUsersByAssessmentId?.items?.map(
    (userAssessment) => {
      const getStatus = (userStatus, assessmentStatus) => {
        if (userStatus === 'NOT_STARTED') return 'NOT STARTED';
        if (userStatus === 'STARTED' && assessmentStatus === 'Ended')
          return 'NOT COMPLETED';
        return userStatus;
      };

      const assessmentDuration =
        hoursToMinutes(userAssessment?.assessment?.hours) +
        Number(userAssessment?.assessment?.minutes);

      const participantDuration = getDifferenceInMinutes(
        userAssessment.finishedOn
          ? new Date(userAssessment.finishedOn)
          : new Date(serverTime?.getSystemTime),
        userAssessment.startedOn
          ? new Date(userAssessment.startedOn)
          : new Date(serverTime?.getSystemTime),
      );
      return {
        id: userAssessment.id,
        userId: userAssessment.userId,
        name: userAssessment?.user?.name,
        email: userAssessment?.user?.email,
        participantType: upperCaseFirstLetter(
          userAssessment?.user?.participantType,
        ),
        status: getStatus(userAssessment.status, status),
        started: userAssessment.startedOn
          ? getDateTimeZoneFormatted(userAssessment.startedOn, true)
          : '-',
        duration: isAfter(participantDuration, assessmentDuration)
          ? assessmentDuration
          : participantDuration,
        startedOn: userAssessment.startedOn,
        hours: isAfter(participantDuration, assessmentDuration)
          ? userAssessment?.assessment?.hours
          : minutesToHours(participantDuration),

        minutes: isAfter(participantDuration, assessmentDuration)
          ? userAssessment?.assessment?.minutes
          : participantDuration % 60,
      };
    },
  );

  const timeSpentDataForAssessment =
    participantRows?.map((p) => ({
      userId: p.userId,
      duration: p.duration,
    })) || [];
  const loading =
    assessmentDataLoading ||
    listTaskAttemptsAggregateDataLoading ||
    listUsersLoading;

  return (
    <Box>
      <FdBreadcrumbHeader page={{ name, type: 'TRAINING' }} />
      <BasePage
        heading={`View ${name}`}
        data-cy="view-fd-training"
        loading={loading}
      >
        <FdTab
          label={[
            {
              label: 'Structure',
              tabRoute: `/assessor/fd-training/view/${assessmentId}?tabindex=0`,
              index: 0,
              data: <FDTrainingStructure tasks={tasks} />,
            },
            {
              label: 'Participants',
              tabRoute: `/assessor/fd-training/view/${assessmentId}?tabindex=1`,
              index: 1,
              data: (
                <FDTrainingParticipants
                  assessmentId={assessmentId}
                  setRouteIndex={setRouteIndex}
                />
              ),
            },
            {
              label: 'Insights',
              tabRoute: `/assessor/fd-training/view/${assessmentId}?tabindex=2`,
              index: 2,
              data: (
                <FDTrainingInsights
                  attemptsData={listTaskAttemptsAggregateData}
                  tasks={tasks}
                  tasksOpened={tasksOpened}
                  timeSpentDataForAssessment={timeSpentDataForAssessment}
                  listUsersData={listUsersData}
                  tasksAttemptsAssessmentData={
                    tasksAttemptsAssessmentData?.listTaskAttempts?.items
                  }
                  defaultInsight={defaultInsight}
                  startDateTime={startDateTime}
                />
              ),
            },
          ]}
          index={parseInt(tabindex, 10)}
          custIndex={parseInt(custIndex, 10)}
          setCustIndex={setRouteIndex}
          tabLinkComponent={RouterLink}
        />
      </BasePage>
    </Box>
  );
};
export default ViewFDTraining;
