import React from 'react';
import { useParams } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQuery, gql } from '@apollo/client';
import { Box } from '@mui/material';
import { BasePage, FdLoadingSpinner, FdTab } from '@fifthdomain/fe-shared';
import { FdBreadcrumbHeader } from '@fifthdomain/sidebar';
import {
  TemplateChallenges,
  TemplateDetails,
} from '../components/Template/View';
import { getTemplate } from '../graphql/queries';
import {
  initialValues,
  validationSchema,
} from '../validation-schemas/Template';
import { TEMPLATE_TYPES } from '../constants';

const ViewTemplate = () => {
  const { templateType, templateId } = useParams();

  const reactHookFormMethods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });
  const {
    reset,
    getValues,
    formState: { isDirty },
  } = reactHookFormMethods;

  // Get Template
  const { loading: templateDataLoading, refetch: refetchTemplateData } =
    useQuery(gql(getTemplate), {
      variables: {
        id: templateId,
      },
      fetchPolicy: 'cache-and-network',
      onCompleted: (data) => {
        if (!isDirty) {
          reset({
            ...data?.getTemplate,
            templateType: data.getTemplate?.teamBased
              ? { id: `${templateType}Type`, value: TEMPLATE_TYPES.TEAM_BASED }
              : {
                  id: `${templateType}Type`,
                  value: TEMPLATE_TYPES.INDIVIDUAL_BASED,
                },
            tasks: data.getTemplate?.tasks.items?.map((task) => ({
              taskId: task?.taskId,
            })),
            taskTemplates: data.getTemplate?.tasks.items?.map((task) => ({
              taskId: task?.taskId,
              tasktemplateId: task?.id,
              templateId: task?.templateId,
            })),
          });
        }
      },
    });

  if (templateDataLoading) {
    return <FdLoadingSpinner />;
  }
  const heading = getValues('name');

  return (
    <Box>
      <FdBreadcrumbHeader
        entries={[
          {
            name: 'Template Library',
            path: '/org/template-library',
            type: 'TEMPLATE',
          },
        ]}
        page={{ name: heading, type: 'TEMPLATE' }}
      />
      <BasePage heading={heading} data-cy="view-template">
        <FormProvider {...reactHookFormMethods}>
          <form>
            <FdTab
              label={[
                {
                  label: 'Details',
                  tabRoute: `/assessor/view/${templateId}?tabindex=3`,
                  index: 3,
                  data: <TemplateDetails />,
                },
                {
                  label: 'Challenges',
                  tabRoute: `/assessor/view/${templateId}?tabindex=4`,
                  index: 4,
                  data: (
                    <TemplateChallenges refetchTemplate={refetchTemplateData} />
                  ),
                },
              ]}
            />
          </form>
        </FormProvider>
      </BasePage>
    </Box>
  );
};

export default ViewTemplate;
