/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onUpdateAssessment = /* GraphQL */ `
  subscription OnUpdateAssessment {
    onUpdateAssessment {
      endDateTime
    }
  }
`;

export const onCreateTaskActivity = /* GraphQL */ `
  subscription OnCreateTaskActivity(
    $filter: ModelSubscriptionTaskActivityFilterInput
  ) {
    onCreateTaskActivity(filter: $filter) {
      taskId
      userId
      teamId
      activity
      assessmentId
    }
  }
`;
export const onUpdateTaskActivity = /* GraphQL */ `
  subscription OnUpdateTaskActivity(
    $filter: ModelSubscriptionTaskActivityFilterInput
  ) {
    onUpdateTaskActivity(filter: $filter) {
      taskId
      userId
      teamId
      activity
      assessmentId
    }
  }
`;

export const onCreateNewTaskAttempt = /* GraphQL */ `
  subscription OnCreateNewTaskAttempt($assessmentId: ID!, $teamId: ID) {
    onCreateNewTaskAttempt(assessmentId: $assessmentId, teamId: $teamId) {
      taskId
      userAssessmentId
      userId
      user {
        id
      }
      teamId
      success
    }
  }
`;
export const onStopGroupAssessment = /* GraphQL */ `
  subscription OnStopGroupAssessment($groupId: ID!, $assessmentId: ID!) {
    onStopGroupAssessment(groupId: $groupId, assessmentId: $assessmentId) {
      groupId
      assessmentId
    }
  }
`;
export const onStartGroupAssessment = /* GraphQL */ `
  subscription OnStartGroupAssessment($groupId: ID!, $assessmentId: ID!) {
    onStartGroupAssessment(groupId: $groupId, assessmentId: $assessmentId) {
      groupId
      assessmentId
    }
  }
`;
export const onCreateAnnouncement = /* GraphQL */ `
  subscription OnCreateAnnouncement(
    $filter: ModelSubscriptionAnnouncementFilterInput
  ) {
    onCreateAnnouncement(filter: $filter) {
      id
      assessmentId
      assessment {
        name
        status
        users {
          items {
            userId
          }
        }
        teams {
          items {
            team {
              members {
                items {
                  userId
                }
              }
            }
          }
        }
      }
      userId
    }
  }
`;

export const onCreateChatForum = /* GraphQL */ `
  subscription OnCreateChatForum(
    $filter: ModelSubscriptionChatForumFilterInput
  ) {
    onCreateChatForum(filter: $filter) {
      id
      assessmentId
      userId
      role
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateChatForum = /* GraphQL */ `
  subscription OnUpdateChatForum(
    $filter: ModelSubscriptionChatForumFilterInput
  ) {
    onUpdateChatForum(filter: $filter) {
      id
      assessmentId
      userId
      role
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteChatForum = /* GraphQL */ `
  subscription OnDeleteChatForum(
    $filter: ModelSubscriptionChatForumFilterInput
  ) {
    onDeleteChatForum(filter: $filter) {
      id
      assessmentId
      userId
      role
      createdAt
      updatedAt
    }
  }
`;
export const onCreateChallengeChat = /* GraphQL */ `
  subscription OnCreateChallengeChat(
    $filter: ModelSubscriptionChallengeChatFilterInput
  ) {
    onCreateChallengeChat(filter: $filter) {
      id
      assessmentId
      userId
      teamId
      challengeId
      messages {
        type
        content
      }
      assessment {
        name
      }
      challenge {
        name
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateChallengeChat = /* GraphQL */ `
  subscription OnUpdateChallengeChat(
    $filter: ModelSubscriptionChallengeChatFilterInput
  ) {
    onUpdateChallengeChat(filter: $filter) {
      id
      assessmentId
      userId
      teamId
      challengeId
      messages {
        type
        content
      }
      assessment {
        name
      }
      challenge {
        name
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteChallengeChat = /* GraphQL */ `
  subscription OnDeleteChallengeChat(
    $filter: ModelSubscriptionChallengeChatFilterInput
  ) {
    onDeleteChallengeChat(filter: $filter) {
      id
      assessmentId
      userId
      teamId
      challengeId
      createdAt
      updatedAt
    }
  }
`;