import {
  differenceInMilliseconds,
  differenceInSeconds,
  addSeconds,
  format,
  isValid,
} from 'date-fns';

// get expired status for started/non-started ones as per today
export const getCompletedStatus = (
  _status,
  _endDateTime,
  contentType,
  serverTime,
) => {
  const isNotExpired =
    differenceInMilliseconds(
      new Date(_endDateTime),
      new Date(serverTime?.getSystemTime),
    ) > 0;
  return isNotExpired
    ? _status
    : contentType === 'competition'
      ? 'ENDED'
      : 'NOT_COMPLETED';
};

export const filterBySkill = (_tasks, _skill) =>
  _tasks?.filter(({ task }) =>
    task?.skills?.items?.map(({ skill }) => skill?.name).includes(_skill),
  );

export const formatDuration = (
  seconds,
  isStart = false,
  timeInFull = false,
) => {
  if (isStart) {
    return '0m';
  }
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;
  const formattedDuration = [];
  if (hours > 0) {
    formattedDuration.push(`${hours}${timeInFull ? ' hours' : 'h'}`);
  }
  if (minutes > 0) {
    formattedDuration.push(`${minutes}${timeInFull ? ' minutes' : 'm'}`);
  }
  if (hours === 0 && remainingSeconds > 0) {
    formattedDuration.push(
      `${remainingSeconds}${timeInFull ? ' seconds' : 's'}`,
    );
  }
  return formattedDuration.join(' ');
};

export const getSlotEndTime = (timeline, index) => {
  return index < timeline.length - 1
    ? timeline[index + 1].time
    : timeline[index].time;
};

export const generateTimeline = (
  startDateTime,
  endDateTime,
  eventStartDateTime,
) => {
  const totalDurationInSeconds = Math.abs(
    differenceInSeconds(new Date(endDateTime), new Date(startDateTime)),
  );

  // Calculate the time interval dynamically to get exactly 10 units
  const timeIntervalInSeconds = totalDurationInSeconds / 9; // 9 intervals will create 10 units

  const timeline = [];
  let currentDateTime = new Date(startDateTime);
  for (let i = 0; i < 10; i += 1) {
    timeline.push(currentDateTime);
    currentDateTime = addSeconds(currentDateTime, timeIntervalInSeconds);
  }

  const timeLineFormatted = timeline.map((date, index) => ({
    time: date,
    timeFormatted: isValid(date) ? format(date, 'dd/MM - HH:mm:ss') : '',
    duration: formatDuration(
      differenceInSeconds(date, new Date(eventStartDateTime)),
      index === 0,
    ),
  }));
  return timeLineFormatted?.map((tl, index) => ({
    ...tl,
    slotStart: tl.time,
    slotEnd: getSlotEndTime(timeLineFormatted, index),
  }));
};

export const calculatePercentagePositionWithinSlot = (
  date,
  slotStart,
  slotEnd,
) => {
  const slotDuration = differenceInSeconds(slotEnd, slotStart);
  const elapsedInSlot = differenceInSeconds(date, slotStart);
  return (elapsedInSlot / slotDuration) * 100;
};

export const getElapsedTime = (dateTime, minDateTime) => {
  const givenTimeInSeconds = differenceInSeconds(
    new Date(dateTime),
    new Date(minDateTime),
  );
  return formatDuration(givenTimeInSeconds, false, true);
};

export const getAssessmentStatusColor = (status) => {
  switch (status) {
    case 'In Progress':
      return 'action';
    case 'Finished':
    case 'Ended':
      return 'error';
    case 'Not Started':
      return 'warning';
    default:
      return 'success';
  }
};
