import React, { useState } from 'react';
import _ from 'lodash';
import { Box, Stepper, Step, StepLabel, Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import * as singleSpa from 'single-spa';
import { useHistory, useParams } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, gql } from '@apollo/client';
import NavigationPrompt from 'react-router-navigation-prompt';
import {
  FdButton,
  BasePage,
  BasePageButtonContainer,
  FdAlert,
  FdModal,
  useQueryRecursive,
  useSnapshot,
  globalStore,
  successToastMessage,
  warningToastMessage,
} from '@fifthdomain/fe-shared';
import { FdBreadcrumbHeader } from '@fifthdomain/sidebar';
import {
  TemplateType,
  Details,
  Duration,
  ScoreboardVisibility,
  Availability,
  TemplateOverview,
  TasksTable,
  PreMessage,
  PostMessage,
  ViewTaskDrawer,
} from '../components/Template/Create';
import { listTasks } from '../graphql/queries';
import { createTemplate, createTaskTemplate } from '../graphql/mutations';
import scrollToTop from '../shared/utils/scroll';
import { capitalize } from '../shared/utils/stringUtils';

import {
  validationSchema,
  initialValues,
} from '../validation-schemas/Template';
import { TEMPLATE_TYPES } from '../constants';
import {
  preAssessmentMessage,
  preCompetitionMessage,
  postAssessmentMessage,
  postCompetitionMessage,
} from '../components/Template/Create/MessageDefault';

const drawerWidth = 400;
const useStyles = makeStyles()((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  stepper: {
    background: 'none',
    paddingLeft: '5px',
  },
}));

const steps = ['Details', 'Challenges', 'Messaging'];

const CreateTemplate = () => {
  const { templateType } = useParams();
  const globalSnap = useSnapshot(globalStore);
  const [activeStep, setActiveStep] = useState(0);
  const history = useHistory();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [activeTask, setActiveTask] = useState();

  const [createTemplateMutation, { loading: createTemplateLoading }] =
    useMutation(gql(createTemplate));
  const [createTaskTemplateMutation, { loading: createTaskTemplateLoading }] =
    useMutation(gql(createTaskTemplate));
  const { data: listTasksData } = useQueryRecursive(gql(listTasks), {
    variables: {
      filter: {
        status: { ne: 'DRAFT' },
      },
    },
  });

  const allTasks = _.uniqBy(
    [
      ...(listTasksData?.listTasks?.items?.map((t) => ({
        ...t,
        owned: false,
      })) || []),
    ],
    'id',
  ).filter((_task) => _task.type !== 'CONTAINER');

  const { classes } = useStyles();

  const reactHookFormMethods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });
  const {
    formState: { errors, isDirty },
    reset,
    setValue,
    getValues,
    trigger,
    watch,
    handleSubmit,
  } = reactHookFormMethods;

  const watchTasks = watch('tasks');

  const validatePage = async () => {
    let result;
    switch (activeStep) {
      case 0: {
        setValue('hours', String(getValues('hours')), { shouldTouch: true });
        const assessmentTemplate = templateType === 'assessment';
        setValue(
          'preMessage',
          assessmentTemplate ? preAssessmentMessage : preCompetitionMessage,
        );
        setValue(
          'postMessage',
          assessmentTemplate ? postAssessmentMessage : postCompetitionMessage,
        );
        const triggerValue =
          templateType === 'competition'
            ? ['templateType', 'name', 'description']
            : [
                'templateType',
                'name',
                'description',
                'hours',
                'minutes',
                'duration',
              ];
        result = await trigger(triggerValue);
        break;
      }
      case 1: {
        result = await trigger(['tasks']);
        if (result) {
          // show challenges toast on success
          const _tasks = getValues('tasks');
          successToastMessage(
            <Box>
              <Typography variant="subtitle1">
                {`${_tasks.length} challenges added to the template.`}
              </Typography>
            </Box>,
          );
        }
        break;
      }
      case 2:
        result = await trigger(['preMessage', 'postMessage']);
        break;
      default:
        break;
    }
    return result;
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    scrollToTop();
  };

  const tableData =
    allTasks
      ?.map((task) => ({
        ...task,
        difficultyInteger: task?.difficulty,
        specialtyName: task?.specialty?.name,
        skills: task?.skills?.items.map((s) => s?.skill?.name),
        techniqueTags: task?.skills?.items
          .map((s) => s.techniqueTags?.items.map((st) => st.techniqueTag?.name))
          .flat(),
        technologyTags: task?.technologyTags?.items.map(
          (t) => t.technologyTag?.name,
        ),
        creator: task?.user?.name,
        ownerOrg: task?.org?.name,
        ownerOrgId: task?.org?.id,
        creatorOrg: task?.user?.org?.name,
        creatorOrgId: task?.user?.org?.id,
        tags: task?.tags?.items || [],
      }))
      .filter((td) => td.id) || [];

  const taskIds = watchTasks?.map((t) => t.taskId) || [];

  const onSubmit = async () => {
    const values = getValues();
    const participantEventType = templateType.toUpperCase();
    const teamBased = values?.templateType?.value === TEMPLATE_TYPES.TEAM_BASED;

    createTemplateMutation({
      variables: {
        input: {
          name: values?.name,
          participantEventType,
          teamBased,
          description: values?.description,
          hours: values?.hours,
          minutes: values?.minutes,
          preMessage: values?.preMessage,
          postMessage: values?.postMessage,
          status: 'UNRELEASED',
          createdBy: globalSnap?.userId,
        },
      },
      onCompleted: async (data) => {
        const templateId = data?.createTemplate?.id;
        await Promise.all(
          taskIds.map(async (taskId) => {
            return new Promise((resolve, reject) => {
              createTaskTemplateMutation({
                variables: {
                  input: {
                    templateId,
                    taskId,
                  },
                },
                onCompleted: () => {
                  resolve();
                  successToastMessage(
                    `Success! ${templateType} template created`,
                  );
                  reset();
                  singleSpa.navigateToUrl('/org/template-library');
                },
                onError: () => reject(),
              });
            });
          }),
        );
      },
    });
  };

  const handleNext = async () => {
    if (await validatePage()) {
      if (activeStep === 2) {
        onSubmit();
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        scrollToTop();
      }
    }
  };

  const BackButton = () => (
    <FdButton size="large" variant="secondary" onClick={handleBack}>
      Back
    </FdButton>
  );
  const heading = `Create ${capitalize(templateType)} Template`;

  return (
    <Box>
      <FdBreadcrumbHeader
        entries={[
          {
            name: 'Template Library',
            path: '/org/template-library',
            type: 'TEMPLATE',
          },
        ]}
        page={{ name: heading, type: 'TEMPLATE' }}
      />
      <BasePage heading={heading} data-cy="create-template-base-page">
        <Box width="834px">
          <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        <FormProvider {...reactHookFormMethods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            {
              {
                0: (
                  <Box>
                    <TemplateType templateType={templateType} />
                    <Details templateType={templateType} />
                    <Availability />
                    {templateType === 'assessment' && <Duration />}
                    {templateType === 'competition' && <ScoreboardVisibility />}
                  </Box>
                ),
                1: (
                  <>
                    <Box>
                      <Grid container>
                        <Grid item xs>
                          <TemplateOverview
                            allTasks={tableData}
                            tasks={watchTasks}
                          />

                          {errors?.tasks && (
                            <Box mt={2}>
                              <FdAlert
                                variant="error"
                                message="Please select challenges(s) for this template"
                              />
                            </Box>
                          )}

                          <TasksTable
                            data={tableData}
                            setOpenDrawer={setOpenDrawer}
                            setActiveTask={setActiveTask}
                            editTasks
                          />
                        </Grid>
                        <Grid
                          item
                          style={{
                            width: openDrawer ? drawerWidth : 0,
                          }}
                        />
                      </Grid>
                    </Box>
                    {activeTask && openDrawer && (
                      <ViewTaskDrawer
                        activeTaskData={activeTask}
                        openDrawer={openDrawer}
                        openDrawerCallBack={setOpenDrawer}
                        mainPageIds={['topnav', 'organisation']}
                      />
                    )}
                  </>
                ),
                2: (
                  <Box>
                    <PreMessage templateType={templateType} />
                    <PostMessage templateType={templateType} />
                  </Box>
                ),
              }[activeStep]
            }
            <BasePageButtonContainer>
              <Box
                display="flex"
                justifyContent={activeStep === 2 ? 'space-between' : 'flex-end'}
                width="100%"
              >
                <Box>
                  <FdButton
                    size="large"
                    variant="tertiary"
                    onClick={() => {
                      if (!isDirty) {
                        warningToastMessage(
                          'Changes to Templates are not saved',
                        );
                      }
                      singleSpa.navigateToUrl('/org/template-library');
                    }}
                  >
                    Cancel
                  </FdButton>
                  {activeStep !== 0 && activeStep < 3 && <BackButton />}
                  <FdButton
                    size="large"
                    onClick={handleNext}
                    disabled={
                      createTemplateLoading || createTaskTemplateLoading
                    }
                  >
                    {createTemplateLoading || createTaskTemplateLoading
                      ? 'Loading...'
                      : activeStep === steps.length - 1
                        ? 'Create'
                        : 'Next'}
                  </FdButton>
                </Box>
              </Box>
            </BasePageButtonContainer>
          </form>
        </FormProvider>
        <NavigationPrompt
          when={isDirty}
          afterCancel={() => {
            if (window.location.pathname !== '/competitions/create') {
              history.goBack();
            }
          }}
          afterConfirm={() =>
            warningToastMessage('Changes to template are not saved')
          }
        >
          {({ onConfirm, onCancel }) => (
            <FdModal
              title="Abandon Template Creation?"
              description="Are you sure that you want to abandon creating this template? Any inputs made during the process will be lost. "
              confirm="Confirm"
              dismiss="Cancel"
              open
              onConfirm={onConfirm}
              onDismiss={onCancel}
            />
          )}
        </NavigationPrompt>
      </BasePage>
    </Box>
  );
};

export default CreateTemplate;
