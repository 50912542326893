import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider, StyledEngineProvider } from '@mui/material';
import { useAppTheme, AppThemeContext } from '@fifthdomain/fe-shared';

const AppThemeProvider = ({ children }) => {
  const theme = useAppTheme();

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <AppThemeContext.Provider value={theme}>
          {children}
        </AppThemeContext.Provider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

AppThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppThemeProvider;
