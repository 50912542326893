import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { globalStore, useSnapshot } from '@fifthdomain/fe-shared';

const AuthRoute = ({ component: Component, ...rest }) => {
  const globalSnap = useSnapshot(globalStore);

  if (globalSnap.loading || !globalSnap.orgId) {
    return null;
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        if (globalSnap?.userId) {
          return <Component {...rest} {...props} />;
        }
        return <Redirect to="/landing" />;
      }}
    />
  );
};

AuthRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
};

export default AuthRoute;
