import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import _ from 'lodash';
import { useFormContext } from 'react-hook-form';
import { FdModal, FdTypography, FdChips, FdChip } from '@fifthdomain/fe-shared';
import ContentWithLabel from './ContentWithLabel';
import {
  SPECIALTY_COLOR,
  PROFICIENCY,
  PROFICIENCY_LEVELS,
} from '../../../constants';
import { getDateTimeZoneFormatted } from '../../../shared/utils/dateUtils';

const FinaliseModal = ({
  finalStep,
  showCloseAction,
  onDismiss,
  onConfirm,
  loading,
}) => {
  const { getValues } = useFormContext();
  const { name, description, trainingStartDate, trainingEndDate, tasks } =
    getValues();
  const minProficiencyValue = _.minBy(tasks, 'difficulty')?.difficulty;
  const maxProficiencyValue = _.maxBy(tasks, 'difficulty')?.difficulty;

  return (
    <FdModal
      size="lg"
      title="Great! This template is almost ready."
      description="After reviewing the details below, you can choose to either Save and Release the template immediately or Save as Draft to release it at a later time."
      confirm="Save and release"
      disableConfirm={loading}
      dismiss="Save as draft"
      disableDismiss={loading}
      showCloseIcon
      showCloseAction={() => {
        showCloseAction();
      }}
      open={finalStep}
      onConfirm={() => {
        onConfirm();
      }}
      onDismiss={() => {
        onDismiss();
      }}
    >
      <Box display="flex" flexDirection="column" width="100%">
        <ContentWithLabel label="Training Name" content={name} />
        <ContentWithLabel label="Training Description" content={description} />
        <ContentWithLabel
          label="Start"
          content={getDateTimeZoneFormatted(trainingStartDate, 'AEDT')}
        />
        <ContentWithLabel
          label="End"
          content={getDateTimeZoneFormatted(trainingEndDate, 'AEDT')}
        />
        <Box my={3}>
          <FdTypography variant="h4">{`Challenges (${tasks?.length})`}</FdTypography>
          <Box className="flex" my={1}>
            <FdTypography variant="subtitle2">Proficiency Range :</FdTypography>
            <Box ml={1.5}>
              <FdChip
                label={PROFICIENCY_LEVELS[minProficiencyValue]}
                size="small"
                style={{
                  backgroundColor:
                    PROFICIENCY[PROFICIENCY_LEVELS[minProficiencyValue]]
                      ?.color || '',
                }}
              />
            </Box>
            {minProficiencyValue !== maxProficiencyValue && (
              <>
                <Box mx={1}>
                  <FdTypography variant="subtitle2">to</FdTypography>
                </Box>
                <FdChip
                  label={PROFICIENCY_LEVELS[maxProficiencyValue]}
                  size="small"
                  style={{
                    backgroundColor:
                      PROFICIENCY[PROFICIENCY_LEVELS[maxProficiencyValue]]
                        ?.color || '',
                  }}
                />
              </>
            )}
          </Box>
          <Box className="flex">
            <FdTypography variant="subtitle2">
              Specialties Covered :
            </FdTypography>
            <Box mx={1.5}>
              <FdChips
                data={[...new Set(tasks?.map((t) => t?.specialtyName))]?.map(
                  (t) => ({
                    label: t,
                    color: SPECIALTY_COLOR[t] || '',
                  }),
                )}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </FdModal>
  );
};

FinaliseModal.propTypes = {
  finalStep: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  showCloseAction: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

export default FinaliseModal;
