import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Box } from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory } from 'react-router-dom';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import { useQuery, gql, useMutation } from '@apollo/client';
import NavigationPrompt from 'react-router-navigation-prompt';
import {
  FdModal,
  FdProgress,
  FdButton,
  FdTypography,
  useSnapshot,
  globalStore,
  warningToastMessage,
  successToastMessage,
} from '@fifthdomain/fe-shared';
import QuizDetails from '../QuizDetails';
import {
  initialValuesDetails,
  validationSchemaDetails,
} from '../../../validation-schemas/Quizzes';
import { getQuiz } from '../../../graphql/queries';
import { updateQuiz } from '../../../graphql/mutations';
import { formatMinutes } from '../../../shared/utils/dateUtils';

const ViewQuizDetails = ({ quizData, onModeChange }) => {
  const [viewMode, setViewMode] = useState(true);
  const history = useHistory();
  const quizId = quizData?.id;
  const validationSchema = Yup.object().shape(validationSchemaDetails);

  const reactHookFormMethods = useForm({
    defaultValues: initialValuesDetails,
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });
  const {
    reset,
    trigger,
    getValues,
    formState: { isDirty },
  } = reactHookFormMethods;

  const { orgId, userId } = useSnapshot(globalStore);

  // Get Quiz details
  const { loading: quizDataLoading } = useQuery(gql(getQuiz), {
    variables: {
      id: quizId,
    },
    onCompleted: async (_data) => {
      const {
        name,
        description,
        duration,
        multipleAttempts,
        showResults,
        graded,
      } = _data?.getQuiz || {};
      reset({
        quizId,
        quizName: name,
        quizDescription: description,
        quizTimeLimit: duration,
        allowMultipleAttempts: multipleAttempts,
        returnResultsToUsers: showResults,
        notGradeUsers: !graded,
      });
    },
    skip: !quizId,
  });

  const [updateQuizMutation, { loading: updateQuizLoading }] = useMutation(
    gql(updateQuiz),
  );

  const totalPoints = quizData?.questions?.items.reduce(
    (acc, cv) => acc + cv.point,
    0,
  );

  if (quizDataLoading || updateQuizLoading) return <FdProgress />;

  const ActionButtons = () => (
    <Box display="flex">
      {!viewMode ? (
        <Box display="flex">
          <FdButton
            variant="secondary"
            onMouseDown={() => {
              reset();
              setViewMode(true);
              onModeChange(true);
              warningToastMessage('Quiz not saved');
            }}
            data-cy="cancel-button"
          >
            Cancel
          </FdButton>
          <Box ml={1}>
            <FdButton
              onMouseDown={async () => {
                if (await trigger()) {
                  const {
                    quizName,
                    quizDescription,
                    quizTimeLimit,
                    allowMultipleAttempts,
                    returnResultsToUsers,
                  } = getValues();
                  // edit quiz
                  await updateQuizMutation({
                    variables: {
                      input: {
                        id: quizId,
                        description: quizDescription,
                        duration: Number(quizTimeLimit),
                        multipleAttempts: allowMultipleAttempts,
                        name: quizName,
                        orgId,
                        ownerId: userId,
                        showResults: returnResultsToUsers,
                      },
                    },
                    onCompleted: async (_quizData) => {
                      // show toast and change to view mode
                      successToastMessage('Success! Quiz updated');
                      setViewMode(true);
                      onModeChange(true);
                    },
                  });
                }
              }}
              data-cy="save-button"
            >
              Save
            </FdButton>
          </Box>
        </Box>
      ) : (
        <FdButton
          onClick={() => {
            setViewMode(false);
            onModeChange(false);
          }}
          data-cy="edit-button"
        >
          Edit
        </FdButton>
      )}
    </Box>
  );

  return (
    <Box>
      <FormProvider {...reactHookFormMethods}>
        <form>
          <QuizDetails
            viewMode={viewMode}
            ActionButtons={ActionButtons}
            summary={
              <Box mr={2} display="flex">
                <Box display="flex" mr={2}>
                  <AvTimerIcon />
                  <Box ml={1}>
                    <FdTypography variant="subtitle1">
                      {formatMinutes(quizData?.duration)}
                    </FdTypography>
                  </Box>
                </Box>
                {quizData?.graded && (
                  <Box display="flex">
                    <FdTypography variant="subtitle1">
                      Total Points:
                    </FdTypography>
                    <Box ml={1}>
                      <FdTypography variant="subtitle1">
                        {totalPoints}
                      </FdTypography>
                    </Box>
                  </Box>
                )}
              </Box>
            }
          />
        </form>
      </FormProvider>
      <NavigationPrompt
        when={isDirty}
        afterCancel={() => {
          if (
            window.location.pathname !==
            `/labs/courses/view/${quizId}?tabindex=0`
          ) {
            history.goBack();
          }
        }}
      >
        {({ onConfirm, onCancel }) => (
          <FdModal
            title="Are you sure you want to leave?"
            description="You have unsaved changes. Click the Stay button to go back to the form and save your changes."
            confirm="Stay"
            dismiss="Leave"
            open
            onConfirm={onCancel}
            onDismiss={onConfirm}
            data-cy="leave-modal"
          />
        )}
      </NavigationPrompt>
    </Box>
  );
};

ViewQuizDetails.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  quizData: PropTypes.object.isRequired,
  onModeChange: PropTypes.func.isRequired,
};

export default ViewQuizDetails;
