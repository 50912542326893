import React from 'react';
import StylesProvider from '@mui/styles/StylesProvider';
import createGenerateClassName from '@mui/styles/createGenerateClassName';
import { BrowserRouter, Switch, Redirect, Route } from 'react-router-dom';
import { AppBasePageProvider } from '@fifthdomain/fe-shared';
import ProtectedRoute from './routes/ProtectedRoute';
import CreateAssessment from './pages/CreateAssessment';
import ViewAssessment from './pages/ViewAssessment';
import AssessmentStart from './pages/AssessmentStart';
import AssessmentOverview from './pages/AssessmentOverview';
import AssessmentTasks from './pages/AssessmentTasks';
import AssessmentComplete from './pages/AssessmentComplete';
import AssessmentScoreboard from './pages/AssessmentScoreboard';
import LabVDI from './pages/LabVDI';
import Error from './pages/Error';
import ParticipantRoute from './routes/ParticipantRoute';
import AppThemeProvider from './providers/AppThemeProvider';
import AppStoreInitializer from './providers/AppStoreInitializer';
import AssessmentScoreboardDetail from './pages/AssessmentScoreboardDetail';
import SkillsOverview from './pages/SkillsOverview';
import ViewTeam from './pages/ViewTeam';
import AffiliatedUserProfile from './pages/AffiliatedUserProfile';
import NonAffiliatedUserProfile from './pages/NonAffiliatedUserProfile';
import AuthRoute from './routes/AuthRoute';
import AssessmentAnnouncements from './pages/AssessmentAnnouncements';
import SubscriptionProvider from './providers/SubscriptionProvider';
import AssessmentChatForum from './pages/AssessmentChatForum';
import CreateTraining from './pages/CreateTraining';
import ViewTraining from './pages/ViewTraining';
import TrainingStart from './pages/TrainingStart';
import TrainingComplete from './pages/TrainingComplete';
import TrainingOverview from './pages/TrainingOverview';
import TrophyRoom from './pages/TrophyRoom';
import GraphQlClient from './shared/GraphQlClient';
import './competitions.css';

const generateClassName = createGenerateClassName({
  productionPrefix: 'competitions',
  seed: 'competitions',
});

const App = () => {
  return (
    <GraphQlClient>
      <AppThemeProvider>
        <StylesProvider generateClassName={generateClassName}>
          <AppStoreInitializer>
            <AppBasePageProvider>
              <SubscriptionProvider>
                <BrowserRouter>
                  <Switch>
                    <ParticipantRoute
                      path="/competitions/comp/:assessmentId/org/:orgId"
                      component={AssessmentStart}
                    />
                    {/* <SurveyRoute path="/competitions/survey" /> */}
                    <ParticipantRoute
                      path="/competitions/competition-start/:assessmentId"
                      component={AssessmentStart}
                    />
                    <ParticipantRoute
                      path="/competitions/competition-overview/:assessmentId"
                      component={AssessmentOverview}
                    />
                    <ParticipantRoute
                      path="/competitions/training-overview/:assessmentId"
                      component={TrainingOverview}
                    />
                    <ParticipantRoute
                      path="/competitions/competition-announcements/:assessmentId"
                      component={AssessmentAnnouncements}
                    />
                    <ParticipantRoute
                      path="/competitions/competition-chat-forum/:assessmentId"
                      component={AssessmentChatForum}
                    />
                    <ParticipantRoute
                      path="/competitions/competition-scoreboard/:assessmentId"
                      component={AssessmentScoreboard}
                      exact
                    />
                    <ParticipantRoute
                      path="/competitions/competition-tasks/:assessmentId"
                      component={AssessmentTasks}
                    />
                    <ParticipantRoute
                      path="/competitions/training-start/:assessmentId"
                      component={TrainingStart}
                    />
                    <ParticipantRoute
                      path="/competitions/training-tasks/:assessmentId"
                      component={AssessmentTasks}
                    />
                    <AuthRoute
                      path="/competitions/competition-scoreboard-view/:assessmentId/view/:id"
                      component={AssessmentScoreboardDetail}
                      exact
                    />
                    <AuthRoute
                      path="/competitions/competition-scoreboard-view/:assessmentId/view/:id/:_userId"
                      component={AssessmentScoreboardDetail}
                      exact
                    />
                    <ParticipantRoute
                      path="/competitions/competition-complete/:assessmentId"
                      component={AssessmentComplete}
                    />
                    <ParticipantRoute
                      path="/competitions/training-complete/:assessmentId"
                      component={TrainingComplete}
                    />
                    <AuthRoute
                      path="/competitions/connect/:labId/vdi/:vmId"
                      component={LabVDI}
                      exact
                    />
                    <ProtectedRoute
                      path="/competitions/create"
                      component={CreateAssessment}
                      formMode="create"
                    />
                    <ProtectedRoute
                      path="/competitions/training/create/:userId"
                      component={CreateTraining}
                    />
                    <ProtectedRoute
                      path="/competitions/training/edit/:trainingId"
                      component={ViewTraining}
                    />
                    <ProtectedRoute
                      path="/competitions/duplicate/:_assessmentId"
                      component={CreateAssessment}
                      formMode="duplicate"
                    />
                    <ProtectedRoute
                      path="/competitions/view/:assessmentId"
                      component={ViewAssessment}
                    />
                    <ProtectedRoute
                      path="/competitions/affiliated-view-user/:userId"
                      component={AffiliatedUserProfile}
                      exact
                    />
                    <ProtectedRoute
                      path="/competitions/non-affiliated-view-user/:userId"
                      component={NonAffiliatedUserProfile}
                      exact
                    />
                    <ProtectedRoute
                      path="/competitions/trophy-room/:userId"
                      component={TrophyRoom}
                      exact
                    />
                    <ParticipantRoute
                      path="/competitions/trophy-room"
                      component={TrophyRoom}
                      exact
                    />
                    <ParticipantRoute
                      path="/competitions/skills-overview"
                      component={SkillsOverview}
                    />
                    <ParticipantRoute
                      path="/competitions/view-team/:teamId"
                      component={ViewTeam}
                    />
                    <Route path="/competitions/error" component={Error} />
                    <Redirect to="/landing" />
                  </Switch>
                </BrowserRouter>
              </SubscriptionProvider>
            </AppBasePageProvider>
          </AppStoreInitializer>
        </StylesProvider>
      </AppThemeProvider>
    </GraphQlClient>
  );
};

export default App;
