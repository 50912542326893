import React from 'react';
import PropTypes from 'prop-types';
import { Box, Card, CardContent, Divider, Grid, useTheme } from '@mui/material';
import _ from 'lodash';
import { differenceInMilliseconds } from 'date-fns';
import { gql, useQuery, useSubscription } from '@apollo/client';
import * as singleSpa from 'single-spa';
import AssignmentIcon from '@mui/icons-material/Assignment';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import WbIncandescentIcon from '@mui/icons-material/WbIncandescent';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import {
  BasePage,
  FdTypography,
  FdLoadingSpinner,
  FdSkeleton,
  FdCard,
  useQueryRecursive,
  useSnapshot,
  globalStore,
  FdButton,
  FdAnimatedDigit,
  FdTooltip,
} from '@fifthdomain/fe-shared';
import { FdBreadcrumbHeader } from '@fifthdomain/sidebar';
import { TrophyRoomCardParticipant } from '@fifthdomain/competitions';
import { getDuration, isDateInRange } from '../shared/utils/dateUtils';
import {
  listCourseUsers,
  getSystemTime,
  listUserScoreboardsByUserId,
  listUserMetricsByUserId,
  listAssessmentsByParticipantEventType,
} from '../graphql/queries';
import { productStyle } from '../shared/utils/layout';
import { onUpdateAssessment } from '../graphql/subscriptions';
import { getRandomImage } from '../shared/utils/images';
import {
  CONTENT_IMAGES,
  SKILLS_PERFORMANCE_TIMELINE_START_DATE,
} from '../constants';
import { getCourseProgressStatus } from '../shared/utils/taskUtils';
import { getCompetitionStatus } from '../shared/utils/getParticipantStatus';
import { listUserAssessmentsLandingPage } from '../queries/customQueries';
import LabelWithTooltip from '../components/Contents/LabelWithTooltip';
import CyberBrain from '../components/Insights/Cortex/CyberBrain';
import HighlightedLabel from '../components/Contents/HighlightedLabel';
import AffiliationTag from '../components/Affiliated/AffiliationTag';
import AffiliationLinkStatusModal from '../components/Affiliated/LandingMessageModals/AffiliationLinkStatusModal';
import SquadTag from '../components/Affiliated/SquadTag';
import { calculatePercentageDifference } from '../shared/utils/objectUtils';
import PercentageDifferenceIndicator from '../components/PercentageDifferenceIndicator';
import CreatedChallenges from '../components/Contents/ChallengeContribution/CreatedChallenges';
import TrainingSvg from '../components/Training/TrainingSvg';
import Events from '../components/Contents/Events';

const Container = ({ children }) => (
  <Box className="flex items-center gap-x-2">{children}</Box>
);

Container.propTypes = {
  children: PropTypes.node.isRequired,
};

const KeyValueIndicator = ({ label, score, percentage, tooltipText }) => (
  <Box className="flex items-center justify-center gap-x-3">
    <FdTooltip title={tooltipText}>
      <span>
        <FdTypography variant="captiontext1" color="secondary">
          {label}
        </FdTypography>
      </span>
    </FdTooltip>
    <FdTypography variant="subtitle1" style={{ fontWeight: 500 }}>
      <FdAnimatedDigit digit={Math.round(score) || 0} />
    </FdTypography>
    <PercentageDifferenceIndicator value={percentage} suffix="last month" />
  </Box>
);

KeyValueIndicator.propTypes = {
  label: PropTypes.string.isRequired,
  score: PropTypes.number.isRequired,
  percentage: PropTypes.number.isRequired,
  tooltipText: PropTypes.string.isRequired,
};

const ParticipantLandingPage = () => {
  const theme = useTheme();
  const globalSnap = useSnapshot(globalStore);

  const {
    data: assessmentsData,
    loading: assessmentsLoading,
    refetch: refetchAssessmentsData,
  } = useQueryRecursive(gql(listUserAssessmentsLandingPage), {
    variables: {
      userId: globalSnap.userId,
      limit: 1000,
    },
    staleTime: { seconds: 0 },
  });

  const { data: fdTrainingsData, loading: fdTrainingsLoading } =
    useQueryRecursive(gql(listAssessmentsByParticipantEventType), {
      variables: {
        orgId: globalSnap.orgId,
        participantEventType: {
          eq: 'FDTRAINING',
        },
        filter: {
          status: {
            eq: 'STARTED',
          },
        },
        limit: 1000,
      },
      staleTime: { seconds: 0 },
    });

  const { data: listCourseUsersData, loading: listCourseUsersLoading } =
    useQueryRecursive(gql(listCourseUsers), {
      variables: {
        filter: {
          userId: { eq: globalSnap.userId },
        },
        status: {
          ne: 'REMOVED',
        },
        limit: 1000,
      },
      staleTime: { seconds: 0 },
    });

  const {
    data: userScoreboardsByUserIdData,
    loading: userScoreboardsByUserIdLoading,
  } = useQueryRecursive(gql(listUserScoreboardsByUserId), {
    variables: {
      userId: globalSnap.userId,
      limit: 1000,
    },
  });

  const {
    data: listUserMetricsByUserIdData,
    loading: listUserMetricsByUserIdLoading,
  } = useQueryRecursive(gql(listUserMetricsByUserId), {
    variables: {
      userId: globalSnap.userId,
      limit: 1000,
    },
  });

  const { data: serverTime, loading: serverTimeLoading } = useQuery(
    gql(getSystemTime),
    {
      fetchPolicy: 'network-only',
    },
  );

  useSubscription(gql(onUpdateAssessment), {
    onData: (_data) => {
      refetchAssessmentsData();
    },
  });

  const userScoreboards =
    userScoreboardsByUserIdData?.listUserScoreboardsByUserId?.items || [];
  const columnsToAverage = ['successScore', 'speed', 'efficiency', 'score'];
  const userAverage = _.reduce(
    userScoreboards,
    (result, item) => {
      _.forEach(columnsToAverage, (key) => {
        if (_.isNumber(item[key])) {
          // eslint-disable-next-line no-param-reassign
          result[key] = (result[key] || 0) + item[key];
        }
      });
      return result;
    },
    {},
  );
  _.forEach(userAverage, (value, key) => {
    userAverage[key] = parseFloat(
      (value / (userScoreboards?.length ?? 0)).toFixed(2),
    );
  });

  const { lastScore, lastSuccessScore, lastEfficiency, lastSpeed } =
    listUserMetricsByUserIdData?.listUserMetricsByUserId?.items[0] || {};

  userAverage.percentageDifferenceInScore =
    calculatePercentageDifference(lastScore, userAverage.score) || 0;
  userAverage.percentageDifferenceInSuccessScore =
    calculatePercentageDifference(lastSuccessScore, userAverage.successScore) ||
    0;
  userAverage.percentageDifferenceInEfficiency =
    calculatePercentageDifference(lastEfficiency, userAverage.efficiency) || 0;
  userAverage.percentageDifferenceInSpeed =
    calculatePercentageDifference(lastSpeed, userAverage.speed) || 0;

  // get expired status for started/non-started ones as per today
  const getCompletedStatus = (_status, _endDateTime, contentType) => {
    const isNotExpired =
      differenceInMilliseconds(
        new Date(_endDateTime),
        new Date(serverTime?.getSystemTime),
      ) > 0;
    return isNotExpired
      ? _status
      : contentType === 'competition'
      ? 'ENDED'
      : 'NOT_COMPLETED';
  };

  // all assessmentData for the user except REMOVED & ARCHIVED
  const listUserAssessmentsData =
    assessmentsData?.listUserAssessmentsByUserId?.items
      .filter((ad) => !['REMOVED'].includes(ad?.status))
      .filter((ad) => ad?.assessment?.status !== 'ARCHIVED');

  const allAssessments =
    listUserAssessmentsData
      ?.filter((ad) => ad.assessment.participantEventType === 'ASSESSMENT')
      .map((ad) => ({
        ...ad,
        contentType: 'assessment',
        type: 'Solo',
        title: ad?.assessment?.name,
        duration: getDuration(ad.assessment.hours, ad.assessment.minutes),
        status:
          ad.status === 'STARTED'
            ? getCompletedStatus(
                ad.status,
                ad.assessment?.endDateTime,
                'assessment',
              )
            : ad.status,
        icon: (
          <Box style={productStyle('ASSESSMENT', theme)}>
            <AssignmentIcon style={{ height: '18px' }} />
          </Box>
        ),
        iconColor: productStyle('ASSESSMENT', theme)?.backgroundColor,
        unReadMessageCount: 0,
        image: getRandomImage(CONTENT_IMAGES),
        tasksCount: ad.assessment.tasks?.items?.length,
        eventStatus: getCompetitionStatus(
          ad.assessment?.startDateTime,
          ad.assessment?.endDateTime,
          serverTime?.getSystemTime,
        ),
      })) || [];

  const coursesData =
    listCourseUsersData?.listCourseUsers?.items
      ?.filter(
        (userCourse) =>
          userCourse.course?.status === 'AVAILABLE' &&
          userCourse.course?.availability,
      )
      .sort(
        (a, b) =>
          new Date(a?.course?.createdAt) - new Date(b?.course?.createdAt),
      )
      .map((userCourse) => {
        const courseModulePartIds = userCourse?.course?.courseModules?.items
          .map((m) => m?.parts?.items.map((mp) => mp?.id))
          .flat();
        const partsProgress =
          userCourse?.modulePartProgresses?.items?.filter((mp) =>
            courseModulePartIds?.includes(mp?.modulePartId),
          ) || [];
        const { courseProgress, status } = getCourseProgressStatus({
          partsProgress,
          courseModulePartIds,
        });
        return {
          ...userCourse,
          contentType: 'course',
          title: userCourse?.course?.name,
          image: userCourse?.course?.image?.key,
          icon: (
            <Box style={productStyle()}>
              <WbIncandescentIcon
                style={{
                  transform: 'rotateX(180deg)',
                  marginBottom: '5px',
                  height: '18px',
                }}
              />
            </Box>
          ),
          iconColor: productStyle('COURSE', theme)?.backgroundColor,
          unReadMessageCount: userCourse?.messages?.items?.filter(
            (m) => m.sender.id !== globalSnap?.userId,
          )?.length,
          courseModulesCount: userCourse?.course?.courseModules?.items?.length,
          courseProgress,
          status,
          preComputedStatus: true,
          eventStatus: 'STARTED',
        };
      }) || [];

  const allCompetitions =
    listUserAssessmentsData
      ?.filter((ad) => ad.assessment.participantEventType === 'COMPETITION')
      .map((ad) => ({
        ...ad,
        contentType: 'competition',
        type: ad?.assessment?.teamBased ? 'Team' : 'Solo',
        title: ad?.assessment?.name,
        participantStatus:
          ['STARTED', 'NOT_STARTED'].includes(ad.status) &&
          ad.assessment?.endDateTime
            ? getCompletedStatus(
                ad.status,
                ad.assessment?.endDateTime,
                'competition',
              )
            : ad.status === 'FINISHED'
            ? 'ENDED'
            : ad.status,
        // status for competition refers to competition status not the participant status
        status: getCompetitionStatus(
          ad.assessment?.startDateTime,
          ad.assessment?.endDateTime,
          serverTime?.getSystemTime,
        ),
        icon: (
          <Box style={productStyle('COMPETITION', theme)}>
            <EmojiEventsIcon style={{ height: '18px' }} />
          </Box>
        ),
        iconColor: productStyle('COMPETITION', theme)?.backgroundColor,
        unReadMessageCount: 0,
        image: getRandomImage(CONTENT_IMAGES),
      })) || [];

  const allTrainings =
    listUserAssessmentsData
      ?.filter((ad) => ad.assessment.participantEventType === 'TRAINING')
      .map((ad) => ({
        ...ad,
        contentType: 'training',
        type: 'Solo',
        title: ad?.assessment?.name,
        duration: getDuration(ad.assessment.hours, ad.assessment.minutes),
        status:
          ad.status === 'STARTED'
            ? getCompletedStatus(
                ad.status,
                ad.assessment?.endDateTime,
                'assessment',
              )
            : ad.status,
        icon: (
          <Box
            className="flex items-center justify-center w-full h-full"
            style={{
              ...productStyle('TRAINING', theme),
              height: '200px',
              width: '32px',
            }}
          >
            <TrainingSvg />
          </Box>
        ),
        iconColor: productStyle('TRAINING', theme)?.backgroundColor,
        unReadMessageCount: 0,
        image: getRandomImage(CONTENT_IMAGES),
        tasksCount: ad.assessment.tasks?.items?.length,
      })) || [];

  const allFDTrainings =
    fdTrainingsData?.listAssessmentsByParticipantEventType?.items
      ?.filter((ad) =>
        isDateInRange(
          serverTime?.getSystemTime,
          ad?.startDateTime,
          ad?.endDateTime,
        ),
      )
      .map((ad) => ({
        ...ad,
        contentType: 'fd-training',
        type: 'Solo',
        title: ad?.name,
        duration: getDuration(ad.hours, ad.minutes),
        assessment: {
          participantEventType: 'FDTRAINING',
        },
        status: 'STARTED',
        icon: (
          <Box
            className="flex items-center justify-center w-full h-full"
            style={{
              ...productStyle('TRAINING', theme),
              height: '200px',
              width: '32px',
            }}
          >
            <TrainingSvg />
          </Box>
        ),
        iconColor: productStyle('TRAINING', theme)?.backgroundColor,
        tasksCount: ad.tasks?.items?.length,
        availableUntil: ad.endDateTime,
      })) || [];

  if (
    assessmentsLoading ||
    serverTimeLoading ||
    listCourseUsersLoading ||
    userScoreboardsByUserIdLoading ||
    listUserMetricsByUserIdLoading ||
    fdTrainingsLoading
  ) {
    return <FdLoadingSpinner />;
  }

  // all events excluding ENDED or NOT_COMPLETED or FINISHED
  const rows = [
    ...allAssessments,
    ...allCompetitions,
    ...coursesData,
    ...allTrainings,
    ...allFDTrainings,
  ]?.filter(
    (item) =>
      !['ENDED', 'NOT_COMPLETED', 'FINISHED', 'Completed']?.includes(
        item?.status,
      ) && item.eventStatus !== 'ENDED',
  );

  const competitionURL = ({ id, participantStatus }) =>
    participantStatus === 'NOT_STARTED'
      ? `/competitions/competition-start/${id}`
      : participantStatus === 'STARTED'
      ? `/competitions/competition-tasks/${id}`
      : `/competitions/competition-scoreboard/${id}`;

  const onEnterClick = ({ id, assessment, participantStatus, status }) => {
    const url =
      assessment?.participantEventType === 'ASSESSMENT'
        ? status === 'STARTED'
          ? `/assessor/assessment-tasks/${id}`
          : `/assessor/assessment-start/${id}`
        : assessment?.participantEventType === 'COMPETITION'
        ? competitionURL({ id, participantStatus })
        : assessment?.participantEventType === 'TRAINING'
        ? status === 'STARTED'
          ? `/competitions/training-tasks/${id}`
          : `/competitions/training-start/${id}`
        : assessment?.participantEventType === 'FDTRAINING'
        ? `/assessor/fd-training/start/${id}`
        : `/labs/courses/view/${id}/`;
    return singleSpa.navigateToUrl(url);
  };

  return (
    <BasePage data-cy="welcome-card">
      <AffiliationLinkStatusModal />
      <FdBreadcrumbHeader />
      <Card variant="outlined" className="mb-4">
        <CardContent sx={{ paddingBottom: '1rem !important' }}>
          <Box className="grid grid-cols-5 divide-x-2 items-center">
            <Box className="flex flex-col col-span-2 h-9 justify-center pl-1">
              <Box className="flex items-center gap-x-2">
                <HomeOutlinedIcon className="h-7 w-7" />
                <Box className="flex items-center gap-x-4">
                  <FdTypography variant="h3">Home</FdTypography>
                </Box>
                <Box
                  className="ml-2 rounded-xl h-6 px-2 flex items-center"
                  sx={{
                    backgroundColor: 'primary.main',
                  }}
                >
                  <FdTypography
                    variant="body2"
                    color={
                      theme?.palette?.type === 'dark'
                        ? 'rgba(0, 0, 0, 0.87)'
                        : '#fff'
                    }
                    style={{ fontWeight: 500 }}
                  >
                    Participate Mode
                  </FdTypography>
                </Box>
              </Box>
              <FdTypography variant="captiontext1" color="secondary">
                Here, you can enter your events, and explore your skills and
                achievements.
              </FdTypography>
            </Box>
            <Box className="h-full flex flex-col gap-y-2 items-center justify-center">
              <Box className="flex items-center justify-between gap-x-2">
                <Box className="flex justify-center items-center w-8 h-8 rounded-full ml-3">
                  <HighlightedLabel
                    value={
                      <FdAnimatedDigit
                        digit={Math.round(userAverage?.score) || 0}
                      />
                    }
                  />
                </Box>
                <Box width={55}>
                  <PercentageDifferenceIndicator
                    value={userAverage?.percentageDifferenceInScore}
                    suffix="last month"
                    suffixInNewline
                  />
                </Box>
              </Box>
              <FdTooltip title="Your FifthDomain score (0-100) is a combined weighted sum of your Success, Efficiency, and Speed scores, with Success carrying the highest weightage.">
                <span>
                  <FdTypography variant="captiontext1" color="secondary">
                    FifthDomain Score
                  </FdTypography>
                </span>
              </FdTooltip>
            </Box>
            <Box className="w-full flex items-center justify-center">
              <Box className="flex flex-col items-start">
                <KeyValueIndicator
                  label="Success"
                  score={userAverage?.successScore}
                  percentage={userAverage?.percentageDifferenceInSuccessScore}
                  tooltipText="The Success Score (0-100) measures your ability to capture flags, taking into account points earned for solved challenges relative to overall points available."
                />
                <KeyValueIndicator
                  label="Efficiency"
                  score={userAverage?.efficiency}
                  percentage={userAverage?.percentageDifferenceInEfficiency}
                  tooltipText="The Efficiency Score (0-100) measures your ability to score points in challenges while minimising the number of attempts made."
                />
                <KeyValueIndicator
                  label="Speed"
                  score={userAverage?.speed}
                  percentage={userAverage?.percentageDifferenceInSpeed}
                  tooltipText="The Speed Score (0-100) reflects your quickness in completing challenges within the allocated time for an event."
                />
              </Box>
            </Box>
            <Box className="h-full flex flex-col gap-y-3 justify-center">
              <Box className="flex items-center justify-center gap-x-2">
                <FdTypography variant="captiontext1" color="secondary">
                  Organisation:
                </FdTypography>
                <AffiliationTag />
              </Box>
              <SquadTag showLabel />
            </Box>
          </Box>
        </CardContent>
      </Card>

      <Grid container spacing={2}>
        <Grid item xs={7}>
          <FdSkeleton
            loading={assessmentsLoading || listCourseUsersLoading}
            height={650}
          >
            <Events
              allEvents={rows}
              onEnterClick={onEnterClick}
              isAffiliated={globalSnap.isAffiliated}
            />
          </FdSkeleton>
          <Box my={2}>
            <TrophyRoomCardParticipant theme={theme} />
          </Box>
        </Grid>
        <Grid item xs={5}>
          <FdSkeleton
            loading={assessmentsLoading || listCourseUsersLoading}
            height="650px"
          >
            <FdCard
              elevation={0}
              heading={
                <Box display="flex" justifyContent="space-between">
                  <LabelWithTooltip
                    label="Skills"
                    tooltipText="Your Skills Overview Page shows an accumulation of the skills and performance data you have demonstrated through your participation in assigned events."
                    labelVariant="h4"
                  />
                  <FdButton
                    size="small"
                    onClick={() => {
                      singleSpa.navigateToUrl('/competitions/skills-overview');
                    }}
                  >
                    View
                  </FdButton>
                </Box>
              }
            >
              <Box mb={3}>
                <FdTypography variant="captiontext2" color="secondary">
                  View a quick snapshot of your Cyber Skills Cortex below. To
                  delve deeper and use filtering and customisation options,
                  click the button located at the top right corner of this
                  section to access your full Skills Overview page, where you
                  can also view your Performance Triangle.
                </FdTypography>
              </Box>
              <Divider />
              <Box my={3}>
                <FdTypography variant="subtitle2">
                  Cyber Skills Cortex
                </FdTypography>
                <FdTypography variant="captiontext2" color="secondary">
                  The Cyber Skills Cortex displays demonstrated cyber skills
                  aligned with the Dreyfus model&apos;s five proficiency levels
                  (1-5). Mapped to one of six FifthDomain Professional
                  Specialties, each skill darkens in corresponding squares from
                  the centre of the Cortex as one&apos;s demonstration of
                  proficiency in that skill increases.
                </FdTypography>
                <Box mt={3} className="flex items-center justify-center">
                  <CyberBrain
                    userId={globalSnap.userId}
                    timeLine={{
                      startDate: SKILLS_PERFORMANCE_TIMELINE_START_DATE,
                      endDate: new Date(),
                    }}
                  />
                </Box>
              </Box>
            </FdCard>
            <CreatedChallenges />
          </FdSkeleton>
        </Grid>
      </Grid>
    </BasePage>
  );
};

export default ParticipantLandingPage;
