import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import {
  useParams,
  Link as RouterLink,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { Box } from '@mui/material';
import {
  BasePage,
  FdTab,
  FdSkeleton,
  useQueryRecursive,
} from '@fifthdomain/fe-shared';
import { FdBreadcrumbHeader } from '@fifthdomain/sidebar';
import Overview from '../components/Training/Overview';
import Details from '../components/Training/Details';
import {
  validationSchema,
  initialValues,
} from '../validation-schemas/Trainings/createTraining';
import Challenges from '../components/Training/Challenges';
import { getTasksByAssessmentId as getAssessment } from '../queries/customQueries';
import {
  getSystemTime,
  listAffliationsByUserId,
  listUsersByAssessmentId,
} from '../graphql/queries';
import { getDifficultyLabel } from '../shared/utils/difficultyMapping';
import { proficiencyLevels } from '../constants';
import { getTrainingStatus } from '../shared/utils/getParticipantStatus';

const ViewTraining = () => {
  const { trainingId } = useParams();
  const history = useHistory();
  const { search } = useLocation();
  const tabindex = new URLSearchParams(search).get('tabindex') || 0; // default
  const [custIndex, setCustIndex] = useState(tabindex);
  const reactHookFormMethods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });
  const { reset, getValues } = reactHookFormMethods;

  const { data: serverTime } = useQuery(gql(getSystemTime), {
    fetchPolicy: 'network-only',
  });

  const { data: assessmentData, loading: assessmentDataLoading } = useQuery(
    gql(getAssessment),
    {
      variables: {
        id: trainingId,
        hasPermission: true,
      },
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        const {
          startDateTime,
          endDateTime,
          name,
          enableJumpbox,
          tasks,
          enableVPN,
          preMessage,
          postMessage,
        } = data.getAssessment || {};
        const labUsage = tasks.items?.filter((t) => t?.task?.type === 'LAB');
        reset({
          jumpBox: enableJumpbox === 'TRUE',
          enableVPN: enableVPN === 'TRUE',
          name,
          startDateTime: startDateTime ? new Date(startDateTime) : null,
          endDateTime: endDateTime ? new Date(endDateTime) : null,
          preMessage,
          postMessage,
          tasks:
            tasks?.items?.map((task) => ({
              taskId: task.taskId,
              taskDbId: task.id,
            })) || [],
          existingTasks:
            tasks?.items?.map((task) => ({
              taskId: task.taskId,
              taskDbId: task.id,
            })) || [],
          taskLabs: labUsage.reduce((acc, curr) => {
            return {
              ...acc,
              [curr.taskId]: curr.modulePartId,
            };
          }, {}),
        });
      },
    },
  );

  const { data: listUsersData, loading: listUsersLoading } = useQueryRecursive(
    gql(listUsersByAssessmentId),
    {
      variables: {
        userAssessmentAssessmentId: trainingId,
        limit: 500,
      },
    },
  );

  const trainingUser = listUsersData?.listUsersByAssessmentId?.items?.[0] || {};

  const {
    data: listAffliationsByUserIdData,
    loading: listAffliationsByUserIdLoading,
  } = useQueryRecursive(gql(listAffliationsByUserId), {
    variables: {
      userId: trainingUser?.userId,
      limit: 1000,
    },
    skip: !trainingUser?.userId,
  });
  const workRole =
    listAffliationsByUserIdData?.listAffliationsByUserId?.items?.find(
      (a) => a.status === 'ACTIVE',
    ) || {};
  const workRoleName = workRole?.customerWorkrole?.name || '';

  // set tabindex in url along with tab switch
  const setRouteIndex = (indexValue) => {
    setCustIndex(indexValue);
    history.push({ search: `tabindex=${indexValue}` });
  };

  const assessmentTasks =
    assessmentData?.getAssessment?.tasks?.items?.map((t) => ({
      ...t,
    })) || [];

  const skillsMap = assessmentTasks
    ?.map((t) => ({
      difficulty: t?.task?.difficulty,
      skills: t?.task?.skills?.items.map((s) => s.skill),
    }))
    .map((s) => s?.skills?.map((ss) => ({ ...ss, difficulty: s.difficulty })))
    .flat()
    ?.filter((s) => s?.alias);

  const skillsMapByProficiency = _(skillsMap)
    .groupBy('name')
    .map((items, name) => ({
      name: `${name} (${items?.[0]?.alias}) - `,
      proficiencies: `${_.uniq(
        items
          ?.sort((a, b) => a.difficulty - b.difficulty)
          .map((d) => {
            const difficultyLabel = getDifficultyLabel(d?.difficulty);
            return `Level ${
              proficiencyLevels.indexOf(difficultyLabel) + 1
            } (${difficultyLabel})`;
          }),
      ).join(', ')}`,
    }))
    .value();

  const loading =
    assessmentDataLoading || listUsersLoading || listAffliationsByUserIdLoading;
  const { startDateTime, endDateTime } = getValues();

  const trainingStatus = getTrainingStatus(
    trainingUser?.status,
    startDateTime,
    endDateTime,
    serverTime?.getSystemTime,
  );
  const trainingName = getValues('name');

  return (
    <Box>
      <FdBreadcrumbHeader page={{ name: trainingName, type: 'TRAINING' }} />
      <BasePage
        data-cy="training-edit-page"
        heading={
          <FdSkeleton loading={loading} height="48px">
            <span>{trainingName}</span>
          </FdSkeleton>
        }
        breadCrumbs={[
          { url: '', name: 'Users' },
          { url: '/user-management/affiliated-users', name: 'Affiliated' },
        ]}
        currentPageBreadcrumbLabel={`View '${trainingUser?.user?.name}'/View Training`}
        linkComponent={RouterLink}
        renderBreadCrumbAsButton
      >
        <Box mb={2}>
          <FormProvider {...reactHookFormMethods}>
            <FdTab
              label={[
                {
                  label: 'Overview',
                  tabRoute: `/competitions/training/edit/${trainingId}?tabindex=0`,
                  index: 0,
                  data: (
                    <Overview
                      loading={loading}
                      trainingUser={trainingUser}
                      tasks={assessmentTasks}
                      trainingId={trainingId}
                      trainingStatus={trainingStatus}
                    />
                  ),
                },
                {
                  label: 'Details',
                  tabRoute: `/competitions/training/edit/${trainingId}?tabindex=1`,
                  index: 1,
                  data: (
                    <Details
                      loading={loading}
                      trainingStatus={trainingStatus}
                    />
                  ),
                },
                {
                  label: 'Challenges',
                  tabRoute: `/competitions/training/edit/${trainingId}?tabindex=2`,
                  index: 2,
                  data: (
                    <Challenges
                      loading={loading}
                      trainingUser={trainingUser}
                      skillsMapByProficiency={skillsMapByProficiency}
                      workRoleName={workRoleName}
                      trainingStatus={trainingStatus}
                    />
                  ),
                },
              ]}
              index={parseInt(tabindex, 10)}
              custIndex={parseInt(custIndex, 10)}
              setCustIndex={setRouteIndex}
              tabLinkComponent={RouterLink}
            />
          </FormProvider>
        </Box>
      </BasePage>
    </Box>
  );
};

export default ViewTraining;
