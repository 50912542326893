import { useEffect } from 'react';
import useInitializeAAFCStore from './useInitializeAAFCStore';
import useInitializeAffiliatedStore from './useInitializeAffiliatedStore';
import useInitializeDefaultEventStore from './useInitializeDefaultEventStore';
import useInitializeDefaultStore from './useInitializeDefaultStore';
import useInitializeProductsStore from './useInitializeProductsStore';
import useInitializeCSCStore from './useInitializeCSCStore';
import useInitializeDefaultCookieStore from './useInitializeDefaultCookieStore';
import globalStore from '../../store';

const useInitializeGlobalStore = (userId) => {
  useEffect(() => {
    globalStore.loading = true;
  }, [userId]);

  useInitializeDefaultStore(userId);
  useInitializeAffiliatedStore(userId);
  useInitializeAAFCStore(userId);
  useInitializeCSCStore(userId);
  useInitializeProductsStore(userId);
  useInitializeDefaultEventStore();
  useInitializeDefaultCookieStore();
};

export default useInitializeGlobalStore;
