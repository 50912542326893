import React, { useState } from 'react';
import {
  Link as RouterLink,
  useParams,
  useLocation,
  useHistory,
} from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import { Box } from '@mui/material';
import {
  BasePage,
  FdTab,
  useQueryRecursive,
  FdDelayed,
  useSnapshot,
  globalStore,
  Authorization,
  useRecentLinks,
} from '@fifthdomain/fe-shared';
import { FdBreadcrumbHeader } from '@fifthdomain/sidebar';
import EditCourse from '../components/Courses/Edit/EditCourse';
import EditCourseUsersGroups from '../components/Courses/Edit/EditCourseUsersGroups';
import { getCourse, listLabPrototypes } from '../graphql/queries';
import {
  listQuizzesByOrgId,
  getCourseUsersGroups,
} from '../queries/customQueries';
import ViewInsight from '../components/Courses/Insights/ViewInsight';
import { ChatQueue } from '../components/Chat';
import setAppMarginRightByIds from '../shared/utils/layout';
import { ChatContext } from '../contexts';
import ChatSaveModal from '../components/Chat/ChatSaveModal';

function ViewCourseAdmin() {
  const { courseId } = useParams();
  const { search, pathname } = useLocation();
  const history = useHistory();
  const tabindex = new URLSearchParams(search).get('tabindex') || 2; // defaults to details tab
  const [custIndex, setCustIndex] = useState(tabindex);
  const [apiLoading, setApiLoading] = useState(false);
  const [isChatDirty, setIsChatDirty] = useState(false);
  const [showChatSave, setShowChatSave] = useState(false);
  const [triggerTabRefresh, setTriggerTabRefresh] = useState(false);
  const [targetTab, setTargetTab] = useState(undefined);
  const { orgId, permissions, userId } = useSnapshot(globalStore);
  const { addRecentLink } = useRecentLinks({ userId });

  const {
    data: courseData,
    loading: courseLoading,
    refetch: refetchCourse,
  } = useQuery(gql(getCourse), {
    variables: {
      id: courseId,
    },
    skip: !courseId,
    fetchPolicy: 'cache-and-network',
    onCompleted: (_data) => {
      // add recent link
      addRecentLink({
        id: courseId,
        name: _data?.getCourse?.name,
        type: 'COURSE',
        url: pathname + search,
        role: 'MANAGE',
      });
    },
  });

  console.log(courseData);
  // List Quizzes
  const { data: quizData, loading: quizDataLoading } = useQueryRecursive(
    gql(listQuizzesByOrgId),
    {
      variables: {
        orgId,
      },
    },
  );

  // List Labs which are with status READY
  const { data: labsData, loading: labsDataLoading } = useQueryRecursive(
    gql(listLabPrototypes),
    {
      variables: {
        filter: {
          orgId: { eq: orgId },
          or: [{ status: { eq: 'READY' } }, { status: { eq: 'EXPIRED' } }],
        },
      },
    },
  );

  const { data: courseUsersGroupsData, loading: courseUsersGroupsLoading } =
    useQuery(gql(getCourseUsersGroups), {
      variables: {
        id: courseId,
      },
      skip: !courseId,
    });

  // set tabindex in url along with tab switch
  const setRouteIndex = (indexValue) => {
    setCustIndex(indexValue);
    history.push({ search: `tabindex=${indexValue}` });
  };

  const allQuizzes = quizData?.listQuizzes?.items || [];
  const allLabs = labsData?.listLabPrototypes?.items || [];

  if (
    apiLoading ||
    courseLoading ||
    quizDataLoading ||
    labsDataLoading ||
    courseUsersGroupsLoading
  )
    return null;

  const name = courseData?.getCourse?.name;
  const hasChatViewPermission = Authorization.canViewInsights(permissions);

  return (
    <Box>
      <FdBreadcrumbHeader page={{ name, type: 'COURSE' }} />
      <BasePage heading={name} data-cy="view-course">
        <Box mt="-1rem">
          <ChatContext.Provider
            value={{
              isChatDirty,
              setIsChatDirty,
            }}
          >
            <FdDelayed triggerField={triggerTabRefresh}>
              <FdTab
                disableTabChange={isChatDirty}
                label={[
                  // TODO - to be added in a later stage
                  // {
                  //   label: 'Overview',
                  //   path: '/labs/courses',
                  //   index: 0,
                  //   data: () => <h1>Overview page</h1>,
                  // },
                  {
                    label: 'Insights',
                    tabRoute: `/labs/courses/view-admin/${courseId}?tabindex=0`,
                    index: 0,
                    data: (
                      <ViewInsight
                        courseUsersGroupsData={courseUsersGroupsData}
                        courseData={courseData}
                      />
                    ),
                  },
                  {
                    label: 'Users',
                    tabRoute: `/labs/courses/view-admin/${courseId}?tabindex=1`,
                    index: 1,
                    data: (
                      <EditCourseUsersGroups
                        courseId={courseId}
                        setRouteIndex={setRouteIndex}
                      />
                    ),
                  },
                  {
                    label: 'Details',
                    tabRoute: `/labs/courses/view-admin/${courseId}?tabindex=2`,
                    index: 2,
                    data: (
                      <EditCourse
                        courseId={courseId}
                        allLabs={allLabs}
                        allQuizzes={allQuizzes}
                        refetchCourse={refetchCourse}
                        setApiLoading={setApiLoading}
                      />
                    ),
                  },
                  ...(hasChatViewPermission
                    ? [
                        {
                          label: 'Chat Queue',
                          tabRoute: `/labs/courses/view-admin/${courseId}?tabindex=3`,
                          index: 3,
                          data: <ChatQueue courseId={courseId} />,
                        },
                      ]
                    : []),
                ]}
                index={parseInt(tabindex, 10)}
                custIndex={parseInt(custIndex, 10)}
                setCustIndex={setRouteIndex}
                tabLinkComponent={RouterLink}
                onTabChange={() =>
                  setAppMarginRightByIds(['topnav', 'labs-fe'], 0)
                }
                onBeforeTabChange={(_tab) => {
                  // user trying to switch tabs with unsaved chat
                  if (isChatDirty && _tab !== custIndex) {
                    setTargetTab(_tab); // save target tab for further nav
                    setShowChatSave(true);
                  }
                }}
              />
            </FdDelayed>
            <ChatSaveModal
              onConfirm={() => {
                setIsChatDirty(false);
                setShowChatSave(false);
                setRouteIndex(targetTab);
                setTriggerTabRefresh(!triggerTabRefresh); // refresh tabs to switch to target tab
              }}
              onCancel={() => {
                setShowChatSave(false);
              }}
              open={showChatSave}
            />
          </ChatContext.Provider>
        </Box>
      </BasePage>
    </Box>
  );
}

export default ViewCourseAdmin;
