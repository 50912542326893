import { useEffect } from 'react';
import { useSnapshot } from 'valtio';
import globalCookieStore from '../../store/cookieStore';

const saveToLocalStorage = (key, data) => {
  localStorage.setItem(key, JSON.stringify(data));
};

const getFromLocalStorage = (key) => {
  const data = localStorage.getItem(key);
  return data ? JSON.parse(data) : [];
};

const useRecentLinks = ({ userId }) => {
  const { recentLinks } = useSnapshot(globalCookieStore);
  const cookieId = `fd-rc-${userId?.split('-')[0]}`;

  useEffect(() => {
    // load from local storage
    globalCookieStore.recentLinks = getFromLocalStorage(cookieId);
  }, [cookieId]);

  const addRecentLink = (link) => {
    const { id, name, type, role, url, newTab = false } = link || {};

    // check if the link already exists in recentLinks
    const isLinkPresent = recentLinks.some(
      (el) => el?.id === id && el?.role === role,
    );

    if (!isLinkPresent) {
      const entries = [
        { id, name, type, role, url, newTab },
        ...recentLinks,
      ].slice(0, 5);
      globalCookieStore.recentLinks = entries;
      saveToLocalStorage(cookieId, entries);
    }
  };

  const deleteRecentLink = ({ id, role }) => {
    const entries = recentLinks.filter(
      (el) => el?.id !== id || el?.role !== role,
    );
    globalCookieStore.recentLinks = entries;
    saveToLocalStorage(cookieId, entries);
  };

  return { recentLinks, addRecentLink, deleteRecentLink };
};

export default useRecentLinks;
