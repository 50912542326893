import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { Box, Stepper, Step, StepLabel } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useForm, FormProvider, useFieldArray } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import * as singleSpa from 'single-spa';
import NavigationPrompt from 'react-router-navigation-prompt';
import {
  BasePage,
  FdButton,
  FdProgress,
  FdAlert,
  FdModal,
  amplifyConfig,
  useQueryRecursive,
  useSnapshot,
  globalStore,
  successToastMessage,
  warningToastMessage,
} from '@fifthdomain/fe-shared';
import { FdBreadcrumbHeader } from '@fifthdomain/sidebar';
import scrollToTop from '../shared/utils/scroll';
import CourseDetails from '../components/Courses/Create/CourseDetails';
import CourseMaterial from '../components/Courses/Create/CourseMaterial';
import CourseUsers from '../components/Courses/Create/CourseUsers';
import CourseGroups from '../components/Courses/Create/CourseGroups';
import { listUsersByOrgId, listQuizzesByOrgId } from '../queries/customQueries';
import {
  createCourse,
  createCourseModule,
  updateCourseModule,
  createModulePart,
  updateModulePart,
  createCourseUser,
  deleteCourseUser,
  updateCourse,
  deleteCourseModule,
  deleteModulePart,
  createModulePartProgress,
  assignCourseToGroups,
  removeCourseFromGroups,
} from '../graphql/mutations';
import {
  listGroupsByOrgId,
  listLabPrototypes,
  listCourseCategories,
} from '../graphql/queries';
import {
  courseInitialValues,
  courseValidationSchema,
} from '../validation-schemas/Course';
import {
  deleteModulesParts,
  createModules,
  addModulePartsProgress,
} from '../components/Courses/Create/courseUtils';
import { COURSE_CATEGORIES_IMAGE } from '../constants';

const useStyles = makeStyles()(() => ({
  stepper: {
    background: 'none',
    paddingLeft: '5px',
    height: '60px',
  },
}));

const CreateCourse = () => {
  const history = useHistory();
  const { orgId, userId } = useSnapshot(globalStore);
  const { classes } = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const steps = ['Add Details', 'Add Modules', 'Add Users and Groups'];
  const initialValues = {
    ...courseInitialValues,
    userIds: [],
    groupIds: [],
    dbUserIds: [],
    dbGroupIds: [],
  };

  const { data: usersData, loading: usersLoading } = useQueryRecursive(
    gql(listUsersByOrgId),
    {
      variables: {
        orgId,
      },
      skip: !orgId,
    },
  );
  const { data: groupsData, loading: groupsLoading } = useQueryRecursive(
    gql(listGroupsByOrgId),
    {
      variables: {
        orgId,
      },
    },
  );

  // List Course Categories
  const { data: courseCategories, loading: courseCategoriesLoading } =
    useQueryRecursive(gql(listCourseCategories));

  // List Quizzes
  const { data: quizData, loading: quizDataLoading } = useQueryRecursive(
    gql(listQuizzesByOrgId),
    {
      variables: {
        orgId,
      },
    },
  );

  // List Labs which are with status READY
  const { data: labsData, loading: labsDataLoading } = useQueryRecursive(
    gql(listLabPrototypes),
    {
      variables: {
        filter: {
          orgId: { eq: orgId },
          status: { eq: 'READY' },
        },
      },
    },
  );

  const allQuizzes = quizData?.listQuizzes?.items || [];
  const allLabs = labsData?.listLabPrototypes?.items || [];

  const [createCourseMutation, { loading: createCourseLoading }] = useMutation(
    gql(createCourse),
  );

  const [updateCourseMutation, { loading: updateCourseLoading }] = useMutation(
    gql(updateCourse),
  );

  const [createCourseModuleMutation, { loading: createCourseModuleLoading }] =
    useMutation(gql(createCourseModule));

  const [updateCourseModuleMutation, { loading: updateCourseModuleLoading }] =
    useMutation(gql(updateCourseModule));

  const [createModulePartMutation, { loading: createModulePartLoading }] =
    useMutation(gql(createModulePart));

  const [
    createModulePartProgressMutation,
    { loading: createModulePartProgressLoading },
  ] = useMutation(gql(createModulePartProgress));

  const [updateModulePartMutation, { loading: updateModulePartLoading }] =
    useMutation(gql(updateModulePart));

  const [createCourseUserMutation, { loading: createCourseUserLoading }] =
    useMutation(gql(createCourseUser));

  const [deleteCourseUserMutation, { loading: deleteCourseUserLoading }] =
    useMutation(gql(deleteCourseUser));

  const [deleteCourseModuleMutation, { loading: deleteCourseModuleLoading }] =
    useMutation(gql(deleteCourseModule));

  const [deleteModulePartMutation, { loading: deleteModulePartLoading }] =
    useMutation(gql(deleteModulePart));

  const [
    assignCourseToGroupsMutation,
    { loading: assignCourseToGroupsLoading },
  ] = useMutation(gql(assignCourseToGroups));

  const [
    removeCourseFromGroupsMutation,
    { loading: removeCourseFromGroupsLoading },
  ] = useMutation(gql(removeCourseFromGroups));

  const validationSchema = Yup.object().shape(
    {
      ...courseValidationSchema,
      userIds: Yup.array().when(['groupIds'], {
        is: (groupIds) => groupIds?.length === 0,
        then: Yup.array().of(Yup.string()).min(1),
        otherwise: Yup.array().of(Yup.string()),
      }),
      groupIds: Yup.array().when(['userIds'], {
        is: (userIds) => userIds?.length === 0,
        then: Yup.array().of(Yup.string()).min(1),
        otherwise: Yup.array().of(Yup.string()),
      }),
      dbUserIds: Yup.array().of(Yup.string()),
      dbGroupIds: Yup.array().of(Yup.string()),
    },
    [['userIds', 'groupIds']],
  );

  const reactHookFormMethods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });
  const {
    control,
    getValues,
    setValue,
    trigger,
    reset,
    formState: { errors, isDirty },
  } = reactHookFormMethods;

  const { append: appendDbUserId } = useFieldArray({
    control,
    name: 'dbUserIds',
  });

  const { append: appendDbGroupId } = useFieldArray({
    control,
    name: 'dbGroupIds',
  });

  if (
    usersLoading ||
    courseCategoriesLoading ||
    groupsLoading ||
    quizDataLoading ||
    labsDataLoading
  )
    return <FdProgress />;

  const users = usersData?.listUsersByOrgId?.items || [];
  const groups =
    groupsData?.listGroupsByOrgId?.items.map((g) => ({
      id: g.id,
      groupName: g.name,
      description: g.description,
      usersCount: g.users?.items.length || 0,
    })) || [];

  const validatePage = async () => {
    let result;
    switch (activeStep) {
      case 0: {
        result = await trigger(['name', 'description', 'category']);
        break;
      }
      case 1:
        result = await trigger(['modules']);
        break;
      case 2:
        result = await trigger(['userIds', 'groupIds']);
        break;
      default:
        break;
    }
    return result;
  };

  const createUsersGroups = async () => {
    // create users & groups
    const { courseId, userIds, groupIds, dbUserIds, dbGroupIds } = getValues();
    // delete users if any
    if (dbUserIds.length > 0) {
      await Promise.all(
        dbUserIds?.map(async (u) => {
          await deleteCourseUserMutation({
            variables: {
              input: {
                id: u,
              },
            },
          });
        }),
      );
      setValue('dbUserIds', []);
    }
    // delete groups if any
    if (dbGroupIds.length > 0) {
      await removeCourseFromGroupsMutation({
        variables: {
          courseId,
          groupIds: dbGroupIds,
        },
      });
      setValue('dbGroupIds', []);
    }
    // add users
    if (userIds.length > 0) {
      await Promise.all(
        userIds?.map(async (u) => {
          await createCourseUserMutation({
            variables: {
              input: {
                courseId,
                orgId,
                status: 'NOT_STARTED',
                userId: u,
              },
            },
            onCompleted: async (_data) => {
              const courseUserId = _data?.createCourseUser?.id;
              appendDbUserId(courseUserId);

              const modules = getValues('modules');

              const modulePartIds = modules
                ?.map((m) => m.parts.map((p) => p.partId))
                .flat();

              await addModulePartsProgress({
                createModulePartProgressMutation,
                modulePartIds,
                courseUserId,
              });
            },
          });
        }),
      );
    }
    // add groups
    if (groupIds.length > 0) {
      await assignCourseToGroupsMutation({
        variables: {
          courseId,
          groupIds,
        },
        onCompleted: () => appendDbGroupId(groupIds),
      });
    }
  };

  const createCourseByStatus = async ({ status, navigate }) => {
    const { courseId, name, description, category, availability } = getValues();
    const allCategories = courseCategories?.listCourseCategories?.items?.map(
      (c) => c.name,
    );
    // random category value initially
    const categoryValue =
      category ||
      allCategories[Math.floor(Math.random() * allCategories.length)];

    const actionId = courseId ? { id: courseId } : undefined;
    const actionMutation = courseId
      ? updateCourseMutation
      : createCourseMutation;
    // create/edit course
    const categoryInput = categoryValue
      ? {
          categoryId: courseCategories?.listCourseCategories?.items?.find(
            (courseCategory) => courseCategory.name === categoryValue,
          ).id,
          image: {
            key: COURSE_CATEGORIES_IMAGE[categoryValue],
            bucket: amplifyConfig.aws_user_files_s3_bucket,
            region: amplifyConfig.aws_user_files_s3_bucket_region,
          },
        }
      : {};

    await actionMutation({
      variables: {
        input: {
          ...categoryInput,
          description,
          name,
          orgId,
          ownerId: userId,
          status,
          availability,
          ...actionId,
        },
      },
      onCompleted: async (_data) => {
        const actionCourse = courseId ? 'updateCourse' : 'createCourse';
        const draftCourseId = _data?.[actionCourse]?.id;
        if (!courseId) {
          setValue('courseId', draftCourseId);
        }
        await createModules({
          updateCourseModuleMutation,
          createCourseModuleMutation,
          values: getValues(),
          courseId: courseId || draftCourseId,
          setValue,
          updateModulePartMutation,
          createModulePartMutation,
          allQuizzes,
          allLabs,
        });
        await createUsersGroups();
        await deleteModulesParts({
          updateModulePartMutation,
          deleteModulePartMutation,
          deleteCourseModuleMutation,
          values: getValues(),
        });
        if (navigate) {
          reset();
          // navigate & show success toast
          const msg =
            status === 'AVAILABLE'
              ? 'Success! Course published'
              : 'Success! Draft Saved';
          successToastMessage(msg);
          setTimeout(() => {
            singleSpa.navigateToUrl('/landing');
          }, 500);
        }
      },
    });
  };

  const saveDraft = async () => {
    const { modules: draftModules } = getValues();
    if (draftModules.length > 0) {
      const noModuleErrors = await trigger(['modules']);
      if (!noModuleErrors) {
        setActiveStep(1);
        return;
      }
    }
    if (await trigger(['name'])) {
      await createCourseByStatus({
        status: 'DRAFT',
        navigate: true,
      });
    }
  };

  const handleNext = async () => {
    if (await validatePage()) {
      if (activeStep === 0) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        scrollToTop();
      }
      if (activeStep === 1) {
        // save & continue
        await createCourseByStatus({
          status: 'DRAFT',
        });
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        scrollToTop();
      }
      if (activeStep === 2) {
        // save & publish
        await createCourseByStatus({
          status: 'AVAILABLE',
          navigate: true,
        });
      }
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    scrollToTop();
  };

  const loadStatus =
    createCourseLoading ||
    updateCourseLoading ||
    createCourseModuleLoading ||
    updateCourseModuleLoading ||
    createModulePartLoading ||
    createModulePartProgressLoading ||
    updateModulePartLoading ||
    createCourseUserLoading ||
    removeCourseFromGroupsLoading ||
    deleteCourseUserLoading ||
    deleteCourseModuleLoading ||
    deleteModulePartLoading ||
    assignCourseToGroupsLoading;

  return (
    <Box>
      <FdBreadcrumbHeader page={{ name: 'Create Course', type: 'COURSE' }} />
      <BasePage heading="Create a Course" data-cy="create-course-base-page">
        <Box width="734px">
          <Stepper
            activeStep={activeStep}
            className={classes.stepper}
            data-cy="stepper"
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel data-cy="stepper-label">{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        <FormProvider {...reactHookFormMethods}>
          <form>
            {
              {
                0: (
                  <Box>
                    <CourseDetails heading="Add Course Details" isEdit />
                  </Box>
                ),
                1: (
                  <Box>
                    <CourseMaterial
                      allQuizzes={allQuizzes}
                      allLabs={allLabs}
                      isEdit
                    />
                  </Box>
                ),
                2: (
                  <Box data-cy="add-users-courses">
                    {(errors?.groupIds || errors?.userIds) && (
                      <Box my={2}>
                        <FdAlert
                          variant="error"
                          message="Please select a user or group"
                        />
                      </Box>
                    )}
                    <CourseUsers rows={users} />
                    <CourseGroups rows={groups} />
                  </Box>
                ),
              }[activeStep]
            }
            <Box display="flex" justifyContent="space-between" width="100%">
              {activeStep === 0 ? (
                <FdButton
                  size="large"
                  variant="secondary"
                  onClick={() => singleSpa.navigateToUrl('/landing')}
                  data-cy="cancel-button"
                >
                  Cancel
                </FdButton>
              ) : (
                <FdButton
                  size="large"
                  variant="secondary"
                  onClick={handleBack}
                  data-cy="back-button"
                >
                  Back
                </FdButton>
              )}
              <Box>
                <FdButton
                  size="large"
                  variant="secondary"
                  onClick={saveDraft}
                  style={{ marginRight: '1rem' }}
                  disabled={createCourseLoading}
                >
                  Save Draft
                </FdButton>
                <FdButton
                  size="large"
                  onClick={handleNext}
                  disabled={loadStatus}
                >
                  {loadStatus
                    ? 'Loading...'
                    : activeStep === steps.length - 1
                      ? 'PUBLISH'
                      : activeStep === 0
                        ? 'Next'
                        : 'Save & Continue'}
                </FdButton>
              </Box>
            </Box>
          </form>
        </FormProvider>
        <NavigationPrompt
          when={isDirty}
          afterCancel={() => {
            if (window.location.pathname !== '/labs/courses/create') {
              history.goBack();
            }
          }}
        >
          {({ onConfirm, onCancel }) => (
            <FdModal
              title="Are you sure you want to leave?"
              description="You have unsaved changes. Click the Stay button to go back to the form and save your changes."
              confirm="Stay"
              dismiss="Leave"
              open
              onConfirm={onCancel}
              onDismiss={() => {
                warningToastMessage('Course not Created');
                onConfirm();
              }}
              data-cy="leave-modal"
            />
          )}
        </NavigationPrompt>
      </BasePage>
    </Box>
  );
};

export default CreateCourse;
