import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  useAuthSession,
  useSnapshot,
  globalStore,
  FdLoadingSpinner,
  Authorization,
} from '@fifthdomain/fe-shared';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { user } = useAuthSession();
  const { orgPricingTier, loading, permissions } = useSnapshot(globalStore);

  // check for review-content and manage-content permissions only
  const canManageTasks =
    orgPricingTier !== 'STARTER' &&
    user &&
    (Authorization.canManageContent(permissions) ||
      Authorization.canReviewContent(permissions));

  if (loading) return <FdLoadingSpinner />;

  return (
    <Route
      {...rest}
      render={(props) => {
        if (canManageTasks) {
          return <Component {...rest} {...props} />;
        }
        return null;
      }}
    />
  );
};

ProtectedRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
};

export default ProtectedRoute;
