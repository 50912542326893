import _ from 'lodash';
import { errorToastMessage } from '@fifthdomain/fe-shared';

export const onError = ({ graphQLErrors }) => {
  if (Array.isArray(graphQLErrors) && graphQLErrors.length > 0) {
    errorToastMessage(graphQLErrors[0].message);
  }
};

export const createCustomerWorkRoles = async ({
  workRoleMutation,
  userMutation,
  functionId,
  workRoles,
  functionLeads,
  onCompleted,
}) => {
  try {
    const workRolePromises = workRoles?.map((wr) =>
      workRoleMutation({
        variables: {
          input: {
            customerWorkroleId: wr?.workRoleId,
            functionId,
            workRoleUnits: wr?.units,
          },
        },
      }),
    );
    const userPromises = functionLeads?.map((fl) =>
      userMutation({
        variables: {
          input: {
            functionId,
            userId: fl?.id,
          },
        },
      }),
    );

    await Promise.all(workRolePromises);
    await Promise.all(userPromises);
    onCompleted();
  } catch (error) {
    onError(error);
  }
};

export const updateFunctionLeads = async ({
  userMutation,
  functionId,
  functionLeads,
  onCompleted,
}) => {
  try {
    const userPromises = functionLeads?.map((fl) =>
      userMutation({
        variables: {
          input: {
            functionId,
            userId: fl?.id,
          },
        },
      }),
    );
    await Promise.all(userPromises);
    onCompleted();
  } catch (error) {
    onError(error);
  }
};

export const editModeCreateCustomerWorkRoles = async ({
  workRoleMutation,
  functionId,
  workRoles,
  onCompleted,
}) => {
  try {
    const workRolePromises = workRoles
      ?.filter((wr) => !wr?.pkId)
      ?.map((wr) =>
        workRoleMutation({
          variables: {
            input: {
              customerWorkroleId: wr?.workRoleId,
              functionId,
              workRoleUnits: wr?.units,
            },
          },
        }),
      );

    await Promise.all(workRolePromises);
    onCompleted();
  } catch (error) {
    onError(error);
  }
};

export const getSquadUsers = (_members = [], role) =>
  (Array.isArray(_members) &&
    _members
      ?.filter((m) => m?.role === role && m?.status === 'ACTIVE')
      ?.map((m) => ({
        pkId: m?.id,
        name: m?.user?.name,
        email: m?.user?.email,
        userId: m?.userId,
        selected: true,
      }))) ||
  [];

export const createSquadUsers = async ({
  squadUserMutation,
  squadId,
  users,
  onCompleted,
}) => {
  try {
    const squadUsersPromises = users
      ?.filter((u) => !u?.pkId)
      ?.map((u) =>
        squadUserMutation({
          variables: {
            input: {
              squadId,
              userId: u?.userId,
              status: 'ACTIVE',
              role: u?.role,
            },
          },
        }),
      );

    await Promise.all(squadUsersPromises);
    onCompleted();
  } catch (error) {
    onError(error);
  }
};

export const deleteSquadUsers = async ({
  squadUserDeleteMutation,
  users,
  onCompleted,
}) => {
  try {
    const squadUsersPromises = users?.map((id) =>
      squadUserDeleteMutation({
        variables: {
          input: {
            id,
          },
        },
      }),
    );

    await Promise.all(squadUsersPromises);
    onCompleted();
  } catch (error) {
    onError(error);
  }
};

export const updateSquadRemoved = async ({
  updateSquadMutation,
  updateSquadUserMutation,
  squadId,
  users,
  onCompleted,
}) => {
  try {
    const squadUsersPromises = users?.map((id) =>
      updateSquadUserMutation({
        variables: {
          input: {
            id,
            status: 'REMOVED',
          },
        },
      }),
    );
    await Promise.all(squadUsersPromises);
    await updateSquadMutation({
      variables: {
        input: {
          id: squadId,
          status: 'REMOVED',
        },
      },
    });
    onCompleted();
  } catch (error) {
    onError(error);
  }
};

export const updateFunctionStatus = async ({
  updateFunctionMutation,
  updateSquadMutation,
  updateSquadUserMutation,
  functionId,
  squads,
  onCompleted,
  status,
}) => {
  try {
    const squadUsersPromises = squads?.map((s) =>
      updateSquadRemoved({
        updateSquadMutation,
        updateSquadUserMutation,
        squadId: s?.squadId,
        users: s?.users,
        onCompleted: () => null,
      }),
    );
    await Promise.all(squadUsersPromises);
    await updateFunctionMutation({
      variables: {
        input: {
          id: functionId,
          status,
        },
      },
    });
    onCompleted();
  } catch (error) {
    onError(error);
  }
};

// CORTEX
export const getCortexTargets = (selectedFunctions) => {
  const workRolesSkills =
    selectedFunctions
      ?.map((fn) => fn?.customerWorkroles?.items)
      .flat()
      .map((w) =>
        w?.customerWorkrole?.customerSkills?.items?.map((s) => ({
          ...s,
          units: w?.workRoleUnits,
        })),
      )
      .flat() || [];
  // targets with levels
  return workRolesSkills
    .map((ws) =>
      ws?.skills?.items
        ?.map((si) => ({
          level: ws?.difficulty,
          skillAlias: si?.skill?.alias,
          units: ws?.units,
        }))
        .flat(),
    )
    .flat();
};

export const getFunctionCortexTargets = (workRoles) => {
  const targets =
    workRoles
      ?.map((wr) => wr?.skills?.map((s) => ({ ...s, units: wr?.units })))
      ?.flat()
      ?.map((s) =>
        s?.skills?.items
          ?.map((si) => ({
            level: s?.difficulty,
            skillAlias: si?.skill?.alias,
            units: s?.units,
          }))
          .flat(),
      )
      .flat() || [];
  const grouped = _.mapValues(
    _.groupBy(targets, 'skillAlias'),
    (items) => _.maxBy(items, 'level')?.level,
  );
  const fillers = _.flatMap(grouped, (maxLevel, skillAlias) =>
    _.times(maxLevel - 1, (i) => ({
      level: i + 1,
      skillAlias,
    })),
  );

  return { targets, fillers };
};

export const getFulfilledUnits = (level, targets, skillMetrics, skillAlias) => {
  let reserveUnits = 0;
  let fulfilledUnits = 0;

  const getTargetUnits = (alias, lvl) =>
    _.sumBy(
      targets?.filter((t) => t?.skillAlias === alias && t?.level === lvl),
      'units',
    ) || 0;

  const getAcquiredUnits = (difficulty) =>
    skillMetrics?.filter(
      (m) =>
        m?.skill?.alias === skillAlias &&
        m?.difficulty === difficulty &&
        m?.degradedScore > 0 &&
        !m?.derived,
    )?.length || 0;

  for (let i = 5; i >= level; i -= 1) {
    const targetUnits = getTargetUnits(skillAlias, i);
    const acquiredUnits = getAcquiredUnits(i);
    const diffUnits = targetUnits - acquiredUnits;

    // If no target units, all acquired units are reserved
    if (targetUnits === 0) {
      reserveUnits += acquiredUnits;
      fulfilledUnits = 0;
      continue;
    }
    // If target units are fully matched by acquired units, no reserve needed
    if (diffUnits === 0) {
      fulfilledUnits = acquiredUnits;
      continue;
    }
    // If target units are less than acquired units, use all acquired units and add the excess to reserve
    if (targetUnits < acquiredUnits) {
      fulfilledUnits = targetUnits;
      reserveUnits += acquiredUnits - targetUnits;
      continue;
    }
    // If target units are greater than acquired units, use reserve if possible
    if (targetUnits > acquiredUnits) {
      const reserveRequired = targetUnits - acquiredUnits;

      // Use reserve units if available to meet the target
      if (reserveUnits >= reserveRequired) {
        fulfilledUnits = acquiredUnits + reserveRequired;
        reserveUnits -= reserveRequired;
      } else {
        // If reserve is insufficient, use all remaining reserve
        fulfilledUnits = acquiredUnits + reserveUnits;
        reserveUnits = 0;
      }
    }
  }

  return fulfilledUnits;
};

export const getReadinessScore = (targets, selectedUserMetrics, squads) => {
  const allSelectedSquadUsers =
    squads?.map((s) => s?.members?.items?.map((m) => m?.userId))?.flat() || [];
  const metricsForUsersSelected =
    selectedUserMetrics?.filter((sm) =>
      allSelectedSquadUsers?.includes(sm?.userId),
    ) || [];

  const fullFilledUnits =
    targets?.map((t) => {
      const fulfilledUnitMetrics = getFulfilledUnits(
        t?.level,
        targets,
        metricsForUsersSelected,
        t?.skillAlias,
      );
      return fulfilledUnitMetrics > t?.units ? t?.units : fulfilledUnitMetrics;
    }) || [];

  const targetUnits = _.sumBy(targets, 'units') || 0;
  const fulfilledUnitsDisplay = _.sum(fullFilledUnits) || 0;
  const readinessScore =
    targetUnits > 0
      ? Math.round((fulfilledUnitsDisplay / targetUnits) * 100)
      : 0;

  return { fulfilledUnitsDisplay, readinessScore };
};

export const getTop3Skills = (data) => {
  const groupedData = _.groupBy(data, 'userId');

  // Map and calculate total scores, sort, and take top 3
  const result = _.map(groupedData, (userData, userId) => ({
    userId,
    skills: _(userData)
      .groupBy('skill.alias')
      .map((skillData, skill) => ({
        skill,
        skillName: skillData[0].skill.name,
        totalScore: _.sumBy(skillData, 'degradedScore'),
      }))
      .orderBy('totalScore', 'desc')
      .take(3) // top 3 skills
      .value(),
  }));

  return result;
};
