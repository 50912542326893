import React from 'react';
import StylesProvider from '@mui/styles/StylesProvider';
import createGenerateClassName from '@mui/styles/createGenerateClassName';
import { BrowserRouter, Switch, Redirect } from 'react-router-dom';
import { AppBasePageProvider } from '@fifthdomain/fe-shared';
import ListTasks from './pages/ListTasks';
import CreateTask from './pages/CreateTask';
import ViewTask from './pages/ViewTask';
import ProtectedRoute from './routes/ProtectedRoute';
import AppThemeProvider from './providers/AppThemeProvider';
import ReviewTask from './pages/ReviewTask';
import GraphQlClient from './shared/GraphQlClient';
import './tasks.css';

const generateClassName = createGenerateClassName({
  productionPrefix: 'tasks',
  seed: 'tasks',
});

const App = () => {
  return (
    <GraphQlClient>
      <AppThemeProvider>
        <BrowserRouter>
          <StylesProvider generateClassName={generateClassName}>
            <AppBasePageProvider>
              <BrowserRouter>
                <Switch>
                  <ProtectedRoute path="/tasks" component={ListTasks} exact />
                  <ProtectedRoute
                    path="/tasks/create"
                    component={CreateTask}
                    formMode="create"
                    exact
                  />
                  <ProtectedRoute
                    path="/tasks/edit-draft/:taskId"
                    component={CreateTask}
                    formMode="edit"
                    exact
                  />
                  <ProtectedRoute
                    path="/tasks/view/:taskId"
                    component={ViewTask}
                    exact
                  />
                  <ProtectedRoute
                    path="/tasks/:mode/:taskId"
                    component={ReviewTask}
                    exact
                  />
                  <Redirect to="/" />
                </Switch>
              </BrowserRouter>
            </AppBasePageProvider>
          </StylesProvider>
        </BrowserRouter>
      </AppThemeProvider>
    </GraphQlClient>
  );
};

export default App;
