import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import { Box, IconButton } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import {
  FdTable,
  FdModal,
  FdTypography,
  FdAlert,
  FdChip,
  useSnapshot,
  globalStore,
  Authorization,
  FdTooltip,
  useRecentLinks,
  errorToastMessage,
  successToastMessage,
  warningToastMessage,
} from '@fifthdomain/fe-shared';
import TableHeaderColumnWithTooltip from './TableHeaderColumnWithTooltip';
import UserDrawer from './UserDrawer';
import { getCommaSeparatedPlusSuffix } from '../../shared/utils/stringUtils';
import { addUsers, updateUserAffliation } from '../../graphql/mutations';
import AssessmentsIcon from './Icons/AssessmentsIcon';
import CompetitionsIcon from './Icons/CompetitionsIcon';
import CoursesIcon from './Icons/CoursesIcon';

const AffiliatedUsersTable = ({
  rows,
  participantsOnly,
  refetchQueries,
  canManageUsers,
}) => {
  const globalSnap = useSnapshot(globalStore);
  const { addRecentLink } = useRecentLinks({ userId: globalSnap.userId });
  const [disaffiliateUser, setDisaffiliateUser] = useState(undefined);
  const [resendAffiliation, setResendAffiliation] = useState(undefined);
  const [revokeAffiliation, setRevokeAffiliation] = useState(undefined);
  const [selectedUser, setSelectedUser] = useState(undefined);
  const [openDrawer, setOpenDrawer] = useState(false);

  const [
    updateUserAffiliationMutation,
    { loading: updateUserAffiliationMutationLoading },
  ] = useMutation(gql(updateUserAffliation), {
    onError: ({ graphQLErrors }) => {
      errorToastMessage(graphQLErrors[0]?.message);
    },
  });

  const [addUsersMutation, { loading: inviteUsersLoading }] = useMutation(
    gql(addUsers),
    {
      onCompleted: (_data) => {
        setResendAffiliation(undefined);
        successToastMessage('Success! Affiliation request sent.');
        refetchQueries();
      },
      onError: ({ graphQLErrors }) => {
        errorToastMessage(graphQLErrors[0]?.message);
      },
    },
  );

  const eventEngagement = {
    competitions: {
      icon: <CompetitionsIcon />,
    },
    assessments: {
      icon: <AssessmentsIcon />,
    },
    courses: {
      icon: <CoursesIcon />,
    },
  };

  const columns = [
    { field: 'name', width: 350, headerName: 'Name' },
    {
      field: 'affiliatedStatus',
      width: 200,
      headerName: 'Affiliation Status',
      valueGetter: (params) => params.value,
      renderCell: (params) =>
        params.row.affiliatedStatus === 'Confirmed' ? (
          <Box
            className="flex items-center gap-x-1"
            style={{ color: 'rgba(46, 125, 50, 1)' }}
          >
            <DoneIcon style={{ fontSize: 14 }} />
            {params.row.affiliatedStatus}
          </Box>
        ) : (
          params.row.affiliatedStatus
        ),
    },
    { field: 'email', width: 200, headerName: 'Email Address' },
    {
      field: 'registrationType',
      width: 200,
      headerName: 'Registration Status',
      renderCell: (params) => (
        <FdChip
          color={
            params?.row?.registrationType === 'Registered'
              ? 'success'
              : 'default'
          }
          size="small"
          label={params?.row?.registrationType}
        />
      ),
    },
    {
      field: 'permissions',
      width: 200,
      headerName: 'Permissions',
      valueGetter: (params) => params.value?.join(', '),
      renderCell: (params) =>
        getCommaSeparatedPlusSuffix(params.row.permissions?.map((p) => p)),
    },
    { field: 'affiliatedDate', width: 150, headerName: 'Date of Affiliation' },
    { field: 'alias', width: 150, headerName: 'Alias' },
    {
      field: 'groups',
      width: 150,
      headerName: 'Groups',
      valueGetter: (params) => params.value?.map((p) => p?.name)?.join(', '),
      renderCell: (params) =>
        getCommaSeparatedPlusSuffix(params.row.groups?.map((p) => p?.name)),
    },
    { field: 'userId', width: 10, headerName: 'userId', filterable: false },
    {
      field: 'assignedEvents',
      width: 200,
      headerName: 'Event Engagement',
      renderHeader: () => (
        <TableHeaderColumnWithTooltip
          title="Event Engagement"
          tooltipText="The Event Engagement column displays a comparison between the number of events initiated by the user and the number of events assigned to them. It's crucial to understand that this only includes events conducted by your organisation and excludes any events initiated or assigned to the user that are hosted by other organisations."
        />
      ),
      renderCell: (params) => {
        const assignedEvents = params?.row?.assignedEvents;
        return (
          <Box className="flex items-center gap-x-2">
            {Object.entries(assignedEvents).map(([key, _value]) => {
              const val = _value?.filter((i) => i?.orgId === globalSnap?.orgId);
              const participantStarted = val
                ?.map((item) => item?.isProgress)
                ?.filter((value) => value).length;
              const totalEvents = val?.length;
              return val?.length > 0 ? (
                <>
                  <FdTooltip
                    title={`Participant has started ${participantStarted} out of ${totalEvents} assigned ${key} in your organisation.`}
                  >
                    <IconButton size="small" style={{ marginLeft: '5px' }}>
                      {eventEngagement[key]?.icon}
                    </IconButton>
                  </FdTooltip>
                  <FdTypography variant="captiontext2" color="secondary">
                    {`  ${participantStarted}/ ${totalEvents}`}
                  </FdTypography>
                </>
              ) : (
                <></>
              );
            })}
          </Box>
        );
      },
    },
  ];

  const actions = [
    {
      label: participantsOnly ? 'View' : 'Edit',
      show: (row) =>
        canManageUsers &&
        row?.name &&
        row?.email !== globalSnap.userEmail &&
        !Authorization?.isAccountOwner(row),
      onClick: (row) => {
        setSelectedUser(row);
        setOpenDrawer(true);
      },
    },
    {
      label: 'View',
      show: (row) =>
        !participantsOnly &&
        ((row?.name && row?.email === globalSnap.userEmail) ||
          (canManageUsers && Authorization?.isAccountOwner(row))),
      onClick: (row) => {
        setSelectedUser(row);
        setOpenDrawer(true);
      },
    },
    {
      label: 'Disaffiliate',
      show: (row) =>
        canManageUsers &&
        !participantsOnly &&
        row?.affiliatedStatus === 'Confirmed' &&
        row?.email !== globalSnap.userEmail &&
        !Authorization?.isAccountOwner(row),
      onClick: (row) => setDisaffiliateUser(row),
    },
    {
      label: 'View Profile',
      show: (row) =>
        row?.email !== globalSnap.userEmail &&
        row?.affiliatedStatus === 'Confirmed',
      onClick: ({ userId, name }) => {
        // add recent link
        addRecentLink({
          id: userId,
          name,
          type: 'USER_PROFILE',
          url: `/competitions/affiliated-view-user/${userId}`,
          role: 'MANAGE',
          newTab: true,
        });
        window.open(
          `/competitions/affiliated-view-user/${userId}`,
          '_blank',
          'noopener,noreferrer',
        );
      },
    },
    {
      label: 'Resend affiliation request',
      show: (row) =>
        canManageUsers &&
        !participantsOnly &&
        ['Pending', 'Expired']?.includes(row?.affiliatedStatus),
      onClick: (row) => setResendAffiliation(row),
    },
    {
      label: 'Revoke affiliation request',
      show: (row) =>
        canManageUsers &&
        !participantsOnly &&
        ['Pending', 'Expired']?.includes(row?.affiliatedStatus),
      onClick: (row) => setRevokeAffiliation(row),
    },
    {
      label: 'Copy invite link',
      show: (row) => canManageUsers && row?.affiliatedStatus === 'Pending',
      onClick: (row) => {
        const inviteLink =
          row?.registrationType === 'Registered'
            ? `${window.origin}/?isAffliated=true&orgId=${globalSnap.orgId}`
            : `${window.origin}/a/register?inviteToken=${row.inviteToken}&email=${row?.email}&isAffliated=true&orgId=${globalSnap.orgId}`;
        navigator.clipboard.writeText(inviteLink);
      },
    },
    {
      show: () => false, // dummy entry to display drop down
    },
  ];

  return (
    <Box height="520px">
      <FdTable
        toolbarSettings={{
          title: '',
          filterButton: true,
          searchBox: true,
        }}
        rows={
          participantsOnly
            ? rows?.filter(
                (r) =>
                  r?.permissions?.length === 2 &&
                  r?.permissions?.every((permission) =>
                    ['Participate', 'Manage Profile'].includes(permission),
                  ),
              )
            : rows || []
        }
        columns={columns}
        tablePageSize={10}
        actions={actions}
        gridId="user-management-list-groups"
        columnVisibilityModel={{
          permissions: participantsOnly ? false : !openDrawer,
          alias: !openDrawer,
          groups: !openDrawer,
          userId: false,
        }}
        loading={updateUserAffiliationMutationLoading}
      />
      <UserDrawer
        user={selectedUser}
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
        viewOnly={participantsOnly}
        refetchQueries={refetchQueries}
      />
      <FdModal
        size="md"
        title={`Confirm disaffiliation of ${disaffiliateUser?.name || ''} ?`}
        description={
          <Box>
            <Box>
              Disaffiliating a user will revert them to being a non-affiliated
              participant within your organisation.
            </Box>
            <Box mt={1}>
              Managers of your organisation will no longer be able to view the
              user&apos;s cumulative skills data for events hosted by other
              organisations.
            </Box>
          </Box>
        }
        confirm="disaffiliate"
        dismiss="CANCEL"
        open={disaffiliateUser}
        confirmLoading={updateUserAffiliationMutationLoading}
        disableConfirm={updateUserAffiliationMutationLoading}
        onConfirm={() => {
          updateUserAffiliationMutation({
            variables: {
              userId: disaffiliateUser?.userId,
              email: disaffiliateUser?.email,
              orgId: globalSnap?.orgId,
              affiliationAction: 'DISAFFILIATE',
            },
            onCompleted: (_data) => {
              setDisaffiliateUser(undefined);
              successToastMessage(
                successToastMessage('User disaffiliated from organisation.'),
              );
              refetchQueries();
            },
          });
        }}
        onDismiss={() => {
          setDisaffiliateUser(undefined);
          warningToastMessage('User not disaffiliated.');
        }}
      />
      <FdModal
        size="md"
        title="Resend affiliation request?"
        description=""
        confirm="send affiliation request"
        dismiss="CANCEL"
        open={resendAffiliation}
        confirmLoading={inviteUsersLoading}
        disableConfirm={inviteUsersLoading}
        onConfirm={() => {
          addUsersMutation({
            variables: {
              emails: [resendAffiliation?.email],
              orgId: globalSnap?.orgId,
              affliationAction: 'RESEND_REQUEST',
              participantType: 'INTERNAL',
            },
          });
        }}
        onDismiss={() => {
          setResendAffiliation(undefined);
          warningToastMessage('Affiliation request not sent.');
        }}
      >
        <Box>
          <FdAlert
            variant="warning"
            message="The link to affiliate expires in 30 days starting from the day the affiliation request was sent. If the invited users don't confirm or decline affiliation in this time, managers will have to resend the invitation. "
          />
          <Box mt={1}>
            <FdTypography variant="body2">
              This action will send an email to the user(s) for them to confirm
              their affiliation with your organisation. A user only becomes
              affiliated after the affiliation is confirmed by them.
            </FdTypography>
          </Box>
        </Box>
      </FdModal>
      <FdModal
        size="md"
        title="Revoke affiliation request?"
        description="Are you sure you want to revoke the affiliation request? This action will confirm the withdrawal of the request, and the user  will not be able to establish an affiliation with your organisation."
        confirm="yes, revoke"
        dismiss="CANCEL"
        open={revokeAffiliation}
        confirmLoading={updateUserAffiliationMutationLoading}
        disableConfirm={updateUserAffiliationMutationLoading}
        onConfirm={() => {
          updateUserAffiliationMutation({
            variables: {
              email: revokeAffiliation?.email,
              orgId: globalSnap?.orgId,
              affiliationAction: 'REVOKE',
              userId: revokeAffiliation?.userId,
            },
            onCompleted: (_data) => {
              setRevokeAffiliation(undefined);
              successToastMessage('Success! Affiliation request revoked.');
              refetchQueries();
            },
          });
        }}
        onDismiss={() => {
          setRevokeAffiliation(undefined);
          warningToastMessage('Affiliation request not revoked.');
        }}
      />
    </Box>
  );
};

AffiliatedUsersTable.defaultProps = {
  participantsOnly: false,
};

AffiliatedUsersTable.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ).isRequired,
  participantsOnly: PropTypes.bool,
  refetchQueries: PropTypes.func.isRequired,
  canManageUsers: PropTypes.bool.isRequired,
};

export default AffiliatedUsersTable;
