import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import { Box, IconButton } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useFieldArray, useFormContext } from 'react-hook-form';
import {
  FdTable,
  FdTypography,
  FdChip,
  FdButton,
  FdTooltip,
  FdModal,
  useSnapshot,
  globalStore,
  FdAlert,
  FdDelayed,
  errorToastMessage,
  successToastMessage,
  warningToastMessage,
} from '@fifthdomain/fe-shared';
import RoleChip from './RoleChip';
import { TEAM_ROLES } from '../../../constants';
import { manageUserAndTeam } from '../../../graphql/mutations';
import { getTeam, listScoreboardsByTeamId } from '../../../graphql/queries';
import { getMemberMapping, getActionStatus } from './utils';
import TeamAvatar from '../../Insights/TeamAvatar';

const modalText = {
  teamManager: {
    targetType: TEAM_ROLES.GENERAL_MEMBER,
    heading: "Change this Team Manager's role type?",
    description: (
      <>
        Are you sure that you want to change this Team Manager&apos;s role type?
        If so, they will lose Team Manager abilities and become a General
        Member.
        <br />
        <br />
        You can always change their role type again if required.
      </>
    ),
    confirmMessage: 'Team member role type changed!',
    cancelMessage: 'Team member role type not changed',
  },
  generalMember: {
    targetType: TEAM_ROLES.TEAM_MANAGER,
    heading: "Change this General Member's role type?",
    description: (
      <>
        Are you sure that you want to change this General Member&apos;s role
        type? If so, they will be granted Team Manager abilities.
        <br />
        <br />
        You can always change their role type again if required.
      </>
    ),
    confirmMessage: 'Team member role type changed!',
    cancelMessage: 'Team member role type not changed',
  },
  removeMember: {
    targetType: 'REMOVE',
    heading: 'Remove this team member?',
    description: (
      <>
        Are you sure that you want to remove this team member? They will no
        longer be part of this team if you choose to proceed with this action.
        <br />
        <br />
        <FdAlert
          message={
            <>
              If you remove this team member during a competition, they will
              lose association with all your team&apos;s flags, points, and
              first solves earned in that competition. Additionally, your team
              will forfeit the flags, points, and first solves this member has
              personally achieved if you remove them. Challenges they have
              solved for the team will lose their &apos;Completed&apos; status,
              becoming attemptable again for your team members.
              <br />
              <br />
              In an ongoing competition, if after removing this member, they
              rejoin your team or join a different team, any flags, points, and
              first solves they earned with your team will not be carried over
              to the next team they join.
            </>
          }
          variant="warning"
        />
      </>
    ),
    confirmMessage: 'Team member removed!',
    cancelMessage: 'Team member not removed',
  },
  leaveTeam: {
    targetType: 'EXIT_TEAM',
    heading: 'Leave this team?',
    description: (
      <>
        Are you sure that you want to leave this team?
        <br />
        <br />
        You will no longer be able to participate in events with this team. You
        will also no longer be able to view this team (even if you were its
        original creator).
        <br />
        <br />
        <FdAlert
          message={
            <>
              If you leave your team during a competition, you will lose
              association with all your team&apos;s flags, points, and first
              bloods earned in that competition. Additionally, your team will
              forfeit the flags, points, and first solves you personally
              achieved if you leave. Challenges you have solved for the team
              will lose their &apos;Completed&apos; status, becoming attemptable
              again for your team members.
              <br />
              <br />
              In an ongoing competition, if after leaving, you rejoin your
              original team or join a different team, any flags, points, and
              first solves you earned with your previous team will not be
              carried over to the next team you join.
            </>
          }
          variant="warning"
        />
      </>
    ),
    confirmMessage: 'You have left this team!',
    cancelMessage: 'You have not left this team',
  },
};

const TeamMembers = ({ viewOnly, onLeave, isPrivate }) => {
  const [changeRoleModal, setChangeRoleModal] = useState(undefined);
  const { control, getValues } = useFormContext();
  const { fields: members, replace: replaceMembers } = useFieldArray({
    control,
    name: 'members',
  });
  const [manageUserAndTeamMutation, { loading: manageUserAndTeamLoading }] =
    useMutation(gql(manageUserAndTeam));
  const [fetchTeam, { loading: teamsLoading }] = useLazyQuery(gql(getTeam));
  const [fetchScoreboard, { loading: scoreboardLoading }] = useLazyQuery(
    gql(listScoreboardsByTeamId),
  );
  const { id } = getValues();
  const globalSnap = useSnapshot(globalStore);
  const { assessmentId } = useParams();

  const refetchTeam = () =>
    fetchTeam({
      variables: {
        id,
      },
      onCompleted: (_teamData) => {
        const teamMembers = _teamData?.getTeam?.members?.items || [];
        const teamMembersSorted = getMemberMapping(
          [...teamMembers],
          globalSnap.userAlias,
        );
        // replace existing list with latest
        replaceMembers(teamMembersSorted);
      },
    });

  const columns = [
    {
      field: 'aliasAndEmail',
      width: 570,
      headerName: 'Team Member',
      valueGetter: (params) => `${params?.row?.aliasAndEmail?.alias}`,
      renderCell: (params) => {
        const { alias } = params?.row?.aliasAndEmail || {};
        const noAlias = alias === 'No alias yet';
        return (
          <Box>
            <Box className="flex items-center justify-center">
              <Box width="100%">
                <TeamAvatar
                  team={{ name: alias?.replace(' (You)', '') }}
                  anonymous={noAlias}
                />
              </Box>
              <FdTypography
                variant="captiontext1"
                style={{ marginLeft: '1rem' }}
              >
                {alias}
              </FdTypography>
              {noAlias && (
                <FdTooltip title="This team member will receive an alias when they register onto the platform.">
                  <IconButton size="small" style={{ marginLeft: '5px' }}>
                    <InfoOutlinedIcon />
                  </IconButton>
                </FdTooltip>
              )}
            </Box>
          </Box>
        );
      },
    },
    {
      field: 'registration',
      width: 290,
      headerName: 'Registration',
      valueGetter: (params) => params?.row?.registration,
      renderCell: (params) => (
        <FdChip
          color={
            params?.row?.registration === 'Registered' ? 'success' : 'default'
          }
          size="small"
          label={params?.row?.registration}
        />
      ),
    },
    {
      field: 'role',
      width: 200,
      renderHeader: () => <span className="font-medium ml-3">Role Type</span>,
      valueGetter: (params) => params?.row?.role,
      renderCell: (params) => <RoleChip role={params?.row?.role} />,
    },
  ];
  const onlyOneTeamManager =
    members?.filter((m) => m.role === TEAM_ROLES.TEAM_MANAGER)?.length === 1;
  const actions = [
    {
      // eslint-disable-next-line react/no-unstable-nested-components, react/prop-types
      CustomElement: ({ rowData }) => {
        const role =
          // eslint-disable-next-line react/prop-types
          rowData?.role === TEAM_ROLES.TEAM_MANAGER
            ? 'teamManager'
            : 'generalMember';
        const onChangeRole = () => {
          setChangeRoleModal({
            ...modalText[role],
            // eslint-disable-next-line react/prop-types
            memberId: rowData?.aliasAndEmail?.memberId,
          });
        };
        // get action button and icon states
        const {
          action2Label,
          disableChangeRole,
          disableAction2,
          showChangeRoleIcon,
          showChangeRoleInvitedIcon,
          showRemoveIcon,
          hideAction2,
        } = getActionStatus(
          rowData,
          globalSnap.userAlias,
          onlyOneTeamManager,
          viewOnly,
          isPrivate,
        );

        return (
          <FdDelayed delay={2000} showSkeleton>
            <Box className="flex justify-center">
              <Box className="flex">
                {!viewOnly && (
                  <Box className="flex">
                    <FdButton
                      variant="tertiary"
                      onClick={onChangeRole}
                      disabled={disableChangeRole}
                    >
                      Change Role
                    </FdButton>
                    <Box width="30px">
                      {showChangeRoleIcon && (
                        <FdTooltip title="You cannot change this Team Manager's role as they are the only Team Manager in this team. This button will become enabled once there is more than one Team Manager in this team.">
                          <IconButton size="small">
                            <InfoOutlinedIcon />
                          </IconButton>
                        </FdTooltip>
                      )}
                      {showChangeRoleInvitedIcon && (
                        <FdTooltip title="Users who are not yet registered on the platform cannot be assigned Team Manager roles. This button will be activated once this user registers onto the platform.">
                          <IconButton size="small">
                            <InfoOutlinedIcon />
                          </IconButton>
                        </FdTooltip>
                      )}
                    </Box>
                  </Box>
                )}
                {!hideAction2 && (
                  <FdButton
                    variant="tertiary"
                    onClick={() =>
                      setChangeRoleModal({
                        ...(action2Label === 'leave'
                          ? modalText.leaveTeam
                          : modalText.removeMember),
                        // eslint-disable-next-line react/prop-types
                        memberId: rowData?.aliasAndEmail?.memberId,
                      })
                    }
                    disabled={disableAction2}
                  >
                    {action2Label}
                  </FdButton>
                )}
                <Box className="flex" width="30px">
                  {showRemoveIcon && (
                    <FdTooltip
                      title={`The option to ${action2Label} is currently disabled as you are the only Team Manager in this team. Teams must have at least one Team Manager at all times. You can leave this team once there is at least one other Team Manager.`}
                    >
                      <IconButton size="small">
                        <InfoOutlinedIcon />
                      </IconButton>
                    </FdTooltip>
                  )}
                </Box>
              </Box>
            </Box>
          </FdDelayed>
        );
      },
      width: 310,
    },
  ];

  return (
    <Box height="468px" pb={3}>
      <FdDelayed delay={2000} showSkeleton height={444}>
        <FdTable
          toolbarSettings={{
            filterButton: true,
            searchBox: true,
          }}
          rows={members?.map((r) => ({
            id: r?.id,
            aliasAndEmail: { alias: r?.alias, memberId: r?.memberId },
            registration: r?.status,
            role: r?.role,
          }))}
          loading={teamsLoading || scoreboardLoading}
          columns={columns}
          tablePageSize={5}
          actions={actions || []}
        />
      </FdDelayed>
      <FdTypography
        variant="captiontext1"
        style={{ fontWeight: 700, marginTop: '1rem' }}
      >
        {`Current Member Count: ${members?.length}`}
      </FdTypography>
      <FdModal
        size="sm"
        title={changeRoleModal?.heading}
        confirm="Confirm"
        dismiss="Cancel"
        open={Boolean(changeRoleModal)}
        confirmLoading={manageUserAndTeamLoading}
        onConfirm={() => {
          const isRemove = ['EXIT_TEAM', 'REMOVE'].includes(
            changeRoleModal?.targetType,
          );
          const isExit = changeRoleModal?.targetType === 'EXIT_TEAM';
          // update with new role or leave/remove from team
          manageUserAndTeamMutation({
            variables: {
              action: isRemove ? 'REMOVE' : 'UPDATE',
              invitedTeamMemberIds: [changeRoleModal?.memberId],
              role: !isRemove ? changeRoleModal?.targetType : undefined,
              teamId: id,
              eventId: assessmentId,
              emails: [],
            },
            onCompleted: (_data) => {
              setChangeRoleModal(undefined);
              successToastMessage(changeRoleModal?.confirmMessage);
              refetchTeam();
              fetchScoreboard({
                variables: { teamId: id },
              });
              if (isExit) {
                onLeave();
              }
            },
            onError: ({ graphQLErrors }) => {
              setChangeRoleModal(undefined);
              if (graphQLErrors) {
                errorToastMessage(graphQLErrors[0]?.message);
              }
            },
          });
        }}
        onDismiss={() => {
          setChangeRoleModal(undefined);
          warningToastMessage(changeRoleModal?.cancelMessage);
        }}
      >
        <FdTypography variant="body1">
          {changeRoleModal?.description}
        </FdTypography>
      </FdModal>
    </Box>
  );
};

TeamMembers.propTypes = {
  viewOnly: PropTypes.bool.isRequired,
  onLeave: PropTypes.func.isRequired,
  isPrivate: PropTypes.bool.isRequired,
};

export default TeamMembers;
