import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Box } from '@mui/material';
import {
  FdCard,
  FdTypography,
  FdCortex,
  getCortexLevels,
  cortexSort,
  FdButton,
  FdModal,
} from '@fifthdomain/fe-shared';
import BreakDownGraphs from '../../Participant/FDTraining/BreakDownGraphs';
import { PROFICIENCY_LEVELS } from '../../../constants';
import useGetSkills from '../../../hooks/useGetSkills';
import {
  getAssessmentChallengesTechniques,
  getAssessmentChallengesTechnologies,
} from '../../../shared/utils/getAssessmentOverview';
import { BarNivo } from '../../Charts';

const TopOnes = ({ top3, heading, onClickEvent }) => (
  <Box my={1}>
    <Box className="flex items-center gap-x-2">
      <FdTypography variant="subtitle2">{heading}</FdTypography>
      <FdButton variant="secondary" onClick={onClickEvent} size="small">
        View All
      </FdButton>
    </Box>
    <FdTypography variant="captiontext1" className="my-2">
      Top 3 Covered
    </FdTypography>
    {top3?.map((t) => (
      <FdTypography variant="captiontext1" color="secondary">
        {`${t?.value} - ${t?.count} Challenge${t?.count === 1 ? '' : 's'}`}
      </FdTypography>
    ))}
  </Box>
);

TopOnes.propTypes = {
  top3: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  heading: PropTypes.string.isRequired,
  onClickEvent: PropTypes.func.isRequired,
};

const FDTrainingStructure = ({ tasks }) => {
  const [showModal, setShowModal] = useState(null);
  const tasksMapping = tasks?.items?.map((t) => ({ ...t?.task })) || [];
  const { skills, skillsLoading } = useGetSkills();
  const specialties = _(skills)
    .groupBy('specialtyId')
    .map((items, specialtyId) => ({
      specialtyId,
      specialtyName: items[0]?.specialty?.name,
      items: items?.filter((s) => s?.alias),
    }))
    .value();
  const cortexLoading = skillsLoading;
  const cortexData =
    tasks?.items
      ?.map(({ task }) =>
        task?.skills?.items?.map(({ skill }) => ({
          difficulty: task?.difficulty,
          skill: skill?.alias,
        })),
      )
      ?.flat() || [];

  const assessmentChallengesTechniques =
    getAssessmentChallengesTechniques(tasksMapping);

  const assessmentChallengesTechnologies =
    getAssessmentChallengesTechnologies(tasksMapping);

  const top3Techniques = _.map(
    _.countBy(
      tasksMapping
        ?.map((task) => ({
          id: task?.id,
          tags: [
            ...new Set(
              task.skills?.items?.flatMap(
                (skill) =>
                  skill.techniqueTags?.items?.map(
                    (techniqueTag) => techniqueTag.techniqueTag?.name,
                  ) || [],
              ),
            ),
          ],
        }))
        ?.flatMap((task) => task.tags) || [],
    ),
    (count, value) => ({ value, count }),
  )
    ?.sort((a, b) => b.count - a.count)
    ?.slice(0, 3);

  const top3Technologies = _.map(
    _.countBy(
      tasksMapping
        ?.map((task) => ({
          id: task?.id,
          tags: [
            ...new Set(
              task.technologyTags?.items?.map(
                (technologyTag) => technologyTag.technologyTag?.name,
              ) || [],
            ),
          ],
        }))
        ?.flatMap((task) => task.tags) || [],
    ),
    (count, value) => ({ value, count }),
  )
    ?.sort((a, b) => b.count - a.count)
    ?.slice(0, 3);

  const specialtiesFormatted =
    specialties?.sort(cortexSort)?.map((specialty, idx) => {
      const { levels = [], flip = false } = getCortexLevels(idx);

      const dataSorted = specialty?.items?.map((skill) => {
        return {
          id: skill?.alias,
          idWidth: '30px',
          data: [
            ...(levels?.map((l) => {
              const yValue =
                cortexData?.filter(
                  (d) => d?.difficulty === l && d?.skill === skill?.alias,
                )?.length || 0;

              return {
                x: l,
                yAlias: skill?.alias,
                yValue,
                yToolTip: (
                  <Box className="text-center">
                    <Box className="flex gap-x-1 justify-center">
                      <FdTypography variant="captiontext1" color="contrastText">
                        <strong>{`${skill?.name} (${skill?.alias})`}</strong>
                      </FdTypography>
                    </Box>
                    <Box className="flex gap-x-1 justify-center">
                      <FdTypography variant="captiontext1" color="contrastText">
                        {`Level ${l} - ${PROFICIENCY_LEVELS[l]}`}
                      </FdTypography>
                    </Box>
                    <Box>
                      <FdTypography
                        variant="captiontext1"
                        color="contrastText"
                        style={{ fontStyle: 'italic' }}
                      >
                        {`${yValue} x Challenge${yValue === 1 ? 's' : ''}`}
                      </FdTypography>
                    </Box>
                  </Box>
                ),
              };
            }) || []),
          ],
        };
      });
      return { ...specialty, flip, levels, dataSorted };
    }) || [];
  const isTechniques = showModal === 'Techniques';
  const modalDescription = isTechniques
    ? 'The bar chart below shows the techniques included in this training. Each segment represents a challenge, with its length indicating the weightage of the technique within the training.'
    : 'The bar chart below shows the technologies included in this training. Each segment represents a challenge, with its length indicating the weightage of the technique within the training.';
  const techniquesHeading = `Techniques (${assessmentChallengesTechniques.length})`;
  const technologiesHeading = `Technologies (${assessmentChallengesTechnologies.length})`;
  const modalHeading = isTechniques ? techniquesHeading : technologiesHeading;

  return (
    <Box>
      <FdCard className="my-1" variant="outlined">
        <FdTypography variant="h3">Structure</FdTypography>
        <Box my={1}>
          <FdTypography variant="body2" color="secondary">
            Below is a detailed breakdown of this training’s structure by
            specialty and proficiency. The Cyber Skills Cortex highlights the
            Skill-Proficiency areas covered by the included challenges. Each
            square indicates the number of challenges in that area, providing a
            visualisation of the training&apos;s coverage.
          </FdTypography>
        </Box>
        <Box className="flex gap-x-14">
          <Box className="flex-grow">
            <Box
              className="rounded-md p-6"
              sx={{ bgcolor: 'rgba(250, 250, 250, 1)' }}
            >
              <FdTypography variant="subtitle1">
                Training Breakdown
              </FdTypography>
              <FdTypography
                variant="captiontext1"
                color="secondary"
                className="my-1"
              >
                View this training&apos;s structure breakdown below.
              </FdTypography>
              <Box
                className="inline-block my-2 px-2 py-1 rounded-lg text-center"
                sx={{ bgcolor: 'rgba(190, 209, 255, 1)' }}
              >
                <FdTypography
                  variant="captiontext2"
                  color="black"
                  className="font-medium"
                >
                  {`${tasksMapping?.length} Challenges`}
                </FdTypography>
              </Box>
              <BreakDownGraphs allTasks={tasksMapping} />
              <TopOnes
                top3={top3Techniques}
                heading={techniquesHeading}
                onClickEvent={() => setShowModal('Techniques')}
              />
              <TopOnes
                top3={top3Technologies}
                heading={technologiesHeading}
                onClickEvent={() => setShowModal('Technologies')}
              />
            </Box>
          </Box>
          <Box maxWidth={550}>
            <Box>
              <FdTypography variant="subtitle1" className="text-center">
                Cyber Skills Cortex Overview
              </FdTypography>
              <FdTypography
                variant="captiontext1"
                color="secondary"
                className="text-center p-1"
              >
                The Cyber Skills Cortex below displays the Skill-Proficiency
                areas addressed by challenges in this training. The numbers
                within each skill-proficiency square indicate the number of
                challenges included for that specific area.
              </FdTypography>
            </Box>
            <Box my={2}>
              <FdCortex
                specialties={specialtiesFormatted}
                loading={cortexLoading}
              />
            </Box>
          </Box>
        </Box>
      </FdCard>
      <FdModal
        size="md"
        title={modalHeading}
        confirm="CLOSE"
        showDismiss={false}
        open={showModal}
        onConfirm={() => {
          setShowModal(false);
        }}
      >
        <Box>
          <FdTypography variant="body2" color="secondary">
            {modalDescription}
          </FdTypography>
          <Box height="450px" className="border rounded-md mt-1">
            <BarNivo
              data={
                isTechniques
                  ? assessmentChallengesTechniques
                  : assessmentChallengesTechnologies
              }
              keys={tasksMapping.map((t) => t.name)}
              indexBy={isTechniques ? 'technique' : 'technology'}
              colors={['#35C3A1']}
              layout="horizontal"
              borderWidth={3}
              borderColor="#fff"
              // eslint-disable-next-line react/no-unstable-nested-components
              tooltip={(d) => (
                <Box
                  style={{
                    backgroundColor: '#777',
                    color: '#fff',
                    padding: '4px 8px',
                    fontSize: '12px',
                  }}
                >
                  {d.id}
                </Box>
              )}
              enableLabel={false}
            />
          </Box>
        </Box>
      </FdModal>
    </Box>
  );
};

FDTrainingStructure.propTypes = {
  tasks: PropTypes.shape({ items: PropTypes.arrayOf(PropTypes.shape({})) })
    .isRequired,
};

export default FDTrainingStructure;
