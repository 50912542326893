import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Box, Card, CardContent } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import NavigationPrompt from 'react-router-navigation-prompt';
import * as singleSpa from 'single-spa';
import { gql, useMutation } from '@apollo/client';
import {
  BasePage,
  FdTextField,
  FdSelect,
  FdTypography,
  FdButton,
  FdModal,
  AuthContext,
  FdLoadingSpinner,
  useQueryRecursive,
} from '@fifthdomain/fe-shared';
import { listOnboardingsByUserId } from '../graphql/queries';
import { createNewOnboarding } from '../graphql/mutations';
import {
  EDUCATION_TYPE,
  CYBER_TRAINING_TYPE,
  FORMAL_TRAINING_TYPE,
  WORKING_INDUSTRY_TYPE,
} from '../constants';
import { getKeyByValue, getKeysByValues } from '../shared/utils/objectUtils';

const initialValues = {
  cyberExperienceInYears: 0,
  highestEducation: '',
  highestEducationOther: '',
  cyberTraining: [],
  formalTrainingType: [],
  formalTrainingTypeOther: '',
  selfDirectedTrainingInHours: 0,
  workingIndustry: '',
  workingIndustryOther: '',
};
Yup.addMethod(Yup.string, 'noWhitespace', function (message) {
  return this.test({
    name: 'noWhitespace',
    message,
    test: (value) => value && value.trim().length,
  });
});

const Survey = () => {
  const { user } = useContext(AuthContext);
  const { assessmentId } = useParams();
  // check link is pointing to shared url
  const urlToNavigate = assessmentId
    ? `/assessor/assessment-start/${assessmentId}`
    : '/landing/landing-homepage';

  const { loading: onboardingDataLoading } = useQueryRecursive(
    gql(listOnboardingsByUserId),
    {
      variables: {
        userId: user?.username,
      },
      skip: !user,
      onCompleted: (data) => {
        // if onboarding is complete then navigate to participant homepage
        if (data.listOnboardingsByUserId?.items.length) {
          singleSpa.navigateToUrl(urlToNavigate);
        }
      },
    },
  );
  const [createOnboardingMutation, { loading: createOnboardingLoading }] =
    useMutation(gql(createNewOnboarding), {
      refetchQueries: ['ListOnboardingsByUserId'],
      awaitRefetchQueries: true,
      onCompleted: () => {
        // eslint-disable-next-line no-use-before-define
        reset();
        singleSpa.navigateToUrl(urlToNavigate);
      },
    });

  const validationSchema = Yup.object().shape({
    cyberExperienceInYears: Yup.number()
      .min(0, 'Please enter a whole number')
      .integer('Please enter a whole number')
      .typeError('Please enter a whole number')
      .test(
        'digits-only',
        'Please enter a whole number',
        () =>
          // eslint-disable-next-line no-use-before-define
          String(getValues('cyberExperienceInYears')).match(/^\d+$/), // only digits
      ),
    highestEducation: Yup.string().required('Please select an option'),
    highestEducationOther: Yup.string().when(['highestEducation'], {
      is: (highestEducation) =>
        highestEducation === EDUCATION_TYPE.OTHER_EDUCATION,
      then: Yup.string()
        .required('Please specify your highest level of education')
        .noWhitespace('Please specify your highest level of education'),
      otherwise: Yup.string(),
    }),
    cyberTraining: Yup.array()
      .of(Yup.string())
      .min(1, 'Please specify the cyber training that you have received')
      .test(
        'no-training-with-other-values',
        'The options selected are inconsistent with each other',
        (values) =>
          !(
            values.includes('I have not received any cyber training') &&
            values.length > 1
          ),
      ),
    formalTrainingType: Yup.array()
      .of(Yup.string())
      .when(['cyberTraining'], {
        is: (cyberTraining) =>
          cyberTraining.includes(CYBER_TRAINING_TYPE.FORMAL_TRAINING),
        then: Yup.array()
          .of(Yup.string())
          .min(1, 'Please select at least one option'),
        otherwise: Yup.array().of(Yup.string()),
      }),
    formalTrainingTypeOther: Yup.string().when(['formalTrainingType'], {
      is: (formalTrainingType) =>
        formalTrainingType.includes(FORMAL_TRAINING_TYPE.OTHER_FORMAL_TRAINING),
      then: Yup.string()
        .required('Please specify the formal training that you have received')
        .noWhitespace(
          'Please specify the formal training that you have received',
        ),
      otherwise: Yup.string(),
    }),
    selfDirectedTrainingInHours: Yup.number().when(['cyberTraining'], {
      is: (cyberTraining) =>
        cyberTraining.includes(CYBER_TRAINING_TYPE.SELF_DIRECTED),
      then: Yup.number()
        .positive('Please enter a whole number')
        .integer('Please enter a whole number')
        .typeError('Please enter a number')
        .test(
          'digits-only',
          'Please enter a whole number',
          () =>
            // eslint-disable-next-line no-use-before-define
            String(getValues('selfDirectedTrainingInHours')).match(/^\d+$/), // only digits
        ),
      otherwise: Yup.number(),
    }),
    workingIndustry: Yup.string().required('Please select an option'),
    workingIndustryOther: Yup.string().when(['workingIndustry'], {
      is: (workingIndustry) =>
        workingIndustry === WORKING_INDUSTRY_TYPE.OTHER_WORKING_INDUSTRY,
      then: Yup.string()
        .required('Please specify your industry')
        .noWhitespace('Please specify your industry'),
      otherwise: Yup.string(),
    }),
  });
  const {
    control,
    formState: { isDirty },
    watch,
    getValues,
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  if (onboardingDataLoading) {
    return <FdLoadingSpinner />;
  }

  const highestEducationWatch = watch('highestEducation');
  const formalTrainingTypeWatch = watch('formalTrainingType');
  const workingIndustryWatch = watch('workingIndustry');
  const cyberTrainingWatch = watch('cyberTraining');
  const onSubmit = async () => {
    const {
      cyberExperienceInYears,
      highestEducation,
      highestEducationOther,
      cyberTraining,
      formalTrainingType,
      formalTrainingTypeOther,
      selfDirectedTrainingInHours,
      workingIndustry,
      workingIndustryOther,
    } = getValues();
    // submit onboarding
    await createOnboardingMutation({
      variables: {
        input: {
          cyberExperience: cyberExperienceInYears,
          highestEducation: String(
            getKeyByValue(EDUCATION_TYPE, highestEducation),
          ),
          highestEducationOther:
            highestEducation === EDUCATION_TYPE.OTHER_EDUCATION
              ? highestEducationOther
              : '',
          cyberTraining: getKeysByValues(
            CYBER_TRAINING_TYPE,
            cyberTraining,
          ).map(
            String, // convert to string array for json payload
          ),
          formalTraining: cyberTraining.includes(
            CYBER_TRAINING_TYPE.FORMAL_TRAINING,
          )
            ? getKeysByValues(FORMAL_TRAINING_TYPE, formalTrainingType).map(
                String, // convert to string array for json payload
              )
            : [],
          formalTrainingOther: formalTrainingType.includes(
            FORMAL_TRAINING_TYPE.OTHER_FORMAL_TRAINING,
          )
            ? formalTrainingTypeOther
            : '',
          selfDirectedTraining: {
            hours: cyberTraining.includes(CYBER_TRAINING_TYPE.SELF_DIRECTED)
              ? selfDirectedTrainingInHours
              : 0,
          },
          workingIndustry: String(
            getKeyByValue(WORKING_INDUSTRY_TYPE, workingIndustry),
          ),
          workingIndustryOther:
            workingIndustry === WORKING_INDUSTRY_TYPE.OTHER_WORKING_INDUSTRY
              ? workingIndustryOther
              : '',
        },
      },
    });
  };

  return (
    <BasePage>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={6}>
          <Card elevation={5}>
            <CardContent>
              <Box ml={2}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Box mb={2} mt={4}>
                    <FdTypography variant="h3">Tell us about you</FdTypography>
                  </Box>
                  <FdTypography variant="subtitle1">
                    How many years of experience do you have working in the
                    cyber security sector?
                  </FdTypography>
                  <Controller
                    control={control}
                    name="cyberExperienceInYears"
                    render={({ field, fieldState: { error } }) => (
                      <Box mt={1} mb={2} mr={2}>
                        <FdTextField
                          id="cyberExperienceInYears"
                          label=""
                          required
                          width="80px"
                          type="number"
                          error={error}
                          helperText={error && error.message}
                          style={{ whiteSpace: 'pre' }}
                          {...field}
                        />
                      </Box>
                    )}
                  />
                  <Controller
                    control={control}
                    name="highestEducation"
                    render={({ field, fieldState: { error } }) => (
                      <Box mt={1} mb={2} mr={2}>
                        <FdSelect
                          id="highestEducation"
                          label="What is the highest level of education you have completed?"
                          options={Object.values(EDUCATION_TYPE)}
                          error={error}
                          helperText={error && error.message}
                          fullWidth
                          {...field}
                        />
                      </Box>
                    )}
                  />
                  {highestEducationWatch === EDUCATION_TYPE.OTHER_EDUCATION && (
                    <Controller
                      control={control}
                      name="highestEducationOther"
                      render={({ field, fieldState: { error } }) => (
                        <Box mt={1} mb={2} mr={2}>
                          <FdTextField
                            id="highestEducationOther"
                            label=""
                            required
                            error={error}
                            helperText={error && error.message}
                            {...field}
                          />
                        </Box>
                      )}
                    />
                  )}
                  <Controller
                    control={control}
                    name="cyberTraining"
                    render={({ field, fieldState: { error } }) => (
                      <Box mt={1} mb={2} mr={2}>
                        <FdSelect
                          id="cyberTraining"
                          label="Have you received any cyber training to date, either completed or in progress?"
                          options={Object.values(CYBER_TRAINING_TYPE)}
                          multiple
                          showSelectAllOption={false}
                          showAllOptions
                          fullWidth
                          error={error}
                          helperText={
                            error ? error.message : 'Select all that apply'
                          }
                          {...field}
                        />
                      </Box>
                    )}
                  />
                  {cyberTrainingWatch.includes(
                    CYBER_TRAINING_TYPE.FORMAL_TRAINING,
                  ) && (
                    <Controller
                      control={control}
                      name="formalTrainingType"
                      render={({ field, fieldState: { error } }) => (
                        <Box mt={1} mb={2} mr={2}>
                          <FdSelect
                            id="formalTrainingType"
                            label="What types of formal training have you received for cyber?"
                            options={Object.values(FORMAL_TRAINING_TYPE)}
                            multiple
                            fullWidth
                            error={error}
                            helperText={
                              error ? error.message : 'Select all that apply'
                            }
                            {...field}
                          />
                        </Box>
                      )}
                    />
                  )}
                  {formalTrainingTypeWatch.includes(
                    FORMAL_TRAINING_TYPE.OTHER_FORMAL_TRAINING,
                  ) && (
                    <Controller
                      control={control}
                      name="formalTrainingTypeOther"
                      render={({ field, fieldState: { error } }) => (
                        <Box mt={1} mb={2} mr={2}>
                          <FdTextField
                            id="formalTrainingTypeOther"
                            label=""
                            required
                            error={error}
                            helperText={error && error.message}
                            {...field}
                          />
                        </Box>
                      )}
                    />
                  )}
                  {cyberTrainingWatch.includes(
                    CYBER_TRAINING_TYPE.SELF_DIRECTED,
                  ) && (
                    <Box>
                      <FdTypography variant="subtitle1">
                        On average, how many hours of self-directed learning
                        would you do outside of work each week for cyber?
                      </FdTypography>
                      <Controller
                        control={control}
                        name="selfDirectedTrainingInHours"
                        render={({ field, fieldState: { error } }) => (
                          <Box mt={1} mb={2} mr={2}>
                            <FdTextField
                              id="selfDirectedTrainingInHours"
                              label=""
                              required
                              width="80px"
                              error={error}
                              type="number"
                              helperText={error && error.message}
                              style={{ whiteSpace: 'pre' }}
                              {...field}
                            />
                          </Box>
                        )}
                      />
                    </Box>
                  )}
                  <Controller
                    control={control}
                    name="workingIndustry"
                    render={({ field, fieldState: { error } }) => (
                      <Box mt={1} mb={2} mr={2}>
                        <FdSelect
                          id="workingIndustry"
                          label="Which of the following best describes the industry you currently work in?"
                          options={Object.values(WORKING_INDUSTRY_TYPE)}
                          fullWidth
                          error={error}
                          helperText={error && error.message}
                          {...field}
                        />
                      </Box>
                    )}
                  />
                  {workingIndustryWatch ===
                    WORKING_INDUSTRY_TYPE.OTHER_WORKING_INDUSTRY && (
                    <Controller
                      control={control}
                      name="workingIndustryOther"
                      render={({ field, fieldState: { error } }) => (
                        <Box mt={1} mb={2} mr={2}>
                          <FdTextField
                            id="workingIndustryOther"
                            label=""
                            required
                            error={error}
                            helperText={error && error.message}
                            {...field}
                          />
                        </Box>
                      )}
                    />
                  )}
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    flexGrow="1"
                    mr={2}
                    mt={2}
                  >
                    <FdButton
                      size="large"
                      type="submit"
                      disabled={createOnboardingLoading}
                    >
                      Next
                    </FdButton>
                  </Box>
                </form>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <NavigationPrompt when={isDirty}>
        {({ onConfirm, onCancel }) => (
          <FdModal
            title="Are you sure you want to leave?"
            description="You have unsaved changes. Click the Stay button to go back to the form and save your changes."
            confirm="Stay"
            dismiss="Leave"
            open
            onConfirm={onCancel}
            onDismiss={onConfirm}
          />
        )}
      </NavigationPrompt>
    </BasePage>
  );
};

export default Survey;
