import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import {
  FdTextField,
  FdTypography,
  FdButton,
  FdModal,
  successToastMessage,
  warningToastMessage,
} from '@fifthdomain/fe-shared';
import EditContainer from '../Fragments/EditContainer';
import FdTextView from '../../FdTextView';
import { updateSquad, updateSquadMember } from '../../../graphql/mutations';
import { onError, updateSquadRemoved } from '../utils';

const SquadDetailsEdit = ({ squadId, refetchFunction, setOpenModal }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { control, getValues, trigger, reset } = useFormContext();
  const [updateSquadMutation, { loading: updateSquadLoading }] = useMutation(
    gql(updateSquad),
    { onError },
  );
  const [updateSquadMemberMutation, { loading: updateSquadMemberLoading }] =
    useMutation(gql(updateSquadMember), { onError });
  const onSave = async (field) => {
    const res = await trigger([`${squadId}.${field}`]);
    if (res) {
      const { squadName, squadDescription } = getValues(squadId);
      // update corresponding field
      updateSquadMutation({
        variables: {
          input: {
            id: getValues(squadId)?.squadId,
            ...(field === 'squadName'
              ? { name: squadName }
              : { description: squadDescription }),
          },
        },
        onCompleted: () => {
          successToastMessage(
            `Success! Squad ${
              field === 'squadName' ? 'name' : 'description'
            } updated.`,
          );
          refetchFunction();
        },
      });
    }
  };

  return (
    <Box className="flex flex-col mt-2">
      <Box>
        <Controller
          control={control}
          name={`${squadId}.squadName`}
          render={({ field: { ref, ...rest }, fieldState: { error } }) => (
            <EditContainer
              title="Squad Name"
              labelOnly={
                <Box>
                  <FdTextView
                    label="Squad Name"
                    value={getValues(`${squadId}.squadName`)}
                    noHeading
                  />
                </Box>
              }
              loading={updateSquadLoading}
              onCancel={() => reset()}
              onSave={() => onSave('squadName')}
              error={error}
            >
              <FdTextField
                id={`${squadId}.squadName`}
                label=""
                required
                fullWidth
                error={error}
                placeholder="Squad name must be less than 50 characters."
                helperText={
                  (error && error.message) ||
                  'Enter a name for this team that is viewable by participants.'
                }
                {...rest}
                inputRef={ref}
              />
            </EditContainer>
          )}
        />
        <Box mt={3}>
          <Controller
            control={control}
            name={`${squadId}.squadDescription`}
            render={({ field: { ref, ...rest }, fieldState: { error } }) => (
              <EditContainer
                title="Squad Description"
                labelOnly={
                  <Box>
                    <FdTextView
                      label="Squad Description"
                      value={
                        getValues(`${squadId}.squadDescription`) ||
                        'No description provided.'
                      }
                      noHeading
                    />
                  </Box>
                }
                loading={updateSquadLoading}
                onCancel={() => reset()}
                onSave={() => onSave('squadDescription')}
                error={error}
              >
                <FdTextField
                  id={`${squadId}.squadDescription`}
                  label=""
                  required
                  fullWidth
                  error={error}
                  multiline
                  rows={3}
                  placeholder="Squad description must be less than 80 characters."
                  helperText={error && error.message}
                  {...rest}
                  inputRef={ref}
                />
              </EditContainer>
            )}
          />
        </Box>
        <Box mt={4}>
          <FdTypography variant="subtitle1">Delete this Squad?</FdTypography>
          <FdButton
            style={{
              marginTop: '1rem',
              backgroundColor: 'rgba(198, 40, 40, 1)',
            }}
            onClick={() => setShowDeleteModal(true)}
          >
            Delete
          </FdButton>
        </Box>
      </Box>
      <FdModal
        size="md"
        title="Delete Squad?"
        description={
          <>
            Deleting the Squad will delete it from the list of squads in this
            organisation.
            <br />
            <br />
            The Squad Manager, and Squad Viewer(s) can no longer access this
            squad to view the skills and performance data of the squad. Squad
            Member(s) will no longer see the indication of association with this
            squad on their Home Page.
          </>
        }
        confirm="Cancel"
        dismiss="Delete"
        dismissStyles={{
          borderColor: 'rgba(198, 40, 40, 1)',
          color: 'rgba(198, 40, 40, 1)',
        }}
        dismissLoading={updateSquadLoading || updateSquadMemberLoading}
        open={showDeleteModal}
        onConfirm={() => {
          setShowDeleteModal(false);
          warningToastMessage('Squad not deleted.');
        }}
        disableDismiss={updateSquadLoading || updateSquadMemberLoading}
        onDismiss={() => {
          const users = [
            ...(getValues(squadId)?.squadMembers?.map((u) => u?.pkId) || []),
            ...(getValues(squadId)?.squadManagers?.map((u) => u?.pkId) || []),
          ];
          // delete squad by updating status to REMOVED
          updateSquadRemoved({
            updateSquadMutation,
            updateSquadUserMutation: updateSquadMemberMutation,
            squadId: getValues(squadId)?.squadId,
            users,
            onCompleted: () => {
              successToastMessage('Success! Squad deleted.');
              setShowDeleteModal(false);
              setOpenModal(null);
              refetchFunction();
            },
          });
        }}
        showDismissInRed
      />
    </Box>
  );
};

SquadDetailsEdit.propTypes = {
  setOpenModal: PropTypes.func,
  refetchFunction: PropTypes.func.isRequired,
  squadId: PropTypes.string.isRequired,
};

SquadDetailsEdit.defaultProps = {
  setOpenModal: undefined,
};

export default SquadDetailsEdit;
