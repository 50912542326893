/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const attempt = /* GraphQL */ `
  mutation Attempt($taskId: ID!, $userAssessmentId: ID!, $answer: String!) {
    attempt(
      taskId: $taskId
      userAssessmentId: $userAssessmentId
      answer: $answer
    )
  }
`;
export const startAssessmentForUser = /* GraphQL */ `
  mutation StartAssessmentForUser($userAssessmentId: ID!) {
    startAssessmentForUser(userAssessmentId: $userAssessmentId)
  }
`;

export const rollbackTraining = /* GraphQL */ `
  mutation RollbackTraining($trainingId: ID!) {
    rollbackTraining(trainingId: $trainingId)
  }
`;

export const stopAssessmentForUser = /* GraphQL */ `
  mutation StopAssessmentForUser($userAssessmentId: ID!) {
    stopAssessmentForUser(userAssessmentId: $userAssessmentId)
  }
`;
export const createNewAssessment = /* GraphQL */ `
  mutation CreateNewAssessment($assesssment: NewAssessment!) {
    createNewAssessment(assesssment: $assesssment)
  }
`;
export const editAssessment = /* GraphQL */ `
  mutation EditAssessment($assesssment: EditAssessment!) {
    editAssessment(assesssment: $assesssment)
  }
`;
export const startGroupAssessment = /* GraphQL */ `
  mutation StartGroupAssessment($groupId: ID!, $assessmentId: ID!) {
    startGroupAssessment(groupId: $groupId, assessmentId: $assessmentId) {
      groupId
      assessmentId
    }
  }
`;
export const stopGroupAssessment = /* GraphQL */ `
  mutation StopGroupAssessment($groupId: ID!, $assessmentId: ID!) {
    stopGroupAssessment(groupId: $groupId, assessmentId: $assessmentId) {
      groupId
      assessmentId
    }
  }
`;

export const addUsers = /* GraphQL */ `
  mutation AddUsers(
    $emails: [AWSEmail]
    $orgId: ID!
    $type: USER_TYPE
    $participantType: PARTICIPANT_TYPE
    $participantEventType: PARTICIPANT_EVENT_TYPE
    $permissions: [PERMISSION_TYPE]
    $assessmentId: ID
    $groups: [ID]
    $action: INVITATION_TYPE
    $affliationAction: AFFLIATION_REQUEST_ACTION
  ) {
    addUsers(
      emails: $emails
      orgId: $orgId
      type: $type
      participantType: $participantType
      participantEventType: $participantEventType
      permissions: $permissions
      assessmentId: $assessmentId
      groups: $groups
      action: $action
      affliationAction: $affliationAction
    )
  }
`;

export const deleteProductOrg = /* GraphQL */ `
  mutation DeleteProductOrg(
    $input: DeleteProductOrgInput!
    $condition: ModelProductOrgConditionInput
  ) {
    deleteProductOrg(input: $input, condition: $condition) {
      orgId
      org {
        id
        name
        members {
          nextToken
        }
        urls {
          nextToken
        }
        tasks {
          nextToken
        }
        products {
          nextToken
        }
        maxInvitees
        remainingInvitees
        createdAt
        updatedAt
      }
      productId
      product {
        id
        name
        description
        orgs {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;

export const addUser = /* GraphQL */ `
  mutation AddUser(
    $email: String!
    $orgId: ID!
    $type: USER_TYPE!
    $participantType: PARTICIPANT_TYPE
    $participantEventType: PARTICIPANT_EVENT_TYPE
    $permissions: [PERMISSION_TYPE]
    $assessmentId: ID
  ) {
    addUser(
      email: $email
      orgId: $orgId
      type: $type
      participantType: $participantType
      participantEventType: $participantEventType
      permissions: $permissions
      assessmentId: $assessmentId
    )
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser($input: EditUser!) {
    updateUser(input: $input)
  }
`;
export const removeUserFromAssessment = /* GraphQL */ `
  mutation RemoveUserFromAssessment($userAssessmentId: ID!) {
    removeUserFromAssessment(userAssessmentId: $userAssessmentId)
  }
`;
export const removeInvitedUser = /* GraphQL */ `
  mutation RemoveInvitedUser($inviteToken: ID!) {
    removeInvitedUser(inviteToken: $inviteToken)
  }
`;
export const createNewOnboarding = /* GraphQL */ `
  mutation CreateNewOnboarding($input: CreateOnboardingInput!) {
    createNewOnboarding(input: $input)
  }
`;
export const updateRoleAndPermissions = /* GraphQL */ `
  mutation UpdateRoleAndPermissions(
    $emails: [AWSEmail]!
    $permissions: [PERMISSION_TYPE]
  ) {
    updateRoleAndPermissions(emails: $emails, permissions: $permissions)
  }
`;
export const manageUserAndGroup = /* GraphQL */ `
  mutation ManageUserAndGroup(
    $groupId: ID!
    $userIds: [ID!]
    $operation: OPERATION_TYPE!
  ) {
    manageUserAndGroup(
      groupId: $groupId
      userIds: $userIds
      operation: $operation
    )
  }
`;
export const removeUserFromOrg = /* GraphQL */ `
  mutation RemoveUserFromOrg($email: AWSEmail!) {
    removeUserFromOrg(email: $email)
  }
`;
export const removeGroupsFromAssessment = /* GraphQL */ `
  mutation RemoveGroupsFromAssessment($groupIds: [ID]!, $assessmentId: ID!) {
    removeGroupsFromAssessment(groupIds: $groupIds, assessmentId: $assessmentId)
  }
`;
export const removeGroups = /* GraphQL */ `
  mutation RemoveGroups($groupIds: [ID]!) {
    removeGroups(groupIds: $groupIds)
  }
`;
export const migrateParticipant = /* GraphQL */ `
  mutation MigrateParticipant($users: [MigratedParticipant]) {
    migrateParticipant(users: $users)
  }
`;
export const createAssessment = /* GraphQL */ `
  mutation CreateAssessment(
    $input: CreateAssessmentInput!
    $condition: ModelAssessmentConditionInput
  ) {
    createAssessment(input: $input, condition: $condition) {
      id
      orgId
      name
      description
      teamBased
      startDateTime
      endDateTime
      hours
      minutes
      tasks {
        items {
          id
          assessmentId
          taskId
          createdAt
          updatedAt
        }
        nextToken
      }
      preMessage
      postMessage
      createdBy
      participantEventType
      creator {
        id
        email
        name
        phone
        ageBracket
        type
        participantType
        orgId
        alias
        org {
          id
          name
          createdAt
          updatedAt
        }
        verifiedOnboarding
        status
        permissions
        groups {
          nextToken
        }
        tasks {
          nextToken
        }
        theme
        createdAt
        updatedAt
      }
      groups {
        items {
          groupId
          assessmentId
          status
          startedOn
          finishedOn
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateAssessment = /* GraphQL */ `
  mutation UpdateAssessment(
    $input: UpdateAssessmentInput!
    $condition: ModelAssessmentConditionInput
  ) {
    updateAssessment(input: $input, condition: $condition) {
      id
      orgId
      name
      description
      teamBased
      startDateTime
      endDateTime
      hours
      minutes
      tasks {
        items {
          id
          assessmentId
          taskId
          createdAt
          updatedAt
        }
        nextToken
      }
      preMessage
      postMessage
      createdBy
      participantEventType
      creator {
        id
        email
        name
        phone
        ageBracket
        type
        participantType
        orgId
        alias
        org {
          id
          name
          createdAt
          updatedAt
        }
        verifiedOnboarding
        status
        permissions
        groups {
          nextToken
        }
        tasks {
          nextToken
        }
        theme
        createdAt
        updatedAt
      }
      groups {
        items {
          groupId
          assessmentId
          status
          startedOn
          finishedOn
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteAssessment = /* GraphQL */ `
  mutation DeleteAssessment(
    $input: DeleteAssessmentInput!
    $condition: ModelAssessmentConditionInput
  ) {
    deleteAssessment(input: $input, condition: $condition) {
      id
      orgId
      name
      description
      teamBased
      startDateTime
      endDateTime
      hours
      minutes
      tasks {
        items {
          id
          assessmentId
          taskId
          createdAt
          updatedAt
        }
        nextToken
      }
      preMessage
      postMessage
      createdBy
      participantEventType
      creator {
        id
        email
        name
        phone
        ageBracket
        type
        participantType
        orgId
        alias
        org {
          id
          name
          createdAt
          updatedAt
        }
        verifiedOnboarding
        status
        permissions
        groups {
          nextToken
        }
        tasks {
          nextToken
        }
        theme
        createdAt
        updatedAt
      }
      groups {
        items {
          groupId
          assessmentId
          status
          startedOn
          finishedOn
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createTask = /* GraphQL */ `
  mutation CreateTask(
    $input: CreateTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    createTask(input: $input, condition: $condition) {
      id
      name
      description
      externalId
      category
      difficulty
      recommendedPoints
      estimatedSolveTime
      solution
      competencies {
        items {
          id
          competencyId
          taskId
          createdAt
          updatedAt
        }
        nextToken
      }
      assessments {
        items {
          id
          assessmentId
          taskId
          createdAt
          updatedAt
        }
        nextToken
      }
      answer
      files {
        items {
          id
          taskId
          url
          name
          size
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      hints {
        items {
          id
          taskId
          text
          createdAt
          updatedAt
        }
        nextToken
      }
      userId
      user {
        id
        email
        name
        phone
        ageBracket
        type
        participantType
        orgId
        alias
        org {
          id
          name
          createdAt
          updatedAt
        }
        verifiedOnboarding
        status
        permissions
        groups {
          nextToken
        }
        tasks {
          nextToken
        }
        theme
        createdAt
        updatedAt
      }
      status
      history {
        items {
          id
          taskId
          userId
          message
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateTask = /* GraphQL */ `
  mutation UpdateTask(
    $input: UpdateTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    updateTask(input: $input, condition: $condition) {
      id
      name
      description
      externalId
      category
      difficulty
      recommendedPoints
      estimatedSolveTime
      solution
      public
      competencies {
        items {
          id
          competencyId
          taskId
          createdAt
          updatedAt
        }
        nextToken
      }
      assessments {
        items {
          id
          assessmentId
          taskId
          createdAt
          updatedAt
        }
        nextToken
      }
      answer
      files {
        items {
          id
          taskId
          url
          name
          size
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      hints {
        items {
          id
          taskId
          text
          createdAt
          updatedAt
        }
        nextToken
      }
      userId
      user {
        id
        email
        name
        phone
        ageBracket
        type
        participantType
        orgId
        alias
        org {
          id
          name
          createdAt
          updatedAt
        }
        verifiedOnboarding
        status
        permissions
        groups {
          nextToken
        }
        tasks {
          nextToken
        }
        theme
        createdAt
        updatedAt
      }
      status
      history {
        items {
          id
          taskId
          userId
          message
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteTask = /* GraphQL */ `
  mutation DeleteTask(
    $input: DeleteTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    deleteTask(input: $input, condition: $condition) {
      id
      name
      description
      externalId
      category
      difficulty
      recommendedPoints
      estimatedSolveTime
      solution
      competencies {
        items {
          id
          competencyId
          taskId
          createdAt
          updatedAt
        }
        nextToken
      }
      assessments {
        items {
          id
          assessmentId
          taskId
          createdAt
          updatedAt
        }
        nextToken
      }
      answer
      files {
        items {
          id
          taskId
          url
          name
          size
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      hints {
        items {
          id
          taskId
          text
          createdAt
          updatedAt
        }
        nextToken
      }
      userId
      user {
        id
        email
        name
        phone
        ageBracket
        type
        participantType
        orgId
        alias
        org {
          id
          name
          createdAt
          updatedAt
        }
        verifiedOnboarding
        status
        permissions
        groups {
          nextToken
        }
        tasks {
          nextToken
        }
        theme
        createdAt
        updatedAt
      }
      status
      history {
        items {
          id
          taskId
          userId
          message
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createHistory = /* GraphQL */ `
  mutation CreateHistory(
    $input: CreateHistoryInput!
    $condition: ModelHistoryConditionInput
  ) {
    createHistory(input: $input, condition: $condition) {
      id
      taskId
      userId
      message
      createdAt
      updatedAt
    }
  }
`;
export const updateHistory = /* GraphQL */ `
  mutation UpdateHistory(
    $input: UpdateHistoryInput!
    $condition: ModelHistoryConditionInput
  ) {
    updateHistory(input: $input, condition: $condition) {
      id
      taskId
      userId
      message
      createdAt
      updatedAt
    }
  }
`;
export const deleteHistory = /* GraphQL */ `
  mutation DeleteHistory(
    $input: DeleteHistoryInput!
    $condition: ModelHistoryConditionInput
  ) {
    deleteHistory(input: $input, condition: $condition) {
      id
      taskId
      userId
      message
      createdAt
      updatedAt
    }
  }
`;
export const createFile = /* GraphQL */ `
  mutation CreateFile(
    $input: CreateFileInput!
    $condition: ModelFileConditionInput
  ) {
    createFile(input: $input, condition: $condition) {
      id
      taskId
      url
      name
      size
      type
      file {
        bucket
        key
        region
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateFile = /* GraphQL */ `
  mutation UpdateFile(
    $input: UpdateFileInput!
    $condition: ModelFileConditionInput
  ) {
    updateFile(input: $input, condition: $condition) {
      id
      taskId
      url
      name
      size
      type
      file {
        bucket
        key
        region
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteFile = /* GraphQL */ `
  mutation DeleteFile(
    $input: DeleteFileInput!
    $condition: ModelFileConditionInput
  ) {
    deleteFile(input: $input, condition: $condition) {
      id
      taskId
      url
      name
      size
      type
      file {
        bucket
        key
        region
      }
      createdAt
      updatedAt
    }
  }
`;
export const createHint = /* GraphQL */ `
  mutation CreateHint(
    $input: CreateHintInput!
    $condition: ModelHintConditionInput
  ) {
    createHint(input: $input, condition: $condition) {
      id
      taskId
      text
      createdAt
      updatedAt
    }
  }
`;
export const updateHint = /* GraphQL */ `
  mutation UpdateHint(
    $input: UpdateHintInput!
    $condition: ModelHintConditionInput
  ) {
    updateHint(input: $input, condition: $condition) {
      id
      taskId
      text
      createdAt
      updatedAt
    }
  }
`;
export const deleteHint = /* GraphQL */ `
  mutation DeleteHint(
    $input: DeleteHintInput!
    $condition: ModelHintConditionInput
  ) {
    deleteHint(input: $input, condition: $condition) {
      id
      taskId
      text
      createdAt
      updatedAt
    }
  }
`;
export const createTaskAssessment = /* GraphQL */ `
  mutation CreateTaskAssessment(
    $input: CreateTaskAssessmentInput!
    $condition: ModelTaskAssessmentConditionInput
  ) {
    createTaskAssessment(input: $input, condition: $condition) {
      id
      assessmentId
      taskId
      assessment {
        id
        orgId
        name
        description
        teamBased
        startDateTime
        endDateTime
        hours
        minutes
        tasks {
          nextToken
        }
        preMessage
        postMessage
        createdBy
        participantEventType
        creator {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        createdAt
        updatedAt
      }
      task {
        id
        name
        description
        externalId
        category
        difficulty
        recommendedPoints
        estimatedSolveTime
        solution
        competencies {
          nextToken
        }
        assessments {
          nextToken
        }
        answer
        files {
          nextToken
        }
        hints {
          nextToken
        }
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        status
        history {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateTaskAssessment = /* GraphQL */ `
  mutation UpdateTaskAssessment(
    $input: UpdateTaskAssessmentInput!
    $condition: ModelTaskAssessmentConditionInput
  ) {
    updateTaskAssessment(input: $input, condition: $condition) {
      id
      assessmentId
      taskId
      assessment {
        id
        orgId
        name
        description
        teamBased
        startDateTime
        endDateTime
        hours
        minutes
        tasks {
          nextToken
        }
        preMessage
        postMessage
        createdBy
        participantEventType
        creator {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        createdAt
        updatedAt
      }
      task {
        id
        name
        description
        externalId
        category
        difficulty
        recommendedPoints
        estimatedSolveTime
        solution
        competencies {
          nextToken
        }
        assessments {
          nextToken
        }
        answer
        files {
          nextToken
        }
        hints {
          nextToken
        }
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        status
        history {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteTaskAssessment = /* GraphQL */ `
  mutation DeleteTaskAssessment(
    $input: DeleteTaskAssessmentInput!
    $condition: ModelTaskAssessmentConditionInput
  ) {
    deleteTaskAssessment(input: $input, condition: $condition) {
      id
      assessmentId
      taskId
      assessment {
        id
        orgId
        name
        description
        teamBased
        startDateTime
        endDateTime
        hours
        minutes
        tasks {
          nextToken
        }
        preMessage
        postMessage
        createdBy
        participantEventType
        creator {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        createdAt
        updatedAt
      }
      task {
        id
        name
        description
        externalId
        category
        difficulty
        recommendedPoints
        estimatedSolveTime
        solution
        competencies {
          nextToken
        }
        assessments {
          nextToken
        }
        answer
        files {
          nextToken
        }
        hints {
          nextToken
        }
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        status
        history {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createTaskAttempt = /* GraphQL */ `
  mutation CreateTaskAttempt(
    $input: CreateTaskAttemptInput!
    $condition: ModelTaskAttemptConditionInput
  ) {
    createTaskAttempt(input: $input, condition: $condition) {
      id
      userId
      task {
        id
        name
        description
        externalId
        category
        difficulty
        recommendedPoints
        estimatedSolveTime
        solution
        competencies {
          nextToken
        }
        assessments {
          nextToken
        }
        answer
        files {
          nextToken
        }
        hints {
          nextToken
        }
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        status
        history {
          nextToken
        }
        createdAt
        updatedAt
      }
      userAssessment {
        id
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        assessment {
          id
          orgId
          name
          description
          teamBased
          startDateTime
          endDateTime
          hours
          minutes
          preMessage
          postMessage
          createdBy
          participantEventType
          createdAt
          updatedAt
        }
        userAssessmentAssessmentId
        groupId
        status
        startedOn
        finishedOn
        createdAt
        updatedAt
      }
      taskAttemptUserAssessmentId
      assessmentId
      success
      firstBlood
      answer
      createdAt
      updatedAt
      taskAttemptTaskId
    }
  }
`;
export const updateTaskAttempt = /* GraphQL */ `
  mutation UpdateTaskAttempt(
    $input: UpdateTaskAttemptInput!
    $condition: ModelTaskAttemptConditionInput
  ) {
    updateTaskAttempt(input: $input, condition: $condition) {
      id
      userId
      task {
        id
        name
        description
        externalId
        category
        difficulty
        recommendedPoints
        estimatedSolveTime
        solution
        competencies {
          nextToken
        }
        assessments {
          nextToken
        }
        answer
        files {
          nextToken
        }
        hints {
          nextToken
        }
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        status
        history {
          nextToken
        }
        createdAt
        updatedAt
      }
      userAssessment {
        id
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        assessment {
          id
          orgId
          name
          description
          teamBased
          startDateTime
          endDateTime
          hours
          minutes
          preMessage
          postMessage
          createdBy
          participantEventType
          createdAt
          updatedAt
        }
        userAssessmentAssessmentId
        groupId
        status
        startedOn
        finishedOn
        createdAt
        updatedAt
      }
      taskAttemptUserAssessmentId
      assessmentId
      success
      firstBlood
      answer
      createdAt
      updatedAt
      taskAttemptTaskId
    }
  }
`;
export const deleteTaskAttempt = /* GraphQL */ `
  mutation DeleteTaskAttempt(
    $input: DeleteTaskAttemptInput!
    $condition: ModelTaskAttemptConditionInput
  ) {
    deleteTaskAttempt(input: $input, condition: $condition) {
      id
      userId
      task {
        id
        name
        description
        externalId
        category
        difficulty
        recommendedPoints
        estimatedSolveTime
        solution
        competencies {
          nextToken
        }
        assessments {
          nextToken
        }
        answer
        files {
          nextToken
        }
        hints {
          nextToken
        }
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        status
        history {
          nextToken
        }
        createdAt
        updatedAt
      }
      userAssessment {
        id
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        assessment {
          id
          orgId
          name
          description
          teamBased
          startDateTime
          endDateTime
          hours
          minutes
          preMessage
          postMessage
          createdBy
          participantEventType
          createdAt
          updatedAt
        }
        userAssessmentAssessmentId
        groupId
        status
        startedOn
        finishedOn
        createdAt
        updatedAt
      }
      taskAttemptUserAssessmentId
      assessmentId
      success
      firstBlood
      answer
      createdAt
      updatedAt
      taskAttemptTaskId
    }
  }
`;
export const createTaskOpened = /* GraphQL */ `
  mutation CreateTaskOpened(
    $input: CreateTaskOpenedInput!
    $condition: ModelTaskOpenedConditionInput
  ) {
    createTaskOpened(input: $input, condition: $condition) {
      id
      userId
      task {
        id
        name
        description
        externalId
        category
        difficulty
        recommendedPoints
        estimatedSolveTime
        solution
        competencies {
          nextToken
        }
        assessments {
          nextToken
        }
        answer
        files {
          nextToken
        }
        hints {
          nextToken
        }
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        status
        history {
          nextToken
        }
        createdAt
        updatedAt
      }
      userAssessment {
        id
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        assessment {
          id
          orgId
          name
          description
          teamBased
          startDateTime
          endDateTime
          hours
          minutes
          preMessage
          postMessage
          createdBy
          participantEventType
          createdAt
          updatedAt
        }
        userAssessmentAssessmentId
        groupId
        status
        startedOn
        finishedOn
        createdAt
        updatedAt
      }
      taskOpenedUserAssessmentId
      createdAt
      updatedAt
      taskOpenedTaskId
    }
  }
`;
export const updateTaskOpened = /* GraphQL */ `
  mutation UpdateTaskOpened(
    $input: UpdateTaskOpenedInput!
    $condition: ModelTaskOpenedConditionInput
  ) {
    updateTaskOpened(input: $input, condition: $condition) {
      id
      userId
      task {
        id
        name
        description
        externalId
        category
        difficulty
        recommendedPoints
        estimatedSolveTime
        solution
        competencies {
          nextToken
        }
        assessments {
          nextToken
        }
        answer
        files {
          nextToken
        }
        hints {
          nextToken
        }
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        status
        history {
          nextToken
        }
        createdAt
        updatedAt
      }
      userAssessment {
        id
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        assessment {
          id
          orgId
          name
          description
          teamBased
          startDateTime
          endDateTime
          hours
          minutes
          preMessage
          postMessage
          createdBy
          participantEventType
          createdAt
          updatedAt
        }
        userAssessmentAssessmentId
        groupId
        status
        startedOn
        finishedOn
        createdAt
        updatedAt
      }
      taskOpenedUserAssessmentId
      createdAt
      updatedAt
      taskOpenedTaskId
    }
  }
`;
export const deleteTaskOpened = /* GraphQL */ `
  mutation DeleteTaskOpened(
    $input: DeleteTaskOpenedInput!
    $condition: ModelTaskOpenedConditionInput
  ) {
    deleteTaskOpened(input: $input, condition: $condition) {
      id
      userId
      task {
        id
        name
        description
        externalId
        category
        difficulty
        recommendedPoints
        estimatedSolveTime
        solution
        competencies {
          nextToken
        }
        assessments {
          nextToken
        }
        answer
        files {
          nextToken
        }
        hints {
          nextToken
        }
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        status
        history {
          nextToken
        }
        createdAt
        updatedAt
      }
      userAssessment {
        id
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        assessment {
          id
          orgId
          name
          description
          teamBased
          startDateTime
          endDateTime
          hours
          minutes
          preMessage
          postMessage
          createdBy
          participantEventType
          createdAt
          updatedAt
        }
        userAssessmentAssessmentId
        groupId
        status
        startedOn
        finishedOn
        createdAt
        updatedAt
      }
      taskOpenedUserAssessmentId
      createdAt
      updatedAt
      taskOpenedTaskId
    }
  }
`;
export const createCompetency = /* GraphQL */ `
  mutation CreateCompetency(
    $input: CreateCompetencyInput!
    $condition: ModelCompetencyConditionInput
  ) {
    createCompetency(input: $input, condition: $condition) {
      id
      tasks {
        items {
          id
          competencyId
          taskId
          createdAt
          updatedAt
        }
        nextToken
      }
      questions {
        items {
          id
          competencyID
          questionID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCompetency = /* GraphQL */ `
  mutation UpdateCompetency(
    $input: UpdateCompetencyInput!
    $condition: ModelCompetencyConditionInput
  ) {
    updateCompetency(input: $input, condition: $condition) {
      id
      tasks {
        items {
          id
          competencyId
          taskId
          createdAt
          updatedAt
        }
        nextToken
      }
      questions {
        items {
          id
          competencyID
          questionID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCompetency = /* GraphQL */ `
  mutation DeleteCompetency(
    $input: DeleteCompetencyInput!
    $condition: ModelCompetencyConditionInput
  ) {
    deleteCompetency(input: $input, condition: $condition) {
      id
      tasks {
        items {
          id
          competencyId
          taskId
          createdAt
          updatedAt
        }
        nextToken
      }
      questions {
        items {
          id
          competencyID
          questionID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createCompetencyTask = /* GraphQL */ `
  mutation CreateCompetencyTask(
    $input: CreateCompetencyTaskInput!
    $condition: ModelCompetencyTaskConditionInput
  ) {
    createCompetencyTask(input: $input, condition: $condition) {
      id
      competencyId
      taskId
      task {
        id
        name
        description
        externalId
        category
        difficulty
        recommendedPoints
        estimatedSolveTime
        solution
        competencies {
          nextToken
        }
        assessments {
          nextToken
        }
        answer
        files {
          nextToken
        }
        hints {
          nextToken
        }
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        status
        history {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCompetencyTask = /* GraphQL */ `
  mutation UpdateCompetencyTask(
    $input: UpdateCompetencyTaskInput!
    $condition: ModelCompetencyTaskConditionInput
  ) {
    updateCompetencyTask(input: $input, condition: $condition) {
      id
      competencyId
      taskId
      task {
        id
        name
        description
        externalId
        category
        difficulty
        recommendedPoints
        estimatedSolveTime
        solution
        competencies {
          nextToken
        }
        assessments {
          nextToken
        }
        answer
        files {
          nextToken
        }
        hints {
          nextToken
        }
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        status
        history {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCompetencyTask = /* GraphQL */ `
  mutation DeleteCompetencyTask(
    $input: DeleteCompetencyTaskInput!
    $condition: ModelCompetencyTaskConditionInput
  ) {
    deleteCompetencyTask(input: $input, condition: $condition) {
      id
      competencyId
      taskId
      task {
        id
        name
        description
        externalId
        category
        difficulty
        recommendedPoints
        estimatedSolveTime
        solution
        competencies {
          nextToken
        }
        assessments {
          nextToken
        }
        answer
        files {
          nextToken
        }
        hints {
          nextToken
        }
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        status
        history {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createOrg = /* GraphQL */ `
  mutation CreateOrg(
    $input: CreateOrgInput!
    $condition: ModelOrgConditionInput
  ) {
    createOrg(input: $input, condition: $condition) {
      id
      name
      maxInvitees
      remainingInvitees
      pricingTier
      createdAt
      updatedAt
    }
  }
`;

export const allocateTasksToOrg = /* GraphQL */ `
  mutation AllocateTasksToOrg(
    $orgId: ID
    $orgAlias: String
    $taskIds: [ID]
    $orgIds: [ID]
  ) {
    allocateTasksToOrg(
      orgId: $orgId
      orgAlias: $orgAlias
      taskIds: $taskIds
      orgIds: $orgIds
    )
  }
`;

export const createProductOrg = /* GraphQL */ `
  mutation CreateProductOrg(
    $input: CreateProductOrgInput!
    $condition: ModelProductOrgConditionInput
  ) {
    createProductOrg(input: $input, condition: $condition) {
      orgId
      org {
        id
        name
        maxInvitees
        remainingInvitees
        createdAt
        updatedAt
      }
      productId
      product {
        id
        name
        description
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;

export const updateOrg = /* GraphQL */ `
  mutation UpdateOrg(
    $input: UpdateOrgInput!
    $condition: ModelOrgConditionInput
  ) {
    updateOrg(input: $input, condition: $condition) {
      id
      name
      members {
        items {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        nextToken
      }
      pricingTier
      createdAt
      updatedAt
    }
  }
`;
export const deleteOrg = /* GraphQL */ `
  mutation DeleteOrg(
    $input: DeleteOrgInput!
    $condition: ModelOrgConditionInput
  ) {
    deleteOrg(input: $input, condition: $condition) {
      id
      name
      members {
        items {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createUserConsent = /* GraphQL */ `
  mutation CreateUserConsent(
    $input: CreateUserConsentInput!
    $condition: ModelUserConsentConditionInput
  ) {
    createUserConsent(input: $input, condition: $condition) {
      id
      userId
      type
      consentedOn
      version
      createdAt
      updatedAt
    }
  }
`;
export const updateUserConsent = /* GraphQL */ `
  mutation UpdateUserConsent(
    $input: UpdateUserConsentInput!
    $condition: ModelUserConsentConditionInput
  ) {
    updateUserConsent(input: $input, condition: $condition) {
      id
      userId
      type
      consentedOn
      version
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserConsent = /* GraphQL */ `
  mutation DeleteUserConsent(
    $input: DeleteUserConsentInput!
    $condition: ModelUserConsentConditionInput
  ) {
    deleteUserConsent(input: $input, condition: $condition) {
      id
      userId
      type
      consentedOn
      version
      createdAt
      updatedAt
    }
  }
`;
export const createConsent = /* GraphQL */ `
  mutation CreateConsent(
    $input: CreateConsentInput!
    $condition: ModelConsentConditionInput
  ) {
    createConsent(input: $input, condition: $condition) {
      id
      type
      version
      createdAt
      updatedAt
    }
  }
`;
export const updateConsent = /* GraphQL */ `
  mutation UpdateConsent(
    $input: UpdateConsentInput!
    $condition: ModelConsentConditionInput
  ) {
    updateConsent(input: $input, condition: $condition) {
      id
      type
      version
      createdAt
      updatedAt
    }
  }
`;
export const deleteConsent = /* GraphQL */ `
  mutation DeleteConsent(
    $input: DeleteConsentInput!
    $condition: ModelConsentConditionInput
  ) {
    deleteConsent(input: $input, condition: $condition) {
      id
      type
      version
      createdAt
      updatedAt
    }
  }
`;
export const createRole = /* GraphQL */ `
  mutation CreateRole(
    $input: CreateRoleInput!
    $condition: ModelRoleConditionInput
  ) {
    createRole(input: $input, condition: $condition) {
      id
      name
      permissions
      createdAt
      updatedAt
    }
  }
`;
export const updateRole = /* GraphQL */ `
  mutation UpdateRole(
    $input: UpdateRoleInput!
    $condition: ModelRoleConditionInput
  ) {
    updateRole(input: $input, condition: $condition) {
      id
      name
      permissions
      createdAt
      updatedAt
    }
  }
`;
export const deleteRole = /* GraphQL */ `
  mutation DeleteRole(
    $input: DeleteRoleInput!
    $condition: ModelRoleConditionInput
  ) {
    deleteRole(input: $input, condition: $condition) {
      id
      name
      permissions
      createdAt
      updatedAt
    }
  }
`;
export const createGroup = /* GraphQL */ `
  mutation CreateGroup(
    $input: CreateGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    createGroup(input: $input, condition: $condition) {
      id
      name
      description
      orgId
      users {
        items {
          id
          groupId
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateGroup = /* GraphQL */ `
  mutation UpdateGroup(
    $input: UpdateGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    updateGroup(input: $input, condition: $condition) {
      id
      name
      description
      users {
        items {
          id
          groupId
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteGroup = /* GraphQL */ `
  mutation DeleteGroup(
    $input: DeleteGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    deleteGroup(input: $input, condition: $condition) {
      id
      name
      description
      users {
        items {
          id
          groupId
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createGroupAssessment = /* GraphQL */ `
  mutation CreateGroupAssessment(
    $input: CreateGroupAssessmentInput!
    $condition: ModelGroupAssessmentConditionInput
  ) {
    createGroupAssessment(input: $input, condition: $condition) {
      groupId
      group {
        id
        name
        description
        users {
          nextToken
        }
        createdAt
        updatedAt
      }
      assessmentId
      assessment {
        id
        orgId
        name
        description
        teamBased
        startDateTime
        endDateTime
        hours
        minutes
        tasks {
          nextToken
        }
        preMessage
        postMessage
        createdBy
        participantEventType
        creator {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        createdAt
        updatedAt
      }
      status
      startedOn
      finishedOn
      createdAt
      updatedAt
    }
  }
`;
export const updateGroupAssessment = /* GraphQL */ `
  mutation UpdateGroupAssessment(
    $input: UpdateGroupAssessmentInput!
    $condition: ModelGroupAssessmentConditionInput
  ) {
    updateGroupAssessment(input: $input, condition: $condition) {
      groupId
      group {
        id
        name
        description
        users {
          nextToken
        }
        createdAt
        updatedAt
      }
      assessmentId
      assessment {
        id
        orgId
        name
        description
        teamBased
        startDateTime
        endDateTime
        hours
        minutes
        tasks {
          nextToken
        }
        preMessage
        postMessage
        createdBy
        participantEventType
        creator {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        createdAt
        updatedAt
      }
      status
      startedOn
      finishedOn
      createdAt
      updatedAt
    }
  }
`;
export const deleteGroupAssessment = /* GraphQL */ `
  mutation DeleteGroupAssessment(
    $input: DeleteGroupAssessmentInput!
    $condition: ModelGroupAssessmentConditionInput
  ) {
    deleteGroupAssessment(input: $input, condition: $condition) {
      groupId
      group {
        id
        name
        description
        users {
          nextToken
        }
        createdAt
        updatedAt
      }
      assessmentId
      assessment {
        id
        orgId
        name
        description
        teamBased
        startDateTime
        endDateTime
        hours
        minutes
        tasks {
          nextToken
        }
        preMessage
        postMessage
        createdBy
        participantEventType
        creator {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        createdAt
        updatedAt
      }
      status
      startedOn
      finishedOn
      createdAt
      updatedAt
    }
  }
`;
export const createQuiz = /* GraphQL */ `
  mutation CreateQuiz(
    $input: CreateQuizInput!
    $condition: ModelQuizConditionInput
  ) {
    createQuiz(input: $input, condition: $condition) {
      id
      orgId
      name
      ownerId
      description
      duration
      multipleAttempts
      showResults
      user {
        id
        email
        name
        phone
        ageBracket
        type
        participantType
        orgId
        alias
        org {
          id
          name
          createdAt
          updatedAt
        }
        verifiedOnboarding
        status
        permissions
        groups {
          nextToken
        }
        tasks {
          nextToken
        }
        theme
        createdAt
        updatedAt
      }
      questions {
        items {
          id
          quizId
          type
          name
          point
          hasOptions
          caseSensitive
          whiteSpaceSensitive
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateQuiz = /* GraphQL */ `
  mutation UpdateQuiz(
    $input: UpdateQuizInput!
    $condition: ModelQuizConditionInput
  ) {
    updateQuiz(input: $input, condition: $condition) {
      id
      orgId
      name
      ownerId
      description
      duration
      multipleAttempts
      showResults
      user {
        id
        email
        name
        phone
        ageBracket
        type
        participantType
        orgId
        alias
        org {
          id
          name
          createdAt
          updatedAt
        }
        verifiedOnboarding
        status
        permissions
        groups {
          nextToken
        }
        tasks {
          nextToken
        }
        theme
        createdAt
        updatedAt
      }
      questions {
        items {
          id
          quizId
          type
          name
          point
          hasOptions
          caseSensitive
          whiteSpaceSensitive
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteQuiz = /* GraphQL */ `
  mutation DeleteQuiz(
    $input: DeleteQuizInput!
    $condition: ModelQuizConditionInput
  ) {
    deleteQuiz(input: $input, condition: $condition) {
      id
      orgId
      name
      ownerId
      description
      duration
      multipleAttempts
      showResults
      user {
        id
        email
        name
        phone
        ageBracket
        type
        participantType
        orgId
        alias
        org {
          id
          name
          createdAt
          updatedAt
        }
        verifiedOnboarding
        status
        permissions
        groups {
          nextToken
        }
        tasks {
          nextToken
        }
        theme
        createdAt
        updatedAt
      }
      questions {
        items {
          id
          quizId
          type
          name
          point
          hasOptions
          caseSensitive
          whiteSpaceSensitive
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createQuestion = /* GraphQL */ `
  mutation CreateQuestion(
    $input: CreateQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    createQuestion(input: $input, condition: $condition) {
      id
      quizId
      type
      name
      point
      hasOptions
      caseSensitive
      whiteSpaceSensitive
      options {
        items {
          questionId
          questionOptionType
          optionName
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      answers {
        items {
          questionId
          answerOptionType
          answer
          orderNumber
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      competencies {
        items {
          id
          competencyID
          questionID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateQuestion = /* GraphQL */ `
  mutation UpdateQuestion(
    $input: UpdateQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    updateQuestion(input: $input, condition: $condition) {
      id
      quizId
      type
      name
      point
      hasOptions
      caseSensitive
      whiteSpaceSensitive
      options {
        items {
          questionId
          questionOptionType
          optionName
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      answers {
        items {
          questionId
          answerOptionType
          answer
          orderNumber
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      competencies {
        items {
          id
          competencyID
          questionID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteQuestion = /* GraphQL */ `
  mutation DeleteQuestion(
    $input: DeleteQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    deleteQuestion(input: $input, condition: $condition) {
      id
      quizId
      type
      name
      point
      hasOptions
      caseSensitive
      whiteSpaceSensitive
      options {
        items {
          questionId
          questionOptionType
          optionName
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      answers {
        items {
          questionId
          answerOptionType
          answer
          orderNumber
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      competencies {
        items {
          id
          competencyID
          questionID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createQuestionOption = /* GraphQL */ `
  mutation CreateQuestionOption(
    $input: CreateQuestionOptionInput!
    $condition: ModelQuestionOptionConditionInput
  ) {
    createQuestionOption(input: $input, condition: $condition) {
      questionId
      questionOptionType
      optionName
      id
      createdAt
      updatedAt
    }
  }
`;
export const updateQuestionOption = /* GraphQL */ `
  mutation UpdateQuestionOption(
    $input: UpdateQuestionOptionInput!
    $condition: ModelQuestionOptionConditionInput
  ) {
    updateQuestionOption(input: $input, condition: $condition) {
      questionId
      questionOptionType
      optionName
      id
      createdAt
      updatedAt
    }
  }
`;
export const deleteQuestionOption = /* GraphQL */ `
  mutation DeleteQuestionOption(
    $input: DeleteQuestionOptionInput!
    $condition: ModelQuestionOptionConditionInput
  ) {
    deleteQuestionOption(input: $input, condition: $condition) {
      questionId
      questionOptionType
      optionName
      id
      createdAt
      updatedAt
    }
  }
`;
export const createQuestionAnswer = /* GraphQL */ `
  mutation CreateQuestionAnswer(
    $input: CreateQuestionAnswerInput!
    $condition: ModelQuestionAnswerConditionInput
  ) {
    createQuestionAnswer(input: $input, condition: $condition) {
      questionId
      answerOptionType
      answer
      orderNumber
      id
      createdAt
      updatedAt
    }
  }
`;
export const updateQuestionAnswer = /* GraphQL */ `
  mutation UpdateQuestionAnswer(
    $input: UpdateQuestionAnswerInput!
    $condition: ModelQuestionAnswerConditionInput
  ) {
    updateQuestionAnswer(input: $input, condition: $condition) {
      questionId
      answerOptionType
      answer
      orderNumber
      id
      createdAt
      updatedAt
    }
  }
`;
export const deleteQuestionAnswer = /* GraphQL */ `
  mutation DeleteQuestionAnswer(
    $input: DeleteQuestionAnswerInput!
    $condition: ModelQuestionAnswerConditionInput
  ) {
    deleteQuestionAnswer(input: $input, condition: $condition) {
      questionId
      answerOptionType
      answer
      orderNumber
      id
      createdAt
      updatedAt
    }
  }
`;
export const createCompetencyQuestions = /* GraphQL */ `
  mutation CreateCompetencyQuestions(
    $input: CreateCompetencyQuestionsInput!
    $condition: ModelCompetencyQuestionsConditionInput
  ) {
    createCompetencyQuestions(input: $input, condition: $condition) {
      id
      competencyID
      questionID
      question {
        id
        quizId
        type
        name
        point
        hasOptions
        caseSensitive
        whiteSpaceSensitive
        options {
          nextToken
        }
        answers {
          nextToken
        }
        competencies {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCompetencyQuestions = /* GraphQL */ `
  mutation UpdateCompetencyQuestions(
    $input: UpdateCompetencyQuestionsInput!
    $condition: ModelCompetencyQuestionsConditionInput
  ) {
    updateCompetencyQuestions(input: $input, condition: $condition) {
      id
      competencyID
      questionID
      question {
        id
        quizId
        type
        name
        point
        hasOptions
        caseSensitive
        whiteSpaceSensitive
        options {
          nextToken
        }
        answers {
          nextToken
        }
        competencies {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCompetencyQuestions = /* GraphQL */ `
  mutation DeleteCompetencyQuestions(
    $input: DeleteCompetencyQuestionsInput!
    $condition: ModelCompetencyQuestionsConditionInput
  ) {
    deleteCompetencyQuestions(input: $input, condition: $condition) {
      id
      competencyID
      questionID
      question {
        id
        quizId
        type
        name
        point
        hasOptions
        caseSensitive
        whiteSpaceSensitive
        options {
          nextToken
        }
        answers {
          nextToken
        }
        competencies {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const manageOrgUrl = /* GraphQL */ `
  mutation ManageOrgUrl(
    $orgId: ID!
    $theme: String!
    $key: String!
    $action: ACTION_TYPE!
  ) {
    manageOrgUrl(orgId: $orgId, theme: $theme, key: $key, action: $action)
  }
`;

export const removeLogoFromOrg = /* GraphQL */ `
  mutation RemoveLogoFromOrg($orgId: ID!, $theme: String!) {
    removeLogoFromOrg(orgId: $orgId, theme: $theme)
  }
`;

export const createThemeOrg = /* GraphQL */ `
  mutation CreateThemeOrg(
    $input: CreateThemeOrgInput!
    $condition: ModelThemeOrgConditionInput
  ) {
    createThemeOrg(input: $input, condition: $condition) {
      id
      orgId
      org {
        id
        name
        alias
        members {
          nextToken
        }
        urls {
          nextToken
        }
        tasks {
          nextToken
        }
        products {
          nextToken
        }
        maxInvitees
        remainingInvitees
        dashboards {
          nextToken
        }
        themeId
        theme {
          id
          name
          special
          createdAt
          updatedAt
        }
        specialThemes {
          nextToken
        }
        createdAt
        updatedAt
      }
      themeId
      theme {
        id
        name
        special
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;

export const deleteThemeOrg = /* GraphQL */ `
  mutation DeleteThemeOrg(
    $input: DeleteThemeOrgInput!
    $condition: ModelThemeOrgConditionInput
  ) {
    deleteThemeOrg(input: $input, condition: $condition) {
      id
      orgId
      org {
        id
        name
        alias
        members {
          nextToken
        }
        urls {
          nextToken
        }
        tasks {
          nextToken
        }
        products {
          nextToken
        }
        maxInvitees
        remainingInvitees
        dashboards {
          nextToken
        }
        themeId
        theme {
          id
          name
          special
          createdAt
          updatedAt
        }
        specialThemes {
          nextToken
        }
        createdAt
        updatedAt
      }
      themeId
      theme {
        id
        name
        special
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;

export const createTemplate = /* GraphQL */ `
  mutation CreateTemplate(
    $input: CreateTemplateInput!
    $condition: ModelTemplateConditionInput
  ) {
    createTemplate(input: $input, condition: $condition) {
      id
      name
      description
      teamBased
      hours
      minutes
      story
      preMessage
      postMessage
      participantEventType
      creator {
        id
        email
        name
        orgId
        alias
      }
      createdBy
      hideScoreBoard
      status
      tasks {
        items {
          id
          templateId
          taskId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const createTaskTemplate = /* GraphQL */ `
  mutation CreateTaskTemplate(
    $input: CreateTaskTemplateInput!
    $condition: ModelTaskTemplateConditionInput
  ) {
    createTaskTemplate(input: $input, condition: $condition) {
      id
      templateId
      taskId
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const updateTemplate = /* GraphQL */ `
  mutation UpdateTemplate(
    $input: UpdateTemplateInput!
    $condition: ModelTemplateConditionInput
  ) {
    updateTemplate(input: $input, condition: $condition) {
      id
      name
      description
      teamBased
      hours
      minutes
      story
      preMessage
      postMessage
      participantEventType
      creator {
        id
        email
        name
        orgId
        alias
      }
      createdBy
      hideScoreBoard
      status
      tasks {
        items {
          id
          templateId
          taskId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const deleteTaskTemplate = /* GraphQL */ `
  mutation DeleteTaskTemplate(
    $input: DeleteTaskTemplateInput!
    $condition: ModelTaskTemplateConditionInput
  ) {
    deleteTaskTemplate(input: $input, condition: $condition) {
      id
      templateId
      taskId
      template {
        id
        name
        description
        teamBased
        hours
        minutes
        story
        preMessage
        postMessage
        participantEventType
        createdBy
        hideScoreBoard
        status
        createdAt
        updatedAt
        __typename
      }
      task {
        id
        name
        description
        externalId
        orgId
        category
        difficulty
        recommendedPoints
        estimatedSolveTime
        solution
        answer
        userId
        modulePartId
        labId
        status
        type
        public
        specialtyId
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const createCustomerSkill = /* GraphQL */ `
  mutation CreateCustomerSkill(
    $input: CreateCustomerSkillInput!
    $condition: ModelCustomerSkillConditionInput
  ) {
    createCustomerSkill(input: $input, condition: $condition) {
      id
      name
    }
  }
`;

export const createCustomerSkillFdSKill = /* GraphQL */ `
  mutation CreateCustomerSkillFdSKill(
    $input: CreateCustomerSkillFdSkillInput!
    $condition: ModelCustomerSkillFdSkillConditionInput
  ) {
    createCustomerSkillFdSkill(input: $input, condition: $condition) {
      id
    }
  }
`;

export const updateCustomerSkill = /* GraphQL */ `
  mutation UpdateCustomerSkill(
    $input: UpdateCustomerSkillInput!
    $condition: ModelCustomerSkillConditionInput
  ) {
    updateCustomerSkill(input: $input, condition: $condition) {
      id
      name
    }
  }
`;

export const updateCustomerSkillFdSKill = /* GraphQL */ `
  mutation UpdateCustomerSkillFdSKill(
    $input: UpdateCustomerSkillFdSkillInput!
    $condition: ModelCustomerSkillFdSkillConditionInput
  ) {
    updateCustomerSkillFdSkill(input: $input, condition: $condition) {
      id
    }
  }
`;

export const deleteCustomerSkill = /* GraphQL */ `
  mutation DeleteCustomerSkill(
    $input: DeleteCustomerSkillInput!
    $condition: ModelCustomerSkillConditionInput
  ) {
    deleteCustomerSkill(input: $input, condition: $condition) {
      id
      name
    }
  }
`;

export const deleteCustomerSkillFdSKill = /* GraphQL */ `
  mutation DeleteCustomerSkillFdSKill(
    $input: DeleteCustomerSkillFdSkillInput!
    $condition: ModelCustomerSkillFdSkillConditionInput
  ) {
    deleteCustomerSkillFdSkill(input: $input, condition: $condition) {
      id
    }
  }
`;

export const createCustomerWorkrole = /* GraphQL */ `
  mutation CreateCustomerWorkrole(
    $input: CreateCustomerWorkroleInput!
    $condition: ModelCustomerWorkroleConditionInput
  ) {
    createCustomerWorkrole(input: $input, condition: $condition) {
      id
      name
    }
  }
`;

export const createCustomerSkillWorkrole = /* GraphQL */ `
  mutation CreateCustomerSkillWorkrole(
    $input: CreateCustomerSkillWorkroleInput!
    $condition: ModelCustomerSkillWorkroleConditionInput
  ) {
    createCustomerSkillWorkrole(input: $input, condition: $condition) {
      id
    }
  }
`;

export const updateCustomerWorkrole = /* GraphQL */ `
  mutation UpdateCustomerWorkrole(
    $input: UpdateCustomerWorkroleInput!
    $condition: ModelCustomerWorkroleConditionInput
  ) {
    updateCustomerWorkrole(input: $input, condition: $condition) {
      id
      name
    }
  }
`;

export const updateCustomerSkillWorkrole = /* GraphQL */ `
  mutation UpdateCustomerSkillWorkrole(
    $input: UpdateCustomerSkillWorkroleInput!
    $condition: ModelCustomerSkillWorkroleConditionInput
  ) {
    updateCustomerSkillWorkrole(input: $input, condition: $condition) {
      id
    }
  }
`;

export const deleteCustomerWorkrole = /* GraphQL */ `
  mutation DeleteCustomerWorkrole(
    $input: DeleteCustomerWorkroleInput!
    $condition: ModelCustomerWorkroleConditionInput
  ) {
    deleteCustomerWorkrole(input: $input, condition: $condition) {
      id
    }
  }
`;

export const deleteCustomerSkillWorkrole = /* GraphQL */ `
  mutation DeleteCustomerSkillWorkrole(
    $input: DeleteCustomerSkillWorkroleInput!
    $condition: ModelCustomerSkillWorkroleConditionInput
  ) {
    deleteCustomerSkillWorkrole(input: $input, condition: $condition) {
      id
    }
  }
`;

export const deleteTaskOrg = /* GraphQL */ `
  mutation DeleteTaskOrg(
    $input: DeleteTaskOrgInput!
    $condition: ModelTaskOrgConditionInput
  ) {
    deleteTaskOrg(input: $input, condition: $condition) {
      id
      taskId
      orgId
      createdAt
      updatedAt
    }
  }
`;
