import React from 'react';
import StylesProvider from '@mui/styles/StylesProvider';
import createGenerateClassName from '@mui/styles/createGenerateClassName';
import { BrowserRouter } from 'react-router-dom';
import { ReactQueryProvider, ToastContainer } from '@fifthdomain/fe-shared';
import { AuthProvider } from '@fifthdomain/auth';
import Sidebar from './pages/Sidebar';
import GraphQlClient from './shared/GraphQlClient';
import AppThemeProvider from './providers/AppThemeProvider';
import './sidebar.css';

const generateClassName = createGenerateClassName({
  productionPrefix: 'sidebar',
  seed: 'sidebar',
});

const App = () => {
  return (
    <GraphQlClient>
      <ReactQueryProvider>
        <AppThemeProvider>
          <BrowserRouter>
            <StylesProvider generateClassName={generateClassName}>
              <AuthProvider>
                <ToastContainer />
                <Sidebar />
              </AuthProvider>
            </StylesProvider>
          </BrowserRouter>
        </AppThemeProvider>
      </ReactQueryProvider>
    </GraphQlClient>
  );
};

export default App;
