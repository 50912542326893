/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import * as singleSpa from 'single-spa';
import PropTypes from 'prop-types';
import { useMutation, gql } from '@apollo/client';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams, useLocation } from 'react-router-dom';
import _ from 'lodash';
import {
  BasePage,
  FdButton,
  FdModal,
  FdTab,
  useQueryRecursive,
  useSnapshot,
  globalStore,
  amplifyConfig,
  FdChip,
  FdSkeleton,
  Authorization,
  PERMISSIONS_AFFILIATED,
  useRecentLinks,
  successToastMessage,
  warningToastMessage,
  errorToastMessage,
} from '@fifthdomain/fe-shared';
import { FdBreadcrumbHeader } from '@fifthdomain/sidebar';
import {
  createHint,
  createCompetencyTask,
  updateTask,
  updateHint,
  createFile,
  createTaskSkill,
  createTechniqueTag,
  createTaskSkillTechniqueTag,
  createTechnologyTag,
  createTaskTechnologyTag,
  deleteTaskSkill,
  deleteTaskSkillTechniqueTag,
  deleteTaskTechnologyTag,
  createHistory,
} from '../graphql/mutations';
import {
  getTask,
  listLabPrototypes,
  listTechniqueTags,
} from '../graphql/queries';
import { CHALLENGE_TYPE, PROFICIENCY, PROFICIENCY_LEVELS } from '../constants';
import { getProficiencyLevel } from '../shared/utils/difficultyMapping';
import { initialValues, validationSchema } from '../validation-schemas';
import {
  createMultipleAttachments,
  createMultipleCompetencies,
  createMultipleHints,
  createMultipleSkills,
  createMultipleTechnologyTags,
  generateHistory,
  removeMultipleSkills,
  removeMultipleTechnologyTags,
  updateExistingSkills,
  updateMultipleHints,
} from '../components/Task/utils';
import DetailsAndResources from '../components/Task/Review/DetailsAndResources';
import MappingAndSupportInfo from '../components/Task/Review/MappingAndSupportInfo';
import CommentsAndHistory from '../components/Task/Review/CommentsAndHistory';

const ReviewTask = () => {
  const [cancel, setCancel] = useState(false);
  const [prevCompetencies, setPrevCompetencies] = useState([]);
  const [prevHints, setPrevHints] = useState([]);
  const [prevAttachments, setPrevAttachments] = useState([]);
  const [prevSkills, setPrevSkills] = useState([]);
  const [prevTechnologyTags, setPrevTechnologyTags] = useState([]);
  const [showDisapproveModal, setShowDisapproveModal] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const { mode, taskId } = useParams();
  const { search, pathname } = useLocation();
  const isReview = mode === 'review';
  const isEdit = mode === 'edit';

  const { userId, permissions, orgId } = useSnapshot(globalStore);
  const { addRecentLink } = useRecentLinks({ userId });

  // List Labs which are with status READY
  const { data: labsData } = useQueryRecursive(gql(listLabPrototypes), {
    variables: {
      filter: {
        orgId: { eq: orgId },
        status: { eq: 'READY' },
      },
    },
  });

  // if invalid mode then send back to tasks
  useEffect(() => {
    if (!isReview && !isEdit) {
      singleSpa.navigateToUrl('/tasks');
    }
  }, [isEdit, isReview]);

  const canUserManageContent = Authorization.hasPermission(permissions, [
    PERMISSIONS_AFFILIATED.MANAGE_CONTENT,
  ]);
  const canUserReviewContent = Authorization.canReviewContent(permissions);

  const reactHookFormMethods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });
  const { reset, getValues, trigger } = reactHookFormMethods;

  const {
    data: taskData,
    loading: taskLoading,
    refetch: refetchTask,
  } = useQueryRecursive(gql(getTask), {
    variables: {
      id: taskId,
    },
    staleTime: { seconds: 0 },
    skip: !taskId,
    onCompleted: (data) => {
      const {
        name,
        description,
        summary,
        difficulty,
        estimatedSolveTime,
        competencies: editCompetencies,
        hints: editHints,
        answer,
        solution,
        solutionVideo,
        solutionSteps,
        files,
        status,
        modulePartId,
        lab,
        skills,
        specialty,
        technologyTags,
        userId: creatorId,
        reviewers,
        history,
      } = data.getTask;

      // if not valid status for this page send back to tasks page
      if (['DRAFT', 'DISAPPROVED'].includes(status)) {
        singleSpa.navigateToUrl('/tasks');
      }

      const selectedCompetencies =
        editCompetencies?.items?.map((item) => ({
          id: item.id,
          competencyId: item.competency.id,
          competency: item.competency.description,
          categoryId: item?.categoryId,
          workRoleId: item?.workRoleId,
          areaId: item?.areaId,
        })) || [];

      const uploadAttachments =
        files?.items
          ?.filter((item) => item.type.startsWith('application'))
          ?.map((file) => ({
            id: file.id,
            name: file.name,
            url: file.url,
            fileSize: file.size,
            fileType: file.type,
          })) || [];

      const hints =
        editHints?.items?.map((h) => ({
          id: h.id,
          description: h.text,
          releasedOnStart: h.automaticRelease,
        })) || [];

      const prevSkillsData =
        skills?.items?.map((skill) => ({
          dbId: skill.id,
          skillId: skill.skill.id,
          techniqueTags:
            skill?.techniqueTags?.items?.map((techniqueTag) => ({
              dbId: techniqueTag.id,
              techniqueId: techniqueTag.techniqueTag.id,
              name: techniqueTag.techniqueTag.name,
            })) || [],
        })) || [];

      const prevTechTags =
        technologyTags?.items?.map((technologyTag) => ({
          dbId: technologyTag.id,
          technologyId: technologyTag.technologyTag.id,
          name: technologyTag.technologyTag.name,
        })) || [];

      const prevSpecialtyData = {
        value: specialty?.name,
        optionData: {
          specialtyId: specialty?.id,
          name: specialty?.name,
          skills: specialty?.skills?.items?.map((skill) => ({
            skillId: skill.id,
            name: skill.name,
            description: skill.description,
          })),
        },
      };

      const taskDetails = {
        name,
        description,
        summary,
        difficulty: PROFICIENCY_LEVELS[difficulty],
        challengeType: (modulePartId || lab) && {
          id: 'challengeType',
          value: CHALLENGE_TYPE.LAB_BASED,
        },
        estimatedSolveTime,
        selectedCompetencies,
        uploadAttachments,
        uploadSolnAttachments:
          files?.items
            ?.filter((item) => item.type === 'text/markdown')
            ?.map((file) => ({
              id: file.id,
              name: file.name,
              url: file.url,
              fileSize: file.size,
              fileType: file.type,
            })) || [],
        hints,
        flag: answer,
        solnWalkthrough: solution,
        solnVideo: solutionVideo,
        solnSteps: solutionSteps,
        status,
        lab: lab?.name,
        labInfo: lab,
        partId: modulePartId,
        specialty: prevSpecialtyData,
        skills: prevSkillsData,
        technologyTags: prevTechTags,
        reviewers: reviewers?.items?.map((r) => ({
          id: r?.assigneeId,
          name: r?.assignee?.name,
          email: r?.assignee?.email,
          assigneeId: r?.assigneeId,
          assignedBy: r?.userId,
          pkId: r?.id,
        })),
        chat: history?.items?.map((h) => ({
          historyId: h.id,
          action: h?.action,
          comment: h?.comment,
          assigneeName: h?.assignee?.name,
          userName: h?.user?.name,
          createdAt: h?.createdAt,
          userId: h?.userId,
          creatorId,
        })),
      };
      reset(taskDetails);
      // add recent link
      addRecentLink({
        id: taskId,
        name,
        type: 'CHALLENGE',
        url: pathname + search,
        role: 'MANAGE',
      });
      setPrevCompetencies(selectedCompetencies);
      setPrevHints(hints);
      setPrevAttachments(uploadAttachments);
      setPrevSkills(prevSkillsData);
      setPrevTechnologyTags(prevTechTags);
    },
  });

  const isReviewerWithoutManageContent =
    !canUserManageContent &&
    canUserReviewContent &&
    getValues('reviewers')?.some((r) => r?.assigneeId === userId);

  const { data: techniqueTagsData } = useQueryRecursive(gql(listTechniqueTags));

  const [createCompetencyTaskMutation] = useMutation(gql(createCompetencyTask));
  const [createHintMutation] = useMutation(gql(createHint));
  const [createFileMutation] = useMutation(gql(createFile));
  const [createTaskSkillMutation] = useMutation(gql(createTaskSkill));
  const [createTaskSkillTechniqueTagMutation] = useMutation(
    gql(createTaskSkillTechniqueTag),
  );
  const [createTechniqueTagMutation] = useMutation(gql(createTechniqueTag));
  const [createTechnologyTagMutation] = useMutation(gql(createTechnologyTag));
  const [createTaskTechnologyTagMutation] = useMutation(
    gql(createTaskTechnologyTag),
  );

  const [deleteTaskSkillMutation] = useMutation(gql(deleteTaskSkill));
  const [deleteTaskSkillTechniqueTagMutation] = useMutation(
    gql(deleteTaskSkillTechniqueTag),
  );
  const [deleteTaskTechnologyTagMutation] = useMutation(
    gql(deleteTaskTechnologyTag),
  );

  const [updateHintMutation] = useMutation(gql(updateHint));
  const [createHistoryMutation] = useMutation(gql(createHistory));

  // update Task
  const [updateTaskMutation] = useMutation(gql(updateTask), {
    onError: ({ graphQLErrors }) => {
      errorToastMessage(graphQLErrors?.[0]?.message);
    },
  });

  const onSubmit = async (fields) => {
    const data = getValues();
    const {
      flag,
      selectedCompetencies,
      hints,
      uploadAttachments,
      difficulty,
      specialty,
      skills,
      technologyTags,
    } = data;

    const newCompetencies = selectedCompetencies?.filter(
      (__competency) =>
        !prevCompetencies?.some(
          (prevCompetency) => prevCompetency.id === __competency.id,
        ),
    );

    const newAttachments = uploadAttachments?.filter(
      (attachment) =>
        !prevAttachments?.some(
          (prevAttachment) => prevAttachment.id === attachment.id,
        ),
    );

    const specialtyId = specialty?.optionData?.specialtyId;

    // eslint-disable-next-line array-callback-return
    const updateTaskParams = fields?.reduce((input, dirtyField) => {
      switch (dirtyField) {
        case 'name': {
          if (taskData?.getTask?.name !== data?.name?.trim()) {
            return {
              ...input,
              [dirtyField]: data[dirtyField]?.trim(),
            };
          }
          break;
        }
        case 'description':
          return {
            ...input,
            [dirtyField]: data[dirtyField]?.trim(),
          };
        case 'summary':
          return {
            ...input,
            [dirtyField]: data[dirtyField]?.trim(),
          };
        case 'solnWalkthrough':
          return {
            ...input,
            solution: data[dirtyField]?.trim(),
          };
        case 'solnVideo':
          return {
            ...input,
            solutionVideo: data[dirtyField]?.trim(),
          };
        case 'solnSteps':
          return {
            ...input,
            solutionSteps: data[dirtyField]?.trim(),
          };
        case 'difficulty':
          return {
            ...input,
            difficulty: getProficiencyLevel(difficulty),
            recommendedPoints: PROFICIENCY[difficulty]?.points,
          };
        case 'estimatedSolveTime':
          return {
            ...input,
            [dirtyField]: Number(data[dirtyField]),
          };
        case 'flag':
          return {
            ...input,
            answer: flag,
          };
        case 'specialty':
          return {
            ...input,
            specialtyId,
          };
        case 'lab':
        case 'labInfo':
          return {
            ...input,
          };
        default:
          return {
            ...input,
          };
      }
    }, {});

    const allLabs = labsData?.listLabPrototypes?.items || [];
    const labName = getValues('lab');
    const labId = labName
      ? allLabs?.filter((l) => l.name === labName)[0]?.id
      : null;

    await updateTaskMutation({
      variables: {
        input: {
          id: taskId,
          ...updateTaskParams,
          labId: labName ? labId : null,
          type: labName ? 'LAB' : 'STATIC',
        },
      },
      onCompleted: () => {
        // create history for edit task
        generateHistory({
          createHistoryMutation,
          action: 'EDITED',
          taskId,
          userId,
        });
        // eslint-disable-next-line no-use-before-define
        addDetailsToTask({
          taskId,
          newCompetencies,
          selectedHints: hints,
          prevHints,
          newAttachments,
          skills,
          technologyTags,
        });
      },
    });
  };

  const techniqueTags = _.uniqBy(
    techniqueTagsData?.listTechniqueTags?.items.map((t) => ({
      ...t,
      techniqueId: t.id,
    })) || [],
    (item) => item.name.toLowerCase(),
  );

  const addDetailsToTask = ({
    // eslint-disable-next-line no-shadow
    taskId,
    newCompetencies = [],
    selectedHints = [],
    // eslint-disable-next-line no-shadow
    prevHints = [],
    newAttachments = [],
    skills = [],
    technologyTags = [],
  }) => {
    if (skills?.length > 0) {
      const newSkills = skills.filter(
        (skill) =>
          !prevSkills.some((prevSkill) => prevSkill.skillId === skill.skillId),
      );

      const deletingSkills = prevSkills.filter(
        (prevSkill) =>
          !skills.some((skill) => skill.skillId === prevSkill.skillId),
      );

      const existingSkills = skills.filter((skill) =>
        prevSkills.some((prevSkill) => prevSkill.skillId === skill.skillId),
      );

      if (existingSkills?.length > 0) {
        updateExistingSkills(
          deleteTaskSkillTechniqueTagMutation,
          prevSkills,
          techniqueTags,
          createTechniqueTagMutation,
          userId,
          createTaskSkillTechniqueTagMutation,
          existingSkills,
        );
      }

      if (newSkills?.length > 0) {
        createMultipleSkills(
          createTaskSkillMutation,
          techniqueTags,
          createTechniqueTagMutation,
          createTaskSkillTechniqueTagMutation,
          userId,
          taskId,
          newSkills,
        );
      }

      if (deletingSkills?.length > 0) {
        removeMultipleSkills(
          deleteTaskSkillMutation,
          deleteTaskSkillTechniqueTagMutation,
          deletingSkills,
        );
      }
    }

    if (technologyTags?.length > 0) {
      const newTechnologies = technologyTags.filter(
        (techTag) =>
          !prevTechnologyTags.some(
            (prevTechTag) => techTag.technologyId === prevTechTag.technologyId,
          ),
      );

      const deletingTechnologies = prevTechnologyTags.filter(
        (prevTechnologyTag) =>
          !technologyTags.some(
            (techTag) =>
              techTag.technologyId === prevTechnologyTag.technologyId,
          ),
      );

      if (newTechnologies?.length > 0) {
        createMultipleTechnologyTags(
          createTechnologyTagMutation,
          createTaskTechnologyTagMutation,
          userId,
          taskId,
          newTechnologies,
        );
      }

      if (deletingTechnologies?.length > 0) {
        removeMultipleTechnologyTags(
          deleteTaskTechnologyTagMutation,
          deletingTechnologies,
        );
      }
    }

    if (selectedHints?.length > 0) {
      // creat new hints if not exist
      const newHints = selectedHints?.filter(
        (hint) => !prevHints.some((item) => item.id === hint.id),
      );

      createMultipleHints(createHintMutation, taskId, newHints);

      // update hints if existing hint is updated with the new description
      const hintsToUpdate = selectedHints?.filter((hint) =>
        prevHints.some(
          (item) =>
            item.id === hint.id && item.description !== hint.description,
        ),
      );

      if (hintsToUpdate?.length > 0) {
        updateMultipleHints(updateHintMutation, taskId, hintsToUpdate);
      }
    }

    if (newCompetencies?.length > 0) {
      createMultipleCompetencies(
        createCompetencyTaskMutation,
        taskId,
        newCompetencies,
      );
    }

    if (newAttachments?.length > 0) {
      const uploadAttachments = newAttachments?.filter((item) =>
        item.fileType.startsWith('application'),
      );

      createMultipleAttachments(
        createFileMutation,
        amplifyConfig,
        taskId,
        uploadAttachments,
      );
    }
    setTimeout(() => {
      successToastMessage('Your challenge has been saved successfully');
      refetchTask();
    }, 2000);
  };
  const isApproved = getValues('status') === 'APPROVED';

  const hidePublishDisapprove =
    isApproved ||
    (!canUserManageContent
      ? !getValues('reviewers')?.some((r) => r?.assigneeId === userId)
      : false);
  const challengeName = getValues('name');

  return (
    <Box>
      <FdBreadcrumbHeader
        entries={[{ name: 'Challenges', type: 'CHALLENGE' }]}
        page={{ name: challengeName, type: 'CHALLENGE' }}
      />
      <BasePage
        heading={
          <FdSkeleton height={40} loading={taskLoading}>
            {isEdit ? (
              <Box className="flex items-center">
                <span>{`Edit ${getValues('name')}`}</span>
                {getValues('status') === 'IN_REVIEW' && (
                  <FdChip
                    label="In-Review"
                    size="small"
                    className="ml-5"
                    color="primary"
                  />
                )}
                {getValues('status') === 'APPROVED' && (
                  <FdChip
                    label="Published"
                    size="small"
                    className="ml-5"
                    color="success"
                  />
                )}
              </Box>
            ) : (
              `Review ${challengeName}`
            )}
          </FdSkeleton>
        }
        headingAdornment={
          !hidePublishDisapprove && (
            <Box className="flex">
              <FdButton
                size="large"
                variant="secondary"
                color="error"
                style={{
                  color: 'rgba(198, 40, 40, 1)',
                  borderColor: 'rgba(198, 40, 40, 1)',
                }}
                onClick={async () => {
                  const res = await trigger();
                  if (res) {
                    setShowDisapproveModal(true);
                  }
                }}
              >
                Reject
              </FdButton>
              <Box ml={2}>
                <FdButton
                  size="large"
                  color="success"
                  style={{
                    backgroundColor: 'rgba(46, 125, 50, 1)',
                    borderColor: 'rgba(46, 125, 50, 1)',
                  }}
                  onClick={async () => {
                    const res = await trigger();
                    if (res) {
                      setShowApproveModal(true);
                    }
                  }}
                >
                  Publish
                </FdButton>
              </Box>
            </Box>
          )
        }
      >
        <FormProvider {...reactHookFormMethods}>
          <form>
            <FdTab
              label={[
                {
                  label: 'Details and Resources',
                  index: 0,
                  data: (
                    <FdSkeleton height={400} loading={taskLoading}>
                      <DetailsAndResources
                        isReviewer={isReviewerWithoutManageContent}
                        onSubmit={onSubmit}
                      />
                    </FdSkeleton>
                  ),
                },
                {
                  label: 'Mapping and Support Info',
                  index: 1,
                  data: <MappingAndSupportInfo onSubmit={onSubmit} />,
                },
                {
                  label: 'Comments and History',
                  index: 2,
                  data: (
                    <CommentsAndHistory
                      taskId={taskId}
                      refetchTask={refetchTask}
                      inReview={!isApproved}
                    />
                  ),
                },
              ]}
            />
            <FdModal
              title="Ready to publish?"
              description="When you publish a challenge, it becomes part of the published challenges library in your organisation. This challenge would be available to be attached to assessments and competitions."
              confirm="Publish"
              dismiss="Cancel"
              open={showApproveModal}
              onConfirm={async () => {
                await updateTaskMutation({
                  variables: {
                    input: {
                      id: taskId,
                      status: 'APPROVED',
                    },
                  },
                  onCompleted: () => {
                    // create history for approved task
                    generateHistory({
                      createHistoryMutation,
                      action: 'APPROVED',
                      taskId,
                      userId,
                    });
                    successToastMessage(
                      `Success! ${getValues('name')} is now published.`,
                    );
                    singleSpa.navigateToUrl('/tasks');
                  },
                });
                setShowApproveModal(false);
              }}
              onDismiss={() => {
                setShowApproveModal(false);
                warningToastMessage(`${getValues('name')} is not published.`);
              }}
              setOpen={setShowApproveModal}
            />
            <FdModal
              title="Disapprove this challenge?"
              description="After a challenge is disapproved, it will be moved to the disapproved tab in the Challenges library."
              confirm="yes,  disapprove"
              dismiss="Cancel"
              open={showDisapproveModal}
              onConfirm={async () => {
                await updateTaskMutation({
                  variables: {
                    input: {
                      id: taskId,
                      status: 'DISAPPROVED',
                    },
                  },
                  onCompleted: () => {
                    // create history for disapproved task
                    generateHistory({
                      createHistoryMutation,
                      action: 'DISAPPROVED',
                      taskId,
                      userId,
                    });
                    successToastMessage(
                      `Success! ${getValues('name')} has been disapproved.`,
                    );
                    singleSpa.navigateToUrl('/tasks');
                  },
                });
                setShowDisapproveModal(false);
              }}
              onDismiss={() => {
                setShowDisapproveModal(false);
                warningToastMessage(`${getValues('name')} is not disapproved`);
              }}
              setOpen={setShowDisapproveModal}
            />
            <FdModal
              title="Are you sure you want to leave?"
              description="You have unsaved changes. Are you sure you want to leave this page? Any changes you have made will be lost."
              confirm="Stay"
              dismiss="Leave"
              open={cancel}
              onConfirm={async () => {
                setCancel(false);
              }}
              onDismiss={() => {
                setCancel(false);
                warningToastMessage(
                  'Changes to your challenge have not been saved',
                );
                singleSpa.navigateToUrl('/tasks');
              }}
              setOpen={setCancel}
            />
          </form>
        </FormProvider>
      </BasePage>
    </Box>
  );
};

ReviewTask.propTypes = {
  formMode: PropTypes.oneOf(['review', 'edit']).isRequired,
};
export default ReviewTask;
