import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  useAuthSession,
  useSnapshot,
  globalStore,
  FdLoadingSpinner,
} from '@fifthdomain/fe-shared';
import { getAffiliatedParams } from '../shared/utils/urlUtils';

const AdminRoute = ({ component: Component, ...rest }) => {
  const { user } = useAuthSession();
  const globalSnap = useSnapshot(globalStore);
  const affiliatedParams = getAffiliatedParams();

  if (globalSnap.loading) {
    return <FdLoadingSpinner />;
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        const type = globalSnap.userType;
        if (user && type === 'PARTICIPANT') {
          if (affiliatedParams?.isAffiliated) {
            return (
              <Redirect
                to={`/landing/landing-homepage?isAffiliated=${affiliatedParams?.isAffiliated}&orgId=${affiliatedParams?.orgId}`}
              />
            );
          }
          return <Redirect to="/landing/landing-homepage" />;
        }
        if (user && type === 'ADMIN') {
          return <Component {...rest} {...props} />;
        }
        return null;
      }}
    />
  );
};

AdminRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
};

export default AdminRoute;
