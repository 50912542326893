import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import _ from 'lodash';
import { addMonths } from 'date-fns';
import {
  FdButton,
  FdAlert,
  useQueryRecursive,
  useSnapshot,
  globalStore,
  successToastMessage,
  errorToastMessage,
  warningToastMessage,
} from '@fifthdomain/fe-shared';
import { gql, useMutation } from '@apollo/client';
import { getCommaSeparated } from '../../shared/utils/stringUtils';
import ViewTaskDrawer from '../Assessment/ViewTaskDrawer';
import {
  createTaskAssessment,
  deleteTaskAssessment,
  createModulePart,
  deleteModulePart,
  createHintReveal,
  manageTasksLevels,
} from '../../graphql/mutations';
import TasksTable from '../Assessment/TasksTable';
import { listHintReveals } from '../../queries/customQueries';
import { getDeletingTasks, getNewTasks } from '../../shared/utils/taskUtils';
import { listTasksByOrgId, listTaskOrgs } from '../../graphql/queries';
import { scrollToTop } from '../../shared/utils/scroll';
import { getArrayByLength } from '../../shared/utils/objectUtils';
import { getDifficultyLabel } from '../../shared/utils/difficultyMapping';
import AssessmentOverview from '../Assessment/AssessmentOverview';

const drawerWidth = 400;

const ViewChallenges = ({
  assessmentData,
  assessmentTasks,
  showEditButton,
  status,
  refetchAssessmentData,
}) => {
  const globalSnap = useSnapshot(globalStore);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [editTasks, setEditTasks] = useState(false);
  const [activeTask, setActiveTask] = useState();
  const [updateInProgress, setUpdateInProgress] = useState(false);
  const {
    trigger,
    getValues,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();
  const { endDateTime, id: assessmentId } = assessmentData?.getAssessment || {
    teamBased: false,
    startDateTime: undefined,
    endDateTime: undefined,
  };

  const { data: listHintRevealsData, refetch: refetchListHintReveals } =
    useQueryRecursive(gql(listHintReveals));

  // Reveal Hints to the Participants
  const [createHintRevealMutation] = useMutation(gql(createHintReveal), {
    onCompleted: (_data) => {
      refetchListHintReveals();
    },
    onError: ({ graphQLErrors }) => {
      errorToastMessage(graphQLErrors[0]?.message);
    },
  });
  const [createModulePartMutation] = useMutation(gql(createModulePart));
  const [deleteModulePartMutation] = useMutation(gql(deleteModulePart));
  const [createTaskAssessmentMutation] = useMutation(gql(createTaskAssessment));
  const [deleteTaskAssessmentMutation] = useMutation(gql(deleteTaskAssessment));
  const [manageTasksLevelsMutation] = useMutation(gql(manageTasksLevels));

  const watchTasks = [...new Set(watch('tasks'))];
  const taskLabs = watch('taskLabs');

  const {
    data: allTasksAvailableToOrg,
    loading: allTasksAvailableToOrgLoading,
    refetch: allTasksAvailableRefetch,
  } = useQueryRecursive(gql(listTaskOrgs), {
    variables: {
      orgId: globalSnap?.orgId,
      limit: 1000,
    },
    skip: !globalSnap?.orgId,
  });

  const {
    data: allTasksData,
    loading: allTasksLoading,
    refetch: refetchListTasksByOrgId,
  } = useQueryRecursive(gql(listTasksByOrgId), {
    variables: {
      orgId: globalSnap?.orgId,
      filter: {
        status: { eq: 'APPROVED' },
      },
    },
    skip: !globalSnap?.orgId,
    onCompleted: () => scrollToTop(),
  });

  const getIncompleteLevels = () =>
    // eslint-disable-next-line no-shadow
    getArrayByLength(Number(getValues('levels')), (_, i) => i + 1)
      .filter(
        (el) => ![...new Set(watchTasks?.map((t) => t.level))].includes(el),
      )
      .map((l) => `Level ${l}`);

  const levelErrorMessage = errors.tasks
    ? getCommaSeparated(getIncompleteLevels())
    : '';

  const allTasksMadeAvailable =
    allTasksAvailableToOrg?.listTaskOrgs?.items?.map(
      (taskOrg) => taskOrg?.task,
    );

  const allTasks = _.uniqBy(
    [
      ...(allTasksData?.listTasksByOrgId?.items.map((t) => ({
        ...t,
        owned: true,
      })) || []),
      ...(allTasksMadeAvailable?.map((t) => ({
        ...t,
        owned: false,
      })) || []),
      ...(assessmentTasks || []),
    ],
    'id',
  ).filter((_task) => _task.type !== 'CONTAINER');
  // merge with task data
  const tasksCombinedWithLevels = getValues('tasks').map((tl) => ({
    ...tl,
    ...allTasks.find((at) => at.id === tl.taskId),
    id: tl.taskId,
  }));

  const tableData = (editTasks ? allTasks : tasksCombinedWithLevels)?.map(
    (t) => ({
      ...t,
      difficultyInteger: t?.difficulty,
      difficultyLabel: getDifficultyLabel(t?.difficulty),
      specialtyName: t?.specialty?.name,
      skills: t?.skills?.items.map((s) => s.skill),
      techniqueTags: t?.skills?.items
        .map((s) => s.techniqueTags?.items.map((st) => st.techniqueTag?.name))
        .flat(),
      technologyTags: t?.technologyTags?.items.map(
        (tt) => tt.technologyTag?.name,
      ),
      creator: t.user?.name,
      ownerOrg: t?.org?.name,
      ownerOrgId: t?.org?.id,
      creatorOrg: t?.user?.org?.name,
      creatorOrgId: t?.user?.org?.id,
      labId: t?.labId,
      lab: t?.lab,
      tags:
        t?.tags?.items?.filter((t1) => t1?.Tag?.orgId === globalSnap?.orgId) ||
        [],
    }),
  );

  const resetTasks = () => {
    setEditTasks(false);
    setUpdateInProgress(false);
    refetchListTasksByOrgId();
    refetchAssessmentData();
  };

  const showToastAndReset = (_updateResult, levelBased = false) => {
    // show toast for both success and failed
    if (_updateResult.some((p) => p?.status === 'rejected')) {
      resetTasks();
      errorToastMessage('Competition update failed');
      return;
    }
    if (_updateResult.every((p) => p?.status === 'fulfilled')) {
      resetTasks();
      let message = 'Competition updated';
      if (levelBased) {
        const _tasks = getValues('tasks') || [];
        const _levels = getValues('levels');
        message = `${_tasks.length} challenges added to ${_levels} levels.`;
      }
      successToastMessage(message);
    }
  };

  const onUpdateWithLevel = async () => {
    // eslint-disable-next-line no-shadow
    const levels = getArrayByLength(getValues('levels'), (_, i) => i + 1);
    const tasks = getValues('tasks');
    const existingTasks =
      assessmentData?.getAssessment?.levels?.items
        ?.map((l) =>
          l.tasks.items.map((t) => ({
            levelId: t.id,
            taskId: t.taskId,
          })),
        )
        .flat() || [];
    const taskIdsForDeletion = existingTasks.filter(
      (et) => et.levelId && !tasks?.map((t) => t.taskId).includes(et.taskId),
    );

    if (taskIdsForDeletion.length) {
      // delete taskIds which no longer exists
      await manageTasksLevelsMutation({
        variables: {
          action: 'REMOVE',
          assessmentId,
          taskIds: taskIdsForDeletion?.map((t) => t.taskId),
        },
      });
    }

    const updateLevel = async (_taskIdsForUpdate, _level) => {
      return new Promise((resolve, reject) => {
        manageTasksLevelsMutation({
          variables: {
            action: 'UPDATE',
            assessmentId,
            taskIds: _taskIdsForUpdate,
            levelNumber: _level,
          },
          onCompleted: () => {
            resolve();
          },
          onError: () => reject(),
        });
      });
    };

    const updatePromises = [];
    // update with taskIds for each level
    levels.forEach((level) => {
      updatePromises.push(
        updateLevel(
          tasks.filter((t) => t.level === level)?.map((t) => t.taskId) || [],
          level,
        ),
      );
    });

    const updateResult = await Promise.allSettled(updatePromises);
    // show toast for both success and failed
    showToastAndReset(updateResult, true);
  };

  const onUpdateNoLevel = async () => {
    const taskIdValues = getValues('tasks');
    if (taskIdValues.length === 0) {
      return;
    }
    const existingAssessmentTasks = assessmentData?.getAssessment?.tasks?.items;
    const deletePromises = [];
    const updatePromises = [];
    const deleteTask = async (_id, modulePartId, task) => {
      return new Promise((resolve, reject) => {
        deleteTaskAssessmentMutation({
          variables: {
            input: {
              id: _id,
            },
          },
          onCompleted: () => {
            if (task.type === 'LAB') {
              deleteModulePartMutation({
                variables: {
                  input: {
                    id: modulePartId,
                  },
                },
              });
            }
            resolve();
          },
          onError: () => reject(),
        });
      });
    };
    const updateTask = async (_taskIdValue, _assessmentId, task) => {
      return new Promise((resolve, reject) => {
        if (task?.type === 'LAB') {
          createModulePartMutation({
            variables: {
              input: {
                assessmentId: _assessmentId,
                courseModuleId: _taskIdValue,
                description: task.description,
                name: task.name,
                orderNumber: Object.entries(taskLabs)?.length + 1,
                type: 'LAB',
                labId: task.labId,
                duration: 120, // default value for lab duration
                inactivityExpiry: 14, // default value for lab abandonment
                expiry: endDateTime // default value for lab expiry based on type of competition
                  ? new Date(endDateTime)
                  : addMonths(new Date(), 2),
              },
            },
            onCompleted: (_data) => {
              const { id, courseModuleId } = _data.createModulePart;
              setValue('taskLabs', {
                ...taskLabs,
                [courseModuleId]: id,
              });

              createTaskAssessmentMutation({
                variables: {
                  input: {
                    taskId: _taskIdValue,
                    assessmentId: _assessmentId,
                    modulePartId: id,
                  },
                },
                onCompleted: () => resolve(),
                onError: () => reject(),
              });
            },
            onError: (error) => {
              errorToastMessage(error.message);
            },
          });
        } else {
          createTaskAssessmentMutation({
            variables: {
              input: {
                taskId: _taskIdValue,
                assessmentId: _assessmentId,
              },
            },
            onCompleted: () => {
              resolve();
            },
            onError: () => reject(),
          });
        }
      });
    };

    // delete existing tasks that are about to be deleted
    getDeletingTasks(existingAssessmentTasks, taskIdValues)?.forEach(
      (taskAssessment) =>
        deletePromises.push(
          deleteTask(
            taskAssessment.id,
            taskAssessment?.modulePartId,
            taskAssessment.task,
          ),
        ),
    );
    const deleteResult = await Promise.allSettled(deletePromises);
    if (deleteResult.some((p) => p.status === 'rejected')) {
      showToastAndReset(deleteResult);
      return;
    }

    // insert new tasks as per form changes
    getNewTasks(existingAssessmentTasks, taskIdValues)?.forEach((ta) => {
      const task = allTasks?.find((t) => t.id === ta.taskId);

      updatePromises.push(updateTask(ta.taskId, assessmentId, task));
    });
    const updateResult = await Promise.allSettled(updatePromises);
    // show toast for both success and failed
    showToastAndReset(updateResult);
  };

  const viewTaskActions = [
    {
      label: 'View',
      onClick: ({ id }) => {
        setOpenDrawer(true);
        const activeTaskData = tableData?.find((t) => t.id === id);
        setActiveTask(activeTaskData);
      },
    },
  ];

  return (
    <form>
      <Box>
        <Grid container>
          <Grid item xs>
            <AssessmentOverview allTasks={tableData} tasks={watchTasks} />
            {/* {isAdminUser && globalSnap.orgPricingTier !== 'STARTER' && (
              <BrowseCatalog />
            )} */}
            {editTasks && errors.tasks && getIncompleteLevels().length > 0 && (
              <Box mt={2}>
                <FdAlert
                  alertTitle="No challenges assigned to Level"
                  variant="error"
                  message={`Please assign challenges to - ${levelErrorMessage}`}
                />
              </Box>
            )}
            {editTasks && errors.tasks && !getValues('multiLevel') && (
              <Box mt={2}>
                <FdAlert
                  variant="error"
                  message="Please select challenges(s) for this competition"
                />
              </Box>
            )}
            <TasksTable
              listHintRevealsData={listHintRevealsData}
              data={tableData || []}
              viewTaskActions={viewTaskActions}
              editTasks={editTasks}
              setEditTasksCallBack={setEditTasks}
              viewAssessment
              competitionStatus={status}
              showEditButton={showEditButton}
              revealHintsCallBack={(hintsDetails) => {
                hintsDetails?.forEach((hintDetail) => {
                  createHintRevealMutation({
                    variables: {
                      input: {
                        hintId: hintDetail?.hintId,
                        taskAssessmentId: hintDetail?.taskAssessmentId,
                      },
                    },
                    onCompleted: () => {
                      refetchListHintReveals();
                      successToastMessage(
                        `All hints in ${hintDetail?.challengeName} are now revealed.`,
                      );
                    },
                  });
                });
              }}
              isRefreshLoading={allTasksAvailableToOrgLoading}
              onRefreshTasks={() => allTasksAvailableRefetch()}
              onEditMode
              loading={updateInProgress || allTasksLoading}
            />
          </Grid>
          <Grid
            item
            style={{
              width: openDrawer ? drawerWidth : 0,
            }}
          />
        </Grid>
      </Box>
      {activeTask && openDrawer && (
        <ViewTaskDrawer
          editTasks={!editTasks}
          activeTaskData={activeTask}
          listHintRevealsData={listHintRevealsData}
          competitionStatus={status}
          openDrawer={openDrawer}
          openDrawerCallBack={setOpenDrawer}
          mainPageIds={['topnav', 'competitions']}
          revealHintCallBack={(hintId, taskAssessmentId) => {
            // Hint Reveal Mutation
            createHintRevealMutation({
              variables: {
                input: {
                  hintId,
                  taskAssessmentId,
                },
              },
            });
          }}
          assessmentTasks={assessmentTasks}
        />
      )}
      {editTasks && (
        <>
          <FdButton
            size="large"
            variant="primary"
            onClick={async () => {
              const result = await trigger(['tasks']);
              if (result) {
                setUpdateInProgress(true);
                window.scrollTo(0, 0);
                const isMultiLevel = getValues('multiLevel');
                // update/delete tasks if multi-level
                if (isMultiLevel) {
                  onUpdateWithLevel();
                } else {
                  // update/delete tasks if not multi-level
                  onUpdateNoLevel();
                }
              }
            }}
          >
            {updateInProgress ? 'Loading...' : 'Save'}
          </FdButton>
          <FdButton
            size="large"
            variant="tertiary"
            onClick={() => {
              resetTasks();
              warningToastMessage('No changes were saved');
            }}
          >
            CANCEL
          </FdButton>
        </>
      )}
    </form>
  );
};

ViewChallenges.propTypes = {
  assessmentData: PropTypes.shape({
    getAssessment: PropTypes.shape({
      id: PropTypes.string,
      level: PropTypes.number,
      levels: PropTypes.shape({
        items: PropTypes.arrayOf(PropTypes.shape({})),
      }),
      tasks: PropTypes.shape({
        items: PropTypes.arrayOf(PropTypes.shape({})),
      }),
    }),
  }).isRequired,
  refetchAssessmentData: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  showEditButton: PropTypes.bool.isRequired,
  assessmentTasks: PropTypes.shape({}).isRequired,
};

export default ViewChallenges;
