import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { FdTypography, FdAnimatedDigit } from '@fifthdomain/fe-shared';

const PercentageDifferenceIndicator = ({ value, suffix, suffixInNewline }) => {
  const type = value === 0 ? '' : value >= 0 ? 'up' : 'down';
  const textColor =
    type === 'up'
      ? 'rgba(76, 175, 80, 1)'
      : type === 'down'
      ? 'rgba(244, 67, 54, 1)'
      : 'rgba(158, 158, 158, 1)';
  const Icon = type === 'down' ? ArrowDownwardIcon : ArrowUpwardIcon;

  return (
    <Box
      className={`flex ${
        suffixInNewline ? 'flex-col' : 'items-end'
      } justify-start gap-x-1`}
    >
      <Box className="flex items-center">
        <Icon
          style={{
            width: '14px',
            height: '14px',
            fill: textColor,
          }}
        />
        <FdTypography
          variant="captiontext"
          style={{
            fontWeight: 700,
            color: textColor,
            fontSize: '12px',
          }}
        >
          <FdAnimatedDigit digit={`${Math.abs(value)}%`} />
        </FdTypography>
      </Box>
      {suffix && (
        <FdTypography variant="captiontext2" color="secondary">
          {suffix}
        </FdTypography>
      )}
    </Box>
  );
};

PercentageDifferenceIndicator.propTypes = {
  value: PropTypes.number.isRequired,
  suffix: PropTypes.string,
  suffixInNewline: PropTypes.bool,
};

PercentageDifferenceIndicator.defaultProps = {
  suffix: '',
  suffixInNewline: false,
};

export default PercentageDifferenceIndicator;
