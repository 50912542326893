import React from 'react';
import PropTypes from 'prop-types';
import { useMutation, gql } from '@apollo/client';
import { Box } from '@mui/material';
import {
  FdModal,
  FdTypography,
  successToastMessage,
  warningToastMessage,
} from '@fifthdomain/fe-shared';
import { updateTemplate, rollbackTraining } from '../../../graphql/mutations';
import { TEMPLATE_STATUS } from '../../../constants';

const ReleaseModal = ({
  openModal,
  setOpenModal,
  templateDetails,
  onConfirm,
  onDismiss,
}) => {
  const isRollBack = openModal === TEMPLATE_STATUS.ROLLBACKED;
  const [updateTemplateMutation, { loading: updateTemplateLoading }] =
    useMutation(gql(updateTemplate));
  const [rollbackTrainingMutation, { loading: rollbackTrainingLoading }] =
    useMutation(gql(rollbackTraining));
  const loading = updateTemplateLoading || rollbackTrainingLoading;

  return (
    <FdModal
      size="sm"
      title={`${isRollBack ? 'Roll back' : 'Release'} this template?`}
      open={openModal}
      confirm={isRollBack ? 'Roll back' : 'release'}
      showConfirmInRed={isRollBack}
      showConfirmInGreen={!isRollBack}
      disableConfirm={loading}
      dismiss="cancel"
      onConfirm={async () => {
        // mutation to update status
        const templateMutation = !isRollBack
          ? updateTemplateMutation
          : rollbackTrainingMutation;
        const templateMutationInput = !isRollBack
          ? {
              input: {
                id: templateDetails?.id,
                status: TEMPLATE_STATUS.RELEASED,
              },
            }
          : {
              trainingId: templateDetails?.id,
            };
        await templateMutation({
          variables: templateMutationInput,
          onCompleted: (_data) => {
            setOpenModal(false);
            successToastMessage(
              `Success! Template ${!isRollBack ? 'released' : 'rolled back'}`,
            );

            onConfirm();
          },
        });
      }}
      onDismiss={() => {
        setOpenModal(false);
        warningToastMessage(
          `Template not ${!isRollBack ? 'released' : 'rolled back'}`,
        );
        onDismiss();
      }}
    >
      <Box display="flex" flexDirection="column" width="100%">
        <FdTypography variant="body1" color="secondary">
          {isRollBack
            ? 'Rolling back this template will remove access to all instances of the template across the platform. Affiliated participants will no longer be able to see or attempt the training, and managers will lose access to insights'
            : 'Releasing this template will create training instances for each paying organisation when the availability period begins (i.e., on the Training Start date).'}
        </FdTypography>
        <Box my={3}>
          <FdTypography variant="body1" color="secondary">
            {isRollBack
              ? "However, skill and performance data already generated will remain available in each individual's profile."
              : 'Affiliated users can attempt the training until the availability period ends, and organisation managers can view the generated data in their instance throughout the availability period.'}
          </FdTypography>
        </Box>
        <FdTypography variant="body1" color="secondary">
          <span className="font-bold mr-1">Note:</span>
          {isRollBack
            ? "Rolling back a template is permanent and cannot be undone. If you proceed, the template's release status will change to 'Rolled Back,' and the scheduled training end date will be updated to the current time."
            : 'Releasing a template is permanent and cannot be undone. However,you can roll back a released template that is still within the availability period if you need to end access before the Training End date.'}
        </FdTypography>
      </Box>
    </FdModal>
  );
};

ReleaseModal.propTypes = {
  openModal: PropTypes.bool.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  templateDetails: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
};

export default ReleaseModal;
