import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Controller, useFormContext } from 'react-hook-form';
import { gql, useMutation } from '@apollo/client';
import { Box } from '@mui/material';
import {
  FdCard,
  FdTextField,
  FdTypography,
  FdAlert,
  FdDateTimePicker,
  FdButton,
  successToastMessage,
  warningToastMessage,
} from '@fifthdomain/fe-shared';
import ContentWithLabel from './ContentWithLabel';
import { updateTemplate } from '../../../graphql/mutations';
import { getDateTimeZoneFormatted } from '../../../shared/utils/dateUtils';

const Details = ({ showEditButton, templateEditButton }) => {
  const { templateId } = useParams();
  const { control, getValues, reset, trigger } = useFormContext();
  const { name, description, trainingStartDate, trainingEndDate } = getValues();
  const [editDetails, setEditDetails] = useState(false);
  const [updateTemplateMutation, { loading: updateTemplateLoading }] =
    useMutation(gql(updateTemplate));

  return (
    <FdCard
      variant="outlined"
      heading={
        showEditButton ? (
          <Box className="flex flex-row justify-between">
            <FdTypography variant="h3">Details</FdTypography>
            <Box>
              {!editDetails ? (
                templateEditButton && (
                  <FdButton
                    variant="primary"
                    size="small"
                    onClick={() => setEditDetails(true)}
                  >
                    EDIT
                  </FdButton>
                )
              ) : (
                <Box className="flex  flex-row justify-between">
                  <Box pr={1}>
                    <FdButton
                      variant="secondary"
                      size="small"
                      onClick={() => {
                        reset();
                        setEditDetails(false);
                        warningToastMessage('Template edits not saved.');
                      }}
                    >
                      CANCEL
                    </FdButton>
                  </Box>
                  <Box>
                    <FdButton
                      variant="primary"
                      size="small"
                      onClick={async () => {
                        const triggerValue = await trigger([
                          'name',
                          'description',
                          'trainingStartDate',
                          'trainingEndDate',
                        ]);
                        if (triggerValue) {
                          const {
                            name,
                            description,
                            trainingStartDate,
                            trainingEndDate,
                          } = getValues();
                          updateTemplateMutation({
                            variables: {
                              input: {
                                id: templateId,
                                name,
                                description,
                                startDateTime: trainingStartDate,
                                endDateTime: trainingEndDate,
                              },
                            },
                            onCompleted: () =>
                              successToastMessage(
                                'Success! Template edits saved.',
                              ),
                          });
                          setEditDetails(false);
                        }
                      }}
                    >
                      {updateTemplateLoading ? 'Loading...' : 'Save'}
                    </FdButton>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        ) : (
          'Add Details'
        )
      }
      subHeading={!showEditButton && 'Enter training template details below.'}
    >
      <Box display="flex" flexDirection="column">
        {!editDetails && showEditButton ? (
          <Box>
            <ContentWithLabel label="Training Name" content={name} />
            <ContentWithLabel
              label="Training Description"
              content={description}
            />
            <ContentWithLabel
              label="Start"
              content={getDateTimeZoneFormatted(trainingStartDate, 'AEDT')}
            />
            <ContentWithLabel
              label="End"
              content={getDateTimeZoneFormatted(trainingEndDate, 'AEDT')}
            />
          </Box>
        ) : (
          <Box>
            <Controller
              control={control}
              name="name"
              render={({ field: { ref, ...rest }, fieldState: { error } }) => (
                <Box mt={1} mb={2}>
                  <FdTextField
                    id="name"
                    label="Training Name"
                    required
                    fullWidth
                    error={error}
                    helperText={error && error?.message}
                    {...rest}
                    inputRef={ref}
                  />
                </Box>
              )}
            />
            <Controller
              control={control}
              name="description"
              render={({ field: { ref, ...rest }, fieldState: { error } }) => (
                <Box mt={1} mb={2}>
                  <FdTextField
                    id="description"
                    label="Training Description"
                    fullWidth
                    required
                    error={error}
                    helperText={
                      error
                        ? error.message
                        : 'The Training Description is visible to users.'
                    }
                    {...rest}
                    inputRef={ref}
                  />
                </Box>
              )}
            />
            <Box my={1}>
              <Controller
                control={control}
                name="trainingStartDate"
                render={({
                  field: { ref, ...rest },
                  fieldState: { error },
                }) => (
                  <FdDateTimePicker
                    format="DD/MM/YYYY"
                    views={['year', 'month', 'day']}
                    label="Training Start (optional)"
                    value={rest.value}
                    disablePast
                    error={error}
                    helperText={error && error.message}
                    {...rest}
                    inputRef={ref}
                  />
                )}
              />
              <Box my={4}>
                <Controller
                  control={control}
                  name="trainingEndDate"
                  render={({
                    field: { ref, ...rest },
                    fieldState: { error },
                  }) => (
                    <FdDateTimePicker
                      format="DD/MM/YYYY"
                      views={['year', 'month', 'day']}
                      label="Training End"
                      value={rest.value}
                      disablePast
                      error={error}
                      helperText={error && error.message}
                      {...rest}
                      inputRef={ref}
                    />
                  )}
                />
              </Box>
              <Box my={1}>
                <FdTypography variant="h4">
                  Advanced Networking Features
                </FdTypography>
              </Box>
              <FdAlert
                variant="info"
                message="VPN and Jump Box are enabled by default for training templates. Participants will always have access to both when attempting FD Trainings."
              />
            </Box>
          </Box>
        )}
      </Box>
    </FdCard>
  );
};

Details.propTypes = {
  showEditButton: PropTypes.bool,
  templateEditButton: PropTypes.bool,
};

Details.defaultProps = {
  showEditButton: false,
  templateEditButton: false,
};

export default Details;
