import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import { Box } from '@mui/material';
import { gql, useMutation } from '@apollo/client';
import {
  FdChatStream,
  globalStore,
  useSnapshot,
  FdSkeleton,
  successToastMessage,
  warningToastMessage,
} from '@fifthdomain/fe-shared';
import {
  createChatForum,
  deleteChatForum,
  updateChatForum,
} from '../../graphql/mutations';
import { uploadFileToS3 } from '../../shared/utils/fileUploadAWS';
import { loadMessagesWithImages } from './utils';
import { invalidateGetAssessment } from '../../queries/invalidateQueries';

const ChatForum = ({
  chatMessages,
  assessmentId,
  isAdminView,
  viewOnly,
  teams,
  loading,
}) => {
  const [imageKey, setImageKey] = useState(undefined);
  const [imageFile, setImageFile] = useState(undefined);
  const [chatMessagesWithImages, setChatMessagesWithImages] = useState([]);
  const [keyIds, setKeyIds] = useState([]);
  const globalSnap = useSnapshot(globalStore);

  useEffect(() => {
    loadMessagesWithImages(chatMessages, globalSnap?.userId, teams)
      .then((chatMessagesDisplay) => {
        setChatMessagesWithImages(chatMessagesDisplay);
      })
      .catch(() => {
        setChatMessagesWithImages([]);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatMessages?.length]);

  const uploadToS3 = (_imageKey, _imageFile) => {
    const pImageKey = imageKey || _imageKey;
    const pImageFile = imageFile || _imageFile;
    if (pImageKey) {
      uploadFileToS3({
        key: pImageKey,
        file: pImageFile,
        progressCallback: (progress) => {
          // reset imageFile on completion
          if (progress?.loaded === progress?.total) {
            setImageFile(undefined);
          }
        },
      });
    }
  };

  const [createChatForumMessageMutation] = useMutation(gql(createChatForum));

  const [updateChatForumMessageMutation] = useMutation(gql(updateChatForum), {
    onCompleted: () => {
      // if image present then upload to S3
      uploadToS3();
      // invalidate queries to force refetch
      invalidateGetAssessment();
    },
  });

  const [deleteChatForumMessageMutation] = useMutation(gql(deleteChatForum), {
    onCompleted: (_data) => {
      successToastMessage('Success! Message deleted.');
      // invalidate queries to force refetch
      invalidateGetAssessment();
    },
  });

  const getImageKey = (image) => {
    const imageKeyGenerated = image
      ? `chat-images/${shortid.generate()}`
      : undefined;
    if (imageKeyGenerated) {
      setImageKey(imageKeyGenerated);
      setImageFile(image);
    }
    return imageKeyGenerated;
  };

  const getMessagePayload = (message, imageKeyGenerated, image) => {
    return {
      messages: [
        {
          content: message,
          type: 'TEXT',
        },
        ...(image
          ? [
              {
                content: imageKeyGenerated,
                type: 'IMAGE',
              },
            ]
          : []),
      ],
    };
  };
  const role = isAdminView ? 'ADMIN' : 'PARTICIPANT';
  const getMessageId = (_keyId) =>
    keyIds?.find((i) => i?.keyId === _keyId)?.messageId;

  return (
    <Box>
      <FdSkeleton loading={loading} height={window.innerHeight - 260}>
        <FdChatStream
          allMessages={chatMessagesWithImages}
          headerHeight={260}
          onAddMessage={(message, image, keyId) => {
            const imageKeyGenerated = getImageKey(image);
            // create message
            createChatForumMessageMutation({
              variables: {
                input: {
                  assessmentId,
                  ...getMessagePayload(message, imageKeyGenerated, image),
                  userId: globalSnap?.userId,
                  role,
                },
              },
              onCompleted: (_data) => {
                const id = _data?.createChatForum?.id;
                setKeyIds([...keyIds, { keyId, messageId: id }]);
                // if image present then upload to S3
                uploadToS3(imageKeyGenerated, image);
                // invalidate queries to force refetch
                invalidateGetAssessment();
              },
            });
          }}
          onUpdateMessage={({ messageId, message, image, keyId }) => {
            const imageKeyGenerated = getImageKey(image);
            // update message
            updateChatForumMessageMutation({
              variables: {
                input: {
                  id: messageId || getMessageId(keyId),
                  ...getMessagePayload(message, imageKeyGenerated, image),
                  userId: globalSnap?.userId,
                  role,
                },
              },
            });
          }}
          onDeleteMessage={(messageId, keyId) =>
            deleteChatForumMessageMutation({
              variables: {
                input: {
                  id: messageId || getMessageId(keyId),
                },
              },
            })
          }
          onCancelDeleteMessage={() =>
            warningToastMessage('Message deletion cancelled')
          }
          currentRole={role}
          adminView={isAdminView}
          viewOnly={viewOnly}
          teams={teams}
          hideReactions
          warningToastMessage={warningToastMessage}
        />
      </FdSkeleton>
    </Box>
  );
};

ChatForum.defaultProps = {
  isAdminView: false,
  viewOnly: false,
  teams: [],
  loading: false,
};

ChatForum.propTypes = {
  chatMessages: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  assessmentId: PropTypes.string.isRequired,
  isAdminView: PropTypes.bool,
  viewOnly: PropTypes.bool,
  teams: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.bool,
};

export default ChatForum;
