import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery, gql, useMutation } from '@apollo/client';
import { Box } from '@mui/material';
import { useForm, FormProvider, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import NavigationPrompt from 'react-router-navigation-prompt';
import {
  FdButton,
  FdProgress,
  FdCard,
  FdModal,
  FdAlert,
  amplifyConfig,
  useQueryRecursive,
  FdDelayed,
  useSnapshot,
  globalStore,
  Authorization,
  successToastMessage,
  warningToastMessage,
} from '@fifthdomain/fe-shared';
import scrollToTop from '../../../shared/utils/scroll';
import CourseDetails from '../Create/CourseDetails';
import CourseMaterial from '../Create/CourseMaterial';
import {
  createCourseModule,
  updateCourseModule,
  createModulePart,
  updateModulePart,
  updateCourse,
  deleteCourseModule,
  deleteModulePart,
  createModulePartProgress,
} from '../../../graphql/mutations';
import { getCourseForEdit } from '../../../queries/customQueries';
import { upperCaseFirstLetter } from '../../../shared/utils/stringUtils';
import {
  courseInitialValues,
  courseValidationSchema,
} from '../../../validation-schemas/Course';
import { deleteModulesParts, createModules } from '../Create/courseUtils';
import {
  listCourseCategories,
  listCourseUsersByOrgId,
} from '../../../graphql/queries';
import { COURSE_CATEGORIES_IMAGE } from '../../../constants';

const EditCourse = ({
  courseId,
  allQuizzes,
  allLabs,
  refetchCourse,
  setApiLoading,
}) => {
  const history = useHistory();
  const [activeStep, setActiveStep] = useState(0);
  const [editMode, setEditMode] = useState(false);
  const [showNoUserAlert, setShowNoUserAlert] = useState(false);
  const [stepNavigateAlert, setStepNavigateAlert] = useState(false);
  const steps = ['Edit Details', 'Edit Modules'];
  const { orgId, userId, permissions } = useSnapshot(globalStore);

  const { data: courseCategories, loading: courseCategoriesLoading } =
    useQueryRecursive(gql(listCourseCategories));

  const { data: courseUsersData, loading: courseUsersLoading } =
    useQueryRecursive(gql(listCourseUsersByOrgId), {
      variables: {
        orgId,
        filter: {
          courseId: {
            eq: courseId,
          },
        },
      },
      skip: !orgId,
    });

  const [updateCourseMutation, { loading: updateCourseLoading }] = useMutation(
    gql(updateCourse),
  );

  const [createCourseModuleMutation, { loading: createCourseModuleLoading }] =
    useMutation(gql(createCourseModule));

  const [updateCourseModuleMutation, { loading: updateCourseModuleLoading }] =
    useMutation(gql(updateCourseModule));

  const [createModulePartMutation, { loading: createModulePartLoading }] =
    useMutation(gql(createModulePart));

  const [updateModulePartMutation, { loading: updateModulePartLoading }] =
    useMutation(gql(updateModulePart));

  const [deleteCourseModuleMutation, { loading: deleteCourseModuleLoading }] =
    useMutation(gql(deleteCourseModule));

  const [deleteModulePartMutation, { loading: deleteModulePartLoading }] =
    useMutation(gql(deleteModulePart));

  const [
    createModulePartProgressMutation,
    { loading: createModulePartProgressLoading },
  ] = useMutation(gql(createModulePartProgress));

  const validationSchema = Yup.object().shape({
    ...courseValidationSchema,
    status: Yup.string().nullable(),
    usersGroupsCount: Yup.number().nullable(),
  });

  const reactHookFormMethods = useForm({
    defaultValues: courseInitialValues,
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });
  const {
    reset,
    getValues,
    setValue,
    trigger,
    control,
    formState: { errors, isDirty },
  } = reactHookFormMethods;

  const watchStatus = useWatch({
    control,
    name: 'status',
  });

  const watchCategory = useWatch({
    control,
    name: 'category',
  });

  const isDraft = watchStatus === 'DRAFT';

  const { data: courseData, loading: courseLoading } = useQuery(
    gql(getCourseForEdit),
    {
      variables: {
        id: courseId,
      },
      fetchPolicy: 'network-only',
      skip: !courseId,
      onCompleted: (_data) => {
        const {
          availability,
          courseModules,
          name,
          category,
          description,
          status,
          courseUsers,
          coursegroups,
        } = _data.getCourse;
        reset({
          courseId,
          name,
          description,
          status,
          category: category?.name,
          availability,
          modules: courseModules?.items
            .map((m, mIdx) => ({
              moduleId: m.id,
              moduleName: m.name,
              moduleDescription: m.description,
              moduleDuration: m.duration,
              order: m.orderNumber || mIdx + 1,
              accordionState: false,
              createdAt: m.createdAt,
              parts: m.parts?.items
                .map((p, pIdx) => ({
                  partId: p.id,
                  partType:
                    p.type !== 'PDF' ? upperCaseFirstLetter(p.type) : p.type,
                  partTitle: p.name,
                  partDescription: p.description,
                  order: p.orderNumber || pIdx + 1,
                  accordionState: false,
                  lab: p.labId ? p.lab.name : '',
                  isLabTransferred: !!(
                    p.labId && !allLabs.some((lab) => lab.id === p.labId)
                  ),
                  quiz: p.quizId ? p.quiz.name : '',
                  file: {
                    bucket: p.file?.bucket,
                    key: p.file?.key,
                    region: p.file?.region,
                  },
                  labDuration: p.type === 'LAB' ? p.duration : undefined,
                  labExpiryDateTime:
                    p.type === 'LAB' && p.expiry ? p.expiry : undefined,
                  labAbandonment:
                    p.type === 'LAB' ? p.inactivityExpiry : undefined,
                  initialLabPool:
                    p.type === 'LAB' ? p.initialLabCount : undefined,
                  minimumLabPool: p.type === 'LAB' ? p.minLabCount : undefined,
                  deletionMode: p.type === 'LAB' ? p.deletionMode : undefined,
                  video: p.type === 'VIDEO' ? p.url : undefined,
                }))
                .sort((a, b) => a.order - b.order),
            }))
            .sort((a, b) => a.order - b.order),
          usersGroupsCount:
            (courseUsers?.items.length || 0) +
              (coursegroups?.items.length || 0) || 0,
          partsSummary: courseModules.items
            .map((m, mIdx) => ({
              moduleId: m.id,
              order: m.orderNumber || mIdx + 1,
              parts: m.parts?.items
                .map((p, pIdx) => ({
                  order: p.orderNumber || pIdx + 1,
                  partType:
                    p.type !== 'PDF' ? upperCaseFirstLetter(p.type) : p.type,
                  quiz:
                    allQuizzes.filter((l) => l.id === p.quizId)[0]?.name || '',
                  labDuration: p.type === 'LAB' ? p.duration : undefined,
                }))
                .sort((a, b) => a.order - b.order),
            }))
            .sort((a, b) => a.order - b.order),
        });
      },
    },
  );

  if (
    courseLoading ||
    courseUsersLoading ||
    courseCategoriesLoading ||
    deleteCourseModuleLoading ||
    deleteModulePartLoading
  )
    return <FdProgress />;

  const validatePage = async () => {
    let result;
    switch (activeStep) {
      case 0: {
        result = await trigger(['name', 'description']);
        break;
      }
      case 1:
        result = await trigger(['modules']);
        break;
      default:
        break;
    }
    return result;
  };

  const createCourseAction = async ({ courseStatus }) => {
    const { name, description, category, availability } = getValues();
    const actionId = courseId ? { id: courseId } : undefined;
    const categoryId = courseCategories?.listCourseCategories?.items?.find(
      (courseCategory) => courseCategory.name === category,
    )?.id;

    let updatedValues = getValues();
    // create/edit course
    await updateCourseMutation({
      variables: {
        input: {
          categoryId,
          image:
            {
              key: COURSE_CATEGORIES_IMAGE[
                category || 'SOC Analyst 1 - Analysis'
              ],
              bucket: amplifyConfig.aws_user_files_s3_bucket,
              region: amplifyConfig.aws_user_files_s3_bucket_region,
            } || courseData?.getCourse?.image,
          description,
          name,
          status: courseStatus,
          orgId,
          ownerId: userId,
          availability,
          ...actionId,
        },
      },
      onCompleted: async (_data) => {
        const draftCourseId = _data?.updateCourse?.id;
        if (!courseId) {
          updatedValues = { ...updatedValues, courseId: draftCourseId };
        }
        await createModules({
          updateCourseModuleMutation,
          createCourseModuleMutation,
          values: updatedValues,
          courseId,
          setValue,
          updateModulePartMutation,
          createModulePartMutation,
          allQuizzes,
          allLabs,
          courseUserIds: courseUsersData.listCourseUsersByOrgId.items.map(
            (courseUser) => courseUser.id,
          ),
          createModulePartProgressMutation,
        });
        await deleteModulesParts({
          updateModulePartMutation,
          deleteModulePartMutation,
          deleteCourseModuleMutation,
          values: updatedValues,
        });
        setApiLoading(true);
        setTimeout(() => {
          // refetch course after a short wait to finish up api calls
          refetchCourse();
          // show success toast
          successToastMessage(
            `Course ${
              courseStatus === 'DRAFT' ? 'updated' : 'published'
            } successfully`,
          );
          setApiLoading(false);
        }, 2000);
      },
    });
  };

  const handleNext = async () => {
    if (await validatePage()) {
      if (isDirty) {
        setStepNavigateAlert(true);
        return;
      }
      if (activeStep === 0) {
        setActiveStep(1);
        scrollToTop();
      }
      if (activeStep === 1) {
        setActiveStep(0);
        scrollToTop();
      }
    }
    reset();
    setEditMode(false);
  };

  const handleBack = async () => {
    const movePage = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      scrollToTop();
      reset();
      setEditMode(false);
    };
    if (isDirty) {
      if (await validatePage()) {
        movePage();
        return;
      }
      setStepNavigateAlert(true);
    } else {
      movePage();
    }
  };

  const loadStatus =
    updateCourseLoading ||
    createCourseModuleLoading ||
    updateCourseModuleLoading ||
    createModulePartLoading ||
    createModulePartProgressLoading ||
    updateModulePartLoading;

  const ActionButtons = () => (
    <Box display="flex">
      {editMode ? (
        <Box display="flex">
          <FdButton
            variant="secondary"
            onMouseDown={() => {
              reset();
              setEditMode(false);
              warningToastMessage('Course not saved');
            }}
            data-cy="cancel-button"
          >
            Cancel
          </FdButton>
          <Box ml={1}>
            <FdButton
              onMouseDown={async () => {
                // if first page check second page status before save
                if (activeStep === 0 && !isDraft) {
                  const moduleResult = await trigger('modules');
                  if (!moduleResult && errors?.modules && !isDirty) {
                    handleNext();
                    return;
                  }
                }
                // save
                const result =
                  (isDraft && activeStep === 0) ||
                  (!isDraft && activeStep === 0)
                    ? await trigger(['name'])
                    : await trigger();

                if (result) {
                  await createCourseAction({
                    courseStatus: isDraft ? 'DRAFT' : 'AVAILABLE',
                  });
                }
              }}
              disabled={loadStatus}
              data-cy="save-button"
            >
              Save
            </FdButton>
          </Box>
        </Box>
      ) : (
        <FdButton onClick={() => setEditMode(true)} data-cy="edit-button">
          Edit
        </FdButton>
      )}
    </Box>
  );

  return (
    <Box>
      <FormProvider {...reactHookFormMethods}>
        <form>
          {showNoUserAlert && (
            <Box mt={1} mb={2}>
              <FdAlert
                variant="error"
                message="Please select a user or group"
              />
            </Box>
          )}
          {
            {
              0: (
                <FdCard
                  variant="outlined"
                  heading="Course Details"
                  summary={
                    Authorization.canManageEvents(permissions) && (
                      <ActionButtons />
                    )
                  }
                >
                  <FdDelayed triggerField={watchCategory}>
                    <CourseDetails heading="" isEdit={editMode} />
                  </FdDelayed>
                </FdCard>
              ),
              1: (
                <CourseMaterial
                  actionButtons={
                    Authorization.canManageEvents(permissions) && (
                      <ActionButtons />
                    )
                  }
                  allQuizzes={allQuizzes}
                  allLabs={allLabs}
                  isEdit={editMode}
                />
              ),
            }[activeStep]
          }
          <Box display="flex" width="100%" justifyContent="space-between">
            <Box>
              {activeStep === 1 && (
                <FdButton
                  size="large"
                  variant="secondary"
                  onClick={handleBack}
                  data-cy="back-button"
                >
                  Back
                </FdButton>
              )}
            </Box>
            <Box display="flex">
              {isDraft && (
                <FdButton
                  size="large"
                  onClick={async () => {
                    // if first page check second page status before publish
                    if (activeStep === 0) {
                      const moduleResult = await trigger('modules');
                      if (!moduleResult && errors?.modules) {
                        handleNext();
                        return;
                      }
                    }
                    // save & continue
                    if (await trigger()) {
                      if (getValues('usersGroupsCount') === 0) {
                        setShowNoUserAlert(true);
                        return;
                      }
                      await createCourseAction({ courseStatus: 'AVAILABLE' });
                    }
                  }}
                  disabled={loadStatus}
                >
                  {loadStatus ? 'Loading...' : 'Publish'}
                </FdButton>
              )}
              <Box ml={1}>
                {activeStep === 0 && (
                  <FdButton
                    size="large"
                    onClick={handleNext}
                    disabled={loadStatus}
                  >
                    {loadStatus
                      ? 'Loading...'
                      : activeStep === steps.length - 1
                        ? 'PUBLISH'
                        : activeStep === 0
                          ? 'Next'
                          : 'Save & Continue'}
                  </FdButton>
                )}
              </Box>
            </Box>
          </Box>
        </form>
      </FormProvider>
      <NavigationPrompt
        when={isDirty}
        afterCancel={() => {
          if (
            window.location.pathname !== `/labs/courses/view-admin/${courseId}/`
          ) {
            history.goBack();
          }
        }}
      >
        {({ onConfirm, onCancel }) => (
          <FdModal
            title="Are you sure you want to leave?"
            description="You have unsaved changes. Click the Stay button to go back to the form and save your changes."
            confirm="Stay"
            dismiss="Leave"
            open
            onConfirm={onCancel}
            onDismiss={onConfirm}
            data-cy="leave-modal"
          />
        )}
      </NavigationPrompt>
      <FdModal
        title="Are you sure you want to leave?"
        description="You have unsaved changes. Click the Stay button to go back to the form and save your changes."
        confirm="Stay"
        dismiss="Leave"
        open={stepNavigateAlert}
        onConfirm={() => setStepNavigateAlert(false)}
        onDismiss={() => {
          setStepNavigateAlert(false);
          reset();
          handleNext();
        }}
        data-cy="leave-modal-step"
      />
    </Box>
  );
};

EditCourse.propTypes = {
  courseId: PropTypes.string.isRequired,
  allQuizzes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  allLabs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  refetchCourse: PropTypes.func.isRequired,
  setApiLoading: PropTypes.func.isRequired,
};

export default EditCourse;
