/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const attempt = /* GraphQL */ `
  mutation Attempt(
    $taskId: ID!
    $userAssessmentId: ID!
    $levelId: ID
    $answer: String!
  ) {
    attempt(
      taskId: $taskId
      userAssessmentId: $userAssessmentId
      levelId: $levelId
      answer: $answer
    ) {
      taskId
      userAssessmentId
      assessmentId
      userId
      user {
        id
        email
        name
        orgId
        alias
        org {
          id
          name
        }
        status
      }
      groupId
      teamId
      success
    }
  }
`;
export const startAssessmentForUser = /* GraphQL */ `
  mutation StartAssessmentForUser($userAssessmentId: ID!) {
    startAssessmentForUser(userAssessmentId: $userAssessmentId)
  }
`;
export const stopAssessmentForUser = /* GraphQL */ `
  mutation StopAssessmentForUser($userAssessmentId: ID!) {
    stopAssessmentForUser(userAssessmentId: $userAssessmentId)
  }
`;
export const createNewAssessment = /* GraphQL */ `
  mutation CreateNewAssessment($assesssment: NewAssessment!) {
    createNewAssessment(assesssment: $assesssment)
  }
`;
export const editAssessment = /* GraphQL */ `
  mutation EditAssessment($assesssment: EditAssessment!) {
    editAssessment(assesssment: $assesssment)
  }
`;
export const startLab = /* GraphQL */ `
  mutation StartLab(
    $labPrototypeId: ID!
    $modulePartId: ID
    $userAssessmentId: ID
  ) {
    startLab(
      labPrototypeId: $labPrototypeId
      modulePartId: $modulePartId
      userAssessmentId: $userAssessmentId
    )
  }
`;
export const restartLab = /* GraphQL */ `
  mutation RestartLab($labInstanceId: ID!, $hardReset: Boolean) {
    restartLab(labInstanceId: $labInstanceId, hardReset: $hardReset)
  }
`;
export const restartJumpbox = /* GraphQL */ `
  mutation RestartJumpbox($labInstanceId: ID!, $hardReset: Boolean) {
    restartJumpbox(labInstanceId: $labInstanceId, hardReset: $hardReset)
  }
`;
export const startJumpbox = /* GraphQL */ `
  mutation StartJumpbox($userAssessmentId: ID!) {
    startJumpbox(userAssessmentId: $userAssessmentId)
  }
`;
export const deleteLab = /* GraphQL */ `
  mutation DeleteLab($labInstanceId: ID!) {
    deleteLab(labInstanceId: $labInstanceId)
  }
`;
export const extendLabTime = /* GraphQL */ `
  mutation ExtendLabTime($labInstanceId: ID!) {
    extendLabTime(labInstanceId: $labInstanceId) {
      timeInLab
      timeRemaining
      __typename
    }
  }
`;
export const startGroupAssessment = /* GraphQL */ `
  mutation StartGroupAssessment($groupId: ID!, $assessmentId: ID!) {
    startGroupAssessment(groupId: $groupId, assessmentId: $assessmentId) {
      groupId
      assessmentId
    }
  }
`;
export const stopGroupAssessment = /* GraphQL */ `
  mutation StopGroupAssessment($groupId: ID!, $assessmentId: ID!) {
    stopGroupAssessment(groupId: $groupId, assessmentId: $assessmentId) {
      groupId
      assessmentId
    }
  }
`;
export const addUsers = /* GraphQL */ `
  mutation AddUsers(
    $emails: [AWSEmail]
    $orgId: ID!
    $type: USER_TYPE!
    $participantType: PARTICIPANT_TYPE
    $participantEventType: PARTICIPANT_EVENT_TYPE
    $permissions: [PERMISSION_TYPE]
    $assessmentId: ID
    $groups: [ID]
  ) {
    addUsers(
      emails: $emails
      orgId: $orgId
      type: $type
      participantType: $participantType
      participantEventType: $participantEventType
      permissions: $permissions
      assessmentId: $assessmentId
      groups: $groups
    )
  }
`;
export const addUser = /* GraphQL */ `
  mutation AddUser(
    $email: String!
    $orgId: ID!
    $type: USER_TYPE!
    $participantType: PARTICIPANT_TYPE
    $participantEventType: PARTICIPANT_EVENT_TYPE
    $permissions: [PERMISSION_TYPE]
    $assessmentId: ID
  ) {
    addUser(
      email: $email
      orgId: $orgId
      type: $type
      participantType: $participantType
      participantEventType: $participantEventType
      permissions: $permissions
      assessmentId: $assessmentId
    )
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser($input: EditUser!) {
    updateUser(input: $input)
  }
`;
export const removeUserFromAssessment = /* GraphQL */ `
  mutation RemoveUserFromAssessment($userAssessmentId: ID!) {
    removeUserFromAssessment(userAssessmentId: $userAssessmentId)
  }
`;
export const removeInvitedUser = /* GraphQL */ `
  mutation RemoveInvitedUser($inviteToken: ID!) {
    removeInvitedUser(inviteToken: $inviteToken)
  }
`;
export const createNewOnboarding = /* GraphQL */ `
  mutation CreateNewOnboarding($input: CreateOnboardingInput!) {
    createNewOnboarding(input: $input)
  }
`;
export const updateRoleAndPermissions = /* GraphQL */ `
  mutation UpdateRoleAndPermissions(
    $emails: [AWSEmail]!
    $type: USER_TYPE!
    $permissions: [PERMISSION_TYPE]
  ) {
    updateRoleAndPermissions(
      emails: $emails
      type: $type
      permissions: $permissions
    )
  }
`;
export const manageUserAndGroup = /* GraphQL */ `
  mutation ManageUserAndGroup(
    $groupId: ID!
    $userIds: [ID!]
    $operation: OPERATION_TYPE!
  ) {
    manageUserAndGroup(
      groupId: $groupId
      userIds: $userIds
      operation: $operation
    )
  }
`;
export const removeUserFromOrg = /* GraphQL */ `
  mutation RemoveUserFromOrg($email: AWSEmail!) {
    removeUserFromOrg(email: $email)
  }
`;
export const removeGroupsFromAssessment = /* GraphQL */ `
  mutation RemoveGroupsFromAssessment($groupIds: [ID]!, $assessmentId: ID!) {
    removeGroupsFromAssessment(groupIds: $groupIds, assessmentId: $assessmentId)
  }
`;
export const migrateParticipant = /* GraphQL */ `
  mutation MigrateParticipant($users: [MigratedParticipant]) {
    migrateParticipant(users: $users)
  }
`;
export const createAssessment = /* GraphQL */ `
  mutation CreateAssessment(
    $input: CreateAssessmentInput!
    $condition: ModelAssessmentConditionInput
  ) {
    createAssessment(input: $input, condition: $condition) {
      id
      orgId
      name
      teamBased
      startDateTime
      endDateTime
      createdBy
      participantEventType
      creator {
        id
        email
        name
        participantType
        orgId
        alias
        org {
          id
          name
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateAssessment = /* GraphQL */ `
  mutation UpdateAssessment(
    $input: UpdateAssessmentInput!
    $condition: ModelAssessmentConditionInput
  ) {
    updateAssessment(input: $input, condition: $condition) {
      id
      orgId
      name
      teamBased
      maxTeamSize
      startDateTime
      endDateTime
      hours
      minutes
      multiLevel
      availabilityType
      status
      createdBy
      participantEventType
      createdAt
      updatedAt
    }
  }
`;

export const createAssessmentLevel = /* GraphQL */ `
  mutation CreateAssessmentLevel(
    $input: CreateAssessmentLevelInput!
    $condition: ModelAssessmentLevelConditionInput
  ) {
    createAssessmentLevel(input: $input, condition: $condition) {
      id
      assessmentId
      name
    }
  }
`;

export const deleteAssessmentLevel = /* GraphQL */ `
  mutation DeleteAssessmentLevel(
    $input: DeleteAssessmentLevelInput!
    $condition: ModelAssessmentLevelConditionInput
  ) {
    deleteAssessmentLevel(input: $input, condition: $condition) {
      id
      assessmentId
      name
    }
  }
`;

export const createTaskAssessment = /* GraphQL */ `
  mutation CreateTaskAssessment(
    $input: CreateTaskAssessmentInput!
    $condition: ModelTaskAssessmentConditionInput
  ) {
    createTaskAssessment(input: $input, condition: $condition) {
      id
      assessmentId
      taskId
      modulePartId
      modulePart {
        courseModuleId
        type
        quizId
        duration
        expiry
        inactivityExpiry
        initialLabCount
        minLabCount
        deletionMode
        labId
        url
        orderNumber
        status
        id
        courseModulePartsId
      }
    }
  }
`;

export const deleteTaskAssessment = /* GraphQL */ `
  mutation DeleteTaskAssessment(
    $input: DeleteTaskAssessmentInput!
    $condition: ModelTaskAssessmentConditionInput
  ) {
    deleteTaskAssessment(input: $input, condition: $condition) {
      id
      assessmentId
      taskId
    }
  }
`;
export const manageTasksLevels = /* GraphQL */ `
  mutation ManageTasksLevels(
    $assessmentId: ID!
    $levelNumber: Int
    $taskIds: [ID]!
    $action: ACTION_TYPE!
  ) {
    manageTasksLevels(
      assessmentId: $assessmentId
      levelNumber: $levelNumber
      taskIds: $taskIds
      action: $action
    )
  }
`;

export const createTaskOpened = /* GraphQL */ `
  mutation CreateTaskOpened(
    $input: CreateTaskOpenedInput!
    $condition: ModelTaskOpenedConditionInput
  ) {
    createTaskOpened(input: $input, condition: $condition) {
      id
      taskOpenedTaskId
    }
  }
`;
export const updateTaskOpened = /* GraphQL */ `
  mutation UpdateTaskOpened(
    $input: UpdateTaskOpenedInput!
    $condition: ModelTaskOpenedConditionInput
  ) {
    updateTaskOpened(input: $input, condition: $condition) {
      id
      userId
      taskOpenedUserAssessmentId
      assessmentId
      startedSolving
      startedSolvingAt
      taskOpenedTaskId
    }
  }
`;

export const createTaskActivity = /* GraphQL */ `
  mutation CreateTaskActivity(
    $input: CreateTaskActivityInput!
    $condition: ModelTaskActivityConditionInput
  ) {
    createTaskActivity(input: $input, condition: $condition) {
      taskId
      userId
      groupId
      teamId
      activity
      assessmentId
    }
  }
`;

export const updateTaskActivity = /* GraphQL */ `
  mutation UpdateTaskActivity(
    $input: UpdateTaskActivityInput!
    $condition: ModelTaskActivityConditionInput
  ) {
    updateTaskActivity(input: $input, condition: $condition) {
      taskId
      userId
      teamId
      groupId
      activity
      assessmentId
    }
  }
`;
export const deleteTaskActivity = /* GraphQL */ `
  mutation DeleteTaskActivity(
    $input: DeleteTaskActivityInput!
    $condition: ModelTaskActivityConditionInput
  ) {
    deleteTaskActivity(input: $input, condition: $condition) {
      id
      activity
    }
  }
`;

export const createModulePart = /* GraphQL */ `
  mutation CreateModulePart(
    $input: CreateModulePartInput!
    $condition: ModelModulePartConditionInput
  ) {
    createModulePart(input: $input, condition: $condition) {
      name
      description
      courseModuleId
      type
      quizId
      labId
      duration
      expiry
      inactivityExpiry
      initialLabCount
      minLabCount
      url
      id
      createdAt
      updatedAt
      courseModulePartsId
    }
  }
`;

export const updateModulePart = /* GraphQL */ `
  mutation UpdateModulePart(
    $input: UpdateModulePartInput!
    $condition: ModelModulePartConditionInput
  ) {
    updateModulePart(input: $input, condition: $condition) {
      name
      description
      courseModuleId
      type
      duration
      expiry
      inactivityExpiry
      initialLabCount
      minLabCount
      deletionMode
      labId
      orderNumber
      status
      id
      createdAt
      updatedAt
      courseModulePartsId
    }
  }
`;

export const deleteModulePart = /* GraphQL */ `
  mutation DeleteModulePart(
    $input: DeleteModulePartInput!
    $condition: ModelModulePartConditionInput
  ) {
    deleteModulePart(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
    }
  }
`;

export const createHintReveal = /* GraphQL */ `
  mutation CreateHintReveal(
    $input: CreateHintRevealInput!
    $condition: ModelHintRevealConditionInput
  ) {
    createHintReveal(input: $input, condition: $condition) {
      id
      hintId
      hint {
        id
        taskId
        text
        createdAt
        updatedAt
      }
      taskAssessmentId
      createdAt
      updatedAt
    }
  }
`;

export const createUserAssessment = /* GraphQL */ `
  mutation CreateUserAssessment(
    $input: CreateUserAssessmentInput!
    $condition: ModelUserAssessmentConditionInput
  ) {
    createUserAssessment(input: $input, condition: $condition) {
      id
    }
  }
`;

export const manageTeamEvents = /* GraphQL */ `
  mutation ManageTeamEvents(
    $teams: [ID]!
    $action: ACTION_TYPE!
    $participantEventType: PARTICIPANT_EVENT_TYPE
    $eventId: ID!
  ) {
    manageTeamEvents(
      teams: $teams
      action: $action
      participantEventType: $participantEventType
      eventId: $eventId
    ) {
      success
      message
    }
  }
`;
export const startTeamEvents = /* GraphQL */ `
  mutation StartTeamEvents($teamId: ID!, $eventId: ID!) {
    startTeamEvents(teamId: $teamId, eventId: $eventId)
  }
`;
export const updateUserAssessment = /* GraphQL */ `
  mutation UpdateUserAssessment(
    $input: UpdateUserAssessmentInput!
    $condition: ModelUserAssessmentConditionInput
  ) {
    updateUserAssessment(input: $input, condition: $condition) {
      id
    }
  }
`;
export const stopTeamEvents = /* GraphQL */ `
  mutation StopTeamEvents($teamId: ID!, $eventId: ID!) {
    stopTeamEvents(teamId: $teamId, eventId: $eventId)
  }
`;
export const createTeam = /* GraphQL */ `
  mutation CreateTeam(
    $input: CreateTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    createTeam(input: $input, condition: $condition) {
      id
      name
      description
      alias
      key
      creatorId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const addUserToTeam = /* GraphQL */ `
  mutation ManageUserAndTeam(
    $teamId: ID!
    $emails: [AWSEmail]!
    $action: ACTION_TYPE!
    $participantEventType: PARTICIPANT_EVENT_TYPE
    $role: TEAM_MEMBER_ROLE
    $invitedTeamMemberIds: [ID]
    $eventId: ID
    $origin: TEAM_INVITATIONS_ORIGIN
  ) {
    manageUserAndTeam(
      teamId: $teamId
      emails: $emails
      action: $action
      participantEventType: $participantEventType
      role: $role
      invitedTeamMemberIds: $invitedTeamMemberIds
      eventId: $eventId
      origin: $origin
    ) {
      success
      message
    }
  }
`;

export const manageUserAndTeam = /* GraphQL */ `
  mutation ManageUserAndTeam(
    $teamId: ID!
    $emails: [AWSEmail]!
    $action: ACTION_TYPE!
    $participantEventType: PARTICIPANT_EVENT_TYPE
    $role: TEAM_MEMBER_ROLE
    $invitedTeamMemberIds: [ID]
    $eventId: ID
    $origin: TEAM_INVITATIONS_ORIGIN
  ) {
    manageUserAndTeam(
      teamId: $teamId
      emails: $emails
      action: $action
      participantEventType: $participantEventType
      role: $role
      invitedTeamMemberIds: $invitedTeamMemberIds
      eventId: $eventId
      origin: $origin
    ) {
      success
      message
    }
  }
`;

export const removeTeams = /* GraphQL */ `
  mutation RemoveTeams($teams: [ID]!) {
    removeTeams(teams: $teams)
  }
`;

export const updateTeam = /* GraphQL */ `
  mutation UpdateTeam(
    $input: UpdateTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    updateTeam(input: $input, condition: $condition) {
      id
      name
      key
    }
  }
`;

export const createChallengeRating = /* GraphQL */ `
  mutation CreateChallengeRating(
    $input: CreateChallengeRatingInput!
    $condition: ModelChallengeRatingConditionInput
  ) {
    createChallengeRating(input: $input, condition: $condition) {
      id
      userId
      assessmentId
      userAssessmentId
      taskId
      ratings
    }
  }
`;
export const updateChallengeRating = /* GraphQL */ `
  mutation UpdateChallengeRating(
    $input: UpdateChallengeRatingInput!
    $condition: ModelChallengeRatingConditionInput
  ) {
    updateChallengeRating(input: $input, condition: $condition) {
      id
      userId
      assessmentId
      userAssessmentId
      taskId
      ratings
    }
  }
`;

export const createEventTag = /* GraphQL */ `
  mutation CreateOrgEventTag(
    $eventTagInput: OrgEventTagInput
    $teamBased: Boolean
  ) {
    createOrgEventTag(eventTagInput: $eventTagInput, teamBased: $teamBased)
  }
`;

export const updateEventTag = /* GraphQL */ `
  mutation UpdateOrgEventTag($eventTagInput: OrgEventTagInput) {
    updateOrgEventTag(eventTagInput: $eventTagInput)
  }
`;

export const deleteEventTag = /* GraphQL */ `
  mutation DeleteOrgEventTag($eventTagId: ID!, $teamBased: Boolean) {
    deleteOrgEventTag(eventTagId: $eventTagId, teamBased: $teamBased)
  }
`;

export const createUserEventTag = /* GraphQL */ `
  mutation CreateUserEventTag(
    $input: CreateUserEventTagInput!
    $condition: ModelUserEventTagConditionInput
  ) {
    createUserEventTag(input: $input, condition: $condition) {
      id
      assessmentId
      userAssessmentId
      userId
      eventTagId
    }
  }
`;

export const createTeamEventTag = /* GraphQL */ `
  mutation CreateTeamEventTag(
    $input: CreateTeamEventTagInput!
    $condition: ModelTeamEventTagConditionInput
  ) {
    createTeamEventTag(input: $input, condition: $condition) {
      id
      assessmentId
      teamEventId
      applied
      eventTagId
    }
  }
`;

export const updateUserEventTag = /* GraphQL */ `
  mutation UpdateUserEventTag(
    $input: UpdateUserEventTagInput!
    $condition: ModelUserEventTagConditionInput
  ) {
    updateUserEventTag(input: $input, condition: $condition) {
      id
      assessmentId
      userAssessmentId
      userId
      eventTagId
    }
  }
`;

export const deleteUserEventTag = /* GraphQL */ `
  mutation DeleteUserEventTag(
    $input: DeleteUserEventTagInput!
    $condition: ModelUserEventTagConditionInput
  ) {
    deleteUserEventTag(input: $input, condition: $condition) {
      id
      assessmentId
      userAssessmentId
      userId
      eventTagId
    }
  }
`;

export const deleteTeamEventTag = /* GraphQL */ `
  mutation DeleteTeamEventTag(
    $input: DeleteTeamEventTagInput!
    $condition: ModelTeamEventTagConditionInput
  ) {
    deleteTeamEventTag(input: $input, condition: $condition) {
      id
      assessmentId
      eventTagId
    }
  }
`;

export const createAnnouncement = /* GraphQL */ `
  mutation CreateAnnouncement(
    $input: CreateAnnouncementInput!
    $condition: ModelAnnouncementConditionInput
  ) {
    createAnnouncement(input: $input, condition: $condition) {
      id
      assessmentId
      assessment {
        name
        status
        users {
          items {
            userId
          }
        }
        teams {
          items {
            team {
              members {
                items {
                  userId
                }
              }
            }
          }
        }
      }
      userId
      createdAt
      updatedAt
      messages {
        content
        type
      }
    }
  }
`;
export const updateAnnouncement = /* GraphQL */ `
  mutation UpdateAnnouncement(
    $input: UpdateAnnouncementInput!
    $condition: ModelAnnouncementConditionInput
  ) {
    updateAnnouncement(input: $input, condition: $condition) {
      id
      assessmentId
      userId
      createdAt
      updatedAt
    }
  }
`;
export const deleteAnnouncement = /* GraphQL */ `
  mutation DeleteAnnouncement(
    $input: DeleteAnnouncementInput!
    $condition: ModelAnnouncementConditionInput
  ) {
    deleteAnnouncement(input: $input, condition: $condition) {
      id
      assessmentId
      userId
      createdAt
      updatedAt
    }
  }
`;
export const createAnnouncementReaction = /* GraphQL */ `
  mutation CreateAnnouncementReaction(
    $input: CreateAnnouncementReactionInput!
    $condition: ModelAnnouncementReactionConditionInput
  ) {
    createAnnouncementReaction(input: $input, condition: $condition) {
      id
      userId
      announcementId
      reactionType
      createdAt
      updatedAt
    }
  }
`;
export const updateAnnouncementReaction = /* GraphQL */ `
  mutation UpdateAnnouncementReaction(
    $input: UpdateAnnouncementReactionInput!
    $condition: ModelAnnouncementReactionConditionInput
  ) {
    updateAnnouncementReaction(input: $input, condition: $condition) {
      id
      userId
      announcementId
      reactionType
      createdAt
      updatedAt
    }
  }
`;
export const deleteAnnouncementReaction = /* GraphQL */ `
  mutation DeleteAnnouncementReaction(
    $input: DeleteAnnouncementReactionInput!
    $condition: ModelAnnouncementReactionConditionInput
  ) {
    deleteAnnouncementReaction(input: $input, condition: $condition) {
      id
      userId
      announcementId
      reactionType
      createdAt
      updatedAt
    }
  }
`;
export const createChatForum = /* GraphQL */ `
  mutation CreateChatForum(
    $input: CreateChatForumInput!
    $condition: ModelChatForumConditionInput
  ) {
    createChatForum(input: $input, condition: $condition) {
      id
      assessmentId
      userId
      role
      createdAt
      updatedAt
    }
  }
`;
export const updateChatForum = /* GraphQL */ `
  mutation UpdateChatForum(
    $input: UpdateChatForumInput!
    $condition: ModelChatForumConditionInput
  ) {
    updateChatForum(input: $input, condition: $condition) {
      id
      assessmentId
      userId
      role
      createdAt
      updatedAt
    }
  }
`;
export const deleteChatForum = /* GraphQL */ `
  mutation DeleteChatForum(
    $input: DeleteChatForumInput!
    $condition: ModelChatForumConditionInput
  ) {
    deleteChatForum(input: $input, condition: $condition) {
      id
      assessmentId
      userId
      role
      createdAt
      updatedAt
    }
  }
`;
export const createChallengeChat = /* GraphQL */ `
  mutation CreateChallengeChat(
    $input: CreateChallengeChatInput!
    $condition: ModelChallengeChatConditionInput
  ) {
    createChallengeChat(input: $input, condition: $condition) {
      id
      assessmentId
      assessment {
        name
      }
      userId
      teamId
      challengeId
      challenge {
        name
      }
      messages {
        content
        type
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateChallengeChat = /* GraphQL */ `
  mutation UpdateChallengeChat(
    $input: UpdateChallengeChatInput!
    $condition: ModelChallengeChatConditionInput
  ) {
    updateChallengeChat(input: $input, condition: $condition) {
      id
      assessmentId
      userId
      teamId
      challengeId
      messages {
        content
        type
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteChallengeChat = /* GraphQL */ `
  mutation DeleteChallengeChat(
    $input: DeleteChallengeChatInput!
    $condition: ModelChallengeChatConditionInput
  ) {
    deleteChallengeChat(input: $input, condition: $condition) {
      id
      assessmentId
      userId
      createdAt
      updatedAt
    }
  }
`;

export const updateUserOrgAffliation = /* GraphQL */ `
  mutation UpdateUserOrgAffliation(
    $input: UpdateUserOrgAffliationInput!
    $condition: ModelUserOrgAffliationConditionInput
  ) {
    updateUserOrgAffliation(input: $input, condition: $condition) {
      id
      userId
      orgId
      status
      customerWorkroleID
      createdAt
      updatedAt
    }
  }
`;
