import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { FdTypography } from '@fifthdomain/fe-shared';

const ContentWithLabel = ({ label, content }) => (
  <Box className="flex flex-col mt-6">
    <FdTypography variant="h4">{label}</FdTypography>
    <Box mt={1}>
      <FdTypography variant="body1" color="secondary">
        {content}
      </FdTypography>
    </Box>
  </Box>
);
ContentWithLabel.propTypes = {
  label: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};

export default ContentWithLabel;
