import React from 'react';
import { gql } from '@apollo/client';
import { Box } from '@mui/material';
import _ from 'lodash';
import {
  FdTypography,
  FdCard,
  useQueryRecursive,
  useSnapshot,
  globalStore,
  BasePage,
  FdSkeleton,
} from '@fifthdomain/fe-shared';
import { FdBreadcrumbHeader } from '@fifthdomain/sidebar';
import {
  listNonAffliatedUserEventsByOrgId,
  listAffliationRequestsByOrgId,
} from '../graphql/queries';
import NonAffiliatedUsersTable from '../components/Affiliated/NonAffiliatedUsersTable';

const UsersNonAffiliated = () => {
  const globalSnap = useSnapshot(globalStore);
  const { orgId } = globalSnap;

  const {
    data: nonAffiliatedUsersData,
    loading: nonAffiliatedUsersLoading,
    refetch: refetchNonAffiliatedUsersData,
  } = useQueryRecursive(gql(listNonAffliatedUserEventsByOrgId), {
    variables: {
      orgId,
      limit: 500,
    },
    skip: !orgId,
  });

  const {
    data: affiliatedRequestsData,
    loading: affiliatedRequestsLoading,
    refetch: refetchAffiliatedRequestsData,
  } = useQueryRecursive(gql(listAffliationRequestsByOrgId), {
    variables: {
      orgId,
      limit: 500,
    },
    skip: !orgId,
  });

  const allAffiliatedUsers =
    affiliatedRequestsData?.listAffliationRequestsByOrgId?.items?.filter(
      (u) => !['DISAFFILIATED', 'REVOKED'].includes(u?.status),
    ) || [];
  const allAffiliatedUserEmails = allAffiliatedUsers.map((u) => u.email);
  const verifiedNonAffiliatedUsers =
    nonAffiliatedUsersData?.listNonAffliatedUserEventsByOrgId?.items.filter(
      (u) => !allAffiliatedUserEmails.includes(u?.email),
    ) || [];

  const allNonAffiliatedUsersByEmail = _.groupBy(
    verifiedNonAffiliatedUsers,
    'email',
  );
  const allNonAffiliatedUsers =
    Object.keys(allNonAffiliatedUsersByEmail).map((user) => {
      const userData = allNonAffiliatedUsersByEmail[user];

      const groups =
        userData[0]?.user?.groups?.items?.map((group) => ({
          id: group.groupId,
          name: group.group?.name,
        })) || [];

      return {
        id: userData[0]?.email,
        userId: userData[0]?.userId,
        name: userData[0]?.user?.name,
        email: userData[0]?.email,
        alias: userData[0]?.user?.alias,
        registrationType: userData[0]?.user?.name ? 'Registered' : 'Invited',
        assignedEvents: [
          ...new Set([...(userData?.map((u) => u.eventType) || [])].flat()),
        ],
        groups,
      };
    }) || [];

  return (
    <Box>
      <FdBreadcrumbHeader
        page={{ name: 'Non- Affiliated Users', type: 'USER' }}
      />
      <BasePage data-cy="non-affiliated-users-page">
        <FdCard variant="outlined">
          <FdTypography variant="h3">Non- Affiliated Users</FdTypography>
          <Box my={2}>
            <FdTypography variant="body2" color="secondary">
              These are users who are not inherently connected to your
              organisation, but are individuals who have participated, or are
              invited to participate, in your organisation&apos;s events. When
              viewing non-affiliated users, your organisation can only view the
              activities and skills they have demonstrated within your
              organisation.
            </FdTypography>
          </Box>
          <Box mt={1}>
            <FdSkeleton
              loading={nonAffiliatedUsersLoading || affiliatedRequestsLoading}
              height={643}
            >
              <NonAffiliatedUsersTable
                rows={allNonAffiliatedUsers}
                refetchQueries={
                  refetchNonAffiliatedUsersData || refetchAffiliatedRequestsData
                }
                allAffiliatedUsers={allAffiliatedUsers}
              />
            </FdSkeleton>
          </Box>
        </FdCard>
      </BasePage>
    </Box>
  );
};
export default UsersNonAffiliated;
