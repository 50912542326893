import { LABS_STATUS, LAB_INSTANCE_STATUS, VM_STATUS } from '../../constants';

export const getLabsStatusColor = (status) => {
  switch (status) {
    case LABS_STATUS.ERROR:
    case LABS_STATUS.EXPIRED:
      return 'error';
    case LABS_STATUS.AVAILABLE:
      return 'success';
    case LABS_STATUS.BUILDING:
      return 'action';
    case LABS_STATUS.DRAFT:
      return 'default';
    case LABS_STATUS.EDIT_IN_PROGRESS:
      return 'warning';
    default:
      return 'success';
  }
};

export const getLabVmsStatusColor = (status) => {
  switch (status) {
    case LAB_INSTANCE_STATUS.OFF:
      return 'error';
    case LAB_INSTANCE_STATUS.READY:
      return 'success';
    case LAB_INSTANCE_STATUS.POWERING_OFF:
    case LAB_INSTANCE_STATUS.POWERING_ON:
      return 'default';
    default:
      return 'default';
  }
};

export const getCoursesStatusColor = (status) => {
  switch (status) {
    case 'Not Started':
      return 'warning';
    case 'In Progress':
      return 'action';
    case 'Completed':
      return 'success';
    case 'Available':
      return 'success';
    case 'Not Available':
      return 'action';
    case 'Draft':
      return 'default';
    default:
      return 'success';
  }
};

export const getVMStatusColor = (status) => {
  switch (status) {
    case VM_STATUS.READY:
      return 'success';
    case VM_STATUS.BUILDING:
      return 'warning';
    case VM_STATUS.UPLOADED:
      return 'action';
    default:
      return 'default';
  }
};

export const getComposeFileStatusColor = (status) => {
  switch (status) {
    case 'READY':
    case 'RUNNING':
    case 'APPROVED':
    case 'Build Successful':
      return 'success';
    case 'STOPPED':
    case 'STOPPING':
    case 'Stopping':
    case 'STARTING':
    case 'PROCESSING':
      return 'warning';
    case 'BUILDING':
    case 'Building':
      return 'action';
    case 'IN_QUEUE':
      return 'default';
    case 'FAILED':
    case 'DELETED':
    case 'REJECTED':
    case 'Build Failed':
      return 'error';
    default:
      return 'default';
  }
};

export const getParticipantStatusColor = (status) => {
  const _status = status.replaceAll('_', ' ');
  switch (_status) {
    case 'STARTED':
    case 'IN PROGRESS':
      return 'action';
    case 'NOT STARTED':
      return 'warning';
    case 'FINISHED':
    case 'COMPLETED':
      return 'success';
    case 'REMOVED':
      return 'error';
    case 'NOT COMPLETED':
      return 'error';
    case 'INVITED':
      return 'primary';
    case 'ENDED':
      return 'error';
    default:
      return 'success';
  }
};
