import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Box, Divider, Chip, TextField } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useParams } from 'react-router-dom';
import {
  FdCard,
  FdIconWithTooltip,
  FdTypography,
  FdButton,
  FdAutocomplete,
  useQueryRecursive,
  FdLoadingSpinner,
  FdChips,
  useSnapshot,
  globalStore,
  getTextColorByBackgroundColor,
  successToastMessage,
  warningToastMessage,
} from '@fifthdomain/fe-shared';
import { gql, useMutation } from '@apollo/client';
import {
  listUserEventTagsByUserAssessmentId,
  listTeamEventTagsByTeamEventId,
  listTeamEvents,
} from '../../graphql/queries';
import { getTagColor } from '../../shared/utils/tagUtils';
import {
  createUserEventTag,
  deleteUserEventTag,
  createTeamEventTag,
  deleteTeamEventTag,
} from '../../graphql/mutations';

const useStyles = makeStyles()(() => ({
  tagContainer: {
    width: 390,
    display: 'flex',
    flexWrap: 'wrap',
    margin: '1rem 0 1rem 0',
  },
}));

const EventTags = ({ eventTagData, teamBased, teamId, _assessmentId }) => {
  const { classes } = useStyles();
  const globalSnap = useSnapshot(globalStore);
  const [editTags, setEditTags] = useState(false);
  const { assessmentId } = useParams();
  const [tagsadded, setTagsAdded] = useState([]);
  const [tagsDeleted, setTagsDeleted] = useState([]);

  // List the event tags for the assessmentId
  const {
    data: listUserEventTagsByUserAssessmentIdData,
    loading: listUserEventTagsByUserAssessmentIdLoading,
    refetch: refetchlistUserEventTagsByUserAssessmentId,
  } = useQueryRecursive(gql(listUserEventTagsByUserAssessmentId), {
    variables: {
      userAssessmentId: assessmentId,
      limit: 500,
    },
    staleTime: { seconds: 0 },
    skip: !assessmentId || teamBased,
  });

  // List the event tags for the TeamId
  const { data: listTeamEventsData, loading: listTeamEventsLoading } =
    useQueryRecursive(gql(listTeamEvents), {
      variables: {
        filter: {
          eventId: {
            eq: _assessmentId,
          },
          teamId: { eq: teamId },
        },
        limit: 500,
      },
      staleTime: { seconds: 0 },
      skip: !assessmentId || !teamBased,
    });

  const teamEventId = listTeamEventsData?.listTeamEvents?.items[0]?.id;
  const {
    data: listTeamEventTagsByTeamEventIdData,
    loading: listTeamEventTagsByTeamEventIdLoading,
    refetch: refetchlistTeamEventTagsByTeamEventId,
  } = useQueryRecursive(gql(listTeamEventTagsByTeamEventId), {
    variables: {
      teamEventId,
      limit: 500,
    },
    staleTime: { seconds: 0 },
    skip: !assessmentId || !teamBased || !teamEventId,
  });

  const tagData = teamBased
    ? listTeamEventTagsByTeamEventIdData?.listTeamEventTagsByTeamEventId
    : listUserEventTagsByUserAssessmentIdData?.listUserEventTagsByUserAssessmentId;

  const tagApplied = _.uniqBy(
    tagData?.items?.map((t) => ({
      ...t,
      label: t?.eventTag?.name,
      color: t?.eventTag?.color && getTagColor(t?.eventTag?.color),
    })) || [],
    'label',
  );

  const existingTags = tagApplied?.map((t) => ({
    userEventTagId: t?.id,
    id: t?.eventTag?.id,
    name: t?.eventTag?.name,
    color: t?.eventTag?.color,
    assessmentId: t?.assessmentId,
  }));

  const [createUserEventTagMutation, { loading: createEventTagLoading }] =
    useMutation(gql(createUserEventTag));
  const [createTeamEventTagMutation, { loading: createTeamTagLoading }] =
    useMutation(gql(createTeamEventTag));

  const [deleteUserEventTagMutation, { loading: deleteUserEventTagLoading }] =
    useMutation(gql(deleteUserEventTag));
  const [deleteTeamEventTagMutation, { loading: deleteTeamEventTagLoading }] =
    useMutation(gql(deleteTeamEventTag));

  if (
    listUserEventTagsByUserAssessmentIdLoading ||
    listTeamEventTagsByTeamEventIdLoading ||
    listTeamEventsLoading
  ) {
    return <FdLoadingSpinner />;
  }

  return (
    <Box my={2} pb={4}>
      <FdCard
        heading={
          <Box display="flex" justifyContent="space-between">
            <Box display="flex" alignItems="center">
              <FdTypography variant="h4">Apply Event Tags</FdTypography>
              <FdIconWithTooltip
                title="Event tags are unique labels created by the administrators for this competition. 

                By applying these tags, you make it easier for administrators to identify and categorise you/your team for various recognition and reward initiatives."
              />
            </Box>
            {editTags ? (
              <Box display="flex" justifyContent="space-between">
                <Box pr={1}>
                  <FdButton
                    variant="secondary"
                    size="small"
                    onClick={() => {
                      setEditTags(false);
                      warningToastMessage('Applied event tags not updated');
                    }}
                  >
                    CANCEL
                  </FdButton>
                </Box>
                <Box>
                  <FdButton
                    variant="primary"
                    size="small"
                    onClick={async () => {
                      const eventTagAddedMutation = teamBased
                        ? createTeamEventTagMutation
                        : createUserEventTagMutation;
                      const eventTagDeletedMutation = teamBased
                        ? deleteTeamEventTagMutation
                        : deleteUserEventTagMutation;
                      const reftechListTags = teamBased
                        ? refetchlistTeamEventTagsByTeamEventId
                        : refetchlistUserEventTagsByUserAssessmentId;
                      if (tagsadded?.length > 0) {
                        await Promise.all(
                          tagsadded?.map((tag) => {
                            return eventTagAddedMutation({
                              variables: {
                                input: {
                                  assessmentId: tag?.assessmentId,
                                  eventTagId: tag?.id,
                                  teamEventId: teamBased
                                    ? teamEventId
                                    : undefined,
                                  applied: teamBased ? true : undefined,
                                  userAssessmentId: !teamBased
                                    ? assessmentId
                                    : undefined,
                                  userId: !teamBased
                                    ? globalSnap.userId
                                    : undefined,
                                },
                              },
                            });
                          }),
                        );
                      }

                      if (tagsDeleted?.length > 0) {
                        await Promise.all(
                          tagsDeleted?.map((tag) => {
                            return eventTagDeletedMutation({
                              variables: {
                                input: {
                                  id: tag?.userEventTagId,
                                },
                              },
                            });
                          }),
                        );
                      }
                      reftechListTags();
                      setEditTags(false);
                      successToastMessage(
                        'Success! Applied event tags updated',
                      );
                    }}
                  >
                    {createEventTagLoading ||
                    deleteUserEventTagLoading ||
                    createTeamTagLoading ||
                    deleteTeamEventTagLoading
                      ? 'Loading'
                      : 'Save'}
                  </FdButton>
                </Box>
              </Box>
            ) : (
              <FdButton
                variant="primary"
                onClick={() => {
                  setEditTags(true);
                }}
              >
                Edit
              </FdButton>
            )}
          </Box>
        }
        subHeading="Your event administrator has created tags for this event. Select tags that represent you/your team by clicking the ‘Edit’ button. You can modify your tags any time during the event via the ‘Overview’ tab. "
      >
        {editTags ? (
          <Box my={2}>
            <FdAutocomplete
              label=""
              helperText="Use the dropdown menu to apply event tags as required"
              options={
                eventTagData?.map((at) => ({
                  ...at,
                  displayColor: getTagColor(at.color),
                })) || []
              }
              multiple
              fullWidth
              selectAllLabel="Select all"
              optionLabel="name"
              optionValue="id"
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  <Chip
                    label={option.name}
                    {...getTagProps({ index })}
                    style={{
                      backgroundColor: getTagColor(option.color),
                      color: getTextColorByBackgroundColor(option.color),
                    }}
                  />
                ))
              }
              ListItemTextRenderer={({ option }) => {
                return (
                  <Chip
                    label={option.name}
                    style={{
                      backgroundColor: option.displayColor,
                      color: getTextColorByBackgroundColor(option.displayColor),
                    }}
                  />
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Add an Existing Tag.."
                />
              )}
              defaultSelected={existingTags}
              onChange={(currentSelection) => {
                const existingTagIds = existingTags?.map((et) => et.id);
                const added = currentSelection?.filter(
                  (et) => !existingTagIds?.includes(et.id),
                );

                const deleted = existingTags?.filter(
                  (et) =>
                    !currentSelection?.map((cs) => cs.id)?.includes(et.id),
                );
                setTagsAdded(added);
                setTagsDeleted(deleted);
              }}
            />
          </Box>
        ) : (
          <Box mb={2}>
            <Box my={3}>
              <Divider />
            </Box>
            <FdTypography>{`Tags Applied : ${tagApplied?.length}`}</FdTypography>

            {tagApplied?.length > 0 ? (
              <Box display="flex" mb={2} mt={1}>
                <Box className={classes.tagContainer}>
                  <FdChips data={tagApplied} />
                </Box>
              </Box>
            ) : (
              <FdTypography
                variant="captiontext1"
                color="secondary"
                style={{ marginTop: '12px' }}
              >
                No tags applied
              </FdTypography>
            )}
          </Box>
        )}
      </FdCard>
    </Box>
  );
};

EventTags.propTypes = {
  teamBased: PropTypes.bool,
  teamId: PropTypes.string,
  _assessmentId: PropTypes.string,
  eventTagData: PropTypes.arrayOf({
    id: PropTypes.string.isRequired,
  }),
};
EventTags.defaultProps = {
  teamId: undefined,
  teamBased: false,
  eventTagData: [],
  _assessmentId: '',
};

export default EventTags;
