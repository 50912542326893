import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { Box, Stepper, Step, StepLabel, CircularProgress } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import {
  FdButton,
  FdModal,
  useSnapshot,
  globalStore,
  FdTypography,
  successToastMessage,
  warningToastMessage,
} from '@fifthdomain/fe-shared';
import { scrollToTop } from '../../../shared/utils/scroll';
import SquadDetails from './SquadDetails';
import SquadUsers from './SquadUsers';
import { createSquad, createSquadMember } from '../../../graphql/mutations';
import { createSquadUsers, onError } from '../utils';

const steps = ['Add Squad Details', 'Add Squad Users'];

const CreateSquad = ({
  openModal,
  setOpenModal,
  users,
  existingSquadMembersInOrg,
  refetchFunction,
  squadIdx,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const { orgId } = useSnapshot(globalStore);
  const { getValues, trigger, reset } = useFormContext();
  const [createSquadMutation, { loading: createSquadMutationLoading }] =
    useMutation(gql(createSquad), {
      onError,
    });
  const [
    createSquadMemberMutation,
    { loading: createSquadMemberMutationLoading },
  ] = useMutation(gql(createSquadMember), {
    onError,
  });
  const squadId = `squads[${squadIdx}]`;
  const loading =
    createSquadMutationLoading || createSquadMemberMutationLoading;
  const allManagers = users?.filter((u) =>
    u?.permissions?.includes('MANAGE_SQUADS'),
  );

  const onSubmit = async () => {
    const result = await trigger([squadId]);
    if (result) {
      const { squadName, squadDescription } = getValues(squadId);
      createSquadMutation({
        variables: {
          input: {
            orgId,
            name: squadName,
            description: squadDescription,
            status: 'ACTIVE',
            functionId: getValues('functionId'),
          },
        },
        onCompleted: (_data) => {
          const squadPkId = _data?.createSquad?.id;
          const squadMembers = [
            ...(getValues(squadId).squadManagers?.map((s) => ({
              ...s,
              role: 'MANAGER',
            })) || []),
            ...(getValues(squadId).squadMembers?.map((s) => ({
              ...s,
              role: 'MEMBER',
            })) || []),
          ];
          createSquadUsers({
            squadUserMutation: createSquadMemberMutation,
            squadId: squadPkId,
            users: squadMembers,
            onCompleted: () => {
              setOpenModal(false);
              successToastMessage('Squad created and added in the function.');
              refetchFunction();
            },
          });
        },
      });
    }
  };

  const validatePage = async () => {
    let result;
    switch (activeStep) {
      case 0: {
        result = await trigger([
          `${squadId}.squadName`,
          `${squadId}.squadDescription`,
        ]);
        break;
      }
      default:
        result = true;
        break;
    }
    return result;
  };

  const handleNext = async () => {
    if (await validatePage()) {
      if (activeStep === 1) {
        onSubmit();
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        scrollToTop();
      }
    }
  };

  const handleBack = () => {
    // on click cancel of first page go back to list page
    if (activeStep === 0) {
      setOpenModal(false);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      scrollToTop();
    }
  };

  return (
    <FdModal
      size="xl"
      open={openModal}
      showConfirm={false}
      showDismiss={false}
      hideFooter
      hideHeader
    >
      <Box mt={2.5} mb={1}>
        <FdTypography variant="h3">Create a Squad</FdTypography>
      </Box>
      <Box width={410}>
        <Stepper activeStep={activeStep} sx={{ height: '70px' }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      <Box maxHeight={700} sx={{ overflowY: 'auto' }}>
        {
          {
            0: <SquadDetails squadId={squadId} />,
            1: (
              <SquadUsers
                squadId={squadId}
                allManagers={allManagers}
                allUsers={users}
                existingSquadMembersInOrg={existingSquadMembersInOrg}
              />
            ),
          }[activeStep]
        }
      </Box>
      <Box className="flex justify-between" mt={2} pb={3}>
        <FdButton
          size="large"
          variant="secondary"
          onClick={() => {
            setActiveStep(0);
            setOpenModal(false);
            warningToastMessage('Squad not created.');
            reset();
          }}
        >
          Cancel
        </FdButton>
        <Box className="flex gap-x-3">
          {activeStep === 1 && (
            <FdButton size="large" variant="secondary" onClick={handleBack}>
              Back
            </FdButton>
          )}
          <Box>
            {activeStep === 1 && (
              <FdButton size="large" disabled={loading} onClick={onSubmit}>
                {loading && <CircularProgress size={16} className="mr-2" />}
                Create Squad
              </FdButton>
            )}
            {activeStep < 1 && (
              <FdButton size="large" onClick={handleNext}>
                Next, add users to the squad
              </FdButton>
            )}
          </Box>
        </Box>
      </Box>
    </FdModal>
  );
};

CreateSquad.propTypes = {
  openModal: PropTypes.bool.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  existingSquadMembersInOrg: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  refetchFunction: PropTypes.func.isRequired,
  squadIdx: PropTypes.string.isRequired,
};

export default CreateSquad;
