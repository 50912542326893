import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import * as singleSpa from 'single-spa';
import {
  FdTable as Table,
  FdChip,
  FdTypography,
  FdButton,
} from '@fifthdomain/fe-shared';
import { ReleaseModal } from './Training';
import {
  getTemplateStatusColor,
  getAvailabilityColor,
} from '../../shared/utils/getStatusColor';
import { capitalize } from '../../shared/utils/stringUtils';
import { TEMPLATE_STATUS, AVAILABILITY_STATUS } from '../../constants';

const TrainingTemplateTable = ({ rows, refetchTemplateData }) => {
  const [releasedStatus, setReleasedStatus] = useState(false);
  const [templateDetails, setTemplateDetails] = useState(undefined);

  const actions = [
    {
      label: 'View',
      show: () => true,
      onClick: ({ id }) => {
        singleSpa.navigateToUrl(
          `/org/template-library/view/training-template/${id}`,
        );
      },
    },
    {
      label: 'Release',
      onClick: (row) => {
        setReleasedStatus(TEMPLATE_STATUS.RELEASED);
        setTemplateDetails(row);
      },
      show: ({ status, availabilityStatus }) =>
        status === TEMPLATE_STATUS.DRAFT &&
        [AVAILABILITY_STATUS.Upcoming, AVAILABILITY_STATUS.Active]?.includes(
          availabilityStatus,
        ),
    },
    {
      label: 'Rollback',
      onClick: (row) => {
        setReleasedStatus(TEMPLATE_STATUS.ROLLBACKED);
        setTemplateDetails(row);
      },
      show: ({ status, availabilityStatus }) =>
        status === TEMPLATE_STATUS.RELEASED &&
        availabilityStatus === AVAILABILITY_STATUS.Active,
    },
  ];

  return (
    <Box>
      <Box className="flex justify-between mt-2 mb-6">
        <FdTypography variant="h3"> Training Templates</FdTypography>
        <FdButton
          size="large"
          onClick={() => {
            singleSpa.navigateToUrl(
              '/org/template-library/create/training-template',
            );
          }}
        >
          Create
        </FdButton>
      </Box>
      <Box height="652px">
        <Table
          elevation={0}
          id="template-table"
          toolbarSettings={{
            filterButton: true,
            searchBox: true,
          }}
          selection={false}
          rows={rows}
          actions={actions}
          columns={[
            {
              field: 'name',
              flex: 1,
              headerName: 'Template Name',
            },
            {
              field: 'description',
              flex: 1,
              headerName: 'Description',
            },
            {
              field: 'status',
              headerName: 'Release Status',
              flex: 1,
              renderCell: (params) => (
                <FdChip
                  color={getTemplateStatusColor(params?.value)}
                  size="small"
                  label={capitalize(params?.value)}
                />
              ),
              sortComparator: (v1, v2, param1, param2) =>
                param1.value.localeCompare(param2.value),
            },
            {
              field: 'availabilityStatus',
              headerName: 'Availability Status',
              flex: 1,
              renderCell: (params) => (
                <FdChip
                  color={getAvailabilityColor(params?.value)}
                  size="small"
                  label={capitalize(params?.value)}
                />
              ),
              sortComparator: (v1, v2, param1, param2) =>
                param1.value.localeCompare(param2.value),
            },
            {
              field: 'availability',
              flex: 1,
              headerName: 'Availability',
              valueGetter: (params) => params.row?.availability || '-',
            },
          ]}
          tablePageSize={10}
          gridId="org-template-lib-templates"
        />
      </Box>
      <ReleaseModal
        openModal={releasedStatus}
        setOpenModal={setReleasedStatus}
        templateDetails={templateDetails}
        onConfirm={async () => {
          refetchTemplateData();
          setTemplateDetails(undefined);
        }}
        onDismiss={() => {
          setTemplateDetails(undefined);
        }}
      />
    </Box>
  );
};

TrainingTemplateTable.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ).isRequired,
  refetchTemplateData: PropTypes.func,
};

TrainingTemplateTable.defaultProps = {
  refetchTemplateData: () => {},
};

export default TrainingTemplateTable;
