import Yup from '../Common';

const normalizeDate = (date) => {
  if (!date) return null;
  const normalized = new Date(date);
  normalized.setHours(0, 0, 0, 0);
  return normalized;
};

export const initialValues = {
  name: '',
  description: '',
  trainingStartDate: null,
  trainingEndDate: null,
  tasks: [],
};

export const validationSchema = ({ trainingNames, existingTrainingName }) =>
  Yup.object().shape({
    name: Yup.string()
      .required('Please enter a Training Name.')
      .max(150, 'Training Name must be 150 characters or less.')
      .noWhitespace('Please enter a Training Name.')
      .test(
        'unique-test',
        'Training Name already in use. Please enter a unique Training Name.',
        (value) =>
          existingTrainingName?.toLowerCase() !== value?.toLowerCase()
            ? !trainingNames?.includes(value?.toLowerCase())
            : true,
      )
      .test(
        'noTrailing&LeadingWhitespace',
        'Please enter a Training Name.',
        (value) => value && value.length === value.trim().length,
      ),
    description: Yup.string()
      .required('Please enter a Training Description.')
      .max(300, 'Training Description must be 300 characters or less. ')
      .noWhitespace('Please enter a Training Description.')
      .test(
        'noTrailing&LeadingWhitespace',
        'Please enter a Training Description.',
        (value) => value && value.length === value.trim().length,
      ),
    trainingStartDate: Yup.date()
      .nullable()
      .typeError('Please enter a valid Training Start.')
      .test(
        'is-future-start',
        'Training Start must be in the future.',
        (value) => {
          if (!value) return true;
          const today = normalizeDate(new Date());
          return normalizeDate(value) > today;
        },
      ),
    trainingEndDate: Yup.date()
      .required('Please enter a Training End.')
      .typeError('Please enter a valid Training End.')
      .test('is-future-end', 'Training End must be in the future.', (value) => {
        if (!value) return false;
        const today = normalizeDate(new Date());
        return normalizeDate(value) > today;
      })
      .test(
        'end-after-start',
        'Training End must be after Training Start.',
        function (value) {
          const { trainingStartDate } = this.parent;
          if (!trainingStartDate) return true;
          const startDate = normalizeDate(trainingStartDate);
          const endDate = normalizeDate(value);
          return endDate > startDate;
        },
      ),
    tasks: Yup.array().min(1),
  });
