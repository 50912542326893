import React, { useState } from 'react';
import { Box } from '@mui/material';
import useAsyncEffect from 'use-async-effect';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  FdCard,
  FdButton,
  FdTypography,
  FdTooltip,
  FdChip,
  warningToastMessage,
  successToastMessage,
  errorToastMessage,
} from '@fifthdomain/fe-shared';
import { useFormContext, Controller, useWatch } from 'react-hook-form';
import { useMutation, gql } from '@apollo/client';
import { propTypes } from 'react-markdown';
import fileUploadAWS from '../../shared/utils/fileUploadAWS';
import { getToolTipTitle } from '../../constants';
import FdFileUploadModal from '../FdFileUploadModal/FdFileUploadModal';
import { getImageUrlFromS3 } from '../../shared/utils/getImageFromS3';
import PdfViewer from '../PdfViewer/PdfViewer';
import { updateUser } from '../../graphql/mutations';

const Resume = ({ userRefetch }) => {
  const [uploadOpen, setUploadOpen] = useState(false);
  const [fileUrl, setFileUrl] = useState('');
  const [fileLoading, setFileLoading] = useState(true);
  const { setValue, trigger, control, getValues } = useFormContext();
  const watchFile = useWatch({
    control,
    name: 'file',
  });
  const onConfirm = (fn) => fn(false);
  const onDismiss = (fn) => fn(false);
  useAsyncEffect(async () => {
    const fileUrlKey = String(watchFile?.name || watchFile?.key) || '';
    const fileUrlS3 = await getImageUrlFromS3(fileUrlKey);
    setFileUrl(fileUrlS3);
    setFileLoading(false);
  });

  const [updateUserMutation, { loading: updateUserLoading }] = useMutation(
    gql(updateUser),
    {
      onError: ({ graphQLErrors }) => {
        errorToastMessage(graphQLErrors[0]?.message);
      },
    },
  );

  if (fileLoading || updateUserLoading) return null;

  return (
    <FdCard
      heading={
        <Box display="flex">
          <FdTypography variant="h3">Resume</FdTypography>
          <FdTooltip position="top" title={getToolTipTitle('Resume')}>
            <Box pl={1} pt={0.8}>
              <InfoOutlinedIcon />
            </Box>
          </FdTooltip>
        </Box>
      }
      subHeading="Upload a resume via the Choose File button. Only PDF files are supported, and only 
      one file can be uploaded at once. You can click on an uploaded file to download it. To delete the file, 
      click the cross next to its name."
      data-cy="media"
    >
      <Box display="flex" mb={3}>
        <FdButton
          data-cy="upload-btn"
          size="small"
          onClick={() => setUploadOpen(true)}
        >
          Choose file
        </FdButton>

        <Controller
          control={control}
          name="file"
          render={({ field: { value: fieldValue } }) => (
            <Box ml={2}>
              {(watchFile?.name || watchFile?.key) && (
                <FdChip
                  color="default"
                  label={fieldValue?.name || fieldValue?.key}
                  onDelete={async () => {
                    setValue('file', undefined);
                    await trigger('file');
                    // muatation to remove the file
                    updateUserMutation({
                      variables: {
                        input: {
                          id: getValues().id,
                          file: {
                            bucket: '',
                            key: '',
                            region: '',
                          },
                        },
                      },

                      onCompleted: () =>
                        successToastMessage('Success! File removed'),
                    });
                  }}
                  style={{
                    marginRight: '0.5rem',
                    marginBottom: '0.5rem',
                  }}
                />
              )}
            </Box>
          )}
        />
      </Box>

      {(watchFile?.name || watchFile?.key) && (
        <PdfViewer theme="dark" fileUrl={fileUrl} />
      )}
      <FdFileUploadModal
        title="Upload Resume"
        description="Select and upload a .pdf file from your local drive."
        confirm="Save"
        dismiss="Cancel"
        open={uploadOpen}
        onConfirm={async (files) => {
          onConfirm(setUploadOpen);
          // upload file if any
          const fileUploadInfo = await fileUploadAWS({
            file: files[0],
            fileType: '.pdf',
          });

          updateUserMutation({
            variables: {
              input: {
                id: getValues().id,
                file: {
                  bucket: fileUploadInfo?.bucket,
                  key: fileUploadInfo?.name,
                  region: fileUploadInfo?.region,
                },
              },
            },

            onCompleted: () => {
              successToastMessage('Success! File Uploaded');
              userRefetch();
            },
          });
        }}
        onDismiss={() => {
          onDismiss(setUploadOpen);
          warningToastMessage('No files were added');
        }}
        setOpen={setUploadOpen}
        fileTypes={{ 'application/pdf': ['.pdf'] }}
        maxWidth="md"
        initialFiles={[]}
      />
    </FdCard>
  );
};

Resume.propTypes = {
  userRefetch: propTypes.func,
};

Resume.defaultProps = {
  userRefetch: () => {},
};

export default Resume;
