import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { useMutation, gql } from '@apollo/client';
import {
  FdTable as Table,
  FdChip as Chip,
  FdModal,
  FdTypography,
  successToastMessage,
  warningToastMessage,
  FdButton,
} from '@fifthdomain/fe-shared';
import * as singleSpa from 'single-spa';
import { getTemplateStatusColor } from '../../shared/utils/getStatusColor';
import { updateTemplate } from '../../graphql/mutations';
import ContentInfoCell from './ContentInfoCell';
import { getDateTimeZoneFormatted } from '../../shared/utils/dateUtils';
import { capitalize } from '../../shared/utils/stringUtils';

const TemplateTable = ({ rows, refetchTemplateData, templateType }) => {
  const [updateTemplateMutation, { loading: updateTemplateLoading }] =
    useMutation(gql(updateTemplate));
  const [template, setTemplate] = useState(undefined);
  const releasedTemplate = template?.status === 'UNRELEASED';
  const actions = [
    {
      label: 'View',
      show: ({ archived }) => !archived,
      onClick: ({ participantEventType, id }) => {
        const _templateType =
          participantEventType === 'COMPETITION' ? 'competition' : 'assessment';
        singleSpa.navigateToUrl(
          `/org/template-library/view/${_templateType}/${id}`,
        );
      },
    },

    {
      label: 'Release',
      onClick: (row) => {
        setTemplate(row);
      },
      show: ({ status }) => status === 'UNRELEASED',
    },
    {
      label: 'Unrelease',
      onClick: (row) => {
        setTemplate(row);
      },
      show: ({ status }) => status === 'RELEASED',
    },
  ];

  return (
    <Box>
      <Box className="flex justify-between mt-2 mb-6">
        <FdTypography variant="h3">
          {`${capitalize(templateType)} Templates`}
        </FdTypography>
        <FdButton
          size="large"
          onClick={() => {
            singleSpa.navigateToUrl(
              `/org/template-library/create/${templateType}`,
            );
          }}
        >
          Create
        </FdButton>
      </Box>
      <Box height="652px">
        <Table
          elevation={0}
          id="template-table"
          toolbarSettings={{
            filterButton: true,
            searchBox: true,
          }}
          columnVisibilityModel={{
            name: false,
            eventType: false,
            eventDuration: false,
          }}
          selection={false}
          rows={rows}
          actions={actions}
          columns={[
            {
              field: 'icon',
              width: 80,
              headerName: '',
              renderCell: (params) => params.row.icon,
            },
            {
              field: 'contentInfo',
              width: 300,
              headerName: 'Name',
              filterable: false,
              valueGetter: (params) => params.row.name,
              renderCell: (params) => <ContentInfoCell values={params.row} />,
            },
            {
              field: 'status',
              headerName: 'Release Status',
              width: 120,
              valueGetter: (params) => params?.value,
              renderCell: (params) => (
                <Chip
                  color={getTemplateStatusColor(params?.row?.status)}
                  size="small"
                  label={capitalize(params?.row?.status)}
                />
              ),
              sortComparator: (v1, v2, param1, param2) =>
                param1.value.localeCompare(param2.value),
            },
            // {
            //   field: 'proficiency',
            //   flex: 1,
            //   headerName: 'Proficiency',
            //   valueGetter: (params) => params?.value,
            //   renderCell: (params) =>
            //     params?.value && (
            //       <Chip size="small" label={params?.row?.Proficiency} />
            //     ),
            //   sortComparator: (v1, v2, param1, param2) =>
            //     param1.value.localeCompare(param2.value),
            // },
            {
              field: 'createdAt',
              width: 200,
              headerName: 'Created On',
              valueGetter: (params) => getDateTimeZoneFormatted(params?.value),
            },
            {
              field: 'creator',
              width: 200,
              headerName: 'Created by',
            },
          ]}
          tablePageSize={10}
          gridId="org-template-lib-templates"
        />
      </Box>
      <FdModal
        size="xs"
        title={`${releasedTemplate ? 'Release' : 'Unrelease'} this Template?`}
        description={
          <Box>
            <FdTypography variant="subtitle1">
              {` Are you sure that you want to ${
                releasedTemplate ? 'Release' : 'Unrelease'
              }  this template?`}
            </FdTypography>
            <Box mt={2}>
              <FdTypography variant="body1" color="secondary">
                {` If so, organisations will ${
                  releasedTemplate ? '' : 'no longer'
                } be able to view this template and use
                it for their own events. 
                ${
                  releasedTemplate
                    ? ''
                    : 'Events created from this template previously will not be affected.'
                } 

                If required, you can ${
                  releasedTemplate ? 'Unrelease' : 'Release'
                }  this template later.
                `}
              </FdTypography>
            </Box>
          </Box>
        }
        disableConfirm={updateTemplateLoading}
        confirm={updateTemplateLoading ? 'Loading...' : 'Confirm'}
        dismiss="CANCEL"
        open={template}
        onConfirm={async () => {
          updateTemplateMutation({
            variables: {
              input: {
                id: template?.id,
                status: releasedTemplate ? 'RELEASED' : 'UNRELEASED',
              },
            },
            onCompleted: () => {
              successToastMessage(
                `Success! Template ${
                  releasedTemplate ? 'released' : 'Unreleased'
                } `,
              );
              refetchTemplateData();
            },
          });
          setTemplate(undefined);
        }}
        onDismiss={() => {
          setTemplate(undefined);
          warningToastMessage(
            `Template not ${releasedTemplate ? 'released' : 'Unreleased'}`,
          );
        }}
      />
    </Box>
  );
};

TemplateTable.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ).isRequired,
  templateType: PropTypes.string.isRequired,
  refetchTemplateData: PropTypes.func,
};

TemplateTable.defaultProps = {
  refetchTemplateData: () => {},
};

export default TemplateTable;
