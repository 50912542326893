import React from 'react';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import PropTypes from 'prop-types';
import { ReactQueryClient } from '../clients';

const ReactQueryProvider = ({ children }) => (
  <QueryClientProvider client={ReactQueryClient}>
    {children}
    {/* <ReactQueryDevtools initialIsOpen={false} /> */}
  </QueryClientProvider>
);

ReactQueryProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default ReactQueryProvider;
