/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getSystemTime = /* GraphQL */ `
  query GetSystemTime {
    getSystemTime
  }
`;
export const getAssessment = /* GraphQL */ `
  query GetAssessment($id: ID!) {
    getAssessment(id: $id) {
      id
      orgId
      name
      description
      teamBased
      startDateTime
      endDateTime
      hours
      minutes
      tasks {
        items {
          id
          assessmentId
          taskId
          createdAt
          updatedAt
        }
        nextToken
      }
      preMessage
      postMessage
      createdBy
      participantEventType
      creator {
        id
        email
        name
        phone
        ageBracket
        type
        participantType
        orgId
        alias
        org {
          id
          name
          createdAt
          updatedAt
        }
        verifiedOnboarding
        status
        permissions
        groups {
          nextToken
        }
        tasks {
          nextToken
        }
        theme
        createdAt
        updatedAt
      }
      groups {
        items {
          groupId
          assessmentId
          status
          startedOn
          finishedOn
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listAssessments = /* GraphQL */ `
  query ListAssessments(
    $filter: ModelAssessmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAssessments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        orgId
        name
        description
        teamBased
        startDateTime
        endDateTime
        hours
        minutes
        tasks {
          nextToken
        }
        preMessage
        postMessage
        createdBy
        participantEventType
        creator {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTask = /* GraphQL */ `
  query GetTask($id: ID!) {
    getTask(id: $id) {
      id
      name
      description
      externalId
      category
      difficulty
      recommendedPoints
      estimatedSolveTime
      solution
      competencies {
        items {
          id
          competencyId
          taskId
          createdAt
          updatedAt
        }
        nextToken
      }
      assessments {
        items {
          id
          assessmentId
          taskId
          createdAt
          updatedAt
        }
        nextToken
      }
      answer
      files {
        items {
          id
          taskId
          url
          name
          size
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      hints {
        items {
          id
          taskId
          text
          createdAt
          updatedAt
        }
        nextToken
      }
      userId
      user {
        id
        email
        name
        phone
        ageBracket
        type
        participantType
        orgId
        alias
        org {
          id
          name
          createdAt
          updatedAt
        }
        verifiedOnboarding
        status
        permissions
        groups {
          nextToken
        }
        tasks {
          nextToken
        }
        theme
        createdAt
        updatedAt
      }
      status
      history {
        items {
          id
          taskId
          userId
          message
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listTasks = /* GraphQL */ `
  query ListTasks(
    $filter: ModelTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTasks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        externalId
        category
        difficulty
        recommendedPoints
        estimatedSolveTime
        solution
        competencies {
          nextToken
        }
        assessments {
          nextToken
        }
        answer
        files {
          nextToken
        }
        hints {
          nextToken
        }
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        status
        history {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getHistory = /* GraphQL */ `
  query GetHistory($id: ID!) {
    getHistory(id: $id) {
      id
      taskId
      userId
      message
      createdAt
      updatedAt
    }
  }
`;
export const listHistories = /* GraphQL */ `
  query ListHistories(
    $filter: ModelHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHistories(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        taskId
        userId
        message
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFile = /* GraphQL */ `
  query GetFile($id: ID!) {
    getFile(id: $id) {
      id
      taskId
      url
      name
      size
      type
      file {
        bucket
        key
        region
      }
      createdAt
      updatedAt
    }
  }
`;
export const listFiles = /* GraphQL */ `
  query ListFiles(
    $filter: ModelFileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        taskId
        url
        name
        size
        type
        file {
          bucket
          key
          region
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getHint = /* GraphQL */ `
  query GetHint($id: ID!) {
    getHint(id: $id) {
      id
      taskId
      text
      createdAt
      updatedAt
    }
  }
`;
export const listHints = /* GraphQL */ `
  query ListHints(
    $filter: ModelHintFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHints(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        taskId
        text
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserAssessment = /* GraphQL */ `
  query GetUserAssessment($id: ID!) {
    getUserAssessment(id: $id) {
      id
      userId
      assessment {
        id
        orgId
        guided
        name
        description
        teamBased
        startDateTime
        endDateTime
        hours
        minutes
        tasks {
          nextToken
        }
        preMessage
        postMessage
        createdBy
        participantEventType
        hideScoreBoard
        createdAt
        updatedAt
      }
      userAssessmentAssessmentId
      groupId
      status
      startedOn
      finishedOn
      createdAt
      updatedAt
    }
  }
`;
export const listUserAssessments = /* GraphQL */ `
  query ListUserAssessments(
    $filter: ModelUserAssessmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserAssessments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        assessment {
          id
          orgId
          name
          description
          teamBased
          startDateTime
          endDateTime
          hours
          minutes
          preMessage
          postMessage
          createdBy
          participantEventType
          createdAt
          updatedAt
        }
        userAssessmentAssessmentId
        groupId
        status
        startedOn
        finishedOn
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTaskAttempt = /* GraphQL */ `
  query GetTaskAttempt($id: ID!) {
    getTaskAttempt(id: $id) {
      id
      userId
      task {
        id
        name
        description
        externalId
        category
        difficulty
        recommendedPoints
        estimatedSolveTime
        solution
        competencies {
          nextToken
        }
        assessments {
          nextToken
        }
        answer
        files {
          nextToken
        }
        hints {
          nextToken
        }
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        status
        history {
          nextToken
        }
        createdAt
        updatedAt
      }
      userAssessment {
        id
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        assessment {
          id
          orgId
          name
          description
          teamBased
          startDateTime
          endDateTime
          hours
          minutes
          preMessage
          postMessage
          createdBy
          participantEventType
          createdAt
          updatedAt
        }
        userAssessmentAssessmentId
        groupId
        status
        startedOn
        finishedOn
        createdAt
        updatedAt
      }
      taskAttemptUserAssessmentId
      assessmentId
      success
      firstBlood
      answer
      createdAt
      updatedAt
      taskAttemptTaskId
    }
  }
`;
export const listTaskAttempts = /* GraphQL */ `
  query ListTaskAttempts(
    $filter: ModelTaskAttemptFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTaskAttempts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        task {
          id
          name
          description
          externalId
          category
          difficulty
          recommendedPoints
          estimatedSolveTime
          solution
          answer
          userId
          status
          createdAt
          updatedAt
        }
        userAssessment {
          id
          userId
          userAssessmentAssessmentId
          groupId
          status
          startedOn
          finishedOn
          createdAt
          updatedAt
        }
        taskAttemptUserAssessmentId
        assessmentId
        success
        firstBlood
        answer
        createdAt
        updatedAt
        taskAttemptTaskId
      }
      nextToken
    }
  }
`;
export const getTaskOpened = /* GraphQL */ `
  query GetTaskOpened($id: ID!) {
    getTaskOpened(id: $id) {
      id
      userId
      task {
        id
        name
        description
        externalId
        category
        difficulty
        recommendedPoints
        estimatedSolveTime
        solution
        competencies {
          nextToken
        }
        assessments {
          nextToken
        }
        answer
        files {
          nextToken
        }
        hints {
          nextToken
        }
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        status
        history {
          nextToken
        }
        createdAt
        updatedAt
      }
      userAssessment {
        id
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        assessment {
          id
          orgId
          name
          description
          teamBased
          startDateTime
          endDateTime
          hours
          minutes
          preMessage
          postMessage
          createdBy
          participantEventType
          createdAt
          updatedAt
        }
        userAssessmentAssessmentId
        groupId
        status
        startedOn
        finishedOn
        createdAt
        updatedAt
      }
      taskOpenedUserAssessmentId
      createdAt
      updatedAt
      taskOpenedTaskId
    }
  }
`;
export const listTaskOpeneds = /* GraphQL */ `
  query ListTaskOpeneds(
    $filter: ModelTaskOpenedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTaskOpeneds(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        task {
          id
          name
          description
          externalId
          category
          difficulty
          recommendedPoints
          estimatedSolveTime
          solution
          answer
          userId
          status
          createdAt
          updatedAt
        }
        userAssessment {
          id
          userId
          userAssessmentAssessmentId
          groupId
          status
          startedOn
          finishedOn
          createdAt
          updatedAt
        }
        taskOpenedUserAssessmentId
        createdAt
        updatedAt
        taskOpenedTaskId
      }
      nextToken
    }
  }
`;
export const getCompetency = /* GraphQL */ `
  query GetCompetency($id: ID!) {
    getCompetency(id: $id) {
      id
      area
      topic
      competency
      code
      tasks {
        items {
          id
          competencyId
          taskId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCompetencies = /* GraphQL */ `
  query ListCompetencies(
    $filter: ModelCompetencyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompetencies(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        area
        topic
        competency
        code
        tasks {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOnboarding = /* GraphQL */ `
  query GetOnboarding($id: ID!) {
    getOnboarding(id: $id) {
      id
      userId
      user {
        id
        email
        name
        phone
        ageBracket
        type
        participantType
        orgId
        alias
        org {
          id
          name
          createdAt
          updatedAt
        }
        verifiedOnboarding
        status
        permissions
        groups {
          nextToken
        }
        tasks {
          nextToken
        }
        theme
        createdAt
        updatedAt
      }
      cyberExperience
      highestEducation
      highestEducationOther
      cyberTraining
      formalTraining
      formalTrainingOther
      selfDirectedTraining {
        hours
      }
      workingIndustry
      workingIndustryOther
      createdAt
      updatedAt
    }
  }
`;
export const listOnboardings = /* GraphQL */ `
  query ListOnboardings(
    $filter: ModelOnboardingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOnboardings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        cyberExperience
        highestEducation
        highestEducationOther
        cyberTraining
        formalTraining
        formalTrainingOther
        selfDirectedTraining {
          hours
        }
        workingIndustry
        workingIndustryOther
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOrg = /* GraphQL */ `
  query GetOrg($id: ID!) {
    getOrg(id: $id) {
      id
      name
      alias
      createdAt
      updatedAt
    }
  }
`;
export const listOrgs = /* GraphQL */ `
  query ListOrgs(
    $filter: ModelOrgFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrgs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        members {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserConsent = /* GraphQL */ `
  query GetUserConsent($id: ID!) {
    getUserConsent(id: $id) {
      id
      userId
      type
      consentedOn
      version
      createdAt
      updatedAt
    }
  }
`;
export const listUserConsents = /* GraphQL */ `
  query ListUserConsents(
    $filter: ModelUserConsentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserConsents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        type
        consentedOn
        version
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getConsent = /* GraphQL */ `
  query GetConsent($id: ID!) {
    getConsent(id: $id) {
      id
      type
      version
      createdAt
      updatedAt
    }
  }
`;
export const listConsents = /* GraphQL */ `
  query ListConsents(
    $filter: ModelConsentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConsents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        version
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRole = /* GraphQL */ `
  query GetRole($id: ID!) {
    getRole(id: $id) {
      id
      name
      permissions
      createdAt
      updatedAt
    }
  }
`;
export const listRoles = /* GraphQL */ `
  query ListRoles(
    $filter: ModelRoleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        permissions
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGroup = /* GraphQL */ `
  query GetGroup($id: ID!) {
    getGroup(id: $id) {
      id
      name
      description
      users {
        items {
          id
          groupId
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listGroups = /* GraphQL */ `
  query ListGroups(
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        users {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGroupUser = /* GraphQL */ `
  query GetGroupUser($id: ID!) {
    getGroupUser(id: $id) {
      id
      groupId
      userId
      user {
        id
        email
        name
        phone
        ageBracket
        type
        participantType
        orgId
        alias
        org {
          id
          name
          createdAt
          updatedAt
        }
        verifiedOnboarding
        status
        permissions
        groups {
          nextToken
        }
        tasks {
          nextToken
        }
        theme
        createdAt
        updatedAt
      }
      group {
        id
        name
        description
        users {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listGroupUsers = /* GraphQL */ `
  query ListGroupUsers(
    $filter: ModelGroupUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroupUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        groupId
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        group {
          id
          name
          description
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listAssessmentsByOrg = /* GraphQL */ `
  query ListAssessmentsByOrg(
    $orgId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelAssessmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAssessmentsByOrg(
      orgId: $orgId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orgId
        name
        description
        teamBased
        startDateTime
        endDateTime
        hours
        minutes
        tasks {
          nextToken
        }
        preMessage
        postMessage
        createdBy
        participantEventType
        creator {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listAssessmentsByParticipantEventType = /* GraphQL */ `
  query ListAssessmentsByParticipantEventType(
    $orgId: String!
    $participantEventType: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAssessmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAssessmentsByParticipantEventType(
      orgId: $orgId
      participantEventType: $participantEventType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orgId
        name
        description
        teamBased
        startDateTime
        endDateTime
        hours
        minutes
        tasks {
          nextToken
        }
        preMessage
        postMessage
        createdBy
        participantEventType
        creator {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listTasksByUserId = /* GraphQL */ `
  query ListTasksByUserId(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTasksByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        externalId
        category
        difficulty
        recommendedPoints
        estimatedSolveTime
        solution
        competencies {
          nextToken
        }
        assessments {
          nextToken
        }
        answer
        files {
          nextToken
        }
        hints {
          nextToken
        }
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        status
        history {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listUsersByAssessmentId = /* GraphQL */ `
  query ListUsersByAssessmentId(
    $userAssessmentAssessmentId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserAssessmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsersByAssessmentId(
      userAssessmentAssessmentId: $userAssessmentAssessmentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        assessment {
          id
          orgId
          name
          description
          teamBased
          startDateTime
          endDateTime
          hours
          minutes
          preMessage
          postMessage
          createdBy
          participantEventType
          createdAt
          updatedAt
        }
        userAssessmentAssessmentId
        groupId
        status
        startedOn
        finishedOn
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserById = /* GraphQL */ `
  query GetUserById(
    $id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUserById(
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        name
        phone
        ageBracket
        type
        participantType
        orgId
        alias
        org {
          id
          name
          createdAt
          updatedAt
        }
        verifiedOnboarding
        status
        permissions
        groups {
          nextToken
        }
        tasks {
          nextToken
        }
        theme
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listUsersByOrgId = /* GraphQL */ `
  query ListUsersByOrgId(
    $orgId: ID!
    $email: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsersByOrgId(
      orgId: $orgId
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        name
        phone
        ageBracket
        type
        participantType
        orgId
        alias
        org {
          id
          name
          createdAt
          updatedAt
        }
        verifiedOnboarding
        status
        permissions
        groups {
          nextToken
        }
        tasks {
          nextToken
        }
        theme
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listOnboardingsByUserId = /* GraphQL */ `
  query ListOnboardingsByUserId(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelOnboardingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOnboardingsByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        cyberExperience
        highestEducation
        highestEducationOther
        cyberTraining
        formalTraining
        formalTrainingOther
        selfDirectedTraining {
          hours
        }
        workingIndustry
        workingIndustryOther
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listInvitedUsersByOrg = /* GraphQL */ `
  query ListInvitedUsersByOrg(
    $orgId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelInvitedUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvitedUsersByOrg(
      orgId: $orgId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        type
        orgId
        alias
        assessmentId
        accepted
        participantType
        permissions
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listInvitedUsersByAssessmentId = /* GraphQL */ `
  query ListInvitedUsersByAssessmentId(
    $assessmentId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelInvitedUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvitedUsersByAssessmentId(
      assessmentId: $assessmentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        type
        orgId
        alias
        assessmentId
        accepted
        participantType
        permissions
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listUsersByGroupId = /* GraphQL */ `
  query ListUsersByGroupId(
    $groupId: ID!
    $userId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGroupUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsersByGroupId(
      groupId: $groupId
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupId
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        group {
          id
          name
          description
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listGroupsByUserId = /* GraphQL */ `
  query ListGroupsByUserId(
    $userId: ID!
    $groupId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGroupUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroupsByUserId(
      userId: $userId
      groupId: $groupId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupId
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        group {
          id
          name
          description
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listAssessmentsByGroupId = /* GraphQL */ `
  query ListAssessmentsByGroupId(
    $groupId: ID!
    $assessmentId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGroupAssessmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAssessmentsByGroupId(
      groupId: $groupId
      assessmentId: $assessmentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        groupId
        group {
          id
          name
          description
          createdAt
          updatedAt
        }
        assessmentId
        assessment {
          id
          orgId
          name
          description
          teamBased
          startDateTime
          endDateTime
          hours
          minutes
          preMessage
          postMessage
          createdBy
          participantEventType
          createdAt
          updatedAt
        }
        status
        startedOn
        finishedOn
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listGroupsByAssessmenId = /* GraphQL */ `
  query ListGroupsByAssessmenId(
    $assessmentId: ID!
    $groupId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGroupAssessmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroupsByAssessmenId(
      assessmentId: $assessmentId
      groupId: $groupId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        groupId
        group {
          id
          name
          description
          createdAt
          updatedAt
        }
        assessmentId
        assessment {
          id
          orgId
          name
          description
          teamBased
          startDateTime
          endDateTime
          hours
          minutes
          preMessage
          postMessage
          createdBy
          participantEventType
          createdAt
          updatedAt
        }
        status
        startedOn
        finishedOn
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listProductsByOrgId = /* GraphQL */ `
  query ListProductsByOrgId(
    $orgId: ID!
    $productId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProductOrgFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductsByOrgId(
      orgId: $orgId
      productId: $productId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        orgId
        org {
          id
          name
          createdAt
          updatedAt
        }
        productId
        product {
          id
          name
          description
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listDashboardsByOrgId = /* GraphQL */ `
  query ListDashboardsByOrgId(
    $orgId: ID!
    $productId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDashboardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDashboardsByOrgId(
      orgId: $orgId
      productId: $productId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        orgId
      }
      nextToken
    }
  }
`;

export const listUrls = /* GraphQL */ `
  query ListUrls(
    $filter: ModelUrlFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUrls(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        theme
        url
        key
        orgId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;