import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, CircularProgress, useTheme, Divider } from '@mui/material';
import AlarmIcon from '@mui/icons-material/Alarm';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import WarningIcon from '@mui/icons-material/Warning';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useQuery, gql, useMutation, useLazyQuery } from '@apollo/client';
import {
  FdTypography,
  FdButton,
  FdModal,
  useQueryRecursive,
  FdChip,
  FdSkeleton,
  FdTooltip,
  FdAlert,
  successToastMessage,
  warningToastMessage,
} from '@fifthdomain/fe-shared';
import Countdown from 'react-countdown';
import { minutesToMilliseconds } from 'date-fns/esm';
import {
  getLabTime,
  listLabInstancesByModulePartId,
  getLabPrototype,
  listVPNVMsByLabInstanceId,
  getVmConsole,
} from '../../graphql/queries';
import {
  startLab,
  restartLab,
  extendLabTime,
  deleteLab,
} from '../../graphql/mutations';
import {
  formatMinutesToHours,
  getDateTimeZoneFormatted,
} from '../../shared/utils/dateUtils';
import { getLabStatusColor } from '../../shared/utils/getStatusColor';
import ContentWithLabel from '../ContentWIthLabel';

const LabControl = ({
  assessmentId,
  activeTask,
  user,
  createEventsData,
  labId,
  modulePartId,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [openRestartModal, setRestartModal] = useState(false);
  const [openExtendModal, setxtExtendModal] = useState(false);
  const [labStatus, setLabStatus] = useState(undefined);
  const [activeLabInstance, setActiveLabInstance] = useState(undefined);
  const [fetchConsoleLoading, setFetchConsoleLoading] = useState(false);
  const [pollingInProgress, setPollingInProgress] = useState(false);
  const theme = useTheme();

  const [deleteLabMutation] = useMutation(gql(deleteLab), {
    onCompleted: () => {
      setLabStatus('Not Started');
    },
  });

  const {
    data: labPrototypeTime,
    loading: refetchLabTimeLoading,
    refetch: refetchLabTime,
  } = useQuery(gql(getLabTime), {
    variables: {
      labInstanceId: activeLabInstance?.id,
      limit: 1000,
    },
    onCompleted: () => {
      // Delete lab if expired
      if (
        labStatus === 'Ready' &&
        labPrototypeTime?.getLabTime?.timeRemaining <= 0
      ) {
        deleteLabMutation({
          variables: {
            labInstanceId: activeLabInstance?.id,
          },
        });
      }
    },
    skip: !activeLabInstance,
  });

  const { data: VPNVMsData, refetch: refetchListVPNVMs } = useQuery(
    gql(listVPNVMsByLabInstanceId),
    {
      variables: {
        labInstanceId: activeLabInstance?.id,
        filter: {
          status: {
            eq: 'ACTIVE',
          },
        },
        limit: 1000,
      },
      skip: !activeLabInstance,
    },
  );

  const { data: labPrototype, loading: labPrototypeLoading } = useQuery(
    gql(getLabPrototype),
    {
      variables: {
        id: labId,
      },
      onCompleted: () => {
        setTimeout(() => {
          refetchLabTime();
        }, 1000);
      },
      skip: !labId,
    },
  );

  const [fetchConsole] = useLazyQuery(gql(getVmConsole), {
    skip: labStatus !== 'Ready' || !activeLabInstance,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const { url } = data.getVmConsole;
      window.open(url, '_blank');
      setFetchConsoleLoading(false);
    },
  });

  const stopPollingProcess = () => {
    // eslint-disable-next-line no-use-before-define
    stopPolling();
    setPollingInProgress(false);
    refetchLabTime();
    if (activeLabInstance?.id) {
      setTimeout(async () => {
        await refetchListVPNVMs();
      }, 1000);
    }
  };

  const {
    loading: labInstancesLoading,
    startPolling,
    stopPolling,
    refetch: refetchListLabInstances,
  } = useQueryRecursive(gql(listLabInstancesByModulePartId), {
    variables: {
      modulePartId,
      userId: { eq: user?.username },
      filter: {
        labPrototypeId: {
          eq: labId,
        },
        userAssessmentId: {
          eq: assessmentId,
        },
        and: [
          { status: { ne: 'DELETED' } },
          { status: { ne: 'IN_DELETE_QUEUE' } },
          { status: { ne: 'DELETE_IN_PROGRESS' } },
          { status: { ne: 'DELETE_FAILED' } },
          { status: { ne: 'BUILD_FAILED' } },
        ],
      },
    },
    onCompleted: (_data) => {
      if (labId && modulePartId) {
        const labPrototypeStatus = labPrototype?.getLabPrototype?.status;
        const labInstance = _data.listLabInstancesByModulePartId?.items[0];
        const labName = labPrototype?.getLabPrototype?.name;
        let labEvent = '';

        switch (labPrototypeStatus) {
          case 'IN_BUILD_QUEUE':
          case 'BUILD_IN_PROGRESS': {
            setLabStatus('Building');
            stopPolling();
            break;
          }
          case 'IN_DELETE_QUEUE':
          case 'DELETE_IN_PROGRESS':
          case 'DELETED': {
            setLabStatus('Deleted');
            stopPolling();
            break;
          }
          case 'READY': {
            if (labInstance) {
              setActiveLabInstance(labInstance);
              // When lab started in assessor, participants can't stop or pause it.
              if (labInstance.status === 'READY') {
                labEvent = 'LAB_READY';
                setLabStatus('Ready');
                stopPollingProcess();
                refetchLabTime();
              } else if (!pollingInProgress) {
                // eslint-disable-next-line no-use-before-define
                startPollingProcess();
              }
            } else {
              setLabStatus('Not Started');
              labEvent = 'LAB_NOT_STARTED';
              stopPollingProcess();
            }
            break;
          }
          default: {
            setLabStatus('Waiting...');
            refetchListLabInstances();
            break;
          }
        }

        // Not collect events when starting lab atm
        // But starting lab events might be used for calculating avg time for starting lab
        if (labEvent !== 'LAB_STARTING') {
          createEventsData(
            user?.username,
            labName,
            labEvent,
            {
              dimension1Id: assessmentId,
              dimension1Name: 'ASSESSMENT',
            },
            {
              dimension2Id: activeTask?.id,
              dimension2Name: 'TASK',
            },
            {
              dimension3Id: activeTask?.modulePart?.labId,
              dimension3Name: 'LAB',
            },
          );
        }
      }
    },
    skip: !labId || !user?.username,
  });

  useEffect(() => {
    setLabStatus(null);
    refetchListLabInstances();
  }, [labId, refetchListLabInstances]);

  const startPollingProcess = () => {
    startPolling(5000);
    setPollingInProgress(true);
  };

  const [startLabMutation] = useMutation(gql(startLab), {
    onError: () => {
      // retry start on error
      startPollingProcess();
    },
    onCompleted: () => {
      startPollingProcess();
    },
  });

  const [restartLabMutation] = useMutation(gql(restartLab), {
    onCompleted: () => {
      refetchListLabInstances();
      setLabStatus('Not Started');
    },
  });

  const [extendLabTimeMutation] = useMutation(gql(extendLabTime), {
    onCompleted: () => {
      setTimeout(() => {
        refetchLabTime();
      }, 5000);
    },
  });

  const timeRemaining = ['Ready', 'Shutdown'].includes(labStatus)
    ? labPrototypeTime?.getLabTime?.timeRemaining
    : activeTask?.modulePart?.duration;
  const milliSecondsToFinish =
    ['Ready', 'Shutdown'].includes(labStatus) && timeRemaining > 0
      ? minutesToMilliseconds(timeRemaining)
      : 0;

  const hasLab = labId && modulePartId;

  if (
    hasLab &&
    (!labStatus ||
      labPrototypeLoading ||
      (labInstancesLoading && !['Starting', 'Stopping'].includes(labStatus)))
  ) {
    return (
      <Box mt={1} mb={1} width="100%">
        <CircularProgress size="3rem" />
      </Box>
    );
  }

  const iconColor =
    theme?.palette?.type === 'dark'
      ? 'rgba(255, 255, 255, 0.7)'
      : 'rgba(0, 0, 0, 0.54)';

  const showStartLab =
    labStatus === 'Not Started' ||
    (labStatus === 'Shutdown' && timeRemaining && timeRemaining > 1);
  const vmInstances = activeLabInstance?.vms?.items || [];

  const vmRows = vmInstances
    ?.map((vm) => {
      const vpn = VPNVMsData?.listVPNVMsByLabInstanceId?.items?.find(
        (i) => i.labInstanceVmId === vm.id,
      );
      return { ...vm, show: true, vpnDetails: vpn };
    })
    .filter((vmr) => vmr.show);

  return (
    <Box>
      {hasLab && (
        <>
          <Divider />
          <Box display="flex" alignItems="center" my={2}>
            <FdTypography style={{ marginRight: '8px' }} variant="subtitle1">
              Lab Details
            </FdTypography>
            <FdTooltip
              alignContent="center"
              title="Each lab has a default duration of 2 hours. 
              Once you have started your lab, the 'Extend Lab' button allows you to reset the timer in your current lab instance back to 2 hours, 
              retaining all progress made. The 'Reset Lab' button deploys a new lab instance, deleting all progress, and resetting your remaining lab time back to 2 hours."
            >
              <InfoOutlinedIcon />
            </FdTooltip>
          </Box>
        </>
      )}
      {hasLab && activeTask?.modulePartId && labStatus && (
        <Box mb={2}>
          <ContentWithLabel
            label="Current Lab Status"
            variant="subtitle2"
            content={
              <FdChip
                color={getLabStatusColor(labStatus)}
                size="medium"
                label={labStatus}
                className="ml-2"
              />
            }
          />
        </Box>
      )}
      {hasLab && labStatus === 'Deleted/Error' && (
        <FdAlert
          alertTitle=""
          variant="info"
          customIcon={<InfoOutlinedIcon />}
          message="If your current lab status is showing as “Deleted/Error”, try closing this challenge and reopening it, and/or refreshing your page.
          In the event that your VM is inaccessible via the web console, you can use the 'Restart' button to initiate the lab's restart process.
          "
        />
      )}
      {activeTask?.modulePart &&
        [
          'Not Started',
          'Starting',
          'Building',
          'Ready',
          'Stopping',
          'Shutdown',
        ].includes(labStatus) && (
          <Box mt={3} mb={3}>
            {!['Starting', 'Stopping'].includes(labStatus) && (
              <Box mt={1} display="flex">
                <FdSkeleton loading={refetchLabTimeLoading} height="28px">
                  <Box className="flex items-center mt-1">
                    <AlarmIcon className="mr-1" style={{ fill: iconColor }} />
                    <FdTypography color="secondary" variant="body2">
                      Lab Time Remaining
                    </FdTypography>
                    <FdTypography color="secondary" variant="body2">
                      <Box ml={1}>
                        {labStatus === 'Ready' && milliSecondsToFinish ? (
                          <Countdown
                            date={
                              new Date().getTime() +
                              // to trigger countdown, minimum 1 second
                              (milliSecondsToFinish > 1000
                                ? milliSecondsToFinish
                                : 1000)
                            }
                          />
                        ) : (
                          `${
                            timeRemaining
                              ? formatMinutesToHours(timeRemaining)
                              : ''
                          }`
                        )}
                      </Box>
                    </FdTypography>
                  </Box>
                </FdSkeleton>
              </Box>
            )}
            {activeTask?.modulePart?.expiry && (
              <Box className="flex items-center mt-1">
                <AvTimerIcon className="mr-1" style={{ fill: iconColor }} />
                <FdTypography color="secondary" variant="body2">
                  Lab Expiry:
                </FdTypography>
                <Box ml={1}>
                  <FdTypography color="secondary" variant="body2">
                    {`${getDateTimeZoneFormatted(
                      activeTask?.modulePart?.expiry,
                      true,
                    )}`}
                  </FdTypography>
                </Box>
              </Box>
            )}
          </Box>
        )}
      {labStatus !== 'Expired' && (
        <>
          <Box>
            {showStartLab && (
              <Box>
                <FdButton
                  size="large"
                  data-cy="lab-button"
                  variant="secondary"
                  onClick={() => setOpenModal(true)}
                  style={{ width: '100%' }}
                >
                  START LAB
                </FdButton>
              </Box>
            )}
            {(['Starting', 'Stopping', 'Deleting', 'Restarting'].includes(
              labStatus,
            ) ||
              (pollingInProgress && labStatus === 'Shutdown')) && (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="100%"
              >
                <CircularProgress size="2rem" />
              </Box>
            )}
            {labStatus === 'Ready' && (
              <Box>
                <Box className="flex gap-x-2">
                  <Box display="flex" width="100%">
                    <Box mr={1}>
                      <FdButton
                        size="large"
                        variant="secondary"
                        onClick={() => setxtExtendModal(true)}
                      >
                        EXTEND LAB
                      </FdButton>
                    </Box>
                    <FdButton
                      size="large"
                      variant="secondary"
                      onClick={() => setRestartModal(true)}
                    >
                      RESET LAB
                    </FdButton>
                    <Box my={3}>
                      <Divider />
                    </Box>
                  </Box>
                </Box>
                <Box mt={2}>
                  <FdAlert
                    alertTitle=""
                    variant="info"
                    customIcon={<InfoOutlinedIcon />}
                    message="Your lab instance will be deleted once Lab Time Remaining reaches 0. You'll be able to start a new lab instance, but all progress will be lost. 
                                Regularly check Lab Time Remaining and extend your lab instance as required before the timer reaches 0."
                  />
                </Box>
              </Box>
            )}
            {labStatus === 'Ready' && vmInstances.length > 0 && (
              <Box mt={3} width="100%">
                <Box my={2}>
                  <Divider />
                </Box>
                <Box my={3}>
                  <FdTypography variant="subtitle1">
                    VM Access Details
                  </FdTypography>
                </Box>
                {vmRows.map((vm, idx) => {
                  return (
                    <Box mb={3}>
                      <Box className="flex items-center my-1">
                        <FdTypography variant="subtitle2" className="pr-2">
                          {`VM ${idx + 1} Name:`}
                        </FdTypography>
                        <FdTypography variant="body2" color="secondary">
                          {vm?.name}
                        </FdTypography>
                      </Box>
                      {vm?.vpnDetails && (
                        <Box className="flex items-center mt-2">
                          <FdTypography color="secondary" variant="subtitle2">
                            {`VPN Access IP: ${
                              vm?.vpnDetails?.externalIp ?? '0.0.0.0'
                            }`}
                          </FdTypography>
                          <FdTooltip
                            alignContent="center"
                            title="Use this IP to access this VM when you are connected to the VPN provided."
                          >
                            <InfoOutlinedIcon
                              style={{
                                fill: iconColor,
                                height: '20px',
                                width: '20px',
                                marginLeft: '0.5rem',
                              }}
                              ml={2}
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  vm?.vpnDetails?.externalIp ?? '0.0.0.0',
                                );
                              }}
                            />
                          </FdTooltip>
                        </Box>
                      )}
                      {vm?.vdi && (
                        <Box mt={1}>
                          <FdButton
                            size="medium"
                            data-cy="lab-button"
                            style={{ width: '100%' }}
                            onClick={() => {
                              setFetchConsoleLoading(true);
                              fetchConsole({
                                variables: {
                                  vmId: vm?.id,
                                  type: 'LAB',
                                },
                              });
                            }}
                          >
                            {fetchConsoleLoading ? (
                              <CircularProgress
                                style={{ color: 'white' }}
                                size="1.5rem"
                              />
                            ) : (
                              `CONNECT TO "${vm?.name ?? 'VM'}" VM VDI`
                            )}
                          </FdButton>
                        </Box>
                      )}
                    </Box>
                  );
                })}
              </Box>
            )}
          </Box>
          <Box my={2}>
            <Divider />
          </Box>
        </>
      )}
      <FdModal
        title={
          <Box display="flex" alignItems="center">
            <WarningIcon
              style={{
                fontSize: 38,
                color: '#C62828',
                paddingRight: '0.5rem',
              }}
            />
            <span>Start Lab?</span>
          </Box>
        }
        size="xs"
        description={
          <Box>
            <FdTypography variant="subtitle1">
              Are you sure you want to start the lab?
            </FdTypography>
            <Box mt={1}>
              A two-hour countdown timer will begin once you start this lab. You
              may extend your remaining lab time as many times as you require.
              You may also reset your lab to deploy a new lab instance as many
              times as you require.
            </Box>
          </Box>
        }
        confirm="Start Lab"
        dismiss="Cancel"
        open={openModal}
        onConfirm={() => {
          if (
            activeTask &&
            activeTask?.modulePartId &&
            activeTask?.modulePart
          ) {
            setLabStatus('Starting');
            startLabMutation({
              variables: {
                labPrototypeId: labId,
                modulePartId,
                userAssessmentId: assessmentId,
              },
            });
            setOpenModal(false);
          }
        }}
        onDismiss={() => setOpenModal(false)}
      />
      <FdModal
        title={
          <Box display="flex" alignItems="center">
            <WarningIcon
              style={{
                fontSize: 38,
                color: '#C62828',
                paddingRight: '0.5rem',
              }}
            />
            <span>Extend this lab instance?</span>
          </Box>
        }
        size="xs"
        description={
          <Box>
            <FdTypography variant="body1">
              Extending this lab instance will reset your time remaining for
              this lab to 2 hours.
            </FdTypography>
            <FdTypography variant="body1" style={{ marginTop: '8px' }}>
              You will be able to continue work on all VMs in this lab, with all
              progress made being unaffected.
            </FdTypography>
            <FdTypography variant="body1" style={{ marginTop: '8px' }}>
              <b>Note:</b>
              there is no limit to the number of times you can extend a lab
              instance. However, once Lab Time Remaining reaches 0, your lab
              instance will be deleted (all progress will be lost) and you will
              need to start the lab again if you wish to continue working on the
              lab.
            </FdTypography>
          </Box>
        }
        confirm="Confirm"
        dismiss="Cancel"
        open={openExtendModal}
        onConfirm={() => {
          extendLabTimeMutation({
            variables: {
              labInstanceId: activeLabInstance?.id,
            },
          });

          refetchLabTime();
          setxtExtendModal(false);
          successToastMessage('Lab instance extended! Timer reset to 2 hours.');
        }}
        onDismiss={() => {
          warningToastMessage('Lab instance not extended');
          setxtExtendModal(false);
        }}
      />
      <FdModal
        title={
          <Box display="flex" alignItems="center">
            <WarningIcon
              style={{
                fontSize: 38,
                color: '#C62828',
                paddingRight: '0.5rem',
              }}
            />
            <span>Reset this lab?</span>
          </Box>
        }
        size="xs"
        description={
          <Box>
            <FdTypography variant="body1">
              Resetting this lab will deploy a new lab instance for your use. If
              you proceed, your current instance will be deleted, and you will
              lose all your work within the lab. Please note that after
              resetting this lab, you will need to start the lab again to begin
              working on it.
            </FdTypography>
          </Box>
        }
        confirm="Confirm"
        dismiss="Cancel"
        open={openRestartModal}
        onConfirm={() => {
          // restart lab
          restartLabMutation({
            variables: {
              labInstanceId: activeLabInstance?.id,
              hardReset: true,
            },
          });

          setRestartModal(false);
          successToastMessage('Lab reset successfully!');
        }}
        onDismiss={() => {
          warningToastMessage('Lab not reset');
          setRestartModal(false);
        }}
      />
    </Box>
  );
};

LabControl.propTypes = {
  assessmentId: PropTypes.string.isRequired,
  activeTask: PropTypes.shape(
    PropTypes.shape({
      modulePart: PropTypes.shape({
        labId: PropTypes.string,
        expiry: PropTypes.string,
      }),
    }),
  ).isRequired,
  user: PropTypes.shape(PropTypes.shape({})).isRequired,
  createEventsData: PropTypes.func.isRequired,
  labId: PropTypes.string.isRequired,
  modulePartId: PropTypes.string.isRequired,
};

export default LabControl;
