import React from 'react';
import PropTypes from 'prop-types';
import { navigateToUrl } from 'single-spa';
import {
  Breadcrumb,
  BreadcrumbEllipsis,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from '@/components/ui/breadcrumb';
import { SidebarTrigger } from '@/components/ui/sidebar';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Separator } from '@/components/ui/separator';
import { Box } from '@mui/material';
import { HouseIcon } from 'lucide-react';
import { FdTooltip, globalStore, useSnapshot } from '@fifthdomain/fe-shared';
import { useTheme } from '@mui/styles';
import EntryIcon from './EntryIcon';

const BreadCrumbLabel = ({ type, name }) => (
  <Box className="flex items-center justify-center gap-1">
    <EntryIcon type={type} className="size-3" />
    {name}
  </Box>
);

BreadCrumbLabel.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

function FdBreadcrumbHeader({ entries, page }) {
  const theme = useTheme();
  const { userType } = useSnapshot(globalStore);
  const homePageLink =
    userType === 'PARTICIPANT' ? '/landing/landing-homepage' : '/landing';

  return (
    <Box
      className="flex items-center gap-2 py-1"
      sx={() => ({ color: 'primary.main' })}
    >
      <FdTooltip title="Toggle sidebar">
        <SidebarTrigger />
      </FdTooltip>
      <Separator
        orientation="vertical"
        className="h-4"
        style={{ color: theme.palette.table.border }}
      />
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink onClick={() => navigateToUrl(homePageLink)}>
              <FdTooltip title="Go to Home Page">
                <HouseIcon size={16} />
              </FdTooltip>
            </BreadcrumbLink>
          </BreadcrumbItem>
          {entries?.map((mi) => (
            <>
              <BreadcrumbSeparator />
              {mi?.children?.length > 0 ? (
                <BreadcrumbItem>
                  <DropdownMenu>
                    <DropdownMenuTrigger className="flex items-center gap-1">
                      <BreadcrumbEllipsis className="h-4 w-4" />
                      <span className="sr-only">Toggle menu</span>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="start">
                      {mi?.children?.map((c) => (
                        <DropdownMenuItem
                          onClick={() => navigateToUrl(c?.path)}
                        >
                          <BreadCrumbLabel type={c?.type} name={c?.name} />
                        </DropdownMenuItem>
                      ))}
                    </DropdownMenuContent>
                  </DropdownMenu>
                </BreadcrumbItem>
              ) : (
                <BreadcrumbItem>
                  <BreadcrumbLink onClick={() => navigateToUrl(mi?.path)}>
                    <BreadCrumbLabel type={mi?.type} name={mi?.name} />
                  </BreadcrumbLink>
                </BreadcrumbItem>
              )}
            </>
          ))}
          {page && (
            <>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbPage>
                  <BreadCrumbLabel type={page?.type} name={page?.name} />
                </BreadcrumbPage>
              </BreadcrumbItem>
            </>
          )}
        </BreadcrumbList>
      </Breadcrumb>
    </Box>
  );
}

FdBreadcrumbHeader.propTypes = {
  entries: PropTypes.arrayOf(PropTypes.shape({})),
  page: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
  }),
};

FdBreadcrumbHeader.defaultProps = {
  entries: [],
  page: undefined,
};

export default FdBreadcrumbHeader;
