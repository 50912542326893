import { useEffect } from 'react';
import { useSnapshot } from 'valtio';
import { useLazyQuery, gql } from '@apollo/client';
import { listProductsByOrgId } from '../../queries/customQueries';
import globalStore from '../../store';

const useInitializeProductsStore = (userId) => {
  const snap = useSnapshot(globalStore);

  // get available products
  const [fetchProducts] = useLazyQuery(gql(listProductsByOrgId), {
    fetchPolicy: 'network-only',
    onCompleted: (_data) => {
      // set orgProducts to globalStore
      globalStore.orgProducts =
        _data?.listProductsByOrgId?.items?.map(
          (orgProduct) => orgProduct?.product?.name,
        ) || [];
      globalStore.loading = false;
    },
    onError: (err) => {
      globalStore.loading = false;
      globalStore.error = err;
    },
  });

  useEffect(() => {
    if (userId && !snap.orgProducts) {
      fetchProducts({
        variables: {
          orgId: snap.orgId,
        },
      });
    }
  }, [userId, fetchProducts, snap.orgId, snap.orgProducts]);
};

export default useInitializeProductsStore;
