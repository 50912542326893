import React, { useState } from 'react';
import { useHistory, useParams, Link as RouterLink } from 'react-router-dom';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, gql, useQuery } from '@apollo/client';
import NavigationPrompt from 'react-router-navigation-prompt';
import { Box } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import {
  BasePage,
  FdLoadingSpinner,
  FdModal,
  FdTab,
  FdTypography,
  successToastMessage,
  warningToastMessage,
} from '@fifthdomain/fe-shared';
import { initialValues, validationSchema } from '../validation-schemas/Groups';
import { updateGroup, manageUserAndGroup } from '../graphql/mutations';
import GroupDetails from '../components/Group/GroupDetails';
import GroupUsers from '../components/Group/GroupUsers';
import { scrollToTop } from '../shared/utils/scroll';
import { getGroup } from '../graphql/queries';
import { invalidateGroupQuery } from '../queries/invalidateQueries';

const EditGroup = () => {
  const history = useHistory();
  const { groupId } = useParams();
  const [editGroup, setEditGroup] = useState(false);
  const [manageUserAndGroupMutation, { loading: manageUserAndGroupLoading }] =
    useMutation(gql(manageUserAndGroup), {
      onCompleted: () => {
        successToastMessage('Success! User(s) updated');
        // re-query list groups and invited users
        invalidateGroupQuery();
        // eslint-disable-next-line no-use-before-define
        groupRefetch();
      },
    });

  const [updateGroupMutation, { loading: updateGroupLoading }] = useMutation(
    gql(updateGroup),
    {
      refetchQueries: ['GetGroup'],
      awaitRefetchQueries: true,
      onCompleted: () => {
        successToastMessage('Success! Group updated');
        // re-query list groups and invited users
        invalidateGroupQuery();
      },
    },
  );

  const hookFormMethods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });
  const {
    control,
    formState: { isDirty },
    reset,
    getValues,
    trigger,
  } = hookFormMethods;

  const { loading: groupDataLoading, refetch: groupRefetch } = useQuery(
    gql(getGroup),
    {
      variables: {
        id: groupId,
      },
      fetchPolicy: 'cache-and-network',
      onCompleted: (data) => {
        const _groupData = data?.getGroup;
        const { name, description, users } = _groupData;
        reset({
          groupName: name,
          groupDescription: description,
          users: users?.items?.map((u) => u.userId),
        });
        scrollToTop();
      },
    },
  );

  const onSaveDetails = async () => {
    if (await trigger(['groupName', 'groupDescription'])) {
      const { groupName, groupDescription } = getValues();
      updateGroupMutation({
        variables: {
          input: {
            id: groupId,
            name: groupName,
            description: groupDescription,
          },
        },
      });
    }
  };

  const onAddUsers = async (selectedUserIds) => {
    await manageUserAndGroupMutation({
      variables: {
        groupId,
        operation: 'ADDUSER',
        userIds: selectedUserIds,
      },
    });
  };

  const onRemoveUser = async (row) => {
    const { id } = row;
    await manageUserAndGroupMutation({
      variables: {
        groupId,
        operation: 'REMOVEUSER',
        userIds: [id],
      },
    });
  };
  // TODO: Will be added later when its ready for dev
  // const onAddCourses = () => {
  //   successToastMessage('Success! Courses(s) added');
  // };
  // TODO: Will be added later when its ready for dev
  // const onRemoveCourse = () => {
  //   successToastMessage('Success! Course removed');
  // };

  if (groupDataLoading || updateGroupLoading) {
    return <FdLoadingSpinner />;
  }

  return (
    <BasePage
      heading="Edit Group"
      data-cy="edit-group-base-page"
      breadCrumbs={[{ url: '/user-management', name: 'Home' }]}
      currentPageBreadcrumbLabel="User Management / Groups / Edit Group"
      linkComponent={RouterLink}
    >
      <FormProvider {...hookFormMethods}>
        <form style={{ marginTop: '-1rem' }}>
          <FdTab
            label={[
              {
                label: 'Basic Info',
                tabRoute: `/user-management/edit-group/${groupId}`,
                index: 0,
                data: (
                  <GroupDetails
                    control={control}
                    Controller={Controller}
                    onClickSave={() => setEditGroup(true)}
                    isEditMode
                  />
                ),
              },
              {
                label: 'Users',
                tabRoute: `/user-management/edit-group/${groupId}`,
                index: 1,
                data: (
                  <GroupUsers
                    manageUserAndGroupLoading={manageUserAndGroupLoading}
                    onAddUsers={onAddUsers}
                    onRemoveUser={onRemoveUser}
                    groupId={groupId}
                  />
                ),
              },
              // TODO: Will be added later when its ready for dev
              // {
              //   label: 'Courses',
              //   tabRoute: `/user-management/edit-group/${groupId}`,
              //   index: 2,
              //   data: () => (
              //     <GroupCourses
              //       onAddCourses={onAddCourses}
              //       onRemoveCourse={onRemoveCourse}
              //     />
              //   ),
              // },
            ]}
          />
        </form>
      </FormProvider>
      <FdModal
        size="md"
        title={
          <Box display="flex" alignItems="center">
            <WarningIcon
              style={{
                fontSize: 38,
                color: '#C62828',
                paddingRight: '0.5rem',
              }}
            />
            <span>Update the Group</span>
          </Box>
        }
        description={
          <Box>
            <FdTypography variant="subtitle1">
              Are you sure you want to save your changes to the group?
            </FdTypography>
            <Box mt={2}>
              <FdTypography variant="body1" color="secondary">
                This will update the group across the platform.
              </FdTypography>
            </Box>
          </Box>
        }
        confirm="Ok"
        dismiss="Cancel"
        open={editGroup}
        onConfirm={() => {
          setEditGroup(false);
          onSaveDetails();
        }}
        onDismiss={() => {
          setEditGroup(false);
          warningToastMessage('Changes to group not saved');
        }}
        setOpen={setEditGroup}
      />
      <NavigationPrompt
        when={isDirty}
        afterCancel={() => {
          if (
            window.location.pathname !==
            `/user-management/groups/edit/${groupId}`
          ) {
            history.goBack();
          }
        }}
      >
        {({ onConfirm, onCancel }) => (
          <FdModal
            title="Are you sure you want to leave?"
            description="You have unsaved changes. Click the Stay button to go back to the form and save your changes."
            confirm="Stay"
            dismiss="Leave"
            open
            onConfirm={onCancel}
            onDismiss={onConfirm}
          />
        )}
      </NavigationPrompt>
    </BasePage>
  );
};

export default EditGroup;
