import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import { Box } from '@mui/material';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import {
  FdChatStream,
  globalStore,
  useSnapshot,
  FdSkeleton,
  successToastMessage,
  warningToastMessage,
} from '@fifthdomain/fe-shared';
import {
  createAnnouncement,
  createAnnouncementReaction,
  deleteAnnouncement,
  updateAnnouncement,
  updateAnnouncementReaction,
} from '../../graphql/mutations';
import { uploadFileToS3 } from '../../shared/utils/fileUploadAWS';
import { loadMessagesWithImages } from './utils';
import { invalidateGetAssessment } from '../../queries/invalidateQueries';
import { listAnnouncementReactionsByUserId } from '../../graphql/queries';

const useReaction = () => {
  const [createReactionMutation] = useMutation(gql(createAnnouncementReaction));
  const [updateReactionMutation] = useMutation(gql(updateAnnouncementReaction));
  const [fetchReaction] = useLazyQuery(gql(listAnnouncementReactionsByUserId));

  const fetchAndSaveReaction = ({ userId, messageId, likeOrDislike }) =>
    fetchReaction({
      variables: {
        userId,
        announcementId: { eq: messageId },
      },
      fetchPolicy: 'network-only',
      onCompleted: (_data) => {
        const likesData = _data?.listAnnouncementReactionsByUserId?.items || [];
        const likesCount = likesData.length || 0;

        if (likesCount === 0) {
          createReactionMutation({
            variables: {
              input: {
                announcementId: messageId,
                reactionType: likeOrDislike,
                userId,
              },
            },
          });
          return;
        }
        const reactionId = likesData?.[0]?.id;
        // update with like or dislike
        updateReactionMutation({
          variables: {
            input: {
              id: reactionId,
              reactionType: likeOrDislike,
            },
          },
        });
      },
    });
  return fetchAndSaveReaction;
};

const Announcements = ({
  announcements,
  assessmentId,
  viewOnly,
  reactionsViewOnly,
  loading,
  adminView,
}) => {
  const [imageKey, setImageKey] = useState(undefined);
  const [imageFile, setImageFile] = useState(undefined);
  const [announcementsWithImages, setAnnouncementsWithImages] = useState([]);
  const [keyIds, setKeyIds] = useState([]);
  const globalSnap = useSnapshot(globalStore);

  useEffect(() => {
    loadMessagesWithImages(announcements, globalSnap?.userId)
      .then((announcementsDisplay) => {
        setAnnouncementsWithImages(announcementsDisplay);
      })
      .catch(() => {
        setAnnouncementsWithImages([]);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [announcements?.length]);

  const uploadToS3 = (_imageKey, _imageFile) => {
    const pImageKey = imageKey || _imageKey;
    const pImageFile = imageFile || _imageFile;
    if (pImageKey) {
      uploadFileToS3({
        key: pImageKey,
        file: pImageFile,
        progressCallback: (progress) => {
          // reset imageFile on completion
          if (progress?.loaded === progress?.total) {
            setImageFile(undefined);
          }
        },
      });
    }
  };

  const [createAnnouncementMutation] = useMutation(gql(createAnnouncement));

  const [updateAnnouncementMutation] = useMutation(gql(updateAnnouncement), {
    onCompleted: () => {
      // if image present then upload to S3
      uploadToS3();
      // invalidate queries to force refetch
      invalidateGetAssessment();
    },
  });

  const [deleteAnnouncementMutation] = useMutation(gql(deleteAnnouncement), {
    onCompleted: (_data) => {
      successToastMessage('Success! Message deleted.');
      // invalidate queries to force refetch
      invalidateGetAssessment();
    },
  });

  const getImageKey = (image) => {
    const imageKeyGenerated = image
      ? `chat-images/${shortid.generate()}`
      : undefined;
    if (imageKeyGenerated) {
      setImageKey(imageKeyGenerated);
      setImageFile(image);
    }
    return imageKeyGenerated;
  };

  const getMessagePayload = (message, imageKeyGenerated, image) => {
    return {
      messages: [
        {
          content: message,
          type: 'TEXT',
        },
        ...(image
          ? [
              {
                content: imageKeyGenerated,
                type: 'IMAGE',
              },
            ]
          : []),
      ],
    };
  };
  const getMessageId = (_keyId) =>
    keyIds?.find((i) => i?.keyId === _keyId)?.messageId;

  return (
    <Box>
      <FdSkeleton loading={loading} height={window.innerHeight - 260}>
        <FdChatStream
          allMessages={announcementsWithImages}
          headerHeight={260}
          onAddMessage={(message, image, keyId) => {
            const imageKeyGenerated = getImageKey(image);
            // create message
            createAnnouncementMutation({
              variables: {
                input: {
                  assessmentId,
                  ...getMessagePayload(message, imageKeyGenerated, image),
                  userId: globalSnap?.userId,
                  likesCount: 0,
                },
              },
              onCompleted: (_data) => {
                const id = _data?.createAnnouncement?.id;
                setKeyIds([...keyIds, { keyId, messageId: id }]);
                // if image present then upload to S3
                uploadToS3(imageKeyGenerated, image);
                // invalidate queries to force refetch
                invalidateGetAssessment();
              },
            });
          }}
          onUpdateMessage={({ messageId, message, image, keyId }) => {
            const imageKeyGenerated = getImageKey(image);
            // update message
            updateAnnouncementMutation({
              variables: {
                input: {
                  id: messageId || getMessageId(keyId),
                  ...getMessagePayload(message, imageKeyGenerated, image),
                  userId: globalSnap?.userId,
                },
              },
            });
          }}
          onDeleteMessage={(messageId, keyId) =>
            deleteAnnouncementMutation({
              variables: {
                input: {
                  id: messageId || getMessageId(keyId),
                },
              },
            })
          }
          onCancelDeleteMessage={() =>
            warningToastMessage('Message deletion cancelled')
          }
          useReaction={useReaction}
          reactionsViewOnly={reactionsViewOnly}
          viewOnly={viewOnly}
          adminView={adminView}
          noRoles
          warningToastMessage={warningToastMessage}
        />
      </FdSkeleton>
    </Box>
  );
};

Announcements.defaultProps = {
  viewOnly: false,
  reactionsViewOnly: false,
  loading: false,
  adminView: false,
};

Announcements.propTypes = {
  announcements: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  assessmentId: PropTypes.string.isRequired,
  viewOnly: PropTypes.bool,
  reactionsViewOnly: PropTypes.bool,
  loading: PropTypes.bool,
  adminView: PropTypes.bool,
};

export default Announcements;
