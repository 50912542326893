import React from 'react';
import { Box } from '@mui/material';
import { useParams, useLocation } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  FdTypography,
  FdTab,
  FdProgress,
  FdButton,
  FdDelayed,
  useSnapshot,
  globalStore,
  useRecentLinks,
} from '@fifthdomain/fe-shared';
import { FdBreadcrumbHeader } from '@fifthdomain/sidebar';
import {
  ViewDetails,
  ViewNetwork,
  ViewVirtualMachines,
} from '../components/LabsView';
import { getLabPrototype } from '../graphql/queries';
import {
  labInitialValues,
  labValidationSchema,
} from '../validation-schemas/Labs';

const ViewLab = () => {
  const { labId } = useParams();
  const { search, pathname } = useLocation();
  const globalSnap = useSnapshot(globalStore);
  const { addRecentLink } = useRecentLinks({ userId: globalSnap.userId });

  const { getValues, reset } = useForm({
    defaultValues: labInitialValues,
    resolver: yupResolver(labValidationSchema),
    mode: 'all',
  });

  // Get Lab Prototype
  const { loading: getLabPrototypeLoading } = useQuery(gql(getLabPrototype), {
    variables: {
      id: labId,
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      reset({
        labName: data.getLabPrototype?.name,
        labDescription: data.getLabPrototype?.description,
        labNetworks: data.getLabPrototype?.networks?.items?.map((item) => item),
        labVms: data.getLabPrototype?.vms?.items?.map((item) => item),
      });

      // add recent link
      addRecentLink({
        id: labId,
        name: data.getLabPrototype?.name,
        type: 'LAB',
        url: pathname + search,
        role: 'MANAGE',
      });
    },
  });

  const viewDetails = () => (
    <ViewDetails
      values={getValues()}
      heading={
        <Box display="flex" justifyContent="space-between">
          <FdTypography variant="h3">Details</FdTypography>
        </Box>
      }
    />
  );

  const viewNetworks = () => (
    <ViewNetwork
      heading={
        <Box display="flex" justifyContent="flex-start">
          <FdTypography variant="h3">Networks</FdTypography>
          <Box p={0.8}>
            <FdTypography variant="body1" color="secondary">
              (optional)
            </FdTypography>
          </Box>
        </Box>
      }
      subHeading="Specify the network(s) that you’d like to add to this lab."
      values={getValues()}
    />
  );

  const viewVirtualMachines = () => (
    <ViewVirtualMachines
      heading={
        <Box display="flex" justifyContent="space-between">
          <FdTypography variant="h3">Virtual Machines</FdTypography>
        </Box>
      }
      subHeading="Add or remove virtual machines to your lab"
      values={getValues()}
    />
  );

  if (getLabPrototypeLoading) {
    return <FdProgress />;
  }
  const { labName } = getValues();

  return (
    <Box>
      <FdBreadcrumbHeader
        entries={[
          {
            name: 'Manage Labs',
            path: '/labs',
            type: 'LAB',
          },
        ]}
        page={{ name: labName, type: 'LAB' }}
      />
      <Box>
        <Box display="flex" justifyContent="space-between">
          <FdTypography variant="h2">{`View ${labName}`}</FdTypography>
          <FdButton
            startIcon={<OpenInNewIcon />}
            onClick={() => window.open(`/labs/test-lab/${labId}`)}
          >
            Enter Edit Lab Mode
          </FdButton>
        </Box>
        <FdDelayed>
          <FdTab
            label={[
              {
                label: 'Details',
                path: '/labs/',
                index: 0,
                data: viewDetails(),
              },
              {
                label: 'Networks',
                path: '/labs/',
                index: 1,
                data: viewNetworks(),
              },
              {
                label: 'Virtual Machines',
                path: '/labs/',
                index: 2,
                data: viewVirtualMachines(),
              },
            ]}
          />
        </FdDelayed>
      </Box>
    </Box>
  );
};
export default ViewLab;
