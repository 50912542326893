import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Box } from '@mui/material';
import { FdTypography } from '@fifthdomain/fe-shared';
import DonutStacked from '../../Charts/DonutStacked';
import {
  PROFICIENCY,
  PROFICIENCY_LEVELS,
  SPECIALTY_COLOR,
} from '../../../constants';
import { getDifficultyLevel } from '../../../shared/utils/difficultyMapping';

const HoverInfo = ({ value }) => {
  return (
    <Box width={100} className="flex justify-center items-center">
      <FdTypography variant="captiontext1" color="secondary">
        {` Hover over the ${value} segment to see more details.`}
      </FdTypography>
    </Box>
  );
};
HoverInfo.propTypes = {
  value: PropTypes.string.isRequired,
};

const DonutGraphLegend = ({ data }) => {
  return (
    <Box className="flex items-start my-2">
      {data?.map((d) => {
        return (
          <Box className="flex items-center justify-center gap-x-1 mr-4">
            <Box
              className="flex items-center size-2"
              style={{
                backgroundColor: d.color,
              }}
            />
            <FdTypography variant="captiontext2" className="leading-[1.2rem]">
              {d.label}
            </FdTypography>
          </Box>
        );
      })}
    </Box>
  );
};

DonutGraphLegend.propTypes = {
  data: PropTypes.isRequired,
};

const LegendTooltip = ({ data, totalTasks }) => {
  const { label, value } = data || {};

  return (
    <Box className="flex flex-col items-start justify-center">
      <Box>
        <FdTypography variant="captiontext1">{label}</FdTypography>
      </Box>
      <Box className="flex items-end gap-x-1">
        <FdTypography
          variant="subtitle2"
          className="leading-[1.2rem] align-baseline"
        >
          {value}
        </FdTypography>
        <FdTypography
          variant="captiontext2"
          className="leading-[1rem] align-baseline"
        >
          challenge(s)
        </FdTypography>
      </Box>
      <Box className="flex items-end gap-x-1">
        <FdTypography
          variant="subtitle2"
          className="leading-[1.2rem] align-baseline"
        >
          {`${Math.round((value / totalTasks) * 100)}%`}
        </FdTypography>
        <FdTypography
          variant="captiontext2"
          className="leading-[1rem] align-baseline"
        >
          of all challenge(s)
        </FdTypography>
      </Box>
    </Box>
  );
};

LegendTooltip.propTypes = {
  data: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.number,
  }).isRequired,
  totalTasks: PropTypes.number.isRequired,
};

const BreakDownGraphs = ({ allTasks }) => {
  const [donutToolTipData, setDonutToolTipData] = useState(undefined);
  const [showDonutGraphCategoryTooltip, setShowDonutGraphCategoryTooltip] =
    useState(false);
  const [showDonutGraphDifficultyTooltip, setShowDonutGraphDifficultyTooltip] =
    useState(false);

  // Specialty Composition
  const donutGraphSpecialtyData = _.chain(
    allTasks
      .map((t) => ({
        id: t?.specialtyId,
        name: t?.specialty?.name,
      }))
      .filter((tc) => tc?.id),
  )
    .groupBy((i) => i?.id)
    .map((items, id) => ({
      id,
      label: items[0].name,
      value: items.length,
      color: SPECIALTY_COLOR[items[0]?.name] || '',
    }))
    .value();
  const donutGraphCategoryDataColors = donutGraphSpecialtyData?.map(
    (d) => d.color,
  );

  // Proficiency Composition
  const donutGraphProficiencyData = _.chain(
    allTasks
      ?.map((at) => ({
        id: at.difficulty,
        label:
          at?.difficulty > 5
            ? getDifficultyLevel(at?.difficulty)
            : PROFICIENCY_LEVELS[at?.difficulty],
      }))
      .filter((dv) => dv?.id),
  )
    .groupBy((i) => i?.label)
    .map((items, label) => ({
      id: label,
      label,
      value: items.length,
      color: PROFICIENCY[label]?.color,
    }))
    .filter((i) => i?.id !== 'null')
    .value();
  const donutGraphDifficultyDataColors = donutGraphProficiencyData?.map(
    (d) => d.color,
  );

  return (
    <Box>
      <Box my={4}>
        <FdTypography variant="subtitle2">Specialty Breakdown</FdTypography>
        <Box display="flex" flexDirection="row" mt={2} mb={2}>
          <Box height="150px" width="150px" mr={4}>
            <DonutStacked
              data={donutGraphSpecialtyData}
              colors={donutGraphCategoryDataColors}
              onHover={(_data) => {
                setDonutToolTipData(_data?.data);
                setShowDonutGraphCategoryTooltip(true);
              }}
              onLeave={() => {
                setDonutToolTipData(undefined);
                setShowDonutGraphCategoryTooltip(false);
              }}
            />
          </Box>
          {showDonutGraphCategoryTooltip ? (
            <LegendTooltip
              data={donutToolTipData}
              totalTasks={allTasks.length}
            />
          ) : (
            allTasks?.length > 0 && <HoverInfo value="specialty" />
          )}
        </Box>
        <DonutGraphLegend data={donutGraphSpecialtyData} />
      </Box>
      <Box my={4}>
        <FdTypography variant="subtitle2">Proficiency Breakdown</FdTypography>
        <Box display="flex" flexDirection="row" mt={2} mb={2}>
          <Box height="150px" width="150px" mr={4}>
            <DonutStacked
              data={donutGraphProficiencyData}
              colors={donutGraphDifficultyDataColors}
              onHover={(_data) => {
                setDonutToolTipData(_data?.data);
                setShowDonutGraphDifficultyTooltip(true);
              }}
              onLeave={() => {
                setDonutToolTipData(undefined);
                setShowDonutGraphDifficultyTooltip(false);
              }}
            />
          </Box>
          {showDonutGraphDifficultyTooltip ? (
            <LegendTooltip
              data={donutToolTipData}
              totalTasks={allTasks.length}
            />
          ) : (
            allTasks?.length > 0 && <HoverInfo value="proficiency" />
          )}
        </Box>
        <DonutGraphLegend data={donutGraphProficiencyData} />
      </Box>
    </Box>
  );
};

BreakDownGraphs.propTypes = {
  allTasks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default BreakDownGraphs;
