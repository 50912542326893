import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { Box, useTheme } from '@mui/material';
import { Building2Icon } from 'lucide-react';
import {
  FdTypography,
  FdSkeleton,
  useSnapshot,
  globalStore,
} from '@fifthdomain/fe-shared';
import { listAffliationsByUserId } from '../../graphql/queries';

const AffiliationTag = () => {
  const theme = useTheme();
  const globalSnap = useSnapshot(globalStore);

  const { data: affiliationData, loading: affiliationDataLoading } = useQuery(
    gql(listAffliationsByUserId),
    {
      variables: {
        userId: globalSnap?.userId,
      },
    },
  );
  const affiliationDetails =
    affiliationData?.listAffliationsByUserId?.items || [];
  const orgName = affiliationDetails?.filter((a) => a?.status === 'ACTIVE')?.[0]
    ?.org?.name;

  return (
    <FdSkeleton height={24} loading={affiliationDataLoading} width={200}>
      <Box>
        {orgName && (
          <Box
            className="flex items-center h-24px px-2 py-1 rounded-xl gap-x-2"
            style={{
              backgroundColor:
                theme?.palette?.type === 'dark'
                  ? theme?.palette?.primary?.main
                  : 'rgba(227, 230, 236, 1)',
              color: 'rgba(0, 0, 0, 0.87)',
            }}
          >
            <Building2Icon size={15} />
            <FdTypography
              variant="captiontext1"
              style={{ fontWeight: 500 }}
              color="rgba(0, 0, 0, 0.87)"
            >
              {orgName}
            </FdTypography>
          </Box>
        )}
      </Box>
    </FdSkeleton>
  );
};

export default AffiliationTag;
