import { format as dateFormat, utcToZonedTime } from 'date-fns-tz';
import {
  addHours,
  addMinutes,
  minutesToHours,
  isWithinInterval,
  parseISO,
} from 'date-fns';

const userTimeProfile = Intl.DateTimeFormat().resolvedOptions();
const { timeZone } = userTimeProfile;
const defaultDateFormatPattern = 'dd/MM/yyyy HH:mm';

const timeZoneAbbreviated = () => {
  const { 1: tz } = new Date().toString().match(/\((.+)\)/);

  // In Chrome browser, new Date().toString() is
  // "Thu Aug 06 2020 16:21:38 GMT+0530 (India Standard Time)"

  // In Safari browser, new Date().toString() is
  // "Thu Aug 06 2020 16:24:03 GMT+0530 (IST)"

  if (tz.includes(' ')) {
    return tz
      .split(' ')
      .map(([first]) => first)
      .join('');
  }
  return tz;
};

export const sortedByDate = (arr) =>
  arr?.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));

export const sortedByCreatedDate = (arr) =>
  arr?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

export const getDateTimeZone = (dateTime) => {
  return utcToZonedTime(new Date(dateTime), timeZone);
};

export const getDateTimeZoneFormatted = (dateTime, hideTimeZoneAbbreviated) => {
  return (
    dateFormat(getDateTimeZone(dateTime), defaultDateFormatPattern) +
    (!hideTimeZoneAbbreviated ? ` ${timeZoneAbbreviated()}` : '')
  );
};

export const getDateTimeZoneCustom = (dateTime, format) => {
  const dateFormatPattern = format || defaultDateFormatPattern;
  return dateFormat(getDateTimeZone(dateTime), dateFormatPattern);
};

export const getAssessmentFinishDateTime = (startedOn, hours, minutes) =>
  addMinutes(addHours(new Date(startedOn), Number(hours)), Number(minutes));

// difference in Minutes
export const getDifferenceInMinutes = (dt2, dt1) => {
  const diff = (dt2.getTime() - dt1.getTime()) / 1000;
  return Math.abs(diff / 60);
};

export const formatMinutes = (
  timeInMinutes,
  format = { hours: 'h', minutes: 'm', seconds: 's' },
) => {
  // less than 60 show as seconds
  if (timeInMinutes > 0 && timeInMinutes < 1)
    return `${Math.round(timeInMinutes * 60)}${format.seconds}`;
  // only minutes
  if (timeInMinutes > 0 && timeInMinutes < 60)
    return `${Math.round(timeInMinutes)}${format.minutes}`;
  // both hours & minutes
  const hours = minutesToHours(timeInMinutes);
  const remainingMinutes = Math.round(timeInMinutes % 60);

  return [
    hours && `${hours}${format.hours}`,
    remainingMinutes !== 0 ? `${remainingMinutes}${format.minutes}` : '',
  ].join(' ');
};

export const getFormattedDuration = (hours, minutes) => {
  const totalMinutes = hours * 60 + minutes;

  return formatMinutes(totalMinutes);
};

export const formatSingleDigit = (number) => {
  return number < 10 ? `0${number}` : number;
};

export const formatMinutesToHours = (timeInMinutes) => {
  let hours = 0;
  let minutes = 0;
  const seconds = 0;
  hours = minutesToHours(timeInMinutes);
  minutes =
    timeInMinutes >= 60 ? Math.round(timeInMinutes % 60) : timeInMinutes;

  return `${formatSingleDigit(hours)}:${formatSingleDigit(
    minutes,
  )}:${formatSingleDigit(seconds)}`;
};

export const formatMinutesToDaysHoursMinutes = (totalMinutes) => {
  const days = Math.floor(totalMinutes / (24 * 60)); // 1 day = 24 hours * 60 minutes
  const hours = Math.floor((totalMinutes % (24 * 60)) / 60);
  const minutes = Math.floor(totalMinutes % 60);
  const parts = [];
  if (days > 0) {
    parts.push(`${days}d`);
  }
  if (hours > 0) {
    parts.push(`${hours}h`);
  }
  if (minutes > 0 || (days === 0 && hours === 0)) {
    parts.push(`${minutes}m`);
  }
  return parts.join(' ');
};

export const getDuration = (hours, minutes) =>
  [hours === 0 ? '' : `${hours}h`, minutes === 0 ? '' : `${minutes}m`].join(
    ' ',
  );

export const isDateInRange = (date, startDate, endDate) => {
  return isWithinInterval(parseISO(date), {
    start: parseISO(startDate),
    end: parseISO(endDate),
  });
};
