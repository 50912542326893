export const listTaskOrgs = /* GraphQL */ `
  query ListTaskOrgs(
    $filter: ModelTaskOrgFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTaskOrgs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        taskId
        orgId
        task {
          modulePart {
            labId
          }
          lab {
            vms {
              items {
                id
              }
            }
          }
          name
          id
          type
          category
          difficulty
          specialtyId
          specialty {
            name
            skills {
              items {
                id
                name
              }
            }
          }
          skills {
            items {
              skillId
              skill {
                name
                alias
              }
              techniqueTags {
                items {
                  techniqueTag {
                    name
                  }
                }
              }
            }
          }
          technologyTags {
            items {
              technologyTag {
                name
              }
            }
          }
          files {
            items {
              id
              name
              url
            }
          }
          competencies {
            items {
              competencyId
              area {
                areaName
              }
            }
          }
          public
          org {
            name
          }
          orgs {
            items {
              org {
                name
                id
              }
            }
          }
          estimatedSolveTime
          summary
          recommendedPoints
          description
          hints {
            items {
              text
              id
            }
          }
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listTaskAttemptAggregates = /* GraphQL */ `
  query ListTaskAttemptAggregates(
    $filter: ModelTaskAttemptAggregateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTaskAttemptAggregates(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        taskId
        status
      }
      nextToken
    }
  }
`;
export const listSkills = /* GraphQL */ `
  query ListSkills(
    $filter: ModelSkillFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSkills(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        alias
        specialty {
          name
          id
        }
      }
      nextToken
    }
  }
`;
