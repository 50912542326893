/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getSystemTime = /* GraphQL */ `
  query GetSystemTime {
    getSystemTime
  }
`;
export const getAssessment = /* GraphQL */ `
  query GetAssessment($id: ID!) {
    getAssessment(id: $id) {
      id
      orgId
      name
      description
      teamBased
      startDateTime
      endDateTime
      hours
      minutes
      tasks {
        items {
          id
          assessmentId
          taskId
          createdAt
          updatedAt
        }
        nextToken
      }
      preMessage
      postMessage
      createdBy
      participantEventType
      creator {
        id
        email
        name
        phone
        ageBracket
        type
        participantType
        orgId
        alias
        org {
          id
          name
          createdAt
          updatedAt
        }
        verifiedOnboarding
        status
        permissions
        groups {
          nextToken
        }
        tasks {
          nextToken
        }
        theme
        createdAt
        updatedAt
      }
      groups {
        items {
          groupId
          assessmentId
          status
          startedOn
          finishedOn
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listAssessments = /* GraphQL */ `
  query ListAssessments(
    $filter: ModelAssessmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAssessments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        orgId
        name
        description
        teamBased
        startDateTime
        endDateTime
        hours
        minutes
        tasks {
          nextToken
        }
        preMessage
        postMessage
        createdBy
        participantEventType
        creator {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTask = /* GraphQL */ `
  query GetTask($id: ID!) {
    getTask(id: $id) {
      id
      name
      description
      externalId
      category
      difficulty
      recommendedPoints
      estimatedSolveTime
      solution
      competencies {
        items {
          id
          competencyId
          taskId
          createdAt
          updatedAt
        }
        nextToken
      }
      assessments {
        items {
          id
          assessmentId
          taskId
          createdAt
          updatedAt
        }
        nextToken
      }
      answer
      files {
        items {
          id
          taskId
          url
          name
          size
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      hints {
        items {
          id
          taskId
          text
          createdAt
          updatedAt
        }
        nextToken
      }
      userId
      user {
        id
        email
        name
        phone
        ageBracket
        type
        participantType
        orgId
        alias
        org {
          id
          name
          createdAt
          updatedAt
        }
        verifiedOnboarding
        status
        permissions
        groups {
          nextToken
        }
        tasks {
          nextToken
        }
        theme
        createdAt
        updatedAt
      }
      status
      history {
        items {
          id
          taskId
          userId
          message
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listTasks = /* GraphQL */ `
  query ListTasks(
    $filter: ModelTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTasks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        externalId
        category
        difficulty
        recommendedPoints
        estimatedSolveTime
        solution
        competencies {
          nextToken
        }
        assessments {
          nextToken
        }
        answer
        files {
          nextToken
        }
        hints {
          nextToken
        }
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        status
        history {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getHistory = /* GraphQL */ `
  query GetHistory($id: ID!) {
    getHistory(id: $id) {
      id
      taskId
      userId
      message
      createdAt
      updatedAt
    }
  }
`;
export const listHistories = /* GraphQL */ `
  query ListHistories(
    $filter: ModelHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHistories(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        taskId
        userId
        message
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFile = /* GraphQL */ `
  query GetFile($id: ID!) {
    getFile(id: $id) {
      id
      taskId
      url
      name
      size
      type
      file {
        bucket
        key
        region
      }
      createdAt
      updatedAt
    }
  }
`;
export const listFiles = /* GraphQL */ `
  query ListFiles(
    $filter: ModelFileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        taskId
        url
        name
        size
        type
        file {
          bucket
          key
          region
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getHint = /* GraphQL */ `
  query GetHint($id: ID!) {
    getHint(id: $id) {
      id
      taskId
      text
      createdAt
      updatedAt
    }
  }
`;
export const listHints = /* GraphQL */ `
  query ListHints(
    $filter: ModelHintFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHints(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        taskId
        text
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserAssessment = /* GraphQL */ `
  query GetUserAssessment($id: ID!) {
    getUserAssessment(id: $id) {
      id
      userId
      user {
        id
        email
        name
        phone
        ageBracket
        type
        participantType
        orgId
        alias
        org {
          id
          name
          createdAt
          updatedAt
        }
        verifiedOnboarding
        status
        permissions
        groups {
          nextToken
        }
        tasks {
          nextToken
        }
        theme
        createdAt
        updatedAt
      }
      assessment {
        id
        orgId
        name
        description
        teamBased
        startDateTime
        endDateTime
        hours
        minutes
        tasks {
          nextToken
        }
        preMessage
        postMessage
        createdBy
        participantEventType
        creator {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        createdAt
        updatedAt
      }
      userAssessmentAssessmentId
      groupId
      status
      startedOn
      finishedOn
      createdAt
      updatedAt
    }
  }
`;
export const listUserAssessments = /* GraphQL */ `
  query ListUserAssessments(
    $filter: ModelUserAssessmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserAssessments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        userId
        assessment {
          id
          orgId
          name
          participantEventType
        }
        status
      }
      nextToken
    }
  }
`;
export const listCoursesByOrgId = /* GraphQL */ `
  query ListCoursesByOrgId(
    $orgId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCoursesByOrgId(
      orgId: $orgId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        status
        name
        availability
        courseUsers {
          items {
            userId
          }
        }
      }
      nextToken
    }
  }
`;

export const getTaskAttempt = /* GraphQL */ `
  query GetTaskAttempt($id: ID!) {
    getTaskAttempt(id: $id) {
      id
      userId
      task {
        id
        name
        description
        externalId
        category
        difficulty
        recommendedPoints
        estimatedSolveTime
        solution
        competencies {
          nextToken
        }
        assessments {
          nextToken
        }
        answer
        files {
          nextToken
        }
        hints {
          nextToken
        }
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        status
        history {
          nextToken
        }
        createdAt
        updatedAt
      }
      userAssessment {
        id
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        assessment {
          id
          orgId
          name
          description
          teamBased
          startDateTime
          endDateTime
          hours
          minutes
          preMessage
          postMessage
          createdBy
          participantEventType
          createdAt
          updatedAt
        }
        userAssessmentAssessmentId
        groupId
        status
        startedOn
        finishedOn
        createdAt
        updatedAt
      }
      taskAttemptUserAssessmentId
      assessmentId
      success
      firstBlood
      answer
      createdAt
      updatedAt
      taskAttemptTaskId
    }
  }
`;
export const listTaskAttempts = /* GraphQL */ `
  query ListTaskAttempts(
    $filter: ModelTaskAttemptFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTaskAttempts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        task {
          id
          name
          description
          externalId
          category
          difficulty
          recommendedPoints
          estimatedSolveTime
          solution
          answer
          userId
          status
          createdAt
          updatedAt
        }
        userAssessment {
          id
          userId
          userAssessmentAssessmentId
          groupId
          status
          startedOn
          finishedOn
          createdAt
          updatedAt
        }
        taskAttemptUserAssessmentId
        assessmentId
        success
        firstBlood
        answer
        createdAt
        updatedAt
        taskAttemptTaskId
      }
      nextToken
    }
  }
`;
export const getTaskOpened = /* GraphQL */ `
  query GetTaskOpened($id: ID!) {
    getTaskOpened(id: $id) {
      id
      userId
      task {
        id
        name
        description
        externalId
        category
        difficulty
        recommendedPoints
        estimatedSolveTime
        solution
        competencies {
          nextToken
        }
        assessments {
          nextToken
        }
        answer
        files {
          nextToken
        }
        hints {
          nextToken
        }
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        status
        history {
          nextToken
        }
        createdAt
        updatedAt
      }
      userAssessment {
        id
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        assessment {
          id
          orgId
          name
          description
          teamBased
          startDateTime
          endDateTime
          hours
          minutes
          preMessage
          postMessage
          createdBy
          participantEventType
          createdAt
          updatedAt
        }
        userAssessmentAssessmentId
        groupId
        status
        startedOn
        finishedOn
        createdAt
        updatedAt
      }
      taskOpenedUserAssessmentId
      createdAt
      updatedAt
      taskOpenedTaskId
    }
  }
`;
export const listTaskOpeneds = /* GraphQL */ `
  query ListTaskOpeneds(
    $filter: ModelTaskOpenedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTaskOpeneds(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        task {
          id
          name
          description
          externalId
          category
          difficulty
          recommendedPoints
          estimatedSolveTime
          solution
          answer
          userId
          status
          createdAt
          updatedAt
        }
        userAssessment {
          id
          userId
          userAssessmentAssessmentId
          groupId
          status
          startedOn
          finishedOn
          createdAt
          updatedAt
        }
        taskOpenedUserAssessmentId
        createdAt
        updatedAt
        taskOpenedTaskId
      }
      nextToken
    }
  }
`;
export const getCompetency = /* GraphQL */ `
  query GetCompetency($id: ID!) {
    getCompetency(id: $id) {
      id
      area
      topic
      competency
      code
      tasks {
        items {
          id
          competencyId
          taskId
          createdAt
          updatedAt
        }
        nextToken
      }
      questions {
        items {
          id
          competencyID
          questionID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCompetencies = /* GraphQL */ `
  query ListCompetencies(
    $filter: ModelCompetencyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompetencies(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        area
        topic
        competency
        code
        tasks {
          nextToken
        }
        questions {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOnboarding = /* GraphQL */ `
  query GetOnboarding($id: ID!) {
    getOnboarding(id: $id) {
      id
      userId
      user {
        id
        email
        name
        phone
        ageBracket
        type
        participantType
        orgId
        alias
        org {
          id
          name
          createdAt
          updatedAt
        }
        verifiedOnboarding
        status
        permissions
        groups {
          nextToken
        }
        tasks {
          nextToken
        }
        theme
        createdAt
        updatedAt
      }
      cyberExperience
      highestEducation
      highestEducationOther
      cyberTraining
      formalTraining
      formalTrainingOther
      selfDirectedTraining {
        hours
      }
      workingIndustry
      workingIndustryOther
      createdAt
      updatedAt
    }
  }
`;
export const listOnboardings = /* GraphQL */ `
  query ListOnboardings(
    $filter: ModelOnboardingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOnboardings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        cyberExperience
        highestEducation
        highestEducationOther
        cyberTraining
        formalTraining
        formalTrainingOther
        selfDirectedTraining {
          hours
        }
        workingIndustry
        workingIndustryOther
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOrg = /* GraphQL */ `
  query GetOrg($id: ID!) {
    getOrg(id: $id) {
      id
      name
      members {
        items {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listOrgs = /* GraphQL */ `
  query ListOrgs(
    $filter: ModelOrgFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrgs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        members {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserConsent = /* GraphQL */ `
  query GetUserConsent($id: ID!) {
    getUserConsent(id: $id) {
      id
      userId
      type
      consentedOn
      version
      createdAt
      updatedAt
    }
  }
`;
export const listUserConsents = /* GraphQL */ `
  query ListUserConsents(
    $filter: ModelUserConsentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserConsents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        type
        consentedOn
        version
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getConsent = /* GraphQL */ `
  query GetConsent($id: ID!) {
    getConsent(id: $id) {
      id
      type
      version
      createdAt
      updatedAt
    }
  }
`;
export const listConsents = /* GraphQL */ `
  query ListConsents(
    $filter: ModelConsentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConsents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        version
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRole = /* GraphQL */ `
  query GetRole($id: ID!) {
    getRole(id: $id) {
      id
      name
      permissions
      createdAt
      updatedAt
    }
  }
`;
export const listRoles = /* GraphQL */ `
  query ListRoles(
    $filter: ModelRoleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        permissions
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGroup = /* GraphQL */ `
  query GetGroup($id: ID!) {
    getGroup(id: $id) {
      id
      name
      description
      users {
        items {
          id
          groupId
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listGroups = /* GraphQL */ `
  query ListGroups(
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        users {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGroupUser = /* GraphQL */ `
  query GetGroupUser($id: ID!) {
    getGroupUser(id: $id) {
      id
      groupId
      userId
      user {
        id
        email
        name
        phone
        ageBracket
        type
        participantType
        orgId
        alias
        org {
          id
          name
          createdAt
          updatedAt
        }
        verifiedOnboarding
        status
        permissions
        groups {
          nextToken
        }
        tasks {
          nextToken
        }
        theme
        createdAt
        updatedAt
      }
      group {
        id
        name
        description
        users {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listGroupUsers = /* GraphQL */ `
  query ListGroupUsers(
    $filter: ModelGroupUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroupUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        groupId
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        group {
          id
          name
          description
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getQuiz = /* GraphQL */ `
  query GetQuiz($id: ID!) {
    getQuiz(id: $id) {
      id
      orgId
      name
      ownerId
      description
      duration
      multipleAttempts
      showResults
      user {
        id
        email
        name
        phone
        ageBracket
        type
        participantType
        orgId
        alias
        org {
          id
          name
          createdAt
          updatedAt
        }
        verifiedOnboarding
        status
        permissions
        groups {
          nextToken
        }
        tasks {
          nextToken
        }
        theme
        createdAt
        updatedAt
      }
      questions {
        items {
          id
          quizId
          type
          name
          point
          hasOptions
          caseSensitive
          whiteSpaceSensitive
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listQuizzes = /* GraphQL */ `
  query ListQuizzes(
    $filter: ModelQuizFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuizzes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        orgId
        name
        ownerId
        description
        duration
        multipleAttempts
        showResults
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        questions {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getQuestion = /* GraphQL */ `
  query GetQuestion($id: ID!) {
    getQuestion(id: $id) {
      id
      quizId
      type
      name
      point
      hasOptions
      caseSensitive
      whiteSpaceSensitive
      options {
        items {
          questionId
          questionOptionType
          optionName
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      answers {
        items {
          questionId
          answerOptionType
          answer
          orderNumber
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      competencies {
        items {
          id
          competencyID
          questionID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listQuestions = /* GraphQL */ `
  query ListQuestions(
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        quizId
        type
        name
        point
        hasOptions
        caseSensitive
        whiteSpaceSensitive
        options {
          nextToken
        }
        answers {
          nextToken
        }
        competencies {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getQuestionOption = /* GraphQL */ `
  query GetQuestionOption($id: ID!) {
    getQuestionOption(id: $id) {
      questionId
      questionOptionType
      optionName
      id
      createdAt
      updatedAt
    }
  }
`;
export const listQuestionOptions = /* GraphQL */ `
  query ListQuestionOptions(
    $filter: ModelQuestionOptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestionOptions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        questionId
        questionOptionType
        optionName
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getQuestionAnswer = /* GraphQL */ `
  query GetQuestionAnswer($id: ID!) {
    getQuestionAnswer(id: $id) {
      questionId
      answerOptionType
      answer
      orderNumber
      id
      createdAt
      updatedAt
    }
  }
`;
export const listQuestionAnswers = /* GraphQL */ `
  query ListQuestionAnswers(
    $filter: ModelQuestionAnswerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestionAnswers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        questionId
        answerOptionType
        answer
        orderNumber
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCompetencyQuestions = /* GraphQL */ `
  query GetCompetencyQuestions($id: ID!) {
    getCompetencyQuestions(id: $id) {
      id
      competencyID
      questionID
      competency {
        id
        area
        topic
        competency
        code
        tasks {
          nextToken
        }
        questions {
          nextToken
        }
        createdAt
        updatedAt
      }
      question {
        id
        quizId
        type
        name
        point
        hasOptions
        caseSensitive
        whiteSpaceSensitive
        options {
          nextToken
        }
        answers {
          nextToken
        }
        competencies {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCompetencyQuestions = /* GraphQL */ `
  query ListCompetencyQuestions(
    $filter: ModelCompetencyQuestionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompetencyQuestions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        competencyID
        questionID
        competency {
          id
          area
          topic
          competency
          code
          createdAt
          updatedAt
        }
        question {
          id
          quizId
          type
          name
          point
          hasOptions
          caseSensitive
          whiteSpaceSensitive
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listAssessmentsByOrg = /* GraphQL */ `
  query ListAssessmentsByOrg(
    $orgId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelAssessmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAssessmentsByOrg(
      orgId: $orgId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orgId
        name
        description
        teamBased
        startDateTime
        endDateTime
        hours
        minutes
        tasks {
          nextToken
        }
        preMessage
        postMessage
        createdBy
        participantEventType
        creator {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listAssessmentsByParticipantEventType = /* GraphQL */ `
  query ListAssessmentsByParticipantEventType(
    $orgId: String!
    $participantEventType: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAssessmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAssessmentsByParticipantEventType(
      orgId: $orgId
      participantEventType: $participantEventType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orgId
        name
        description
        teamBased
        startDateTime
        endDateTime
        hours
        minutes
        tasks {
          nextToken
        }
        preMessage
        postMessage
        createdBy
        participantEventType
        creator {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listTasksByUserId = /* GraphQL */ `
  query ListTasksByUserId(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTasksByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        externalId
        category
        difficulty
        recommendedPoints
        estimatedSolveTime
        solution
        competencies {
          nextToken
        }
        assessments {
          nextToken
        }
        answer
        files {
          nextToken
        }
        hints {
          nextToken
        }
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        status
        history {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listUsersByAssessmentId = /* GraphQL */ `
  query ListUsersByAssessmentId(
    $userAssessmentAssessmentId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserAssessmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsersByAssessmentId(
      userAssessmentAssessmentId: $userAssessmentAssessmentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        assessment {
          id
          orgId
          name
          description
          teamBased
          startDateTime
          endDateTime
          hours
          minutes
          preMessage
          postMessage
          createdBy
          participantEventType
          createdAt
          updatedAt
        }
        userAssessmentAssessmentId
        groupId
        status
        startedOn
        finishedOn
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserById = /* GraphQL */ `
  query GetUserById(
    $id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUserById(
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        name
        phone
        ageBracket
        type
        participantType
        orgId
        alias
        org {
          id
          name
          createdAt
          updatedAt
          specialThemes {
            items {
              themeId
              theme {
                name
              }
            }
          }
        }
        verifiedOnboarding
        status
        permissions
        groups {
          nextToken
        }
        tasks {
          nextToken
        }
        theme
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listUsersByOrgId = /* GraphQL */ `
  query ListUsersByOrgId(
    $orgId: ID!
    $email: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsersByOrgId(
      orgId: $orgId
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        name
        phone
        ageBracket
        type
        participantType
        orgId
        alias
        org {
          id
          name
          createdAt
          updatedAt
        }
        verifiedOnboarding
        status
        permissions
        groups {
          items {
            groupId
            group {
              id
              name
            }
          }
          nextToken
        }
        tasks {
          nextToken
        }
        assessments(
          filter: {
            and: [{ status: { ne: FINISHED } }, { status: { ne: REMOVED } }]
          }
        ) {
          items {
            assessment {
              id
              name
              participantEventType
            }
          }
        }
        courses {
          items {
            course {
              id
              name
            }
          }
        }
        theme
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listOnboardingsByUserId = /* GraphQL */ `
  query ListOnboardingsByUserId(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelOnboardingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOnboardingsByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        cyberExperience
        highestEducation
        highestEducationOther
        cyberTraining
        formalTraining
        formalTrainingOther
        selfDirectedTraining {
          hours
        }
        workingIndustry
        workingIndustryOther
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listInvitedUsersByOrg = /* GraphQL */ `
  query ListInvitedUsersByOrg(
    $orgId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelInvitedUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvitedUsersByOrg(
      orgId: $orgId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        type
        orgId
        alias
        groupId
        group {
          id
          name
        }
        assessmentId
        accepted
        participantType
        permissions
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listInvitedUsersByAssessmentId = /* GraphQL */ `
  query ListInvitedUsersByAssessmentId(
    $assessmentId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelInvitedUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvitedUsersByAssessmentId(
      assessmentId: $assessmentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        type
        orgId
        alias
        assessmentId
        accepted
        participantType
        permissions
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listInvitedUsersByGroupId = /* GraphQL */ `
  query ListInvitedUsersByGroupId(
    $groupId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelInvitedUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvitedUsersByGroupId(
      groupId: $groupId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        type
        orgId
        alias
        assessmentId
        groupId
        accepted
        participantType
        permissions
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listUsersByGroupId = /* GraphQL */ `
  query ListUsersByGroupId(
    $groupId: ID!
    $userId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGroupUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsersByGroupId(
      groupId: $groupId
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupId
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        group {
          id
          name
          description
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listGroupsByUserId = /* GraphQL */ `
  query ListGroupsByUserId(
    $userId: ID!
    $groupId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGroupUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroupsByUserId(
      userId: $userId
      groupId: $groupId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupId
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        group {
          id
          name
          description
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listAssessmentsByGroupId = /* GraphQL */ `
  query ListAssessmentsByGroupId(
    $groupId: ID!
    $assessmentId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGroupAssessmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAssessmentsByGroupId(
      groupId: $groupId
      assessmentId: $assessmentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        groupId
        group {
          id
          name
          description
          createdAt
          updatedAt
        }
        assessmentId
        assessment {
          id
          orgId
          name
          description
          teamBased
          startDateTime
          endDateTime
          hours
          minutes
          preMessage
          postMessage
          createdBy
          participantEventType
          createdAt
          updatedAt
        }
        status
        startedOn
        finishedOn
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listGroupsByAssessmenId = /* GraphQL */ `
  query ListGroupsByAssessmenId(
    $assessmentId: ID!
    $groupId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGroupAssessmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroupsByAssessmenId(
      assessmentId: $assessmentId
      groupId: $groupId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        groupId
        group {
          id
          name
          description
          createdAt
          updatedAt
        }
        assessmentId
        assessment {
          id
          orgId
          name
          description
          teamBased
          startDateTime
          endDateTime
          hours
          minutes
          preMessage
          postMessage
          createdBy
          participantEventType
          createdAt
          updatedAt
        }
        status
        startedOn
        finishedOn
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listQuizesByOrgId = /* GraphQL */ `
  query ListQuizesByOrgId(
    $orgId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelQuizFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuizesByOrgId(
      orgId: $orgId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orgId
        name
        ownerId
        description
        duration
        multipleAttempts
        showResults
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        questions {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listQuestionsByQuizId = /* GraphQL */ `
  query ListQuestionsByQuizId(
    $quizId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestionsByQuizId(
      quizId: $quizId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        quizId
        type
        name
        point
        hasOptions
        caseSensitive
        whiteSpaceSensitive
        options {
          nextToken
        }
        answers {
          nextToken
        }
        competencies {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listGroupsByOrgId = /* GraphQL */ `
  query ListGroupsByOrgId(
    $orgId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroupsByOrgId(
      orgId: $orgId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        users {
          items {
            userId
            id
          }
          nextToken
        }
        orgId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listTagsByOrgId = /* GraphQL */ `
  query ListTagsByOrgId(
    $orgId: ID!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTagsByOrgId(
      orgId: $orgId
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        type
        color
        userId
        user {
          id
          name
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        status
        orgId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listCourseGroups = /* GraphQL */ `
  query ListCourseGroups(
    $filter: ModelCourseGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCourseGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        courseId
        groupId
        group {
          id
          name
        }
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const listAffliationRequestsByOrgId = /* GraphQL */ `
  query ListAffliationRequestsByOrgId(
    $orgId: ID!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAffliationRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAffliationRequestsByOrgId(
      orgId: $orgId
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        orgId
        creatorId
        email
        org {
          maxInvitees
        }
        status
        createdAt
        updatedAt
        user {
          id
          groups {
            items {
              group {
                name
              }
              groupId
            }
          }
          name
          alias
          permissions
          assessments(filter: { status: { ne: REMOVED } }) {
            items {
              status
              userAssessmentAssessmentId
              assessment {
                orgId
                id
                name
                participantEventType
              }
            }
          }
          courses {
            items {
              course {
                orgId
                id
                name
                availability
                status
                modulePartProgresses(filter: { status: { ne: NOT_STARTED } }) {
                  items {
                    status
                    courseId
                    userId
                  }
                }
              }
            }
          }
        }
      }
      nextToken
      __typename
    }
  }
`;

export const listAffliationsByOrgId = /* GraphQL */ `
  query ListAffliationsByOrgId(
    $orgId: ID!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserOrgAffliationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAffliationsByOrgId(
      orgId: $orgId
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        orgId
        status
        user {
          id
          name
          email
          alias
          permissions
          assessments(filter: { status: { ne: REMOVED } }) {
            items {
              assessment {
                id
                name
                participantEventType
              }
            }
          }
          courses {
            items {
              course {
                id
                name
              }
            }
          }
        }
      }
      nextToken
      __typename
    }
  }
`;

export const listNonAffliatedUserEventsByOrgId = /* GraphQL */ `
  query ListNonAffliatedUserEventsByOrgId(
    $orgId: ID!
    $eventId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNonAffliatedUserEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNonAffliatedUserEventsByOrgId(
      orgId: $orgId
      eventId: $eventId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        eventId
        eventType
        orgId
        email
        createdAt
        updatedAt
        user {
          id
          name
          alias
          permissions
        }
      }
      nextToken
      __typename
    }
  }
`;
export const listSkills = /* GraphQL */ `
  query ListSkills(
    $filter: ModelSkillFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSkills(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        alias
        specialtyId
        specialty {
          name
        }
      }
      nextToken
    }
  }
`;
export const listTaskAttemptAggregatesByTeamId = /* GraphQL */ `
  query ListTaskAttemptAggregatesByTeamId(
    $teamId: ID!
    $assessmentId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTaskAttemptAggregateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTaskAttemptAggregatesByTeamId(
      teamId: $teamId
      assessmentId: $assessmentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        taskId
        status
        attempts
        userId
        points
        task {
          specialtyId
          skills {
            items {
              skillId
              skill {
                id
                name
                alias
              }
            }
          }
          difficulty
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listTaskAttemptAggregatesByUserId = /* GraphQL */ `
  query ListTaskAttemptAggregatesByUserId(
    $userId: ID!
    $assessmentId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTaskAttemptAggregateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTaskAttemptAggregatesByUserId(
      userId: $userId
      assessmentId: $assessmentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        taskId
        status
        attempts
        userId
        points
        task {
          id
          specialtyId
          skills {
            items {
              skillId
              skill {
                id
                name
                alias
              }
            }
          }
          difficulty
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listUserScoreboardsByTeamId = /* GraphQL */ `
  query ListUserScoreboardsByTeamId(
    $teamId: ID!
    $assessmentId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserScoreboardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserScoreboardsByTeamId(
      teamId: $teamId
      assessmentId: $assessmentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        teamId
        flags
        firstBloods
        points
        successScore
        speed
        efficiency
        score
        assessmentId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listUserScoreboardsByUserId = /* GraphQL */ `
  query ListUserScoreboardsByUserId(
    $userId: ID!
    $assessmentId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserScoreboardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserScoreboardsByUserId(
      userId: $userId
      assessmentId: $assessmentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        teamId
        flags
        firstBloods
        points
        successScore
        speed
        efficiency
        score
        assessmentId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listSquadsByName = /* GraphQL */ `
  query ListSquadsByName(
    $name: String!
    $orgId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSquadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSquadsByName(
      name: $name
      orgId: $orgId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        orgId
      }
      nextToken
    }
  }
`;
export const listSquadsByOrgId = /* GraphQL */ `
  query ListSquadsByOrgId(
    $orgId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSquadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSquadsByOrgId(
      orgId: $orgId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        function {
          functionLeads {
            items {
              userId
            }
          }
        }
        members {
          items {
            id
            userId
            role
            status
            user {
              id
              name
              email
              alias
              permissions
              assessments {
                items {
                  startedOn
                  status
                  assessment {
                    orgId
                    participantEventType
                  }
                }
              }
              courses {
                items {
                  course {
                    orgId
                    id
                    status
                    modulePartProgresses {
                      items {
                        status
                        courseId
                        userId
                      }
                    }
                  }
                }
              }
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listSquadMembersBySquadId = /* GraphQL */ `
  query ListSquadMembersBySquadId(
    $squadId: ID!
    $userId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSquadMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSquadMembersBySquadId(
      squadId: $squadId
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        squadId
        userId
        role
      }
      nextToken
    }
  }
`;
export const listFunctionsByOrgId = /* GraphQL */ `
  query ListFunctionsByOrgId(
    $orgId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelFunctionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFunctionsByOrgId(
      orgId: $orgId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        userId
        orgId
        status
        createdAt
        updatedAt
        functionLeads {
          items {
            userId
            user {
              id
              name
            }
            functionFunctionLeadsId
          }
        }
        customerWorkroles {
          items {
            id
            workRoleUnits
            customerWorkrole {
              name
              customerSkills {
                items {
                  skills {
                    items {
                      skill {
                        name
                        specialty {
                          name
                        }
                        alias
                      }
                      skillId
                    }
                  }
                  difficulty
                }
              }
            }
          }
        }
      }
      nextToken
    }
  }
`;
export const listCustomerWorkRolesByOrgId = /* GraphQL */ `
  query ListCustomerWorkRolesByOrgId(
    $orgId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCustomerWorkroleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomerWorkRolesByOrgId(
      orgId: $orgId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        alias
        customerSkills {
          items {
            skills {
              items {
                skill {
                  name
                  specialty {
                    name
                  }
                  alias
                }
                skillId
              }
            }
            difficulty
          }
        }
        orgId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFunction = /* GraphQL */ `
  query GetFunction($id: ID!) {
    getFunction(id: $id) {
      id
      name
      description
      functionLeads {
        items {
          id
          userId
          user {
            id
            email
            name
          }
        }
      }
      userId
      user {
        id
        name
      }
      orgId
      squads {
        nextToken
      }
      status
      customerWorkroles {
        items {
          id
          workRoleUnits
          customerWorkroleId
          customerWorkrole {
            name
            customerSkills {
              items {
                skills {
                  items {
                    skill {
                      name
                      specialty {
                        name
                      }
                      alias
                    }
                    skillId
                  }
                }
                difficulty
              }
            }
          }
        }
      }
      squads {
        items {
          id
          name
          status
          description
          members {
            items {
              id
              userId
              status
              user {
                id
                email
                name
              }
              role
              squadId
            }
          }
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const listUserSkillMetricsByUserId = /* GraphQL */ `
  query ListUserSkillMetricsByUserId(
    $userId: ID!
    $participatedOrgId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserSkillMetricFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserSkillMetricsByUserId(
      userId: $userId
      participatedOrgId: $participatedOrgId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        difficulty
        specialtyId
        skillId
        skill {
          alias
          name
        }
        derived
        userId
        participatedOrgId
        score
        degradedScore
        points
        lastAttemptedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
