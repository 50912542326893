import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { AppBasePageProvider } from '@fifthdomain/fe-shared';

const FullPageWrapper = ({ children }) => <Box px={3}>{children}</Box>;

FullPageWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

const AppPageWrapperProvider = ({ hideSideBar, children }) => {
  const ComponentWrapper = hideSideBar ? FullPageWrapper : AppBasePageProvider;

  return <ComponentWrapper>{children}</ComponentWrapper>;
};

AppPageWrapperProvider.defaultProps = {
  hideSideBar: false,
};

AppPageWrapperProvider.propTypes = {
  hideSideBar: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default AppPageWrapperProvider;
