import React from 'react';
import { Box } from '@mui/material';
import * as singleSpa from 'single-spa';
import {
  FdTypography,
  FdButton,
  FdCard,
  FdProgress,
  useQueryRecursive,
} from '@fifthdomain/fe-shared';
import { FdBreadcrumbHeader } from '@fifthdomain/sidebar';
import { gql } from '@apollo/client';
import { listOrgs } from '../graphql/queries';
import AddUserImage from '../shared/images/add-user.svg';
import { OrgTable } from '../components/Organisation';
import { sortedByDate } from '../shared/utils/dateUtils';

const ManageOrgs = () => {
  const { data: listOrgsData, loading: listOrgsLoading } = useQueryRecursive(
    gql(listOrgs),
  );

  if (listOrgsLoading) {
    return <FdProgress />;
  }

  const listOrgsRows = sortedByDate([
    ...(listOrgsData?.listOrgs?.items?.map((item) => ({
      id: item?.id,
      name: item?.name,
      members: item?.members?.items?.length,
      createdAt: item?.createdAt,
      updatedAt: item?.updatedAt,
      products: item?.products?.items?.map((productName) => ({
        productName: productName?.product?.name,
      })),
    })) || []),
  ]);

  const actions = [
    {
      label: 'Edit',
      show: () => true,
      onClick: ({ id }) => singleSpa.navigateToUrl(`/org/view/${id}`),
    },
    {
      label: 'Delete',
      show: () => true,
      onClick: () => {},
    },
    {
      show: () => false,
    },
  ];

  return (
    <Box>
      <FdBreadcrumbHeader
        page={{ name: 'Organisations', type: 'ORGANISATION' }}
      />
      <Box display="flex" my={2}>
        <FdTypography variant="h2">Manage Orgs</FdTypography>
      </Box>
      <FdCard variant="outlined">
        {listOrgsRows?.length ? (
          <Box>
            <Box display="flex" justifyContent="space-between" mb={2}>
              <FdTypography variant="h3">Created Orgs</FdTypography>
              <FdButton
                variant="primary"
                onClick={() => singleSpa.navigateToUrl('/org/create')}
              >
                Create Org
              </FdButton>
            </Box>
            <OrgTable rows={listOrgsRows} actions={actions} />
          </Box>
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
          >
            <Box>
              <FdTypography variant="h2" color="secondary">
                Created list of Orgs will appear here.
              </FdTypography>
            </Box>
            <Box my={2}>
              <img src={AddUserImage} alt="add-user" />
            </Box>
            <Box my={1}>
              <FdButton
                size="large"
                onClick={() => singleSpa.navigateToUrl('/org/create')}
              >
                Create Org
              </FdButton>
            </Box>
          </Box>
        )}
      </FdCard>
    </Box>
  );
};
export default ManageOrgs;
