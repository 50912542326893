import React from 'react';
import PropTypes from 'prop-types';
import { gql, useQuery } from '@apollo/client';
import { Box, useTheme } from '@mui/material';
import SpokeIcon from '@mui/icons-material/Spoke';
import {
  FdTypography,
  FdSkeleton,
  useSnapshot,
  globalStore,
  FdTooltip,
} from '@fifthdomain/fe-shared';
import { listSquadMembersByUserId } from '../../graphql/queries';

const SquadTag = ({ showLabel }) => {
  const theme = useTheme();
  const globalSnap = useSnapshot(globalStore);

  const { data: squadMemberData, loading: squadMemberLoading } = useQuery(
    gql(listSquadMembersByUserId),
    {
      variables: {
        userId: globalSnap?.userId,
      },
    },
  );
  const squadMember =
    squadMemberData?.listSquadMembersByUserId?.items?.find(
      (s) => s.squad && s?.role !== 'VIEWER',
    ) || {};
  const squadName = squadMember?.squad?.name || '';

  return (
    <FdSkeleton height={24} loading={squadMemberLoading} width={200}>
      <Box className="flex items-center justify-center gap-x-2">
        {showLabel && squadName && (
          <FdTypography variant="captiontext1" color="secondary">
            Squad:
          </FdTypography>
        )}
        <Box>
          {squadName && (
            <FdTooltip
              title={`
          You are currently a member of ${squadName}. Squad Managers and Squad Viewers can see your skill and performance data, and how it contributes to this squad. 
          Squad members without management abilities will not be able to view your individual skill and performance data.`}
            >
              <Box
                className="flex items-center px-2 py-1 rounded-xl gap-x-2"
                style={{
                  height: '24px',
                  backgroundColor:
                    theme?.palette?.type === 'dark'
                      ? theme.palette.primary.main
                      : 'rgba(227, 230, 236, 1)',
                  color: 'rgba(0, 0, 0, 0.87)',
                }}
              >
                <SpokeIcon style={{ height: '16px', width: '16px' }} />
                <FdTypography
                  variant="captiontext1"
                  style={{ fontWeight: 500 }}
                  color="rgba(0, 0, 0, 0.87)"
                >
                  {squadName}
                </FdTypography>
              </Box>
            </FdTooltip>
          )}
        </Box>
      </Box>
    </FdSkeleton>
  );
};

SquadTag.propTypes = {
  showLabel: PropTypes.bool,
};

SquadTag.defaultProps = {
  showLabel: false,
};

export default SquadTag;
