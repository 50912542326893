import React, { useState } from 'react';
import { Box, Grid } from '@mui/material';
import { gql, useQuery } from '@apollo/client';
import { Link as RouterLink, useParams } from 'react-router-dom';
import * as singleSpa from 'single-spa';
import {
  BasePage,
  FdLoadingSpinner,
  useSnapshot,
  globalStore,
} from '@fifthdomain/fe-shared';
import { FdBreadcrumbHeader } from '@fifthdomain/sidebar';
import AssessmentRules from './AssessmentRules';
import AssessmentSummary from '../components/Participant/AssessmentSummary';
import useStopAssessment from '../hooks/useStopAssessment';
import useGetAssessmentsTasks from '../hooks/useGetAssessmentsTasks';
import {
  onCreateNewTaskAttempt,
  onUpdateAssessment,
} from '../graphql/subscriptions';
import { getCompetitionStatus } from '../shared/utils/getParticipantStatus';
import { getSystemTime, getAssessment } from '../graphql/queries';
import useSubscription from '../hooks/useSubscription';
import useGetScoreboard from '../hooks/useGetScoreboard';

const TrainingOverview = () => {
  const { assessmentId } = useParams();
  const globalSnap = useSnapshot(globalStore);
  const [endDateTime, setEndDateTime] = useState(undefined);
  const { data: assessmentTasksData, loading: assessmentTasksLoading } =
    useGetAssessmentsTasks({ userId: globalSnap?.userId });

  const { data: serverTime, loading: serverTimeLoading } = useQuery(
    gql(getSystemTime),
    {
      fetchPolicy: 'network-only',
    },
  );

  // get the assessment selected
  const assessmentDataFiltered =
    assessmentTasksData.listUserAssessments?.items.find(
      (ad) => ad.id === assessmentId,
    );

  // get Assessment EndDateTime
  const { loading: getAssessmentLoading } = useQuery(gql(getAssessment), {
    variables: {
      id: assessmentDataFiltered?.userAssessmentAssessmentId,
    },
    onCompleted: (data) => {
      setEndDateTime(data?.getAssessment?.endDateTime);
    },
    skip: !assessmentDataFiltered?.userAssessmentAssessmentId,
    fetchPolicy: 'network-only',
  });

  // redirect to home page if not started
  if (
    assessmentDataFiltered?.status &&
    assessmentDataFiltered?.status !== 'STARTED'
  ) {
    singleSpa.navigateToUrl('/landing/landing-homepage');
  }

  const {
    data: listTaskAttemptsScoreboardData,
    loading: listTaskAttemptsScoreboardLoading,
    refetch: refetchScoreboard,
  } = useGetScoreboard({
    assessmentId: assessmentDataFiltered?.assessment?.id,
    teamBased: assessmentDataFiltered?.assessment?.teamBased,
    ...(assessmentDataFiltered?.assessment?.teamBased
      ? { teamId: assessmentDataFiltered?.teamId }
      : { userId: globalSnap?.userId }),
  });

  useSubscription({
    query: gql(onCreateNewTaskAttempt),
    variables: {
      teamId: assessmentDataFiltered?.teamId,
      assessmentId: assessmentDataFiltered?.assessment?.id,
    },
    onData: (_result) => {
      const { success } = _result?.data?.onCreateNewTaskAttempt || {
        success: false,
      };
      if (success) {
        refetchScoreboard();
      }
    },
  });

  const [stopAssessment, { loading: stopAssessmentInProgress }] =
    useStopAssessment(assessmentDataFiltered?.assessment?.participantEventType);

  useSubscription({
    query: gql(onUpdateAssessment),
    variables: {
      filter: {
        id: { eq: assessmentDataFiltered?.userAssessmentAssessmentId },
      },
    },
    onData: (_data) => {
      const _endDateTime = _data?.data?.onUpdateAssessment?.endDateTime;
      setEndDateTime(_endDateTime);

      if (_endDateTime) {
        // call stop user assessment if endDate is present
        // backend will stop the assessment based on server date time
        stopAssessment({
          variables: {
            userAssessmentId: assessmentId,
          },
        });
      }
    },
  });

  if (
    assessmentTasksLoading ||
    stopAssessmentInProgress ||
    listTaskAttemptsScoreboardLoading ||
    serverTimeLoading ||
    getAssessmentLoading
  ) {
    return <FdLoadingSpinner />;
  }

  const {
    assessment: { name, teamBased, startDateTime },
  } = assessmentDataFiltered || { assessment: {} };

  const updatedStatus = getCompetitionStatus(
    startDateTime,
    endDateTime,
    serverTime?.getSystemTime,
  );

  // redirect to homepage only if the competition is in ended state or not started state
  if (['ENDED', 'NOT_STARTED'].includes(updatedStatus)) {
    singleSpa.navigateToUrl('/landing/landing-homepage');
  }

  const scoreboardData =
    listTaskAttemptsScoreboardData?.listScoreboardsByUserId?.items?.[0];
  const tasksCompleted = scoreboardData?.flags || 0;
  const totalPoints = scoreboardData?.points || 0;

  return (
    <Box>
      <FdBreadcrumbHeader
        entries={[
          {
            name,
            path: `/competitions/training-tasks/${assessmentId}`,
            type: 'TRAINING',
          },
        ]}
        page={{ name: 'Overview' }}
      />
      <BasePage heading={name} data-cy="overview-page">
        <Box pb={4}>
          <Box mb={2}>
            <AssessmentSummary
              assessment={{
                tasksCompleted,
                endDateTime,
                totalPoints: totalPoints || 0,
                teamBased,
                teamId: assessmentDataFiltered?.teamId,
                orgId: assessmentDataFiltered?.user?.orgId,
                enableVPN: assessmentDataFiltered?.enableVPN,
              }}
              onFinish={() =>
                stopAssessment({
                  variables: {
                    userAssessmentId: assessmentId,
                  },
                })
              }
            />
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <AssessmentRules
                videoUrl={assessmentDataFiltered?.assessment?.videoUrl}
                userFullName={globalSnap?.userName}
                preMessage={assessmentDataFiltered?.assessment?.preMessage}
                assessmentData={assessmentDataFiltered}
              />
            </Grid>
          </Grid>
        </Box>
      </BasePage>
    </Box>
  );
};

export default TrainingOverview;
