import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Box, TextField, useTheme } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  FdCard,
  FdTypography,
  FdAutocompleteStatic,
  getAvatarColor,
  getAvatarName,
  FdAvatar,
  FdAlert,
} from '@fifthdomain/fe-shared';
import { Summary } from '../../Assessment';
import ProfileHyperlink from '../../Assessment/Insights/ProfileHyperlink';
import { getDateTimeZoneFormatted } from '../../../shared/utils/dateUtils';
import { LineNivo } from '../../Charts';
import {
  filterBySkill,
  getRankingNormalizedRows,
} from '../../Overview/overviewUtils';
import { sortObjectArrayByField } from '../../../shared/utils/objectUtils';
import FDTechGraphs from './FDTechGraphs';
import FDProficiencyApproachTable from './FDProficiencyApproachTable';

const FDTrainingInsights = ({
  attemptsData,
  tasks,
  tasksOpened,
  timeSpentDataForAssessment,
  listUsersData,
  tasksAttemptsAssessmentData,
  defaultInsight,
  startDateTime,
}) => {
  const [selectedUser, setSelectedUser] = useState(null);
  const theme = useTheme();

  const users = useMemo(() => {
    return (
      listUsersData?.listUsersByAssessmentId?.items?.map((u) => ({
        id: u?.userId,
        name: u?.user?.name,
        email: u?.user?.email,
        status: u?.status,
        startedOn: u?.startedOn,
      })) || []
    );
  }, [listUsersData]);

  useEffect(() => {
    if (defaultInsight) {
      const insightUser = users?.find((u) => u?.id === defaultInsight);
      setSelectedUser({ id: insightUser?.id, label: insightUser?.name });
    }
  }, [defaultInsight, users]);

  const submittedData = listUsersData?.listUsersByAssessmentId?.items || [];
  const userSubmittedData = submittedData?.filter(
    (sd) => sd.userId === selectedUser?.id && sd.status === 'FINISHED',
  )[0];
  const selectedUserData = users?.find((u) => u?.id === selectedUser?.id);

  const allTaskAttempts =
    attemptsData?.listTaskAttemptAggregatesByAssessmentId?.items || [];
  const completedTasks = allTaskAttempts?.filter(
    (a) => a.status === 'COMPLETED',
  );
  const challengesSolved =
    completedTasks?.filter((c) => c.userId === selectedUser?.id)?.length || 0;
  const participantsFinished =
    listUsersData?.listUsersByAssessmentId?.items || [];

  const allTaskAttemptsUserWise = allTaskAttempts?.filter(
    (at) => at.userId === selectedUser?.id,
  );
  // attempts detail based on selected user
  const taskAttemptsDetail =
    tasksAttemptsAssessmentData?.filter(
      (ta) => ta.userId === selectedUser?.id,
    ) || [];
  const totalAssessmentPoints = tasks?.items.reduce(
    (acc, i) => acc + (i.task?.recommendedPoints || 0),
    0,
  );
  const totalPointsScoredInAssessment = completedTasks?.reduce(
    (acc, i) => acc + (i?.points || 0),
    0,
  );
  // if team based then populate with teams instead
  const participantsFinishedAssessment = _.flatMap(
    _.groupBy(
      listUsersData?.listUsersByAssessmentId?.items?.map((u) => u?.user) || [],
      'name', // check for duplicates in name
    ),
    (_users) =>
      _users?.length === 1
        ? [{ userId: _users[0]?.id, userName: _users[0]?.name }]
        : _users.map((user, index) => ({
            userId: user?.id,
            userName: `${user?.name} [${index + 1}]`,
          })),
  ).sort(sortObjectArrayByField('userName'));
  const selectedUserIds =
    participantsFinishedAssessment
      ?.filter((u) => u.userName === selectedUserData?.name)
      .map((p) => p?.userId) || [];

  const maxDurationInMins = 0;
  // all skills in the assessment
  const allSkills = _.chain(
    tasks?.items
      ?.map(({ task }) =>
        task?.skills?.items?.map(({ skill }) => ({
          skillId: skill?.id,
          skillName: skill?.name,
          skillAlias: skill?.alias,
        })),
      )
      ?.flat()
      ?.filter((a) => a?.skillId),
  )
    .uniqBy('skillId')
    .sortBy('skillName')
    ?.map((nc) => {
      const tasksCount =
        tasks?.items?.filter((t) =>
          t.task?.skills?.items?.some(({ skill }) => skill?.id === nc?.skillId),
        )?.length || 0;
      return {
        ...nc,
        caption: `${tasksCount} tasks`,
        tasksCount,
      };
    })
    .value();

  const rankingNormalizedRows = getRankingNormalizedRows({
    _participantsFinished: participantsFinished,
    _completedTasks: completedTasks,
    _allTaskAttempts: allTaskAttempts,
    _totalAssessmentPoints: totalAssessmentPoints,
    _maxDurationInMins: maxDurationInMins,
    _timeSpentData: timeSpentDataForAssessment,
    _totalPointsScoredInAssessment: totalPointsScoredInAssessment,
    _teamBased: false,
    _teams: [],
    _teamGroups: [],
    _tasksOpened: tasksOpened,
  });
  const chartCohortRow = {
    id: 'Cohort Average',
    userId: 0,
    data: allSkills?.map((s) => {
      const skillTasks = filterBySkill(tasks?.items, s.skillName);
      const skillTotalAssessmentPoints = skillTasks?.reduce(
        (acc, i) => acc + (i.task?.recommendedPoints || 0),
        0,
      );

      const normalizedRows = getRankingNormalizedRows({
        _participantsFinished: participantsFinished,
        _completedTasks: filterBySkill(completedTasks, s.skillName),
        _allTaskAttempts: filterBySkill(allTaskAttempts, s.skillName),
        _totalAssessmentPoints: skillTotalAssessmentPoints,
        _maxDurationInMins: maxDurationInMins,
        _timeSpentData: timeSpentDataForAssessment,
        _totalPointsScoredInAssessment: totalPointsScoredInAssessment,
        _teamBased: false,
        _teams: [],
        _teamGroups: [],
        _tasksOpened: filterBySkill(tasksOpened, s.skillName),
      });

      return {
        x: s.skillAlias,
        y:
          rankingNormalizedRows && rankingNormalizedRows.length > 0
            ? (normalizedRows?.reduce(
                (acc, i) => acc + (i?.overallScore ?? 0),
                0,
              ) || 0) / rankingNormalizedRows.length
            : 0,
      };
    }),
  };
  const getSkillName = (alias) =>
    allSkills?.find((s) => s.skillAlias === alias)?.skillName || '';

  const chartData = rankingNormalizedRows
    ?.filter((c) => selectedUserIds.includes(c.id))
    .map((c) => {
      return {
        id: c.name,
        userId: c.id,
        data: allSkills?.map((s) => {
          const skillTasks = filterBySkill(tasks?.items, s.skillName);
          const skillTotalAssessmentPoints = skillTasks?.reduce(
            (acc, i) => acc + (i.task?.recommendedPoints || 0),
            0,
          );

          const normalizedRows = getRankingNormalizedRows({
            _participantsFinished: participantsFinished,
            _completedTasks: filterBySkill(completedTasks, s.skillName),
            _allTaskAttempts: filterBySkill(allTaskAttempts, s.skillName),
            _totalAssessmentPoints: skillTotalAssessmentPoints,
            _maxDurationInMins: maxDurationInMins,
            _timeSpentData: timeSpentDataForAssessment,
            _totalPointsScoredInAssessment: totalPointsScoredInAssessment,
            _teamBased: false,
            _teams: [],
            _teamGroups: [],
            _tasksOpened: filterBySkill(tasksOpened, s.skillName),
          });

          return {
            x: s.skillAlias,
            y: normalizedRows?.find((nr) => nr?.id === c.id)?.overallScore || 0,
          };
        }),
        rank: c.rank,
      };
    });
  const chartDataRows = [chartCohortRow, ...chartData];

  return (
    <Box>
      <FdAutocompleteStatic
        options={users?.map((u) => ({
          label: u?.name,
          id: u?.id,
        }))}
        sx={{ width: '400px' }}
        onChange={(__, _value) => {
          setSelectedUser(_value);
        }}
        value={selectedUser}
        renderOption={(props, option) => {
          // eslint-disable-next-line react/prop-types
          const { key, ...optionProps } = props;
          const { label } = option;
          return (
            <Box key={key} component="li" {...optionProps}>
              <FdAvatar
                color={getAvatarColor(label)}
                content={getAvatarName(label)}
                size="small"
              />
              <span className="ml-2">{option.label}</span>
            </Box>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Select Participant"
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <Box mr={1}>
                  {selectedUser && (
                    <FdAvatar
                      color={getAvatarColor(selectedUser?.label)}
                      content={getAvatarName(selectedUser?.label)}
                      size="small"
                    />
                  )}
                </Box>
              ),
            }}
          />
        )}
        data-cy="filer-event-type"
        className="absolute top-[85px] right-4"
      />
      <FdCard className="my-1" variant="outlined">
        {selectedUser ? (
          <Box>
            <Summary
              data={[
                {
                  value: (
                    <ProfileHyperlink userId={selectedUser?.id}>
                      <FdTypography variant="subtitle1">
                        {selectedUserData?.name}
                      </FdTypography>
                    </ProfileHyperlink>
                  ),
                  description: selectedUserData?.email || '',
                },
                {
                  value:
                    selectedUserData?.startedOn &&
                    getDateTimeZoneFormatted(selectedUserData?.startedOn),
                  description: 'Started',
                },
                {
                  value: `${challengesSolved}/${tasks?.items?.length}`,
                  description: 'Challenges Solved',
                },
              ]}
              titleVariant="subtitle1"
              subtitleVariant="body2"
            />
            <Box mt={2}>
              <FdCard variant="outlined">
                <FdTypography variant="h3">Performance in Skills</FdTypography>
                <FdTypography variant="body2" color="secondary">
                  The line graph below shows the user&apos;s performance in the
                  skills covered in this training. Performance is measured by
                  the Success Score, based on challenges successfully solved
                  within each skill. The blue line represents the
                  candidate&apos;s performance, while the dashed line shows the
                  cohort&apos;s average. Hover over the nodes to compare the
                  candidate&apos;s scores with the cohort average.
                </FdTypography>
                <Box height="271px" width="100%">
                  <LineNivo
                    data={chartDataRows}
                    colors={['#757575', theme?.palette?.primary?.main]}
                    showDashedForId="Cohort Average"
                    // eslint-disable-next-line react/no-unstable-nested-components
                    tooltip={(d) => {
                      const { serieId, data: _data } = d.point;
                      const skillName = getSkillName(_data?.x);
                      return (
                        <Box
                          p={1}
                          style={{
                            backgroundColor: '#6E6E6E',
                            borderRadius: '4px',
                          }}
                        >
                          <FdTypography
                            variant="captiontext1"
                            color="contrastText"
                          >
                            {serieId}
                          </FdTypography>
                          <Box mt={0.5}>
                            <FdTypography
                              variant="captiontext2"
                              color="contrastText"
                            >
                              {`${skillName}(${_data?.x}): ${Math.round(
                                _data?.yFormatted,
                              )}`}
                            </FdTypography>
                          </Box>
                        </Box>
                      );
                    }}
                  />
                </Box>
              </FdCard>
              <FDTechGraphs
                tasks={tasks}
                completedTasks={completedTasks}
                selectedUserIds={selectedUserIds}
                graphHeight="350px"
              />
              <FdCard variant="outlined">
                <Box>
                  <FdTypography variant="h3">Challenge Approach</FdTypography>
                  <FdTypography variant="body2" color="secondary">
                    The table below outlines the participant&apos;s approach to
                    this training, detailing when they viewed, entered,
                    attempted (with attempt counts), and solved each challenge.
                    It also shows their time allocation, offering a clear view
                    of their strategy and progress.
                  </FdTypography>
                </Box>
                <FdAlert
                  customIcon={<InfoOutlinedIcon />}
                  variant="info"
                  message={
                    <FdTypography variant="body2">
                      Click &apos;View&apos; below to see more information about
                      each challenge.
                    </FdTypography>
                  }
                  className="my-4"
                />
                <FDProficiencyApproachTable
                  taskAttempts={allTaskAttemptsUserWise}
                  taskAttemptsDetail={taskAttemptsDetail}
                  startDateTime={userSubmittedData?.startedOn || startDateTime}
                  tasks={tasks}
                  completedTasks={completedTasks}
                  selectedUserIds={selectedUserIds}
                  teamBased={false}
                  tasksOpened={tasksOpened}
                />
              </FdCard>
            </Box>
          </Box>
        ) : (
          <Box
            className="flex flex-col items-center content-center justify-center"
            style={{ height: '70vh' }}
          >
            <FdTypography variant="h3" data-cy="insights-message">
              No participant selected
            </FdTypography>
            <FdTypography variant="body1" color="secondary">
              <i>
                Select a participant at the top right of this page to view their
                Insights
              </i>
            </FdTypography>
          </Box>
        )}
      </FdCard>
    </Box>
  );
};

FDTrainingInsights.propTypes = {
  assessmentId: PropTypes.string.isRequired,
  attemptsData: PropTypes.shape({
    listTaskAttemptAggregatesByAssessmentId: PropTypes.arrayOf(
      PropTypes.shape({}),
    ),
  }).isRequired,
  tasks: PropTypes.shape({ items: PropTypes.arrayOf(PropTypes.shape({})) })
    .isRequired,
  listUsersData: PropTypes.shape({
    listUsersByAssessmentId: PropTypes.shape({
      items: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }).isRequired,
  tasksOpened: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  timeSpentDataForAssessment: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  tasksAttemptsAssessmentData: PropTypes.arrayOf(PropTypes.shape({}))
    .isRequired,
  defaultInsight: PropTypes.string,
  startDateTime: PropTypes.string.isRequired,
};

FDTrainingInsights.defaultProps = {
  defaultInsight: undefined,
};

export default FDTrainingInsights;
