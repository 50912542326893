import { proxy } from 'valtio';

// initial state of events
const initialValues = {
  recentLinks: [],
};

// store definition
const globalCookieStore = proxy({
  ...initialValues,
});

// reset store to its initial state
export const resetGlobalEventStore = () => {
  globalCookieStore.recentLinks = [...initialValues.recentLinks];
};

export default globalCookieStore;
