import React, { useState } from 'react';
import { Box, Stepper, Step, StepLabel } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import * as singleSpa from 'single-spa';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, gql } from '@apollo/client';
import NavigationPrompt from 'react-router-navigation-prompt';
import {
  FdButton,
  BasePage,
  FdModal,
  useSnapshot,
  globalStore,
  useQueryRecursive,
  FdLoadingSpinner,
  successToastMessage,
  warningToastMessage,
} from '@fifthdomain/fe-shared';
import { FdBreadcrumbHeader } from '@fifthdomain/sidebar';
import {
  Details,
  Challenges,
  FinaliseModal,
} from '../components/Template/Training';
import { createTemplate, createTaskTemplate } from '../graphql/mutations';
import { listTemplatesByParticipantEventType } from '../graphql/queries';
import scrollToTop from '../shared/utils/scroll';
import {
  validationSchema,
  initialValues,
} from '../validation-schemas/TrainingTemplate';
import { TEMPLATE_STATUS } from '../constants';

const useStyles = makeStyles()((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  stepper: {
    background: 'none',
    paddingLeft: '5px',
  },
}));

const steps = ['Add Details', 'Select Challenges'];
const CreateTrainingTemplate = () => {
  const globalSnap = useSnapshot(globalStore);
  const [activeStep, setActiveStep] = useState(0);
  const [finalStep, setFinalStep] = useState(false);

  const [createTemplateMutation, { loading: createTemplateLoading }] =
    useMutation(gql(createTemplate));
  const [createTaskTemplateMutation, { loading: createTaskTemplateLoading }] =
    useMutation(gql(createTaskTemplate));

  const { data: listTemplatesData, loading: listTemplatesLoading } =
    useQueryRecursive(gql(listTemplatesByParticipantEventType), {
      variables: {
        participantEventType: 'FDTRAINING',
      },
    });

  const { classes } = useStyles();
  const alltraining =
    listTemplatesData?.listTemplatesByParticipantEventType?.items || [];
  const alltrainingNames = alltraining?.map((s) => s?.name?.toLowerCase());

  const reactHookFormMethods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(
      validationSchema({ trainingNames: alltrainingNames }),
    ),
    mode: 'all',
  });
  const {
    formState: { isDirty },
    reset,
    getValues,
    trigger,
    watch,
  } = reactHookFormMethods;

  const watchTasks = watch('tasks');

  const validatePage = async () => {
    let result;
    switch (activeStep) {
      case 0: {
        const triggerValue = [
          'name',
          'description',
          'trainingStartDate',
          'trainingEndDate',
        ];
        result = await trigger(triggerValue);
        break;
      }
      case 1: {
        result = await trigger(['tasks']);
        break;
      }
      default:
        break;
    }
    return result;
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    scrollToTop();
  };

  const taskIds = watchTasks?.map((t) => t.taskId) || [];
  const onSubmit = async (status) => {
    const { name, description, trainingStartDate, trainingEndDate } =
      getValues();
    await createTemplateMutation({
      variables: {
        input: {
          name,
          description,
          teamBased: true,
          preMessage: '',
          postMessage: '',
          participantEventType: 'FDTRAINING',
          createdBy: globalSnap?.userId,
          status,
          startDateTime: trainingStartDate,
          endDateTime: trainingEndDate,
        },
      },
      onCompleted: async (_data) => {
        const templateId = _data?.createTemplate?.id;
        await Promise.all(
          taskIds?.map(async (item) => {
            await createTaskTemplateMutation({
              variables: {
                input: {
                  templateId,
                  taskId: item,
                },
              },
            });
          }),
        );
        const _toastMessage =
          status === TEMPLATE_STATUS.DRAFT ? 'as draft' : 'and released';
        successToastMessage(`Success! Template saved ${_toastMessage}.`);
        reset();
        // add delay to clear out form
        setTimeout(() => {
          singleSpa.navigateToUrl('/org/template-library');
        }, 500);
      },
    });
  };

  const handleNext = async () => {
    if (await validatePage()) {
      if (activeStep === 1) {
        // onSubmit();
        setFinalStep(true);
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        scrollToTop();
      }
    }
  };

  if (listTemplatesLoading) {
    return <FdLoadingSpinner />;
  }

  const loading = createTaskTemplateLoading || createTemplateLoading;
  return (
    <>
      <FdBreadcrumbHeader
        page={{ name: 'Create Training Template', type: 'TRAINING' }}
      />
      <BasePage
        heading="Create Training Template"
        data-cy="create-template-base-page"
      >
        <Box mt={2} mb={2.5} width="834px">
          <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        <FormProvider {...reactHookFormMethods}>
          <form>
            {
              {
                0: <Details />,
                1: (
                  <Challenges
                    templateEditButton={false}
                    templateMode="create"
                  />
                ),
              }[activeStep]
            }
            <Box className="flex justify-between mt-4">
              <FdButton
                size="large"
                variant="secondary"
                onClick={() => {
                  if (isDirty) {
                    warningToastMessage('Changes to Templates are not saved');
                  }
                  reset();
                  singleSpa.navigateToUrl('/org/template-library');
                }}
              >
                Cancel
              </FdButton>
              <Box display="flex">
                {activeStep === 1 && (
                  <Box mr={2}>
                    <FdButton
                      size="large"
                      variant="tertiary"
                      onClick={handleBack}
                    >
                      Back
                    </FdButton>
                  </Box>
                )}
                <FdButton size="large" onClick={handleNext}>
                  {activeStep === (steps?.length ?? 0) - 1
                    ? 'Finalise'
                    : 'Next'}
                </FdButton>
              </Box>
            </Box>
            <FinaliseModal
              finalStep={finalStep}
              loading={loading}
              showCloseAction={() => setFinalStep(false)}
              onConfirm={() => {
                const status = TEMPLATE_STATUS.RELEASED;
                onSubmit(status);
              }}
              onDismiss={() => {
                const status = TEMPLATE_STATUS.DRAFT;
                onSubmit(status);
              }}
            />
          </form>
        </FormProvider>
        <NavigationPrompt
          when={isDirty}
          afterConfirm={() =>
            warningToastMessage('Changes to template are not saved')
          }
        >
          {({ onConfirm, onCancel }) => (
            <FdModal
              title="Abandon Template Creation?"
              description="Are you sure that you want to abandon creating this template? Any inputs made during the process will be lost. "
              confirm="Confirm"
              dismiss="Cancel"
              open
              onConfirm={onConfirm}
              onDismiss={onCancel}
            />
          )}
        </NavigationPrompt>
      </BasePage>
    </>
  );
};

export default CreateTrainingTemplate;
