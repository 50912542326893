import React from 'react';
import { useDropzone } from 'react-dropzone';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import ClearIcon from '@mui/icons-material/Clear';
import { FdButton, FdTypography, FdChip } from '@fifthdomain/fe-shared';

const maxSizeInBytes = 2 * 1024 * 1024; // 2MB

function fileSizeValidator(file) {
  if (file.size > maxSizeInBytes) {
    return {
      code: 'too-large',
      message: `The image '${file.name}' is too large. It's ${file.size} bytes, which exceeds the 2MB limit. Please remove this image and upload a new one that is smaller than 2MB in size.`,
    };
  }
  return null;
}

const ClearIconButton = () => <ClearIcon />;

const FdImageUpload = ({ onDrop, onDropRejected, fileSelected, onDelete }) => {
  const { getRootProps, open, getInputProps, fileRejections } = useDropzone({
    onDrop,
    noClick: true,
    accept: {
      'image/png': ['.png'],
      'image/jpg': ['.jpg'],
      'image/jpeg': ['.jpeg'],
    },
    multiple: false,
    validator: fileSizeValidator,
    onDropRejected,
  });
  const onClickEvent = open;

  return (
    <Box>
      <Box {...getRootProps()} className="flex align-center">
        <input {...getInputProps()} />
        <Box className="flex items-center space-x-3">
          <FdButton
            onClick={onClickEvent}
            data-cy="upload-btn"
            disabled={fileSelected}
            aria-label="add-image"
            size="small"
          >
            Upload Image
          </FdButton>
          {!fileSelected && (
            <FdTypography variant="captiontext1">No file selected</FdTypography>
          )}
        </Box>
        {fileSelected && (
          <FdChip
            label={fileSelected?.name}
            style={{
              marginLeft: '0.5rem',
              fontSize: '12px',
              height: '27px',
            }}
            icon={ClearIconButton}
            onDelete={onDelete}
          />
        )}
      </Box>
      {fileSelected &&
        fileRejections?.map(({ _file, errors }) => (
          <Box mt={1}>
            {errors.map((error) => (
              <FdTypography
                variant="caption"
                key={error?.code}
                style={{ color: 'red' }}
              >
                {error?.message}
              </FdTypography>
            ))}
          </Box>
        ))}
    </Box>
  );
};

FdImageUpload.propTypes = {
  onDrop: PropTypes.func.isRequired,
  onDropRejected: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  fileSelected: PropTypes.objectOf(PropTypes.shape({})).isRequired,
};

export default FdImageUpload;
