import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import {
  FdTable,
  FdModal,
  FdTypography,
  warningToastMessage,
} from '@fifthdomain/fe-shared';
import * as singleSpa from 'single-spa';
import { useHistory } from 'react-router-dom';

const QuizTable = ({ rows = [], onDeleteQuiz, isAdmin }) => {
  const history = useHistory();
  const [quizToDelete, setQuizToDelete] = useState(undefined);

  const columns = [
    { field: 'name', width: 250, headerName: 'Name' },
    ...(isAdmin
      ? [{ field: 'creator', width: 250, headerName: 'Created By' }]
      : []),
    { field: 'attempts', width: 250, headerName: 'No. of Attempts' },
    {
      field: 'course',
      width: 250,
      headerName: 'No. of Courses',
    },
    {
      field: 'createdAt',
      width: 250,
      headerName: 'Date Created',
    },
  ];

  const actions = [
    {
      label: 'View',
      show: () => true,
      onClick: ({ id }) => {
        singleSpa.navigateToUrl(`/labs/quizzes/view/${id}`);
      },
    },
    {
      label: 'Delete',
      show: ({ attempts }) => !attempts,
      onClick: (row) => setQuizToDelete(row),
    },
    {
      label: 'hide',
      show: () => false,
      onClick: () => {},
    },
  ];

  return (
    <Box height="715px">
      <FdTable
        toolbarSettings={{
          title: 'Quizzes',
          filterButton: true,
          searchBox: true,
          headerActions: [
            {
              label: 'Create Quiz',
              onClick: () => history.push('/labs/quizzes/create'),
            },
          ],
        }}
        rows={rows}
        columns={columns}
        tablePageSize={10}
        actions={actions}
        gridId="labs-admin-quizzes-table"
      />
      <FdModal
        size="md"
        title={
          <Box display="flex" alignItems="center">
            <WarningIcon
              style={{
                fontSize: 38,
                color: '#C62828',
                paddingRight: '0.5rem',
              }}
            />
            <span>Delete Quiz?</span>
          </Box>
        }
        description="Are you sure you want to delete the Quiz?"
        confirm="OK"
        dismiss="CANCEL"
        open={quizToDelete}
        onConfirm={() => {
          onDeleteQuiz(quizToDelete?.id);
          setQuizToDelete(undefined);
        }}
        onDismiss={() => {
          setQuizToDelete(undefined);
          warningToastMessage('Quiz not deleted');
        }}
      >
        <Box mt={2}>
          <FdTypography variant="body1" color="secondary">
            The quiz will be permanently removed from the platform, and from any
            courses.
          </FdTypography>
        </Box>
      </FdModal>
    </Box>
  );
};

QuizTable.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ).isRequired,
  onDeleteQuiz: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

export default QuizTable;
