import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import {
  Box,
  Drawer,
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Divider,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from 'tss-react/mui';
import {
  FdTypography,
  FdExternalLink,
  FdCheckbox,
  FdButton,
  PERMISSIONS_AFFILIATED,
  USER_PERMISSIONS_EXPLAINER,
  PERMISSIONS_AFFILIATED_DISPLAY,
  useSnapshot,
  globalStore,
  FdLoadingSpinner,
  Authorization,
  errorToastMessage,
  successToastMessage,
} from '@fifthdomain/fe-shared';
import {
  getCommaSeparated,
  snakeCaseToTitleCase,
  titleCaseToSnakeCase,
} from '../../shared/utils/stringUtils';
import { arrayToObject } from '../../shared/utils/objectUtils';
import { updateRoleAndPermissions } from '../../graphql/mutations';

const drawerWidth = 400;
const useStyles = makeStyles()(() => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    '& .see-more': {
      color: '#1976D2',
      display: 'block',
      paddingTop: '0.5rem',
      textDecoration: 'none',
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
}));

const GroupedItems = ({ title, items }) => (
  <Box my={2}>
    <Divider className="mb-1" />
    <Box className="flex flex-col my-2">
      <FdTypography variant="subtitle1">{title}</FdTypography>
      <FdTypography>{getCommaSeparated(items, true)}</FdTypography>
    </Box>
  </Box>
);

GroupedItems.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const UserDrawer = ({
  user,
  openDrawer,
  setOpenDrawer,
  viewOnly,
  refetchQueries,
}) => {
  const [userPermissions, setUserPermissions] = useState([]);
  const { classes } = useStyles();
  const globalSnap = useSnapshot(globalStore);

  const handleOpenDrawer = (drawerState) => {
    setOpenDrawer(drawerState);
  };

  // clear margins on exit
  useEffect(() => {
    handleOpenDrawer(openDrawer);
    setUserPermissions(
      user?.permissions?.map((p) =>
        titleCaseToSnakeCase(
          PERMISSIONS_AFFILIATED[
            titleCaseToSnakeCase(p === 'Create Content' ? 'CREATE' : p)
          ],
        ),
      ),
    );
  }, [openDrawer]);

  const [
    updateRoleAndPermissionsMutation,
    { loading: updateRoleAndPermissionsLoading },
  ] = useMutation(gql(updateRoleAndPermissions), {
    onCompleted: (_data) => {
      successToastMessage('Success! User permissions updated');
      setOpenDrawer(false);
      refetchQueries();
    },
    onError: ({ graphQLErrors }) => {
      errorToastMessage(graphQLErrors[0]?.message);
    },
  });

  const onClickSave = () => {
    updateRoleAndPermissionsMutation({
      variables: {
        emails: [user?.email],
        permissions: userPermissions?.filter(
          (p) => p !== null && p !== undefined,
        ),
      },
    });
  };

  return (
    <Drawer
      variant="persistent"
      anchor="right"
      open={openDrawer}
      className={classes.drawer}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <Card style={{ overflow: 'auto', height: '100%' }}>
        <CardHeader
          action={
            <IconButton
              onClick={() => {
                handleOpenDrawer(false);
              }}
              size="large"
            >
              <CloseIcon style={{ fontSize: 28 }} />
            </IconButton>
          }
          title={
            <Box
              width={350}
              style={{
                width: '319px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {user?.name || user?.email}
            </Box>
          }
        />
        <CardContent style={{ height: '867px' }}>
          <Box
            className="flex flex-col h-full"
            style={{
              justifyContent: viewOnly ? 'flex-start' : 'space-between',
            }}
          >
            {!viewOnly && (
              <Box>
                <FdTypography variant="subtitle1">Permissions</FdTypography>
                <FdTypography variant="captiontext1" color="secondary">
                  You can explore
                  <FdExternalLink href={USER_PERMISSIONS_EXPLAINER} noUnderline>
                    User Permissions Explainer
                  </FdExternalLink>
                  for in-depth insights on permissions on FifthDomain platform.
                </FdTypography>
                <Box mt={1}>
                  <FdCheckbox
                    onChange={setUserPermissions}
                    name="Permissions"
                    options={arrayToObject(
                      Object.keys(PERMISSIONS_AFFILIATED_DISPLAY).map(
                        (key) => ({
                          [PERMISSIONS_AFFILIATED_DISPLAY[key]]:
                            snakeCaseToTitleCase(
                              PERMISSIONS_AFFILIATED_DISPLAY[key] === 'CREATE'
                                ? 'Create Content'
                                : PERMISSIONS_AFFILIATED_DISPLAY[key],
                            ),
                        }),
                      ) || [],
                    )}
                    disabled={
                      user?.email === globalSnap.userEmail ||
                      Authorization?.isAccountOwner(user)
                    }
                    multiple
                    defaultSelected={userPermissions}
                  />
                </Box>
              </Box>
            )}
            <Box
              height={viewOnly ? '807px' : '205px'}
              style={{ overflowY: 'auto', marginTop: '0.5rem' }}
            >
              {user?.groups?.length > 0 && (
                <GroupedItems
                  title="Groups"
                  items={user?.groups?.map((g) => g?.name)}
                />
              )}
              {user?.assignedEvents?.competitions?.length > 0 && (
                <GroupedItems
                  title="Competitions"
                  items={user?.assignedEvents?.competitions?.map(
                    (g) => g?.name,
                  )}
                />
              )}
              {user?.assignedEvents?.assessments?.length > 0 && (
                <GroupedItems
                  title="Assessments"
                  items={user?.assignedEvents?.assessments?.map((g) => g?.name)}
                />
              )}
              {user?.assignedEvents?.courses?.length > 0 && (
                <GroupedItems
                  title="Courses"
                  items={user?.assignedEvents?.courses?.map((g) => g?.name)}
                />
              )}
            </Box>
            {user?.email !== globalSnap.userEmail &&
              !viewOnly &&
              !Authorization?.isAccountOwner(user) && (
                <>
                  <Divider />
                  <Box className="flex gap-x-3 mt-4">
                    <FdButton
                      onClick={onClickSave}
                      disabled={updateRoleAndPermissionsLoading}
                    >
                      {updateRoleAndPermissionsLoading ? (
                        <FdLoadingSpinner />
                      ) : (
                        <span>Save</span>
                      )}
                    </FdButton>
                    <FdButton
                      variant="secondary"
                      onClick={() => {
                        handleOpenDrawer(false);
                      }}
                    >
                      Cancel
                    </FdButton>
                  </Box>
                </>
              )}
          </Box>
        </CardContent>
      </Card>
    </Drawer>
  );
};

UserDrawer.defaultProps = {
  viewOnly: false,
};

UserDrawer.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string,
    name: PropTypes.string,
    permissions: PropTypes.arrayOf(PropTypes.string),
    assignedEvents: PropTypes.shape({
      assessments: PropTypes.arrayOf(PropTypes.shape({})),
      courses: PropTypes.arrayOf(PropTypes.shape({})),
      competitions: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    groups: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  openDrawer: PropTypes.bool.isRequired,
  setOpenDrawer: PropTypes.func.isRequired,
  viewOnly: PropTypes.bool,
  refetchQueries: PropTypes.func.isRequired,
};

export default UserDrawer;
