import React, { useState } from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import {
  FdTypography,
  FdCard,
  FdTable,
  FdSkeleton,
  FdModal,
  FdChip,
  useQueryRecursive,
  useSnapshot,
  globalStore,
  successToastMessage,
  warningToastMessage,
} from '@fifthdomain/fe-shared';
import {
  listTagsByAssessmentId,
  listTeamsByEventId,
  listUserEventTagsByAssessmentId,
  listTeamEventTagsByAssessmentId,
} from '../../graphql/queries';
import {
  createEventTag,
  updateEventTag,
  deleteEventTag,
} from '../../graphql/mutations';
import EventTagSummary from './EventTagSummary';
import CreateEventTag from './CreateEventTag';
import { initialValues } from '../../validation-schemas/TagDirectory';
import { getTagColor } from '../../shared/utils/tagUtils';
import { sortedByDate } from '../../shared/utils/dateUtils';

const ListEventTags = ({
  teamBased,
  invitedCount,
  status,
  hasManagePermission,
}) => {
  const { assessmentId } = useParams();
  const globalSnap = useSnapshot(globalStore);
  const [openModal, setOpenModal] = useState(false);
  const [editDetail, setEditDetails] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [eventTagId, setEventTagId] = useState();

  const {
    data: teamsInCompetitionData,
    loading: teamsInCompetitionDataLoading,
  } = useQueryRecursive(gql(listTeamsByEventId), {
    variables: {
      eventId: assessmentId,
      filter: {
        status: {
          ne: 'REMOVED',
        },
      },
    },
    skip: !teamBased,
  });

  const teamsInCompetition =
    teamsInCompetitionData?.listTeamsByEventId?.items || [];

  // List all the tags created by this event
  const {
    data: listTagsByAssessmentIdData,
    loading: listTagsByAssessmentIdLoading,
    refetch: refetchlistTagsByAssessmentId,
  } = useQueryRecursive(gql(listTagsByAssessmentId), {
    variables: {
      assessmentId,
      limit: 500,
    },
    staleTime: { seconds: 0 },
    skip: !assessmentId,
  });

  // List the event tags for the assessmentId
  const {
    data: listUserEventTagsByAssessmentIdData,
    loading: listUserEventTagsByAssessmentIdLoading,
  } = useQueryRecursive(gql(listUserEventTagsByAssessmentId), {
    variables: {
      assessmentId,
      limit: 500,
    },
    staleTime: { seconds: 0 },
    skip: !assessmentId,
  });

  // List the event tags for the assessmentId
  const {
    data: listTeamEventTagsByAssessmentIdData,
    loading: listTeamEventTagsByAssesmentIdLoading,
  } = useQueryRecursive(gql(listTeamEventTagsByAssessmentId), {
    variables: {
      assessmentId,
      limit: 500,
    },
    staleTime: { seconds: 0 },
    skip: !assessmentId || !teamBased,
  });

  const eventTagsByAssessmentId = teamBased
    ? listTeamEventTagsByAssessmentIdData?.listTeamEventTagsByAssessmentId
        ?.items
    : listUserEventTagsByAssessmentIdData?.listUserEventTagsByAssessmentId
        ?.items;

  const [createEventTagMutation, { loading: createEventTagLoading }] =
    useMutation(gql(createEventTag));

  const [updateEventTagMutation, { loading: updateEventTagLoading }] =
    useMutation(gql(updateEventTag));

  const [deleteEventTagMutation, { loading: deleteEventTagLoading }] =
    useMutation(gql(deleteEventTag));

  const eventTagData = sortedByDate(
    listTagsByAssessmentIdData?.listTagsByAssessmentId?.items?.map((item) => ({
      ...item,
      tagName: item?.name.trim(),
      tagDescription: item?.description,
      tagColor: item?.color,
    })) || [],
  );

  const actions = [
    {
      label: 'Edit',
      show: () => hasManagePermission && status !== 'Ended',
      onClick: (row) => {
        setOpenModal(true);
        setEditDetails(row);
      },
    },
    {
      label: 'Delete',
      show: () => hasManagePermission && status !== 'Ended',
      onClick: ({ id }) => {
        setEventTagId(id);
        setDeleteModal(true);
      },
    },
    {
      show: () => false,
    },
  ];

  return (
    <Box>
      <EventTagSummary
        teamBased={teamBased}
        invitedCount={teamBased ? teamsInCompetition?.length : invitedCount}
        eventTagData={eventTagData}
        eventTagsByAssessmentId={eventTagsByAssessmentId || []}
        loading={
          teamsInCompetitionDataLoading ||
          listUserEventTagsByAssessmentIdLoading ||
          listTeamEventTagsByAssesmentIdLoading
        }
      />
      <Box mt={2}>
        <FdCard
          heading={<FdTypography variant="h3">Event Tags</FdTypography>}
          subHeading={
            <FdTypography variant="body2" color="secondary">
              Event tags are unique and customisable labels that event
              administrators can create for each competition. These tags can be
              applied by individuals/teams to identify themselves, enabling
              administrators to easily identify and view these tags alongside
              corresponding individuals/teams on the event scoreboard.
            </FdTypography>
          }
        >
          <Box mt={3} mb={2} height="450px">
            <FdSkeleton loading={listTagsByAssessmentIdLoading} height="450px">
              <FdTable
                toolbarSettings={{
                  filterButton: true,
                  searchBox: true,
                  headerActions: hasManagePermission &&
                    status !== 'Ended' && [
                      {
                        label: 'Create Tag',
                        onClick: () => {
                          setOpenModal(true);
                        },
                      },
                    ],
                }}
                rows={eventTagData}
                columns={[
                  {
                    field: 'tagName',
                    width: 300,
                    headerName: 'Event Tag',
                    valueGetter: (params) => params?.value,
                    renderCell: (params) => {
                      return (
                        <FdChip
                          size="small"
                          label={params?.value}
                          style={{
                            backgroundColor: getTagColor(params?.row?.color),
                          }}
                        />
                      );
                    },
                  },
                  {
                    field: 'tagDescription',
                    width: 300,
                    headerName: 'Tag Description',
                  },
                ]}
                actions={actions}
                tablePageSize={5}
                gridId="competition-list-tags"
              />
            </FdSkeleton>
            <CreateEventTag
              openModal={openModal}
              tagDetails={editDetail || initialValues}
              isEdit={editDetail}
              loading={createEventTagLoading || updateEventTagLoading}
              onConfirm={async (
                { tagName, tagDescription, tagColor, id },
                reset,
              ) => {
                const tagEventType = id
                  ? updateEventTagMutation
                  : createEventTagMutation;
                const tagEventParams = id ? { id } : { id: undefined };
                const previousValue = editDetail?.tagName?.toLowerCase();

                const duplicateTagName = eventTagData
                  ?.map((tag) => tag?.tagName.toLowerCase())
                  ?.includes(tagName.toLowerCase());
                if (previousValue !== tagName.toLowerCase()) {
                  if (duplicateTagName) {
                    warningToastMessage(
                      'This tag name has already been used for this event. Please choose another name for this tag',
                    );
                    return;
                  }
                }
                // // create/update tag events
                await tagEventType({
                  variables: {
                    eventTagInput: {
                      color: tagColor || 'YELLOW',
                      name: tagName,
                      description: tagDescription,
                      orgId: globalSnap?.orgId,
                      assessmentId,
                      ...tagEventParams,
                    },
                  },
                  onCompleted: ({ createOrgEventTag }) => {
                    if (createOrgEventTag?.includes('400')) {
                      warningToastMessage(
                        'This tag name has already been used for this event. Please choose another name for this tag',
                      );
                      return;
                    }
                    refetchlistTagsByAssessmentId();
                    reset();
                    setOpenModal(false);
                    setEditDetails(false);
                    successToastMessage(
                      `Success! Tag ${editDetail ? 'updated' : 'created'}`,
                    );
                  },
                });
              }}
              onDismiss={() => {
                setOpenModal(false);
                setEditDetails(false);
                warningToastMessage(
                  `Tag not ${editDetail ? 'updated' : 'created'}`,
                );
              }}
            />
            <FdModal
              size="sm"
              title="Delete this event tag?"
              description={
                <>
                  <FdTypography variant="body1">
                    Are you sure you want to delete this event tag?
                  </FdTypography>
                  <FdTypography variant="body1">
                    Deleting this tag will remove it from all individuals/teams
                    currently using it. This tag will also no longer be visible
                    on the scoreboard for this event.
                  </FdTypography>
                </>
              }
              confirm={deleteEventTagLoading ? 'Loading' : 'CONFIRM'}
              dismiss="CANCEL"
              open={deleteModal}
              onConfirm={async () => {
                await deleteEventTagMutation({
                  variables: {
                    eventTagId,
                    teamBased,
                  },
                });
                refetchlistTagsByAssessmentId();
                setDeleteModal(false);
                successToastMessage('Success! Tag deleted');
              }}
              onDismiss={() => {
                setDeleteModal(false);
                warningToastMessage('Tag not deleted');
              }}
              data-cy="open-modal"
            />
          </Box>
        </FdCard>
      </Box>
    </Box>
  );
};

ListEventTags.propTypes = {
  status: PropTypes.string.isRequired,
  teamBased: PropTypes.bool.isRequired,
  invitedCount: PropTypes.number,
  hasManagePermission: PropTypes.bool.isRequired,
};

ListEventTags.defaultProps = {
  invitedCount: 0,
};

export default ListEventTags;
