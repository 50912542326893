import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, useTheme } from '@mui/material';
import { useQuery, gql } from '@apollo/client';
import { FdBreadcrumbHeader } from '@fifthdomain/sidebar';
import {
  FdLoadingSpinner,
  FdTab,
  FdTypography,
  FdChip,
  FdButton,
  FdAlert,
  useQueryRecursive,
  FdTooltip,
} from '@fifthdomain/fe-shared';
import {
  Challenges,
  Details,
  ReleaseModal,
} from '../components/Template/Training';
import {
  getTemplate,
  listTemplatesByParticipantEventType,
} from '../graphql/queries';
import {
  initialValues,
  validationSchema,
} from '../validation-schemas/TrainingTemplate';
import {
  getTemplateStatusColor,
  getAvailabilityColor,
} from '../shared/utils/getStatusColor';
import { capitalize } from '../shared/utils/stringUtils';
import {
  getTemplateAlertMsg,
  getTemplateAvailabilityStatus,
} from '../shared/utils/templateUtils';
import { TEMPLATE_STATUS, AVAILABILITY_STATUS } from '../constants';

const ViewTrainingTemplate = () => {
  const { templateId } = useParams();
  const [releasedStatus, setReleasedStatus] = useState(false);

  const theme = useTheme();

  const { data: listTemplatesData, loading: listTemplatesLoading } =
    useQueryRecursive(gql(listTemplatesByParticipantEventType), {
      variables: {
        participantEventType: 'FDTRAINING',
      },
    });
  const alltraining =
    listTemplatesData?.listTemplatesByParticipantEventType?.items || [];
  const alltrainingNames = alltraining?.map((s) => s?.name?.toLowerCase());

  const {
    data: templateDetails,
    loading: templateDataLoading,
    refetch: refetchTemplateData,
  } = useQuery(gql(getTemplate), {
    variables: {
      id: templateId,
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      // eslint-disable-next-line no-use-before-define
      if (!isDirty) {
        // eslint-disable-next-line no-use-before-define
        reset({
          ...data?.getTemplate,
          trainingStartDate: data.getTemplate?.startDateTime,
          trainingEndDate: data.getTemplate?.endDateTime,
          tasks: data.getTemplate?.tasks.items?.map((task) => ({
            taskId: task?.taskId,
          })),
          taskTemplates: data.getTemplate?.tasks.items?.map((task) => ({
            taskId: task?.taskId,
            tasktemplateId: task?.id,
            templateId: task?.templateId,
          })),
        });
      }
    },
  });

  const reactHookFormMethods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(
      validationSchema({
        trainingNames: alltrainingNames,
        existingTrainingName: templateDetails?.getTemplate?.name,
      }),
    ),
    mode: 'all',
  });
  const {
    reset,
    getValues,
    formState: { isDirty },
  } = reactHookFormMethods;

  if (templateDataLoading || listTemplatesLoading) {
    return <FdLoadingSpinner />;
  }
  const heading = `View Training ${getValues('name')}`;
  const availabilityStatus = getTemplateAvailabilityStatus(
    templateDetails?.getTemplate?.startDateTime,
    templateDetails?.getTemplate?.endDateTime,
  );
  const templateStatus = templateDetails?.getTemplate?.status;
  const templateEditButton =
    templateStatus === TEMPLATE_STATUS.DRAFT ||
    (templateStatus === TEMPLATE_STATUS.RELEASED &&
      availabilityStatus === AVAILABILITY_STATUS.Upcoming);
  const isReleaseButtonDisabled =
    availabilityStatus === AVAILABILITY_STATUS.Ended;

  return (
    <Box>
      <FdBreadcrumbHeader
        entries={[
          {
            name: 'Template Library',
            path: '/org/template-library',
            type: 'TEMPLATE',
          },
        ]}
        page={{ name: heading, type: 'TEMPLATE' }}
      />
      <Box className="flex justify-between  mb-6">
        <Box className="flex">
          <FdTypography variant="h2">{heading}</FdTypography>
          <Box className="flex ml-4 mt-2">
            <FdChip
              color={getTemplateStatusColor(templateStatus)}
              size="small"
              label={capitalize(templateStatus)}
            />
            <FdChip
              color={getAvailabilityColor(availabilityStatus)}
              size="small"
              label={availabilityStatus}
              style={{
                marginLeft: '8px',
              }}
            />
          </Box>
        </Box>
        {templateStatus === TEMPLATE_STATUS.DRAFT && (
          <FdTooltip
            title={
              isReleaseButtonDisabled
                ? "The template's availability period has ended. To release this template, you must reset the availability to a future date."
                : ''
            }
          >
            <span>
              <FdButton
                onClick={() => setReleasedStatus(TEMPLATE_STATUS.RELEASED)}
                style={{
                  backgroundColor:
                    availabilityStatus !== AVAILABILITY_STATUS.Ended &&
                    theme?.fdProColors?.alert?.successDark,
                }}
                disabled={isReleaseButtonDisabled}
              >
                Release
              </FdButton>
            </span>
          </FdTooltip>
        )}
        {availabilityStatus === AVAILABILITY_STATUS.Active &&
          templateStatus === TEMPLATE_STATUS.RELEASED && (
            <FdButton
              onClick={() => setReleasedStatus(TEMPLATE_STATUS.ROLLBACKED)}
              style={{
                backgroundColor: theme?.fdProColors?.alert?.errorDark,
              }}
            >
              Roll back
            </FdButton>
          )}
      </Box>

      <FdAlert
        variant="info"
        message={getTemplateAlertMsg(templateStatus, availabilityStatus)}
      />

      <FormProvider {...reactHookFormMethods}>
        <form>
          <FdTab
            label={[
              {
                label: 'Details',
                tabRoute: `/assessor/view/${templateId}?tabindex=1`,
                index: 3,
                data: (
                  <Details
                    templateEditButton={templateEditButton}
                    showEditButton
                  />
                ),
              },
              {
                label: 'Challenges',
                tabRoute: `/assessor/view/${templateId}?tabindex=2`,
                index: 4,
                data: (
                  <Challenges
                    showEditButton
                    templateEditButton={templateEditButton}
                  />
                ),
              },
            ]}
          />
        </form>
      </FormProvider>
      <ReleaseModal
        openModal={releasedStatus}
        setOpenModal={setReleasedStatus}
        templateDetails={getValues()}
        onConfirm={async () => {
          refetchTemplateData();
        }}
        onDismiss={() => {}}
      />
    </Box>
  );
};

export default ViewTrainingTemplate;
