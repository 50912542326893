import React, { useState } from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { useMutation, gql } from '@apollo/client';
import {
  FdButton,
  FdCard,
  FdTypography as Typography,
  successToastMessage,
  warningToastMessage,
} from '@fifthdomain/fe-shared';
import { formatDuration } from 'date-fns';
import { capitalize } from '../../../shared/utils/stringUtils';
import {
  TemplateType,
  Details,
  Duration,
  ScoreboardVisibility,
  Availability,
  PreMessage,
  PostMessage,
} from '../Create';
import FdTextView from '../Create/FdTextView';
import { updateTemplate } from '../../../graphql/mutations';

const TemplateDetails = () => {
  const { templateType, templateId } = useParams();
  const [editDetails, setEditDetails] = useState(false);
  const [editDuration, setEditDuration] = useState(false);
  const [editPreAssessment, setEditPreAssessment] = useState(false);
  const [editPostAssessment, setEditPostAssessment] = useState(false);

  const { getValues, trigger, reset } = useFormContext();
  const [updateTemplateMutation, { loading: updateTemplateLoading }] =
    useMutation(gql(updateTemplate));
  return (
    <>
      {editDetails ? (
        <FdCard
          variant="outlined"
          heading={
            <Box display="flex" justifyContent="space-between">
              Details
              <Box display="flex" justifyContent="space-between">
                <Box pr={1}>
                  <FdButton
                    variant="secondary"
                    size="small"
                    onClick={() => {
                      reset();
                      setEditDetails(false);
                      warningToastMessage('No changes were saved');
                    }}
                  >
                    CANCEL
                  </FdButton>
                </Box>
                <Box>
                  <FdButton
                    variant="primary"
                    size="small"
                    onClick={async () => {
                      const details = await trigger(['name']);
                      if (details) {
                        const { name, description, story } = getValues();
                        updateTemplateMutation({
                          variables: {
                            input: {
                              id: templateId,
                              name,
                              description,
                              story: story || undefined,
                            },
                          },
                          onCompleted: () =>
                            successToastMessage('Success! Template updated'),
                        });

                        setEditDetails(false);
                      }
                    }}
                  >
                    {updateTemplateLoading ? 'Loading...' : 'Save'}
                  </FdButton>
                </Box>
              </Box>
            </Box>
          }
        >
          <TemplateType templateType={templateType} editMode />
          <Details templateType={templateType} editMode />
        </FdCard>
      ) : (
        <FdCard
          variant="outlined"
          heading={
            <Box display="flex" justifyContent="space-between">
              <Typography variant="h3">Details</Typography>
              <Box>
                <FdButton
                  variant="primary"
                  size="small"
                  onClick={() => setEditDetails(true)}
                >
                  EDIT
                </FdButton>
              </Box>
            </Box>
          }
        >
          <FdTextView
            mt={2}
            label={`${capitalize(templateType)} Type`}
            value={getValues('templateType')?.value}
          />
          <FdTextView mt={2} label="Name" value={getValues('name')} />
          <FdTextView
            mt={2}
            label="Description"
            value={getValues('description')}
            hideOnEmpty
          />
          <FdTextView label="Story" value={getValues('story')} hideOnEmpty />
        </FdCard>
      )}
      <Availability />
      {templateType === 'competition' && <ScoreboardVisibility />}
      {templateType === 'assessment' &&
        (editDuration ? (
          <Duration
            heading={
              <Box display="flex" justifyContent="space-between">
                Duration
                <Box display="flex" justifyContent="space-between">
                  <Box pr={1}>
                    <FdButton
                      variant="secondary"
                      size="small"
                      onClick={() => {
                        reset();
                        setEditDuration(false);
                        warningToastMessage('No changes were saved');
                      }}
                    >
                      CANCEL
                    </FdButton>
                  </Box>
                  <Box>
                    <FdButton
                      variant="primary"
                      size="small"
                      onClick={async () => {
                        const duration = await trigger(['hours', 'minutes']);
                        if (duration) {
                          updateTemplateMutation({
                            variables: {
                              input: {
                                id: templateId,
                                hours: getValues('hours'),
                                minutes: getValues('minutes'),
                              },
                            },
                            onCompleted: successToastMessage(
                              'Success! Template updated',
                            ),
                          });
                          setEditDuration(false);
                        }
                      }}
                    >
                      {updateTemplateLoading ? 'Loading...' : 'Save'}
                    </FdButton>
                  </Box>
                </Box>
              </Box>
            }
          />
        ) : (
          <FdCard
            variant="outlined"
            heading={
              <Box display="flex" justifyContent="space-between">
                <Typography variant="h3">Duration</Typography>
                <Box>
                  <FdButton
                    variant="primary"
                    size="small"
                    onClick={() => setEditDuration(true)}
                  >
                    EDIT
                  </FdButton>
                </Box>
              </Box>
            }
          >
            <Box mt={2}>
              <Typography variant="subtitle1">Duration</Typography>
              <Typography variant="body1" color="secondary">
                {formatDuration({
                  hours: Number(getValues('hours')),
                  minutes: Number(getValues('minutes')),
                })}
              </Typography>
            </Box>
          </FdCard>
        ))}

      {editPreAssessment ? (
        <PreMessage
          templateType={templateType}
          heading={
            <Box display="flex" justifyContent="space-between">
              {`Pre-${templateType} Message`}
              <Box display="flex" justifyContent="space-between">
                <Box pr={1}>
                  <FdButton
                    variant="secondary"
                    size="small"
                    onClick={() => {
                      reset();
                      setEditPreAssessment(false);
                      warningToastMessage('No changes were saved');
                    }}
                  >
                    CANCEL
                  </FdButton>
                </Box>
                <Box>
                  <FdButton
                    variant="primary"
                    size="small"
                    onClick={async () => {
                      const preMessageRes = await trigger('preMessage');

                      if (preMessageRes) {
                        updateTemplateMutation({
                          variables: {
                            input: {
                              id: templateId,
                              preMessage: getValues('preMessage'),
                            },
                          },
                          onCompleted: successToastMessage(
                            'Success! Template updated',
                          ),
                        });
                        setEditPreAssessment(false);
                      }
                    }}
                  >
                    {updateTemplateLoading ? 'Loading...' : 'Save'}
                  </FdButton>
                </Box>
              </Box>
            </Box>
          }
        />
      ) : (
        <FdCard
          variant="outlined"
          heading={
            <Box display="flex" justifyContent="space-between">
              <Typography variant="h3">{`Pre-${templateType} Message`}</Typography>
              <Box>
                <FdButton
                  variant="primary"
                  size="small"
                  onClick={() => setEditPreAssessment(true)}
                >
                  EDIT
                </FdButton>
              </Box>
            </Box>
          }
        >
          <Box mt={2}>
            <Typography variant="body1" color="secondary">
              {getValues('preMessage')}
            </Typography>
          </Box>
        </FdCard>
      )}
      {editPostAssessment ? (
        <PostMessage
          templateType={templateType}
          heading={
            <Box display="flex" justifyContent="space-between">
              {`Post-${templateType} Message`}
              <Box display="flex" justifyContent="space-between">
                <Box pr={1}>
                  <FdButton
                    variant="secondary"
                    size="small"
                    onClick={() => {
                      reset();
                      setEditPostAssessment(false);
                      warningToastMessage('No changes were saved');
                    }}
                  >
                    CANCEL
                  </FdButton>
                </Box>
                <Box>
                  <FdButton
                    variant="primary"
                    size="small"
                    onClick={async () => {
                      const postMessageRes = await trigger('postMessage');

                      if (postMessageRes) {
                        updateTemplateMutation({
                          variables: {
                            input: {
                              id: templateId,
                              postMessage: getValues('postMessage'),
                            },
                          },
                          onCompleted: successToastMessage(
                            'Success! Template updated',
                          ),
                        });
                        setEditPostAssessment(false);
                      }
                    }}
                  >
                    {updateTemplateLoading ? 'Loading...' : 'Save'}
                    Save
                  </FdButton>
                </Box>
              </Box>
            </Box>
          }
        />
      ) : (
        <FdCard
          variant="outlined"
          heading={
            <Box display="flex" justifyContent="space-between">
              <Typography variant="h3">{`Post-${templateType} Message`}</Typography>
              <Box>
                <FdButton
                  variant="primary"
                  size="small"
                  onClick={() => setEditPostAssessment(true)}
                >
                  EDIT
                </FdButton>
              </Box>
            </Box>
          }
        >
          <Box mt={2}>
            <Typography variant="body1" color="secondary">
              {getValues('postMessage')}
            </Typography>
          </Box>
        </FdCard>
      )}
    </>
  );
};

export default TemplateDetails;
