import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { format, parse } from 'date-fns';
import { FdTable, FdTypography, FdTooltip } from '@fifthdomain/fe-shared';
import * as singleSpa from 'single-spa';
import {
  PROFICIENCY,
  PROFICIENCY_LEVELS,
  SPECIALTY_COLOR,
} from '../../constants';
import { getLabelColor } from '../../shared/utils/layout';

const Chip = ({ text, bgColor }) => (
  <Box
    className="flex items-center justify-center h-4 px-2 rounded"
    sx={{ bgcolor: bgColor }}
  >
    <FdTypography variant="captiontext2" color={getLabelColor(bgColor)}>
      {text}
    </FdTypography>
  </Box>
);

Chip.propTypes = {
  text: PropTypes.string.isRequired,
  bgColor: PropTypes.string.isRequired,
};

const FDTrainingTable = ({ rows }) => {
  const actions = [
    {
      label: 'View',
      onClick: ({ id }) => {
        singleSpa.navigateToUrl(`/assessor/fd-training/view/${id}`);
      },
    },
  ];

  return (
    <Box height="452px" data-cy="event-table">
      <FdTable
        elevation={0}
        id="fd-training-table"
        defaultMuiToolbarSettings={{
          showMuiDefaultToolbar: true,
          filterButton: true,
          searchBox: true,
          columnsButton: true,
          densityButton: false,
        }}
        toolbarSettings={{
          filterButton: true,
          searchBox: true,
          columnsButton: true,
        }}
        selection={false}
        rows={rows}
        actions={actions}
        columns={[
          { field: 'name', width: 400, headerName: 'Training Name' },
          {
            field: 'endDateTime',
            width: 180,
            headerName: 'Available Until',
            valueFormatter: (params) =>
              format(
                parse(params.value, 'dd/MM/yyyy HH:mm', new Date()),
                'dd/MM/yyyy',
              ),
          },
          {
            field: 'started',
            width: 100,
            type: 'number',
            headerName: 'Started',
          },
          {
            field: 'completed',
            width: 100,
            type: 'number',
            headerName: 'Completed',
          },
          {
            field: 'specialties',
            width: 300,
            headerName: 'Specialties',
            renderCell: (params) => {
              const specialties = params.value;
              return specialties?.length > 0 ? (
                <FdTooltip title={specialties.join(', ')}>
                  <Box className="flex items-center gap-x-1">
                    <Chip
                      text={specialties[0]}
                      bgColor={SPECIALTY_COLOR[specialties[0]]}
                    />
                    <FdTypography variant="captiontext2">
                      {specialties.length > 1
                        ? `+${specialties.length - 1}`
                        : ''}
                    </FdTypography>
                  </Box>
                </FdTooltip>
              ) : (
                '-'
              );
            },
          },
          {
            field: 'proficiencies',
            width: 300,
            headerName: 'Proficiencies',
            renderCell: (params) => {
              const proficiencies = params.value;
              if (proficiencies?.length === 0) {
                return '-';
              }
              const minProficiency = Math.min(...proficiencies);
              const maxProficiency = Math.max(...proficiencies);
              if (minProficiency === maxProficiency) {
                return (
                  <Chip
                    text={PROFICIENCY_LEVELS[minProficiency]}
                    bgColor={
                      PROFICIENCY[PROFICIENCY_LEVELS[minProficiency]]
                        ?.completeColor
                    }
                  />
                );
              }
              return (
                <Box className="flex items-center gap-x-1">
                  <Chip
                    text={PROFICIENCY_LEVELS[minProficiency]}
                    bgColor={
                      PROFICIENCY[PROFICIENCY_LEVELS[minProficiency]]
                        ?.completeColor
                    }
                  />
                  <FdTypography variant="captiontext2">to</FdTypography>
                  <Chip
                    text={PROFICIENCY_LEVELS[maxProficiency]}
                    bgColor={
                      PROFICIENCY[PROFICIENCY_LEVELS[maxProficiency]]
                        ?.completeColor
                    }
                  />
                </Box>
              );
            },
          },
        ]}
        tablePageSize={5}
        gridId="fd-trainings"
      />
    </Box>
  );
};

FDTrainingTable.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ).isRequired,
};

export default FDTrainingTable;
