import React from 'react';
import { BrowserRouter, Switch, Redirect, Route } from 'react-router-dom';
import Landing from '@fifthdomain/landing';
import Competitions from '@fifthdomain/competitions';
import Assessments from '@fifthdomain/assessor';
import UserManagement from '@fifthdomain/user-management';
import Labs from '@fifthdomain/labs-fe';
import Organisation from '@fifthdomain/organisation';
import Tasks from '@fifthdomain/tasks';

const ChildAppRoutes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/landing" component={Landing} />
        <Route path="/competitions" component={Competitions} />
        <Route path="/assessor" component={Assessments} />
        <Route path="/user-management" component={UserManagement} />
        <Route path="/labs" component={Labs} />
        <Route path="/org" component={Organisation} />
        <Route path="/tasks" component={Tasks} />
        <Redirect to="/" />
      </Switch>
    </BrowserRouter>
  );
};

export default ChildAppRoutes;
