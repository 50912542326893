import React, { useState } from 'react';
import { Box } from '@mui/material';
import { useParams, useHistory } from 'react-router-dom';
import { useQuery, gql, useMutation, useLazyQuery } from '@apollo/client';
import NavigationPrompt from 'react-router-navigation-prompt';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import WarningIcon from '@mui/icons-material/Warning';
import {
  FdTypography,
  FdCard,
  FdProgress,
  BasePage,
  FdButton,
  FdAlert,
  FdTable,
  FdChip,
  FdModal,
} from '@fifthdomain/fe-shared';
import { FdBreadcrumbHeader } from '@fifthdomain/sidebar';
import { getLabPrototype, getVmConsole } from '../graphql/queries';
import { startPrototype, stopPrototype } from '../graphql/mutations';
import { EDIT_LAB_STATUS } from '../constants';

const ViewLab = () => {
  const { labId } = useParams();
  const history = useHistory();
  const [labStatus, setLabStatus] = useState('NOT_STARTED');
  const [exit, setExit] = useState(false);
  const [saveAndExit, setSaveAndExit] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const pollInterval = 2000;

  // Get Lab Prototype
  const {
    loading: getLabPrototypeLoading,
    data: labPrototypeData,
    startPolling,
    stopPolling,
  } = useQuery(gql(getLabPrototype), {
    variables: {
      id: labId,
    },
    onCompleted: (_data) => {
      const status =
        EDIT_LAB_STATUS[_data?.getLabPrototype?.status] || 'NOT_DEFINED'; // lookup from a pre-defined list
      setLabStatus(status);
      // consider EDIT_IN_PROGRESS status as a changed state
      setIsDirty(status === EDIT_LAB_STATUS.EDIT_IN_PROGRESS);
      // if status is either EDIT_IN_PROGRESS or READY then stop polling
      if (
        [EDIT_LAB_STATUS.EDIT_IN_PROGRESS, EDIT_LAB_STATUS.READY].includes(
          status,
        )
      ) {
        stopPolling();
      }
      // start polling if status is in transient state
      if (
        [
          EDIT_LAB_STATUS.IN_BUILD_QUEUE,
          EDIT_LAB_STATUS.BUILD_IN_PROGRESS,
        ].includes(status)
      ) {
        startPolling(pollInterval);
      }
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });

  const [startPrototypeMutation] = useMutation(gql(startPrototype), {
    onCompleted: () => startPolling(pollInterval),
  });

  const [stopPrototypeMutation] = useMutation(gql(stopPrototype), {
    onCompleted: () => {
      setIsDirty(false);
      window.close();
    },
  });

  const [fetchConsole] = useLazyQuery(gql(getVmConsole), {
    skip: labStatus !== EDIT_LAB_STATUS.EDIT_IN_PROGRESS,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const { url } = data.getVmConsole;
      window.open(url, '_blank');
    },
  });

  const onStartLab = () => {
    setLabStatus(EDIT_LAB_STATUS.IN_BUILD_QUEUE);
    startPrototypeMutation({
      variables: { labPrototypeId: labPrototypeData?.getLabPrototype?.id },
    });
  };

  const onStopLab = (save) => {
    setLabStatus(EDIT_LAB_STATUS.STOPPING);
    stopPrototypeMutation({
      variables: {
        labPrototypeId: labPrototypeData?.getLabPrototype?.id,
        savePrototype: save,
      },
    });
  };

  if (
    getLabPrototypeLoading &&
    ![
      EDIT_LAB_STATUS.IN_BUILD_QUEUE,
      EDIT_LAB_STATUS.BUILD_IN_PROGRESS,
    ].includes(labStatus)
  ) {
    return <FdProgress />;
  }

  const { name: labName, description } =
    labPrototypeData?.getLabPrototype || {};

  const columns = [
    {
      field: 'name',
      width: 300,
      headerName: 'Name',
    },
  ];

  const actions = [
    {
      label: 'Connect',
      show: () => true,
      width: 150,
      onClick: (vm) => {
        fetchConsole({
          variables: {
            vmId: vm?.id,
            type: 'PROTOTYPE',
          },
        });
      },
    },
  ];

  return (
    <Box>
      <FdBreadcrumbHeader
        entries={[
          {
            name: 'Manage Labs',
            path: '/labs',
            type: 'LAB',
          },
        ]}
        page={{ name: labName, type: 'LAB' }}
      />
      <BasePage heading="Edit Lab" data-cy="test-lab-base-page">
        <FdCard
          variant="outlined"
          heading={labName}
          summary={
            labStatus === EDIT_LAB_STATUS.EDIT_IN_PROGRESS && (
              <Box display="flex" alignItems="center">
                <FdChip color="success" size="small" label="Edit MODE" />
                <Box ml={2}>
                  <FdButton
                    startIcon={<OpenInNewIcon />}
                    variant="secondary"
                    onClick={() => setExit(true)}
                  >
                    Exit
                  </FdButton>
                </Box>
                <Box ml={2}>
                  <FdButton
                    startIcon={<OpenInNewIcon />}
                    onClick={() => setSaveAndExit(true)}
                  >
                    Save and Exit
                  </FdButton>
                </Box>
              </Box>
            )
          }
        >
          <FdTypography variant="body1">{description}</FdTypography>
          {[EDIT_LAB_STATUS.READY].includes(labStatus) && (
            <Box mt={2}>
              <FdButton size="large" onClick={onStartLab}>
                Start Lab
              </FdButton>
            </Box>
          )}
          {[
            EDIT_LAB_STATUS.IN_BUILD_QUEUE,
            EDIT_LAB_STATUS.BUILD_IN_PROGRESS,
          ].includes(labStatus) && (
            <Box mt={2}>
              <FdAlert
                alertTitle="Your lab is being built. This may take several minutes."
                message="Constructing additional pylons"
                variant="info"
              />
              <Box my={1}>
                <FdProgress />
              </Box>
            </Box>
          )}
        </FdCard>
        {labStatus === EDIT_LAB_STATUS.EDIT_IN_PROGRESS && (
          <Box height="553px">
            <FdTable
              toolbarSettings={{
                title: 'Virtual Machines',
                filterButton: true,
                searchBox: true,
              }}
              rows={
                labPrototypeData?.getLabPrototype?.vms?.items.map((vm) => ({
                  id: vm.id,
                  name: vm.name,
                  labPrototypeId: vm.labPrototypeId,
                })) || []
              }
              columns={columns}
              tablePageSize={5}
              actions={actions}
              gridId="labs-test-lab-vms-grid"
            />
          </Box>
        )}
        <FdModal
          title={
            <Box display="flex" alignItems="center">
              <WarningIcon
                style={{
                  fontSize: 38,
                  color: '#C62828',
                  paddingRight: '0.5rem',
                }}
              />
              <span>Exit Edit Mode</span>
            </Box>
          }
          size="xs"
          description={
            <Box>
              <FdTypography variant="subtitle1">
                Are you sure you want to exit edit mode?
              </FdTypography>
              <Box mt={1}>
                This will delete your edit lab. Any changes made to the lab will
                NOT be saved.
              </Box>
            </Box>
          }
          confirm="Exit Edit mode"
          dismiss="Cancel"
          open={exit}
          onConfirm={() => {
            setExit(false);
            onStopLab(false);
          }}
          onDismiss={() => {
            setExit(false);
          }}
        />
        <FdModal
          title={
            <Box display="flex" alignItems="center">
              <WarningIcon
                style={{
                  fontSize: 38,
                  color: '#C62828',
                  paddingRight: '0.5rem',
                }}
              />
              <span>Save And Exit Edit Mode</span>
            </Box>
          }
          size="xs"
          description={
            <Box>
              <FdTypography variant="subtitle1">
                Are you sure you want to save and exit edit mode?
              </FdTypography>
              <Box mt={1}>
                This will delete your lab. Any changes made to the lab WILL be
                saved.
              </Box>
            </Box>
          }
          confirm="Save And Exit"
          dismiss="Cancel"
          open={saveAndExit}
          onConfirm={() => {
            setSaveAndExit(false);
            onStopLab(true);
          }}
          onDismiss={() => {
            setSaveAndExit(false);
          }}
        />
        <NavigationPrompt
          when={isDirty}
          afterCancel={() => {
            if (window.location.pathname !== `/labs/lab-test/${labId}`) {
              history.goBack();
            }
          }}
          beforeConfirm={() => onStopLab(false)} // stop lab on exit
        >
          {({ onConfirm, onCancel }) => (
            <FdModal
              title="Are you sure you want to leave?"
              description="Your lab will be deleted if you leave this page. Any changes you have made to the lab will NOT be saved. "
              confirm="Stay"
              dismiss="Leave"
              open
              onConfirm={onCancel}
              onDismiss={onConfirm}
              data-cy="leave-modal"
            />
          )}
        </NavigationPrompt>
      </BasePage>
    </Box>
  );
};
export default ViewLab;
