import React, { useState } from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import * as singleSpa from 'single-spa';
import { gql, useQuery } from '@apollo/client';
import {
  BasePage,
  FdLoadingSpinner,
  useSnapshot,
  globalStore,
  useQueryRecursive,
} from '@fifthdomain/fe-shared';
import { FdBreadcrumbHeader } from '@fifthdomain/sidebar';
import useGetAssessmentsTasks from '../hooks/useGetAssessmentsTasks';
import {
  getSystemTime,
  getAssessment,
  listChatForumsByAssessmentId,
} from '../graphql/queries';
import useStopAssessment from '../hooks/useStopAssessment';
import { onUpdateAssessment } from '../graphql/subscriptions';
import { getCompetitionStatus } from '../shared/utils/getParticipantStatus';
import useSubscription from '../hooks/useSubscription';
import FdSystemDateCountdown from '../components/FdSystemDateCountdown';
import ChatForum from '../components/Chats/ChatForum';
import useChatForumSubscription from '../hooks/useChatForumSubscription';

const AssessmentChatForum = () => {
  const { assessmentId } = useParams();
  const globalSnap = useSnapshot(globalStore);
  const [endDateTime, setEndDateTime] = useState(undefined);
  const { data: assessmentTasksData, loading: assessmentTasksLoading } =
    useGetAssessmentsTasks({ userId: globalSnap?.userId });

  const { data: serverTime, loading: serverTimeLoading } = useQuery(
    gql(getSystemTime),
    {
      fetchPolicy: 'network-only',
    },
  );

  // get the assessment selected
  const assessmentDataFiltered =
    assessmentTasksData.listUserAssessments?.items.find(
      (ad) => ad.id === assessmentId,
    );

  // get Assessment EndDateTime
  const { data: assessmentData, loading: getAssessmentLoading } = useQuery(
    gql(getAssessment),
    {
      variables: {
        id: assessmentDataFiltered?.userAssessmentAssessmentId,
      },
      onCompleted: (data) => {
        setEndDateTime(data?.getAssessment?.endDateTime);
      },
      skip: !assessmentDataFiltered?.userAssessmentAssessmentId,
      fetchPolicy: 'network-only',
    },
  );

  const {
    data: chatForumData,
    refetch: refetchChatForumData,
    loading: chatForumLoading,
  } = useQueryRecursive(gql(listChatForumsByAssessmentId), {
    variables: {
      assessmentId: assessmentDataFiltered?.userAssessmentAssessmentId,
    },
    skip: !assessmentDataFiltered?.userAssessmentAssessmentId,
    staleTime: { seconds: 0 },
  });

  const assessment = assessmentData?.getAssessment || {};
  const chatMessages = chatForumData?.listChatForumsByAssessmentId?.items || [];
  const teams = assessment?.teamBased ? assessment?.teams?.items : [];

  const [stopAssessment, { loading: stopAssessmentInProgress }] =
    useStopAssessment(assessmentDataFiltered?.assessment?.participantEventType);

  useSubscription({
    query: gql(onUpdateAssessment),
    variables: {
      filter: {
        id: { eq: assessmentDataFiltered?.userAssessmentAssessmentId },
      },
    },
    onData: (_data) => {
      const _endDateTime = _data?.data?.onUpdateAssessment?.endDateTime;
      setEndDateTime(_endDateTime);

      if (_endDateTime) {
        // call stop user assessment if endDate is present
        // backend will stop the assessment based on server date time
        stopAssessment({
          variables: {
            userAssessmentId: assessmentId,
          },
        });
      }
    },
  });

  // refresh page query on chat forum subscription
  useChatForumSubscription({
    assessmentId: assessmentDataFiltered?.userAssessmentAssessmentId,
    refreshData: refetchChatForumData,
  });

  if (
    assessmentTasksLoading ||
    serverTimeLoading ||
    stopAssessmentInProgress ||
    getAssessmentLoading
  ) {
    return <FdLoadingSpinner />;
  }

  const {
    assessment: { name, startDateTime, guided },
  } = assessmentDataFiltered || { assessment: {} };

  const updatedCompetitionStatus = getCompetitionStatus(
    startDateTime,
    endDateTime,
    serverTime?.getSystemTime,
  );

  // redirect to homepage only if the competition is in not started state
  if (updatedCompetitionStatus === 'NOT_STARTED' || guided) {
    singleSpa.navigateToUrl('/landing/landing-homepage');
  }

  return (
    <Box>
      <FdBreadcrumbHeader
        entries={[
          {
            name,
            type: 'COMPETITION',
            path: `/competitions/competition-tasks/${assessmentId}`,
          },
        ]}
        page={{
          name: 'Chat Forum',
        }}
      />
      <BasePage heading="Chat Forum" data-cy="chat-forum-page">
        {endDateTime && updatedCompetitionStatus !== 'ENDED' && (
          <FdSystemDateCountdown
            endDateTime={endDateTime}
            onFinish={() => {
              stopAssessment({
                variables: {
                  userAssessmentId: assessmentId,
                },
              });
            }}
            renderer={({ completed }) => !completed && <></>}
          />
        )}
        <Box mt={1}>
          <ChatForum
            chatMessages={chatMessages}
            assessmentId={assessmentDataFiltered?.userAssessmentAssessmentId}
            teams={teams}
            loading={chatForumLoading}
          />
        </Box>
      </BasePage>
    </Box>
  );
};

export default AssessmentChatForum;
