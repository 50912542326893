import { useEffect } from 'react';
import { useSnapshot } from 'valtio';
import globalStore from '../../store';

const useInitializeCSCStore = (userId) => {
  const { orgId } = useSnapshot(globalStore);

  useEffect(() => {
    // list of CSC orgs from different envs
    globalStore.isCSCOrg = [
      '8be42aab-5aad-40db-806c-8df2f045bdb9',
      'b05c8663-5a76-4a28-be43-19701043cc87',
      'ef592ed3-3950-42f4-859f-fc4f369799f8', // external
    ].includes(orgId);
  }, [orgId, userId]);
};

export default useInitializeCSCStore;
