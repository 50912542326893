import React, { useState } from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import WarningIcon from '@mui/icons-material/Warning';
import { FdButton, FdModal, FdTypography } from '@fifthdomain/fe-shared';
import { Summary } from '../Assessment';
import { getFormattedDuration } from '../../shared/utils/dateUtils';
import FdSystemDateCountdown from '../FdSystemDateCountdown';
import { upperCaseFirstLetter } from '../../shared/utils/stringUtils';

const AssessmentSummary = ({
  assessment: {
    hours,
    minutes,
    endDateTime,
    tasksCompleted,
    teamBased,
    participantEventType,
  },
  onFinish,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const isFDTraining = participantEventType === 'FDTRAINING';
  const eventType = isFDTraining ? 'training' : 'assessment';

  return (
    <Box>
      <Summary
        data={[
          ...(eventType !== 'training'
            ? [
                {
                  value: 'Duration',
                  description: getFormattedDuration(hours, minutes),
                  icon: (
                    <TimelapseIcon
                      style={{
                        fontSize: 38,
                        backgroundColor: '#FBE9E7',
                        borderRadius: '50%',
                        padding: '0.5rem',
                        color: '#f44336',
                      }}
                    />
                  ),
                },
              ]
            : []),
          {
            value: 'Challenges Completed',
            description: String(tasksCompleted),
            icon: (
              <FlagOutlinedIcon
                style={{
                  fontSize: 38,
                  backgroundColor: '#F3E5F5',
                  borderRadius: '50%',
                  padding: '0.5rem',
                  color: 'rgb(153 22 172)',
                }}
              />
            ),
          },
          {
            value: 'Time Left',
            description: (
              <FdSystemDateCountdown
                endDateTime={endDateTime}
                onFinish={() => {
                  onFinish();
                }}
                daysInHours
              />
            ),
            icon: (
              <TimerOutlinedIcon
                style={{
                  fontSize: 38,
                  backgroundColor: '#E0F2F1',
                  borderRadius: '50%',
                  padding: '0.5rem',
                  color: 'rgb(0 192 181)',
                }}
              />
            ),
          },
          ...(!isFDTraining
            ? [
                {
                  value: (
                    <FdButton
                      size="large"
                      style={{
                        backgroundColor: '#C62828',
                        color: '#FFFFFF',
                        borderColor: '#C62828',
                      }}
                      onClick={() => setModalOpen(true)}
                    >
                      Finish
                    </FdButton>
                  ),
                },
              ]
            : []),
        ]}
        titleVariant="subtitle2"
        subtitleVariant="h3"
        justifyContent="flex-start"
      />
      <FdModal
        title={
          <Box display="flex" alignItems="center">
            <WarningIcon
              style={{
                fontSize: 38,
                color: '#C62828',
                paddingRight: '0.5rem',
              }}
            />
            <span>{`Finish ${upperCaseFirstLetter(eventType)}?`}</span>
          </Box>
        }
        size="xs"
        description={
          <Box>
            <FdTypography variant="subtitle1">
              {`Are you sure you want to finish the ${eventType}?`}
            </FdTypography>
            <Box mt={1}>
              {teamBased
                ? 'This will end the assessment for all members of your team. Your team will not be able to submit any more flags if you finish the assessment.'
                : `You still have more time to complete the ${eventType}. You will not be able to re-access the ${eventType} if you finish it early.`}
            </Box>
          </Box>
        }
        confirm="Cancel"
        dismiss={`Finish ${upperCaseFirstLetter(eventType)}`}
        open={modalOpen}
        onConfirm={() => setModalOpen(false)}
        onDismiss={() => {
          onFinish();
        }}
        setOpen={setModalOpen}
        data-cy="finish-assessment-modal"
      />
    </Box>
  );
};

AssessmentSummary.propTypes = {
  assessment: PropTypes.shape({
    hours: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    minutes: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    endDateTime: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
    tasksCompleted: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    teamBased: PropTypes.bool,
    participantEventType: PropTypes.string,
  }).isRequired,
  onFinish: PropTypes.func.isRequired,
};

export default AssessmentSummary;
