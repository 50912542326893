import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { useMutation, gql } from '@apollo/client';
import _ from 'lodash';
import {
  FdButton,
  FdLoadingSpinner,
  FdAlert,
  useQueryRecursive,
  warningToastMessage,
  successToastMessage,
  errorToastMessage,
} from '@fifthdomain/fe-shared';
import { TemplateOverview, TasksTable, ViewTaskDrawer } from '../Create';
import { listTasks } from '../../../graphql/queries';
import {
  createTaskTemplate,
  deleteTaskTemplate,
} from '../../../graphql/mutations';
import { getDeletingTasks, getNewTasks } from '../../../shared/utils/taskUtils';

const TemplateChallenges = ({
  trainingTemplate,
  columns,
  templateEditButton,
  templateMode,
}) => {
  const { templateId } = useParams();
  const [editTasks, setEditTasks] = useState(false);
  const { errors, watch, getValues, trigger } = useFormContext();
  const watchTasks = [...new Set(watch('tasks'))];
  const [activeTask, setActiveTask] = useState();
  const [openDrawer, setOpenDrawer] = useState(false);
  const drawerWidth = 400;
  const createTemplateMode = templateMode === 'create';

  const [createTaskTemplateMutation, { loading: createTaskTemplateLoading }] =
    useMutation(gql(createTaskTemplate));
  const [deleteTaskTemplateMutation, { loading: deleteTaskTemplateLoading }] =
    useMutation(gql(deleteTaskTemplate));

  const { data: listTasksData, loading: listTasksLoading } = useQueryRecursive(
    gql(listTasks),
    {
      variables: {
        filter: {
          status: { eq: 'APPROVED' },
        },
      },
      staleTime: { hours: 24 },
    },
  );

  const allTasks = _.uniqBy(
    [
      ...(listTasksData?.listTasks?.items?.map((t) => ({
        ...t,
        owned: false,
      })) || []),
    ],
    'id',
  ).filter((_task) => _task.type !== 'CONTAINER');

  // merge task data
  const tasksCombined = getValues('tasks').map((tl) => ({
    ...allTasks.find((at) => at.id === tl.taskId),
    id: tl.taskId,
    templateId: tl?.tasktemplateId,
    taskTemplateId: tl?.tasktemplateId,
  }));

  const tableData = (
    editTasks || createTemplateMode ? allTasks : tasksCombined
  )?.map((t) => ({
    ...t,
    specialtyName: t?.specialty?.name,
    skills: t?.skills?.items.map((s) => s.skill?.name),
    techniqueTags: t?.skills?.items
      .map((s) => s.techniqueTags?.items.map((st) => st.techniqueTag?.name))
      .flat(),
    technologyTags: t?.technologyTags?.items.map(
      (tt) => tt.technologyTag?.name,
    ),
    creator: t.user?.name,
    ownerOrg: t?.org?.name,
    ownerOrgId: t?.org?.id,
    creatorOrg: t?.user?.org?.name,
    creatorOrgId: t?.user?.org?.id,
    labId: t?.labId,
    lab: t?.lab,
    tags: t?.tags?.items || [],
  }));

  const deleteTask = async (_id) => {
    return new Promise((resolve, reject) => {
      deleteTaskTemplateMutation({
        variables: {
          input: {
            id: _id,
          },
        },
        onCompleted: () => {
          resolve();
        },
        onError: () => reject(),
      });
    });
  };
  const updateTask = async (_taskIdValue) => {
    return new Promise((resolve, reject) => {
      createTaskTemplateMutation({
        variables: {
          input: {
            taskId: _taskIdValue,
            templateId,
          },
        },
        onCompleted: () => {
          resolve();
        },
        onError: () => reject(),
      });
    });
  };

  const updateTaskTemplate = async () => {
    const taskIdValues = getValues('tasks');
    if (taskIdValues.length === 0) {
      return;
    }
    const existingTemplateTasks = getValues('taskTemplates');
    const deletePromises = [];
    const updatePromises = [];

    // delete existing tasks that are about to be deleted
    getDeletingTasks(existingTemplateTasks, taskIdValues)?.forEach(
      (taskTemplate) =>
        deletePromises.push(deleteTask(taskTemplate.tasktemplateId)),
    );
    const deleteResult = await Promise.allSettled(deletePromises);
    if (deleteResult.some((p) => p.status === 'rejected')) {
      return;
    }

    // insert new tasks as per form changes
    getNewTasks(existingTemplateTasks, taskIdValues)?.forEach((ta) => {
      updatePromises.push(updateTask(ta.taskId));
    });
    const updateResult = await Promise.allSettled(updatePromises);

    if (updateResult.some((p) => p?.status === 'rejected')) {
      setEditTasks(false);
      errorToastMessage('Update failed');
      return;
    }

    if (updateResult.every((p) => p?.status === 'fulfilled')) {
      setEditTasks(false);
      successToastMessage('Success! Template updated!');
    }
  };

  if (listTasksLoading) {
    return <FdLoadingSpinner />;
  }
  return (
    <>
      <Box>
        <Grid container>
          <Grid item xs>
            {!trainingTemplate && (
              <>
                <TemplateOverview allTasks={tableData} tasks={watchTasks} />
                {editTasks && errors?.tasks && (
                  <Box mt={2}>
                    <FdAlert
                      variant="error"
                      message="Please select challenges(s) for this template"
                    />
                  </Box>
                )}
              </>
            )}
            <TasksTable
              templateMode={templateMode}
              templateEditButton={templateEditButton}
              templateColumns={columns}
              trainingTemplate={trainingTemplate}
              data={tableData}
              editTasks={editTasks}
              setEditTasksCallBack={setEditTasks}
              viewAssessment
              setActiveTask={setActiveTask}
              setOpenDrawer={setOpenDrawer}
              onEditMode
              showEditButton
            />
          </Grid>
          <Grid
            item
            style={{
              width: openDrawer ? drawerWidth : 0,
            }}
          />
        </Grid>
      </Box>
      {activeTask && openDrawer && (
        <ViewTaskDrawer
          editTasks={!editTasks}
          activeTaskData={activeTask}
          openDrawer={openDrawer}
          openDrawerCallBack={setOpenDrawer}
          mainPageIds={['topnav', 'organisation']}
        />
      )}
      {editTasks && (
        <>
          <FdButton
            size="large"
            variant="primary"
            onClick={async () => {
              const result = await trigger(['tasks']);
              if (result) {
                window.scrollTo(0, 0);
                updateTaskTemplate();
              }
            }}
          >
            {deleteTaskTemplateLoading || createTaskTemplateLoading
              ? 'Loading...'
              : 'Save'}
          </FdButton>
          <FdButton
            size="large"
            variant="tertiary"
            onClick={() => {
              setEditTasks(false);
              warningToastMessage('No changes were saved');
            }}
          >
            CANCEL
          </FdButton>
        </>
      )}
    </>
  );
};

TemplateChallenges.defaultProps = {
  columns: [],
  trainingTemplate: false,
  templateEditButton: false,
  templateMode: false,
};

TemplateChallenges.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ),
  templateMode: PropTypes.string,
  templateEditButton: PropTypes.bool,
  trainingTemplate: PropTypes.bool,
};

export default TemplateChallenges;
