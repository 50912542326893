import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Drawer,
  IconButton,
  Card,
  CardHeader,
  CardContent,
  Grid,
  CircularProgress,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import DownloadIcon from '@mui/icons-material/Download';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { makeStyles } from 'tss-react/mui';
import * as singleSpa from 'single-spa';
import { useQuery, gql, useMutation, useLazyQuery } from '@apollo/client';
import * as Yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams, useLocation } from 'react-router-dom';
import { getUrl, uploadData } from 'aws-amplify/storage';
import shortid from 'shortid';
import {
  BasePage,
  FdTypography,
  FdLoadingSpinner,
  FdSelect,
  FdMarkdownRender,
  FdTab,
  FdTooltip,
  FdButton,
  FdCard,
  useQueryRecursive,
  useSnapshot,
  globalStore,
  FdSkeleton,
  FdChip,
  FdExternalLink,
  FdFileAttachment,
  useRecentLinks,
  successToastMessage,
  errorToastMessage,
} from '@fifthdomain/fe-shared';
import { FdBreadcrumbHeader } from '@fifthdomain/sidebar';
import ShowMoreText from 'react-show-more-text';
import TasksTable from '../components/Participant/TasksTable';
import AssessmentSummary from '../components/Participant/AssessmentSummary';
import {
  getUserAssessment,
  listHintReveals,
  listTaskAttemptAggregatesByUserId,
} from '../queries/customQueries';
import FlagSubmission from '../components/Participant/FlagSubmission';
import {
  listTaskNotes,
  listTaskOpenedsByUserAssessmentId,
  listJumpboxInstancesByUserAssessmentId,
  getVmConsole,
  downloadVPNConfigureFile,
} from '../graphql/queries';
import {
  createTaskOpened,
  attempt,
  createTaskNote,
  updateTaskNote,
  updateTaskOpened,
  startJumpbox,
  restartJumpbox,
  updateUserAssessment,
} from '../graphql/mutations';
import useStopAssessment from '../hooks/useStopAssessment';
import {
  getAssessmentFinishDateTime,
  sortByDateField,
} from '../shared/utils/dateUtils';
import createEventsData from '../shared/utils/userEvents';
import { TASK_ACTIVITY } from '../constants';
import NotesSubmission from '../components/Participant/NotesSubmission';
import LabControl from '../components/Participant/LabControl';
import TaskDrawerHeaderParticipant from '../components/Assessment/TaskDrawerHeaderParticipant';
import ChallengeRating from '../components/Participant/ChallengeRating';

const drawerWidth = 400;
const useStyles = makeStyles()(() => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    '& .see-more': {
      color: '#1976D2',
      display: 'block',
      paddingTop: '0.5rem',
      textDecoration: 'none',
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  description: {
    wordWrap: 'break-word',
  },
}));

const ActivitySelector = ({ activity, onActivityChange }) => (
  <Box mt={1} mb={2}>
    <FdSelect
      id="taskActivity"
      defaultSelected={activity}
      options={TASK_ACTIVITY.map((t) => t.desc)}
      onChange={onActivityChange}
      placeholder="Select Activity"
      data-cy="task-activity"
    />
  </Box>
);

ActivitySelector.propTypes = {
  activity: PropTypes.string.isRequired,
  onActivityChange: PropTypes.func.isRequired,
};

const AssessmentTasks = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [assessmentName, setAssessmentName] = useState();
  const [taskAttempts, setTaskAttempts] = useState(undefined);
  const [taskStatus, setTaskStatus] = useState(undefined);
  const [activeTask, setActiveTask] = useState();
  const [activeTaskOpenedId, setActiveTaskOpenedId] = useState('');
  const [lastActiveTaskOpened, setLastActiveTaskOpened] = useState(undefined);
  const [fetchConsoleLoading, setFetchConsoleLoading] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [isJumboxStarting, setIsJumboxStarting] = useState(false);
  const [isJumpboxReady, setIsJumpboxReady] = useState(false);
  const { userId, user } = useSnapshot(globalStore);
  const { search, pathname } = useLocation();
  const { assessmentId } = useParams();
  const [isVPNdownload, setIsVPNdownload] = useState(false);
  const [isVPNCopying, setIsVPNCopying] = useState(false);
  const { addRecentLink } = useRecentLinks({ userId });
  const { classes } = useStyles();

  const { data: assessmentData, loading: assessmentLoading } = useQuery(
    gql(getUserAssessment),
    {
      variables: {
        id: assessmentId,
      },
      onCompleted: (_data) => {
        const _assessmentData = _data?.getUserAssessment?.assessment;
        setAssessmentName(_assessmentData?.name);
        // add recent link
        addRecentLink({
          id: assessmentId,
          name: _assessmentData?.name,
          type: 'TRAINING',
          url: pathname + search,
          role: 'PARTICIPATE',
        });
        createEventsData(userId, _assessmentData?.name, 'VIEW', {
          dimension1Id: assessmentId,
          dimension1Name: 'ASSESSMENT',
        });
      },
      skip: !assessmentId,
      fetchPolicy: 'cache-and-network',
    },
  );

  // get the assessment selected
  const assessmentDataFiltered = assessmentData?.getUserAssessment;

  const {
    assessment: {
      hours,
      minutes,
      tasks,
      showPostAssessmentReflection,
      enableJumpbox,
      enableVPN,
      participantEventType,
      endDateTime,
    },
    id: userAssessmentId,
    startedOn,
  } = assessmentDataFiltered || { assessment: {}, id: {} };
  const isFDTraining = participantEventType === 'FDTRAINING';

  const onClickAttachment = (id, name) => {
    createEventsData(
      userId,
      name,
      'CLICK',
      {
        dimension1Id: assessmentId,
        dimension1Name: 'ASSESSMENT',
      },
      {
        dimension2Id: activeTask?.id,
        dimension2Name: 'TASK',
      },
      {
        dimension3Id: id,
        dimension3Name: 'ARTEFACT',
      },
    );
  };

  // get all task attempts for the assessment
  const {
    data: tasksAttemptsUserData,
    loading: tasksAttemptsLoading,
    refetch: refetchTasksAttempts,
  } = useQueryRecursive(gql(listTaskAttemptAggregatesByUserId), {
    variables: {
      assessmentId: { eq: assessmentDataFiltered?.assessment?.id },
      userId,
      limit: 10000,
    },
    onCompleted: (data) => {
      if (activeTask?.id) {
        const tasksData =
          data?.listTaskAttemptAggregatesByUserId?.items?.find(
            (t) => t.taskId === activeTask?.id,
          ) || {};
        const attempts = tasksData?.attempts || 0;
        const completed = tasksData?.status === 'COMPLETED';
        let status = attempts > 0 ? 'Attempted' : taskStatus;
        if (completed) {
          status = 'Solved';
        }
        setTaskAttempts(attempts);
        setTaskStatus(status);
      }
    },
    skip: !assessmentDataFiltered?.id,
  });

  // get all task notes for the assessment
  const {
    data: tasksNotesData,
    loading: taskNotesLoading,
    refetch: refetchTaskNotes,
  } = useQueryRecursive(gql(listTaskNotes), {
    variables: {
      filter: {
        taskId: { eq: activeTask?.id },
        userAssessmentId: { eq: assessmentId },
      },
      limit: 100000,
    },
    skip: !activeTask?.id,
    onCompleted: (_data) => {
      if (activeTask?.id) {
        const taskNotes =
          _data?.listTaskNotes?.items
            .filter((t) => t.taskId === activeTask?.id)
            .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))[0]
            ?.notes || '';
        // eslint-disable-next-line no-use-before-define
        reset({ notes: taskNotes, flag: '' });
      }
    },
  });

  const [updateUserAssessmentMutation] = useMutation(gql(updateUserAssessment));
  const { data: listHintRevealsData, loading: listHintRevealsLoading } =
    useQueryRecursive(gql(listHintReveals), {
      variables: {
        filter: {
          taskAssessmentId: { eq: activeTask?.taskAssessmentId },
        },
        limit: 1000,
      },
      skip: !activeTask?.taskAssessmentId || !activeTask?.hints?.items?.length,
    });

  const {
    data: jumpboxData,
    loading: jumpboxLoading,
    startPolling,
    stopPolling,
    refetch: refetchListJumpboxes,
  } = useQueryRecursive(gql(listJumpboxInstancesByUserAssessmentId), {
    variables: {
      userAssessmentId: assessmentId,
      filter: {
        or: [
          { status: { eq: 'READY' } },
          { status: { eq: 'IN_BUILD_QUEUE' } },
          { status: { eq: 'BUILD_IN_PROGRESS' } },
        ],
      },
    },
    staleTime: { seconds: 0 },
    skip: enableJumpbox !== 'TRUE',
    onCompleted: (data) => {
      const jumpboxStatus =
        data?.listJumpboxInstancesByUserAssessmentId?.items?.[0]?.status;
      if (jumpboxStatus && jumpboxStatus === 'READY') {
        setIsJumpboxReady(true);
        setIsJumboxStarting(false);
        stopPolling();
        return;
      }

      setIsJumpboxReady(false);
      if (data?.listJumpboxInstancesByUserAssessmentId?.items.length > 0) {
        startPolling(1000);
      }
    },
  });

  const [startJumpboxMutation, { loading: startJumpboxLoading }] = useMutation(
    gql(startJumpbox),
    {
      onError: () => {
        // retry start on error
        refetchListJumpboxes();
      },
      onCompleted: () => {
        refetchListJumpboxes();
      },
    },
  );

  const [restartJumpboxMutation, { loading: restartJumpboxLoading }] =
    useMutation(gql(restartJumpbox));

  const [fetchConsole] = useLazyQuery(gql(getVmConsole), {
    skip: enableJumpbox !== 'TRUE' || !isJumpboxReady,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const { url } = data.getVmConsole;
      if (url) {
        window.open(url, '_blank');
      } else {
        setIsJumpboxReady(false);
      }
      setFetchConsoleLoading(false);
    },
  });

  const [downloadVPNConfig] = useLazyQuery(gql(downloadVPNConfigureFile));

  const [createTaskOpenMutation] = useMutation(gql(createTaskOpened), {
    onCompleted: (data) => {
      setActiveTaskOpenedId(data.createTaskOpened?.id);
    },
  });
  const [updateTaskOpenedMutation, { loading: updateTaskOpenedLoading }] =
    useMutation(gql(updateTaskOpened), {
      onCompleted: (data) => {
        setActiveTaskOpenedId(data.updateTaskOpened?.id);
      },
    });

  const {
    data: taskOpenedData,
    loading: taskOpenedLoading,
    refetch: refetchTaskOpened,
  } = useQueryRecursive(gql(listTaskOpenedsByUserAssessmentId), {
    variables: {
      taskOpenedUserAssessmentId: assessmentId,
      limit: 1000,
    },
    skip: !assessmentId,
    staleTime: { seconds: 0 },
    onCompleted: (_data) => {
      const recentTaskOpenedData = [
        ...(_data?.listTaskOpenedsByUserAssessmentId?.items?.filter(
          (ta) => ta?.taskOpenedTaskId === activeTask?.id,
        ) || []),
      ]
        ?.filter((ot) => ot.id !== activeTaskOpenedId)
        ?.sort(sortByDateField('updatedAt', 'desc'));

      const recentTaskOpened = recentTaskOpenedData?.[0];
      setLastActiveTaskOpened(recentTaskOpened);

      const taskOpenedParams = {
        taskOpenedTaskId: activeTask?.id,
        taskOpenedUserAssessmentId: assessmentId,
        userId,
        assessmentId: assessmentDataFiltered?.assessment?.id,
        startedSolving: recentTaskOpened
          ? recentTaskOpened?.startedSolving
          : false,
        startedSolvingAt: recentTaskOpened?.startedSolvingAt
          ? recentTaskOpened?.startedSolvingAt
          : undefined,
      };
      if (activeTask?.id && activeTask?.status !== 'Solved') {
        createTaskOpenMutation({
          variables: {
            input: taskOpenedParams,
          },
        });
        updateUserAssessmentMutation({
          variables: {
            input: {
              id: assessmentDataFiltered?.id,
              lastActive: new Date().toISOString(),
            },
          },
        });
      }
    },
  });

  // there is a delay in activeTask being set and notes fetched being made
  // to avoid that this useEffect is used
  useEffect(() => {
    if (activeTask?.id) {
      refetchTaskNotes();
    }
  }, [activeTask, refetchTaskNotes]);

  // eslint-disable-next-line func-names
  Yup.addMethod(Yup.string, 'noWhitespace', function (message) {
    // eslint-disable-next-line react/no-this-in-sfc
    return this.test({
      name: 'noWhitespace',
      message,
      test: (value) => value && value.trim().length,
    });
  });

  const validationSchema = Yup.object().shape({
    flag: Yup.string().required('Please enter the flag to submit'),
    notes: Yup.string()
      .max(1000, 'Notes must be 1,000 characters or less')
      .noWhitespace('Please add notes before saving'),
  });
  const { control, reset, getValues, trigger } = useForm({
    defaultValues: { flag: '', notes: '' },
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const [createTaskNoteMutation] = useMutation(gql(createTaskNote));

  const [updateTaskNoteMutation] = useMutation(gql(updateTaskNote));

  const assessmentRoute = showPostAssessmentReflection
    ? 'assessment-post-reflection'
    : 'assessment-complete';

  const [stopAssessment, { loading: stopAssessmentInProgress }] =
    useStopAssessment(
      showPostAssessmentReflection,
      assessmentDataFiltered?.assessment?.participantEventType,
    );

  const tasksAttemptsData =
    tasksAttemptsUserData?.listTaskAttemptAggregatesByUserId?.items || [];

  const tableRows =
    tasks?.items?.map((i) => {
      const allTaskAttempts =
        tasksAttemptsData?.find((ta) => ta.taskId === i.task.id) || {};
      const attempts = allTaskAttempts?.attempts || 0;
      const completed = allTaskAttempts?.status === 'COMPLETED';
      const taskOpened =
        [
          ...(taskOpenedData?.listTaskOpenedsByUserAssessmentId?.items?.sort(
            sortByDateField('updatedAt', 'desc'),
          ) || []),
        ]?.filter((ta) => ta.taskOpenedTaskId === i.task.id) || [];
      const taskStarted = taskOpened?.[0]?.startedSolving;
      let status =
        attempts > 0
          ? 'Attempted'
          : taskStarted
            ? 'Started'
            : taskOpened?.length > 0
              ? 'Opened'
              : 'Incomplete';
      if (completed) {
        status = 'Solved';
      }
      return {
        ...i.task,
        status,
        attempts,
        specialty: i?.task?.specialty?.name,
        skills: i?.task?.skills?.items?.map((s) => s?.skill?.name) || [],
        creator: i.task?.org?.name,
        modulePartId: i.modulePartId,
        modulePart: i.modulePart,
        labId: i.task?.labId,
        taskAssessmentId: i?.id,
      };
    }) || [];

  const tasksCompleted = tableRows?.reduce(
    (p, c) => p + (c.status === 'Solved' ? 1 : 0),
    0,
  );

  const handleOpenDrawer = (drawerState) => {
    setOpenDrawer(drawerState);
  };

  const [submitAttempt, { loading: flagSubmissionLoading }] = useMutation(
    gql(attempt),
    {
      onCompleted: (_data) => {
        refetchTasksAttempts();
        if (_data.attempt.success) {
          successToastMessage('Success! You have entered the correct flag.');

          createEventsData(
            userId,
            activeTask?.name,
            'SUBMIT_SUCCESS',
            {
              dimension1Id: assessmentId,
              dimension1Name: 'ASSESSMENT',
            },
            {
              dimension2Id: activeTask?.id,
              dimension2Name: 'TASK',
            },
          );
          updateUserAssessmentMutation({
            variables: {
              input: {
                id: assessmentDataFiltered?.id,
                lastActive: new Date().toISOString(),
              },
            },
          });
        } else {
          errorToastMessage('You did not get the right flag.');

          createEventsData(
            userId,
            activeTask?.name,
            'SUBMIT_FAILED',
            {
              dimension1Id: assessmentId,
              dimension1Name: 'ASSESSMENT',
            },
            {
              dimension2Id: activeTask?.id,
              dimension2Name: 'TASK',
            },
          );
        }
      },
    },
  );

  if (assessmentLoading || stopAssessmentInProgress) {
    return <FdLoadingSpinner />;
  }

  const hasActiveTaskStartedSolving = lastActiveTaskOpened?.startedSolving;

  const onSubmitFlag = (flag) => {
    submitAttempt({
      variables: {
        answer: flag,
        taskId: activeTask?.id,
        userAssessmentId,
      },
    });

    createEventsData(
      userId,
      activeTask?.name,
      'SUBMIT',
      {
        dimension1Id: assessmentId,
        dimension1Name: 'ASSESSMENT',
      },
      {
        dimension2Id: activeTask?.id,
        dimension2Name: 'TASK',
      },
    );

    reset();
  };

  const onNotesSubmission = async () => {
    const taskNoteId = tasksNotesData?.listTaskNotes?.items.sort(
      (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt),
    )[0]?.id;
    const resetNotes = () => {
      refetchTaskNotes();
      reset({ flag: '', notes: getValues('notes') });
      successToastMessage('Success! Notes saved');
    };
    const notes = getValues('notes');
    // edit if present
    if (taskNoteId) {
      updateTaskNoteMutation({
        variables: {
          input: {
            id: taskNoteId,
            notes,
          },
        },
        onCompleted: () => resetNotes(),
      });
    } else if (activeTask?.id) {
      // create if not present
      createTaskNoteMutation({
        variables: {
          input: {
            taskId: activeTask?.id,
            userId,
            userAssessmentId: assessmentId,
            assessmentID: assessmentDataFiltered?.assessment?.id,
            notes: undefined,
          },
        },
        onCompleted: () => resetNotes(),
      });
    }

    createEventsData(
      userId,
      activeTask?.name,
      'ADDNOTE',
      {
        dimension1Id: assessmentId,
        dimension1Name: 'ASSESSMENT',
      },
      {
        dimension2Id: activeTask?.id,
        dimension2Name: 'TASK',
      },
    );
  };

  if (assessmentDataFiltered?.status === 'FINISHED') {
    singleSpa.navigateToUrl(`/assessor/${assessmentRoute}/${assessmentId}`);
  }

  if (assessmentDataFiltered?.status !== 'STARTED') {
    singleSpa.navigateToUrl('/landing/landing-homepage');
  }

  // const labInstance = labInstanceData?.byModulePartId?.items?.[0];
  const jumpbox =
    jumpboxData?.listJumpboxInstancesByUserAssessmentId?.items?.[0];

  return (
    <Box>
      <FdBreadcrumbHeader page={{ name: assessmentName, type: 'ASSESSMENT' }} />
      <BasePage heading={assessmentName} data-cy="participant-tasks">
        <Grid container>
          <Grid item xs>
            <Box mb={2}>
              <AssessmentSummary
                assessment={{
                  hours,
                  minutes,
                  endDateTime: isFDTraining
                    ? new Date(endDateTime)
                    : getAssessmentFinishDateTime(startedOn, hours, minutes),
                  tasksCompleted,
                  participantEventType,
                }}
                onFinish={async () => {
                  await onNotesSubmission();
                  // stop individual assessment
                  stopAssessment({
                    variables: {
                      userAssessmentId,
                    },
                  });

                  createEventsData(
                    userId,
                    activeTask?.name,
                    'FINISH',
                    {
                      dimension1Id: assessmentId,
                      dimension1Name: 'ASSESSMENT',
                    },
                    {
                      dimension2Id: activeTask?.id,
                      dimension2Name: 'TASK',
                    },
                  );
                }}
              />
            </Box>
            {(enableJumpbox === 'TRUE' || enableVPN === 'TRUE') && (
              <Box display="flex" justifyContent="space-between">
                {enableVPN === 'TRUE' && (
                  <FdCard
                    variant="outlined"
                    style={{
                      width:
                        enableJumpbox === 'TRUE' && enableVPN === 'TRUE'
                          ? '49%'
                          : '100%',
                    }}
                  >
                    <Box>
                      <Box mb={2}>
                        <FdTypography variant="subtitle1">
                          VPN Configuration
                        </FdTypography>
                      </Box>
                      <Box mb={2} className="flex items-center">
                        <Box>
                          <FdButton
                            startIcon={<DownloadIcon />}
                            disabled={!!isVPNdownload}
                            onClick={async () => {
                              setIsVPNdownload(true);
                              const orgId = assessmentDataFiltered?.user?.orgId;
                              downloadVPNConfig({
                                variables: {
                                  userAssessmentId: assessmentDataFiltered?.id,
                                  assessmentId:
                                    assessmentDataFiltered?.assessment?.id,
                                  orgId,
                                },
                                onCompleted: (data) => {
                                  const text = atob(
                                    data.downloadVPNConfigureFile,
                                  );
                                  const blob = new Blob([text], {
                                    type: 'text/plain',
                                  });
                                  const url = URL.createObjectURL(blob);
                                  const a = document.createElement('a');
                                  a.href = url;
                                  a.download = `user-config-${assessmentDataFiltered?.assessment?.id}.ovpn`;
                                  a.click();
                                  URL.revokeObjectURL(url);
                                  setIsVPNdownload(false);
                                },
                              });
                            }}
                          >
                            {isVPNdownload
                              ? 'Waiting...'
                              : 'Download VPN Configuration File'}
                          </FdButton>
                        </Box>
                        <Box ml={1}>
                          <FdTypography variant="body1">or</FdTypography>
                        </Box>
                        <Box ml={1}>
                          <FdButton
                            startIcon={<ContentCopyIcon />}
                            disabled={!!isVPNCopying}
                            onClick={async () => {
                              setIsVPNCopying(true);
                              const orgId = assessmentDataFiltered?.user?.orgId;
                              const key = `user-config-${assessmentDataFiltered?.assessment?.id}-fd.ovpn`;
                              const currentDate = new Date();
                              const expiryDate = new Date(currentDate);
                              expiryDate.setHours(currentDate.getHours() + 24);

                              try {
                                await getUrl({
                                  path: ({ identityId }) =>
                                    `private/${identityId}/${key}`,
                                  options: {
                                    cacheControl: 'no-cache',
                                    validateObjectExistence: true,
                                  },
                                });

                                const userConfigLink = await getUrl({
                                  path: ({ identityId }) =>
                                    `private/${identityId}/${key}`,
                                  options: {
                                    cacheControl: 'no-cache',
                                  },
                                });

                                navigator.clipboard
                                  .writeText(userConfigLink.url)
                                  .then(() => {
                                    successToastMessage(
                                      'VPN Configuration link copied successfully.',
                                    );
                                  });

                                setIsVPNCopying(false);
                              } catch (error) {
                                console.error(
                                  'Error retrieving file URL:',
                                  error,
                                );
                                downloadVPNConfig({
                                  variables: {
                                    userAssessmentId:
                                      assessmentDataFiltered?.id,
                                    assessmentId:
                                      assessmentDataFiltered?.assessment?.id,
                                    orgId,
                                  },
                                  onCompleted: async (data) => {
                                    const text = atob(
                                      data.downloadVPNConfigureFile,
                                    );

                                    const expiryDate48 = new Date(currentDate);
                                    expiryDate48.setHours(
                                      currentDate.getHours() + 48,
                                    );

                                    await uploadData({
                                      path: ({ identityId }) =>
                                        `private/${identityId}/${key}`,
                                      data: text,
                                      options: {
                                        cacheControl: 'no-cache',
                                        expires: expiryDate48,
                                      },
                                    });

                                    const userConfigLink = await getUrl({
                                      path: ({ identityId }) =>
                                        `private/${identityId}/${key}`,
                                      options: {
                                        cacheControl: 'no-cache',
                                      },
                                    });

                                    navigator.clipboard
                                      .writeText(userConfigLink.url)
                                      .then(() => {
                                        successToastMessage(
                                          'VPN Configuration link copied successfully.',
                                        );
                                      });

                                    setIsVPNCopying(false);
                                  },
                                });
                              }
                            }}
                          >
                            {isVPNCopying
                              ? 'Waiting...'
                              : 'Copy VPN Configuration Link'}
                          </FdButton>
                        </Box>
                      </Box>
                      <Box mb={2}>
                        <FdTypography variant="body2">
                          You must use the provided VPN file on your workstation
                          or jump box to securely connect to the lab virtual
                          machines(VMs) in each challenge. You can either
                          download the VPN configuration file directly or copy
                          the download URL to into the jumpbox or elsewhere.
                          <FdExternalLink
                            href="https://au.intercom.help/fifth-domain/en/articles/2396-using-vpn-in-a-competition"
                            noUnderline
                          >
                            Click here
                          </FdExternalLink>
                          to learn more about using VPN configuration file.
                        </FdTypography>
                      </Box>
                    </Box>
                  </FdCard>
                )}
                {enableJumpbox === 'TRUE' && (
                  <FdCard
                    variant="outlined"
                    style={{
                      width:
                        enableJumpbox === 'TRUE' && enableVPN === 'TRUE'
                          ? '50%'
                          : '100%',
                    }}
                  >
                    <Box mb={2}>
                      <FdTypography variant="subtitle1">Jump Box</FdTypography>
                    </Box>
                    <Box className="flex items-center gap-x-3 my-3">
                      <FdSkeleton
                        loading={
                          jumpboxLoading ||
                          isJumboxStarting ||
                          startJumpboxLoading ||
                          restartJumpboxLoading ||
                          fetchConsoleLoading
                        }
                        height="32px"
                        width="198px"
                      >
                        <FdButton
                          style={
                            isJumpboxReady
                              ? {
                                  backgroundColor: '#228b22',
                                  color: '#FFFFFF',
                                  borderColor: '#228b22',
                                }
                              : {}
                          }
                          disabled={
                            isJumboxStarting ||
                            startJumpboxLoading ||
                            restartJumpboxLoading ||
                            fetchConsoleLoading
                          }
                          onClick={() => {
                            if (isJumpboxReady) {
                              setFetchConsoleLoading(true);
                              fetchConsole({
                                variables: {
                                  vmId: jumpbox?.id,
                                  type: 'JUMPBOX',
                                },
                              });
                              refetchListJumpboxes();
                            } else if (!startJumpboxLoading) {
                              setIsJumboxStarting(true);
                              startJumpboxMutation({
                                variables: {
                                  userAssessmentId: assessmentId,
                                },
                              });
                              startPolling(1000);
                            }
                          }}
                        >
                          {!isJumpboxReady || startJumpboxLoading ? (
                            <Box display="flex" alignItems="center">
                              <Box mr={2}>Start Jump Box</Box>
                              {isJumboxStarting && (
                                <CircularProgress
                                  size={25}
                                  style={{ color: '#fff' }}
                                />
                              )}
                            </Box>
                          ) : (
                            'Connect to Jump Box'
                          )}
                        </FdButton>
                      </FdSkeleton>
                      {['BUILD_IN_PROGRESS', 'POWERING_OFF']?.includes(
                        jumpbox?.status,
                      ) && (
                        <FdChip
                          color="warning"
                          size="medium"
                          label={
                            jumpbox?.status === 'BUILD_IN_PROGRESS'
                              ? 'Starting'
                              : 'Stopping'
                          }
                          className="ml-2"
                        />
                      )}
                      {isJumpboxReady && (
                        <FdButton
                          variant="secondary"
                          disabled={isJumboxStarting}
                          onClick={() => {
                            restartJumpboxMutation({
                              variables: {
                                labInstanceId: jumpbox?.id,
                                hardReset: false,
                              },
                            });
                          }}
                        >
                          Restart Jump box
                        </FdButton>
                      )}
                    </Box>
                    <FdTypography variant="body2">
                      To securely access challenges, click &apos;Start Jump
                      Box&apos;. After a short loading period, connect by
                      clicking &apos;Connect Jump Box&apos;.
                      <FdExternalLink
                        href="https://au.intercom.help/fifth-domain/en/articles/2346-using-jump-box-in-your-competition"
                        noUnderline
                      >
                        Click here
                      </FdExternalLink>
                      learn more about using the Jump Box.
                    </FdTypography>
                  </FdCard>
                )}
              </Box>
            )}
            {tableRows.length > 0 && (
              <TasksTable
                rows={tableRows}
                onRowClick={async (value) => {
                  setActiveTaskOpenedId('');
                  setLastActiveTaskOpened(undefined);
                  refetchTaskOpened();

                  handleOpenDrawer(true);
                  const selectedTask = tableRows?.find(
                    (t) => t.id === value?.row?.id,
                  );
                  setActiveTask(selectedTask);
                  setTaskStatus(selectedTask.status);
                  setTaskAttempts(selectedTask.attempts);

                  createEventsData(
                    userId,
                    selectedTask?.name,
                    'CLICK',
                    {
                      dimension1Id: assessmentId,
                      dimension1Name: 'ASSESSMENT',
                    },
                    {
                      dimension2Id: selectedTask?.id,
                      dimension2Name: 'TASK',
                    },
                  );
                  setTabIndex(0);
                }}
                loading={tasksAttemptsLoading}
              />
            )}
          </Grid>
          <Grid
            item
            style={{
              width: openDrawer ? drawerWidth : 0,
            }}
          />
        </Grid>
        <Drawer
          variant="persistent"
          anchor="right"
          open={openDrawer}
          className={classes.drawer}
          classes={{
            paper: classes.drawerPaper,
          }}
          data-cy="drawer"
        >
          <Card
            style={{ overflow: 'auto', height: 'inherit' }}
            data-cy="drawer-card"
          >
            <CardHeader
              action={
                <IconButton
                  onClick={() => {
                    setActiveTaskOpenedId('');
                    setLastActiveTaskOpened(undefined);

                    handleOpenDrawer(false);
                  }}
                  size="large"
                >
                  <CloseIcon style={{ fontSize: 28 }} />
                </IconButton>
              }
              title={activeTask?.name}
            />
            <CardContent
              style={{ height: '795px', paddingTop: 0 }}
              data-cy="drawer-content"
            >
              <TaskDrawerHeaderParticipant
                activeTask={activeTask}
                taskStatus={taskStatus}
                taskAttempts={taskAttempts}
                loading={tasksAttemptsLoading}
              />
              {!hasActiveTaskStartedSolving && (
                <>
                  <Box mt={2} mb={2} className={classes.description}>
                    <FdTypography
                      variant="subtitle1"
                      data-cy="description-title"
                    >
                      Description
                    </FdTypography>
                    <FdTypography
                      variant="body1"
                      data-cy="description"
                      color="secondary"
                    >
                      <ShowMoreText
                        lines={8}
                        more="See more"
                        less="See less"
                        anchorClass="see-more"
                      >
                        <FdMarkdownRender markdown={activeTask?.description} />
                      </ShowMoreText>
                    </FdTypography>
                  </Box>
                  {activeTask?.skills?.length > 0 && (
                    <Box mb={2}>
                      <FdTypography variant="subtitle1" data-cy="skills-title">
                        Skills
                      </FdTypography>
                      <FdTypography variant="body1" color="secondary">
                        {activeTask?.skills?.map((skill) => (
                          <ul key={shortid.generate()} data-cy="skills">
                            <li>{skill}</li>
                          </ul>
                        ))}
                      </FdTypography>
                    </Box>
                  )}
                </>
              )}

              {taskOpenedLoading || updateTaskOpenedLoading ? (
                <FdLoadingSpinner />
              ) : hasActiveTaskStartedSolving ? (
                <>
                  <FdTab
                    variant="fullWidth"
                    custIndex={tabIndex}
                    label={[
                      {
                        label: 'Challenge Details',
                        path: '/assessor/',
                        index: 0,
                        data: (
                          <Box>
                            <Box mb={2} className={classes.description}>
                              <FdTypography
                                variant="subtitle1"
                                data-cy="description-title"
                              >
                                Description
                              </FdTypography>
                              <FdTypography
                                variant="body1"
                                data-cy="description"
                                color="secondary"
                              >
                                <ShowMoreText
                                  lines={8}
                                  more="See more"
                                  less="See less"
                                  anchorClass="see-more"
                                >
                                  <FdMarkdownRender
                                    markdown={activeTask?.description}
                                  />
                                </ShowMoreText>
                              </FdTypography>
                            </Box>
                            {activeTask?.skills?.length > 0 && (
                              <Box mb={2}>
                                <FdTypography
                                  variant="subtitle1"
                                  data-cy="skills-title"
                                >
                                  Skills
                                </FdTypography>
                                <FdTypography variant="body1" color="secondary">
                                  {activeTask?.skills?.map((skill) => (
                                    <ul
                                      key={shortid.generate()}
                                      data-cy="skills"
                                    >
                                      <li>{skill}</li>
                                    </ul>
                                  ))}
                                </FdTypography>
                              </Box>
                            )}
                            {taskStatus !== 'Solved' && (
                              <LabControl
                                assessmentId={assessmentId}
                                activeTask={activeTask}
                                user={user}
                                createEventsData={createEventsData}
                                labId={activeTask?.labId}
                                modulePartId={activeTask?.modulePartId}
                              />
                            )}
                            {taskStatus !== 'Solved' && (
                              <Box>
                                {activeTask?.files?.items?.length !== 0 && (
                                  <Box mb={2}>
                                    <FdTypography
                                      variant="subtitle1"
                                      data-cy="attachments-title"
                                    >
                                      Attachments
                                    </FdTypography>
                                    {activeTask?.files?.items
                                      ?.filter((file) =>
                                        [
                                          'application/x-zip-compressed',
                                          'application/zip',
                                        ].includes(file.type),
                                      )
                                      .map((f) => (
                                        <FdFileAttachment
                                          name={f.name}
                                          onClickAttachment={(id, name) =>
                                            onClickAttachment(id, name)
                                          }
                                        />
                                      ))}
                                  </Box>
                                )}
                                {listHintRevealsLoading ? (
                                  <FdLoadingSpinner />
                                ) : (
                                  listHintRevealsData?.listHintReveals?.items
                                    ?.length > 0 && (
                                    <Box mb={2}>
                                      <FdTypography variant="subtitle1">
                                        Hints
                                      </FdTypography>
                                      <FdTypography
                                        variant="body1"
                                        color="secondary"
                                      >
                                        {listHintRevealsData?.listHintReveals?.items?.map(
                                          (item) => (
                                            <Box key={item?.id}>
                                              <Box
                                                display="flex"
                                                flexDirection="row"
                                                mt={1.5}
                                              >
                                                <EmojiObjectsIcon />
                                                <Box ml={1.5}>
                                                  {
                                                    activeTask?.hints?.items?.find(
                                                      (_item) =>
                                                        _item?.id ===
                                                        item?.hintId,
                                                    )?.text
                                                  }
                                                </Box>
                                              </Box>
                                            </Box>
                                          ),
                                        )}
                                      </FdTypography>
                                    </Box>
                                  )
                                )}

                                <FlagSubmission
                                  onClickSubmit={async () => {
                                    if (await trigger('flag')) {
                                      onSubmitFlag(getValues('flag'));
                                    }
                                  }}
                                  loading={flagSubmissionLoading}
                                  control={control}
                                  Controller={Controller}
                                />
                              </Box>
                            )}
                          </Box>
                        ),
                      },
                      {
                        label: (
                          <Box display="flex" alignItems="center">
                            <Box>Notes</Box>
                            <FdTooltip
                              position="top"
                              title="Notes let you explain your approach to your assessment organiser.
This can help you share what steps you took, which parts of the challenge you solved, or what you would have tried if you had more time."
                            >
                              <Box pl={1}>
                                <InfoOutlinedIcon />
                              </Box>
                            </FdTooltip>
                          </Box>
                        ),
                        path: '/assessor/',
                        index: 1,
                        data: taskNotesLoading ? (
                          <FdLoadingSpinner />
                        ) : (
                          <NotesSubmission
                            onClickSubmit={onNotesSubmission}
                            loading={flagSubmissionLoading}
                            control={control}
                            Controller={Controller}
                            reset={reset}
                            getValues={getValues}
                            trigger={trigger}
                          />
                        ),
                      },
                    ]}
                  />
                  <ChallengeRating
                    userId={userId}
                    assessmentId={assessmentDataFiltered?.assessment?.id}
                    taskId={activeTask?.id}
                    userAssessmentId={assessmentId}
                  />
                </>
              ) : (
                <>
                  <FdTypography color="secondary">
                    Click below to see detailed challenge view including
                    attachments, virtual environments, and hints to start
                    solving the challenge.
                  </FdTypography>
                  <FdButton
                    variant="secondary"
                    onClick={() => {
                      updateTaskOpenedMutation({
                        variables: {
                          input: {
                            id: activeTaskOpenedId,
                            startedSolving: true,
                            startedSolvingAt: new Date().toISOString(),
                          },
                        },
                        onCompleted: (_data) => {
                          setLastActiveTaskOpened(_data.updateTaskOpened);
                          setActiveTaskOpenedId('');
                        },
                      });
                      updateUserAssessmentMutation({
                        variables: {
                          input: {
                            id: assessmentDataFiltered?.id,
                            lastActive: new Date().toISOString(),
                          },
                        },
                      });
                    }}
                    style={{ marginTop: '16px' }}
                  >
                    Start Solving
                  </FdButton>
                </>
              )}
            </CardContent>
          </Card>
        </Drawer>
      </BasePage>
    </Box>
  );
};

export default AssessmentTasks;
