import React, { useState } from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import {
  FdTypography,
  FdCard,
  FdProgress,
  BasePage,
  FdButton,
  FdAlert,
  FdTable,
  useQueryRecursive,
} from '@fifthdomain/fe-shared';
import { FdBreadcrumbHeader } from '@fifthdomain/sidebar';
import { getLabModulePart } from '../queries/customQueries';
import {
  checkParticipantLab,
  listLabInstances,
  getUserById,
} from '../graphql/queries';
import { startParticipantLab, stopParticipantLab } from '../graphql/mutations';
import { EDIT_LAB_STATUS } from '../constants';
import { getDateTimeZoneFormatted } from '../shared/utils/dateUtils';

const ViewCourseLab = () => {
  const { partId, courseUserId } = useParams();
  const [labStatus, setLabStatus] = useState('NOT_STARTED');
  const pollInterval = 5000;

  const { data: courseUserData, loading: courseUserLoading } = useQuery(
    gql(getUserById),
    {
      variables: {
        id: courseUserId,
      },
      skip: !courseUserId,
    },
  );

  // Get Lab Instances which are powered ON
  const {
    data: labInstancesData,
    loading: labInstancesLoading,
    // refetch: refetchLabInstances,
  } = useQueryRecursive(gql(listLabInstances), {
    variables: {
      filter: {
        userId: {
          eq: courseUserId,
        },
        modulePartId: {
          eq: partId,
        },
        poweredOn: { eq: true },
      },
    },
    skip: labStatus !== EDIT_LAB_STATUS.READY,
  });

  // Get Lab Module Part
  const { loading: getLabModulePartLoading, data: getLabModulePartData } =
    useQueryRecursive(gql(getLabModulePart), {
      variables: {
        id: partId,
      },
    });

  // Get Participant Lab
  const { startPolling, stopPolling } = useQuery(gql(checkParticipantLab), {
    variables: {
      userId: courseUserId,
      modulePartId: partId,
    },
    onCompleted: (_data) => {
      const status =
        EDIT_LAB_STATUS[_data?.checkParticipantLab] || 'NOT_DEFINED'; // lookup from a pre-defined list
      setLabStatus(status);
      // if status is either OFF or READY then stop polling
      if ([EDIT_LAB_STATUS.OFF, EDIT_LAB_STATUS.READY].includes(status)) {
        stopPolling();
      }
      // start polling if status is in transient state
      if (
        [EDIT_LAB_STATUS.POWERING_ON, EDIT_LAB_STATUS.POWERING_OFF].includes(
          status,
        )
      ) {
        startPolling(pollInterval);
      }
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });

  const [startParticipantLabMutation] = useMutation(gql(startParticipantLab), {
    onCompleted: () => startPolling(pollInterval),
  });

  const [stopParticipantLabMutation] = useMutation(gql(stopParticipantLab), {
    onCompleted: () => startPolling(pollInterval),
  });

  const onStartLab = () => {
    setLabStatus(EDIT_LAB_STATUS.POWERING_ON);
    startParticipantLabMutation({
      variables: { userId: courseUserId, modulePartId: partId },
    });
  };

  const onStopLab = () => {
    setLabStatus(EDIT_LAB_STATUS.POWERING_OFF);
    stopParticipantLabMutation({
      variables: { userId: courseUserId, modulePartId: partId },
    });
  };

  if (
    (getLabModulePartLoading && labStatus !== EDIT_LAB_STATUS.POWERING_ON) ||
    labInstancesLoading ||
    courseUserLoading
  ) {
    return <FdProgress />;
  }

  const {
    name: partName,
    description,
    expiry,
  } = getLabModulePartData?.getModulePart || {};

  const columns = [
    {
      field: 'name',
      width: 300,
      headerName: 'Name',
    },
  ];

  const actions = [
    {
      label: 'Connect',
      show: () => true,
      onClick: (vm) => {
        window.open(`/labs/connect/${vm?.labInstanceId}/vdi/${vm?.id}`);
      },
    },
  ];

  const vmRows = labInstancesData?.listLabInstances?.items[0]?.vms?.items || [];
  const courseUserName = courseUserData?.getUserById?.items[0]?.name || '';

  return (
    <Box>
      <FdBreadcrumbHeader />
      <BasePage data-cy="modules-part-base-page">
        <FdCard
          variant="outlined"
          heading={partName}
          summary={
            <Box display="flex">
              {courseUserName && (
                <Box mr={3}>
                  <FdTypography variant="body1">
                    {`User: ${courseUserName}`}
                  </FdTypography>
                </Box>
              )}
              {expiry && (
                <Box display="flex">
                  <Box mr={1}>
                    <AccessAlarmIcon />
                  </Box>
                  <FdTypography variant="body1">
                    Lab Expiry Time:
                    {` ${getDateTimeZoneFormatted(expiry, true)}`}
                  </FdTypography>
                </Box>
              )}
              {labStatus === EDIT_LAB_STATUS.READY && (
                <Box ml={2}>
                  <FdButton variant="secondary" onClick={() => onStopLab()}>
                    Shutdown Lab
                  </FdButton>
                </Box>
              )}
            </Box>
          }
        >
          <FdTypography variant="body1">{description}</FdTypography>
          {[EDIT_LAB_STATUS.OFF].includes(labStatus) && (
            <Box mt={2}>
              <FdButton size="large" onClick={onStartLab}>
                Start Lab
              </FdButton>
            </Box>
          )}
          {labStatus === EDIT_LAB_STATUS.POWERING_ON && (
            <Box mt={2}>
              <FdAlert
                alertTitle="Your lab is being built. This may take several minutes."
                message="Constructing additional pylons"
                variant="info"
              />
              <Box my={1}>
                <FdProgress />
              </Box>
            </Box>
          )}
          {labStatus === EDIT_LAB_STATUS.POWERING_OFF && (
            <Box mt={2}>
              <FdAlert
                alertTitle="The lab is being reset. This may take several minutes."
                message="Constructing additional pylons"
                variant="info"
              />
              <Box my={1}>
                <FdProgress />
              </Box>
            </Box>
          )}
        </FdCard>
        {labStatus === EDIT_LAB_STATUS.DELETED && (
          <Box mt={2}>
            <FdAlert
              alertTitle="This lab has expired"
              message="The participant’s lab has expired, and can no longer be accessed."
              variant="warning"
            />
          </Box>
        )}
        {labStatus === EDIT_LAB_STATUS.READY && (
          <Box height="553px">
            <FdTable
              toolbarSettings={{
                title: 'Virtual Machines',
                filterButton: true,
                searchBox: true,
              }}
              rows={
                vmRows?.map((vm) => ({
                  id: vm?.id,
                  name: vm?.name,
                  labInstanceId: vm?.labInstanceId,
                })) || []
              }
              columns={columns}
              tablePageSize={5}
              actions={actions}
              gridId="labs-view-course-lab-vms-grid"
            />
          </Box>
        )}
      </BasePage>
    </Box>
  );
};
export default ViewCourseLab;
