import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, useTheme, TextField } from '@mui/material';
import shortid from 'shortid';
import {
  FdTypography,
  FdModal,
  FdAlert,
  FdTable,
  useQueryRecursive,
  useSnapshot,
  globalStore,
  FdAutocomplete,
  FdSkeleton,
  errorToastMessage,
  successToastMessage,
} from '@fifthdomain/fe-shared';
import { listTeams } from '../../graphql/queries';
import {
  initialValues,
  validationSchema,
} from '../../validation-schemas/Team/inviteTeam';
import { manageTeamEvents } from '../../graphql/mutations';
import { getTeamsWithSharedEmails } from '../../shared/utils/teamUtils';
import { getCommaSeparated } from '../../shared/utils/stringUtils';

const InviteTeams = ({
  assessmentId,
  teamSize,
  teamsInCompetition,
  openModal,
  onConfirm,
  setOpenModal,
  onDismiss,
}) => {
  const theme = useTheme();
  const globalSnap = useSnapshot(globalStore);
  const [inputValue, setInputValue] = useState('');
  const privateValue = globalSnap?.isAAFCOrg ? { eq: true } : { ne: true };

  const clearSelected = () => {
    setInputValue('');
  };

  const { data: listTeamsData, loading: listTeamsLoading } = useQueryRecursive(
    gql(listTeams),
    {
      variables: {
        limit: 1000,
        filter: {
          private: privateValue,
        },
      },
    },
  );
  const [manageTeamEventsMutation, { loading: manageTeamEventsLoading }] =
    useMutation(gql(manageTeamEvents));
  // if team is private then only show when creator's org id matches with current

  const teamIdsInCompetition = teamsInCompetition.map((t) => t.team?.id);
  const teamMembersInCompetition = teamsInCompetition
    .map((t) => t.team?.members?.items.map((tm) => tm?.email))
    .flat();

  const {
    control,
    trigger,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });
  const { fields: teams, replace: replaceTeams } = useFieldArray({
    control,
    name: 'teams',
  });

  const columns = [
    {
      field: 'name',
      flex: 1,
      headerName: 'Team Name',
      valueGetter: (row) => row?.value,
    },
    {
      field: 'memberCount',
      type: 'number',
      flex: 1,
      headerName: 'Member Count',
      headerAlign: 'left',
      align: 'left',
      valueGetter: (row) => row?.value,
    },
  ];

  const getTeamStatus = (team) => {
    let disabled = false;
    let message = '';
    let tooltip;
    // team already exists
    const teamAlreadyExist = teamIdsInCompetition?.includes(team?.id);
    if (teamAlreadyExist) {
      disabled = true;
      message = 'already in the competition';
      return { disabled, message };
    }
    // team count based
    const teamCount = team?.members?.items.length || 0;
    if (teamCount === 0 || teamCount > teamSize) {
      disabled = true;
      if (teamCount === 0) {
        message = 'no team members';
      } else {
        message = 'exceeds max team size';
      }
      return { disabled, message };
    }
    // overlapping team members
    const overlappingTeamMembers = team?.members?.items.some((tm) =>
      teamMembersInCompetition.includes(tm.email),
    );
    if (overlappingTeamMembers) {
      disabled = true;
      message = 'members already competing in other teams';
      tooltip =
        'This team cannot be invited as some members are already in the competition as part of other teams.';
      return { disabled, message, tooltip };
    }
    // already selected to be invited
    const selectedTeams = teams.map((t) => t?.teamId)?.includes(team?.id);
    if (selectedTeams) {
      disabled = true;
      message = 'already selected to be invited';
      return { disabled, message };
    }
    return { disabled, message };
  };

  const teamsData =
    listTeamsData?.listTeams?.items
      ?.filter((t) =>
        t?.private ? t?.creator?.orgId === globalSnap.orgId : true,
      )
      ?.map((t) => {
        const { disabled, message, tooltip } = getTeamStatus(t);
        return {
          ...t,
          teamId: t?.id,
          teamName: t?.name,
          memberCount: t?.members?.items?.length || 0,
          emails: t?.members?.items?.map((tm) => tm?.email),
          disabled,
          message,
          tooltip,
        };
      }) || [];

  return (
    <form>
      <FdModal
        size="lg"
        title={
          <Box>
            <FdTypography variant="h3">Invite Existing Teams</FdTypography>
            <FdTypography variant="body2" color="secondary">
              Invite eligible existing teams to this competition below.
            </FdTypography>
            <Box mt={3} mb={1}>
              <FdTypography variant="subtitle2">{`Max Team Size: ${teamSize}`}</FdTypography>
            </Box>
            <FdAlert
              variant="info"
              message={
                <Box>
                  <FdTypography variant="subtitle1">
                    Only teams within the maximum team size limit are eligible
                    for invitation.
                  </FdTypography>
                  <FdTypography variant="body2">
                    Teams exceeding this limit will still appear in search
                    results but cannot be selected. To adjust the maximum team
                    size for this competition, visit the &apos;Details&apos;
                    tab.
                  </FdTypography>
                </Box>
              }
            />
            {errors?.teams?.type === 'min' && (
              <Box mt={1}>
                <FdAlert
                  variant="error"
                  message="Please select at least one team to add to the invitation list before sending invites."
                />
              </Box>
            )}
          </Box>
        }
        dismiss="CANCEL"
        confirm="Invite"
        open={openModal}
        onDismiss={() => {
          reset();
          setOpenModal(false);
          onDismiss();
        }}
        disableConfirm={manageTeamEventsLoading}
        onConfirm={async () => {
          const valid = await trigger();
          if (valid) {
            manageTeamEventsMutation({
              variables: {
                action: 'ADD',
                teams: teams?.map((t) => t?.teamId),
                participantEventType: 'COMPETITION',
                eventId: assessmentId,
              },
              onCompleted: (_data) => {
                setOpenModal(false);
                successToastMessage(_data?.manageTeamEvents?.message);
                reset();
                onConfirm();
              },
              onError: ({ graphQLErrors }) => {
                setOpenModal(false);
                errorToastMessage(graphQLErrors[0]?.message);
              },
            });
          }
        }}
        data-cy="invite-teams-modal"
      >
        <FdSkeleton loading={listTeamsLoading}>
          <Box>
            {errors?.teams?.type === 'unique-email' && (
              <Box mb={3}>
                <FdAlert
                  variant="error"
                  message={
                    <Box>
                      <FdTypography variant="body2">
                        A participant cannot be in multiple teams in the same
                        competition. The following instances of overlapping team
                        members need to be resolved before invites can be sent:
                      </FdTypography>
                      <Box ml={2}>
                        <FdTypography variant="body2">
                          <ul style={{ listStyleType: 'disc' }}>
                            {getTeamsWithSharedEmails(teams)?.map((e) => (
                              <li key={shortid.generate()}>
                                {`Overlap between : ${getCommaSeparated(e?.teams)}`}
                              </li>
                            ))}
                          </ul>
                        </FdTypography>
                      </Box>
                    </Box>
                  }
                />
              </Box>
            )}
            <FdTypography variant="subtitle1">
              Search Existing Teams
            </FdTypography>
            <Box my={1.5}>
              <FdTypography variant="body2" color="secondary">
                Enter exact team names below to find and invite eligible teams.
                Only fully matching names will appear in results.
              </FdTypography>
            </Box>
            <FdAutocomplete
              fullWidth
              id="select-teams"
              freeSolo={!inputValue}
              placeholder="Type exact team name to search"
              options={teamsData || []}
              optionLabel="name"
              optionValue="id"
              onInputChange={(e) => {
                setInputValue(e?.target?.value);
              }}
              noOptionsText="No matching results"
              getOptionDisabled={(option) => option?.disabled === true}
              filterOptions={(option) =>
                inputValue
                  ? option?.filter(
                      (o) =>
                        inputValue?.toLowerCase() === o?.name?.toLowerCase(),
                    )
                  : []
              }
              renderOption={(optionProps, option) => (
                <Box {...optionProps}>
                  <Box className="m-2">
                    <FdTypography variant="subtitle1">
                      {option?.name}
                    </FdTypography>
                    <FdTypography
                      variant="captiontext1"
                      color="secondary"
                    >{`Member Count: ${option?.memberCount}`}</FdTypography>
                    {option?.disabled && (
                      <Box className="flex">
                        <FdTypography
                          variant="captiontext2"
                          color={theme?.fdProColors?.alert?.errorDark}
                        >
                          {'Unable to select : '}
                        </FdTypography>
                        <FdTypography
                          variant="captiontext2"
                          color={theme?.fdProColors?.alert?.errorDark}
                        >
                          {option?.message}
                        </FdTypography>
                      </Box>
                    )}
                  </Box>
                </Box>
              )}
              disableClearable
              value={inputValue}
              renderInput={(params) => <TextField {...params} />}
              onChange={(currentSelection) => {
                replaceTeams([...teams, currentSelection]);
                clearSelected();
              }}
            />
            <Box my={3}>
              <FdTypography variant="subtitle1">
                {` Selected Existing Teams (${teams?.length})`}
              </FdTypography>
            </Box>
            {teams?.length > 0 && (
              <Box height="428px">
                <FdTable
                  toolbarSettings={{
                    filterButton: true,
                    searchBox: true,
                  }}
                  actions={[
                    {
                      label: 'remove',
                      onClick: ({ id }) => {
                        replaceTeams(
                          teams?.filter((td) => !id.includes(td?.id)),
                        );
                        successToastMessage('Success! Team removed.');
                      },
                    },
                  ]}
                  rows={teams}
                  columns={columns}
                  pagination
                  rowsPerPageOptions={[5, 10, 20]}
                  tablePageSize={5}
                  gridId="competitions-invited-teams"
                />
              </Box>
            )}
          </Box>
        </FdSkeleton>
      </FdModal>
    </form>
  );
};

InviteTeams.propTypes = {
  assessmentId: PropTypes.string.isRequired,
  teamSize: PropTypes.string.isRequired,
  openModal: PropTypes.bool.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  teamsInCompetition: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default InviteTeams;
