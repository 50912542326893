import React, { useEffect, useState } from 'react';
import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Box } from '@mui/material';
import {
  FdTypography,
  useSnapshot,
  globalStore,
  FdModal,
  FdExternalLink,
  Authorization,
  FdSkeleton,
  errorToastMessage,
} from '@fifthdomain/fe-shared';
import { getAffiliatedParams } from '../../../shared/utils/urlUtils';
import { updateUserAffliation } from '../../../graphql/mutations';
import { getUserById, listAffliationsByUserId } from '../../../graphql/queries';
import { getOrg } from '../../../queries/customQueries';

const AffiliationLinkStatusModal = () => {
  const globalSnap = useSnapshot(globalStore);
  const [showExpiredModal, setShowExpiredModal] = useState(undefined);
  const [showRevokeModal, setShowRevokeModal] = useState(undefined);

  const [
    updateUserAffiliationMutation,
    { loading: updateUserAffiliationLoading },
  ] = useMutation(gql(updateUserAffliation), {
    onError: ({ graphQLErrors }) => {
      errorToastMessage(graphQLErrors[0]?.message);
    },
  });
  const affiliatedParams = getAffiliatedParams();

  const { data: orgData, loading: orgDataLoading } = useQuery(gql(getOrg), {
    variables: {
      id: affiliatedParams?.orgId,
    },
    skip: !affiliatedParams?.orgId,
  });
  const orgName = orgData?.getOrg?.name || '';

  // get user after affiliation
  const [getUser, { loading: userLoading }] = useLazyQuery(gql(getUserById), {
    variables: {
      id: globalSnap.userId,
    },
    fetchPolicy: 'network-only',
    onCompleted: (_data) => {
      const { permissions } = _data?.getUserById?.items[0] || {};

      const hasManagerialPermissions =
        Authorization.hasManagerialPermissions(permissions);
      globalStore.permissions = permissions;

      if (hasManagerialPermissions) {
        globalStore.userType = 'ADMIN';
        globalStore.mode = 'Manage';
      }
    },
  });

  // get & set affiliation status
  const [getAffiliation, { loading: affiliationsLoading }] = useLazyQuery(
    gql(listAffliationsByUserId),
    {
      fetchPolicy: 'network-only',
      onCompleted: (_data) => {
        const affiliationDetails =
          _data?.listAffliationsByUserId?.items?.filter(
            (a) => a?.status === 'ACTIVE',
          )[0] || {};

        if (affiliationDetails?.status === 'ACTIVE') {
          globalStore.orgId = affiliationDetails?.orgId;
          globalStore.orgAlias = affiliationDetails?.org?.alias || '';
          globalStore.orgName = affiliationDetails?.org?.name || '';
          globalStore.orgPricingTier =
            affiliationDetails?.org?.pricingTier || '';
          globalStore.isAffiliated = true;
        }
        // update user permissions
        getUser();
      },
    },
  );

  useEffect(() => {
    if (affiliatedParams.isAffiliated) {
      updateUserAffiliationMutation({
        variables: {
          userId: globalSnap?.userId,
          email: globalSnap.userEmail,
          orgId: affiliatedParams?.orgId,
          affiliationAction: 'NEW_REQUEST',
        },
        onCompleted: (_data) => {
          const affiliation = _data?.updateUserAffliation || {};
          if (affiliation?.status === 'REVOKED') {
            setShowRevokeModal(true);
            return;
          }
          if (affiliation?.status === 'EXPIRED') {
            setShowExpiredModal(true);
            return;
          }
          getAffiliation({
            variables: {
              userId: globalSnap?.userId,
            },
          });
        },
      });
    }
  }, []);

  return (
    <Box>
      <FdSkeleton
        variant="text"
        loading={
          orgDataLoading ||
          updateUserAffiliationLoading ||
          affiliationsLoading ||
          userLoading
        }
        style={{ marginBottom: '1rem' }}
      >
        <span className="h-0" />
      </FdSkeleton>
      <FdModal
        size="md"
        title="The affiliation link seems to have expired!"
        description=""
        confirm="GOT IT"
        open={showExpiredModal}
        onConfirm={() => {
          setShowExpiredModal(undefined);
        }}
        showDismiss={false}
      >
        <Box>
          <FdTypography variant="body2">
            {`Oops! It seems that the affiliation link has expired. Not to worry though, keep an eye out for another affiliation request from  ${orgName} in your email account.`}
          </FdTypography>
          <Box mt={1}>
            <FdTypography variant="body2">
              If you&apos;re an existing user of FifthDomain, rest assured that
              your experience on the platform remains unchanged.
            </FdTypography>
          </Box>
          <Box mt={1}>
            <FdTypography variant="body2">
              If you&apos;re new to FifthDomain, welcome aboard! While you may
              not currently see any assigned events such as competitions,
              assessments, or courses, we encourage you to refer to this
              <FdExternalLink href="https://au.intercom.help/fifth-domain/en/articles/13706-joining-the-fd-platform-your-gateway-to-cyber-events-and-skill-development">
                article
              </FdExternalLink>
              to learn more about engaging with events on our platform.
            </FdTypography>
          </Box>
          <Box mt={1}>
            <FdTypography variant="body2">
              Thank you, and have a great day!
            </FdTypography>
          </Box>
        </Box>
      </FdModal>
      <FdModal
        size="md"
        title={`${orgName} has withdrawn their request for you to affiliate.`}
        description=""
        confirm="GOT IT"
        open={showRevokeModal}
        onConfirm={() => {
          setShowRevokeModal(undefined);
        }}
        showDismiss={false}
      >
        <Box>
          <FdTypography variant="body2">
            {`You were recently invited to join the FifthDomain platform as an internal member of ${orgName}. However, the organisation has withdrawn their request for you to confirm affiliation with them.`}
          </FdTypography>
          <Box mt={1}>
            <FdTypography variant="body2">
              If you&apos;re an existing user of FifthDomain, rest assured that
              your experience on the platform remains unchanged.
            </FdTypography>
          </Box>
          <Box mt={1}>
            <FdTypography variant="body2">
              If you&apos;re new to FifthDomain, welcome aboard! While you may
              not currently see any assigned events such as competitions,
              assessments, or courses, we encourage you to refer to this
              <FdExternalLink href="https://au.intercom.help/fifth-domain/en/articles/13706-joining-the-fd-platform-your-gateway-to-cyber-events-and-skill-development">
                article
              </FdExternalLink>
              to learn more about engaging with events on our platform.
            </FdTypography>
          </Box>
          <Box mt={1}>
            <FdTypography variant="body2">
              Thank you, and have a great day!
            </FdTypography>
          </Box>
        </Box>
      </FdModal>
    </Box>
  );
};

export default AffiliationLinkStatusModal;
