import React from 'react';
import StylesProvider from '@mui/styles/StylesProvider';
import createGenerateClassName from '@mui/styles/createGenerateClassName';
import { BrowserRouter, Switch, Redirect } from 'react-router-dom';
import OrganisationRoute from './routes/OrganisationRoute';
import ContentCatalogueRoute from './routes/ContentCatalogueRoute';
import ManageOrgs from './pages/ManageOrgs';
import CreateOrg from './pages/CreateOrg';
import ViewOrg from './pages/ViewOrg';
import ContentCatalogue from './pages/ContentCatalogue';
import AppThemeProvider from './providers/AppThemeProvider';
import ContentLeaseCatalogue from './pages/ContentLeaseCatalogue';
import ListTemplates from './pages/ListTemplates';
import CreateTemplate from './pages/CreateTemplate';
import CreateTrainingTemplate from './pages/CreateTrainingTemplate';
import ViewTrainingTemplate from './pages/ViewTrainingTemplate';
import ViewTemplate from './pages/ViewTemplate';
import GraphQlClient from './shared/GraphQlClient';
import './organisation.css';

const generateClassName = createGenerateClassName({
  productionPrefix: 'organisation',
  seed: 'organisation',
});

const App = () => {
  return (
    <GraphQlClient>
      <AppThemeProvider>
        <StylesProvider generateClassName={generateClassName}>
          <BrowserRouter>
            <Switch>
              <OrganisationRoute path="/org" component={ManageOrgs} exact />
              <OrganisationRoute
                path="/org/create"
                component={CreateOrg}
                exact
              />
              <OrganisationRoute path="/org/view/:orgId" component={ViewOrg} />
              <OrganisationRoute
                path="/org/content-library"
                component={ContentCatalogue}
                exact
              />
              <OrganisationRoute
                path="/org/template-library"
                component={ListTemplates}
                exact
              />
              <OrganisationRoute
                path="/org/template-library/create/training-template"
                component={CreateTrainingTemplate}
                exact
              />
              <OrganisationRoute
                path="/org/template-library/view/training-template/:templateId"
                component={ViewTrainingTemplate}
                exact
              />
              <OrganisationRoute
                path="/org/template-library/create/:templateType"
                component={CreateTemplate}
              />
              <OrganisationRoute
                path="/org/template-library/view/:templateType/:templateId"
                component={ViewTemplate}
              />
              <ContentCatalogueRoute
                path="/org/content-lease-catalogue"
                component={ContentLeaseCatalogue}
                exact
              />
              <Redirect to="/" />
            </Switch>
          </BrowserRouter>
        </StylesProvider>
      </AppThemeProvider>
    </GraphQlClient>
  );
};

export default App;
