import React from 'react';
import StylesProvider from '@mui/styles/StylesProvider';
import createGenerateClassName from '@mui/styles/createGenerateClassName';
import { BrowserRouter, Switch, Redirect, Route } from 'react-router-dom';
import { AppBasePageProvider } from '@fifthdomain/fe-shared';
import ProtectedRoute from './routes/ProtectedRoute';
import CreateAssessment from './pages/CreateAssessment';
import ViewAssessment from './pages/ViewAssessment';
import AssessmentStart from './pages/AssessmentStart';
import AssessmentOverview from './pages/AssessmentOverview';
import AssessmentTasks from './pages/AssessmentTasks';
import AssessmentComplete from './pages/AssessmentComplete';
import LabVDI from './pages/LabVDI';
import Error from './pages/Error';
import ParticipantRoute from './routes/ParticipantRoute';
import SurveyRoute from './routes/SurveyRoute';
import AssessmentPostReflection from './pages/AssessmentPostReflection';
import AppThemeProvider from './providers/AppThemeProvider';
import QuickSight from './pages/QuickSight';
import ListDashboards from './pages/ListDashboards';
import AppStoreInitializer from './providers/AppStoreInitializer';
import CreateAssessmentTemplate from './pages/CreateAssessmentTemplate';
import GraphQlClient from './shared/GraphQlClient';
import FDTrainingOverview from './pages/FDTrainingOverview';
import FDTrainingComplete from './pages/FDTrainingComplete';
import ViewFDTraining from './pages/ViewFDTraining';
import './assessor.css';

const generateClassName = createGenerateClassName({
  productionPrefix: 'assessor',
  seed: 'assessor',
});

const App = () => {
  return (
    <GraphQlClient>
      <AppThemeProvider>
        <StylesProvider generateClassName={generateClassName} injectFirst>
          <AppStoreInitializer>
            <AppBasePageProvider>
              <BrowserRouter>
                <Switch>
                  <ParticipantRoute
                    path="/assessor/assess/:assessmentId/org/:orgId"
                    component={AssessmentStart}
                  />
                  <SurveyRoute path="/assessor/survey" exact />
                  <SurveyRoute
                    path="/assessor/survey/assess/:assessmentId"
                    exact
                  />
                  <ParticipantRoute
                    path="/assessor/assessment-start/:assessmentId"
                    component={AssessmentStart}
                  />
                  <ParticipantRoute
                    path="/assessor/assessment-overview/:assessmentId"
                    component={AssessmentOverview}
                  />
                  <ParticipantRoute
                    path="/assessor/assessment-tasks/:assessmentId"
                    component={AssessmentTasks}
                  />
                  <ParticipantRoute
                    path="/assessor/assessment-complete/:assessmentId"
                    component={AssessmentComplete}
                  />
                  <ParticipantRoute
                    path="/assessor/assessment-post-reflection/:assessmentId"
                    component={AssessmentPostReflection}
                  />
                  <ParticipantRoute
                    path="/assessor/fd-training/complete/:assessmentId"
                    component={FDTrainingComplete}
                    exact
                  />
                  <ParticipantRoute
                    path="/assessor/fd-training/tasks/:assessmentId"
                    component={AssessmentTasks}
                    exact
                  />
                  <ProtectedRoute
                    path="/assessor/fd-training/view/:assessmentId"
                    component={ViewFDTraining}
                    exact
                  />
                  <ParticipantRoute
                    path="/assessor/fd-training/:mode/:assessmentId"
                    component={FDTrainingOverview}
                  />
                  <ProtectedRoute
                    path="/assessor/create"
                    component={CreateAssessment}
                    formMode="create"
                    exact
                  />
                  <ProtectedRoute
                    path="/assessor/duplicate/:_assessmentId"
                    component={CreateAssessment}
                    formMode="duplicate"
                    exact
                  />
                  <ProtectedRoute
                    path="/assessor/create/template"
                    component={CreateAssessmentTemplate}
                    exact
                  />
                  <ProtectedRoute
                    path="/assessor/view/:assessmentId"
                    component={ViewAssessment}
                  />
                  <ProtectedRoute
                    path="/assessor/dashboards"
                    component={ListDashboards}
                    exact
                  />
                  <ParticipantRoute
                    path="/assessor/connect/:labId/vdi/:vmId"
                    component={LabVDI}
                    exact
                  />
                  <ProtectedRoute
                    path="/assessor/dashboards/:dashboardId"
                    component={QuickSight}
                  />
                  <Route path="/assessor/error" component={Error} />
                  <Redirect to="/landing" />
                </Switch>
              </BrowserRouter>
            </AppBasePageProvider>
          </AppStoreInitializer>
        </StylesProvider>
      </AppThemeProvider>
    </GraphQlClient>
  );
};

export default App;
