import { gql, useMutation } from '@apollo/client';
import * as singleSpa from 'single-spa';
import { stopAssessmentForUser } from '../graphql/mutations';

const useStopAssessment = (hasPostReflection, assessmentType) =>
  useMutation(gql(stopAssessmentForUser), {
    refetchQueries: ['ListUserAssessmentsWithTasks'],
    awaitRefetchQueries: true,
    onCompleted: (_data) => {
      const assessmentRoute = hasPostReflection
        ? 'assessment-post-reflection'
        : assessmentType === 'FDTRAINING'
          ? 'fd-training/complete'
          : 'assessment-complete';
      singleSpa.navigateToUrl(
        `/assessor/${assessmentRoute}/${_data?.stopAssessmentForUser}`,
      );
    },
    onError: () => singleSpa.navigateToUrl('/assessor/error'),
  });

export default useStopAssessment;
