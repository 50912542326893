export const getLabStatus = (status) => {
  const _status = status.replaceAll('_', ' ');
  switch (_status) {
    case 'READY':
      return 'Available';
    case 'DRAFT':
      return 'Draft';
    case 'IN BUILD QUEUE':
    case 'BUILD IN PROGRESS':
    case 'BUILDING':
      return 'Building';
    case 'EXPIRED':
      return 'Expired';
    case 'ERROR':
      return 'Error';
    case 'EDIT IN PROGRESS':
      return 'In Editing';
    default:
      return 'Available';
  }
};
