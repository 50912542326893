import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Drawer,
  IconButton,
  Card,
  CardHeader,
  CardContent,
  Grid,
  Divider,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import { makeStyles } from 'tss-react/mui';
import * as singleSpa from 'single-spa';
import ShowMoreText from 'react-show-more-text';
import { gql, useMutation, useQuery } from '@apollo/client';
import * as Yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams, useLocation } from 'react-router-dom';
import shortid from 'shortid';
import {
  BasePage,
  FdTypography,
  FdMarkdownRender,
  FdLoadingSpinner,
  useQueryRecursive,
  FdAccordion,
  FdSkeleton,
  FdButton,
  FdModal,
  useSnapshot,
  globalStore,
  FdTab,
  FdFileAttachment,
  FdChip,
  triggerGlobalEvent,
  clearGlobalEvent,
  GLOBAL_EVENTS,
  useGlobalEventListener,
  FdTooltip,
  useRecentLinks,
  errorToastMessage,
  successToastMessage,
} from '@fifthdomain/fe-shared';
import { FdBreadcrumbHeader } from '@fifthdomain/sidebar';
import TasksTable from '../components/Participant/TasksTable';
import AssessmentSummary from '../components/Participant/AssessmentSummary';
import {
  onCreateTaskActivity,
  onUpdateTaskActivity,
  onCreateNewTaskAttempt,
  onUpdateAssessment,
} from '../graphql/subscriptions';
import FlagSubmission from '../components/Participant/FlagSubmission';
import {
  listTaskActivities,
  listTaskAttemptAggregates,
  getSystemTime,
  getAssessment,
  getTeam,
  listTaskActivitiesByTeamId,
  listTaskOpenedsByUserAssessmentId,
} from '../graphql/queries';
import { listHintReveals } from '../queries/customQueries';
import {
  updateUserAssessment,
  createTaskOpened,
  attempt,
  createTaskActivity,
  updateTaskActivity,
  deleteTaskActivity,
  updateTaskOpened,
} from '../graphql/mutations';
import useStopAssessment from '../hooks/useStopAssessment';
import useGetAssessmentsTasks from '../hooks/useGetAssessmentsTasks';
import {
  TASK_ACTIVITY,
  LEVEL_NAMES,
  PROFICIENCY_LEVELS,
  CUSTOM_LEVEL_NAMES_LONG,
  CUSTOM_LEVEL_NAMES_SHORT,
  AI_ASSISTANT_NAME,
} from '../constants';
import LevelSelector from '../components/Participant/LevelSelector';
import ProgressBarWithTitle from '../components/Participant/ProgressBar';
import { getArrayByLength } from '../shared/utils/objectUtils';
import LevelIndicator from '../components/Participant/LevelIndicator';
import ProgressGraphs from '../components/Participant/AccordionContents/ProgressGraphs';
import LabControl from '../components/Participant/LabControl';
import useGetScoreboard from '../hooks/useGetScoreboard';
import { invalidateAssessmentsHomePageDataQuery } from '../queries/invalidateQueries';
import { getCompetitionStatus } from '../shared/utils/getParticipantStatus';
import useSubscription from '../hooks/useSubscription';
import { getDifficultyLevel } from '../shared/utils/difficultyMapping';
import TaskDrawerHeaderParticipant from '../components/Assessment/TaskDrawerHeaderParticipant';
import { sortByDateField } from '../shared/utils/dateUtils';
import ChallengeRating from '../components/Participant/ChallengeRating';
import ParticipantChallengeChat from '../components/Assessment/ParticipantChallengeChat';
import VPNConfiguration from '../components/Participant/Tasks/VPNConfiguration';
import ParticipantJumpBox from '../components/Participant/ParticipantJumpBox';
import ActivitySelector from '../components/Participant/Tasks/ActivitySelector';
import SpecialtyIcon from '../components/Participant/SpecialtyIcon';
import { getParticipantAssessmentStatusColor } from '../shared/utils/getStatusColor';
import ChallengeAIChat from '../components/Participant/Tasks/ChallengeAIChat';
import ChallengeFinder from '../components/Participant/ChallengeFinder/ChallengeFinder';

const drawerWidth = 900;
const useStyles = makeStyles()((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    '& .see-more': {
      color: '#1976D2',
      display: 'block',
      paddingTop: '0.5rem',
      textDecoration: 'none',
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  divider: {
    margin: '10px 0',
    backgroundColor: theme.palette.table.border,
  },
}));

const AssessmentTasks = () => {
  const [searchText, setSearchText] = useState('');
  const [challengeSpeciality, setChallengeSpeciality] = useState([]);
  const [challengeProficiency, setChallengeProficiency] = useState([]);
  const [challengeStatus, setChallengeStatus] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [activeTask, setActiveTask] = useState();
  const [taskActivity, setTaskActivity] = useState('');
  const [taskAttempts, setTaskAttempts] = useState(undefined);
  const [taskStatus, setTaskStatus] = useState(undefined);
  const [levelSelected, setLevelSelected] = useState(1);
  const [barGraphLegendData, setBarGraphLegendData] = useState(undefined);
  const [flagSubmitToast, setFlagSubmitToast] = useState(undefined);
  const [endDateTime, setEndDateTime] = useState(undefined);
  const [solutionModal, setSolutionModal] = useState(false);
  const [lastActiveTaskOpened, setLastActiveTaskOpened] = useState(undefined);
  const [activeTaskOpenedId, setActiveTaskOpenedId] = useState('');
  const headerRef = useRef();

  const globalSnap = useSnapshot(globalStore);
  const isCSCOrg = globalSnap.isCSCOrg || false;
  const { search, pathname } = useLocation();
  const { assessmentId } = useParams();
  const { addRecentLink } = useRecentLinks({ userId: globalSnap.userId });
  const { classes } = useStyles();
  const noFilterApplied =
    searchText === '' &&
    challengeProficiency?.length <= 0 &&
    challengeSpeciality?.length <= 0 &&
    challengeStatus?.length <= 0;

  // hide parent page scroll if drawer is open
  useEffect(() => {
    document.body.classList.toggle('overflow-hidden', openDrawer);
    return () => document.body.classList.remove('overflow-hidden');
  }, [openDrawer]);

  useGlobalEventListener(GLOBAL_EVENTS.CLOSE_TASK_DRAWER, () => {
    // close drawer on global event
    setOpenDrawer(false);
  });

  const { data: assessmentTasksData, loading: assessmentTasksLoading } =
    useGetAssessmentsTasks({
      userId: globalSnap.userId,
    });

  const { data: serverTime, loading: serverTimeLoading } = useQuery(
    gql(getSystemTime),
    {
      fetchPolicy: 'network-only',
    },
  );

  const assessmentDataFiltered =
    assessmentTasksData?.listUserAssessments?.items.find(
      (ad) => ad.id === assessmentId,
    );

  const { loading: getAssessmentLoading } = useQuery(gql(getAssessment), {
    variables: {
      id: assessmentDataFiltered?.userAssessmentAssessmentId,
    },
    onCompleted: (data) => {
      setEndDateTime(data?.getAssessment?.endDateTime);
      // add recent link
      addRecentLink({
        id: assessmentId,
        name: data.getAssessment?.name,
        type: 'COMPETITION',
        url: pathname + search,
        role: 'PARTICIPATE',
      });
    },
    skip: !assessmentDataFiltered?.userAssessmentAssessmentId,
    fetchPolicy: 'network-only',
  });
  const [updateUserAssessmentMutation] = useMutation(gql(updateUserAssessment));
  const [setTaskOpen] = useMutation(gql(createTaskOpened), {
    onCompleted: (data) => {
      setActiveTaskOpenedId(data.createTaskOpened?.id);
    },
  });
  const [updateTaskOpenedMutation, { loading: updateTaskOpenedLoading }] =
    useMutation(gql(updateTaskOpened), {
      onCompleted: (data) => {
        setActiveTaskOpenedId(data.updateTaskOpened?.id);
      },
    });
  const taskOpenedFilter = assessmentDataFiltered?.teamId
    ? { teamId: { eq: assessmentDataFiltered?.teamId } }
    : {
        taskOpenedUserAssessmentId: {
          eq: assessmentId,
        },
      };

  const {
    data: taskOpenedData,
    loading: taskOpenedLoading,
    refetch: refetchTaskOpened,
  } = useQueryRecursive(gql(listTaskOpenedsByUserAssessmentId), {
    variables: {
      taskOpenedUserAssessmentId:
        assessmentDataFiltered?.userAssessmentAssessmentId,
      limit: 1000,
      filter: taskOpenedFilter,
    },
    skip: !assessmentId,
    staleTime: { seconds: 0 },
    onCompleted: (_data) => {
      const recentTaskOpenedData = [
        ...(_data?.listTaskOpenedsByAssessmentId?.items?.filter(
          (ta) => ta?.taskOpenedTaskId === activeTask?.id,
        ) || []),
      ]
        ?.filter((ot) => ot.id !== activeTaskOpenedId)
        ?.sort(sortByDateField('updatedAt', 'desc'));

      const recentTaskOpened = recentTaskOpenedData?.[0];
      setLastActiveTaskOpened(recentTaskOpened);

      const taskOpenedParams = {
        taskOpenedTaskId: activeTask?.id,
        teamId: assessmentDataFiltered?.teamId || undefined,
        taskOpenedUserAssessmentId: assessmentId,
        userId: globalSnap?.userId,
        assessmentId: assessmentDataFiltered?.assessment?.id,
        startedSolving: recentTaskOpened
          ? recentTaskOpened?.startedSolving
          : false,
        startedSolvingAt: recentTaskOpened?.startedSolvingAt
          ? recentTaskOpened?.startedSolvingAt
          : undefined,
      };

      if (
        activeTask?.id &&
        activeTask?.status !== 'Completed' &&
        recentTaskOpenedData?.length === 0
      ) {
        setTaskOpen({
          variables: {
            input: taskOpenedParams,
          },
        });
        updateUserAssessmentMutation({
          variables: {
            input: {
              id: assessmentDataFiltered?.id,
              lastActive: new Date().toISOString(),
            },
          },
        });
      }
    },
  });

  const [stopAssessment, { loading: stopAssessmentInProgress }] =
    useStopAssessment(assessmentDataFiltered?.assessment?.participantEventType);

  useSubscription({
    query: gql(onUpdateAssessment),
    variables: {
      filter: {
        id: { eq: assessmentDataFiltered?.userAssessmentAssessmentId },
      },
    },
    onData: (_data) => {
      const _endDateTime = _data?.data?.onUpdateAssessment?.endDateTime;
      setEndDateTime(_endDateTime);

      if (_endDateTime) {
        // call stop user assessment if endDate is present
        // backend will stop the assessment based on server date time
        stopAssessment({
          variables: {
            userAssessmentId: assessmentId,
          },
        });
      }
    },
  });

  const assessmentStatus = assessmentDataFiltered?.status;
  const assessmentFinishedOn = assessmentDataFiltered?.finishedOn;
  const enableJumpBox =
    assessmentDataFiltered?.assessment?.enableJumpbox === 'TRUE';
  const enableVPN = assessmentDataFiltered?.assessment?.enableVPN === 'TRUE';

  // redirect to home page if not started
  useEffect(() => {
    if (
      (!assessmentTasksLoading &&
        assessmentStatus &&
        assessmentStatus !== 'STARTED') ||
      assessmentFinishedOn
    ) {
      invalidateAssessmentsHomePageDataQuery();
      singleSpa.navigateToUrl('/landing/landing-homepage');
    }
  }, [assessmentTasksLoading, assessmentStatus, assessmentFinishedOn]);

  const showFlagSubmitToast = () => {
    if (flagSubmitToast) {
      const toast =
        flagSubmitToast?.type === 'success'
          ? successToastMessage
          : errorToastMessage;
      toast(flagSubmitToast?.message);
      setFlagSubmitToast(undefined);
    }
  };

  const onTaskAttemptsComplete = (data) => {
    if (activeTask?.id) {
      let tasksData =
        data?.listTaskAttemptAggregates?.items?.filter(
          (t) => t.taskId === activeTask?.id,
        ) || [];

      if (assessmentDataFiltered?.assessment?.teamBased) {
        tasksData = tasksData?.filter(
          (teamTaskAttempt) =>
            teamTaskAttempt.teamId === assessmentDataFiltered?.teamId,
        );
      }
      const attempts = tasksData[0]?.attempts || 0;
      const completed = tasksData[0]?.status === 'COMPLETED';
      let status = attempts > 0 ? 'Attempted' : 'Incomplete';
      if (completed) {
        status = 'Completed';
      }
      setTaskAttempts(attempts);
      setTaskStatus(status);
      showFlagSubmitToast();

      // for TRAINING tasks submit stop-assessment mutation if all tasks are complete
      if (
        assessmentDataFiltered?.assessment?.participantEventType === 'TRAINING'
      ) {
        const completedTasks =
          data?.listTaskAttemptAggregates?.items?.filter(
            (t) => t.status === 'COMPLETED',
          )?.length || 0;
        // all tasks are completed then stop the user assessment
        if (
          completedTasks ===
          assessmentDataFiltered?.assessment?.tasks?.items?.length
        ) {
          stopAssessment({
            variables: {
              userAssessmentId: assessmentDataFiltered?.id,
            },
          });
        }
      }
    }
  };

  const {
    data: listTaskAttemptsScoreboardData,
    loading: listTaskAttemptsScoreboardLoading,
    refetch: refetchScoreboard,
  } = useGetScoreboard({
    assessmentId: assessmentDataFiltered?.assessment?.id,
    teamBased: assessmentDataFiltered?.assessment?.teamBased,
    ...(assessmentDataFiltered?.assessment?.teamBased
      ? { teamId: assessmentDataFiltered?.teamId }
      : { userId: globalSnap?.userId }),
  });

  const { data: listHintRevealsData, loading: listHintRevealsLoading } =
    useQueryRecursive(gql(listHintReveals), {
      variables: {
        filter: {
          taskAssessmentId: { eq: activeTask?.taskAssessmentId },
        },
        limit: 1000,
      },
      skip: !activeTask?.taskAssessmentId || !activeTask?.hints?.items?.length,
    });

  // get all task attempts for the assessment
  const {
    data: tasksAttemptsUserData,
    loading: tasksAttemptsLoading,
    refetch: refetchTasksAttempts,
  } = useQueryRecursive(gql(listTaskAttemptAggregates), {
    variables: {
      filter: {
        userAssessmentId: { eq: assessmentDataFiltered?.id },
      },
      limit: 1000,
    },
    onCompleted: onTaskAttemptsComplete,
    skip:
      !assessmentDataFiltered?.id ||
      assessmentDataFiltered?.assessment?.teamBased,
  });

  // get activity for the user
  const {
    data: tasksActivitiesData,
    refetch: refetchTaskActivitiesUser,
    loading: taskActivitiesUserLoading,
  } = useQueryRecursive(gql(listTaskActivities), {
    variables: {
      filter: {
        userId: { eq: globalSnap.userId },
        assessmentId: { eq: assessmentDataFiltered?.assessment?.id },
        teamId: { eq: assessmentDataFiltered?.teamId },
      },
      limit: 1000,
    },
    skip:
      !assessmentDataFiltered?.id ||
      !assessmentDataFiltered?.assessment?.teamBased,
  });

  // get activity for all users
  const {
    data: tasksActivitiesAllUsersData,
    refetch: refetchActivityAllUsers,
    loading: tasksActivitiesAllUsersLoading,
    // eslint-disable-next-line react-hooks/rules-of-hooks
  } = useQueryRecursive(gql(listTaskActivitiesByTeamId), {
    variables: {
      assessmentId: { eq: assessmentDataFiltered?.assessment?.id },
      teamId: assessmentDataFiltered?.teamId,
      limit: 1000,
    },
    skip:
      !assessmentDataFiltered?.assessment?.id ||
      !assessmentDataFiltered?.assessment?.teamBased,
  });

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [createTaskActivityMutation] = useMutation(gql(createTaskActivity), {
    onCompleted: () => {
      refetchTaskActivitiesUser();
      refetchActivityAllUsers();
    },
  });

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [updateTaskActivityMutation] = useMutation(gql(updateTaskActivity), {
    onCompleted: () => {
      refetchTaskActivitiesUser();
      refetchActivityAllUsers();
    },
  });

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [deleteTaskActivityMutation] = useMutation(gql(deleteTaskActivity), {
    onCompleted: () => {
      refetchTaskActivitiesUser();
      refetchActivityAllUsers();
    },
  });

  // get all task attempts for the assessment
  const {
    data: tasksAttemptsAssessmentData,
    refetch: refetchAttemptsTeamBased,
    loading: taskAttemptsAssessmentTeamBasedLoading,
    // eslint-disable-next-line react-hooks/rules-of-hooks
  } = useQueryRecursive(gql(listTaskAttemptAggregates), {
    variables: {
      filter: {
        assessmentId: { eq: assessmentDataFiltered?.assessment?.id },
        teamId: { eq: assessmentDataFiltered?.teamId },
      },
      limit: 1000,
    },
    onCompleted: onTaskAttemptsComplete,
    skip:
      !assessmentDataFiltered?.assessment?.id ||
      !assessmentDataFiltered?.assessment?.teamBased,
  });

  const refetchAttempts = () =>
    assessmentDataFiltered?.assessment?.teamBased
      ? refetchAttemptsTeamBased()
      : refetchTasksAttempts();

  const { data: teamMembersData, loading: teamMembersDataLoading } = useQuery(
    gql(getTeam),
    {
      variables: {
        id: assessmentDataFiltered?.teamId,
      },
      fetchPolicy: 'cache-first',
      skip: !assessmentDataFiltered || !assessmentDataFiltered?.teamId,
    },
  );

  const refreshActivity = (_result, action) => {
    const { assessmentId: activityAssessmentId, teamId: activityTeamId } =
      _result?.value?.data?.[`on${action}TaskActivity`] || {
        activityAssessmentId: undefined,
        activityTeamId: undefined,
      };
    if (
      activityAssessmentId ===
        assessmentDataFiltered?.userAssessmentAssessmentId &&
      activityTeamId === assessmentDataFiltered?.teamId
    ) {
      refetchActivityAllUsers();
    }
  };

  useSubscription({
    query: gql(onCreateTaskActivity),
    onData: (_data) => {
      refreshActivity(_data, 'Create');
    },
  });

  useSubscription({
    query: gql(onUpdateTaskActivity),
    onData: (_data) => {
      refreshActivity(_data, 'Update');
    },
  });

  useSubscription({
    query: gql(onCreateNewTaskAttempt),
    variables: {
      teamId: assessmentDataFiltered?.teamId,
      assessmentId: assessmentDataFiltered?.assessment?.id,
    },
    onData: (_result) => {
      const { success, user: subUser } = _result?.data
        ?.onCreateNewTaskAttempt || { success: false, user: undefined };
      // if attempts query in-flight then ignore
      if (assessmentDataFiltered?.assessment?.teamBased) {
        if (!taskAttemptsAssessmentTeamBasedLoading && !tasksAttemptsLoading) {
          refetchAttempts();
        }
        if (success && globalSnap.userId !== subUser.id) {
          successToastMessage(`${subUser?.alias} solved a challenge!`);
        }
        if (success) {
          refetchScoreboard();
        }
      }
    },
  });

  const validationSchema = Yup.object().shape({
    flag: Yup.string().required('Please enter a flag to submit'),
  });
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { control, reset, handleSubmit, getValues } = useForm({
    defaultValues: { flag: '' },
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const {
    assessment: {
      name,
      tasks,
      multiLevel,
      guided,
      level: levelCount,
      startDateTime,
    },
    id: userAssessmentId,
  } = assessmentDataFiltered || { assessment: {}, id: {} };

  const updatedStatus = getCompetitionStatus(
    startDateTime,
    endDateTime,
    serverTime?.getSystemTime,
  );

  // redirect to homepage only if the competition is in ended state or not started state
  if (
    !assessmentTasksLoading &&
    assessmentDataFiltered &&
    ['ENDED', 'NOT_STARTED'].includes(updatedStatus)
  ) {
    singleSpa.navigateToUrl('/landing/landing-homepage');
  }

  const teamMembers =
    teamMembersData?.getTeam?.members?.items?.map(
      (member) => member.User?.id,
    ) || [];

  const allUserActivity =
    tasksActivitiesAllUsersData?.listTaskActivitiesByTeamId?.items
      .filter((la) => teamMembers?.includes(la.userId))
      .map((a) => ({
        activity: a.activity,
        name: teamMembersData?.getTeam?.members?.items?.find(
          (gm) => gm.User?.id === a.userId,
        )?.User?.alias,
        taskId: a.taskId,
      })) || [];

  const tasksAttemptsData =
    (assessmentDataFiltered?.assessment?.teamBased
      ? tasksAttemptsAssessmentData?.listTaskAttemptAggregates?.items?.filter(
          (ta) => teamMembers?.includes(ta.userId),
        )
      : tasksAttemptsUserData?.listTaskAttemptAggregates?.items) || [];

  const tasksData = multiLevel
    ? assessmentDataFiltered?.assessment?.levels?.items
        ?.map((l) => ({
          ...l,
          tasks: {
            items: l.tasks?.items.map((t) => ({ ...t, levelId: l.id })),
          },
        }))
        ?.find((_l) => _l.levelNumber === levelSelected)?.tasks || []
    : tasks;

  const getChallengeDataForParticipant = (i) => {
    const proficiency =
      i?.task?.difficulty > 5
        ? getDifficultyLevel(i?.task?.difficulty)
        : PROFICIENCY_LEVELS[i?.task?.difficulty];
    const speciality = i?.task?.specialty?.name;
    const allTaskAttempts =
      tasksAttemptsData?.find((ta) => ta.taskId === i.task?.id) || [];
    const attempts = allTaskAttempts?.attempts || 0;
    const completed = allTaskAttempts?.status === 'COMPLETED';
    const taskOpened =
      [
        ...(taskOpenedData?.listTaskOpenedsByAssessmentId?.items?.sort(
          sortByDateField('updatedAt', 'desc'),
        ) || []),
      ]?.filter((ta) => ta.taskOpenedTaskId === i.task.id) || [];
    const taskStarted = taskOpened?.[0]?.startedSolving;
    let _status =
      attempts > 0
        ? 'Attempted'
        : taskStarted
          ? 'Started'
          : taskOpened?.length > 0
            ? 'Opened'
            : 'Incomplete';
    if (completed) {
      _status = 'Completed';
    }
    const searchItems =
      `${i?.task?.name}, ${proficiency}, ${i?.task?.recommendedPoints}, ${speciality}, ${_status}, ${attempts}, ${i?.task?.skills?.items?.map(({ skill }) => skill?.name)}`?.toLowerCase();
    return { attempts, _status, proficiency, speciality, searchItems };
  };

  const tableRows =
    tasksData?.items?.map((i) => {
      const { attempts, _status, proficiency, speciality, searchItems } =
        getChallengeDataForParticipant(i);
      return {
        ...i.task,
        searchItems,
        levelId: i.levelId,
        status: _status,
        attempts,
        activities: allUserActivity.filter((ua) => ua.taskId === i.task.id),
        difficultyInteger: i.task?.difficulty,
        difficulty: proficiency,
        specialty: speciality,
        skills:
          i?.task?.skills?.items?.map((s) => ({
            name: s?.skill?.name,
            alias: s?.skill?.alias,
          })) || [],
        creator: i.task?.org?.name,
        modulePartId: i.modulePartId,
        modulePart: i.modulePart,
        taskAssessmentId: i?.id,
        labId: i.task?.labId,
      };
    }) || [];

  const scoreboardData = assessmentDataFiltered?.assessment?.teamBased
    ? listTaskAttemptsScoreboardData?.listScoreboardsByTeamId?.items?.[0]
    : listTaskAttemptsScoreboardData?.listScoreboardsByUserId?.items?.[0];

  const tasksCompleted = scoreboardData?.flags || 0;
  const firstBloods = scoreboardData?.firstBloods || 0;
  const totalPoints = scoreboardData?.points || 0;

  const handleOpenDrawer = (drawerState) => {
    setOpenDrawer(drawerState);
    triggerGlobalEvent(GLOBAL_EVENTS.CLOSE_SIDEBAR);
    clearGlobalEvent(GLOBAL_EVENTS.CLOSE_TASK_DRAWER);
  };

  const getCurrentActivity = (_taskId) =>
    tasksActivitiesData?.listTaskActivities?.items.find(
      (ta) =>
        ta.taskId === _taskId &&
        ta.userId === globalSnap.userId &&
        ta.assessmentId === assessmentDataFiltered?.userAssessmentAssessmentId,
    );

  const getCurrentActivityLabel = (_taskId) => {
    const activity = getCurrentActivity(_taskId)?.activity;
    return TASK_ACTIVITY.find((t) => t.value === activity)?.desc || '';
  };
  const hasActiveTaskStartedSolving = lastActiveTaskOpened?.startedSolving;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [submitAttempt, { loading: flagSubmissionLoading }] = useMutation(
    gql(attempt),
    {
      onCompleted: (_data) => {
        refetchAttempts();
        if (_data.attempt.success) {
          setFlagSubmitToast({
            type: 'success',
            message: 'Success! You have entered the correct flag.',
          });
        } else {
          setFlagSubmitToast({
            type: 'error',
            message: 'You did not get the right flag.',
          });
        }
        refetchScoreboard(); // refetch scoreboard on every attempt
      },
    },
  );

  if (
    assessmentTasksLoading ||
    stopAssessmentInProgress ||
    teamMembersDataLoading ||
    serverTimeLoading ||
    getAssessmentLoading
  ) {
    return <FdLoadingSpinner />;
  }

  const onSubmitFlag = (flag) => {
    submitAttempt({
      variables: {
        answer: flag,
        taskId: activeTask?.id,
        levelId: activeTask?.levelId,
        userAssessmentId,
      },
    });
    updateUserAssessmentMutation({
      variables: {
        input: {
          id: assessmentDataFiltered?.id,
          lastActive: new Date().toISOString(),
        },
      },
    });
    reset();
  };

  const ContentWithLabel = ({ label, content }) => (
    <Box display="flex" alignItems="center" mb={2}>
      <Box width="120px">
        <FdTypography variant="subtitle1">{label}</FdTypography>
      </Box>
      {content}
    </Box>
  );

  ContentWithLabel.propTypes = {
    label: PropTypes.string.isRequired,
    content: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
      .isRequired,
  };

  const onActivityChange = (value) => {
    setTaskActivity(value);
    const currentActivityId = getCurrentActivity(activeTask.id)?.id;
    const activity = TASK_ACTIVITY.find((t) => t.desc === value)?.value;
    const isNotWorking = activity === 'NOT_WORKING';
    // just to create an illusion that the selection worked.
    setTimeout(() => {
      if (isNotWorking) {
        setTaskActivity('');
      }
    }, 2000);

    // if no activity present create else update
    if (currentActivityId) {
      if (isNotWorking) {
        deleteTaskActivityMutation({
          variables: {
            input: {
              id: currentActivityId,
            },
          },
        });
        return;
      }
      updateTaskActivityMutation({
        variables: {
          input: {
            id: currentActivityId,
            activity,
          },
        },
      });
    } else {
      createTaskActivityMutation({
        variables: {
          input: {
            userId: globalSnap.userId,
            assessmentId: assessmentDataFiltered?.assessment?.id,
            taskId: activeTask.id,
            teamId: assessmentDataFiltered?.teamId,
            activity,
          },
        },
      });
    }
  };
  const teamBased = assessmentDataFiltered?.assessment?.teamBased || false;

  const getChallengeLevelDetails = (_level) => {
    const _tasks =
      assessmentDataFiltered?.assessment?.levels?.items?.find(
        (_l) => _l.levelNumber === _level,
      )?.tasks || [];
    const _tasksWithStatus =
      _tasks?.items?.map((i) => {
        const allTaskAttempts =
          tasksAttemptsData?.filter((ta) => ta.taskId === i.task?.id) || [];
        return {
          completed: allTaskAttempts.some((ta) => ta.status === 'COMPLETED'),
        };
      }) || [];
    const taskFilteredItems =
      _tasks?.items?.map((i) => {
        const { proficiency, speciality, _status, searchItems } =
          getChallengeDataForParticipant(i);
        return {
          proficiency,
          speciality,
          searchItems,
          status: _status,
        };
      }) || [];
    const filteredLevelChallenges =
      taskFilteredItems?.filter(
        (x) =>
          (challengeProficiency?.length <= 0
            ? []
            : challengeProficiency?.includes(x.proficiency)) &&
          (challengeSpeciality?.length <= 0
            ? []
            : challengeSpeciality?.includes(x.speciality)) &&
          (challengeStatus?.length <= 0
            ? []
            : challengeStatus?.includes(x.status)) &&
          (searchText === '' ? [] : x?.searchItems?.includes(searchText)),
      ) || [];

    return {
      PercentageLevelCompleted: Math.round(
        ((_tasksWithStatus?.filter((t) => t.completed) || []).length /
          (_tasks?.items?.length || 1)) *
          100,
      ),
      filteredLevelChallenges: filteredLevelChallenges?.length,
    };
  };
  const allLevels = getArrayByLength(levelCount || 0, (_, i) => i + 1);
  const allLevelDetails = allLevels.map((l, index) => {
    const { PercentageLevelCompleted, filteredLevelChallenges } =
      getChallengeLevelDetails(l);
    const defaultName = LEVEL_NAMES[index] || `Level ${l}`;
    return {
      level: l,
      levelName: isCSCOrg
        ? CUSTOM_LEVEL_NAMES_LONG[index] || defaultName
        : LEVEL_NAMES[index] || defaultName,
      levelTitle: 'Mission',
      selected: levelSelected === l,
      percentageComplete: PercentageLevelCompleted,
      filteredChallenges: noFilterApplied ? '' : filteredLevelChallenges,
      shortLevelName: isCSCOrg ? CUSTOM_LEVEL_NAMES_SHORT[index] : '',
    };
  });

  const selectLevelData = {
    levelName: isCSCOrg
      ? CUSTOM_LEVEL_NAMES_LONG[levelSelected - 1] || `Level ${levelSelected}`
      : LEVEL_NAMES[levelSelected - 1] || `Level ${levelSelected}`,
    levelTitle: 'Mission',
    attempted: tableRows.filter((t) => t.attempts)?.length || 0,
    solved: tableRows.filter((t) => t.status === 'Completed')?.length || 0,
    pointScored:
      tableRows?.reduce(
        (acc, p) => acc + (p.status === 'Completed' ? p.recommendedPoints : 0),
        0,
      ) || 0,
    totalTasks: tableRows?.length,
    totalPoints:
      tableRows?.reduce((acc, p) => acc + p.recommendedPoints, 0) || 0,
    shortLevelName: isCSCOrg ? CUSTOM_LEVEL_NAMES_SHORT[levelSelected - 1] : '',
  };

  const ProgressSpinner = () => (
    <FdSkeleton loading width="128px" height="20px" />
  );
  const showActivitySelector = teamBased && taskStatus !== 'Completed';
  const hasAIAssistant = activeTask?.hasAssistant === 'TRUE';
  const hideDrawerTabs =
    (!hasAIAssistant && !teamBased) || !hasActiveTaskStartedSolving;
  const headerHeight = headerRef?.current?.clientHeight || 0;
  const cardDetailMultiplier =
    headerHeight <= 38 ? 8 : headerHeight <= 72 ? 4.7 : 3.5;

  const CardDetails = () => (
    <Box>
      <Box
        className="flex flex-col flex-grow overflow-y-auto"
        sx={{
          height: hasActiveTaskStartedSolving
            ? `calc(100vh - ${headerHeight * cardDetailMultiplier + (hideDrawerTabs ? 0 : 71)}px)`
            : '100%',
        }}
      >
        <Box
          className="flex rounded items-center py-2"
          sx={(theme) => ({
            border: `1px solid ${theme.palette.divider}`,
          })}
        >
          <Box className="flex flex-col items-center flex-1">
            <FdChip
              color={getParticipantAssessmentStatusColor(taskStatus)}
              size="medium"
              label={taskStatus}
              style={{ height: '24px' }}
            />
            <FdTypography
              variant="captiontext1"
              color="secondary"
              style={{ marginTop: '7px' }}
            >
              Status
            </FdTypography>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box className="flex flex-col items-center flex-1">
            <FdTypography variant="subtitle1">{taskAttempts}</FdTypography>
            <FdTypography
              variant="captiontext1"
              color="secondary"
              style={{ marginTop: '7px' }}
            >
              Attempts
            </FdTypography>
          </Box>
        </Box>
        <Box>
          {showActivitySelector && (
            <ActivitySelector
              activity={taskActivity}
              onActivityChange={onActivityChange}
            />
          )}
          <Box mt={2} mb={2} className="break-words">
            <FdTypography variant="subtitle1">Description</FdTypography>
            <FdTypography variant="body1" color="secondary">
              <ShowMoreText
                lines={8}
                more="See more"
                less="See less"
                anchorClass="see-more"
              >
                <FdMarkdownRender markdown={activeTask?.description} />
              </ShowMoreText>
            </FdTypography>
          </Box>
          {activeTask?.skills?.length > 0 && (
            <Box mb={2}>
              <FdTypography variant="subtitle1" data-cy="skills-title">
                Skills
              </FdTypography>
              <Box ml={4}>
                <FdTypography variant="body1" color="secondary">
                  <ul
                    key={shortid.generate()}
                    data-cy="skills"
                    style={{ listStyleType: 'disc' }}
                  >
                    {activeTask?.skills?.map((skill) => (
                      <li>{`${skill.name} (${skill.alias})`}</li>
                    ))}
                  </ul>
                </FdTypography>
              </Box>
            </Box>
          )}
        </Box>
        {taskOpenedLoading || updateTaskOpenedLoading ? (
          <FdLoadingSpinner />
        ) : hasActiveTaskStartedSolving ? (
          <Box className="flex flex-col justify-between h-full">
            <Box className="flex flex-col justify-between">
              <Box>
                {guided && activeTask?.solutionVideo && (
                  <Box mb={2} className={classes.description}>
                    <FdButton
                      variant="primary"
                      onClick={() => {
                        setSolutionModal(true);
                      }}
                    >
                      View Solution Walkthrough
                    </FdButton>
                  </Box>
                )}
                {taskStatus !== 'Completed' && (
                  <LabControl
                    assessmentId={assessmentId}
                    activeTask={activeTask}
                    user={globalSnap.user}
                    labId={activeTask?.labId}
                    modulePartId={activeTask?.modulePartId}
                  />
                )}
                {activeTask?.files?.items?.length !== 0 && (
                  <Box mb={2}>
                    <FdTypography variant="subtitle1">Attachments</FdTypography>
                    {activeTask?.files.items
                      .filter((file) =>
                        [
                          'application/x-zip-compressed',
                          'application/zip',
                        ].includes(file.type),
                      )
                      .map((f) => (
                        <FdFileAttachment name={f.name} />
                      ))}
                  </Box>
                )}
                {listHintRevealsLoading ? (
                  <FdLoadingSpinner />
                ) : (
                  listHintRevealsData?.listHintReveals?.items?.length > 0 && (
                    <Box mb={2}>
                      <FdTypography variant="subtitle1">Hints</FdTypography>
                      <FdTypography variant="body1" color="secondary">
                        {listHintRevealsData?.listHintReveals?.items?.map(
                          (item) => (
                            <Box key={item?.id}>
                              <Box display="flex" flexDirection="row" mt={1.5}>
                                <EmojiObjectsIcon />
                                <Box ml={1.5}>
                                  {
                                    activeTask?.hints?.items?.find(
                                      (_item) => _item?.id === item?.hintId,
                                    )?.text
                                  }
                                </Box>
                              </Box>
                            </Box>
                          ),
                        )}
                      </FdTypography>
                    </Box>
                  )
                )}
              </Box>
              <ChallengeRating
                userId={globalSnap.userId}
                assessmentId={assessmentDataFiltered?.assessment?.id}
                taskId={activeTask?.id}
                userAssessmentId={assessmentId}
              />
            </Box>
          </Box>
        ) : (
          <>
            <FdTypography color="secondary">
              Click below to see detailed challenge view including attachments,
              virtual environments, and hints to start solving the challenge.
            </FdTypography>
            <FdButton
              variant="secondary"
              onClick={() => {
                updateTaskOpenedMutation({
                  variables: {
                    input: {
                      id: activeTaskOpenedId || lastActiveTaskOpened?.id,
                      startedSolving: true,
                      startedSolvingAt: new Date().toISOString(),
                    },
                  },
                  onCompleted: (_data) => {
                    setLastActiveTaskOpened(_data.updateTaskOpened);
                    setActiveTaskOpenedId('');
                  },
                });
                updateUserAssessmentMutation({
                  variables: {
                    input: {
                      id: assessmentDataFiltered?.id,
                      lastActive: new Date().toISOString(),
                    },
                  },
                });
              }}
              style={{ marginTop: '16px' }}
            >
              Start Solving
            </FdButton>
          </>
        )}
      </Box>
      <Box>
        {taskStatus !== 'Completed' && hasActiveTaskStartedSolving && (
          <FlagSubmission
            onClickSubmit={() => onSubmitFlag(getValues('flag'))}
            loading={flagSubmissionLoading || flagSubmitToast}
            handleSubmit={handleSubmit}
            control={control}
            Controller={Controller}
          />
        )}
      </Box>
    </Box>
  );

  return (
    <Box>
      <FdBreadcrumbHeader page={{ name, type: 'COMPETITION' }} />
      <BasePage
        heading={name}
        data-cy="assessment-tasks-page"
        currentPageBreadcrumbLabel={name}
      >
        <Grid container>
          <Grid item xs>
            {multiLevel ? (
              <Box mb={6}>
                <Box height="80px" width="100%">
                  <AssessmentSummary
                    assessment={{
                      tasksCompleted,
                      endDateTime,
                      firstBloods,
                      totalPoints,
                      teamBased,
                      teamId: assessmentDataFiltered?.teamId,
                    }}
                    onFinish={() =>
                      stopAssessment({
                        variables: {
                          userAssessmentId,
                        },
                      })
                    }
                    loading={
                      tasksAttemptsLoading ||
                      taskAttemptsAssessmentTeamBasedLoading ||
                      listTaskAttemptsScoreboardLoading
                    }
                  />
                </Box>
              </Box>
            ) : (
              <FdAccordion
                showToggleButton={false}
                summary={() => (
                  <Box
                    height="80px"
                    width="100%"
                    sx={{ marginBottom: '-10px' }}
                  >
                    <AssessmentSummary
                      assessment={{
                        tasksCompleted,
                        endDateTime,
                        firstBloods,
                        totalPoints,
                        teamBased,
                        teamId: assessmentDataFiltered?.teamId,
                        orgId: assessmentDataFiltered?.user?.orgId,
                        userId: globalSnap.userId,
                      }}
                      onFinish={() =>
                        stopAssessment({
                          variables: {
                            userAssessmentId,
                          },
                        })
                      }
                      noBorderBox
                      loading={
                        tasksAttemptsLoading ||
                        taskAttemptsAssessmentTeamBasedLoading ||
                        listTaskAttemptsScoreboardLoading
                      }
                    />
                  </Box>
                )}
                content={() => (
                  <ProgressGraphs
                    data={tableRows}
                    barGraphLegendData={barGraphLegendData}
                    setBarGraphLegendData={setBarGraphLegendData}
                  />
                )}
                endAdornment
              />
            )}
            <Box className="flex w-full gap-x-4">
              {enableVPN && (
                <VPNConfiguration
                  orgId={assessmentDataFiltered?.user?.orgId}
                  userAssessmentId={assessmentDataFiltered?.id}
                  assessmentId={assessmentDataFiltered?.assessment?.id}
                />
              )}
              {enableJumpBox && (
                <ParticipantJumpBox assessmentId={assessmentId} />
              )}
            </Box>
            {multiLevel && (
              <Box width="100%" my={2}>
                <ChallengeFinder
                  setProficiency={setChallengeProficiency}
                  setStatus={setChallengeStatus}
                  setSpeciality={setChallengeSpeciality}
                  setSearchText={setSearchText}
                  searchText={searchText}
                />
              </Box>
            )}
            <Box className="flex w-full" mt={1}>
              {multiLevel && (
                <Box width="14%" style={{ marginRight: '1%' }}>
                  <LevelSelector
                    levels={allLevelDetails}
                    onSelect={(_level) => {
                      setLevelSelected(_level);
                      setBarGraphLegendData('');
                      handleOpenDrawer(false);
                    }}
                    customIcons={isCSCOrg}
                  />
                </Box>
              )}
              <Box width={multiLevel ? '85%' : '100%'}>
                {multiLevel && (
                  <FdAccordion
                    summary={() => (
                      <Box className="flex h-20 items-center justify-around w-full">
                        <Box px={2} width="80px">
                          <LevelIndicator
                            level={selectLevelData}
                            fontVariant="h4"
                            containerWidth={220}
                          />
                        </Box>
                        <Divider
                          orientation="vertical"
                          className={classes.divider}
                          flexItem
                        />
                        {tasksAttemptsLoading ||
                        taskAttemptsAssessmentTeamBasedLoading ? (
                          <ProgressSpinner />
                        ) : (
                          <ProgressBarWithTitle
                            color="blue"
                            title="Total Challenges attempted"
                            value={Math.round(
                              ((selectLevelData?.attempted ?? 0) /
                                (selectLevelData?.totalTasks ?? 1)) *
                                100,
                            )}
                            caption={`${selectLevelData?.attempted}/${selectLevelData?.totalTasks}`}
                          />
                        )}
                        <Divider
                          orientation="vertical"
                          className={classes.divider}
                          flexItem
                        />
                        {tasksAttemptsLoading ||
                        taskAttemptsAssessmentTeamBasedLoading ? (
                          <ProgressSpinner />
                        ) : (
                          <ProgressBarWithTitle
                            color="green"
                            title="Challenges solved"
                            value={Math.round(
                              ((selectLevelData?.solved ?? 0) /
                                (selectLevelData?.totalTasks ?? 1)) *
                                100,
                            )}
                            caption={`${selectLevelData?.solved}/${selectLevelData?.totalTasks}`}
                          />
                        )}
                        <Divider
                          orientation="vertical"
                          className={classes.divider}
                          flexItem
                        />
                        {tasksAttemptsLoading ||
                        taskAttemptsAssessmentTeamBasedLoading ? (
                          <ProgressSpinner />
                        ) : (
                          <ProgressBarWithTitle
                            color="purple"
                            title="Total Points earned"
                            value={Math.round(
                              ((selectLevelData?.pointScored || 0) /
                                (selectLevelData?.totalPoints || 1)) *
                                100,
                            )}
                            caption={`${selectLevelData?.pointScored}/${selectLevelData?.totalPoints}`}
                          />
                        )}
                        <Divider
                          orientation="vertical"
                          className={classes.divider}
                          flexItem
                        />
                      </Box>
                    )}
                    endAdornment
                    showToggleButton={false}
                    content={() => (
                      <ProgressGraphs
                        data={tableRows}
                        barGraphLegendData={barGraphLegendData}
                        setBarGraphLegendData={setBarGraphLegendData}
                      />
                    )}
                  />
                )}
                {tableRows.length > 0 && (
                  <Box mt={1}>
                    <TasksTable
                      rows={tableRows?.map((i) => {
                        return {
                          ...i,
                          filterItem: noFilterApplied
                            ? false
                            : (searchText !== ''
                                ? i?.searchItems?.includes(searchText)
                                : true) &&
                              (challengeProficiency?.length > 0
                                ? challengeProficiency?.includes(i?.difficulty)
                                : true) &&
                              (challengeSpeciality?.length > 0
                                ? challengeSpeciality?.includes(i?.specialty)
                                : true) &&
                              (challengeStatus?.length > 0
                                ? challengeStatus?.includes(i?.status)
                                : true),
                        };
                      })}
                      teamBased={teamBased}
                      onRowClick={(value) => {
                        setActiveTaskOpenedId('');
                        setLastActiveTaskOpened(undefined);
                        refetchTaskOpened();
                        handleOpenDrawer(true);
                        const selectedTask = tableRows?.find(
                          (t) => t.id === value?.row?.id,
                        );
                        setActiveTask(selectedTask);
                        setTaskStatus(selectedTask.status);
                        setTaskAttempts(selectedTask.attempts);
                        const activityLabel =
                          getCurrentActivityLabel(value?.row?.id) || '';
                        setTaskActivity(activityLabel);
                        reset();
                      }}
                      activityLoading={
                        taskActivitiesUserLoading ||
                        tasksActivitiesAllUsersLoading
                      }
                      attemptsLoading={
                        tasksAttemptsLoading ||
                        taskAttemptsAssessmentTeamBasedLoading
                      }
                    />
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Drawer
          variant="persistent"
          anchor="right"
          open={openDrawer}
          className={classes.drawer}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <Card>
            <CardHeader
              action={
                <IconButton
                  onClick={() => {
                    setActiveTaskOpenedId('');
                    setLastActiveTaskOpened(undefined);

                    handleOpenDrawer(false);
                  }}
                  size="large"
                >
                  <CloseIcon style={{ fontSize: 28 }} />
                </IconButton>
              }
              title={
                <Box
                  className="flex items-center gap-x-4 overflow-hidden"
                  maxHeight="110px"
                  ref={headerRef}
                >
                  <SpecialtyIcon specialty={activeTask?.specialty} />
                  <FdTypography variant="h4">{activeTask?.name}</FdTypography>
                </Box>
              }
              subheader={
                <TaskDrawerHeaderParticipant activeTask={activeTask} />
              }
            />
            <CardContent className="flex flex-col h-screen">
              <Divider />
              {hideDrawerTabs ? (
                <CardDetails />
              ) : (
                <FdTab
                  variant="fullWidth"
                  label={[
                    {
                      label: 'Challenge Resources',
                      index: 0,
                      data: <CardDetails />,
                    },
                    ...(teamBased
                      ? [
                          {
                            label: 'Team Chat',
                            index: 1,
                            data: (
                              <ParticipantChallengeChat
                                challengeId={activeTask?.id}
                                assessmentId={
                                  assessmentDataFiltered?.userAssessmentAssessmentId
                                }
                                team={teamMembersData?.getTeam || {}}
                              />
                            ),
                          },
                        ]
                      : []),
                    {
                      label: (
                        <FdTooltip
                          title={`${AI_ASSISTANT_NAME}, your challenge assistant, is here to help! Click to ask a question and get started.`}
                        >
                          <Box className="flex items-center justify-center gap-x-3">
                            {AI_ASSISTANT_NAME}
                            <Box
                              className="flex items-center justify-center h-6 rounded px-2"
                              sx={(theme) => ({
                                backgroundColor: theme?.palette?.primary?.main,
                                color: theme?.palette?.primary?.contrastText,
                                fontWeight: 500,
                                fontSize: '12px',
                                lineHeight: '14px',
                              })}
                            >
                              BETA
                            </Box>
                          </Box>
                        </FdTooltip>
                      ),
                      index: teamBased ? 2 : 1,
                      data: (
                        <ChallengeAIChat
                          userId={globalSnap?.userId}
                          assessmentId={assessmentDataFiltered?.assessment?.id}
                          userAssessmentId={assessmentId}
                          taskId={activeTask?.id}
                        />
                      ),
                    },
                  ]}
                />
              )}
            </CardContent>
          </Card>

          <FdModal
            size="xl"
            title="Challenge Solution Walkthrough"
            description={
              <Box display="flex" alignItems="center">
                <Box
                  style={{
                    width: '40%',
                    maxHeight: '400px',
                    overflowY: 'auto',
                    marginRight: '32px',
                  }}
                >
                  <FdMarkdownRender markdown={activeTask?.solutionSteps} />
                </Box>

                <div
                  style={{ width: '40%' }}
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: activeTask?.solutionVideo,
                  }}
                />
                <script src="https://player.vimeo.com/api/player.js" />
              </Box>
            }
            confirm="Close"
            showDismiss={false}
            open={solutionModal}
            onConfirm={() => {
              setSolutionModal(false);
            }}
          />
        </Drawer>
      </BasePage>
    </Box>
  );
};

export default AssessmentTasks;
