import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import {
  FdCard,
  FdMarkdownEditor,
  FdAlert,
  warningToastMessage,
} from '@fifthdomain/fe-shared';
import FdFileUploadModal from '../FdFileUploadModal/FdFileUploadModal';

const Solution = ({ triggerAfterSubmit }) => {
  const [solutionOpen, setSolutionOpen] = useState(false);
  const {
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();

  return (
    <Box>
      <FdCard
        heading="Solution"
        subHeading="Upload a challenge solution, or use the editor below to enter a solution."
        variant="outlined"
      >
        <Box>
          {errors.solnWalkthrough && (
            <Box mb={2}>
              <FdAlert
                alertTitle="Solution is required"
                variant="error"
                message="Please provide a solution walk-through in the markdown editor below. "
              />
            </Box>
          )}
          <Box mt={2}>
            <Controller
              control={control}
              name="solnWalkthrough"
              render={({ field }) => (
                <FdMarkdownEditor
                  id="soln-walkthrough"
                  name="solnWalkthrough"
                  markdown={field.value}
                  setMarkdown={field.onChange}
                  label="Solution Walkthrough"
                  required
                  {...field}
                />
              )}
            />
          </Box>
        </Box>
      </FdCard>
      <FdFileUploadModal
        title="Upload Solution"
        description="Select and upload .md files from your local drive."
        confirm="Save"
        dismiss="Cancel"
        open={solutionOpen}
        onConfirm={(files) => {
          setSolutionOpen(false);
          setValue('uploadSolnAttachments', [
            ...getValues().uploadSolnAttachments,
            ...files,
          ]);
          triggerAfterSubmit(['uploadSolnAttachments', 'solnWalkthrough']);
        }}
        onDismiss={() => {
          setSolutionOpen(false);
          warningToastMessage('No files were added');
        }}
        setOpen={setSolutionOpen}
        fileTypes=".md"
        maxWidth="md"
      />
    </Box>
  );
};

Solution.propTypes = {
  preHeader: PropTypes.func,
  triggerAfterSubmit: PropTypes.func.isRequired,
};

Solution.defaultProps = {
  preHeader: () => {},
};

export default Solution;
