import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import * as singleSpa from 'single-spa';
import { useFormContext } from 'react-hook-form';
import {
  FdCard,
  FdAlert,
  FdTypography,
  FdButton,
  FdModal,
  successToastMessage,
  warningToastMessage,
} from '@fifthdomain/fe-shared';
import {
  updateFunction,
  updateSquad,
  updateSquadMember,
} from '../../../graphql/mutations';
import { onError, updateFunctionStatus } from '../utils';

const DeleteFunction = () => {
  const [showInactiveModal, setShowInactiveModal] = useState(false);
  const { getValues } = useFormContext();
  const { functionId, squads } = getValues();
  const [updateFunctionMutation, { loading: updateFunctionMutationLoading }] =
    useMutation(gql(updateFunction), {
      onError,
    });
  const [updateSquadMutation, { loading: updateSquadMutationLoading }] =
    useMutation(gql(updateSquad), {
      onError,
    });
  const [updateSquadUserMutation, { loading: updateSquadUserMutationLoading }] =
    useMutation(gql(updateSquadMember), {
      onError,
    });
  const squadsForDeactivation = squads?.map((s) => ({
    squadId: s?.squadId,
    users: [
      ...(s?.squadMembers?.map((m) => m?.pkId) || []),
      ...(s?.squadManagers?.map((m) => m?.pkId) || []),
    ],
  }));
  const loading =
    updateFunctionMutationLoading ||
    updateSquadMutationLoading ||
    updateSquadUserMutationLoading;

  return (
    <FdCard variant="outlined" sx={{ mt: 2 }}>
      <FdTypography variant="h3">Delete Function</FdTypography>
      <FdAlert
        variant="warning"
        alertTitle="Attention: Deleting function can't be undone."
        message="Before clicking the Delete Function button below, please be aware that deleting a function is irreversible. All function target data, and associated information will be permanently lost."
        sx={{ mt: 2 }}
      />
      <FdButton
        style={{
          marginTop: '1rem',
          color: 'white',
          backgroundColor: 'rgba(198, 40, 40, 1)',
        }}
        onClick={() => setShowInactiveModal(true)}
      >
        delete this function
      </FdButton>
      <FdModal
        title="Delete this function?"
        description={
          <>
            Are you sure that you want to delete this function?
            <br />
            <br />
            If so, all function history, data, and associated information will
            be permanently lost.
            <br />
            <br />
            Please ensure that this is your intended action, as it cannot be
            undone.
          </>
        }
        confirm="proceed with deletion"
        showConfirmInRed
        dismiss="Cancel"
        open={showInactiveModal}
        disableConfirm={loading}
        confirmLoading={loading}
        onConfirm={() => {
          updateFunctionStatus({
            updateFunctionMutation,
            updateSquadMutation,
            updateSquadUserMutation,
            functionId,
            squads: squadsForDeactivation,
            onCompleted: () => {
              setShowInactiveModal(false);
              singleSpa.navigateToUrl('/user-management/workforce/functions');
              successToastMessage('Function is now deleted.');
            },
            status: 'REMOVED',
          });
        }}
        onDismiss={() => {
          setShowInactiveModal(false);
          warningToastMessage('Function not deleted.');
        }}
      />
    </FdCard>
  );
};

export default DeleteFunction;
