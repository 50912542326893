import React, { useState } from 'react';
import { Box, Divider, IconButton } from '@mui/material';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SendIcon from '@mui/icons-material/Send';
import {
  FdTypography,
  FdModal,
  FdTooltip,
  FdTable,
  FdChip,
  useSnapshot,
  globalStore,
  FdButton,
  FdMultiChipTextField,
  errorToastMessage,
  successToastMessage,
  warningToastMessage,
} from '@fifthdomain/fe-shared';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  getTeamMemberColor,
  getTeamMemberTypeColor,
} from '../../shared/utils/getStatusColor';
import { TEAM_MEMBER_TYPES } from '../../constants';
import { addUserToTeam } from '../../graphql/mutations';
import { listScoreboardsByTeamId } from '../../graphql/queries';

const ViewTeam = ({ viewTeam, setViewTeam, existingTeamData, onConfirm }) => {
  const [remove, setRemove] = useState(false);
  const globalSnap = useSnapshot(globalStore);
  const isAAFC = globalSnap?.isAAFCOrg;
  const initialValues = {
    emails: [],
  };
  const validationSchema = Yup.object().shape({
    emails: Yup.array()
      .of(Yup.string().email('Please enter a valid email'))
      .min(1, 'Please enter an email before sending an invite.')
      .max(
        50,
        'You cannot invite over 50 users in one invitation instance. Please reduce the number of emails and try again.',
      )
      .test('unique-emails', function (value) {
        const { path, createError } = this;
        const duplicateEmails = existingTeamData
          ?.map((item) => item?.team?.members?.items?.map((i) => i?.email))
          ?.flat()
          ?.filter((element) => value?.includes(element));
        if (duplicateEmails?.length > 0)
          return createError({
            path,
            message: `${duplicateEmails} already a part of another team for this competition`,
          });
        return true;
      }),
  });

  const {
    control,
    getValues,
    trigger,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const rows =
    viewTeam?.team?.members?.items?.map((item) => ({
      id: item?.id,
      alias: item?.User?.alias,
      email: item?.email,
      registration: item?.User?.alias ? 'Registered' : 'Invited',
      type: item?.role,
    })) || [];

  const [fetchScoreboard, { loading: scoreboardLoading }] = useLazyQuery(
    gql(listScoreboardsByTeamId),
  );

  const [addUserToTeamMutation, { loading: addUserToTeamLoading }] =
    useMutation(gql(addUserToTeam));

  const onSendInvites = async (e) => {
    e.preventDefault();
    const valid = await trigger(['emails']);
    if (valid) {
      const { emails } = getValues();
      // add users to the team
      addUserToTeamMutation({
        variables: {
          action: 'ADD',
          emails,
          participantEventType: 'COMPETITION',
          role: Object.keys(TEAM_MEMBER_TYPES)[1],
          teamId: viewTeam?.teamId,
        },
        onCompleted: (_data) => {
          onConfirm();
          setValue('emails', []);
          successToastMessage(
            _data?.manageUserAndTeam?.message || 'Team invites sent!',
          );
          setViewTeam(false);
        },
        onError: ({ graphQLErrors }) => {
          if (graphQLErrors) {
            errorToastMessage(graphQLErrors[0]?.message);
          }
        },
      });
    }
  };

  return (
    <FormProvider>
      <form>
        <FdModal
          size="lg"
          title={viewTeam?.name}
          description={
            <>
              <Box display="flex" alignItems="center">
                <FdTypography variant="subtitle1" color="primary">
                  Team Member List
                </FdTypography>
                <FdTooltip title="A team consists of two role types: Team Managers (who can edit and manage the team) and General Members (who can view the team). Teams can have any number of Team Managers.">
                  <IconButton size="small" style={{ marginLeft: '10px' }}>
                    <InfoOutlinedIcon />
                  </IconButton>
                </FdTooltip>
              </Box>
              <FdTypography variant="captiontext1" color="primary">
                A current list of team members and role types is listed below.
              </FdTypography>
            </>
          }
          dismiss="close"
          open={viewTeam}
          onDismiss={() => {
            reset();
            setViewTeam(false);
          }}
          data-cy="delete-team-modal"
          showConfirm={false}
        >
          <>
            <Box height="320px" mt={3}>
              <FdTable
                noBorderDisplay
                rowHeight={60}
                toolbarSettings={{
                  hide: true,
                }}
                defaultMuiToolbarSettings={{
                  showMuiDefaultToolbar: false,
                }}
                density="compact"
                rows={rows}
                actions={
                  isAAFC && [
                    {
                      label: 'remove',
                      onClick: (row) => {
                        setRemove(row);
                      },
                      show: () => isAAFC,
                    },
                  ]
                }
                columns={[
                  {
                    field: 'alias',
                    width: 300,
                    headerName: isAAFC ? 'Alias and Email' : 'Member Alias',
                    renderCell: (params) => {
                      const result = params?.value ? (
                        <Box>
                          <FdTypography variant="captiontext1">
                            {params?.value}
                          </FdTypography>
                          {isAAFC && (
                            <FdTypography variant="captiontext1">
                              {params?.row?.email}
                            </FdTypography>
                          )}
                        </Box>
                      ) : (
                        <Box>
                          <Box display="flex" alignItems="center">
                            <FdTypography
                              variant="captiontext1"
                              color="secondary"
                            >
                              No alias yet
                            </FdTypography>
                            <FdTooltip title="This team member will receive an alias when they register onto the platform.">
                              <IconButton
                                size="small"
                                style={{ marginLeft: '10px' }}
                              >
                                <InfoOutlinedIcon />
                              </IconButton>
                            </FdTooltip>
                          </Box>
                          {isAAFC && (
                            <FdTypography
                              variant="captiontext1"
                              color="secondary"
                            >
                              {params?.row?.email}
                            </FdTypography>
                          )}
                        </Box>
                      );
                      return result;
                    },
                  },
                  {
                    field: 'registration',
                    width: 140,
                    headerName: 'Registration',
                    renderCell: (params) => (
                      <FdChip
                        color={getTeamMemberColor(params?.row?.registration)}
                        size="small"
                        label={params?.row?.registration || ''}
                      />
                    ),
                  },
                  {
                    field: 'type',
                    headerName: 'Role Type',
                    width: 150,
                    renderCell: (params) => (
                      <FdChip
                        style={{
                          backgroundColor: getTeamMemberTypeColor(
                            TEAM_MEMBER_TYPES[params?.row?.type],
                          ),
                        }}
                        size="small"
                        label={TEAM_MEMBER_TYPES[params?.row?.type] || ''}
                      />
                    ),
                  },
                ]}
                gridId="competitions-view-teams"
              />
            </Box>
            <Box my={3}>
              <FdTypography variant="subtitle2" fontWeight="bold">
                {`Current Member Count: ${rows?.length}`}
              </FdTypography>
            </Box>
            {isAAFC && (
              <>
                <Divider />
                <Box display="flex" alignItems="center" mt={3}>
                  <FdTypography variant="subtitle1">
                    Invite Team Members
                  </FdTypography>
                  <FdTooltip title="Invited users will receive an email to log in/register to the FifthDomain platform. When they log in, they will be able to view your team on their My Teams page.">
                    <IconButton size="small" style={{ marginLeft: '10px' }}>
                      <InfoOutlinedIcon />
                    </IconButton>
                  </FdTooltip>
                </Box>
                <FdTypography variant="captiontext1">
                  Invite new members to this team by typing or pasting email
                  addresses into the box below and clicking the Invite button.
                </FdTypography>
                <Box mt={3} height="130px">
                  <Box className="flex justify-between mb-2 items-center">
                    <FdTypography
                      variant="captiontext1"
                      style={{ fontWeight: 'bold' }}
                    >
                      Invitee Email Addresses
                    </FdTypography>
                    <FdButton
                      startIcon={<SendIcon />}
                      onClick={onSendInvites}
                      disabled={errors?.emails || addUserToTeamLoading}
                    >
                      Send invites
                    </FdButton>
                  </Box>
                  <Controller
                    control={control}
                    name="emails"
                    render={({
                      field: { ref, value, onChange, ...rest },
                      fieldState: { error },
                    }) => {
                      const allErrors =
                        error?.length > 0
                          ? [...new Set(error?.map((e) => e.message))]
                          : [error?.message];
                      return (
                        <Box>
                          <FdMultiChipTextField
                            id="emails"
                            width="100%"
                            label=""
                            required
                            values={value}
                            setValues={onChange}
                            error={error}
                            helperText={allErrors}
                            {...rest}
                            inputRef={ref}
                          />
                        </Box>
                      );
                    }}
                  />
                </Box>
              </>
            )}
          </>
        </FdModal>

        <FdModal
          size="sm"
          title="Remove this team member?"
          description="Are you sure that you want to remove this team member? They will no longer be part of this team if you choose to proceed with this action."
          dismiss={
            addUserToTeamLoading || scoreboardLoading ? 'Loading' : 'confirm'
          }
          confirm="cancel"
          disableDismiss={addUserToTeamLoading || scoreboardLoading}
          open={remove}
          onDismiss={async () => {
            await addUserToTeamMutation({
              variables: {
                action: 'REMOVE',
                emails: [remove?.email],
                participantEventType: 'COMPETITION',
                role: Object.keys(TEAM_MEMBER_TYPES)[1],
                teamId: viewTeam?.teamId,
              },
            });
            onConfirm();
            successToastMessage('Team member removed!');
            fetchScoreboard({ variables: { teamId: viewTeam?.teamId } });
            setRemove(false);
            setViewTeam(false);
          }}
          onConfirm={() => {
            setRemove(false);
            warningToastMessage('Team member not removed');
          }}
          data-cy="delete-team-modal"
        />
      </form>
    </FormProvider>
  );
};

ViewTeam.propTypes = {
  viewTeam: PropTypes.shape({
    teamId: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    team: PropTypes.shape({
      name: PropTypes.string,
      members: PropTypes.shape({
        items: PropTypes.arrayOf(PropTypes.objectOf({})),
      }),
    }),
  }),
  setViewTeam: PropTypes.func,
  onConfirm: PropTypes.func,
  existingTeamData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      team: PropTypes.shape({
        name: PropTypes.string,
        members: PropTypes.shape({
          items: PropTypes.arrayOf(PropTypes.objectOf({})),
        }),
      }),
    }),
  ),
};

ViewTeam.defaultProps = {
  setViewTeam: () => {},
  viewTeam: undefined,
  onConfirm: () => {},
  existingTeamData: [],
};

export default ViewTeam;
