import { useContext } from 'react';
import { useSnapshot } from 'valtio';
import { gql, useQuery } from '@apollo/client';
import { getUserById } from '../../queries/customQueries';
import globalStore from '../../store';
import { AuthContext } from '../../contexts';
import { Authorization } from '../../shared';
import useLocalStorage from '../useLocalStorage';
import { getUserType } from './utils';

const useInitializeDefaultStore = (userId) => {
  const snap = useSnapshot(globalStore);
  const { user } = useContext(AuthContext);

  const [userPreferencesSidebar] = useLocalStorage(
    'user-preferences-sidebar-user-mode',
  );

  // get orgId and type
  useQuery(gql(getUserById), {
    variables: {
      id: userId,
    },
    fetchPolicy: 'network-only',
    skip: !userId || Boolean(snap.userId),
    onCompleted: (_data) => {
      const {
        orgId,
        org,
        hmacId,
        email,
        theme,
        alias,
        name,
        permissions,
        participantType,
        key,
      } = _data?.getUserById?.items[0] || {};
      const isAffiliated = participantType === 'INTERNAL';
      const hasManagerialPermission = isAffiliated
        ? Authorization.hasManagerialPermissions(permissions)
        : false;
      // set user type based on user preferences and permissions
      globalStore.userType = getUserType({
        userPreferencesSidebarMode: userPreferencesSidebar?.[userId]?.mode,
        hasManagerialPermission,
      });
      globalStore.userHmacId = hmacId || '';
      globalStore.userEmail = email?.toLowerCase() || '';
      globalStore.userId = user?.userId || '';
      globalStore.userName = name || '';
      globalStore.userAlias = alias || '';
      globalStore.userAvatarKey = key ? `/user-avatars/${key}` : undefined;
      if (!isAffiliated) {
        globalStore.orgId = orgId;
        globalStore.orgAlias = org?.alias || '';
        globalStore.orgName = org?.name || '';
        globalStore.orgPricingTier = org?.pricingTier || '';
        globalStore.orgMaxInvitees = org?.maxInvitees || '';
      }
      const userTheme = theme?.toLowerCase() || '';
      globalStore.theme = userTheme;
      const isFdAmin =
        user?.signInUserSession?.accessToken.payload['cognito:groups'].includes(
          'fd-admin',
        );
      globalStore.permissions = [
        ...permissions,
        ...(isFdAmin ? ['FD_ADMIN'] : []),
      ];
      globalStore.isAffiliated = isAffiliated;
      globalStore.hasManagerialPermissions = isAffiliated
        ? permissions?.filter(
            (p) => !['PARTICIPATE', 'MANAGE_PROFILE'].includes(p), // managerial permissions includes all other permissions other than PARTICIPATE and MANAGE_PROFILE
          )?.length > 0
        : false;
    },
    onError: (err) => {
      globalStore.loading = false;
      globalStore.error = err;
    },
  });
};

export default useInitializeDefaultStore;
