import React, { useState } from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { useMutation, gql } from '@apollo/client';
import { Controller, useFormContext } from 'react-hook-form';
import EmailIcon from '@mui/icons-material/Email';
import {
  FdCard,
  FdTextField,
  FdTypography,
  FdButton,
  FdTooltip,
  removeDashes,
  removeWhiteSpace,
  FdExternalLink,
  warningToastMessage,
  successToastMessage,
  errorToastMessage,
  globalStore,
} from '@fifthdomain/fe-shared';
import { PROFILE_DEFAULT_VALUES } from '../../constants';
import { updateUser } from '../../graphql/mutations';
import FdImageUpload from '../ParticipantTeams/FdImageUpload';
import { uploadFileToS3 } from '../../shared/utils/fileUploadAWS';
import UserAvatar from './UserAvatar';

const Details = ({ previousAlias, refetchUserData }) => {
  const [editMode, setEditMode] = useState(false);
  const { control, getValues, trigger, reset, setValue, watch, getFieldState } =
    useFormContext();
  const watchAvatar = watch('avatar');

  const [updateUserMutation, { loading: updateUserLoading }] = useMutation(
    gql(updateUser),
    {
      onError: ({ graphQLErrors }) => {
        errorToastMessage(graphQLErrors[0]?.message);
      },
    },
  );

  return (
    <FdCard
      variant="outlined"
      heading={
        editMode ? (
          <Box display="flex" justifyContent="space-between">
            <FdTypography variant="h3">Key Details</FdTypography>
            <Box display="flex">
              <FdButton
                variant="secondary"
                size="small"
                onClick={() => {
                  reset();
                  setEditMode(false);
                  warningToastMessage('Updates not saved');
                }}
                style={{
                  marginRight: '8px',
                }}
              >
                CANCEL
              </FdButton>
              <FdButton
                variant="primary"
                size="small"
                onClick={async () => {
                  const details = await trigger([
                    'name',
                    'alias',
                    'error',
                    // 'location',
                    // 'workStatus',
                  ]);
                  if (details) {
                    const {
                      id,
                      name,
                      phone,
                      alias,
                      currentOccupation,
                      avatar,
                      // location,
                      // workStatus,
                    } = getValues();
                    const userAlias =
                      alias === previousAlias ? undefined : alias;
                    const _phone = phone
                      ? removeDashes(removeWhiteSpace(phone))
                      : 'N/A';
                    const avatarKey = `${name}/${avatar?.name}`
                      .replace(/\s/g, '-')
                      .toLowerCase();
                    const isAvatarChanged = getFieldState('avatar')?.isDirty;
                    await updateUserMutation({
                      variables: {
                        input: {
                          id,
                          name,
                          phone: _phone,
                          alias: userAlias,
                          currentOccupation,
                          ...(isAvatarChanged && {
                            key: avatarKey,
                          }),
                          //  location,
                          //  workStatus: getKeyByValue(WORK_STATUS, workStatus),
                        },
                      },
                      onCompleted: () => {
                        if (isAvatarChanged) {
                          const _avatarKey = avatarKey
                            ? `/user-avatars/${avatarKey}`
                            : undefined;
                          uploadFileToS3({
                            key: _avatarKey,
                            file: avatar,
                            level: 'public',
                          });
                          globalStore.userAvatarKey = _avatarKey;
                        }
                        successToastMessage('Updates saved!');
                        refetchUserData();
                      },
                    });
                    setEditMode(false);
                  }
                }}
              >
                {updateUserLoading ? 'SAVING...' : 'SAVE'}
              </FdButton>
            </Box>
          </Box>
        ) : (
          <Box display="flex" justifyContent="space-between">
            <Box className="flex items-center gap-2">
              <UserAvatar size="small" user={getValues()} />
              <FdTypography variant="h3">{getValues()?.name}</FdTypography>
              <FdTooltip title={getValues().email}>
                <EmailIcon
                  style={{
                    marginTop: '4px',
                  }}
                />
              </FdTooltip>
            </Box>
            <FdButton
              variant="primary"
              size="small"
              onClick={() => setEditMode(true)}
            >
              Edit
            </FdButton>
          </Box>
        )
      }
      data-cy="details"
    >
      {editMode ? (
        <Box>
          <Controller
            control={control}
            name="name"
            render={({ field: { ref, ...rest }, fieldState: { error } }) => (
              <Box mt={1} mb={3}>
                <FdTextField
                  id="name"
                  label="Full Name"
                  required
                  fullWidth
                  error={error}
                  helperText={error && error.message}
                  data-cy="name"
                  {...rest}
                  inputRef={ref}
                />
              </Box>
            )}
          />
          <Controller
            control={control}
            name="alias"
            render={({ field: { ref, ...rest }, fieldState: { error } }) => (
              <Box mb={3}>
                <FdTextField
                  id="alias"
                  label="Alias"
                  required
                  fullWidth
                  error={error}
                  helperText={error && error.message}
                  data-cy="alias"
                  {...rest}
                  inputRef={ref}
                />
              </Box>
            )}
          />
          <Box display="flex" alignItems="center">
            <FdTypography variant="subtitle1">User Avatar</FdTypography>
            <FdTypography
              variant="captiontext1"
              color="secondary"
              style={{ paddingLeft: '0.5rem' }}
            >
              optional
            </FdTypography>
          </Box>
          <FdTypography variant="captiontext1" color="secondary">
            To upload an avatar, click ‘Upload Image’ below. Please note, files
            larger than 2MB are not supported. Only JPEG, JPG, and PNG image
            formats can be uploaded. For best results, use an image with a
            square size ratio, as this ensures optimal avatar framing.
          </FdTypography>
          <Box mt={1}>
            <FdImageUpload
              onDrop={(files) => {
                setValue('avatar', files[0], { shouldDirty: true });
              }}
              onDropRejected={(files) => {
                setValue('avatar', files[0]?.file);
                setValue('error', true);
              }}
              fileSelected={watchAvatar}
              onDelete={() => {
                setValue('avatar', undefined);
                setValue('error', false);
              }}
            />
          </Box>

          <Box mt={3}>
            <FdTypography variant="subtitle1">Age</FdTypography>
            <FdTypography variant="body1" color="secondary">
              {getValues()?.ageBracket}
            </FdTypography>
          </Box>
          <Box mb={3} mt={3}>
            <FdTypography variant="subtitle1">Email Address</FdTypography>
            <FdTypography variant="body1" color="secondary">
              {getValues()?.email}
            </FdTypography>
          </Box>
          <Controller
            control={control}
            name="currentOccupation"
            render={({ field: { ref, ...rest }, fieldState: { error } }) => (
              <Box mb={3}>
                <FdTextField
                  id="currentOccupation"
                  label="Current Occupation"
                  fullWidth
                  data-cy="currentOccupation"
                  {...rest}
                  inputRef={ref}
                  error={error}
                  helperText={error && error.message}
                />
              </Box>
            )}
          />

          {/* <Controller
            control={control}
            name="location"
            render={({ field: { ref, ...rest }, fieldState: { error } }) => (
              <Box mb={3}>
                <FdAutocomplete
                  id="location"
                  label="Location"
                  options={autocompleteCities}
                  fullWidth
                  onInputChange={handleChange}
                  defaultValue={rest?.value}
                  {...rest}
                  inputRef={ref}
                  error={error}
                  helperText={error && error.message}
                />
              </Box>
            )}
          /> */}
          {/* <Controller
            control={control}
            name="workStatus"
            render={({ field, fieldState: { error } }) => (
              <Box mt={1} mb={2}>
                <FdSelect
                  id="workStatus"
                  label="Right to Work Status *"
                  options={Object.values(WORK_STATUS)}
                  error={error}
                  helperText={error && error.message}
                  fullWidth
                  defaultSelected={field?.value}
                  {...field}
                />
              </Box>
            )}
          /> */}
        </Box>
      ) : (
        <Box display="flex" flexDirection="column">
          {/*
          <Box display="flex" flexDirection="row" my={1}>
            <FdChip
              size="small"
              label={getValues()?.location || PROFILE_DEFAULT_VALUES[1]}
              rounded={false}
            />
            <FdChip
              size="small"
              label={getValues()?.workStatus || PROFILE_DEFAULT_VALUES[2]}
              rounded={false}
              style={{
                marginLeft: '8px',
              }}
            /> 
          </Box>
          */}
          <Box mb={3}>
            <FdTypography variant="subtitle1">Alias</FdTypography>
            <FdTypography variant="subtitle1" color="secondary">
              {getValues()?.alias}
            </FdTypography>
          </Box>
          <FdTypography variant="subtitle1">User Avatar</FdTypography>
          <FdTypography variant="subtitle1" color="secondary">
            {(getValues()?.key && (
              <>
                User avatar file:
                <FdExternalLink>{getValues()?.key}</FdExternalLink>
              </>
            )) ||
              'None Added'}
          </FdTypography>
          <Box my={3}>
            <FdTypography variant="subtitle1">Age</FdTypography>
            <FdTypography variant="subtitle1" color="secondary">
              {getValues()?.ageBracket}
            </FdTypography>
          </Box>
          <FdTypography variant="subtitle1">Current Occupation</FdTypography>
          <FdTypography variant="subtitle1" color="secondary">
            {getValues()?.currentOccupation || PROFILE_DEFAULT_VALUES[0]}
          </FdTypography>
        </Box>
      )}
    </FdCard>
  );
};

Details.propTypes = {
  previousAlias: PropTypes.string.isRequired,
  refetchUserData: PropTypes.func.isRequired,
};

export default Details;
