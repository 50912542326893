import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { useFormContext, Controller, useFieldArray } from 'react-hook-form';
import {
  FdTypography,
  FdTextField,
  successToastMessage,
} from '@fifthdomain/fe-shared';
import CompetenciesSelector from '../Competencies/NICE/CompetenciesSelector';

const QuestionPoints = ({ qIdx, userGraded }) => {
  const { control, trigger } = useFormContext();
  const {
    fields: questionCompetencies,
    append: appendQuestionCompetencies,
    remove: removeQuestionCompetencies,
  } = useFieldArray({
    control,
    name: `questions[${qIdx}].competencies`,
  });

  const { append: appendDeletedCompetencies } = useFieldArray({
    control,
    name: `questions[${qIdx}].deletedCompetencies`,
  });

  return (
    <Box>
      <Box mt={2} mb={2}>
        {userGraded && (
          <Box>
            <FdTypography fontWeight="bold">
              Points for the Question
            </FdTypography>
            <Controller
              control={control}
              name={`questions[${qIdx}].points`}
              render={({ field: { ref, ...rest }, fieldState: { error } }) => (
                <Box mt={1} mb={2}>
                  <FdTextField
                    id={`questions[${qIdx}].points`}
                    required
                    type="number"
                    width="168px"
                    error={error}
                    helperText={error && error.message}
                    data-cy={`questions[${qIdx}].points`}
                    {...rest}
                    inputRef={ref}
                  />
                </Box>
              )}
            />
          </Box>
        )}

        <Box mt={2}>
          <Box mb={1}>
            <FdTypography fontWeight="bold">Competencies</FdTypography>
          </Box>
          <CompetenciesSelector
            existingCompetencies={questionCompetencies}
            onCompetencySelection={(values) => {
              appendQuestionCompetencies(values);
              trigger(`questions[${qIdx}].competencies`);
              successToastMessage(
                `${values?.length} competencies added to the quiz`,
              );
            }}
            onCompetencyDeletion={(value) => {
              const compIndex = questionCompetencies.findIndex(
                (c) => c.id === value?.id,
              );
              removeQuestionCompetencies(compIndex);
              successToastMessage(
                'Success! The Competency was removed from the quiz',
              );
              // mark for deletion via api
              if (value?.cDbId) {
                appendDeletedCompetencies(value.cDbId);
              }
              trigger(`questions[${qIdx}].competencies`);
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

QuestionPoints.propTypes = {
  qIdx: PropTypes.number.isRequired,
  userGraded: PropTypes.bool,
};

QuestionPoints.defaultProps = {
  userGraded: true,
};

export default QuestionPoints;
