import React from 'react';
import { useParams, Link as RouterLink } from 'react-router-dom';
import { Box } from '@mui/material';
import { BasePage, FdTab, FdProgress } from '@fifthdomain/fe-shared';
import { FdBreadcrumbHeader } from '@fifthdomain/sidebar';
import { useQuery, gql } from '@apollo/client';
import { OrgUsers, OrgWorkRoles } from '../components/Organisation';
import EditOrgDetail from '../components/Organisation/EditOrgDetail';
import EditOrgContent from '../components/Organisation/EditOrgContent';
import { getOrg } from '../graphql/queries';

const ViewOrg = () => {
  const { orgId } = useParams();

  const { data: getOrgData, loading: getOrgLoading } = useQuery(gql(getOrg), {
    variables: {
      id: orgId,
    },
  });
  if (getOrgLoading) {
    return <FdProgress />;
  }

  const orgName = getOrgData?.getOrg?.name;

  return (
    <Box>
      <FdBreadcrumbHeader
        entries={[
          { name: 'Organisations', path: '/org', type: 'ORGANISATION' },
        ]}
        page={{ name: orgName, type: 'ORGANISATION' }}
      />
      <BasePage heading={`Edit ${orgName}`} data-cy="view-org">
        <FdTab
          label={[
            {
              label: 'Details',
              tabRoute: `/org/view/${orgId}?tabindex=0`,
              index: 0,
              data: <EditOrgDetail />,
            },
            {
              label: 'Affiliated Users',
              tabRoute: `/org/view/${orgId}?tabindex=1`,
              index: 1,
              data: <OrgUsers orgId={orgId} />,
            },
            {
              label: 'Work Roles',
              tabRoute: `/org/view/${orgId}?tabindex=1`,
              index: 2,
              data: <OrgWorkRoles orgId={orgId} />,
            },
            {
              label: 'Assigned Content',
              tabRoute: `/org/view/${orgId}?tabindex=3`,
              index: 3,
              data: <EditOrgContent />,
            },
          ]}
          tabLinkComponent={RouterLink}
        />
      </BasePage>
    </Box>
  );
};

export default ViewOrg;
