import React from 'react';
import { Route, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  useAuthSession,
  useSnapshot,
  globalStore,
  FdLoadingSpinner,
  Authorization,
} from '@fifthdomain/fe-shared';

const CreateFunctionRoute = ({ component: Component, ...rest }) => {
  const { user } = useAuthSession();
  const { userType, loading, permissions } = useSnapshot(globalStore);
  const history = useHistory();

  // only user with permission manage-functions
  const canManageFunctions = Authorization.canManageFunctions(permissions);

  if (loading || !permissions) return <FdLoadingSpinner />;

  return (
    <Route
      {...rest}
      render={(props) => {
        if (user && userType !== 'PARTICIPANT' && canManageFunctions) {
          return <Component {...rest} {...props} />;
        }
        return history.goBack();
      }}
    />
  );
};

CreateFunctionRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
};

export default CreateFunctionRoute;
