export const getTemplateStatusColor = (status) => {
  switch (status) {
    case 'RELEASED':
      return 'success';
    case 'UNRELEASED':
    case 'DRAFT':
      return 'default';
    case 'ROLLBACKED':
      return 'error';
    default:
      return 'default';
  }
};

export const getAvailabilityColor = (status) => {
  switch (status) {
    case 'Active':
      return 'success';
    case 'Upcoming':
      return 'primary';
    case 'Ended':
      return 'error';
    default:
      return 'success';
  }
};
