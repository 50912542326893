import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton } from '@mui/material';
import {
  FdTable,
  FdSkeleton,
  FdButton,
  FdTooltip,
  FdTypography,
  warningToastMessage,
  successToastMessage,
} from '@fifthdomain/fe-shared';
import _ from 'lodash';
import { gql, useMutation } from '@apollo/client';
import AddWorkRole from './AddWorkRole';
import {
  deleteCustomerWorkrole,
  deleteCustomerSkillWorkrole,
  updateCustomerWorkrole,
  createCustomerSkillWorkrole,
  updateCustomerSkillWorkrole,
} from '../../../graphql/mutations';
import DeleteModal from './DeleteModal';
import { duplicateName } from '../../../shared/utils/duplicateName';

const WorkRoleTable = ({
  workRoleData,
  customerSkillsFdSkills,
  customerSkillsWorkroles,
  customerSkillData,
  refetchQueries,
  loading,
}) => {
  const [workRoleModal, setWorkRoleModal] = useState(false);
  const [editDetails, setEditDetails] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);

  // Delete Mutataions
  const [
    deleteCustomerWorkroleMutation,
    { loading: deleteCustomerWorkroleLoading },
  ] = useMutation(gql(deleteCustomerWorkrole));
  const [
    deleteCustomerSkillWorkroleMutation,
    { loading: deleteCustomerSkillWorkroleLoading },
  ] = useMutation(gql(deleteCustomerSkillWorkrole));

  // Update Mutataions
  const [
    updateCustomerWorkroleMutation,
    { loading: updateCustomerWorkroleLoading },
  ] = useMutation(gql(updateCustomerWorkrole));

  const [
    updateCustomerSkillWorkroleMutation,
    { loading: updateCustomerSkillWorkroleLoading },
  ] = useMutation(gql(updateCustomerSkillWorkrole));

  const [
    createCustomerSkillWorkroleMutation,
    { loading: createCustomerSkillWorkroleLaoding },
  ] = useMutation(gql(createCustomerSkillWorkrole));

  const columns = [
    {
      field: 'name',
      width: 450,
      headerName: 'Customer Work Role Name',
    },
    {
      field: 'workRoleSkills',
      width: 450,
      headerName: 'Number of Customer Skills',
      renderCell: (params) => {
        const fdSkillData = _.uniqBy(
          params?.value
            ?.map(({ fdSkills }) => fdSkills?.map(({ skill }) => skill))
            .flat(),
          'id',
        );
        return (
          <FdTooltip
            position="right-end"
            title={
              <Box m={2}>
                <FdTypography>FifthDomain Skills:</FdTypography>
                {fdSkillData?.map((i) => (
                  <ul key={i?.id} style={{ listStyleType: 'disc' }}>
                    <li>{`${i?.name} (${i?.alias}) `}</li>
                  </ul>
                ))}
              </Box>
            }
          >
            <IconButton size="large">
              <FdTypography variant="body2">{` ${params?.value?.length}   (${fdSkillData?.length} FifthDomain Skills)`}</FdTypography>
            </IconButton>
          </FdTooltip>
        );
      },
    },
  ];

  const actions = [
    {
      // eslint-disable-next-line react/no-unstable-nested-components, react/prop-types
      CustomElement: ({ rowData }) => {
        return (
          <Box className="flex justify-center">
            <FdButton
              variant="tertiary"
              onClick={() => {
                setWorkRoleModal(true);
                setEditDetails(rowData);
              }}
            >
              Edit
            </FdButton>
            <FdButton
              variant="tertiary"
              onClick={() => {
                setDeleteModal(rowData);
              }}
            >
              DELETE
            </FdButton>
          </Box>
        );
      },
      width: 180,
    },
  ];

  return (
    <FdSkeleton loading={loading} height={480}>
      <Box height="450px" bgcolor="#fff">
        <FdTable
          toolbarSettings={{
            title: '',
            filterButton: true,
            searchBox: true,
          }}
          rows={workRoleData?.map((item) => {
            return {
              ...item,
              workRoleSkills: customerSkillsWorkroles
                ?.filter(
                  (workRole) => workRole?.customerWorkroleId === item?.id,
                )
                ?.map((i) => ({
                  ...i,
                  customerSkillsWRId: i?.id,
                  id: i?.customerSkill?.id,
                  name: i?.customerSkill?.name,
                  fdSkills: customerSkillsFdSkills?.filter((im) =>
                    i?.customerSkill?.id?.includes(im?.customerSkillId),
                  ),
                })),
            };
          })}
          actions={actions}
          columns={columns}
          tablePageSize={10}
          gridId="work-role-list-org"
        />
        <AddWorkRole
          loading={
            createCustomerSkillWorkroleLaoding ||
            updateCustomerWorkroleLoading ||
            updateCustomerSkillWorkroleLoading
          }
          wRDetails={editDetails}
          isEdit
          openModal={workRoleModal}
          customerSkillData={customerSkillData}
          onConfirm={async (_customerWorkRole) => {
            const existingCSSkills = editDetails?.workRoleSkills;
            const deleteItems = existingCSSkills?.filter(
              (item) =>
                !_customerWorkRole?.workRoleSkills
                  ?.map((i) => i?.id)
                  ?.includes(item?.id),
            );
            const addItems = _customerWorkRole?.workRoleSkills?.filter(
              (item) =>
                !existingCSSkills?.map((i) => i?.id)?.includes(item?.id),
            );
            const updateItems = existingCSSkills.map((skill) => ({
              ...skill,
              ..._customerWorkRole?.workRoleSkills?.find(
                (wr) => wr?.id === skill?.id,
              ),
            }));
            const previousValue = editDetails?.name?.toLowerCase();
            if (previousValue !== _customerWorkRole?.name.toLowerCase()) {
              if (duplicateName(workRoleData, _customerWorkRole?.name)) {
                warningToastMessage(
                  'Work Role Name already exists in this organisation. Please enter a unique Work Role Name.',
                );
                return;
              }
            }
            await updateCustomerWorkroleMutation({
              variables: {
                input: {
                  id: _customerWorkRole?.id,
                  name: _customerWorkRole?.name,
                },
              },
            });
            if (addItems?.length > 0) {
              await Promise.all(
                addItems?.map((i) => {
                  return createCustomerSkillWorkroleMutation({
                    variables: {
                      input: {
                        customerSkillId: i?.id,
                        customerWorkroleId: _customerWorkRole?.id,
                        orgId: i?.orgId,
                        difficulty: i?.difficulty,
                      },
                    },
                  });
                }),
              );
            }
            if (updateItems?.length > 0) {
              await Promise.all(
                updateItems?.map((i) => {
                  return updateCustomerSkillWorkroleMutation({
                    variables: {
                      input: {
                        id: i?.customerSkillsWRId,
                        difficulty: i?.difficulty,
                      },
                    },
                  });
                }),
              );
            }
            if (deleteItems?.length > 0) {
              await Promise.all(
                deleteItems?.map((i) => {
                  return deleteCustomerSkillWorkroleMutation({
                    variables: {
                      input: {
                        id: i?.customerSkillsWRId,
                      },
                    },
                  });
                }),
              );
            }
            refetchQueries();
            setWorkRoleModal(false);
            successToastMessage('Work role updated!');
          }}
          onDismiss={() => {
            setWorkRoleModal(false);
            warningToastMessage('Work role not added');
          }}
        />
        <DeleteModal
          loading={
            deleteCustomerWorkroleLoading || deleteCustomerSkillWorkroleLoading
          }
          workRole
          openModal={deleteModal}
          onDismiss={async (_customerWorkRole) => {
            await deleteCustomerWorkroleMutation({
              variables: {
                input: {
                  id: _customerWorkRole?.id,
                },
              },
            });
            if (_customerWorkRole?.workRoleSkills?.length > 0) {
              await Promise.all(
                _customerWorkRole?.workRoleSkills?.map(async (_item) => {
                  await deleteCustomerSkillWorkroleMutation({
                    variables: {
                      input: {
                        id: _item?.customerSkillsWRId,
                      },
                    },
                  });
                }),
              );
            }
            refetchQueries();
            setDeleteModal(false);
          }}
          onConfirm={(_customerSkills) => {
            setDeleteModal(false);
          }}
        />
      </Box>
    </FdSkeleton>
  );
};

WorkRoleTable.propTypes = {
  workRoleData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ).isRequired,
  customerSkillsWorkroles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ).isRequired,
  loading: PropTypes.bool.isRequired,
  customerSkillsFdSkills: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ).isRequired,
  customerSkillData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ).isRequired,
  refetchQueries: PropTypes.func.isRequired,
};

export default WorkRoleTable;
