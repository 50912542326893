import React from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import { BasePage, FdLoadingSpinner } from '@fifthdomain/fe-shared';
import { FdBreadcrumbHeader } from '@fifthdomain/sidebar';
import { getUserById } from '../graphql/queries';
import UserProfile from '../components/User/UserProfile';

const NonAffiliatedUserProfile = () => {
  const { userId } = useParams();
  const { data: userData, loading: userLoading } = useQuery(gql(getUserById), {
    variables: {
      id: userId,
    },
    skip: !userId,
  });
  const selectedUser = userData?.getUserById?.items[0];

  if (userLoading) {
    return <FdLoadingSpinner />;
  }

  return (
    <Box>
      <FdBreadcrumbHeader
        entries={[
          {
            name: 'Non-Affiliated Users',
            path: '/user-management/non-affiliated-users',
            type: 'USER',
          },
        ]}
        page={{ name: selectedUser?.name, type: 'USER' }}
      />
      <BasePage data-cy="user-profile-card">
        <UserProfile selectedUser={selectedUser} />
      </BasePage>
    </Box>
  );
};

export default NonAffiliatedUserProfile;
