import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Badge } from '@mui/material';
import { useMutation, gql } from '@apollo/client';
import ThreePIcon from '@mui/icons-material/ThreeP';
import WarningIcon from '@mui/icons-material/Warning';
import {
  FdTable as Table,
  FdChip as Chip,
  FdTooltip,
  FdModal,
  FdTypography,
  useSnapshot,
  globalStore,
  Authorization,
  successToastMessage,
  warningToastMessage,
} from '@fifthdomain/fe-shared';
import * as singleSpa from 'single-spa';
import { getEventStatusColor } from '../../shared/utils/getStatusColor';
import {
  updateModulePart,
  updateCourse,
  updateAssessment,
} from '../../graphql/mutations';
import ContentInfoCell from './ContentInfoCell';
import { getDateTimeZoneFormatted } from '../../shared/utils/dateUtils';
import { upperCaseFirstLetter } from '../../shared/utils/stringUtils';
import TableHeaderColumnWithTooltip from './TableHeaderColumnWithTooltip';

const EventTable = ({
  rows,
  onDeleteCourse,
  refetchAssessmentData,
  filterType,
}) => {
  const globalSnap = useSnapshot(globalStore);
  const [deleteCourse, setDeleteCourse] = useState(undefined);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const [updateAssessmentMutation] = useMutation(gql(updateAssessment));
  const [updateModulePartMutation, { loading: deleteCoursePartLoading }] =
    useMutation(gql(updateModulePart));
  const [updateCourseMutation, { loading: deleteCourseLoading }] = useMutation(
    gql(updateCourse),
    {
      refetchQueries: ['ListCoursesByOrgId'],
      awaitRefetchQueries: true,
      onCompleted: () => successToastMessage('Success! Course deleted'),
    },
  );

  useEffect(() => {
    setColumnVisibilityModel({
      name: false,
      eventDuration: false,
      ...(filterType === 'Courses' && {
        startDateTime: false,
        endDateTime: false,
        eventLink: false,
      }),
      ...(['Competitions', 'Assessments', 'Archived'].includes(filterType) && {
        unReadMessagesUserCount: false,
      }),
    });
  }, [filterType]);

  const actions = [
    {
      label: 'View',
      show: ({ archived }) => !archived,
      onClick: ({ participantEventType, id }) => {
        const url =
          participantEventType === 'COMPETITION'
            ? `competitions/view/${id}?tabindex=4`
            : participantEventType === 'ASSESSMENT'
            ? `assessor/view/${id}`
            : `/labs/courses/view-admin/${id}/`;
        singleSpa.navigateToUrl(url);
      },
    },
    {
      label: 'Participant View',
      show: ({ type, courseUserId }) => !type && courseUserId,
      onClick: ({ courseUserId }) => {
        const url = `/labs/courses/view/${courseUserId}/`;
        singleSpa.navigateToUrl(url);
      },
    },
    {
      label: 'Chat Queue',
      show: ({ type }) =>
        Authorization.canViewInsights(globalSnap?.permissions) && !type,
      onClick: ({ id }) => {
        const url = `/labs/courses/view-admin/${id}/?tabindex=3`;
        singleSpa.navigateToUrl(url);
      },
    },
    {
      label: 'Delete',
      show: ({ type }) =>
        Authorization.canManageEvents(globalSnap?.permissions) && !type,
      onClick: (row) => {
        setDeleteCourse(row);
      },
    },
    {
      label: 'Duplicate',
      show: ({ archived }) =>
        Authorization.canManageEvents(globalSnap?.permissions) && !archived,
      onClick: ({ participantEventType, id }) => {
        const url =
          participantEventType === 'COMPETITION'
            ? `competitions/duplicate/${id}?tabindex=3`
            : participantEventType === 'ASSESSMENT'
            ? `/assessor/duplicate/${id}`
            : `/labs/courses/duplicate/${id}/`;
        singleSpa.navigateToUrl(url);
      },
    },

    {
      label: 'Archive',
      onClick: ({ id, participantEventType }) => {
        // set assessment as archived
        updateAssessmentMutation({
          variables: { input: { id, status: 'ARCHIVED' } },
          onCompleted: () => {
            successToastMessage(
              `${upperCaseFirstLetter(
                participantEventType,
              )} archived successfully`,
            );
            refetchAssessmentData();
          },
        });
      },
      show: ({ archived, status }) =>
        Authorization.canManageEvents(globalSnap?.permissions) &&
        !archived &&
        status === 'Ended',
    },
    {
      label: 'Unarchive',
      onClick: ({ id, participantEventType }) => {
        // set assessment as unarchived
        updateAssessmentMutation({
          variables: { input: { id, status: 'FINISHED' } },
          onCompleted: () => {
            successToastMessage(
              `${upperCaseFirstLetter(
                participantEventType,
              )} unarchived successfully`,
            );
            refetchAssessmentData();
          },
        });
      },
      show: ({ archived }) =>
        Authorization.canManageEvents(globalSnap?.permissions) && archived,
    },
  ];

  const loadingState = deleteCoursePartLoading || deleteCourseLoading;

  return (
    <Box height="452px" data-cy="event-table">
      <Table
        elevation={0}
        id="event-table"
        defaultMuiToolbarSettings={{
          showMuiDefaultToolbar: true,
          filterButton: true,
          searchBox: true,
          columnsButton: true,
          densityButton: false,
        }}
        toolbarSettings={{
          filterButton: true,
          searchBox: true,
          columnsButton: true,
        }}
        columnVisibilityModel={columnVisibilityModel}
        selection={false}
        rows={rows}
        actions={actions}
        columns={[
          {
            field: 'icon',
            width: 50,
            headerName: '',
            renderCell: (params) => params.row.icon,
          },
          {
            field: 'contentInfo',
            width: 300,
            headerName: 'Name',
            filterable: false,
            valueGetter: (params) => params.row.name,
            renderCell: (params) => <ContentInfoCell values={params.row} />,
          },
          { field: 'name', width: 300, headerName: 'Content Name' },
          {
            field: 'creator',
            width: 200,
            headerName: 'Creator',
          },
          {
            field: 'startDateTime',
            width: 180,
            headerName: 'Start',
            valueGetter: (params) => params.value || '-',
          },
          {
            field: 'endDateTime',
            width: 180,
            headerName: 'End',
            valueGetter: (params) => params.value || '-',
          },
          {
            field: 'createdAt',
            width: 200,
            headerName: 'Created On',
            valueGetter: (params) => getDateTimeZoneFormatted(params?.value),
          },
          {
            field: 'status',
            headerName: 'Status',
            width: 120,
            valueGetter: (params) => params?.value,
            renderCell: (params) => (
              <Chip
                color={getEventStatusColor(params?.row?.status)}
                size="small"
                label={params?.row?.status}
              />
            ),
            sortComparator: (v1, v2, param1, param2) =>
              param1.value.localeCompare(param2.value),
          },
          {
            field: 'unReadMessagesUserCount',
            width: 100,
            headerName: 'Chat',
            filterable: false,
            searchable: false,
            renderCell: (params) => {
              const userCount = params.row?.unReadMessagesUserCount || 0;
              if (userCount === 0) {
                return null;
              }
              return (
                <FdTooltip
                  title={`New Messages from ${userCount} users. 
                Navigate to Chat Queue from the
                action drop down to see the messages.`}
                >
                  <Badge badgeContent={userCount} color="error">
                    <ThreePIcon />
                  </Badge>
                </FdTooltip>
              );
            },
          },
          ...(Authorization.canManageEvents(globalSnap?.permissions)
            ? [
                {
                  field: 'eventLink',
                  headerName: 'Event Link',
                  width: 100,
                  renderCell: (params) => params.row.eventLink,
                  renderHeader: () => (
                    <TableHeaderColumnWithTooltip
                      title="Event Link"
                      tooltipText={
                        <Box>
                          You can share an Event link directly with external
                          users, allowing them to participate in competitions or
                          assessments without the need of platform invitations.
                          <br />
                          Anyone with a link is able to enter that event,
                          simplifyng the process of reaching out to and
                          involving a larger audience.
                        </Box>
                      }
                    />
                  ),
                },
              ]
            : []),
        ]}
        tablePageSize={5}
        gridId="landing-events"
      />

      <FdModal
        size="xs"
        title={
          <Box display="flex" alignItems="center">
            <WarningIcon
              style={{
                fontSize: 38,
                color: '#C62828',
                paddingRight: '0.5rem',
              }}
            />
            <span>Delete Course</span>
          </Box>
        }
        description={
          <Box>
            <FdTypography variant="subtitle1">
              Do you want to delete the course?
            </FdTypography>
            <Box mt={2}>
              <FdTypography variant="body1" color="secondary">
                This will permanently remove the course, and delete all insights
                from the course.
              </FdTypography>
            </Box>
          </Box>
        }
        disableConfirm={loadingState}
        confirm={loadingState ? 'Loading...' : 'DELETE COURSE'}
        dismiss="CANCEL"
        open={deleteCourse}
        onConfirm={async () => {
          onDeleteCourse();
          const promises = [];
          deleteCourse?.courseModules?.items?.forEach((m) =>
            m.parts.items.forEach((p) => {
              if (p.type === 'LAB') {
                promises.push(
                  updateModulePartMutation({
                    variables: {
                      input: {
                        id: p.id,
                        status: 'DELETED',
                      },
                    },
                  }),
                );
              }
            }),
          );
          await Promise.all(promises);

          // update course statue to "DELETED"
          await updateCourseMutation({
            variables: {
              input: {
                id: deleteCourse?.id,
                status: 'DELETED',
              },
            },
            onCompleted: () => onDeleteCourse(),
          });

          setDeleteCourse(undefined);
        }}
        onDismiss={() => {
          setDeleteCourse(undefined);
          warningToastMessage('Course not deleted');
        }}
      />
    </Box>
  );
};

EventTable.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ).isRequired,
  onDeleteCourse: PropTypes.func,
  refetchAssessmentData: PropTypes.func,
  filterType: PropTypes.string.isRequired,
};

EventTable.defaultProps = {
  onDeleteCourse: () => {},
  refetchAssessmentData: () => {},
};

export default EventTable;
