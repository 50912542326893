import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import _ from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import { gql, useQuery } from '@apollo/client';
import * as singleSpa from 'single-spa';
import { useHistory, useParams } from 'react-router-dom';
import NavigationPrompt from 'react-router-navigation-prompt';
import { Box, Divider, Card, CardContent } from '@mui/material';
import { isWithinInterval, subMonths } from 'date-fns';
import {
  BasePage,
  FdTypography,
  FdButton,
  FdCard,
  FdModal,
  useQueryRecursive,
  useSnapshot,
  globalStore,
  FdIconWithTooltip,
  FdSkeleton,
  FdAlert,
  warningToastMessage,
} from '@fifthdomain/fe-shared';
import { FdBreadcrumbHeader } from '@fifthdomain/sidebar';
import TasksTable from '../components/Training/TasksTable';
import Tile from '../components/Training/Tile';
import ProgressionTile from '../components/Training/ProgressionTile';
import {
  initialValues,
  validationSchema,
} from '../validation-schemas/Trainings/createTraining';
import {
  getUserById,
  listAffliationsByUserId,
  listTaskAttemptAggregatesByUserId,
  listTasksByOrgId,
  listTaskOrgs,
  queryTemplatesByStatus,
} from '../graphql/queries';
import {
  listCustomerSkillsByCustomerWorkRoleId,
  listCustomerSkillFdSkills,
} from '../queries/customQueries';
import {
  getDifficultyLabel,
  getProficiencyKey,
} from '../shared/utils/difficultyMapping';
import UserRole from '../components/Training/UserRole';
import CreateModal from '../components/Training/CreateModal';
import TrainingIcon from '../components/Training/TrainingIcon';
import { scrollToBottom } from '../shared/utils/scroll';
import WorkRoleCortex from '../components/Training/WorkRoleCortex';
import WorkRoleFulfilment from '../components/WorkRole/WorkRoleFulfilment';
import { degradationRate } from '../constants';

const CreateTraining = () => {
  const [showModal, setShowModal] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const history = useHistory();
  const { userId } = useParams();
  const globalSnap = useSnapshot(globalStore);

  const { data: userData, loading: userLoading } = useQuery(gql(getUserById), {
    variables: {
      id: userId,
    },
    skip: !userId,
  });
  const selectedUser = userData?.getUserById?.items[0];

  const {
    data: listAffliationsByUserIdData,
    loading: listAffliationsByUserIdLoading,
  } = useQueryRecursive(gql(listAffliationsByUserId), {
    variables: {
      userId,
      limit: 1000,
    },
    staleTime: { seconds: 0 },
    skip: !userId,
  });
  const workRole =
    listAffliationsByUserIdData?.listAffliationsByUserId?.items?.find(
      (a) => a.status === 'ACTIVE',
    ) || {};
  const workRoleName = workRole?.customerWorkrole?.name || '';

  // get list of customer skills for a work role id
  const {
    data: listCustomerSkillsByCustomerWorkRoleIdData,
    loading: listCustomerSkillsByCustomerWorkRoleIdLoading,
  } = useQueryRecursive(gql(listCustomerSkillsByCustomerWorkRoleId), {
    variables: {
      customerWorkroleId: workRole?.customerWorkroleID,
      limit: 1000,
    },
    staleTime: { seconds: 0 },
    skip: !workRole?.customerWorkroleID,
  });

  const listCustomerSkills =
    listCustomerSkillsByCustomerWorkRoleIdData
      ?.listCustomerSkillsByCustomerWorkRoleId?.items || [];

  // get list of all customer to fd skills mapping and get intersection of FD skills
  const {
    data: listCustomerSkillFdSkillsData,
    loading: listCustomerSkillFdSkillsLoading,
  } = useQueryRecursive(gql(listCustomerSkillFdSkills), {
    variables: {
      limit: 1000,
    },
    staleTime: { seconds: 0 },
    skip: !workRole?.customerWorkroleID,
  });

  const listAllFdSkills =
    listCustomerSkillFdSkillsData?.listCustomerSkillFdSkills?.items || [];

  const result = listAllFdSkills?.filter((item) =>
    listCustomerSkills
      ?.map((i) => i?.customerSkillId)
      ?.includes(item?.customerSkillId),
  );

  const listFdSkills = result?.map((skill) => ({
    ...listCustomerSkills?.find(
      (item) => item?.customerSkillId === skill?.customerSkillId,
    ),
    ...skill,
  }));

  const requiredSkills = _.map(
    _.groupBy(listFdSkills, 'skillId'), // group by the skillId
    (item) => _.maxBy(item, 'difficulty'), // take the one with the highest value
  );

  const requiredSkillsCount = requiredSkills.length;
  const requiredSkillAlias = requiredSkills?.map((rs) => rs?.skill?.alias);
  const {
    data: listTaskAttemptAggregatesByUserIdData,
    loading: listTaskAttemptAggregatesByUserIdLoading,
  } = useQueryRecursive(gql(listTaskAttemptAggregatesByUserId), {
    variables: {
      userId,
      limit: 1000,
    },
  });

  const { data: allTasksData, loading: allTasksLoading } = useQueryRecursive(
    gql(listTasksByOrgId),
    {
      variables: {
        orgId: globalSnap?.orgId,
        filter: {
          status: { eq: 'APPROVED' },
        },
        limit: 1000,
      },
      skip: !globalSnap?.orgId,
    },
  );

  const {
    data: allTasksAvailableToOrg,
    loading: allTasksAvailableToOrgLoading,
  } = useQueryRecursive(gql(listTaskOrgs), {
    variables: {
      orgId: globalSnap.orgId,
      limit: 1000,
    },
    skip: !globalSnap?.orgId,
  });

  const { data: releasedTemplates, loading: templatesLoading } =
    useQueryRecursive(gql(queryTemplatesByStatus), {
      variables: {
        status: 'RELEASED',
      },
    });
  const releasedTemplatesTasks =
    releasedTemplates?.queryTemplatesByStatus?.items
      ?.map((template) => template?.tasks?.items?.map((task) => task?.task))
      .flat();

  const reactHookFormMethods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });
  const {
    formState: { isDirty },
    getValues,
    setValue,
    trigger,
  } = reactHookFormMethods;

  const taskAttempts =
    listTaskAttemptAggregatesByUserIdData?.listTaskAttemptAggregatesByUserId?.items
      ?.map((i) => {
        const endDate = new Date();
        const startDate = subMonths(
          endDate,
          degradationRate[i?.task?.difficulty],
        );
        const isWithinIntervalDate = isWithinInterval(new Date(i?.updatedAt), {
          start: startDate,
          end: endDate,
        });
        return { isWithinIntervalDate, ...i };
      })
      ?.filter((j) => !!j?.isWithinIntervalDate) || [];

  const taskAttemptsMap = taskAttempts
    ?.map((ta) =>
      ta?.task?.skills?.items.map((s) => ({
        ...s,
        attempts: ta?.attempts,
        status: ta?.status,
        taskId: ta?.taskId,
        assessmentId: ta?.assessmentId,
        difficulty: getProficiencyKey(ta?.task?.difficulty),
      })),
    )
    .flat()
    ?.filter((a) => requiredSkillAlias.includes(a?.skill?.alias));

  const skillsAllAttempts = _(taskAttemptsMap)
    .groupBy('skillId')
    .map((items, skillGroup) => ({
      skillGroup,
      attempts: [...items],
      skillName: items?.[0]?.skill?.name,
      skillAlias: items?.[0]?.skill?.alias,
      difficulty: requiredSkills?.find((item) => item?.skillId === skillGroup)
        ?.difficulty,
    }))
    .value();

  const fulfilledSkills = skillsAllAttempts
    ?.map((s) => ({
      ...s,
      skillId: s.skillGroup,
      fulfilled: s?.attempts?.filter(
        (a) => a.status === 'COMPLETED' && a.difficulty >= s?.difficulty,
      ).length,
      skillName: s.skillName,
      skillAlias: s?.skillAlias,
    }))
    ?.filter((sk) => sk?.fulfilled > 0);

  const completedTasks = skillsAllAttempts?.map((s) => ({
    skillId: s.skillGroup,
    maxLevelSolved: _.maxBy(
      s?.attempts?.filter((a) => a.status === 'COMPLETED'),
      'difficulty',
    )?.difficulty,
    skillName: s.skillName,
  }));

  const isTaskLevelUnfinished = (_skillId, _difficulty) => {
    const _maxLevelSolved =
      completedTasks?.find((ct) => ct?.skillId === _skillId)?.maxLevelSolved ||
      0;
    return _maxLevelSolved > 0 ? _difficulty > _maxLevelSolved : true;
  };
  const fulfilledSkillIds = fulfilledSkills?.map((s) => s.skillId);
  const fulfilledSkillsCount = fulfilledSkills.length;
  const unfulfilledSkills = requiredSkills?.filter(
    (s) => !fulfilledSkillIds.includes(s?.skillId),
  );
  const unfulfilledSkillsCount = requiredSkillsCount - fulfilledSkillsCount;
  const unfulfilledSkillIds = unfulfilledSkills?.map((s) => s?.skillId);

  const allTasks = _.uniqBy(
    [
      ...(allTasksData?.listTasksByOrgId?.items?.map((t) => ({
        ...t,
        difficulty: getProficiencyKey(t?.difficulty),
        owned: true,
        skills: { items: t?.skills?.items?.filter((s) => s?.skill) },
      })) || []),
      ...(allTasksAvailableToOrg?.listTaskOrgs?.items?.map(({ task }) => ({
        ...task,
        difficulty: getProficiencyKey(task?.difficulty),
        owned: false,
        skills: { items: task?.skills?.items?.filter((s) => s?.skill) },
      })) || []),
      ...(releasedTemplatesTasks?.map((task) => ({
        ...task,
        difficulty: getProficiencyKey(task?.difficulty),
        owned: task?.orgId === globalSnap?.orgId,
        skills: { items: task?.skills?.items?.filter((s) => s?.skill) },
      })) || []),
    ],
    'id',
  ).filter(
    (_task) =>
      _task.type !== 'CONTAINER' &&
      _task.skills.items?.some(
        (s) =>
          unfulfilledSkillIds.includes(s?.skillId) &&
          isTaskLevelUnfinished(s?.skillId, _task?.difficulty),
      ),
  );

  const selectedTaskIds = getValues('tasks')?.map((t) => t.taskId);

  const trainingSkills = allTasks
    ?.filter((t) => selectedTaskIds.includes(t?.id) && t?.difficulty >= 3)
    ?.map((t) => t?.skills?.items?.map((s) => s?.skill))
    .flat()
    ?.filter((s) => requiredSkillAlias.includes(s?.alias))
    ?.map((s) => s?.id);

  const trainingSkillIds = _.uniq(trainingSkills);
  const trainingSkillIdsCount = trainingSkillIds.length;
  const unfulfilledSkillsCountPostTraining =
    unfulfilledSkillsCount - trainingSkillIdsCount;
  const fulfilledSkillsCountPostTraining =
    fulfilledSkillsCount + trainingSkillIdsCount;
  const skillDiffCount =
    fulfilledSkillsCountPostTraining - fulfilledSkillsCount;

  const tableData =
    allTasks
      ?.map((task) => ({
        ...task,
        difficultyLabel: getDifficultyLabel(task?.difficulty),
        difficultyInteger: task?.difficulty,
        specialtyName: task?.specialty?.name,
        skills: task?.skills?.items.map((s) => s.skill),
        techniqueTags: task?.skills?.items
          .map((s) => s.techniqueTags?.items.map((st) => st.techniqueTag?.name))
          .flat(),
        technologyTags: task?.technologyTags?.items.map(
          (t) => t.technologyTag?.name,
        ),
        creator: task?.user?.name,
        ownerOrg: task?.org?.name,
        ownerOrgId: task?.org?.id,
        creatorOrg: task?.user?.org?.name,
        creatorOrgId: task?.user?.org?.id,
        tags:
          task?.tags?.items?.filter(
            (t1) => t1?.Tag?.orgId === globalSnap?.orgId,
          ) || [],
      }))
      .filter((td) => td.id) || [];

  const trainingSkillsMap = allTasks
    ?.filter((t) => selectedTaskIds.includes(t?.id))
    ?.map((t) => ({
      challengeName: t?.name,
      difficulty: t?.difficulty,
      skills: t?.skills?.items?.map((s) => s?.skill),
    }));

  const plannedTrainingsSkills =
    _.uniq(
      trainingSkillsMap
        ?.map((ts) => ts?.skills)
        ?.flat()
        ?.map((s) => s?.alias),
    ) || [];

  const cortexData = listFdSkills
    ?.map((rs) => {
      const data = Array.from({ length: 5 }, (__, i) => i + 1).map((d) => {
        const solved =
          taskAttempts?.filter(
            (ta) =>
              ta.status === 'COMPLETED' &&
              ta?.task?.difficulty === d &&
              ta.task?.skills.items
                ?.map((s) => s?.skillId)
                .includes(rs?.skillId),
          )?.length > 0;

        const trainingPlanned = trainingSkillsMap
          ?.filter((ts) => ts?.difficulty === d)
          .map((ts) => ts?.skills)
          .flat()
          ?.map((s) => s?.alias)
          .includes(rs?.skill?.alias);

        const trainingCount = trainingSkillsMap?.filter(
          (ts) =>
            ts?.difficulty === d &&
            ts?.skills?.map((s) => s?.alias)?.includes(rs?.skill?.alias),
        )?.length;

        const challengesSelected =
          trainingSkillsMap
            ?.filter(
              (ts) =>
                ts?.difficulty === d &&
                ts?.skills?.some(({ alias }) => alias === rs?.skill?.alias),
            )
            ?.map((ts) => ts?.challengeName) || [];

        return {
          alias: rs?.skill?.alias,
          difficulty: d,
          requiredProficiency: rs?.difficulty,
          value: !solved && trainingPlanned ? trainingCount : 0,
          solved,
          training: trainingPlanned,
          challengesSelected,
        };
      });
      return data;
    })
    ?.flat();

  const modalData = {
    fullFilledSkillsCount: `${fulfilledSkillsCount}/${requiredSkillsCount}`,
    unfulfilledSkillsCount: `${unfulfilledSkillsCount}/${requiredSkillsCount}`,
    fullFilledSkillsCountPostTraining: `${fulfilledSkillsCountPostTraining}/${requiredSkillsCount}`,
    unfulfilledSkillsCountPostTraining: `${unfulfilledSkillsCountPostTraining}/${requiredSkillsCount}`,
    tasksCount: skillDiffCount,
  };

  const detailModalData = {
    fulfilledSkills: fulfilledSkills?.map((fs) => ({
      ...fs,
      skill: { name: fs?.skillName, alias: fs?.skillAlias },
      data: _.maxBy(fs?.attempts, 'difficulty')?.difficulty || 0,
    })),
    unfulfilledSkills: unfulfilledSkills?.map((us) => ({
      ...us,
      skill: { name: us?.skill?.name, alias: us?.skill?.alias },
      data: completedTasks
        ?.filter((ct) => ct?.skillId === us?.skillId)
        ?.map((c) => c?.maxLevelSolved),
    })),
  };

  const loading =
    userLoading ||
    listAffliationsByUserIdLoading ||
    listCustomerSkillFdSkillsLoading ||
    listCustomerSkillsByCustomerWorkRoleIdLoading ||
    listTaskAttemptAggregatesByUserIdLoading;

  return (
    <Box>
      <FdBreadcrumbHeader
        page={{ name: 'Create Training', type: 'TRAINING' }}
      />
      <BasePage
        heading="Training Activity Challenge Selection"
        headingAdornment={
          <Box className="flex gap-x-3">
            <FdButton
              variant="secondary"
              size="large"
              onClick={() =>
                singleSpa.navigateToUrl(
                  `/competitions/affiliated-view-user/${userId}`,
                )
              }
            >
              Back
            </FdButton>
            <FdButton
              variant="primary"
              size="large"
              onClick={() => {
                if (getValues('tasks').length === 0) {
                  trigger('tasks');
                  scrollToBottom();
                  return;
                }
                const trainingName = getValues('name');
                setShowModal(true);
                if (!trainingName) {
                  setValue(
                    'name',
                    `Training Activity - ${workRoleName} - ${selectedUser?.name} - #No.`,
                  );
                }
              }}
              style={{ width: '280px' }}
            >
              Finalise training Activity
            </FdButton>
          </Box>
        }
        data-cy="create-training-base-page"
      >
        <FormProvider {...reactHookFormMethods}>
          <form>
            <Box mt={2}>
              <FdCard variant="outlined">
                <FdTypography variant="h3">
                  Training Activity Outcomes
                </FdTypography>
                <Box mt={1}>
                  <FdTypography variant="body2" color="secondary">
                    Each of the assigned user&apos;s required skills (as per
                    their work role) are populated below. As you select
                    challenges in the &apos;Recommended Challenges in your
                    Library&apos; table, the Cyber Skills Cortex below will
                    update to display the user&apos;s skills fulfilment after
                    completion of your selected challenges.
                    <br />
                    <br />
                    Each skill in a work role must be demonstrated at its target
                    proficiency level (or above) to be considered as fulfilled.
                    Demonstration of skills below the target proficiency levels
                    set for each skill in a work role does not affect skill
                    fulfilment.
                  </FdTypography>
                </Box>
                <Box my={3}>
                  <FdTypography variant="subtitle1">Assigned User</FdTypography>
                  <UserRole
                    name={selectedUser?.name}
                    userRole={workRoleName}
                    coverageInPercentage={
                      Math.round(
                        (fulfilledSkillsCount / requiredSkillsCount) * 100,
                      ) || 0
                    }
                    loading={loading}
                    onClickDetails={() => setShowDetailsModal(true)}
                  />
                </Box>
                <Box my={3}>
                  <Divider />
                </Box>
                <Box className="flex justify-around gap-x-8">
                  <Card variant="outlined" style={{ borderRadius: '8px' }}>
                    <CardContent style={{ height: '100%' }}>
                      <Box
                        className="flex flex-col justify-evenly h-full"
                        maxWidth="280px"
                      >
                        <Tile
                          heading={selectedTaskIds?.length}
                          subHeading="Challenges Selected"
                          description="
                  This shows the number of challenges you have currently selected to package into a training activity for this user."
                        />
                        <Divider style={{ flexShrink: 1 }} />
                        <Tile
                          heading={
                            <FdSkeleton
                              loading={loading}
                              height="36px"
                              width="100px"
                            >
                              {`${plannedTrainingsSkills?.length}/${unfulfilledSkillsCount}`}
                            </FdSkeleton>
                          }
                          subHeading="Unfulfilled Skills to be Addressed"
                          description="This is the number of (this user's) unfulfilled skills which will become fulfilled upon completion of currently selected challenge(s) for this training activity."
                        />
                      </Box>
                    </CardContent>
                  </Card>
                  <Box>
                    <Box
                      className="flex items-center justify-center rounded mb-4"
                      style={{
                        backgroundColor: 'rgba(204, 213, 222, 1)',
                        height: '24px',
                      }}
                    >
                      <FdTypography
                        variant="captiontext1"
                        style={{ fontWeight: 500 }}
                      >
                        {workRoleName}
                      </FdTypography>
                    </Box>
                    <WorkRoleCortex data={cortexData} loading={loading} />
                  </Box>
                  <FdCard variant="outlined">
                    <Box
                      className="flex flex-col items-center"
                      maxWidth="280px"
                    >
                      <FdTypography variant="body2" style={{ fontWeight: 600 }}>
                        Required Skills
                      </FdTypography>
                      <FdTypography variant="h3">
                        <FdSkeleton
                          loading={loading}
                          height="36px"
                          width="100px"
                        >
                          {requiredSkillsCount}
                        </FdSkeleton>
                      </FdTypography>
                      <FdTypography variant="captiontext1" color="secondary">
                        Only skills required of this user&apos;s work role are
                        shown in this version of their Cyber Skills Cortex. All
                        non-required skills are greyed out.
                      </FdTypography>
                      <Box className="flex my-4 w-full">
                        <Divider className="w-full" />
                      </Box>
                      <ProgressionTile
                        heading="Fulfilled Skills"
                        subHeading={
                          <FdSkeleton
                            loading={loading}
                            height="36px"
                            width="100px"
                          >
                            {`${fulfilledSkillsCount}/${requiredSkillsCount}`}
                          </FdSkeleton>
                        }
                        subHeadingAbbreviation={!loading && '(Current)'}
                        description="Fulfilled skills (Current) are required skills which a user has already demonstrated at the target proficiency or higher."
                        subHeading2={`${fulfilledSkillsCountPostTraining}/${requiredSkillsCount}`}
                        subHeading2Abbreviation={
                          <Box className="flex items-center">
                            <span>{`(Post-Training) (${skillDiffCount} x `}</span>
                            <TrainingIcon />
                            <span>)</span>
                          </Box>
                        }
                        description2="Fulfilled skills (Post-Training) are required skills which a user will have demonstrated at the target proficiency or higher upon completion of selected challenges."
                        subheadingColor="rgba(105, 240, 174, 1)"
                      />
                      <Box className="flex my-4 w-full">
                        <Divider className="w-full" />
                      </Box>
                      <ProgressionTile
                        heading="Unfulfilled Skills"
                        subHeading={
                          <FdSkeleton
                            loading={loading}
                            height="36px"
                            width="100px"
                          >
                            {`${unfulfilledSkillsCount}/${requiredSkillsCount}`}
                          </FdSkeleton>
                        }
                        subHeadingAbbreviation={!loading && '(Current)'}
                        description="Unfulfilled skills (Current) are required skills which a user has not yet demonstrated at the target proficiency level or higher."
                        subHeading2={`${unfulfilledSkillsCountPostTraining}/${requiredSkillsCount}`}
                        subHeading2Abbreviation="(Post-Training)"
                        description2="Unfulfilled skills (Post-Training) are required skills which a user will still not yet have demonstrated at the target proficiency level or higher upon completion of selected challenges."
                        subheadingColor="rgba(239, 154, 154, 1)"
                      />
                    </Box>
                  </FdCard>
                </Box>
              </FdCard>
              <FdCard variant="outlined">
                <Box className="flex items-center gap-x-1">
                  <FdTypography variant="h3">
                    Recommended Challenges in your Library
                  </FdTypography>
                  <FdIconWithTooltip title="Only challenges associated with required skills the user has not yet demonstrated at Level 3 (Competent) or higher are recommended below. Additionally, only challenges above the user's demonstrated proficiency in a required skill are shown." />
                </Box>
                <Box my={1}>
                  <FdTypography variant="body2" color="secondary">
                    Tailored challenge recommendations for the assigned user
                    (based on the
                    <strong style={{ opacity: 0.7, padding: '0 5px' }}>
                      unfulfilled
                    </strong>
                    skills in their work role) are below. Use the checkboxes
                    below to select challenges to package into a tailored
                    training activity for the assigned user. When you’re happy
                    with your selection, click &apos;Finalise Training
                    Activity&apos; at the top of this page.
                  </FdTypography>
                </Box>
                <FdAlert
                  variant="info"
                  message={
                    <FdTypography variant="body2">
                      All labs in lab-based challenges have a duration of 2
                      hours. Participants can extend their lab instances as many
                      times as they require, resetting their lab timers to 2
                      hours and retaining progress made. Participants can also
                      reset their labs as needed, which will deploy a new lab
                      instance and erase all progress.
                    </FdTypography>
                  }
                />
                <TasksTable
                  data={tableData}
                  loading={
                    allTasksLoading ||
                    allTasksAvailableToOrgLoading ||
                    templatesLoading
                  }
                />
              </FdCard>
            </Box>
            <CreateModal
              showModal={showModal}
              setShowModal={setShowModal}
              userName={selectedUser?.name}
              email={selectedUser?.email}
              allTasks={allTasks}
              userId={userId}
              workRoleName={workRoleName}
              data={modalData}
            />
            <WorkRoleFulfilment
              fulfilledSkills={detailModalData?.fulfilledSkills}
              unfulfilledSkills={detailModalData?.unfulfilledSkills}
              assignedWorkRole={[{ name: workRoleName }]}
              openModal={showDetailsModal}
              onDismiss={() => {
                setShowDetailsModal(false);
              }}
            />
          </form>
        </FormProvider>
        <NavigationPrompt
          when={isDirty}
          afterCancel={() => {
            if (
              !window.location.pathname.includes(
                '/competitions/training/create/',
              )
            ) {
              history.goBack();
            }
          }}
          afterConfirm={() =>
            warningToastMessage('Changes to Training Activity are not saved')
          }
        >
          {({ onConfirm, onCancel }) => (
            <FdModal
              title="Are you sure you want to leave?"
              description="You have unsaved changes. Click the Stay button to go back to the form and save your changes."
              confirm="Stay"
              dismiss="Leave"
              open
              onConfirm={onCancel}
              onDismiss={onConfirm}
            />
          )}
        </NavigationPrompt>
      </BasePage>
    </Box>
  );
};

export default CreateTraining;
