import React from 'react';
import { Route, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  useAuthSession,
  useSnapshot,
  globalStore,
  FdLoadingSpinner,
  Authorization,
} from '@fifthdomain/fe-shared';

const TagDirectoryManagementRoute = ({ component: Component, ...rest }) => {
  const { user } = useAuthSession();
  const { loading, orgPricingTier, permissions } = useSnapshot(globalStore);
  const history = useHistory();
  const canManageTags = Authorization.canManageTags(
    permissions,
    orgPricingTier,
  );

  if (loading || !permissions) return <FdLoadingSpinner />;

  return (
    <Route
      {...rest}
      render={(props) => {
        if (user && !canManageTags) {
          return history.goBack();
        }

        if (user && canManageTags) {
          return <Component {...rest} {...props} />;
        }
        return null;
      }}
    />
  );
};

TagDirectoryManagementRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
};

export default TagDirectoryManagementRoute;
