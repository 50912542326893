import React from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import _ from 'lodash';
import { gql } from '@apollo/client';
import ChatIcon from '@mui/icons-material/Chat';
import {
  useSnapshot,
  globalStore,
  stickyToastMessage,
} from '@fifthdomain/fe-shared';
import useSubscription from '../hooks/useSubscription';
import {
  onCreateAnnouncement,
  onCreateChallengeChat,
  onUpdateChallengeChat,
} from '../graphql/subscriptions';
import { invalidateGetAssessment } from '../queries/invalidateQueries';
import notificationStore from '../store/notificationStore';
import { getAllMentions } from '../shared/utils/chatUtils';

const SubscriptionProvider = ({ children }) => {
  const globalSnap = useSnapshot(globalStore);
  const notificationSnap = useSnapshot(notificationStore);
  // Announcement subscription
  useSubscription({
    query: gql(onCreateAnnouncement),
    onData: (_result) => {
      const { id, assessment } = _result?.data?.onCreateAnnouncement || {};
      // All userIds who are supposed to get the toast
      const userIdsFromUsers = _.map(assessment?.users?.items || [], 'userId');
      const userIdsFromTeams = _.flatMap(
        assessment?.teams?.items || [],
        'team.members.items',
        'userId',
      );
      const uniqueUserIds = _.union(userIdsFromUsers, userIdsFromTeams);
      // show toast
      if (
        uniqueUserIds.includes(globalSnap?.userId) &&
        ['READY', 'STARTED'].includes(assessment?.status)
      ) {
        stickyToastMessage(
          <ChatIcon style={{ color: 'rgba(245, 124, 0, 1)' }} />,
          `A message was just posted in Announcements within ${assessment?.name}!`,
          `toastId-${id}`,
        );
        // invalidate get assessment query
        invalidateGetAssessment();
        notificationStore.announcementsRefreshPage =
          !notificationSnap.announcementsRefreshPage;
      }
    },
  });

  const showChallengeToast = (data = {}) => {
    const { messages, userId, challenge, assessment } = data;
    const textMessage = messages?.find((m) => m.type === 'TEXT')?.content || '';
    const mentions = getAllMentions(textMessage) || [];
    const mentionedInMessage = mentions?.some(
      (m) => m.replace('@', '') === globalSnap?.userAlias,
    );

    // show toast
    if (globalSnap.userId !== userId && mentionedInMessage) {
      stickyToastMessage(
        <ChatIcon style={{ color: 'rgba(0, 151, 167, 1)' }} />,
        `You've been tagged in a message in '${challenge?.name}' within '${assessment?.name}'!`,
        `toastId-${shortid.generate()}`,
        'rgba(178, 235, 242, 1)',
      );
    }
  };

  // Challenge chat subscription
  useSubscription({
    query: gql(onCreateChallengeChat),
    onData: (_result) => {
      showChallengeToast(_result?.data?.onCreateChallengeChat);
    },
  });

  useSubscription({
    query: gql(onUpdateChallengeChat),
    onData: (_result) => {
      showChallengeToast(_result?.data?.onUpdateChallengeChat);
    },
  });

  return <>{children}</>;
};

SubscriptionProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SubscriptionProvider;
