import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import { Box, IconButton } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import {
  FdTable,
  useSnapshot,
  globalStore,
  FdChips,
  FdTooltip,
  FdTypography,
  FdButton,
  useQueryRecursive,
  successToastMessage,
  warningToastMessage,
} from '@fifthdomain/fe-shared';
import TaskInfoCell from '../Assessment/TaskInfoCell';
import TableHeaderColumnWithTooltip from '../Assessment/TableHeaderColumnWithTooltip';
import {
  getCommaSeparatedPlusSuffix,
  upperCaseFirstLetter,
} from '../../shared/utils/stringUtils';
import { getTagColor } from '../../shared/utils/tagUtils';
import { sortByDateField } from '../../shared/utils/dateUtils';
import ViewTaskDrawer from '../Assessment/ViewTaskDrawer';
import { listTasksByOrgId } from '../../graphql/queries';
import { getDifficultyLabel } from '../../shared/utils/difficultyMapping';
import { getDeletingTasks, getNewTasks } from '../../shared/utils/taskUtils';
import {
  createModulePart,
  createTaskAssessment,
  deleteModulePart,
  deleteTaskAssessment,
} from '../../graphql/mutations';
import { updateTask } from './utils';

const TasksEditTable = ({ loading, editHidden }) => {
  const { trainingId } = useParams();
  const [saveInProgress, setSaveInProgress] = useState(false);
  const globalSnap = useSnapshot(globalStore);
  const { data: allTasksData, loading: allTasksLoading } = useQueryRecursive(
    gql(listTasksByOrgId),
    {
      variables: {
        orgId: globalSnap?.orgId,
        filter: {
          status: { eq: 'APPROVED' },
        },
      },
      skip: !globalSnap?.orgId,
    },
  );
  const [createModulePartMutation] = useMutation(gql(createModulePart));
  const [deleteTaskAssessmentMutation] = useMutation(gql(deleteTaskAssessment));
  const [deleteModulePartMutation] = useMutation(gql(deleteModulePart));
  const [createTaskAssessmentMutation] = useMutation(gql(createTaskAssessment));
  const { control, setValue, trigger, reset, getValues } = useFormContext();
  const [activeTask, setActiveTask] = useState();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const { fields: tasks } = useFieldArray({
    control,
    name: 'tasks',
  });

  const tasksData =
    allTasksData?.listTasksByOrgId?.items
      ?.filter((t) => t?.type !== 'CONTAINER')
      ?.map((t) => ({
        ...t,
        difficultyInteger: t?.difficulty,
        difficultyLabel: getDifficultyLabel(t?.difficulty),
        specialtyName: t?.specialty?.name,
        skills: t?.skills?.items.map((s) => s.skill),
        techniqueTags: t?.skills?.items
          .map((s) => s.techniqueTags?.items.map((st) => st.techniqueTag?.name))
          .flat(),
        technologyTags: t?.technologyTags?.items.map(
          (tt) => tt.technologyTag?.name,
        ),
        creator: t.user?.name,
        ownerOrg: t?.org?.name,
        ownerOrgId: t?.org?.id,
        creatorOrg: t?.user?.org?.name,
        creatorOrgId: t?.user?.org?.id,
        labId: t?.labId,
        lab: t?.lab,
        tags:
          t?.tags?.items?.filter(
            (t1) => t1?.Tag?.orgId === globalSnap?.orgId,
          ) || [],
        taskId: t.id,
        taskDbId: t.id,
      })) || [];
  const taskIds = tasks?.map((t) => t.taskId);
  const taskRows = editMode
    ? tasksData
    : tasksData?.filter((t) => taskIds.includes(t.taskId));

  const viewTaskActions = [
    {
      label: 'View',
      onClick: ({ id }) => {
        setOpenDrawer(true);
        const activeTaskData = taskRows?.find((t) => t.taskId === id);
        setActiveTask(activeTaskData);
      },
    },
  ];

  const columns = [
    {
      field: 'name',
      width: 300,
      headerName: 'Name',
      valueGetter: (params) => params.row.name,
      renderCell: (params) => <TaskInfoCell values={params.row} />,
    },
    {
      field: 'creator',
      headerName: 'Creator/ Provider',
      flex: 1,
      renderHeader: () => {
        return (
          <Box display="flex" alignItems="center">
            <FdTypography variant="subtitle2">Creator/ Provider</FdTypography>
            <FdTooltip
              title={
                <Box>
                  Creator of the challenge is the manager/organisation which
                  created that challenge. Creator information appears for
                  challenges that are owned by your organisation.
                  <br />
                  <br />
                  Provider name appears for challenges that have been leased
                  from, (or provided by) other organisation.
                </Box>
              }
            >
              <IconButton size="small" style={{ marginLeft: '10px' }}>
                <InfoOutlinedIcon />
              </IconButton>
            </FdTooltip>
          </Box>
        );
      },
      valueGetter: (params) => {
        const rowVal = params?.row;
        if (rowVal?.owned) {
          return rowVal?.creatorOrgId === rowVal?.ownerOrgId
            ? `Creator: ${rowVal.creator}`
            : `Creator: ${rowVal?.creatorOrg}`;
        }
        return `Provider: ${rowVal?.ownerOrg}`;
      },
    },
    {
      field: 'specialtyName',
      flex: 1,
      headerName: 'Professional Specialty',
      renderHeader: () => (
        <TableHeaderColumnWithTooltip
          title="Professional Specialty"
          tooltipText="Professional specialties represent broad categories of commonly found technical functions within the field of cyber operations. These specialties offer a structured framework under which a particular challenge can be classified."
        />
      ),
    },
    {
      field: 'skills',
      flex: 1,
      headerName: 'Skills',
      renderHeader: () => (
        <TableHeaderColumnWithTooltip
          title="Skills"
          tooltipText="Skills indicate the specific, acquired ability necessary to solve a challenge within a given timeframe or effort."
        />
      ),
      valueGetter: (params) =>
        params?.value?.length > 0
          ? [...params?.value].map((t) => t?.name)?.join(' ')
          : '',
      renderCell: (params) => {
        return getCommaSeparatedPlusSuffix(
          params?.row?.skills?.map((skill) => skill?.name),
        );
      },
    },
    {
      field: 'type',
      width: 100,
      headerName: 'Type',
      renderCell: (params) => upperCaseFirstLetter(params?.value),
    },
    ...(globalSnap.orgPricingTier === 'STARTER'
      ? []
      : [
          {
            field: 'tags',
            flex: 1,
            headerName: 'Assigned Tags',
            valueGetter: (params) =>
              params.row?.tags?.length > 0
                ? [...params.row?.tags].map((t) => t.Tag?.name)?.join(' ')
                : '',
            renderCell: (params) => {
              const _data = [...params.row?.tags]
                ?.sort(sortByDateField('updatedAt', 'desc'))
                .map((t) => ({
                  label: t.Tag?.name,
                  color: getTagColor(t.Tag?.color),
                }));
              return _data?.length > 0 ? (
                <FdChips
                  data={_data}
                  numberOfChipsDisplayed={1}
                  caption="More"
                />
              ) : (
                <Box width="100%" textAlign="center">
                  -
                </Box>
              );
            },
          },
        ]),
  ];

  const showToast = (_updateResult) => {
    // show toast
    if (_updateResult.every((p) => p?.status === 'fulfilled')) {
      successToastMessage('Success! Training activity updated!');
    }
    setSaveInProgress(false);
    setEditMode(false);
  };

  const updateTasks = async () => {
    setSaveInProgress(true);
    const taskIdValues = getValues('tasks');
    if (taskIdValues.length === 0) {
      return;
    }
    const existingTasks = getValues('existingTasks');
    const deletePromises = [];
    const updatePromises = [];
    const deleteTask = async (_id, modulePartId, task) => {
      return new Promise((resolve, reject) => {
        deleteTaskAssessmentMutation({
          variables: {
            input: {
              id: _id,
            },
          },
          onCompleted: () => {
            if (task.type === 'LAB') {
              deleteModulePartMutation({
                variables: {
                  input: {
                    id: modulePartId,
                  },
                },
              });
            }
            resolve();
          },
          onError: () => reject(),
        });
      });
    };

    // delete existing tasks that are about to be deleted
    getDeletingTasks(existingTasks, taskIdValues)?.forEach((ta) =>
      deletePromises.push(deleteTask(ta?.taskDbId, ta?.modulePartId, ta?.task)),
    );
    const deleteResult = await Promise.allSettled(deletePromises);
    if (deleteResult.some((p) => p.status === 'rejected')) {
      showToast(deleteResult);
      return;
    }

    // insert new tasks as per form changes
    getNewTasks(existingTasks, taskIdValues)?.forEach((ta) => {
      const task = tasksData?.find((t) => t.id === ta.taskId);
      updatePromises.push(
        updateTask(
          ta.taskId,
          trainingId,
          task,
          createModulePartMutation,
          createTaskAssessmentMutation,
          setValue,
          getValues,
        ),
      );
    });
    const updateResult = await Promise.allSettled(updatePromises);
    // show toast for both success and failed
    showToast(updateResult);
  };

  const loadingStatus = loading || allTasksLoading;

  return (
    <Box>
      <Box mt={2} mb={2} height="610px" width="100%">
        <FdTable
          loading={loadingStatus}
          defaultMuiToolbarSettings={{
            showMuiDefaultToolbar: true,
            columnsButton: false,
            filterButton: true,
            densityButton: true,
          }}
          rowHeight={60}
          slotProps={{
            columnsPanel: {
              getTogglableColumns: () => [
                'creator',
                'specialtyName',
                'skills',
                'type',
                'tags',
              ],
              disableHideAllButton: true,
              disableShowAllButton: true,
            },
          }}
          toolbarSettings={{
            title: '',
            filterOptions: [],
            searchBox: true,
            filterButton: true,
            headerActions:
              editMode || editHidden
                ? []
                : [
                    {
                      label: 'EDIT',
                      onClick: () => {
                        setEditMode(true);
                      },
                    },
                  ],
          }}
          selectionModel={tasks.map((t) => t.taskId)}
          onSelectionModelChange={(_values) => {
            if (_values?.length > 0) {
              setValue(
                'tasks',
                _values.map((v) => ({ taskId: v })),
                { shouldDirty: true },
              );
              trigger('tasks');
            }
          }}
          selection={editMode}
          disableSelectionOnClick
          actions={viewTaskActions}
          rows={taskRows || []}
          columns={columns}
          pagination
          visibleSelection
          rowsPerPageOptions={[5, 10, 20]}
          tablePageSize={10}
          gridId="training-challenges"
        />
      </Box>
      {editMode && (
        <Box className="flex items-center gap-x-2">
          <FdButton
            variant="primary"
            onClick={updateTasks}
            disabled={saveInProgress}
          >
            Save
          </FdButton>
          <FdButton
            variant="tertiary"
            onClick={() => {
              setEditMode(false);
              reset();
              warningToastMessage('Training activity not updated');
            }}
          >
            Cancel
          </FdButton>
        </Box>
      )}
      {activeTask && openDrawer && (
        <ViewTaskDrawer
          activeTaskData={activeTask}
          openDrawer={openDrawer}
          openDrawerCallBack={setOpenDrawer}
          mainPageIds={['topnav', 'competitions']}
        />
      )}
    </Box>
  );
};

TasksEditTable.defaultProps = {
  loading: false,
};

TasksEditTable.propTypes = {
  loading: PropTypes.bool,
  editHidden: PropTypes.bool.isRequired,
};

export default TasksEditTable;
