import React, { useState, useContext } from 'react';
import { Box, Stepper, Step, StepLabel, Link } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useQuery, useMutation, gql } from '@apollo/client';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import * as singleSpa from 'single-spa';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  FdButton,
  BasePage,
  AuthContext,
  FdLoadingSpinner,
  successToastMessage,
} from '@fifthdomain/fe-shared';
import { initialValues, validationSchema } from '../validation-schemas/Quizzes';
import { scrollToTop } from '../shared/utils/scroll';
import QuizDetails from '../components/Quiz/QuizDetails';
import AddQuizQuestions from '../components/Quiz/AddQuizQuestions';
import { createQuiz, createQuestion } from '../graphql/mutations';
import { getUserById } from '../graphql/queries';

const useStyles = makeStyles()((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  stepper: {
    background: 'none',
    paddingLeft: '5px',
  },
}));

const steps = ['Add Details', 'Add Questions'];

const CreateQuiz = () => {
  const [activeStep, setActiveStep] = useState(0);
  const { user } = useContext(AuthContext);
  const { classes } = useStyles();

  const hookFormMethods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });
  const { control, reset, getValues, trigger, handleSubmit } = hookFormMethods;

  const { data: getUserData, loading: userLoading } = useQuery(
    gql(getUserById),
    {
      variables: {
        id: user?.username,
      },
    },
  );

  const orgId = getUserData?.getUserById?.items[0].orgId;
  const ownerId = getUserData?.getUserById?.items[0].id;

  const [createQuestionMutation, { loading: createQuestionLoading }] =
    useMutation(gql(createQuestion), {
      onCompleted: async () => {
        reset();
        singleSpa.navigateToUrl('/user-management/quizzes');
      },
    });

  const [createQuizMutation, { loading: createQuizLoading }] = useMutation(
    gql(createQuiz),
    {
      onCompleted: async (data) => {
        const { id, name } = data?.createQuiz;
        successToastMessage(`Success! New ${name} created`);
        reset();
        singleSpa.navigateToUrl('/user-management/quizzes');
        await createQuestionMutation({
          variables: {
            input: {
              quizId: id,
              type: 'FREE_TEXT',
              caseSensitive: false,
              hasOptions: false,
              name: 'question',
              point: 10,
              whiteSpaceSensitive: false,
            },
          },
        });
      },
    },
  );

  const onSubmit = async () => {
    const values = getValues();
    const {
      quizName,
      quizDescription,
      quizTimeLimit,
      allowMultipleAttempts,
      returnResults,
    } = values;
    await createQuizMutation({
      variables: {
        input: {
          description: quizDescription,
          duration: quizTimeLimit,
          multipleAttempts: allowMultipleAttempts,
          name: quizName,
          orgId,
          ownerId,
          showResults: returnResults,
        },
      },
    });
  };

  const validatePage = async () => {
    let result;
    switch (activeStep) {
      case 0: {
        result = await trigger(['quizName', 'quizDescription']);
        break;
      }
      default:
        result = true;
        break;
    }
    return result;
  };

  const handleNext = async () => {
    if (await validatePage()) {
      if (activeStep === 1) {
        onSubmit();
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        scrollToTop();
      }
    }
  };

  const handleBack = () => {
    // on click cancel of first page go back to list page
    if (activeStep === 0) {
      singleSpa.navigateToUrl('/user-management/quizzes');
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      scrollToTop();
    }
  };

  if (userLoading || createQuizLoading || createQuestionLoading) {
    return <FdLoadingSpinner />;
  }

  return (
    <BasePage
      heading="Create a Quiz"
      data-cy="create-quiz-base-page"
      breadCrumbs={[{ url: '/', name: 'Home' }]}
      currentPageBreadcrumbLabel="User Management / Manage QUizzes / Create Quiz"
      linkComponent={Link}
    >
      <Box width="650px">
        {' '}
        <Stepper activeStep={activeStep} className={classes.stepper}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      <FormProvider {...hookFormMethods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {
            {
              0: <QuizDetails control={control} Controller={Controller} />,
              1: <AddQuizQuestions control={control} Controller={Controller} />,
            }[activeStep]
          }
          <Box display="flex" justifyContent="space-between" pb={3}>
            <FdButton size="large" variant="secondary" onClick={handleBack}>
              {activeStep === 0 ? 'Cancel' : 'Back'}
            </FdButton>
            <Box>
              <FdButton size="large" variant="secondary" type="submit">
                Save
              </FdButton>
              {activeStep < 1 && (
                <FdButton
                  size="large"
                  onClick={handleNext}
                  style={{ marginLeft: '1rem' }}
                >
                  Next
                </FdButton>
              )}
            </Box>
          </Box>
        </form>
      </FormProvider>
    </BasePage>
  );
};

export default CreateQuiz;
