import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Box } from '@mui/material';
import { FdTable, FdChips } from '@fifthdomain/fe-shared';
import { getTagColor } from '../../../shared/utils/tagUtils';
import { sortByDateField } from '../../../shared/utils/dateUtils';
import TaskInfoCell from './TaskInfoCell';
import TableHeaderColumnWithTooltip from './TableHeaderColumnWithTooltip';
import {
  getCommaSeparatedPlusSuffix,
  capitalize,
} from '../../../shared/utils/stringUtils';
import { getDifficultyLevel } from '../../../shared/utils/difficultyMapping';
import { PROFICIENCY_LEVELS } from '../../../constants';

const getTableData = (tData, _tasks) =>
  tData?.map((d) => ({
    ...d,
  })) || [];

const TasksTable = ({
  templateMode,
  templateEditButton,
  templateColumns,
  trainingTemplate,
  data,
  editTasks,
  setEditTasksCallBack,
  setOpenDrawer,
  setActiveTask,
  viewAssessment,
  showEditButton,
  onEditMode,
}) => {
  const { control, setValue } = useFormContext();
  const createTemplateMode = templateMode === 'create';
  const [selectionModel, setSelectionModel] = useState([]);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    difficulty: trainingTemplate ? true : false,
    estimatedSolveTime: false,
    technologyTags: false,
    techniqueTags: false,
    creator: false,
  });

  const { fields: tasks } = useFieldArray({
    control,
    name: 'tasks',
  });

  const tableData = useMemo(() => getTableData(data, tasks), [data, tasks]);

  const viewTaskActions = [
    {
      label: 'View',
      onClick: ({ id }) => {
        setOpenDrawer(true);
        const activeTaskData = tableData?.find((t) => t.id === id);
        setActiveTask(activeTaskData);
      },
    },
  ];

  useEffect(() => setSelectionModel(tasks?.map((t) => t?.taskId)), [tasks]);

  const columns = [
    {
      field: 'name',
      width: 300,
      headerName: 'Challenge Name',
      valueGetter: (params) => params.row.name,
      renderCell: (params) => <TaskInfoCell values={params.row} />,
    },
    {
      field: 'specialtyName',
      flex: 1,
      headerName: 'Professional Specialty',
      renderHeader: () => (
        <TableHeaderColumnWithTooltip
          title="Professional Specialty"
          tooltipText="Professional specialties represent broad categories of commonly found technical functions within the field of cyber operations. These specialties offer a structured framework under which a particular challenge can be classified."
        />
      ),
    },
    {
      field: 'skills',
      flex: 1,
      headerName: 'Skills',
      renderHeader: () => (
        <TableHeaderColumnWithTooltip
          title="Skills"
          tooltipText="Skills indicate the specific, acquired ability necessary to solve a challenge within a given timeframe or effort."
        />
      ),
      renderCell: (params) => getCommaSeparatedPlusSuffix(params.value),
    },
    {
      field: 'type',
      width: 100,
      headerName: 'Type',
      renderCell: (params) => capitalize(params?.value),
    },
    {
      field: 'difficulty',
      flex: 1,
      headerName: 'Proficiency',
    },
    {
      field: 'estimatedSolveTime',
      flex: 1,
      headerName: 'Est. Time to Solve (mins)',
      type: 'number',
    },
    {
      field: 'tags',
      flex: 1,
      headerName: 'Assigned Tags',
      valueGetter: (params) =>
        params.row?.tags?.length > 0
          ? [...params.row?.tags].map((t) => t.Tag?.name)?.join(' ')
          : '',
      renderCell: (params) => {
        const _data = params.row?.tags
          ?.sort(sortByDateField('updatedAt', 'desc'))
          .map((t) => ({
            label: t.Tag?.name,
            color: getTagColor(t.Tag?.color),
          }));
        return _data?.length > 0 ? (
          <FdChips data={_data} numberOfChipsDisplayed={1} caption="More" />
        ) : (
          <Box width="100%" textAlign="center">
            -
          </Box>
        );
      },
    },
    {
      field: 'recommendedPoints',
      width: 100,
      headerName: 'Points',
      type: 'number',
    },
  ];

  const editTask = viewAssessment || !editTasks ? editTasks : true;

  return (
    <Box mt={2} mb={2} height={editTask ? '550px' : '650px'} width="100%">
      <FdTable
        defaultMuiToolbarSettings={{
          showMuiDefaultToolbar: true,
          columnsButton: !trainingTemplate,
          filterButton: true,
          densityButton: true,
        }}
        rowHeight={60}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) =>
          setColumnVisibilityModel(newModel)
        }
        toolbarSettings={{
          title:
            !trainingTemplate &&
            `${!onEditMode ? 'Add Challenges' : 'Challenges'}`,
          headerActions:
            showEditButton && !editTasks && templateEditButton
              ? [
                  {
                    label: 'EDIT',
                    onClick: () => {
                      setEditTasksCallBack(true);
                    },
                  },
                ]
              : [],
          filterOptions: [],
          searchBox: true,
          filterButton: editTask,
        }}
        selectionModel={selectionModel}
        onSelectionModelChange={(_values) => {
          setSelectionModel(_values);
          const _tasks = createTemplateMode
            ? tableData
                ?.filter((item) => _values?.includes(item?.id))
                ?.map((task) => ({ ...task, taskId: task?.id }))
            : _values.map((v) => ({ taskId: v }));
          setValue('tasks', _tasks);
        }}
        selection={editTask || createTemplateMode}
        disableSelectionOnClick
        actions={viewTaskActions}
        rows={
          tableData?.map((d) => {
            return {
              ...d,
              difficulty:
                d.difficulty > 5
                  ? getDifficultyLevel(d.difficulty)
                  : PROFICIENCY_LEVELS[d.difficulty],
            };
          }) || []
        }
        columns={templateColumns || columns}
        pagination
        visibleSelection
        rowsPerPageOptions={[5, 10, 20]}
        tablePageSize={10}
        gridId="org-list-tasks"
      />
    </Box>
  );
};

TasksTable.defaultProps = {
  editTasks: true,
  setEditTasksCallBack: () => {},
  viewAssessment: false,
  templateEditButton: true,
  trainingTemplate: false,
  showEditButton: undefined,
  templateMode: '',
  setOpenDrawer: () => {},
  setActiveTask: () => {},
  templateColumns: [],
  onEditMode: false,
};

TasksTable.propTypes = {
  templateColumns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ),
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      category: PropTypes.string,
      difficulty: PropTypes.number,
      solveTime: PropTypes.number,
      recommendedPoints: PropTypes.number,
    }).isRequired,
  ).isRequired,
  editTasks: PropTypes.bool,
  setEditTasksCallBack: PropTypes.func,
  viewAssessment: PropTypes.bool,
  setOpenDrawer: PropTypes.func,
  setActiveTask: PropTypes.func,
  showEditButton: PropTypes.bool,
  templateEditButton: PropTypes.bool,
  trainingTemplate: PropTypes.bool,
  templateMode: PropTypes.string,
  onEditMode: PropTypes.bool,
};

export default TasksTable;
