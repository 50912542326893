import React from 'react';
import _ from 'lodash';
import { Box, Card } from '@mui/material';
import PropTypes from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import {
  FdCard,
  FdSelect,
  FdTypography,
  FdButton,
  FdDelayed,
  successToastMessage,
} from '@fifthdomain/fe-shared';
import SummaryCell from '../Fragments/SummaryCell';
import NoWorkRoleAssigned from '../Fragments/NoWorkRoleAssigned';
import WorkRolesTable from '../Fragments/WorkRolesTable';
import {
  editModeCreateCustomerWorkRoles,
  getFunctionCortexTargets,
  onError,
} from '../utils';
import { createCustomerWorkroleFunction } from '../../../graphql/mutations';
import TargetsCortex from '../Cortex/TargetsCortex';

const FunctionSkillsTarget = ({
  allCustomerWorkRoles,
  isEditMode,
  refetchFunction,
}) => {
  const { trigger, control, watch, setValue, getValues } = useFormContext();
  const [createCustomerWorkRoleFunctionMutation] = useMutation(
    gql(createCustomerWorkroleFunction),
    {
      onError,
    },
  );
  const {
    fields: workRoles,
    append: appendWorkRole,
    remove: removeWorkRole,
  } = useFieldArray({
    control,
    name: 'workRoles',
  });
  const workRolesSelection = watch('workRolesSelection');
  const workRolesCount = workRoles?.length || 0;
  const workRolesWatch = watch('workRoles');
  const workRolesUnitsCount = workRolesWatch.reduce(
    (acc, workRole) => acc + workRole?.units,
    0,
  );
  const { targets, fillers } = getFunctionCortexTargets(workRolesWatch);
  const cortexData = {
    targets,
    fillers,
  };
  const requiredUnits = _.sumBy(targets, 'units');
  const uniqueSkills = _.uniqBy(targets, 'skillAlias')?.length || 0;

  return (
    <FdCard
      variant="outlined"
      heading={
        <FdTypography variant="h3">
          {`${!isEditMode ? 'Define the ' : ''}Skills Target for this Function`}
        </FdTypography>
      }
    >
      <Box mb={2}>
        <FdTypography variant="captiontext1">
          From the dropdown list of all work roles created for your
          organisation, select the ones relevant to this function. Specify the
          number of units needed for each selected work role. As you add work
          roles, the required skills for those roles will be highlighted on the
          Cyber Skills Cortex. The number of units needed for each skill will be
          indicated on the side labels. This visual representation will provide
          a clear picture of the expected skills requirement of the function.
        </FdTypography>
      </Box>
      <Card variant="outlined" sx={{ padding: 1.5 }}>
        <Box className="grid grid-cols-4 divide-x">
          <SummaryCell
            header={requiredUnits > 0 ? requiredUnits : '-'}
            label={
              <Box className="flex items-center gap-x-2">
                <Box
                  sx={{
                    width: '16px',
                    height: '16px',
                    border: '3px solid rgba(0, 151, 167, 1)',
                  }}
                />
                Required Units
              </Box>
            }
            tooltipText="Each square on the Skills Cortex represents a Skill-Proficiency unit. The Required Units for a function are the total skill-proficiency units needed, combining all units required for each selected work role. This is represented by highlighted borders on the Skills Cortex"
          />
          <SummaryCell
            header={uniqueSkills > 0 ? uniqueSkills : '-'}
            label="Required Unique Skills"
            tooltipText="The total number of distinct skills needed for the function, considering all selected work roles."
          />
          <SummaryCell
            header={workRolesCount > 0 ? workRolesCount : '-'}
            label="Number of Unique Work Roles"
            tooltipText="The number of different work roles required as a target for the function."
          />
          <SummaryCell
            header={workRolesUnitsCount > 0 ? workRolesUnitsCount : '-'}
            label="Number of Work Roles"
            tooltipText="The total count of work role units required for the function."
          />
        </Box>
      </Card>
      <Box className="grid grid-cols-2 gap-8 mt-8">
        <Box>
          <FdTypography variant="subtitle1">
            Add from a list of Work Roles in your organisation.
          </FdTypography>
          <Box className="flex items-center gap-x-3 mt-2">
            <Box className="w-full">
              <Controller
                control={control}
                name="workRolesSelection"
                render={({ field, fieldState: { error } }) => (
                  <FdDelayed
                    showSkeleton
                    height="56px"
                    triggerField={workRoles}
                    delay={1}
                  >
                    <FdSelect
                      inputTitle=""
                      id="workRolesSelection"
                      options={
                        allCustomerWorkRoles?.filter(
                          (w) =>
                            !workRoles?.some((wr) => wr.workRoleId === w.id),
                        ) || []
                      }
                      placeholder="Add Work Roles"
                      showSelectAllOption={false}
                      multiple
                      fullWidth
                      error={error}
                      helperText={error && error.message}
                      {...field}
                    />
                  </FdDelayed>
                )}
              />
            </Box>
            <FdButton
              onClick={async () => {
                workRolesSelection.forEach((workRoleId) => {
                  const customerRole =
                    allCustomerWorkRoles?.find((w) => w.id === workRoleId) ||
                    {};
                  appendWorkRole({
                    workRoleId,
                    workRoleName: customerRole?.name,
                    units: 1,
                    skills: customerRole?.customerSkills?.items,
                  });
                });
                setValue('workRolesSelection', []);
                const result = await trigger([
                  'workRoles',
                  'workRolesSelection',
                ]);
                if (isEditMode & result) {
                  const { workRoles, functionId } = getValues();
                  // save newly added work roles to db
                  editModeCreateCustomerWorkRoles({
                    workRoleMutation: createCustomerWorkRoleFunctionMutation,
                    functionId,
                    workRoles,
                    onCompleted: () => {
                      successToastMessage(
                        "Success! Work Role added to the function's target state.",
                      );
                      refetchFunction();
                    },
                  });
                }
              }}
            >
              Add
            </FdButton>
          </Box>
          <Box mt={4}>
            <FdTypography variant="subtitle1">Selected Work Roles</FdTypography>
            {workRoles?.length > 0 ? (
              <WorkRolesTable
                removeWorkRole={removeWorkRole}
                refetchFunction={refetchFunction}
              />
            ) : (
              <NoWorkRoleAssigned />
            )}
          </Box>
        </Box>
        <Box>
          <FdTypography variant="subtitle1" style={{ textAlign: 'center' }}>
            Cyber Skills Cortex (Function Skills Target)
          </FdTypography>
          <Box className="flex flex-col items-center justify-center">
            <Box
              sx={{
                backgroundColor: 'rgba(235, 242, 255, 1)',
                borderRadius: '8px',
                mt: 1,
                mb: 1,
                p: '1rem',
              }}
            >
              <FdTypography variant="captiontext1" color="secondary">
                Hover over a square to see the units needed for each
                Skill-Proficiency. The total units needed for each skill are
                shown on the side labels.
              </FdTypography>
            </Box>
            <TargetsCortex data={cortexData} showUnits />
          </Box>
        </Box>
      </Box>
    </FdCard>
  );
};

FunctionSkillsTarget.propTypes = {
  isEditMode: PropTypes.bool,
  refetchFunction: PropTypes.func,
};

FunctionSkillsTarget.defaultProps = {
  isEditMode: false,
  refetchFunction: () => null,
};

export default FunctionSkillsTarget;
