import { useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import Color from 'color';

const useSyncMuiThemeWithTailwind = () => {
  const theme = useTheme();

  useEffect(() => {
    const root = document.documentElement;
    const colorPrimary = Color(theme.palette.primary.main);

    // convert color to HSL
    const toHSL = (colorValue) =>
      Color(colorValue.trim())
        .hsl()
        .string()
        .replace(/hsl\(|\)/g, '')
        .replace(/,\s*/g, ' ');

    // mapping of MUI palette keys to Tailwind CSS variables
    const themeMappings = {
      '--accent': toHSL(theme.palette.listItem.selectedBackground),
      '--accent-foreground': toHSL(
        theme.palette.mode === 'light'
          ? theme.palette.primary.main
          : theme.palette.typography.primary,
      ),
      '--muted': toHSL(theme.palette.table.border),
      '--muted-foreground': toHSL(Color(colorPrimary).darken(0.2).hex()),
      '--breadcrumb-hover': toHSL(theme.palette.primary.main),
      '--breadcrumb-page':
        theme.palette.mode === 'light'
          ? '0 0% 0% / 70%'
          : toHSL(theme.palette.typography.primary),
      '--border': toHSL(theme.palette.table.border),
      '--popover': toHSL(theme.palette.background.sidebar),
      '--popover-foreground': toHSL(theme.palette.typography.primary),
      '--sidebar-background': toHSL(theme.palette.background.sidebar),
      '--sidebar-foreground': toHSL(
        theme.palette.mode === 'dark'
          ? '#9E9E9E'
          : theme.palette.typography.primary,
      ),
      '--sidebar-primary': toHSL(theme.palette.typography.primary),
      '--sidebar-primary-foreground': toHSL(theme.palette.typography.primary),
      '--sidebar-accent': toHSL(theme.palette.listItem.selectedBackground),
      '--sidebar-accent-foreground': toHSL(
        theme.palette.mode === 'light'
          ? theme.palette.primary.main
          : theme.palette.typography.primary,
      ),
      '--sidebar-border': toHSL(theme.palette.table.border),
      '--sidebar-ring': toHSL(theme.palette.listItem.color),
    };

    Object.entries(themeMappings).forEach(([key, value]) => {
      if (value) {
        root.style.setProperty(key, value);
      }
    });
  }, [theme]);
};

export default useSyncMuiThemeWithTailwind;
