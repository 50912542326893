import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  useFormContext,
  Controller,
  useFieldArray,
  useWatch,
} from 'react-hook-form';
import { Box } from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import {
  FdAccordion,
  FdTypography,
  FdButton,
  FdSelect,
  FdTextField,
  FdAlert,
  FdDelayed,
  warningToastMessage,
  successToastMessage,
} from '@fifthdomain/fe-shared';
import { COURSE_PARTS } from '../../../constants';
import PartIcon from '../Modules/PartIcon';
import FdFileUploadModal from '../../FdFileUploadModal/FdFileUploadModal';
import FdTextView from '../../FdTextView';
import MarkdownPdfPart from './MarkdownPdfPart';
import QuizPart from './QuizPart';
import LabPart from './LabPart';
import VideoPart from './VideoPart';
import useDragDrop from '../../../hooks/useDragDrop';

const CoursePart = ({
  moduleIdx,
  partIdx,
  part,
  deletePart,
  deletePartSummaryItem,
  allQuizzes,
  allLabs,
  isEdit,
  swapParts,
}) => {
  const [uploadOpen, setUploadOpen] = useState(false);
  const {
    control,
    setValue,
    trigger,
    getValues,
    formState: { errors },
  } = useFormContext();
  const modulePartRef = useRef(null);

  const watchModulePart = useWatch({
    control,
    name: `modules[${moduleIdx}].parts[${partIdx}]`,
  });

  const watchPartType = useWatch({
    control,
    name: `modules[${moduleIdx}].parts[${partIdx}].partType`,
  });
  const watchPartFile = useWatch({
    control,
    name: `modules[${moduleIdx}].parts[${partIdx}].file`,
  });
  const watchModuleAccordionState = useWatch({
    control,
    name: `modules[${moduleIdx}].parts[${partIdx}].accordionState`,
  });
  const { append: appendDeletePart } = useFieldArray({
    control,
    name: 'partsToDelete',
  });

  const moveCard = (dragIdx, hoverIdx) => {
    const sourcePart = getValues(
      `modules[${moduleIdx}].parts[${dragIdx}].order`,
    );
    const destinationPart = getValues(
      `modules[${moduleIdx}].parts[${hoverIdx}].order`,
    );
    // interchange order
    if (sourcePart && destinationPart && sourcePart !== destinationPart) {
      setValue(`modules[${moduleIdx}].parts[${hoverIdx}].order`, sourcePart);
      setValue(
        `modules[${moduleIdx}].parts[${dragIdx}].order`,
        destinationPart,
      );
      swapParts(dragIdx, hoverIdx);
    }
  };

  const { handlerId, isDragging, drag, drop } = useDragDrop({
    moveCard,
    ref: modulePartRef,
    dragIdx: watchModulePart?.id,
    dropIdx: partIdx,
    canDrag: isEdit,
    canDrop: isEdit,
  });
  const opacity = isDragging ? 0.5 : 1;
  drag(drop(modulePartRef));

  const partType = String(watchPartType).toUpperCase();
  const fileType = watchPartType === 'PDF' ? '.pdf' : '.md';
  const onConfirm = (fn) => fn(false);
  const onDismiss = (fn) => fn(false);
  const fileError = errors?.modules?.[moduleIdx]?.parts?.[partIdx]?.file;
  const isLabPartForDuplication = window.location.href.includes('/duplicate/');

  return (
    <Box
      mb={1}
      display="flex"
      alignItems="center"
      width="100%"
      ref={modulePartRef}
      style={{ opacity }}
      data-handler-id={handlerId}
    >
      {isEdit && (
        <Box mr={2} style={{ cursor: 'pointer' }}>
          <DragIndicatorIcon />
        </Box>
      )}
      <Box flexGrow={1}>
        <FdAccordion
          data-cy="part"
          summary={() => (
            <Box
              display="flex"
              justifyContent="flex-start"
              width="100%"
              alignItems="center"
            >
              <Box mr={1}>{watchPartType && <PartIcon type={partType} />}</Box>
              <FdTypography variant="h3">
                {watchPartType || 'New Part'}
              </FdTypography>
            </Box>
          )}
          TransitionProps={{ unmountOnExit: true }}
          expanded={watchModuleAccordionState}
          onExpandCollapse={() =>
            setValue(
              `modules[${moduleIdx}].parts[${partIdx}].accordionState`,
              !watchModuleAccordionState,
            )
          }
          actions={
            isEdit ? (
              <Box display="flex" alignItems="center">
                <Controller
                  control={control}
                  name={`modules[${moduleIdx}].parts[${partIdx}].partType`}
                  render={({
                    field: { id, ref, value: fieldValue, ...rest },
                    fieldState: { error },
                  }) => (
                    <FdDelayed>
                      <Box width="498px">
                        <FdSelect
                          disabled={
                            partType === 'LAB' && !isLabPartForDuplication
                          }
                          key={id}
                          id={`modules[${moduleIdx}].parts[${partIdx}].partType`}
                          label=""
                          options={COURSE_PARTS}
                          defaultSelected={fieldValue}
                          fullWidth
                          error={error}
                          helperText={error && error.message}
                          data-cy={`modules[${moduleIdx}].parts[${partIdx}].partType`}
                          placeholder="Select Part Type"
                          {...rest}
                          inputRef={ref}
                          onChange={(value) => {
                            setValue(
                              `modules[${moduleIdx}].parts[${partIdx}].partType`,
                              value,
                            );
                            setValue(
                              `partsSummary[${moduleIdx}].parts[${partIdx}].partType`,
                              value,
                            );
                          }}
                        />
                      </Box>
                    </FdDelayed>
                  )}
                />
                <Box ml={1}>
                  <FdButton
                    variant="secondary"
                    onClick={async (e) => {
                      e.preventDefault();
                      const actionId = part;
                      if (actionId) {
                        // delete from db if present on save
                        appendDeletePart(actionId);
                      }
                      deletePart(partIdx);
                      deletePartSummaryItem(partIdx);
                    }}
                  >
                    Delete
                  </FdButton>
                </Box>
              </Box>
            ) : (
              []
            )
          }
          content={() => (
            <Box display="flex" flexDirection="column">
              {fileError && (
                <Box mt={2} mb={2}>
                  <FdAlert
                    variant="error"
                    message={`Please upload a ${fileType} file`}
                  />
                </Box>
              )}
              <Controller
                control={control}
                name={`modules[${moduleIdx}].parts[${partIdx}].partTitle`}
                render={({
                  field: { value, ref, ...rest },
                  fieldState: { error },
                }) => (
                  <Box mt={1} mb={2}>
                    {isEdit ? (
                      <FdTextField
                        id={`modules[${moduleIdx}].parts[${partIdx}].partTitle`}
                        label="Title"
                        value={value}
                        required
                        fullWidth
                        placeholder="Title must be 120 characters or less"
                        error={error}
                        helperText={error && error.message}
                        data-cy={`modules[${moduleIdx}].parts[${partIdx}].partTitle`}
                        {...rest}
                        inputRef={ref}
                      />
                    ) : (
                      <FdTextView label="Title" value={value} />
                    )}
                  </Box>
                )}
              />
              <Box mt={0} mb={0}>
                <Controller
                  control={control}
                  name={`modules[${moduleIdx}].parts[${partIdx}].partDescription`}
                  render={({
                    field: { value, ref, ...rest },
                    fieldState: { error },
                  }) => (
                    <Box mb={2}>
                      {isEdit ? (
                        <FdTextField
                          id={`modules[${moduleIdx}].parts[${partIdx}].partDescription`}
                          label="Description"
                          value={value}
                          fullWidth
                          required
                          placeholder="Description must be 256 characters or less"
                          error={error}
                          helperText={error && error.message}
                          data-cy={`modules[${moduleIdx}].parts[${partIdx}].partDescription`}
                          {...rest}
                          inputRef={ref}
                        />
                      ) : (
                        <FdTextView label="Description" value={value} />
                      )}
                    </Box>
                  )}
                />
              </Box>
              {['PDF', 'Markdown'].includes(watchPartType) && (
                <MarkdownPdfPart
                  moduleIdx={moduleIdx}
                  partIdx={partIdx}
                  setUploadOpen={setUploadOpen}
                  isEdit={isEdit}
                />
              )}
              {watchPartType === 'Quiz' && (
                <QuizPart
                  moduleIdx={moduleIdx}
                  partIdx={partIdx}
                  isEdit={isEdit}
                  allQuizzes={allQuizzes}
                />
              )}
              {watchPartType === 'Lab' && (
                <LabPart
                  moduleIdx={moduleIdx}
                  partIdx={partIdx}
                  isEdit={isEdit}
                  allLabs={allLabs}
                />
              )}
              {watchPartType === 'Video' && (
                <VideoPart
                  moduleIdx={moduleIdx}
                  partIdx={partIdx}
                  isEdit={isEdit}
                />
              )}
            </Box>
          )}
          startAdornment
          showToggleButton={false}
        />
      </Box>
      <FdFileUploadModal
        title={`Upload ${partType === 'MARKDOWN' ? 'Markdown' : partType}`}
        description={`Select and upload a ${fileType} file from your local drive.`}
        confirm="Save"
        dismiss="Cancel"
        open={uploadOpen}
        onConfirm={async (files) => {
          onConfirm(setUploadOpen);
          setValue(`modules[${moduleIdx}].parts[${partIdx}].file`, files[0]);
          await trigger(`modules[${moduleIdx}].parts[${partIdx}].file`);
          successToastMessage('Success! File Uploaded');
        }}
        onDismiss={() => {
          onDismiss(setUploadOpen);
          warningToastMessage('No files were added');
        }}
        setOpen={setUploadOpen}
        fileTypes={fileType}
        maxWidth="md"
        initialFiles={watchPartFile || []}
      />
    </Box>
  );
};

CoursePart.propTypes = {
  moduleIdx: PropTypes.number.isRequired,
  partIdx: PropTypes.number.isRequired,
  deletePart: PropTypes.func.isRequired,
  deletePartSummaryItem: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  allQuizzes: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  allLabs: PropTypes.array.isRequired,
  isEdit: PropTypes.bool,
  part: PropTypes.shape({
    partType: PropTypes.string,
    partId: PropTypes.string,
    file: PropTypes.shape({ name: PropTypes.string, key: PropTypes.string }),
  }).isRequired,
  swapParts: PropTypes.func,
};

CoursePart.defaultProps = {
  isEdit: false,
  swapParts: () => {},
};

export default CoursePart;
