import React, { useState } from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import { Controller, useFormContext } from 'react-hook-form';
import {
  FdCard,
  FdTable,
  FdModal,
  FdMarkdownEditor,
  successToastMessage,
  warningToastMessage,
} from '@fifthdomain/fe-shared';
import { useMutation, gql } from '@apollo/client';
import { deleteHint } from '../../graphql/mutations';

const Hints = () => {
  const [selectedHint, setSelectedHint] = useState(null);
  const [hintOpen, setHintOpen] = useState(false);
  const [releasedOnStart, setReleasedOnStart] = useState(false);
  const { control, getValues, setValue } = useFormContext();
  const [deleteHintMutation] = useMutation(gql(deleteHint));

  return (
    <Box>
      <FdCard
        heading="Hints"
        subHeading="Add hints to help participants complete the challenge. You can add as many hints as required."
        variant="outlined"
        optional
      >
        <Box>
          <Controller
            control={control}
            name="hints"
            render={({ field }) => (
              <FdTable
                hideFooter={!field.value?.length}
                autoHeight
                columns={[
                  {
                    field: 'description',
                    headerName: 'Hint description',
                    flex: 1,
                  },
                ]}
                rows={field.value}
                pageSize={5}
                noRowsMessage="No Hints to display"
                toolbarSettings={{
                  title: 'Hints',
                  headerActions: [
                    {
                      label: 'Add Hint',
                      onClick: () => {
                        setSelectedHint('');
                        setValue('hint', '');
                        setReleasedOnStart(false);
                        setHintOpen(true);
                      },
                    },
                  ],
                }}
                actions={[
                  {
                    label: 'Edit',
                    onClick: ({ id }) => {
                      const _selectedHint = field.value.find(
                        (h) => h.id === id,
                      );
                      setSelectedHint(_selectedHint);
                      setValue('hint', _selectedHint.description);
                      setReleasedOnStart(_selectedHint.releasedOnStart);
                      setHintOpen(true);
                    },
                  },
                  {
                    label: 'Delete',
                    onClick: ({ id }) => {
                      setValue(
                        'hints',
                        field.value.filter((h) => h.id !== id),
                      );
                      deleteHintMutation({
                        variables: {
                          input: {
                            id,
                          },
                        },
                      });
                      successToastMessage(
                        'Success! The Hint was removed from the challenge',
                      );
                    },
                  },
                ]}
              />
            )}
          />
        </Box>
      </FdCard>
      <FdModal
        title={selectedHint ? 'Edit Hint' : 'Add Hint'}
        description="Type the hint in the editor below."
        confirm={selectedHint ? 'Save' : 'Add hint'}
        dismiss="Cancel"
        open={hintOpen}
        onConfirm={() => {
          if (getValues().hint?.length) {
            setHintOpen(false);
            if (selectedHint) {
              setValue(
                'hints',
                getValues().hints.map((h) =>
                  h.id === selectedHint.id
                    ? {
                        ...h,
                        description: getValues().hint,
                        releasedOnStart,
                      }
                    : { ...h },
                ),
              );
              setSelectedHint(null);
              setValue('hint', '');
              setReleasedOnStart(false);
            } else {
              setValue('hints', [
                ...getValues().hints,
                {
                  id: shortid.generate(),
                  description: getValues().hint,
                  releasedOnStart,
                },
              ]);
            }
          }
        }}
        onDismiss={() => {
          setHintOpen(false);
          setSelectedHint(null);
          warningToastMessage('No changes were saved to the challenge');
        }}
        setOpen={setHintOpen}
        maxWidth="md"
      >
        <Controller
          control={control}
          name="hint"
          render={({ field }) => (
            <FdMarkdownEditor
              id="hint-text"
              name="hint"
              markdown={field.value}
              setMarkdown={(_md) => setValue('hint', _md)}
              label=""
              {...field}
            />
          )}
        />
      </FdModal>
    </Box>
  );
};

Hints.propTypes = {
  setSelectedHintCallBack: PropTypes.func,
  setReleasedOnStartCallBack: PropTypes.func,
  setHintOpenCallBack: PropTypes.func,
  setValueHint: PropTypes.func,
};

Hints.defaultProps = {
  setSelectedHintCallBack: () => {},
  setReleasedOnStartCallBack: () => {},
  setHintOpenCallBack: () => {},
  setValueHint: () => {},
};

export default Hints;
