import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';
import {
  TextField,
  IconButton,
  InputAdornment,
  Box,
  lighten,
  darken,
  Tooltip,
} from '@mui/material';
import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined';
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import {
  FdTypography,
  FdTooltip,
  warningToastMessage,
} from '@fifthdomain/fe-shared';
import ImagePreview from './ImagePreview';

const maxSizeInBytes = 5 * 1024 * 1024; // 5MB
const maxTextLimit = 1000;

function fileSizeValidator(file) {
  if (file.size > maxSizeInBytes) {
    return {
      code: 'too-large',
    };
  }
  return null;
}

const MessageInput = ({ placeholder, captionText, onSubmitMessage }) => {
  const [text, setText] = useState('');
  const [images, setImages] = useState([]);
  const { getRootProps, open, getInputProps } = useDropzone({
    onDrop: (files) => {
      if (files.length > 3) {
        warningToastMessage('Only 3 files are accepted for each message');
      }
      setImages((allFiles) => [...allFiles, ...files].slice(0, 3));
    },
    noClick: true,
    accept: {
      'image/png': ['.png'],
      'image/jpg': ['.jpg'],
      'image/jpeg': ['.jpeg'],
    },
    multiple: true,
    validator: fileSizeValidator,
    onDropRejected: (files) => {
      if (files?.length > 0) {
        warningToastMessage('Only image files smaller than 5MB are accepted.');
      }
    },
  });

  const handleTextChange = (event) => {
    setText(event.target.value);
  };
  const textExceedsLimit = text.trim().length > maxTextLimit;

  const handleSubmit = () => {
    if (textExceedsLimit) {
      return;
    }
    onSubmitMessage({ text: text.trim(), images });
    setText('');
    setImages([]);
  };

  const handleKeyDown = (event) => {
    if (text.trim() === '') return;
    if (event.key === 'Enter') {
      if (event.ctrlKey || event.metaKey || event.shiftKey) {
        // `metaKey` is `Command` on macOS
        // Ctrl + Enter or Command + Enter or Shift + Enter: Add a new line
        event.preventDefault();
        setText((prevText) => `${prevText}\n`);
      } else {
        // Enter only: Submit form
        event.preventDefault();
        handleSubmit();
      }
    }
  };

  const getBgColor = (theme) =>
    theme?.palette?.type === 'dark'
      ? '#111010'
      : theme.palette.inputs.background;

  return (
    <>
      <Box
        className="flex flex-col items-center w-full border rounded-2xl"
        sx={(theme) => ({
          backgroundColor: getBgColor(theme),
          borderColor:
            (theme?.palette?.type === 'dark'
              ? theme?.palette?.primary?.main
              : theme?.palette?.inputs?.border) || '#ffffff',
        })}
      >
        {images?.length > 0 && (
          <Box className="flex mt-3 bg-transparent w-full ml-20 gap-x-3">
            {images.map((image) => {
              const imageUrl = URL.createObjectURL(image);
              return (
                <ImagePreview
                  imageSrc={imageUrl}
                  onClose={() =>
                    setImages((allImages) =>
                      allImages.filter((img) => img?.name !== image?.name),
                    )
                  }
                  key={image?.name}
                />
              );
            })}
          </Box>
        )}
        <TextField
          variant="outlined"
          placeholder={placeholder}
          multiline
          maxRows={6}
          value={text}
          onChange={handleTextChange}
          onKeyDown={handleKeyDown}
          sx={(theme) => ({
            width: '100%',
            '& .MuiInputBase-input': {
              fontSize: '13px',
            },
            '& .MuiOutlinedInput-root': {
              display: 'flex',
              alignItems: 'flex-end',
              borderRadius: '16px',
              '& fieldset': {
                border: 'none',
              },
              padding: '0.9rem 0.6rem',
              background: getBgColor(theme),
            },
            '& .MuiInputAdornment-root': {
              alignSelf: 'flex-end',
              paddingBottom: '0.7rem',
            },
          })}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" {...getRootProps()}>
                <input {...getInputProps()} />
                <FdTooltip title="You can upload up to 3 images (PNG, JPG, JPEG) at a time in a single message. Each image can be up to 5MB, with a total limit of 15MB for all 3 images.">
                  <IconButton
                    component="label"
                    sx={(theme) => ({
                      padding: '0',
                      color: theme.palette?.primary?.main,
                      '&:disabled': {
                        color: darken(theme.palette?.primary?.main, 0.5),
                      },
                    })}
                    onClick={open}
                    disabled={images?.length >= 3}
                  >
                    <InsertPhotoOutlinedIcon
                      sx={{
                        width: '28px',
                        height: '28px',
                      }}
                    />
                  </IconButton>
                </FdTooltip>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip
                  placement="top"
                  title={
                    textExceedsLimit &&
                    `Message is too long. Please shorten to ${maxTextLimit} characters or less.`
                  }
                >
                  <span>
                    <IconButton
                      onClick={handleSubmit}
                      sx={(theme) => ({
                        backgroundColor: theme.palette?.primary?.main,
                        color: '#000',
                        borderRadius: '50%',
                        width: '32px',
                        height: '32px',
                        '&:hover': {
                          backgroundColor: lighten(
                            theme.palette?.primary?.main,
                            0.2,
                          ),
                        },
                        '&:disabled': {
                          backgroundColor: darken(
                            theme.palette?.primary?.main,
                            0.5,
                          ),
                          color: '#000',
                        },
                      })}
                      disabled={text.trim() === '' || textExceedsLimit}
                    >
                      <ArrowUpwardOutlinedIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              </InputAdornment>
            ),
          }}
          className="w-full rounded-2xl"
        />
      </Box>
      {captionText && (
        <Box className="mt-2 text-center">
          <FdTypography variant="captiontext2" color="secondary">
            {captionText}
          </FdTypography>
        </Box>
      )}
    </>
  );
};

MessageInput.defaultProps = {
  placeholder: '',
  captionText: '',
};

MessageInput.propTypes = {
  placeholder: PropTypes.string,
  captionText: PropTypes.string,
  onSubmitMessage: PropTypes.func.isRequired,
};

export default MessageInput;
