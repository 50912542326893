import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import withThemeProviderSubscribe from './WithThemeProviderSubscribe';

const AppBasePageProvider = ({ children }) => {
  return (
    <Box
      className="flex flex-1 flex-col px-3 pb-4 pt-0 transition-[width,height] ease-linear"
      sx={(theme) => ({
        background: theme.palette.background.default,
        color: theme.palette.typography.primary,
        minHeight: '100vh',
      })}
    >
      {children}
    </Box>
  );
};

AppBasePageProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default withThemeProviderSubscribe(AppBasePageProvider);
