import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Box } from '@mui/material';
import StarsIcon from '@mui/icons-material/Stars';
import {
  FdTable,
  FdTypography,
  FdButton,
  FdSkeleton,
  warningToastMessage,
} from '@fifthdomain/fe-shared';
import TableHeaderColumnWithTooltip from '../Assessment/TableHeaderColumnWithTooltip';
import ProgressIndicator from './ProgressIndicator';

const ToolTip = ({ name, value, min, max, label }) => (
  <Box>
    <FdTypography variant="captiontext1">{name}</FdTypography>
    <FdTypography variant="captiontext2">
      {`${label}: ${Math.round(value)}`}
    </FdTypography>
    <FdTypography variant="captiontext2">
      {`± 1 Standard Deviation Range: ${Math.round(min)} - ${Math.round(max)}`}
    </FdTypography>
  </Box>
);

ToolTip.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
};

const RankingTable = ({
  rows,
  selectedCandidates,
  setSelectedCandidates,
  loading,
  onParticipantClick,
  teamBased,
}) => {
  const teamOrCandidateText = teamBased ? 'Team' : 'Participant';

  const assessmentRange = {
    Success: {
      min: _.minBy(rows, 'normalizedSuccess')?.normalizedSuccess || 0,
      max: _.maxBy(rows, 'normalizedSuccess')?.normalizedSuccess || 0,
    },
    Efficiency: {
      min: _.minBy(rows, 'normalizedEfficiency')?.normalizedEfficiency || 0,
      max: _.maxBy(rows, 'normalizedEfficiency')?.normalizedEfficiency || 0,
    },
    Speed: {
      min: _.minBy(rows, 'normalizedSpeed')?.normalizedSpeed || 0,
      max: _.maxBy(rows, 'normalizedSpeed')?.normalizedSpeed || 0,
    },
  };

  const sortRanks = (v1, v2) => {
    const r1 = rows.find((r) => r.name === v1)?.rank;
    const r2 = rows.find((r) => r.name === v2)?.rank;
    return r1 - r2;
  };

  const columns = [
    {
      field: 'name',
      width: 300,
      headerName: `${teamOrCandidateText} Rank`,
      renderHeader: () => (
        <Box display="flex" alignItems="center">
          <Box mr={1} pb={1}>
            <StarsIcon style={{ opacity: '0.8' }} />
          </Box>
          <FdTypography variant="subtitle2">
            {`${teamOrCandidateText} Rank`}
          </FdTypography>
        </Box>
      ),
      sortComparator: sortRanks,
      renderCell: (params) => (
        <Box display="flex" alignItems="center">
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width={30}
            height={30}
            borderRadius="50%"
            mr={1}
            style={{
              backgroundColor: '#E3F2FD',
              color: 'rgba(0, 0, 0, 0.87)',
            }}
          >
            <FdTypography variant="body1">{`#${params.row.rank}`}</FdTypography>
          </Box>
          <FdTypography variant="body1">{params.value}</FdTypography>
        </Box>
      ),
    },
    {
      field: 'overallScore',
      flex: 1,
      headerName: 'Assessment Score',
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      renderHeader: () => (
        <TableHeaderColumnWithTooltip
          title="Assessment Score"
          tooltipText={
            <Box>
              Assessment score is a measurement of Success(challenges
              successfully solved), Efficiency(scoring points with least number
              of tries), and Speed(quickness in completing the assessment).
              <Box>
                The most important metric that determines the Assessment Score
                or Assessment Rank is Success, but Speed and Efficiency are also
                given weightage.
              </Box>
            </Box>
          }
        />
      ),
      renderCell: (params) => (
        <FdSkeleton loading={loading} variant="text">
          {params.value}
        </FdSkeleton>
      ),
      valueGetter: (params) => params.value,
    },
    {
      field: 'success',
      flex: 1,
      headerName: 'Success',
      headerAlign: 'center',
      type: 'number',
      align: 'center',
      renderHeader: () => (
        <TableHeaderColumnWithTooltip
          title="Success"
          tooltipText="Success is measured by the individual's ability to solve as many challenges as possible."
        />
      ),
      renderCell: (params) => (
        <ProgressIndicator
          variant="red"
          range1={{
            start: assessmentRange.Success.min,
            end: assessmentRange.Success.max,
          }}
          range2={{
            start: params.row.successRange.min,
            end: params.row.successRange.max,
          }}
          marker={params.row.normalizedSuccess}
          tooltipText={
            <ToolTip
              name={params.row.name}
              value={params.row.normalizedSuccess}
              min={params.row.successRange.min}
              max={params.row.successRange.max}
              label="Success"
            />
          }
          loading={loading}
        />
      ),
      valueGetter: (params) => params.row.normalizedSuccess,
    },
    {
      field: 'efficiency',
      flex: 1,
      headerName: 'Efficiency',
      headerAlign: 'center',
      type: 'number',
      align: 'center',
      renderHeader: () => (
        <TableHeaderColumnWithTooltip
          title="Efficiency"
          tooltipText={
            <Box>
              Ability to successfully solve a challenge in the least number of
              attempts.
              <Box>
                Efficiency is influenced by both completion rate and success
                rate, rewarding participants who effectively solve challenges
                with fewer initiated attempts.
              </Box>
            </Box>
          }
        />
      ),
      renderCell: (params) => (
        <ProgressIndicator
          variant="orange"
          range1={{
            start: assessmentRange.Efficiency.min,
            end: assessmentRange.Efficiency.max,
          }}
          range2={{
            start: params.row.efficiencyRange.min,
            end: params.row.efficiencyRange.max,
          }}
          marker={params.row.normalizedEfficiency}
          tooltipText={
            <ToolTip
              name={params.row.name}
              value={params.row.normalizedEfficiency}
              min={params.row.efficiencyRange.min}
              max={params.row.efficiencyRange.max}
              label="Efficiency"
            />
          }
          loading={loading}
        />
      ),
      valueGetter: (params) => params.row.normalizedEfficiency,
    },
    {
      field: 'speed',
      flex: 1,
      headerName: 'Speed',
      headerAlign: 'center',
      type: 'number',
      align: 'center',
      renderHeader: () => (
        <TableHeaderColumnWithTooltip
          title="Speed"
          tooltipText="Speed is determined by the individual's capability to complete the assessment in the quickest possible time."
        />
      ),
      renderCell: (params) => (
        <ProgressIndicator
          variant="green"
          range1={{
            start: assessmentRange.Speed.min,
            end: assessmentRange.Speed.max,
          }}
          range2={{
            start: params.row.speedRange.min,
            end: params.row.speedRange.max,
          }}
          marker={params.row.normalizedSpeed}
          tooltipText={
            <ToolTip
              name={params.row.name}
              value={params.row.normalizedSpeed}
              min={params.row.speedRange.min}
              max={params.row.speedRange.max}
              label="Speed"
            />
          }
          loading={loading}
        />
      ),
      valueGetter: (params) => params.row.normalizedSpeed,
    },
    {
      field: 'viewPerformanceButton',
      flex: 1,
      headerName: '',
      filterable: false,
      unSort: true,
      renderCell: (params) => (
        <FdButton
          variant="secondary"
          onClick={() => onParticipantClick(params.row.name)}
        >
          View Performance
        </FdButton>
      ),
    },
  ];
  return (
    <Box height="500px" mt={2}>
      <FdTable
        defaultMuiToolbarSettings={{
          showMuiDefaultToolbar: true,
          filterButton: true,
          densityButton: false,
        }}
        columnVisibilityModel={{}}
        toolbarSettings={{
          title: `${teamOrCandidateText} Ranking`,
          filterOptions: [],
          searchBox: true,
          filterButton: true,
        }}
        selectionModel={selectedCandidates}
        onSelectionModelChange={(_values) => {
          if (_values.length > 5) {
            warningToastMessage(
              `You can select only upto 5 ${teamOrCandidateText}s to compare.`,
            );
            return;
          }
          setSelectedCandidates(_values);
        }}
        selection
        disableSelectionOnClick
        actions={[]}
        rows={rows}
        columns={columns}
        pagination
        visibleSelection
        tablePageSize={5}
        gridId="assessor-ranking-table"
      />
    </Box>
  );
};

RankingTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedCandidates: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setSelectedCandidates: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  onParticipantClick: PropTypes.func.isRequired,
  teamBased: PropTypes.bool.isRequired,
};

export default RankingTable;
