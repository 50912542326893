import React, { useState } from 'react';
import { Box, Grid } from '@mui/material';
import { gql, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import * as singleSpa from 'single-spa';
import {
  BasePage,
  FdLoadingSpinner,
  useSnapshot,
  globalStore,
  useQueryRecursive,
  FdCard,
} from '@fifthdomain/fe-shared';
import { FdBreadcrumbHeader } from '@fifthdomain/sidebar';
import AssessmentRules from './AssessmentRules';
import AssessmentSummary from '../components/Participant/AssessmentSummary';
import useStopAssessment from '../hooks/useStopAssessment';
import useGetAssessmentsTasks from '../hooks/useGetAssessmentsTasks';
import {
  onCreateNewTaskAttempt,
  onUpdateAssessment,
} from '../graphql/subscriptions';
import CompetitionStory from '../components/Participant/CompetitionStory';
import { getCompetitionStatus } from '../shared/utils/getParticipantStatus';
import {
  getSystemTime,
  getAssessment,
  listTagsByAssessmentId,
} from '../graphql/queries';
import useSubscription from '../hooks/useSubscription';
import useGetScoreboard from '../hooks/useGetScoreboard';
import EventTags from '../components/EventTag/EventTags';
import TeamMembers from '../components/Participant/TeamMembers';

const AssessmentOverview = () => {
  const { assessmentId } = useParams();
  const globalSnap = useSnapshot(globalStore);
  const [endDateTime, setEndDateTime] = useState(undefined);
  const { data: assessmentTasksData, loading: assessmentTasksLoading } =
    useGetAssessmentsTasks({ userId: globalSnap?.userId });

  const { data: serverTime, loading: serverTimeLoading } = useQuery(
    gql(getSystemTime),
    {
      fetchPolicy: 'network-only',
    },
  );

  // get the assessment selected
  const assessmentDataFiltered =
    assessmentTasksData.listUserAssessments?.items.find(
      (ad) => ad.id === assessmentId,
    );
  const isEventGuided = assessmentDataFiltered?.assessment?.guided;
  // List the event tags for the assessmentId
  const {
    data: listTagsByAssessmentIdData,
    loading: listTagsByAssessmentIdLoading,
  } = useQueryRecursive(gql(listTagsByAssessmentId), {
    variables: {
      assessmentId: assessmentDataFiltered?.userAssessmentAssessmentId,
      limit: 500,
    },
    staleTime: { seconds: 0 },
    skip: !assessmentDataFiltered?.userAssessmentAssessmentId || isEventGuided,
  });

  const listEventTags =
    listTagsByAssessmentIdData?.listTagsByAssessmentId?.items;

  // get Assessment EndDateTime
  const { loading: getAssessmentLoading } = useQuery(gql(getAssessment), {
    variables: {
      id: assessmentDataFiltered?.userAssessmentAssessmentId,
    },
    onCompleted: (data) => {
      setEndDateTime(data?.getAssessment?.endDateTime);
    },
    skip: !assessmentDataFiltered?.userAssessmentAssessmentId,
    fetchPolicy: 'network-only',
  });

  // redirect to home page if not started
  if (
    assessmentDataFiltered?.status &&
    assessmentDataFiltered?.status !== 'STARTED'
  ) {
    singleSpa.navigateToUrl('/landing/landing-homepage');
  }

  const {
    data: listTaskAttemptsScoreboardData,
    loading: listTaskAttemptsScoreboardLoading,
    refetch: refetchScoreboard,
  } = useGetScoreboard({
    assessmentId: assessmentDataFiltered?.assessment?.id,
    teamBased: assessmentDataFiltered?.assessment?.teamBased,
    ...(assessmentDataFiltered?.assessment?.teamBased
      ? { teamId: assessmentDataFiltered?.teamId }
      : { userId: globalSnap?.userId }),
  });

  useSubscription({
    query: gql(onCreateNewTaskAttempt),
    variables: {
      teamId: assessmentDataFiltered?.teamId,
      assessmentId: assessmentDataFiltered?.assessment?.id,
    },
    onData: (_result) => {
      const { success } = _result?.data?.onCreateNewTaskAttempt || {
        success: false,
      };
      if (success) {
        refetchScoreboard();
      }
    },
  });

  const [stopAssessment, { loading: stopAssessmentInProgress }] =
    useStopAssessment(assessmentDataFiltered?.assessment?.participantEventType);

  useSubscription({
    query: gql(onUpdateAssessment),
    variables: {
      filter: {
        id: { eq: assessmentDataFiltered?.userAssessmentAssessmentId },
      },
    },
    onData: (_data) => {
      const _endDateTime = _data?.data?.onUpdateAssessment?.endDateTime;
      setEndDateTime(_endDateTime);

      if (_endDateTime) {
        // call stop user assessment if endDate is present
        // backend will stop the assessment based on server date time
        stopAssessment({
          variables: {
            userAssessmentId: assessmentId,
          },
        });
      }
    },
  });

  if (
    assessmentTasksLoading ||
    stopAssessmentInProgress ||
    listTaskAttemptsScoreboardLoading ||
    serverTimeLoading ||
    getAssessmentLoading
  ) {
    return <FdLoadingSpinner />;
  }

  const {
    assessment: { name, teamBased, startDateTime },
  } = assessmentDataFiltered || { assessment: {} };

  const updatedStatus = getCompetitionStatus(
    startDateTime,
    endDateTime,
    serverTime?.getSystemTime,
  );

  // redirect to homepage only if the competition is in ended state or not started state
  if (['ENDED', 'NOT_STARTED'].includes(updatedStatus)) {
    singleSpa.navigateToUrl('/landing/landing-homepage');
  }

  const scoreboardData = teamBased
    ? listTaskAttemptsScoreboardData?.listScoreboardsByTeamId?.items?.[0]
    : listTaskAttemptsScoreboardData?.listScoreboardsByUserId?.items?.[0];
  const tasksCompleted = scoreboardData?.flags || 0;
  const firstBloods = scoreboardData?.firstBloods || 0;
  const totalPoints = scoreboardData?.points || 0;

  return (
    <Box>
      <FdBreadcrumbHeader
        entries={[
          {
            name,
            type: 'COMPETITION',
            path: `/competitions/competition-tasks/${assessmentId}`,
          },
        ]}
        page={{
          name: 'Overview',
        }}
      />
      <BasePage heading={name} data-cy="overview-page">
        <Box pb={4}>
          <Box mb={2}>
            <AssessmentSummary
              assessment={{
                tasksCompleted,
                endDateTime,
                firstBloods,
                totalPoints: totalPoints || 0,
                teamBased,
                teamId: assessmentDataFiltered?.teamId,
                orgId: assessmentDataFiltered?.user?.orgId,
                enableVPN: assessmentDataFiltered?.enableVPN,
              }}
              onFinish={() =>
                stopAssessment({
                  variables: {
                    userAssessmentId: assessmentId,
                  },
                })
              }
            />
          </Box>
          <CompetitionStory story={assessmentDataFiltered?.assessment?.story} />
          <Grid container spacing={2}>
            <Grid item xs={teamBased ? 9 : listEventTags?.length > 0 ? 9 : 12}>
              <AssessmentRules
                videoUrl={assessmentDataFiltered?.assessment?.videoUrl}
                userFullName={globalSnap?.userName}
                preMessage={assessmentDataFiltered?.assessment?.preMessage}
                assessmentData={assessmentDataFiltered}
              />
            </Grid>
            <Grid item xs={3}>
              {teamBased && (
                <FdCard variant="outlined">
                  <TeamMembers assessmentData={assessmentDataFiltered} />
                </FdCard>
              )}
              {!listTagsByAssessmentIdLoading && listEventTags?.length > 0 && (
                <EventTags
                  eventTagData={listEventTags}
                  teamBased={assessmentDataFiltered?.assessment?.teamBased}
                  teamId={assessmentDataFiltered?.teamId}
                  _assessmentId={
                    assessmentDataFiltered?.userAssessmentAssessmentId
                  }
                />
              )}
            </Grid>
          </Grid>
        </Box>
      </BasePage>
    </Box>
  );
};

export default AssessmentOverview;
