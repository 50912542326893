export const listGroupsWithUsers = /* GraphQL */ `
  query listGroupsWithUsers(
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        users {
          items {
            user {
              id
              name
              email
            }
          }
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listTasksByOrgId = /* GraphQL */ `
  query listTasksByOrgId(
    $orgId: ID!
    $filter: ModelTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTasksByOrgId(
      orgId: $orgId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tags {
          items {
            tagId
            taskId
          }
        }
      }
      nextToken
    }
  }
`;

export const listGroupsByOrgId = /* GraphQL */ `
  query ListGroupsByOrgId(
    $orgId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroupsByOrgId(
      orgId: $orgId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listAffliationsByOrgId = /* GraphQL */ `
  query ListAffliationsByOrgId(
    $orgId: ID!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserOrgAffliationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAffliationsByOrgId(
      orgId: $orgId
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        status
        user {
          id
          name
          email
          alias
          permissions
          assessments {
            items {
              startedOn
              status
              assessment {
                orgId
                participantEventType
              }
            }
          }
          courses {
            items {
              course {
                orgId
                id
                status
                modulePartProgresses {
                  items {
                    status
                    courseId
                    userId
                  }
                }
              }
            }
          }
        }
      }
      nextToken
    }
  }
`;
export const listSquadsByOrgId = /* GraphQL */ `
  query ListSquadsByOrgId(
    $orgId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSquadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSquadsByOrgId(
      orgId: $orgId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        status
        functionId
        function {
          name
        }
        members {
          items {
            id
            role
            userId
            status
          }
        }
      }
      nextToken
    }
  }
`;
export const listUsersByOrgId = /* GraphQL */ `
  query ListUsersByOrgId(
    $orgId: ID!
    $email: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsersByOrgId(
      orgId: $orgId
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        name
        orgId
        alias
        permissions
      }
      nextToken
    }
  }
`;
export const listAffiliationsWorkRoleByOrgId = /* GraphQL */ `
  query ListAffliationsByOrgId(
    $orgId: ID!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserOrgAffliationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAffliationsByOrgId(
      orgId: $orgId
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        userId
        customerWorkrole {
          name
        }
      }
      nextToken
    }
  }
`;
export const listAffiliationsByOrgId = /* GraphQL */ `
  query ListAffliationsByOrgId(
    $orgId: ID!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserOrgAffliationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAffliationsByOrgId(
      orgId: $orgId
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        user {
          id
          email
          name
          permissions
          orgId
          alias
        }
        userId
      }
      nextToken
    }
  }
`;
